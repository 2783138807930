/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { I18NService } from '@ws1c/intelligence-common';
import { AutomationService, PreviewCommonService } from '@ws1c/intelligence-core/services';
import { AlertBannerActions } from '@ws1c/intelligence-core/store/alert-banner';
import { PreviewCommonActions } from '@ws1c/intelligence-core/store/preview-common/preview-common.actions';
import {
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  Automation,
  CustomReportPreviewSearchResponse,
  PreviewReportContentRequest,
  ReportType,
  Trend,
  TrendMode,
} from '@ws1c/intelligence-models';

/**
 * PreviewCommonEffects
 *
 * @export
 * @class PreviewCommonEffects
 */
@Injectable()
export class PreviewCommonEffects {
  /**
   * loadWidgetPreview$
   * @type {Observable<Action>}
   * @memberof PreviewCommonEffects
   */
  public loadWidgetPreview$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PreviewCommonActions.loadWidgetPreview),
      switchMap(({ template, trendDefinition }: ReturnType<typeof PreviewCommonActions.loadWidgetPreview>) => {
        const previewTrendDefinition = trendDefinition ?? template?.trendDefinition;
        return this.previewCommonService.loadWidgetPreview(previewTrendDefinition).pipe(
          map((widgetPreview: Trend) => PreviewCommonActions.loadWidgetPreviewSuccess({ widgetPreview })),
          catchError((error: WebError) => [
            PreviewCommonActions.loadWidgetPreviewFailure({ error }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.APP,
              autoDismiss: true,
              message: this.i18nService.translate('PREVIEW.LOAD_WIDGET_PREVIEW_ERROR', {
                error: error.getFullReason(),
              }),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * loadReportPreview$
   * @type {Observable<Action>}
   * @memberof PreviewCommonEffects
   */
  public loadReportPreview$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PreviewCommonActions.loadReportPreview),
      switchMap(({ template, request }: ReturnType<typeof PreviewCommonActions.loadReportPreview>) => {
        let previewRequest = new PreviewReportContentRequest({
          pageSize: 25,
        });
        let isSnapshot = true;
        if (template) {
          const entitiesByIntegration = {
            [template.integration]: [template.entity],
          };
          isSnapshot = template.reportType === ReportType.SNAPSHOT;
          previewRequest = new PreviewReportContentRequest({
            ...previewRequest,
            entitiesByIntegration,
            filter: template.filter,
            fields: template.columnNames,
            ignoreCase: isSnapshot ? undefined : true,
            ...(isSnapshot ? [] : template.dateRange?.getStartEndMillis()),
          });
        }
        if (request) {
          previewRequest = new PreviewReportContentRequest({
            ...previewRequest,
            ...request,
          });
          isSnapshot = request.trendMode === TrendMode.SNAPSHOT;
        }
        return this.previewCommonService.loadReportPreview(previewRequest, isSnapshot).pipe(
          map((reportPreview: CustomReportPreviewSearchResponse) => PreviewCommonActions.loadReportPreviewSuccess({ reportPreview })),
          catchError((error: WebError) => [
            PreviewCommonActions.loadReportPreviewFailure({ error }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.APP,
              autoDismiss: true,
              message: this.i18nService.translate('PREVIEW.LOAD_REPORT_PREVIEW_ERROR', {
                error: error.getFullReason(),
              }),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * loadAutomationPreview$
   * @type {Observable<Action>}
   * @memberof PreviewCommonEffects
   */
  public loadAutomationPreview$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PreviewCommonActions.loadAutomationPreview),
      switchMap(({ template }: ReturnType<typeof PreviewCommonActions.loadAutomationPreview>) => {
        return this.automationService.getAutomationTemplateDetailsV3(template).pipe(
          map((automationPreview: Automation) => PreviewCommonActions.loadAutomationPreviewSuccess({ automationPreview })),
          catchError((error: WebError) => [
            PreviewCommonActions.loadAutomationPreviewFailure({ error }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.APP,
              autoDismiss: true,
              message: this.i18nService.translate('PREVIEW.LOAD_AUTOMATION_PREVIEW_ERROR', {
                error: error.getFullReason(),
              }),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * Creates an instance of PreviewCommonEffects.
   * @param {Actions} actions$
   * @param {I18NService} i18nService
   * @param {PreviewCommonService} previewCommonService
   * @param {AutomationService} automationService
   * @memberof PreviewCommonEffects
   */
  constructor(
    private actions$: Actions,
    private i18nService: I18NService,
    private previewCommonService: PreviewCommonService,
    private automationService: AutomationService,
  ) {}
}
