/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { differenceWith, keyBy } from 'lodash-es';

import {
  ConnectionDeauthorizeRequest,
  ConnectionSetupRequest,
  EventForwarderTemplate,
  ServiceConfigTemplate,
} from '@dpa-shared-merlot/model';
import { ConnectionCommonActions } from '@ws1c/intelligence-core/store/connection-common';
import {
  AvailableEventForwarder,
  AvailablePartner,
  AvailableService,
  Connection,
  ConnectionModalMode,
  EventForwarderConnectionModalMode,
  EventForwarderConnectionModalSection,
  LOAD_STATE,
  TrustNetworkPartnerConnectionModalMode,
  TrustNetworkPartnerConnectionModalSection,
} from '@ws1c/intelligence-models';
import { ConnectionActions } from './connection.actions';
import { ConnectionState, initialConnectionState } from './connection.state';

let connectionSetupRequestData: Partial<ConnectionSetupRequest>;

const connectionReducer: ActionReducer<ConnectionState, Action> = createReducer(
  initialConnectionState,
  on(
    ConnectionCommonActions.updateServiceWithConfig,
    (state: ConnectionState, { connection }: ReturnType<typeof ConnectionCommonActions.updateServiceWithConfig>): ConnectionState => ({
      ...state,
      trustNetworkServices: state.trustNetworkServices.map(updateServiceConnection(connection)),
    }),
  ),
  on(
    ConnectionCommonActions.requestEditConnection,
    (
      state: ConnectionState,
      { availableService, availableServiceTenant }: ReturnType<typeof ConnectionCommonActions.requestEditConnection>,
    ): ConnectionState => ({
      ...state,
      connectionModalMode: ConnectionModalMode.EDIT,
      connectionModalModel: availableService,
      connectionModalTenantModel: availableServiceTenant,
      connectionSetupRequest: new ConnectionSetupRequest({
        loadState: LOAD_STATE.IN_FLIGHT,
        key: availableService.key,
        serviceId: availableService.id,
      }),
      connectionSlideOver: {
        ...state.connectionSlideOver,
        model: undefined,
        isOpen: false,
      },
    }),
  ),
  on(
    ConnectionActions.loadServiceConfigTemplatesSuccess,
    (state: ConnectionState, { templates }: ReturnType<typeof ConnectionActions.loadServiceConfigTemplatesSuccess>): ConnectionState => ({
      ...state,
      serviceTemplatesByType: keyBy(templates, (template: ServiceConfigTemplate) => template.compositeServiceType),
    }),
  ),
  on(
    ConnectionActions.loadTrustNetworkServicesSuccess,
    (state: ConnectionState, { response }: ReturnType<typeof ConnectionActions.loadTrustNetworkServicesSuccess>): ConnectionState => ({
      // Currently all 3 services where automationSupported is false, also have ingestionSupported = true
      ...state,
      trustNetworkServices: response.results.filter((service: AvailableService) => service.ingestionSupported),
    }),
  ),
  on(
    ConnectionActions.requestSetupConnection,
    (state: ConnectionState, { availableService }: ReturnType<typeof ConnectionActions.requestSetupConnection>): ConnectionState => {
      return {
        ...state,
        connectionModalMode: ConnectionModalMode.SETUP,
        connectionModalModel: availableService,
        connectionModalTenantModel: undefined,
        connectionSetupRequest: {
          loadState: LOAD_STATE.NONE,
          key: availableService.key,
          serviceId: availableService.id,
        } as ConnectionSetupRequest,
        connectionSlideOver: {
          ...state.connectionSlideOver,
          model: undefined,
          isOpen: false,
        },
      };
    },
  ),
  on(ConnectionActions.processSetupConnection, (state: ConnectionState): ConnectionState => {
    let connectionModalMode = state.connectionModalMode;
    if (connectionModalMode === ConnectionModalMode.EDIT_CONFIRM) {
      connectionModalMode = ConnectionModalMode.EDIT;
    }
    return {
      ...state,
      connectionModalMode,
      connectionSetupRequest: {
        ...state.connectionSetupRequest,
        loadState: LOAD_STATE.IN_FLIGHT,
      } as ConnectionSetupRequest,
    };
  }),
  on(
    ConnectionActions.confirmEditConnection,
    (state: ConnectionState): ConnectionState => ({
      ...state,
      connectionModalMode: ConnectionModalMode.EDIT_CONFIRM,
    }),
  ),
  on(
    ConnectionActions.cancelConfirmEditConnection,
    (state: ConnectionState): ConnectionState => ({
      ...state,
      connectionModalMode: ConnectionModalMode.EDIT,
    }),
  ),
  on(
    ConnectionActions.requestEditConnectionSuccess,
    (state: ConnectionState, { settings }: ReturnType<typeof ConnectionActions.requestEditConnectionSuccess>): ConnectionState => ({
      ...state,
      connectionSetupRequest: {
        ...state.connectionSetupRequest,
        loadState: LOAD_STATE.SUCCESS,
        settings,
      } as ConnectionSetupRequest,
    }),
  ),
  on(
    ConnectionActions.updateConnectionSetupRequest,
    (state: ConnectionState, { request }: ReturnType<typeof ConnectionActions.updateConnectionSetupRequest>): ConnectionState => {
      connectionSetupRequestData = request;
      return {
        ...state,
        connectionSetupRequest: {
          ...state.connectionSetupRequest,
          ...connectionSetupRequestData,
        },
      };
    },
  ),
  on(
    ConnectionActions.closeConnectionModal,
    (state: ConnectionState): ConnectionState => ({
      ...state,
      connectionModalMode: ConnectionModalMode.CLOSE,
      connectionModalModel: undefined,
      connectionModalTenantModel: undefined,
    }),
  ),
  on(
    ConnectionActions.requestDeauthorizeConnection,
    (
      state: ConnectionState,
      { availableService, availableServiceTenant }: ReturnType<typeof ConnectionActions.requestDeauthorizeConnection>,
    ): ConnectionState => ({
      ...state,
      connectionModalMode: ConnectionModalMode.DEAUTHORIZE,
      connectionModalModel: availableService,
      connectionModalTenantModel: availableServiceTenant,
      connectionDeauthorizeRequest: {
        loadState: LOAD_STATE.NONE,
      } as ConnectionDeauthorizeRequest,
      connectionSlideOver: {
        ...state.connectionSlideOver,
        model: undefined,
        isOpen: false,
      },
    }),
  ),
  on(
    ConnectionActions.updateConnectionDeauthorizeRequest,
    (
      state: ConnectionState,
      { connectionDeauthorizeRequest }: ReturnType<typeof ConnectionActions.updateConnectionDeauthorizeRequest>,
    ): ConnectionState => ({
      ...state,
      connectionDeauthorizeRequest: {
        ...state.connectionDeauthorizeRequest,
        ...connectionDeauthorizeRequest,
      },
    }),
  ),
  on(
    ConnectionActions.openPreviewConnectionSlideOver,
    (
      state: ConnectionState,
      { availableService }: ReturnType<typeof ConnectionActions.openPreviewConnectionSlideOver>,
    ): ConnectionState => ({
      ...state,
      connectionSlideOver: {
        ...state.connectionSlideOver,
        model: availableService,
        isOpen: true,
      },
    }),
  ),
  on(
    ConnectionActions.closePreviewConnectionSlideOver,
    (state: ConnectionState): ConnectionState => ({
      ...state,
      connectionSlideOver: {
        ...state.connectionSlideOver,
        model: undefined,
        isOpen: false,
      },
    }),
  ),
  on(
    ConnectionActions.getConnectionDetailsSuccess,
    (state: ConnectionState, { availableService }: ReturnType<typeof ConnectionActions.getConnectionDetailsSuccess>): ConnectionState => ({
      ...state,
      trustNetworkServices: updateService(state.trustNetworkServices, availableService),
    }),
  ),
  on(
    ConnectionActions.loadPartnersSuccess,
    (state: ConnectionState, { partners }: ReturnType<typeof ConnectionActions.loadPartnersSuccess>): ConnectionState => ({
      ...state,
      availablePartners: partners,
    }),
  ),
  on(
    ConnectionActions.setupTrustNetworkPartnerConnection,
    ConnectionActions.updateTrustNetworkPartnerConnection,
    (state: ConnectionState): ConnectionState => ({
      ...state,
      isSavingOrUpdatingPartnerConnection: true,
    }),
  ),
  on(
    ConnectionActions.setupOrUpdateTrustNetworkPartnerConnectionSuccess,
    (
      state: ConnectionState,
      { availablePartner }: ReturnType<typeof ConnectionActions.setupOrUpdateTrustNetworkPartnerConnectionSuccess>,
    ): ConnectionState => ({
      ...state,
      availablePartners: setupOrUpdateTrustNetworkPartnerConnection(state.availablePartners, availablePartner),
      trustNetworkPartnerConnectionModalSection: TrustNetworkPartnerConnectionModalSection.CREDENTIALS,
      selectedPartner: availablePartner,
      isSavingOrUpdatingPartnerConnection: false,
    }),
  ),
  on(
    ConnectionActions.setupOrUpdateTrustNetworkPartnerConnectionFailure,
    (state: ConnectionState): ConnectionState => ({
      ...state,
      isSavingOrUpdatingPartnerConnection: false,
    }),
  ),
  on(
    ConnectionActions.regenerateTrustNetworkPartnerConnectionToken,
    (state: ConnectionState): ConnectionState => ({
      ...state,
      isRegeneratingPartnerConnectionToken: true,
    }),
  ),
  on(
    ConnectionActions.regenerateTrustNetworkPartnerConnectionTokenSuccess,
    (
      state: ConnectionState,
      { availablePartner }: ReturnType<typeof ConnectionActions.regenerateTrustNetworkPartnerConnectionTokenSuccess>,
    ): ConnectionState => ({
      ...state,
      availablePartners: setupOrUpdateTrustNetworkPartnerConnection(state.availablePartners, availablePartner),
      selectedPartner: availablePartner,
      isRegeneratingPartnerConnectionToken: false,
    }),
  ),
  on(
    ConnectionActions.regenerateTrustNetworkPartnerConnectionTokenFailure,
    (state: ConnectionState): ConnectionState => ({
      ...state,
      isRegeneratingPartnerConnectionToken: false,
    }),
  ),
  on(
    ConnectionActions.removeTrustNetworkPartnerConnectionToken,
    (state: ConnectionState): ConnectionState => ({
      ...state,
      availablePartners: setupOrUpdateTrustNetworkPartnerConnection(
        state.availablePartners,
        new AvailablePartner({
          ...state.selectedPartner,
          token: undefined,
        }),
      ),
    }),
  ),
  on(
    ConnectionActions.deauthorizeTrustNetworkPartnerConnection,
    (state: ConnectionState): ConnectionState => ({
      ...state,
      isDeauthorizingPartnerConnection: true,
    }),
  ),
  on(
    ConnectionActions.deauthorizeTrustNetworkPartnerConnectionSuccess,
    ConnectionActions.deauthorizeTrustNetworkPartnerConnectionFailure,
    ConnectionActions.revokePartnerFailure,
    ConnectionActions.revokePartnerSuccess,
    (state: ConnectionState): ConnectionState => ({
      ...state,
      isDeauthorizingPartnerConnection: false,
    }),
  ),
  on(
    ConnectionActions.openSetupOrEditPartnerConnectionModal,
    (
      state: ConnectionState,
      { availablePartner }: ReturnType<typeof ConnectionActions.openSetupOrEditPartnerConnectionModal>,
    ): ConnectionState => {
      let modalSelection = TrustNetworkPartnerConnectionModalSection.SETUP_OR_EDIT;
      if (availablePartner.isAcknowledgementRequired()) {
        modalSelection = availablePartner.id
          ? TrustNetworkPartnerConnectionModalSection.CREDENTIALS
          : TrustNetworkPartnerConnectionModalSection.ACKNOWLEDGE;
      }
      return {
        ...state,
        trustNetworkPartnerConnectionModalMode: TrustNetworkPartnerConnectionModalMode.SETUP_OR_EDIT,
        trustNetworkPartnerConnectionModalSection: modalSelection,
        selectedPartner: availablePartner,
      };
    },
  ),
  on(
    ConnectionActions.openDeauthorizePartnerConnectionModal,
    (
      state: ConnectionState,
      { availablePartner }: ReturnType<typeof ConnectionActions.openDeauthorizePartnerConnectionModal>,
    ): ConnectionState => ({
      ...state,
      trustNetworkPartnerConnectionModalMode: TrustNetworkPartnerConnectionModalMode.DEAUTHORIZE,
      selectedPartner: availablePartner,
    }),
  ),
  on(
    ConnectionActions.closePartnerConnectionModal,
    (state: ConnectionState): ConnectionState => ({
      ...state,
      trustNetworkPartnerConnectionModalMode: TrustNetworkPartnerConnectionModalMode.CLOSE,
      trustNetworkPartnerConnectionModalSection: TrustNetworkPartnerConnectionModalSection.NONE,
      selectedPartner: undefined,
    }),
  ),
  on(
    ConnectionActions.togglePartnerConnectionModalSection,
    (
      state: ConnectionState,
      { trustNetworkPartnerConnectionModalSection }: ReturnType<typeof ConnectionActions.togglePartnerConnectionModalSection>,
    ): ConnectionState => ({
      ...state,
      trustNetworkPartnerConnectionModalSection:
        state.trustNetworkPartnerConnectionModalSection === trustNetworkPartnerConnectionModalSection
          ? TrustNetworkPartnerConnectionModalSection.NONE
          : trustNetworkPartnerConnectionModalSection,
    }),
  ),
  on(
    ConnectionActions.loadEventForwardersTemplatesSuccess,
    (state: ConnectionState, { templates }: ReturnType<typeof ConnectionActions.loadEventForwardersTemplatesSuccess>): ConnectionState => ({
      ...state,
      eventForwardersTemplatesByName: keyBy(templates, (template: EventForwarderTemplate) => template.name),
    }),
  ),
  on(
    ConnectionActions.loadTrustNetworkEventForwardersSuccess,
    (
      state: ConnectionState,
      { trustNetworkEventForwarders }: ReturnType<typeof ConnectionActions.loadTrustNetworkEventForwardersSuccess>,
    ): ConnectionState => ({
      ...state,
      trustNetworkEventForwarders,
    }),
  ),
  on(
    ConnectionActions.openSetupOrEditEventForwarderConnectionModal,
    (
      state: ConnectionState,
      { availableEventForwarder }: ReturnType<typeof ConnectionActions.openSetupOrEditEventForwarderConnectionModal>,
    ): ConnectionState => {
      const modalSection = availableEventForwarder.id
        ? EventForwarderConnectionModalSection.CREDENTIALS
        : EventForwarderConnectionModalSection.AUTHORITIES;
      return {
        ...state,
        eventForwarderConnectionModalMode: EventForwarderConnectionModalMode.SETUP_OR_EDIT,
        eventForwarderConnectionModalSection: modalSection,
        selectedEventForwarder: availableEventForwarder,
      };
    },
  ),
  on(
    ConnectionActions.toggleEventForwarderConnectionModalSection,
    (
      state: ConnectionState,
      { eventForwarderConnectionModalSection }: ReturnType<typeof ConnectionActions.toggleEventForwarderConnectionModalSection>,
    ): ConnectionState => ({
      ...state,
      eventForwarderConnectionModalSection:
        state.eventForwarderConnectionModalSection === eventForwarderConnectionModalSection
          ? EventForwarderConnectionModalSection.NONE
          : eventForwarderConnectionModalSection,
    }),
  ),
  on(
    ConnectionActions.setupOrUpdateEventForwarderConnection,
    (state: ConnectionState): ConnectionState => ({
      ...state,
      isSavingOrUpdatingEventForwarderConnection: true,
    }),
  ),
  on(
    ConnectionActions.setupOrUpdateEventForwarderConnectionSuccess,
    (
      state: ConnectionState,
      { availableEventForwarder }: ReturnType<typeof ConnectionActions.setupOrUpdateEventForwarderConnectionSuccess>,
    ): ConnectionState => ({
      ...state,
      trustNetworkEventForwarders: setupOrUpdateEventForwarderConnection(state.trustNetworkEventForwarders, availableEventForwarder),
      isSavingOrUpdatingEventForwarderConnection: false,
      eventForwarderConnectionModalMode: EventForwarderConnectionModalMode.CLOSE,
      eventForwarderConnectionModalSection: EventForwarderConnectionModalSection.NONE,
      selectedEventForwarder: undefined,
    }),
  ),
  on(
    ConnectionActions.setupOrUpdateEventForwarderConnectionFailure,
    (state: ConnectionState): ConnectionState => ({
      ...state,
      isSavingOrUpdatingEventForwarderConnection: false,
    }),
  ),
  on(
    ConnectionActions.confirmUpdateEventForwarderConnection,
    (state: ConnectionState): ConnectionState => ({
      ...state,
      eventForwarderConnectionModalMode: EventForwarderConnectionModalMode.CONFIRM,
    }),
  ),
  on(
    ConnectionActions.closeConfirmUpdateEventForwarderConnectionModal,
    (state: ConnectionState): ConnectionState => ({
      ...state,
      eventForwarderConnectionModalMode: EventForwarderConnectionModalMode.SETUP_OR_EDIT,
    }),
  ),
  on(
    ConnectionActions.closeEventForwarderConnectionModal,
    (state: ConnectionState): ConnectionState => ({
      ...state,
      eventForwarderConnectionModalMode: EventForwarderConnectionModalMode.CLOSE,
      eventForwarderConnectionModalSection: EventForwarderConnectionModalSection.NONE,
      selectedEventForwarder: undefined,
    }),
  ),
  on(
    ConnectionActions.openDeauthorizeEventForwarderConnectionModal,
    (
      state: ConnectionState,
      { availableEventForwarder }: ReturnType<typeof ConnectionActions.openDeauthorizeEventForwarderConnectionModal>,
    ): ConnectionState => ({
      ...state,
      eventForwarderConnectionModalMode: EventForwarderConnectionModalMode.DEAUTHORIZE,
      selectedEventForwarder: availableEventForwarder,
    }),
  ),
  on(
    ConnectionActions.deauthorizeEventForwarderConnection,
    (state: ConnectionState): ConnectionState => ({
      ...state,
      isDeauthorizingEventForwarderConnection: true,
    }),
  ),
  on(
    ConnectionActions.deauthorizeEventForwarderConnectionSuccess,
    (
      state: ConnectionState,
      { availableEventForwarder }: ReturnType<typeof ConnectionActions.deauthorizeEventForwarderConnectionSuccess>,
    ): ConnectionState => ({
      ...state,
      trustNetworkEventForwarders: deleteEventForwarderConnection(state.trustNetworkEventForwarders, availableEventForwarder),
      isDeauthorizingEventForwarderConnection: false,
    }),
  ),
  on(
    ConnectionActions.deauthorizeEventForwarderConnectionFailure,
    (state: ConnectionState): ConnectionState => ({
      ...state,
      isDeauthorizingEventForwarderConnection: false,
    }),
  ),
  on(
    ConnectionActions.revokePartner,
    (state: ConnectionState, { availablePartner }: ReturnType<typeof ConnectionActions.revokePartner>): ConnectionState => ({
      ...state,
      selectedPartner: availablePartner,
    }),
  ),
);

/**
 * Connection State Reducer
 * @export
 * @param {ConnectionState} state
 * @param {Action} action
 * @returns {ConnectionState}
 */
export function connectionState(state: ConnectionState, action: Action): ConnectionState {
  return connectionReducer(state, action);
}

/**
 * updateServiceConnection
 * Updates service with config connection status
 * @param {Connection} config
 * @returns {(service: AvailableService) => AvailableService}
 */
function updateServiceConnection(config: Connection): (service: AvailableService) => AvailableService {
  return (service: AvailableService): AvailableService => {
    if (service.id === config.serviceId) {
      return new AvailableService({
        ...service,
        serviceConfigId: config.id,
        statusCheckSuccess: config.statusCheckSuccess,
      });
    }
    return service;
  };
}

/**
 * updateService
 * @param {AvailableService[]} stateAvailableServices
 * @param {AvailableService} availableService
 * @returns {AvailableService[]}
 */
function updateService(stateAvailableServices: AvailableService[], availableService: AvailableService): AvailableService[] {
  return stateAvailableServices.map((existingService: AvailableService): AvailableService => {
    return existingService.id === availableService.id ? availableService : existingService;
  });
}

/**
 * setupOrUpdateTrustNetworkPartnerConnection
 * Utility function which will either add, or update if already present based on id match, a single Trust
 * Network Partner in the array of Trust Network Partners.
 *
 * @param {AvailablePartner[]} stateTrustNetworkPartners
 * @param {AvailablePartner} newOrUpdatedPartner
 * @returns {AvailablePartner[]}
 */
function setupOrUpdateTrustNetworkPartnerConnection(
  stateTrustNetworkPartners: AvailablePartner[],
  newOrUpdatedPartner: AvailablePartner,
): AvailablePartner[] {
  let partnerExist: boolean = false;
  const updatedTrustNetworkPartners: AvailablePartner[] = stateTrustNetworkPartners.map(
    (existingPartner: AvailablePartner): AvailablePartner => {
      if (existingPartner.id === newOrUpdatedPartner.id) {
        partnerExist = true;
        return newOrUpdatedPartner;
      }
      return existingPartner;
    },
  );
  return partnerExist ? updatedTrustNetworkPartners : [...stateTrustNetworkPartners, newOrUpdatedPartner];
}

/**
 * setupOrUpdateEventForwarderConnection
 * Utility function which will either add, or update if already present based on name match, a single
 * Event Forwarder Connection in the array of Event Forwarders.
 *
 * @param {AvailableEventForwarder[]} stateTrustNetworkEventForwarders
 * @param {AvailableEventForwarder} newOrUpdatedEventForwarder
 * @returns {AvailableEventForwarder[]}
 */
function setupOrUpdateEventForwarderConnection(
  stateTrustNetworkEventForwarders: AvailableEventForwarder[],
  newOrUpdatedEventForwarder: AvailableEventForwarder,
): AvailableEventForwarder[] {
  let eventForwarderExist: boolean = false;
  const updatedTrustNetworkEventForwarders: AvailableEventForwarder[] = stateTrustNetworkEventForwarders.map(
    (existingEventForwarder: AvailableEventForwarder): AvailableEventForwarder => {
      if (existingEventForwarder.name === newOrUpdatedEventForwarder.name) {
        eventForwarderExist = true;
        return newOrUpdatedEventForwarder;
      }
      return existingEventForwarder;
    },
  );
  return eventForwarderExist ? updatedTrustNetworkEventForwarders : [...stateTrustNetworkEventForwarders, newOrUpdatedEventForwarder];
}

/**
 * deleteEventForwarderConnection
 * Utility function which will delete a single Event Forwarder in the array of Event Forwarders.
 *
 * @param {AvailableEventForwarder[]} stateTrustNetworkEventForwarders
 * @param {AvailableEventForwarder} deletedEventForwarder
 * @returns {AvailableEventForwarder[]}
 */
function deleteEventForwarderConnection(
  stateTrustNetworkEventForwarders: AvailableEventForwarder[],
  deletedEventForwarder: AvailableEventForwarder,
): AvailableEventForwarder[] {
  return differenceWith(
    stateTrustNetworkEventForwarders,
    [deletedEventForwarder],
    (stateTrustNetworkEventForwarder: AvailableEventForwarder, eventForwarder: AvailableEventForwarder) => {
      return stateTrustNetworkEventForwarder.name === eventForwarder.name;
    },
  );
}
