<dpa-slide-over
  [isVisible]="isVisible$ | async"
  (hide)="onHidePreview()"
>
  <div class="slide-over">
    <div class="slide-over-header pl-x4 pr-x2">
      <h4 class="mt-0">{{ 'MARKETPLACE.TEMPLATE_PREVIEW' | translate }}</h4>
      <button class="btn btn-link btn-icon">
        <cds-icon
          (click)="onHidePreview()"
          shape="times"
          size="20"
        ></cds-icon>
      </button>
    </div>
    <div
      *ngIf="resource$ | async as resource"
      class="slide-over-content"
    >
      <ng-template
        [ngTemplateOutlet]="previewTemplateByResourceType[resource?.resourceType]"
        [ngTemplateOutletContext]="{ resource }"
      ></ng-template>
    </div>
    <div class="slide-over-footer ph-x2">
      <div class="previous">
        <ng-container *ngIf="previousResource$ | async as prevResource">
          <button
            (click)="previewPrevResource(prevResource)"
            class="btn btn-link btn-icon ph-0"
          >
            <cds-icon
              shape="angle"
              direction="left"
            ></cds-icon>
          </button>
          <button
            (click)="previewPrevResource(prevResource)"
            class="btn btn-link"
          >
            <span>{{ prevResource.name }}</span>
          </button>
        </ng-container>
      </div>
      <div class="next">
        <ng-container *ngIf="nextResource$ | async as nextResource">
          <button
            (click)="previewNextResource(nextResource)"
            class="btn btn-link"
          >
            <span>{{ nextResource.name }}</span>
          </button>
          <button
            (click)="previewNextResource(nextResource)"
            class="btn btn-link btn-icon ph-0"
          >
            <cds-icon
              shape="angle"
              direction="right"
            ></cds-icon>
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</dpa-slide-over>
