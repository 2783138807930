<dpa-key-selector-search
  *ngIf="availableColumns$ | async as availableColumns"
  [showReset]="false"
  [showColumnsFromInput]="true"
  [allColumns]="availableColumns"
  [selectedColumn]="selectedColumn$ | async"
  [isCrossCategory]="isCrossCategory"
  [placeholderTextKey]="'DASHBOARD_ACTIONS.SELECT_A_GROUP_BY'"
  (columnChange)="onSelectColumn($event)"
></dpa-key-selector-search>
