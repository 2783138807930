/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { AccountSearchResponse, COLUMN_NAMES, GenericSearchRequest, LOAD_STATE, SearchTerm } from '@ws1c/intelligence-models';
import { AccountActions } from './account.actions';
import { AccountState, initialAccountState } from './account.state';

const _reducer: ActionReducer<AccountState> = createReducer(
  initialAccountState,
  on(
    AccountActions.getAccountGroupByDirectoryGroupId,
    (state: AccountState): AccountState => ({
      ...state,
      groupByAzureId: null,
    }),
  ),
  on(
    AccountActions.getAccountGroupByDirectoryGroupIdSuccess,
    (state: AccountState, { accountGroup }: ReturnType<typeof AccountActions.getAccountGroupByDirectoryGroupIdSuccess>): AccountState => ({
      ...state,
      groupByAzureId: accountGroup,
    }),
  ),
  on(
    AccountActions.getUsersForAddAccount,
    (state: AccountState): AccountState => ({
      ...state,
      isLoadingUsers: true,
    }),
  ),
  on(
    AccountActions.getUsersForAddAccountSuccess,
    (state: AccountState, { userAdminAccounts }: ReturnType<typeof AccountActions.getUsersForAddAccountSuccess>): AccountState => ({
      ...state,
      usersForAddAccount: userAdminAccounts,
      isLoadingUsers: false,
    }),
  ),

  on(
    AccountActions.getUsersForAddAccountFailure,
    (state: AccountState): AccountState => ({
      ...state,
      usersForAddAccount: [],
      isLoadingUsers: false,
    }),
  ),

  on(
    AccountActions.getAccounts,
    (state: AccountState, { searchRequest }: ReturnType<typeof AccountActions.getAccounts>): AccountState => ({
      ...state,
      searchRequest,
      isLoading: true,
    }),
  ),

  on(
    AccountActions.getAccountsSuccess,
    (state: AccountState, { accountSearchResponse }: { accountSearchResponse: AccountSearchResponse }): AccountState => ({
      ...state,
      searchResponse: accountSearchResponse,
      isLoading: false,
    }),
  ),

  on(
    AccountActions.getAccountsFailure,
    (state: AccountState): AccountState => ({
      ...state,
      isLoading: false,
    }),
  ),

  on(
    AccountActions.selectAccounts,
    (state: AccountState, { selectedAccounts }: ReturnType<typeof AccountActions.selectAccounts>): AccountState => ({
      ...state,
      selectedAccounts,
    }),
  ),

  on(
    AccountActions.refreshAccounts,
    AccountActions.goToAdministratorHome,
    (state: AccountState): AccountState => ({
      ...state,
      selectedAccounts: [],
      selectedIntelligenceRoles: [],
    }),
  ),

  on(
    AccountActions.getRolesSuccess,
    (state: AccountState, { roles }: ReturnType<typeof AccountActions.getRolesSuccess>): AccountState => ({
      ...state,
      roles,
    }),
  ),

  on(
    AccountActions.getAccountGroups,
    (state: AccountState, { searchRequest }: ReturnType<typeof AccountActions.getAccountGroups>): AccountState => ({
      ...state,
      accountGroupSearchRequest: searchRequest,
      isLoadingAccountGroups: true,
    }),
  ),

  on(
    AccountActions.getAccountGroupsSuccess,
    (state: AccountState, { accountGroupSearchResponse }: ReturnType<typeof AccountActions.getAccountGroupsSuccess>): AccountState => ({
      ...state,
      accountGroupSearchResponse,
      isLoadingAccountGroups: false,
    }),
  ),

  on(
    AccountActions.getAccountGroupsFailure,
    (state: AccountState): AccountState => ({
      ...state,
      isLoadingAccountGroups: false,
    }),
  ),

  on(
    AccountActions.getIntegrationGroups,
    (state: AccountState): AccountState => ({
      ...state,
      isLoadingIntegrationGroups: true,
    }),
  ),

  on(
    AccountActions.getIntegrationGroupsSuccess,
    (state: AccountState, { integrationGroups }: ReturnType<typeof AccountActions.getIntegrationGroupsSuccess>): AccountState => ({
      ...state,
      integrationGroups,
      isLoadingIntegrationGroups: false,
    }),
  ),

  on(
    AccountActions.getIntegrationGroupsFailure,
    (state: AccountState): AccountState => ({
      ...state,
      integrationGroups: [],
      isLoadingIntegrationGroups: false,
    }),
  ),

  on(
    AccountActions.selectGroups,
    (state: AccountState, { selectedGroups }: ReturnType<typeof AccountActions.selectGroups>): AccountState => ({
      ...state,
      selectedGroups,
    }),
  ),

  on(
    AccountActions.refreshGroups,
    AccountActions.goToAdministratorGroupHome,
    (state: AccountState): AccountState => ({
      ...state,
      selectedGroups: [],
    }),
  ),

  on(
    AccountActions.showChooseDataAccessPolicyModal,
    (
      state: AccountState,
      { showChooseDataAccessPolicyModal }: ReturnType<typeof AccountActions.showChooseDataAccessPolicyModal>,
    ): AccountState => ({
      ...state,
      showChooseDataAccessPolicyModal,
    }),
  ),

  on(
    AccountActions.selectDataAccessPolicy,
    (state: AccountState, { selectedDataAccessPolicy }: ReturnType<typeof AccountActions.selectDataAccessPolicy>): AccountState => ({
      ...state,
      selectedDataAccessPolicy,
    }),
  ),

  on(
    AccountActions.getAssignPolicyPreviewAndSave,
    (state: AccountState, { selectedAccounts }: ReturnType<typeof AccountActions.getAssignPolicyPreviewAndSave>): AccountState => ({
      ...state,
      selectedAccounts,
    }),
  ),

  on(
    AccountActions.getIntelligenceRolesSuccess,
    (state: AccountState, { intelligenceRolesResponse }: ReturnType<typeof AccountActions.getIntelligenceRolesSuccess>): AccountState => ({
      ...state,
      intelligenceRolesResponse,
      intelligenceRolesLoadingStatus: LOAD_STATE.SUCCESS,
    }),
  ),

  on(
    AccountActions.getIntelligenceRolesFailure,
    (state: AccountState): AccountState => ({
      ...state,
      intelligenceRolesLoadingStatus: LOAD_STATE.FAILURE,
    }),
  ),

  on(
    AccountActions.setIntelligenceRolesSearchTerm,
    (state: AccountState, { query }: ReturnType<typeof AccountActions.setIntelligenceRolesSearchTerm>): AccountState => ({
      ...state,
      intelligenceRolesRequest: new GenericSearchRequest({
        ...state.intelligenceRolesRequest,
        searchTerms: [
          new SearchTerm({
            value: query,
            fields: [COLUMN_NAMES.byName.name],
          }),
        ],
      }),
    }),
  ),

  on(
    AccountActions.setSelectedIntelligenceRoles,
    (state: AccountState, { selectedIntelligenceRoles }: ReturnType<typeof AccountActions.setSelectedIntelligenceRoles>): AccountState => ({
      ...state,
      selectedIntelligenceRoles,
    }),
  ),

  on(
    AccountActions.toggleRemoveConfirmModal,
    (state: AccountState, { show }: ReturnType<typeof AccountActions.toggleRemoveConfirmModal>): AccountState => ({
      ...state,
      showRemoveConfirm: show,
    }),
  ),

  on(
    AccountActions.toggleReplaceConfirmModal,
    (state: AccountState, { show }: ReturnType<typeof AccountActions.toggleReplaceConfirmModal>): AccountState => ({
      ...state,
      showReplaceConfirm: show,
    }),
  ),

  on(
    AccountActions.togglePolicyWarning,
    (state: AccountState, { show }: ReturnType<typeof AccountActions.togglePolicyWarning>): AccountState => ({
      ...state,
      showPolicyWarning: show,
    }),
  ),

  on(
    AccountActions.toggleSaveWarning,
    (state: AccountState, { show }: ReturnType<typeof AccountActions.toggleSaveWarning>): AccountState => ({
      ...state,
      showSaveWarning: show,
    }),
  ),
);

/**
 * Reducer for AccountState
 * @export
 * @param {AccountState} state
 * @param {Action} action
 * @returns {AccountState}
 */
export function accountState(state: AccountState, action: Action): AccountState {
  return _reducer(state, action);
}
