<div class="automation-status">
  <ng-template #statusBadge>
    <span
      [class.badge-success]="automation.active"
      [class.ml-x4]="layoutType === LayoutType.GRID_VIEW && !automation.isSystemDisabled"
      class="badge status"
    >
      {{ (automation.active ? 'AUTOMATION_ACTIONS.ENABLED' : 'AUTOMATION_ACTIONS.DISABLED') | translate }}
    </span>
  </ng-template>
  <dpa-tooltip
    *ngIf="automation.isSystemDisabled; else statusBadge"
    [tooltipText]="automation.statusChangeMessage"
    class="disconnected"
  >
    <cds-icon
      shape="disconnect"
      size="16"
      status="danger"
    ></cds-icon>
    <ng-template [ngTemplateOutlet]="statusBadge"></ng-template>
  </dpa-tooltip>
</div>
