/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';

import { BaseFormFieldComponent } from '@ws1c/intelligence-core/components/dynamic-form/components/form-field/components/base-form-field.component';

/**
 * CheckboxFormFieldComponent
 * @export
 * @class CheckboxFormFieldComponent
 * @extends {BaseFormFieldComponent}
 */
@Component({
  selector: 'dpa-checkbox-form-field',
  templateUrl: 'checkbox-form-field.component.html',
  styleUrls: ['checkbox-form-field.component.scss'],
})
export class CheckboxFormFieldComponent extends BaseFormFieldComponent {}
