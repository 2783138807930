/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import {
  GenericSearchRequest,
  IntegrationDetails,
  IntegrationReprovisionStatus,
  LOAD_STATE,
  LoadStateByKey,
} from '@ws1c/intelligence-models';
import { IntegrationActions } from './integration.actions';
import { initialIntegrationState, IntegrationState } from './integration.state';

/**
 * Integration State Reducer
 * @type {ActionReducer<IntegrationState>}
 */
const _reducer: ActionReducer<IntegrationState> = createReducer(
  initialIntegrationState,

  on(
    IntegrationActions.getStarted,
    (state: IntegrationState): IntegrationState => ({
      ...state,
      showSetupModal: false,
      showAuthConfirmModal: true,
    }),
  ),

  on(
    IntegrationActions.setCurrentIntegration,
    (state: IntegrationState, props: ReturnType<typeof IntegrationActions.setCurrentIntegration>): IntegrationState => ({
      ...state,
      current: props.integration,
    }),
  ),

  on(
    IntegrationActions.deAuthorize,
    (state: IntegrationState): IntegrationState => ({
      ...state,
      loading: true,
    }),
  ),

  on(
    IntegrationActions.deAuthorizeSuccess,
    (state: IntegrationState): IntegrationState => ({
      ...state,
      loading: false,
      authorized: false,
      showDeauthConfirmModal: false,
    }),
  ),

  on(
    IntegrationActions.deAuthorizeFailure,
    (state: IntegrationState, { error }: ReturnType<typeof IntegrationActions.deAuthorizeFailure>): IntegrationState => ({
      ...state,
      loading: false,
      showDeauthConfirmModal: false,
      error,
    }),
  ),

  on(
    IntegrationActions.showDeauthConfirmModal,
    (
      state: IntegrationState,
      { showDeauthConfirmModal }: ReturnType<typeof IntegrationActions.showDeauthConfirmModal>,
    ): IntegrationState => ({
      ...state,
      showDeauthConfirmModal,
    }),
  ),

  on(
    IntegrationActions.authorizeSuccess,
    (state: IntegrationState): IntegrationState => ({
      ...state,
      loading: false,
      authorized: true,
      showAuthConfirmModal: false,
      integrationResultCode: undefined,
    }),
  ),

  on(
    IntegrationActions.authorizeFailure,
    (
      state: IntegrationState,
      { error, integrationResultCode, errorMessage }: ReturnType<typeof IntegrationActions.authorizeFailure>,
    ): IntegrationState => ({
      ...state,
      error,
      integrationResultCode,
      loading: false,
      showAuthConfirmModal: false,
      errorMessage,
    }),
  ),

  on(
    IntegrationActions.showSetupModal,
    (state: IntegrationState, { showSetupModal }: ReturnType<typeof IntegrationActions.showSetupModal>): IntegrationState => ({
      ...state,
      showSetupModal,
    }),
  ),

  on(
    IntegrationActions.showAuthConfirmModal,
    (state: IntegrationState, { showAuthConfirmModal }: ReturnType<typeof IntegrationActions.showAuthConfirmModal>): IntegrationState => ({
      ...state,
      showAuthConfirmModal,
    }),
  ),

  on(
    IntegrationActions.showItsmSetupModal,
    (state: IntegrationState, { showItsmSetupModal }: ReturnType<typeof IntegrationActions.showItsmSetupModal>): IntegrationState => ({
      ...state,
      showItsmSetupModal,
    }),
  ),

  on(
    IntegrationActions.getItsmStarted,
    (state: IntegrationState): IntegrationState => ({
      ...state,
      showItsmSetupModal: false,
      showItsmAuthConfirmModal: true,
    }),
  ),

  on(
    IntegrationActions.showItsmAuthConfirmModal,
    (
      state: IntegrationState,
      { showItsmAuthConfirmModal }: ReturnType<typeof IntegrationActions.showItsmAuthConfirmModal>,
    ): IntegrationState => ({
      ...state,
      showItsmAuthConfirmModal,
    }),
  ),

  on(
    IntegrationActions.showItsmDeauthConfirmModal,
    (
      state: IntegrationState,
      { showItsmDeauthConfirmModal }: ReturnType<typeof IntegrationActions.showItsmDeauthConfirmModal>,
    ): IntegrationState => ({
      ...state,
      showItsmDeauthConfirmModal,
    }),
  ),

  on(
    IntegrationActions.getUagStarted,
    (state: IntegrationState): IntegrationState => ({
      ...state,
      showUagSetupModal: false,
      showUagAuthConfirmModal: true,
    }),
  ),

  on(
    IntegrationActions.showUagSetupModal,
    (state: IntegrationState, { showUagSetupModal }: ReturnType<typeof IntegrationActions.showUagSetupModal>): IntegrationState => ({
      ...state,
      showUagSetupModal,
    }),
  ),

  on(
    IntegrationActions.showUagAuthConfirmModal,
    (
      state: IntegrationState,
      { showUagAuthConfirmModal }: ReturnType<typeof IntegrationActions.showUagAuthConfirmModal>,
    ): IntegrationState => ({
      ...state,
      showUagAuthConfirmModal,
    }),
  ),

  on(
    IntegrationActions.showUagDeauthConfirmModal,
    (
      state: IntegrationState,
      { showUagDeauthConfirmModal }: ReturnType<typeof IntegrationActions.showUagDeauthConfirmModal>,
    ): IntegrationState => ({
      ...state,
      showUagDeauthConfirmModal,
    }),
  ),

  on(
    IntegrationActions.finishAuthConfirmModalSuccess,
    (
      state: IntegrationState,
      { finishAuthConfirmModalSuccess }: ReturnType<typeof IntegrationActions.finishAuthConfirmModalSuccess>,
    ): IntegrationState => ({
      ...state,
      finishAuthConfirmModalSuccess,
    }),
  ),

  on(
    IntegrationActions.finishAuthConfirmModalFailure,
    (
      state: IntegrationState,
      { finishAuthConfirmModalFailure }: ReturnType<typeof IntegrationActions.finishAuthConfirmModalFailure>,
    ): IntegrationState => ({
      ...state,
      finishAuthConfirmModalFailure,
    }),
  ),

  on(
    IntegrationActions.showAzureSetupModal,
    (state: IntegrationState, { showAzureSetupModal }: ReturnType<typeof IntegrationActions.showAzureSetupModal>): IntegrationState => ({
      ...state,
      showAzureSetupModal,
    }),
  ),

  on(
    IntegrationActions.showDeleteAzureConnectionModal,
    (
      state: IntegrationState,
      { showDeleteAzureConnectionModal }: ReturnType<typeof IntegrationActions.showDeleteAzureConnectionModal>,
    ): IntegrationState => ({
      ...state,
      showDeleteAzureConnectionModal,
    }),
  ),

  on(
    IntegrationActions.showReauthorizeAzureConnectionModal,
    (
      state: IntegrationState,
      { showReauthorizeAzureConnectionModal }: ReturnType<typeof IntegrationActions.showReauthorizeAzureConnectionModal>,
    ): IntegrationState => ({
      ...state,
      showReauthorizeAzureConnectionModal,
    }),
  ),

  on(
    IntegrationActions.getIntegrationReprovisioningStatus,
    (state: IntegrationState): IntegrationState => ({
      ...state,
      accessIntegrationReprovisioningStatusList: [],
    }),
  ),

  on(
    IntegrationActions.updateIntegrationReprovisioningStatus,
    (state: IntegrationState, props: ReturnType<typeof IntegrationActions.updateIntegrationReprovisioningStatus>): IntegrationState => {
      const integrationReprovisionStatus: IntegrationReprovisionStatus = props.integrationReprovisionStatus;
      const integrationIdToReprovisionStatusMap: Map<string, IntegrationReprovisionStatus> = new Map(
        state.accessIntegrationReprovisioningStatusList.map((integrationReprovisionStatusItem: IntegrationReprovisionStatus) => {
          return [integrationReprovisionStatusItem.integrationId, integrationReprovisionStatusItem];
        }),
      );
      integrationIdToReprovisionStatusMap.set(integrationReprovisionStatus.integrationId, integrationReprovisionStatus);
      return {
        ...state,
        accessIntegrationReprovisioningStatusList: Array.from(integrationIdToReprovisionStatusMap.values()),
      };
    },
  ),

  on(
    IntegrationActions.setIntegrationReprovisioningStatus,
    (state: IntegrationState, props: ReturnType<typeof IntegrationActions.setIntegrationReprovisioningStatus>): IntegrationState => ({
      ...state,
      accessIntegrationReprovisioningStatusList: props.accessIntegrationReprovisioningStatusList,
    }),
  ),

  on(
    IntegrationActions.initializeIntegrationView,
    (state: IntegrationState, props: ReturnType<typeof IntegrationActions.initializeIntegrationView>): IntegrationState => ({
      ...state,
      integrationDetailViewModel: props.integrationDetailView,
    }),
  ),

  on(IntegrationActions.optIntoIntegration, (state: IntegrationState, props: { integrationDetails: IntegrationDetails }) => {
    return {
      ...state,
      integrationsOptInStatus: {
        ...state.integrationsOptInStatus,
        [props.integrationDetails.id]: LOAD_STATE.IN_FLIGHT,
      },
      loading: true,
    };
  }),

  on(IntegrationActions.optIntoIntegrationSuccess, (state: IntegrationState, props: { id: string }) => {
    return {
      ...state,
      integrationsOptInStatus: {
        ...state.integrationsOptInStatus,
        [props.id]: LOAD_STATE.SUCCESS,
      },
      loading: false,
    };
  }),

  on(IntegrationActions.optIntoIntegrationFailure, (state: IntegrationState, props: { id: string }) => {
    return {
      ...state,
      integrationsOptInStatus: {
        ...state.integrationsOptInStatus,
        [props.id]: LOAD_STATE.FAILURE,
      },
      loading: false,
    };
  }),

  on(IntegrationActions.optIntoIntegrations, (state: IntegrationState, props: { integrationDetailsList: IntegrationDetails[] }) => {
    const integrationsOptInStatus: LoadStateByKey = {};
    props.integrationDetailsList.forEach((integrationDetails: IntegrationDetails) => {
      integrationsOptInStatus[integrationDetails.id] = LOAD_STATE.NONE;
    });
    return {
      ...state,
      integrationsOptInStatus,
    };
  }),

  on(
    IntegrationActions.setActiveIntegrationDetails,
    (state: IntegrationState, props: { integrationDetails: IntegrationDetails }): IntegrationState => {
      const activeIntegrationDetails: IntegrationDetails = props.integrationDetails;
      return {
        ...state,
        activeIntegrationDetails,
        current: activeIntegrationDetails?.integration,
      };
    },
  ),

  on(IntegrationActions.setPreviewIntegrationSlideOverOpen, (state: IntegrationState, props: { isOpen: boolean }) => {
    return {
      ...state,
      isPreviewIntegrationSlideOverOpen: props.isOpen,
    };
  }),

  on(
    IntegrationActions.loadIntegrationHealthStatus,
    IntegrationActions.initializeIntegrationView,
    (state: IntegrationState): IntegrationState => ({
      ...state,
      integrationHealthReportLoading: true,
    }),
  ),

  on(
    IntegrationActions.loadIntegrationHealthStatusSuccess,
    (state: IntegrationState, props: ReturnType<typeof IntegrationActions.loadIntegrationHealthStatusSuccess>): IntegrationState => {
      const integrationHealthReport = {
        ...state.integrationHealthReport,
        [props.integration]: props.integrationHealthReport,
      };
      return {
        ...state,
        integrationHealthReport,
        integrationHealthReportLoading: false,
      };
    },
  ),

  on(
    IntegrationActions.loadIntegrationHealthStatusFailure,
    (state: IntegrationState): IntegrationState => ({
      ...state,
      integrationHealthReportLoading: false,
    }),
  ),

  on(
    IntegrationActions.loadIntegrationObjectsCounts,
    (state: IntegrationState): IntegrationState => ({
      ...state,
      integrationUsage: {
        ...state.integrationUsage,
        objectsCounts: undefined,
        objectsCountsLoading: true,
      },
    }),
  ),

  on(
    IntegrationActions.loadIntegrationObjectsCountsSuccess,
    (
      state: IntegrationState,
      { objectsCounts }: ReturnType<typeof IntegrationActions.loadIntegrationObjectsCountsSuccess>,
    ): IntegrationState => ({
      ...state,
      integrationUsage: {
        ...state.integrationUsage,
        objectsCounts,
        objectsCountsLoading: false,
      },
    }),
  ),

  on(
    IntegrationActions.loadIntegrationObjectsCountsFailure,
    (state: IntegrationState): IntegrationState => ({
      ...state,
      integrationUsage: {
        ...state.integrationUsage,
        objectsCountsLoading: false,
      },
    }),
  ),

  on(
    IntegrationActions.loadIntegrationObjects,
    (
      state: IntegrationState,
      { selectedObjectType, objectsSearchRequest }: ReturnType<typeof IntegrationActions.loadIntegrationObjects>,
    ): IntegrationState => ({
      ...state,
      integrationUsage: {
        ...state.integrationUsage,
        selectedObjectType,
        objectsSearchRequest,
        objectsLoading: true,
      },
    }),
  ),

  on(
    IntegrationActions.loadIntegrationObjectsSuccess,
    (
      state: IntegrationState,
      { objectsSearchResponse }: ReturnType<typeof IntegrationActions.loadIntegrationObjectsSuccess>,
    ): IntegrationState => ({
      ...state,
      integrationUsage: {
        ...state.integrationUsage,
        objectsSearchResponse,
        objectsLoading: false,
      },
    }),
  ),

  on(
    IntegrationActions.loadIntegrationObjectsFailure,
    (state: IntegrationState): IntegrationState => ({
      ...state,
      integrationUsage: {
        ...state.integrationUsage,
        objectsLoading: false,
      },
    }),
  ),

  on(
    IntegrationActions.changeIntegrationObjectsPagination,
    (
      state: IntegrationState,
      { pagedRequest }: ReturnType<typeof IntegrationActions.changeIntegrationObjectsPagination>,
    ): IntegrationState => ({
      ...state,
      integrationUsage: {
        ...state.integrationUsage,
        objectsSearchRequest: new GenericSearchRequest({
          ...state.integrationUsage.objectsSearchRequest,
          ...pagedRequest,
        }),
        objectsLoading: true,
      },
    }),
  ),

  on(
    IntegrationActions.refreshIntegrationObjects,
    (state: IntegrationState): IntegrationState => ({
      ...state,
      integrationUsage: {
        ...state.integrationUsage,
        objectsLoading: true,
      },
    }),
  ),

  on(
    IntegrationActions.loadIntegrationActions,
    (state: IntegrationState, { searchRequest }: ReturnType<typeof IntegrationActions.loadIntegrationActions>): IntegrationState => ({
      ...state,
      integrationActions: {
        ...state.integrationActions,
        searchRequest,
        loading: true,
      },
    }),
  ),

  on(
    IntegrationActions.loadIntegrationActionsSuccess,
    (
      state: IntegrationState,
      { searchResponse }: ReturnType<typeof IntegrationActions.loadIntegrationActionsSuccess>,
    ): IntegrationState => ({
      ...state,
      integrationActions: {
        ...state.integrationActions,
        searchResponse,
        loading: false,
      },
    }),
  ),

  on(
    IntegrationActions.loadIntegrationActionsFailure,
    (state: IntegrationState): IntegrationState => ({
      ...state,
      integrationActions: {
        ...state.integrationActions,
        loading: false,
      },
    }),
  ),

  on(
    IntegrationActions.changeIntegrationActionsPagination,
    (
      state: IntegrationState,
      { pagedRequest }: ReturnType<typeof IntegrationActions.changeIntegrationActionsPagination>,
    ): IntegrationState => ({
      ...state,
      integrationActions: {
        ...state.integrationActions,
        searchRequest: new GenericSearchRequest({
          ...state.integrationActions.searchRequest,
          ...pagedRequest,
        }),
      },
    }),
  ),
);

/**
 * Integration State Reducer
 *
 * @export
 * @param {IntegrationState} state
 * @param {Action} action
 * @returns {IntegrationState}
 */
export function integrationState(state: IntegrationState, action: Action): IntegrationState {
  return _reducer(state, action);
}
