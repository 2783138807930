/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { CustomGlobalEvent, deserialize, GenericObject, PagedRequest, SortOn } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { flatMap } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';

import { AppConfig, I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import { FeatureSelectors, UserPreferenceSelectors } from '@ws1c/intelligence-core/store';
import { MarketplaceActions, MarketplaceSelectors } from '@ws1c/intelligence-core/store/marketplace';
import {
  CLARITY_SIGNPOST_POSITION,
  COLUMN_NAMES,
  FacetType,
  LayoutType,
  MarketplaceResource,
  MarketplaceResourceActionType,
  MarketplaceResourceProperty,
  MarketplaceResourceTag,
  MarketplaceResourceType,
  MarketplaceSearchRequest,
  MarketplaceSearchResponse,
  PendoCategory,
  PendoEventMarketplaceTemplatesSelectedSource,
  PendoEventTriggeredBy,
  PendoTrackEventType,
  SearchTerm,
} from '@ws1c/intelligence-models';

/**
 * MarketplaceResourceSearchComponent
 * @export
 * @class MarketplaceResourceSearchComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-marketplace-resource-search',
  templateUrl: 'marketplace-resource-search.component.html',
  styleUrls: ['marketplace-resource-search.component.scss'],
})
export class MarketplaceResourceSearchComponent implements OnInit, OnDestroy {
  @Input() public resourceTypes: MarketplaceResourceType[] = [];
  @Input() public availableLayouts?: LayoutType[] = [LayoutType.CARD_VIEW, LayoutType.GRID_VIEW];
  @Input() public selectedLayout?: LayoutType = LayoutType.CARD_VIEW;
  @Output() public selectResource: EventEmitter<MarketplaceResource> = new EventEmitter<MarketplaceResource>();

  public isDeployDisabledForScriptAndSensor$: Observable<boolean>;
  public isLoading$: Observable<boolean>;
  public request: MarketplaceSearchRequest = new MarketplaceSearchRequest();
  public response: MarketplaceSearchResponse;
  public selectedSortOn: SortOn;
  public sortByList: SortOn[] = [];
  public selectedTags: MarketplaceResourceTag[];
  public hasDashboardPerm: boolean;
  public hasReportPerm: boolean;
  public hasWorkflowPerm: boolean;
  public showUsageCountColumn: boolean;
  public usageCountSortByField: string;
  public sub: Subscription = new Subscription();

  public readonly MarketplaceResourceActionType = MarketplaceResourceActionType;
  public readonly MarketplaceResourceType = MarketplaceResourceType;
  public readonly MarketplaceResourceProperty = MarketplaceResourceProperty;
  public readonly COLUMN_NAMES = COLUMN_NAMES;
  public readonly AppConfig = AppConfig;
  public readonly FacetType = FacetType;
  public readonly LayoutType = LayoutType;
  public readonly PendoEventMarketplaceTemplatesSelectedSource = PendoEventMarketplaceTemplatesSelectedSource;
  public readonly SIGNPOST_POSITION = CLARITY_SIGNPOST_POSITION;
  public readonly columnWidthByKey = {
    [COLUMN_NAMES.byName.actions]: 12,
    [COLUMN_NAMES.byName.name]: 20,
    [COLUMN_NAMES.byName.description]: 40,
  };

  /**
   * Creates an instance of MarketplaceResourceSearchComponent.
   * @param {I18NService} i18nService
   * @param {Store} store
   * @param {ActivatedRoute} activatedRoute
   * @param {RouterExtensions} routerExt
   * @memberof MarketplaceResourceSearchComponent
   */
  constructor(
    private i18nService: I18NService,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private routerExt: RouterExtensions,
  ) {
    this.sortByList = [
      new SortOn({
        by: COLUMN_NAMES.byName.modified_at,
        label: this.i18nService.translate('COMMON_ACTIONS.LAST_MODIFIED_NEWEST'),
        reverse: true,
      }),
      new SortOn({
        by: COLUMN_NAMES.byName.modified_at,
        label: this.i18nService.translate('COMMON_ACTIONS.LAST_MODIFIED_OLDEST'),
        reverse: false,
      }),
      new SortOn({
        by: COLUMN_NAMES.byName.name,
        label: this.i18nService.translate('COMMON_ACTIONS.SORT_A_TO_Z'),
        reverse: false,
      }),
      new SortOn({
        by: COLUMN_NAMES.byName.name,
        label: this.i18nService.translate('COMMON_ACTIONS.SORT_Z_TO_A'),
        reverse: true,
      }),
    ];
    this.selectedSortOn = this.sortByList[0];
    this.isLoading$ = this.store.select(MarketplaceSelectors.isSearchLoading);
    this.isDeployDisabledForScriptAndSensor$ = this.store.select(UserPreferenceSelectors.isDeployDisabledForScriptAndSensor);
  }

  /**
   * ngOnInit
   * @memberof MarketplaceResourceSearchComponent
   */
  public ngOnInit() {
    if (!this.isUEMFreestyleWorkflowTemplate()) {
      this.showUsageCountColumn = true;
      this.usageCountSortByField = this.isScriptOrSensorTemplate() ? COLUMN_NAMES.byName.deployed : COLUMN_NAMES.byName.saved;
      this.sortByList.push(
        new SortOn({
          by: this.usageCountSortByField,
          label: this.i18nService.translate('MARKETPLACE.MOST_USED'),
          reverse: true,
        }),
      );
    }
    this.sub.add(
      this.activatedRoute.queryParams.subscribe((queryParams: Params) => {
        const tagsString = queryParams?.[AppConfig.QUERY_PARAM_RESOURCE_TAGS];
        this.selectedTags = JSON.parse(tagsString ?? '[]').map((tag: GenericObject) => deserialize(MarketplaceResourceTag, tag));
        this.store.dispatch(
          MarketplaceActions.searchMarketplace({
            request: new MarketplaceSearchRequest({
              resourceTypes: this.resourceTypes,
              tags: this.selectedTags,
            }),
          }),
        );
      }),
    );
    this.sub.add(
      this.store
        .select(FeatureSelectors.hasDashboardPerm)
        .subscribe((hasDashboardPerm: boolean) => (this.hasDashboardPerm = hasDashboardPerm)),
    );
    this.sub.add(
      this.store.select(FeatureSelectors.hasReportPerm).subscribe((hasReportPerm: boolean) => (this.hasReportPerm = hasReportPerm)),
    );
    this.sub.add(
      this.store.select(FeatureSelectors.hasWorkflowPerm).subscribe((hasWorkflowPerm: boolean) => (this.hasWorkflowPerm = hasWorkflowPerm)),
    );
    this.sub.add(
      this.store.select(MarketplaceSelectors.getSearchRequest).subscribe((request: MarketplaceSearchRequest) => (this.request = request)),
    );
    this.sub.add(
      this.store
        .select(MarketplaceSelectors.getSearchResponse)
        .subscribe((response: MarketplaceSearchResponse) => (this.response = response)),
    );
  }

  /**
   * ngOnDestroy
   * @memberof MarketplaceResourceSearchComponent
   */
  public ngOnDestroy() {
    this.sub.unsubscribe();
    this.store.dispatch(MarketplaceActions.clearMarketplaceSearchResponse());
  }

  /**
   * onSearchQueryChange
   * @param {string} value
   * @memberof MarketplaceResourceSearchComponent
   */
  public onSearchQueryChange(value: string) {
    this.store.dispatch(
      MarketplaceActions.searchMarketplace({
        request: new MarketplaceSearchRequest({
          ...this.request,
          searchTerm: new SearchTerm({ value }),
        }),
      }),
    );
  }

  /**
   * onSortByChange
   * @param {SortOn} sortOn
   * @memberof MarketplaceResourceSearchComponent
   */
  public onSortByChange(sortOn?: SortOn) {
    this.selectedSortOn = sortOn;
    this.store.dispatch(
      MarketplaceActions.searchMarketplace({
        request: new MarketplaceSearchRequest({
          ...this.request,
          sortOns: sortOn ? [sortOn] : [],
        }),
      }),
    );
  }

  /**
   * onPageChange
   * @param {PagedRequest} pagedRequest
   * @memberof MarketplaceResourceSearchComponent
   */
  public onPageChange(pagedRequest: PagedRequest) {
    this.store.dispatch(
      MarketplaceActions.searchMarketplace({
        request: new MarketplaceSearchRequest({
          ...this.request,
          ...pagedRequest,
        }),
      }),
    );
  }

  /**
   * onSortChange
   * @param {SortOn[]} sortOns
   * @memberof MarketplaceResourceSearchComponent
   */
  public onSortChange(sortOns: SortOn[]) {
    this.store.dispatch(
      MarketplaceActions.searchMarketplace({
        request: new MarketplaceSearchRequest({
          ...this.request,
          sortOns,
        }),
      }),
    );
  }

  /**
   * onRefresh
   * @memberof MarketplaceResourceSearchComponent
   */
  public onRefresh() {
    this.store.dispatch(
      MarketplaceActions.searchMarketplace({
        request: this.request,
      }),
    );
  }

  /**
   * onFilterTagChange
   * @param {MarketplaceResourceTag[]} tags
   * @memberof MarketplaceResourceSearchComponent
   */
  @CustomGlobalEvent.emitBefore(PendoTrackEventType, (self: MarketplaceResourceSearchComponent, tags: MarketplaceResourceTag[]) => {
    return {
      category: PendoCategory.MARKETPLACE,
      data: {
        triggeredAt: Date.now(),
        triggeredBy: PendoEventTriggeredBy.CLICK,
        type: self.resourceTypes[0],
        selectedSource: PendoEventMarketplaceTemplatesSelectedSource.FILTER,
        filterTags: flatMap(tags, (tag: MarketplaceResourceTag) => {
          if (tag.subCategories?.length) {
            return tag.subCategories;
          }
          return tag.category;
        }).toString(),
      },
    };
  })
  public onFilterTagChange(tags: MarketplaceResourceTag[]) {
    const queryParams = tags?.length ? { [AppConfig.QUERY_PARAM_RESOURCE_TAGS]: JSON.stringify(tags) } : {};
    this.routerExt.navigate([], { relativeTo: this.activatedRoute, queryParams });
  }

  /**
   * sortByFormatter
   * @param {SortOn} sortOn
   * @returns {string}
   * @memberof MarketplaceResourceSearchComponent
   */
  public sortByFormatter(sortOn: SortOn): string {
    return sortOn.label;
  }

  /**
   * trackByMarketplaceResourceId
   * @param {number} _index
   * @param {MarketplaceResource} resource
   * @returns {string}
   * @memberof MarketplaceResourceSearchComponent
   */
  public trackByMarketplaceResourceId(_index: number, resource: MarketplaceResource): string {
    return resource.id;
  }

  /**
   * onPreviewResource
   * @param {MarketplaceResource} resource
   * @memberof MarketplaceResourceSearchComponent
   */
  @CustomGlobalEvent.emitBefore(PendoTrackEventType, (self: MarketplaceResourceSearchComponent, resource: MarketplaceResource) => {
    return {
      category: PendoCategory.MARKETPLACE,
      data: {
        triggeredAt: Date.now(),
        triggeredBy: PendoEventTriggeredBy.CLICK,
        type: self.resourceTypes[0],
        selectedSource: PendoEventMarketplaceTemplatesSelectedSource.PREVIEW,
        name: resource.name,
        id: resource.id,
      },
    };
  })
  public onPreviewResource(resource: MarketplaceResource) {
    this.store.dispatch(MarketplaceActions.setPreviewResource({ resource, request: this.request, response: this.response }));
    this.store.dispatch(MarketplaceActions.setResourcePreviewVisibility({ isVisible: true }));
  }

  /**
   * onSelectResource
   * @param {MarketplaceResource} resource
   * @param {PendoEventMarketplaceTemplatesSelectedSource} _selectedSource
   * @memberof MarketplaceResourceSearchComponent
   */
  @CustomGlobalEvent.emitBefore(
    PendoTrackEventType,
    (
      self: MarketplaceResourceSearchComponent,
      resource: MarketplaceResource,
      selectedSource: PendoEventMarketplaceTemplatesSelectedSource,
    ) => {
      return {
        category: PendoCategory.MARKETPLACE,
        data: {
          triggeredAt: Date.now(),
          triggeredBy: PendoEventTriggeredBy.CLICK,
          type: self.resourceTypes[0],
          selectedSource,
          name: resource.name,
          id: resource.id,
        },
      };
    },
  )
  public onSelectResource(resource: MarketplaceResource, _selectedSource: PendoEventMarketplaceTemplatesSelectedSource) {
    this.selectResource.emit(resource);
  }

  /**
   * trackByIndex
   * @param {number} index
   * @returns {number}
   * @memberof MarketplaceResourceSearchComponent
   */
  public trackByIndex(index: number): number {
    return index;
  }

  /**
   * isAddToWorkspaceEnabled
   * @param {MarketplaceResource} resource
   * @returns {boolean}
   * @memberof MarketplaceResourceSearchComponent
   */
  public isAddToWorkspaceEnabled(resource: MarketplaceResource): boolean {
    switch (resource.resourceType) {
      case MarketplaceResourceType.AUTOMATION_TEMPLATE: {
        return this.hasWorkflowPerm;
      }
      case MarketplaceResourceType.WIDGET_TEMPLATE: {
        return this.hasDashboardPerm;
      }
      case MarketplaceResourceType.DASHBOARD_TEMPLATE: {
        return this.hasDashboardPerm;
      }
      case MarketplaceResourceType.REPORT_TEMPLATE: {
        return this.hasReportPerm;
      }
    }
    return false;
  }

  /**
   * isActionHidden
   * @param {MarketplaceResourceActionType} action
   * @returns {(resource: MarketplaceResource) => boolean}
   * @memberof MarketplaceResourceSearchComponent
   */
  public isActionHidden(action: MarketplaceResourceActionType): (resource: MarketplaceResource) => boolean {
    switch (action) {
      case MarketplaceResourceActionType.ADD_TO_WORKSPACE: {
        return (resource: MarketplaceResource) => !resource.showAddToWorkspaceButton;
      }
      case MarketplaceResourceActionType.DEPLOY: {
        return (resource: MarketplaceResource) => !resource.showDeployButton;
      }
      case MarketplaceResourceActionType.DOWNLOAD: {
        return (resource: MarketplaceResource) => !resource.showDownloadButton;
      }
    }
  }

  /**
   * isAddToWorkspaceActionDisabled
   * @returns {(resource: MarketplaceResource) => boolean}
   * @memberof MarketplaceResourceSearchComponent
   */
  public isAddToWorkspaceActionDisabled(): (resource: MarketplaceResource) => boolean {
    return (resource: MarketplaceResource) => !this.isAddToWorkspaceEnabled(resource);
  }

  /**
   * isScriptOrSensorTemplate
   * @returns {boolean}
   * @memberof MarketplaceResourceSearchComponent
   */
  public isScriptOrSensorTemplate(): boolean {
    return (
      this.resourceTypes.includes(MarketplaceResourceType.SCRIPT_TEMPLATE) ||
      this.resourceTypes.includes(MarketplaceResourceType.SENSOR_TEMPLATE)
    );
  }

  /**
   * isUEMFreestyleWorkflowTemplate
   * @returns {boolean}
   * @memberof MarketplaceResourceSearchComponent
   */
  public isUEMFreestyleWorkflowTemplate(): boolean {
    return this.resourceTypes.includes(MarketplaceResourceType.UEM_FREESTYLE_WORKFLOW_TEMPLATE);
  }
}
