/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Connector, ConnectorActionEvent, ConnectorActionType, DropdownOption } from '@ws1c/intelligence-models';

/**
 * ConnectorCardComponent
 *
 * migrated from ConnectionCardComponent
 * @export
 * @class ConnectorCardComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-connector-card',
  templateUrl: 'connector-card.component.html',
  styleUrls: ['connector-card.component.scss'],
})
export class ConnectorCardComponent implements OnInit {
  @Input() public connector: Connector;
  @Input() public showActions?: boolean = false;

  @Output() public refreshClick: EventEmitter<void> = new EventEmitter();
  @Output() public actionClick: EventEmitter<ConnectorActionEvent> = new EventEmitter();

  public cardFooterDropdownOptions: DropdownOption[] = [];
  public readonly ConnectorActionType = ConnectorActionType;

  /**
   * ngOnInit
   * @memberof ConnectorCardComponent
   */
  public ngOnInit() {
    this.cardFooterDropdownOptions = [
      {
        labelKey: 'COMMON_ACTIONS.EDIT',
        isHidden: !this.connector?.allowEdit,
        action: () => this.onAction(ConnectorActionType.EDIT),
      },
      {
        labelKey: 'COMMON_ACTIONS.RENAME',
        isHidden: !this.connector?.allowRename,
        action: () => this.onAction(ConnectorActionType.RENAME),
      },
      {
        labelKey: 'COMMON_ACTIONS.DELETE',
        action: () => this.onAction(ConnectorActionType.DELETE),
        textClass: 'text-danger',
      },
    ];
  }

  /**
   * onAction
   * @param {ConnectorActionType} action
   * @memberof ConnectorCardComponent
   */
  public onAction(action: ConnectorActionType) {
    this.actionClick.emit({
      action,
      data: this.connector,
    });
  }
}
