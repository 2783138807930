/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { GenericObject, WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { iif, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import { OrgService } from '@ws1c/intelligence-core/services';
import { AlertBannerActions, CoreAppState, OrgActions, OrgSelectors, UserPreferenceActions } from '@ws1c/intelligence-core/store';
import {
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  OPT_IN_FEATURE_TO_FLAG_MAPPING,
  OptInFeatureFlag,
  OrgPreference,
  OrgSettings,
  OrgTreeNode,
  ROUTE_NAMES,
} from '@ws1c/intelligence-models';

/**
 * Handles side effects for Org Status UI
 * @export
 * @class OrgEffects
 */
@Injectable()
export class OrgEffects {
  /**
   * completeOnboardingStep$
   * @type {Observable<Action>}
   * @memberof OrgEffects
   */
  public completeOnboardingStep$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgActions.completeOnboardingStep),
      map((action: ReturnType<typeof OrgActions.completeOnboardingStep>) => {
        return OrgActions.updateOrgSettings({
          orgPreferences: {
            [action.orgPreference]: true,
          },
        });
      }),
    ),
  );

  /**
   * abortOnboardingStep$
   * @type {Observable<Action>}
   * @memberof OrgEffects
   */
  public abortOnboardingStep$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgActions.abortOnboardingStep),
      withLatestFrom(this.store.select(OrgSelectors.getOrgPreferences)),
      map(([{ orgPreference }, preferences]: [ReturnType<typeof OrgActions.abortOnboardingStep>, GenericObject]) => {
        const abortedOnboardingPreferences: OrgPreference[] = preferences?.[OrgPreference.ABORTED_ONBOARDING_PREFERENCES] ?? [];
        return OrgActions.updateOrgSettings({
          orgPreferences: {
            [OrgPreference.ABORTED_ONBOARDING_PREFERENCES]: [...abortedOnboardingPreferences, orgPreference],
          },
        });
      }),
    ),
  );

  /**
   * completeOnboardingStepSuccess$
   * @type {Observable<Action>}
   * @memberof OrgEffects
   */
  public completeOnboardingStepSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgActions.completeOnboardingStepSuccess),
      map(() => OrgActions.goToNextOnboardingStep()),
    ),
  );

  /**
   * goToNextOnboardingStep$
   * @type {Observable<Action>}
   * @memberof OrgEffects
   */
  public goToNextOnboardingStep$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrgActions.goToNextOnboardingStep),
        withLatestFrom(this.store.select(OrgSelectors.allowedNextOnboardingStepRoute)),
        tap(([_action, onboardingStepRoute]: [ReturnType<typeof OrgActions.goToNextOnboardingStep>, string]) => {
          const route: string = onboardingStepRoute || ROUTE_NAMES.WORKSPACE.HOME;
          this.routerExtensions.navigate([`/${route}`]);
        }),
      ),
    { dispatch: false },
  );

  /**
   * createOrgSettings$
   * @type {Observable<Action>}
   * @memberof OrgEffects
   */
  public createOrgSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgActions.createOrgSettings),
      switchMap(({ orgSettings }: ReturnType<typeof OrgActions.createOrgSettings>) =>
        this.orgService.createOrgSettings(orgSettings).pipe(
          mergeMap(() => [
            OrgActions.getOrgSettingsSuccess({ orgSettings }),
            OrgActions.orgPreferencesChangeSuccess({
              preferenceKeys: Object.keys(orgSettings.preferences) as OrgPreference[],
            }),
          ]),
          catchError((errorDetails: WebError) =>
            of(
              OrgActions.orgPreferencesChangeFailure({
                preferenceKeys: Object.keys(orgSettings.preferences),
                error: errorDetails,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  /**
   * getOrgSettings$
   * @type {Observable<Action>}
   * @memberof OrgEffects
   */
  public getOrgSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgActions.getOrgSettings),
      startWith(OrgActions.getOrgSettings),
      switchMap(() =>
        this.orgService.getOrgSettings().pipe(
          map((orgSettings: OrgSettings) => OrgActions.getOrgSettingsSuccess({ orgSettings })),
          catchError((error: WebError) => of(OrgActions.getOrgSettingsFailure({ error }))),
        ),
      ),
    ),
  );

  /**
   * Load org hierarchy of current Org
   * Auto load on UI init
   *
   * @type {Observable<Action>}
   * @memberof OrgEffects
   */
  public loadOrgHierarchy$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgActions.loadOrgHierarchy, UserPreferenceActions.loadUserPreferenceSuccess),
      switchMap(() => {
        return this.orgService.getLocationGroupHierachy().pipe(
          map((orgTreeNode: OrgTreeNode) => {
            return OrgActions.loadOrgHierarchySuccess({ orgTreeNode });
          }),
          catchError((error: WebError) => of(OrgActions.loadOrgHierarchyFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * orgPreferencesChangeFailure$
   * @type {Observable<Action>}
   * @memberof OrgEffects
   */
  public orgPreferencesChangeFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgActions.orgPreferencesChangeFailure),
      mergeMap((action: ReturnType<typeof OrgActions.orgPreferencesChangeFailure>) => {
        const actions: Action[] = [];
        const { preferenceKeys, error } = action;
        const errorAlertPreferenceKeysMap: Partial<
          Record<
            OrgPreference,
            {
              messageKey: string;
              target: AlertBannerTarget;
            }
          >
        > = {
          [OrgPreference.CSP_FEDERATION_ACCESS_REQUESTED]: {
            messageKey: 'ORG_PREFERENCES_ERRORS.FEDERATION_ACCESS_REQUESTED_FAILURE_MSG',
            target: AlertBannerTarget.PAGE,
          },
          [OrgPreference.SERVICE_AUTO_OPT_IN_ACKNOWLEDGED]: {
            messageKey: 'INTRO_PAGE.SERVICE_AUTO_OPT_IN_ACKNOWLEDGE_FAILURE_MSG',
            target: AlertBannerTarget.MODAL,
          },
        };
        Object.keys(errorAlertPreferenceKeysMap).forEach((orgPreferenceKey: OrgPreference) => {
          if (preferenceKeys.includes(orgPreferenceKey)) {
            actions.push(
              AlertBannerActions.showAlertBanner({
                target: errorAlertPreferenceKeysMap[orgPreferenceKey].target,
                alertType: ALERT_BANNER_TYPE.DANGER,
                message: this.i18nService.translate(errorAlertPreferenceKeysMap[orgPreferenceKey].messageKey, {
                  reason: error && error.getFullReason(),
                }),
              }),
            );
          }
        });
        return actions;
      }),
    ),
  );

  /**
   * orgPreferencesChangeSuccess$
   * @type {Observable<Action>}
   * @memberof OrgEffects
   */
  public orgPreferencesChangeSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgActions.orgPreferencesChangeSuccess),
      withLatestFrom(
        this.store.select(OrgSelectors.getOrgPreferences),
        this.store.select(OrgSelectors.isAbortingOnboardingStep),
        this.store.select(OrgSelectors.isCompletingOnboardingStep),
        this.store.select(OrgSelectors.isServiceOptInModalOpen),
      ),
      mergeMap(
        ([{ preferenceKeys }, orgPreferences, isAbortingOnboardingStep, isCompletingOnboardingStep, isServiceOptInModalOpen]: [
          ReturnType<typeof OrgActions.orgPreferencesChangeSuccess>,
          GenericObject,
          boolean,
          boolean,
          boolean,
        ]) => {
          const actions: Action[] = [];
          if (isAbortingOnboardingStep) {
            actions.push(OrgActions.abortOnboardingStepSuccess(), OrgActions.goToNextOnboardingStep());
            return actions;
          }
          if (isCompletingOnboardingStep) {
            actions.push(OrgActions.completeOnboardingStepSuccess());
          }
          if (!isServiceOptInModalOpen) {
            return actions;
          }
          const isAutoOptInAcknowledged: boolean =
            preferenceKeys.includes(OrgPreference.SERVICE_AUTO_OPT_IN_ACKNOWLEDGED) &&
            orgPreferences[OrgPreference.SERVICE_AUTO_OPT_IN_ACKNOWLEDGED];
          const isManualOptInPerformed: boolean =
            preferenceKeys.includes(OrgPreference.SERVICE_MANUAL_OPT_IN_PERFORMED) &&
            orgPreferences[OrgPreference.SERVICE_MANUAL_OPT_IN_PERFORMED];
          if (isAutoOptInAcknowledged || isManualOptInPerformed) {
            actions.push(
              OrgActions.toggleServiceOptInModal({ isOpen: false }),
              UserPreferenceActions.acknowledgeIntroPageVisitPreference(),
            );
          }
          if (isManualOptInPerformed) {
            actions.push(OrgActions.performManualOptInOfServicesComplete());
          }
          return actions;
        },
      ),
    ),
  );

  /**
   * updateOrgSettings$
   * @type {Observable<Action>}
   * @memberof OrgEffects
   */
  public updateOrgSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgActions.updateOrgSettings),
      withLatestFrom(this.store.select(OrgSelectors.getOrgPreferences)),
      switchMap(
        ([{ orgPreferences: preferencesToBeUpdated }, existingPreferences]: [
          ReturnType<typeof OrgActions.updateOrgSettings>,
          GenericObject,
        ]) => {
          const orgSettingsToBeUpdated: OrgSettings = new OrgSettings({
            preferences: {
              ...preferencesToBeUpdated,
            },
          });
          const orgSettings: OrgSettings = new OrgSettings({
            preferences: {
              ...existingPreferences,
              ...preferencesToBeUpdated,
            },
          });
          const preferenceKeys: OrgPreference[] = Object.keys(preferencesToBeUpdated) as OrgPreference[];
          return iif(
            () => Object.keys(existingPreferences).length === 0,
            of(OrgActions.createOrgSettings({ orgSettings })),
            this.orgService.updateOrgSettings(orgSettingsToBeUpdated).pipe(
              mergeMap(() => [
                OrgActions.getOrgSettingsSuccess({ orgSettings }),
                OrgActions.orgPreferencesChangeSuccess({ preferenceKeys }),
              ]),
              catchError((errorDetails: WebError) =>
                of(
                  OrgActions.orgPreferencesChangeFailure({
                    preferenceKeys,
                    error: errorDetails,
                  }),
                ),
              ),
            ),
          );
        },
      ),
    ),
  );

  /**
   * enableFeature$
   * @type {Observable<Action>}
   * @memberof OrgEffects
   */
  public enableFeature$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgActions.enableFeature),
      withLatestFrom(this.store.select(OrgSelectors.getCurrentOptingFeature)),
      switchMap(([{ sendEmail = false }, feature]: [{ sendEmail?: boolean }, OptInFeatureFlag]) => {
        return this.orgService.enableOrgFeature(feature, sendEmail).pipe(
          mergeMap(() => [
            OrgActions.enableFeatureSuccess(),
            OrgActions.closeConfirmEnableFeatureModal(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              autoDismiss: true,
              message: this.i18nService.translate(`FEATURE.OPT_IN.${feature.toUpperCase()}_SUCCESS`),
            }),
          ]),
          catchError(() => [
            OrgActions.enableFeatureFailure(),
            AlertBannerActions.showAlertBanner({
              target: AlertBannerTarget.MODAL,
              alertType: ALERT_BANNER_TYPE.DANGER,
              message: this.i18nService.translate(`FEATURE.OPT_IN.${feature.toUpperCase()}_FAILURE`),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * enableFeatureSuccess$
   * @type {Observable<Action>}
   * @memberof OrgEffects
   */
  public enableFeatureSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgActions.enableFeatureSuccess),
      withLatestFrom(this.store.select(OrgSelectors.getCurrentOptingFeature)),
      switchMap(([_props, feature]: [GenericObject, OptInFeatureFlag]) => {
        return of(
          UserPreferenceActions.updateOptinFeatureFlag({
            flagName: OPT_IN_FEATURE_TO_FLAG_MAPPING[feature],
            value: true,
          }),
        );
      }),
    ),
  );

  /**
   * disableFeature$
   * @type {Observable<Action>}
   * @memberof OrgEffects
   */
  public disableFeature$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgActions.disableFeature),
      withLatestFrom(this.store.select(OrgSelectors.getCurrentOptingFeature), this.routerExtensions.url$),
      switchMap(([_props, feature, navigationUrl]: [GenericObject, OptInFeatureFlag, string]) => {
        const actions: Action[] = [
          OrgActions.disableFeatureSuccess(),
          OrgActions.closeConfirmDisableFeatureModal(),
          AlertBannerActions.showAlertBanner({
            alertType: ALERT_BANNER_TYPE.SUCCESS,
            autoDismiss: true,
            message: this.i18nService.translate(`FEATURE.OPT_OUT.${feature.toUpperCase()}_SUCCESS`),
          }),
        ];
        if (navigationUrl.match(ROUTE_NAMES.INTEGRATIONS.INBOUND_CONNECTORS_VIEW)) {
          actions.push(OrgActions.disableFeatureFromIntegrationViewSuccess());
        }
        return this.orgService.disableOrgFeature(feature).pipe(
          mergeMap(() => actions),
          catchError(() => [
            OrgActions.disableFeatureFailure(),
            AlertBannerActions.showAlertBanner({
              target: AlertBannerTarget.MODAL,
              alertType: ALERT_BANNER_TYPE.DANGER,
              message: this.i18nService.translate(`FEATURE.OPT_OUT.${feature.toUpperCase()}_FAILURE`),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * disableFeatureSuccess$
   * @type {Observable<Action>}
   * @memberof OrgEffects
   */
  public disableFeatureSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgActions.disableFeatureSuccess),
      withLatestFrom(this.store.select(OrgSelectors.getCurrentOptingFeature)),
      switchMap(([_props, feature]: [GenericObject, OptInFeatureFlag]) => {
        return of(
          UserPreferenceActions.updateOptinFeatureFlag({
            flagName: OPT_IN_FEATURE_TO_FLAG_MAPPING[feature],
            value: false,
          }),
        );
      }),
    ),
  );

  /**
   * disableFeatureFromIntegrationViewSuccess$
   * @type {Observable<Action>}
   * @memberof OrgEffects
   */
  public disableFeatureFromIntegrationViewSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrgActions.disableFeatureFromIntegrationViewSuccess),
        tap(() => this.routerExtensions.navigate([`/${ROUTE_NAMES.INTEGRATIONS.INBOUND_CONNECTORS}`])),
      ),
    { dispatch: false },
  );

  /**
   * Creates an instance of OrgEffects.
   * @param {Actions} actions$
   * @param {I18NService} i18nService
   * @param {OrgService} orgService
   * @param {RouterExtensions} routerExtensions - Router extensions instance
   * @param {Store<CoreAppState>} store
   * @memberof OrgEffects
   */
  constructor(
    private actions$: Actions,
    private i18nService: I18NService,
    private orgService: OrgService,
    private routerExtensions: RouterExtensions,
    private store: Store<CoreAppState>,
  ) {}
}
