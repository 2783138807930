/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import {
  CheckboxFormFieldComponent,
  ColumnTextareaFormFieldComponent,
  DefaultFormFieldComponent,
  EmailMultiFormFieldComponent,
  ListFormFieldComponent,
  LookupFormFieldComponent,
  PasswordFormFieldComponent,
  RadioFormFieldComponent,
  ReadOnlyFormFieldComponent,
  RichTextFormFieldComponent,
  TextareaFormFieldComponent,
  UrlFormFieldComponent,
} from './components';
import { FormFieldComponent } from './form-field.component';

export const FORM_FIELD_COMPONENTS: any[] = [
  FormFieldComponent,
  TextareaFormFieldComponent,
  EmailMultiFormFieldComponent,
  CheckboxFormFieldComponent,
  ColumnTextareaFormFieldComponent,
  PasswordFormFieldComponent,
  RichTextFormFieldComponent,
  RadioFormFieldComponent,
  ListFormFieldComponent,
  DefaultFormFieldComponent,
  UrlFormFieldComponent,
  LookupFormFieldComponent,
  ReadOnlyFormFieldComponent,
];
