/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { LookupVariable, MetaFormFieldPresentationType } from '@ws1c/intelligence-models';

/**
 * Create a textarea that allows subbing in variables
 * @export
 * @class LookupVariableInputComponent
 * @implements {ControlValueAccessor}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-lookup-variable-input',
  templateUrl: 'lookup-variable-input.component.html',
  styleUrls: ['lookup-variable-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: LookupVariableInputComponent,
      multi: true,
    },
  ],
})
export class LookupVariableInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() public presentationType: MetaFormFieldPresentationType;
  @Input() public placeholderText?: string = '';
  @Input() public lookupVariables: LookupVariable[];
  @Input() public id: string;
  @Input() public required?: boolean = false;
  @Input() public disabledField?: boolean = false;
  @ViewChild('input') public input: ElementRef;
  @ViewChild('textArea') public textArea: ElementRef;

  public sub: Subscription;
  public textFormControl$: BehaviorSubject<UntypedFormControl> = new BehaviorSubject<UntypedFormControl>(new UntypedFormControl());

  /**
   * ngOnInit
   * @memberof LookupVariableInputComponent
   */
  public ngOnInit() {
    this.sub = this.textFormControl$
      .pipe(
        tap((textFormControl: UntypedFormControl) => {
          if (this.disabledField) {
            textFormControl.disable();
          }
        }),
        switchMap((textFormControl: UntypedFormControl) => textFormControl.valueChanges),
      )
      .subscribe((text: string) => this._onChange(text));
  }

  /**
   * _onChange this is overwritten by registerOnChange
   * @type {string} text
   * @memberof LookupVariableInputComponent
   */
  public _onChange: (text: string) => void = (_text: string) => {};

  /**
   * ngOnDestroy
   * @memberof LookupVariableInputComponent
   */
  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /**
   * writeValue
   * @param {string} text
   * @memberof LookupVariableInputComponent
   */
  public writeValue(text: string) {
    this.textFormControl$.next(new UntypedFormControl(text));
  }

  /**
   * registerOnChange
   * @param {(text: string) => void} onChange - emit equivalent for ControlValueAccessors
   * @memberof LookupVariableInputComponent
   */
  public registerOnChange(onChange: (text: string) => void) {
    this._onChange = onChange;
  }

  /**
   * registerOnTouched - part of ControlValueAccessor
   * @param {() => void} _onTouched
   * @memberof LookupVariableInputComponent
   */
  public registerOnTouched(_onTouched: () => void) {
    // intentional as part of ControlValueAccessor
  }

  /**
   * onLookupVariableSelect
   * @param {LookupVariable} lookupVariable
   * @memberof LookupVariableInputComponent
   */
  public onLookupVariableSelect(lookupVariable: LookupVariable) {
    const currentTextFormControl = this.textFormControl$.getValue();
    const currentText: string = currentTextFormControl.value || '';
    const elementRef: ElementRef = this.isSingleLine() ? this.input : this.textArea;
    const nativeElement = elementRef.nativeElement;

    currentTextFormControl.patchValue(
      `${currentText.substring(0, nativeElement.selectionStart)}${lookupVariable.name}${currentText.substr(nativeElement.selectionEnd)}`,
    );
    nativeElement.focus();
  }

  /**
   * isSingleLine
   * @returns {boolean}
   * @memberof LookupVariableInputComponent
   */
  public isSingleLine(): boolean {
    return this.presentationType !== MetaFormFieldPresentationType.TEXTAREA;
  }
}
