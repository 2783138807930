<h3 *ngIf="showPayloadBodyHeader()">
  {{ 'CONNECTIONS.PAYLOAD_BODY' | translate }}
</h3>
<dpa-fields-meta-form
  *ngIf="isLegacy"
  [columnLookupVariables]="columnLookupVariables"
  [formGroup]="getFormGroup(FORM_TYPE.FIELDS)"
  [isEditMode]="isEditMode"
  [metaForm]="metaForm"
  [settings]="settings"
  [searchableActionsEnabled]="searchableActionsEnabled"
  [lookupMap]="lookupMap"
  [lookupMapLoading]="lookupMapLoading"
  [nestedLookups]="nestedLookups"
  (formGroupChange)="onFormGroupChange(FORM_TYPE.FIELDS, $event)"
  (lookupChoiceChange)="onLookupChoiceChange($event)"
  (onRequestLookup)="onRequestFieldLookup($event)"
></dpa-fields-meta-form>
<div
  *ngIf="isLegacy && isSectioned"
  class="pt-x4"
></div>
<dpa-sections-meta-form
  *ngIf="isSectioned && formGroup.contains(FORM_TYPE.SECTIONS)"
  [columnLookupVariables]="columnLookupVariables"
  [formGroup]="getFormGroup(FORM_TYPE.SECTIONS)"
  [isEditMode]="isEditMode"
  [metaForm]="sectionMetaForm"
  [settings]="settingsBySection"
  [searchableActionsEnabled]="searchableActionsEnabled"
  [lookupMap]="lookupMap"
  [lookupMapLoading]="lookupMapLoading"
  [nestedLookups]="nestedLookups"
  (formGroupChange)="onFormGroupChange(FORM_TYPE.SECTIONS, $event)"
  (lookupChoiceChange)="onLookupChoiceChange($event)"
  (onRequestLookup)="onRequestFieldLookup($event)"
></dpa-sections-meta-form>
