/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { gql } from 'apollo-angular';

export const CONNECTORS_SEARCH = gql`
  query ConnectorViews($connectorInput: PagedSearchRequestInput!) {
    connectorViews(input: $connectorInput) {
      paged_response {
        offset
        page_size
        total_count
      }
      connector_views {
        id
        name
        description
        status
        status_time
        managed
        modified_at
        workflows_count
        icon_link
        connector_config_id
        config_schema_id
        config_required
        setup_info_url
      }
    }
  }
`;
