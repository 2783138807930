/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';

/**
 * SidePanelMainBodyCardFooterComponent
 * @export
 * @class SidePanelMainBodyCardFooterComponent
 */
@Component({
  selector: 'dpa-side-panel-main-body-card-footer',
  template: '<ng-content></ng-content>',
})
export class SidePanelMainBodyCardFooterComponent {}
