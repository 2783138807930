/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppConfig } from '@ws1c/intelligence-common';
import {
  CoreAppState,
  UserPreferenceActions,
  UserPreferenceAssetsSelectors,
  UserPreferenceTrialInfoSelectors,
} from '@ws1c/intelligence-core/store';
import { IntroModalMode, UIPreference } from '@ws1c/intelligence-models';

/**
 * Component for the app-level trial banner
 * @export
 * @class TrialBannerComponent
 */
@Component({
  selector: 'dpa-trial-banner',
  templateUrl: 'trial-banner.component.html',
  styleUrls: ['trial-banner.component.scss'],
})
export class TrialBannerComponent {
  public TRIAL_DAYS = AppConfig.TRIAL_DAYS;
  public contactSalesUrl$: Observable<string>;
  public isDeclined$: Observable<boolean>;
  public isCountingDown$: Observable<boolean>;
  public isAlmostExpired$: Observable<boolean>;
  public isExpired$: Observable<boolean>;
  public isTrialStarted$: Observable<boolean>;
  public expiresDays$: Observable<number>;
  public alertType$: Observable<string>;

  /**
   * Creates an instance of TrialBannerComponent
   * @param {Store<CoreAppState>} store
   * @memberof TrialBannerComponent
   */
  constructor(private store: Store<CoreAppState>) {
    this.contactSalesUrl$ = this.store.select(UserPreferenceAssetsSelectors.contactSalesUrl);
    this.expiresDays$ = this.store.select(UserPreferenceTrialInfoSelectors.getExpiresDays);
    this.isDeclined$ = this.store.select(UserPreferenceTrialInfoSelectors.isTrialBannerDeclined);
    this.isCountingDown$ = this.store.select(UserPreferenceTrialInfoSelectors.isTrialBannerCountingDown);
    this.isAlmostExpired$ = this.store.select(UserPreferenceTrialInfoSelectors.isTrialBannerAlmostExpired);
    this.isExpired$ = this.store.select(UserPreferenceTrialInfoSelectors.isTrialBannerExpired);
    this.isTrialStarted$ = this.store.select(UserPreferenceTrialInfoSelectors.isTrialBannerStarted);
    this.alertType$ = this.store.select(UserPreferenceTrialInfoSelectors.getTrialBannerAlertType);
  }

  /**
   * activateTrial
   * @memberof TrialBannerComponent
   */
  public activateTrial() {
    this.store.dispatch(UserPreferenceActions.setIntroModalMode({ introModalMode: IntroModalMode.ACTIVATE_TRIAL }));
  }

  /**
   * closeBanner
   *
   * @param {boolean} isClose
   * @returns {void}
   * @memberof TrialBannerComponent
   */
  public closeBanner(isClose: boolean) {
    if (!isClose) {
      return;
    }
    this.store.dispatch(
      UserPreferenceActions.updateUISettings({
        uiSettings: {
          [UIPreference.TRIAL_BANNER_SNOOZE_START_TIME]: Date.now(),
        },
      }),
    );
  }

  /**
   * update user preference flag
   * @memberof TrialBannerComponent
   */
  public dismissExpiredBanner() {
    this.store.dispatch(
      UserPreferenceActions.updateUISettings({
        uiSettings: {
          [UIPreference.EXPIRED_BANNER_HIDDEN]: true,
        },
      }),
    );
  }
}
