/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GenericObject } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { find } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { getCommonColumns, IntegrationMetaActions, IntegrationMetaSelectors } from '@ws1c/intelligence-core/store/integration-meta';
import { Column, getUniqueId } from '@ws1c/intelligence-models';

/**
 * ColumnAttributeSelectorComponent
 * @export
 * @implements {OnInit}
 * @implements {OnChanges}
 * @class ColumnAttributeSelectorComponent
 */
@Component({
  selector: 'dpa-column-attribute-selector',
  templateUrl: 'column-attribute-selector.component.html',
  styleUrls: ['column-attribute-selector.component.scss'],
})
export class ColumnAttributeSelectorComponent implements OnInit, OnChanges {
  @Input() public entity: string;
  @Input() public integration: string;
  @Input() public integrations: string[];
  @Input() public selectedColumnName: string;
  @Input() public isCrossCategory?: boolean = false;
  @Output() public selectedColumnChange = new EventEmitter<Column>();

  public categoryId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public selectedColumnName$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public selectedColumn$: Observable<Column> = new Observable<Column>(undefined);
  public availableColumns$: Observable<Column[]>;

  /**
   * constructor
   * @param {Store<CoreAppState>} store
   * @memberof ColumnAttributeSelectorComponent
   */
  constructor(private store: Store<CoreAppState>) {}

  /**
   * ngOnInit
   * @memberof ColumnAttributeSelectorComponent
   */
  public ngOnInit() {
    this.availableColumns$ = combineLatest([
      this.categoryId$,
      this.store.select(IntegrationMetaSelectors.getColumnsByCategoryId),
      this.store.select(IntegrationMetaSelectors.getCrossCategoryColumns),
    ]).pipe(
      map(([_categoryId, columnsByCategoryId, crossCategoryColumns]: [string, Record<string, Column[]>, Record<string, Column[]>]) => {
        const colMap: GenericObject = {};
        let id: string;
        this.integrations?.forEach((integration: string) => {
          id = getUniqueId(integration, this.entity);
          colMap[id] = this.isCrossCategory ? crossCategoryColumns[id] : columnsByCategoryId[id];
        });
        return this.isValidCategoryMap(colMap) ? getCommonColumns(colMap) : [];
      }),
    );
    this.selectedColumn$ = combineLatest([this.availableColumns$, this.selectedColumnName$]).pipe(
      map(([availableColumns, selectedColumnName]: [Column[], string]) => {
        return find(availableColumns, (column: Column) => column.attributeName === selectedColumnName);
      }),
    );
  }

  /**
   * isValidCategoryMap
   * @param {GenericObject} colMap
   * @returns {boolean}
   * @memberof ColumnAttributeSelectorComponent
   */
  public isValidCategoryMap(colMap: GenericObject): boolean {
    return Object.values(colMap).every(Boolean);
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof ColumnAttributeSelectorComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if ((changes.entity || changes.integration) && this.integration && this.entity) {
      this.integrations?.forEach((integration) => {
        const categoryId = getUniqueId(integration, this.entity);
        this.store.dispatch(
          IntegrationMetaActions.loadColumnsQuietly({
            categoryId,
            isCrossCategory: this.isCrossCategory,
          }),
        );
        this.categoryId$.next(categoryId);
      });
    }
    if (changes.selectedColumnName) {
      this.selectedColumnName$.next(this.selectedColumnName);
    }
  }

  /**
   * onSelectColumn
   * @param {Column} column
   * @memberof ColumnAttributeSelectorComponent
   */
  public onSelectColumn(column: Column) {
    this.selectedColumnChange.emit(column);
  }
}
