/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { WorkspaceOneMtdDashboardActions } from './workspace-one-mtd-dashboard.actions';
import { initialWorkspaceOneMtdDashboardState, WorkspaceOneMtdDashboardState } from './workspace-one-mtd-dashboard.state';

const _reducer: ActionReducer<WorkspaceOneMtdDashboardState> = createReducer(
  initialWorkspaceOneMtdDashboardState,
  on(
    WorkspaceOneMtdDashboardActions.setTrendDateRange,
    (
      state: WorkspaceOneMtdDashboardState,
      { trendDateRange }: ReturnType<typeof WorkspaceOneMtdDashboardActions.setTrendDateRange>,
    ): WorkspaceOneMtdDashboardState => ({
      ...state,
      trendDateRange,
    }),
  ),
);

/**
 * workspaceOneMtdDashboardState
 * @export
 * @param {WorkspaceOneMtdDashboardState} state
 * @param {Action} action
 * @returns {WorkspaceOneMtdDashboardState}
 */
export function workspaceOneMtdDashboardState(state: WorkspaceOneMtdDashboardState, action: Action): WorkspaceOneMtdDashboardState {
  return _reducer(state, action);
}
