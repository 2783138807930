/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { TrendSpan } from '@dpa/ui-common';
import moment, { DurationInputArg2 } from 'moment';

import { DevicesDashboardConfig, TrendDateRange } from '@ws1c/intelligence-models';

/**
 * getNewTargetTrendDateRange
 * @export
 * @param {string} unit
 * @param {number} range
 * @param {number} date
 * @returns  {TrendDateRange}
 */
export function getNewTargetTrendDateRange(unit: string, range: number, date: number): TrendDateRange {
  const unitLoweCase = unit.toLowerCase() as DurationInputArg2;
  const start = moment(date).subtract(range, unitLoweCase);
  const end = moment(date).add(range + 1, unitLoweCase);
  const diff = end.diff(start, unitLoweCase);
  const duration =
    diff > DevicesDashboardConfig.TIMELINE_MAX_DATA_POINTS ? Math.ceil(diff / DevicesDashboardConfig.TIMELINE_MAX_DATA_POINTS) : 1;
  return new TrendDateRange({
    startDateMillis: start.valueOf(),
    endDateMillis: end.valueOf(),
    samplingFrequency: new TrendSpan({ unit, duration }),
  });
}
