<div class="field-container">
  <div class="field">
    <ng-template dpaHostContainer></ng-template>
  </div>
  <dpa-tooltip
    *ngIf="metadata?.note"
    [attr.aria-label]="metadata.note"
    [tooltipText]="metadata.note"
    role="tooltip"
    tabindex="0"
  >
    <cds-icon
      shape="info-circle"
      size="24"
      class="mt-x4"
    ></cds-icon>
  </dpa-tooltip>
</div>
