/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';

/**
 * SidePanelMainBodyCardBlockComponent
 * @export
 * @class SidePanelMainBodyCardBlockComponent
 */
@Component({
  selector: 'dpa-side-panel-main-body-card-block',
  template: '<ng-content></ng-content>',
})
export class SidePanelMainBodyCardBlockComponent {}
