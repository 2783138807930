/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { createAction } from '@ngrx/store';

/**
 * Actions related User Information
 * @export
 * @class UserActions
 */
@Injectable()
export class UserActions {
  public static readonly CATEGORY = '[USER]';

  private static type = (actionName: string): string => `${UserActions.CATEGORY} ${actionName}`;

  public static renewSession = createAction(UserActions.type('RENEW_SESSION'));
  public static renewSessionSuccess = createAction(UserActions.type('RENEW_SESSION_SUCCESS'));
  public static renewSessionFailure = createAction(UserActions.type('RENEW_SESSION_FAILURE'));

  public static silentLogout = createAction(UserActions.type('SILENT_LOGOUT'));
  public static silentLogoutSuccess = createAction(UserActions.type('SILENT_LOGOUT_SUCCESS'));
  public static silentLogoutFailure = createAction(UserActions.type('SILENT_LOGOUT_FAILURE'));

  public static logout = createAction(UserActions.type('LOGOUT'));
}
