/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { ActionReducerMap } from '@ngrx/store';

import { coreAppReducer, CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { accountState } from './account/account.reducer';
import { AccountState } from './account/account.state';
import { appLoadsState } from './app-loads/app-loads.reducer';
import { AppLoadsState } from './app-loads/app-loads.state';
import { connectionState } from './connection/connection.reducer';
import { ConnectionState } from './connection/connection.state';
import { standardDashboardReducers, StandardDashboardState } from './dashboard/standard-dashboard.store';
import { dataAccessPolicyState } from './data-access-policy/data-access-policy.reducer';
import { DataAccessPolicyState } from './data-access-policy/data-access-policy.state';
import { dataExplorerState } from './data-explorer/data-explorer.reducer';
import { DataExplorerState } from './data-explorer/data-explorer.state';
import { globalSearchState } from './global-search/global-search.reducer';
import { GlobalSearchState } from './global-search/global-search.state';
import { integrationState } from './integration/integration.reducer';
import { IntegrationState } from './integration/integration.state';
import { nonAdminState } from './non-admin/non-admin.reducer';
import { NonAdminState } from './non-admin/non-admin.state';
import { serviceAccountsState } from './service-accounts/service-accounts.reducer';
import { ServiceAccountsState } from './service-accounts/service-accounts.state';
import { systemLimitsState } from './system-limits/system-limits.reducer';
import { SystemLimitsState } from './system-limits/system-limits.state';
import { ws1BrokerState } from './ws1-broker/ws1-broker.reducer';
import { WS1BrokerState } from './ws1-broker/ws1-broker.state';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 * @export
 * @interface MerlotState
 */
export interface MerlotState extends CoreAppState {
  accountState: AccountState;
  appLoadsState: AppLoadsState;
  connectionState: ConnectionState;
  dataAccessPolicyState: DataAccessPolicyState;
  dataExplorerState: DataExplorerState;
  globalSearchState: GlobalSearchState;
  integrationState: IntegrationState;
  nonAdminState: NonAdminState;
  serviceAccountsState: ServiceAccountsState;
  standardDashboardState: StandardDashboardState;
  systemLimitsState: SystemLimitsState;
  ws1BrokerState: WS1BrokerState;
}

/**
 * Top Level Reducers
 */
export const appReducer: ActionReducerMap<MerlotState> = {
  ...coreAppReducer,
  accountState,
  appLoadsState,
  connectionState,
  dataAccessPolicyState,
  dataExplorerState,
  globalSearchState,
  integrationState,
  nonAdminState,
  serviceAccountsState,
  standardDashboardState: standardDashboardReducers,
  systemLimitsState,
  ws1BrokerState,
};
