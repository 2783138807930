/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { NavigationMenuSelectors, UserPreferenceAssetsSelectors, UserPreferenceTrialInfoSelectors } from '@ws1c/intelligence-core/store';
import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { UserPreferenceActions } from '@ws1c/intelligence-core/store/user-preference/user-preference.actions';
import { IntroModalMode, IntroType } from '@ws1c/intelligence-models';

/**
 * IntroFeatureAlertComponent
 * @export
 * @class IntroFeatureAlertComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-intro-feature-alert',
  templateUrl: 'intro-feature-alert.component.html',
  styleUrls: ['intro-feature-alert.component.scss'],
})
export class IntroFeatureAlertComponent implements OnInit {
  @Input() public introType: IntroType;
  @Input() public alertText: string;

  public isTrialNotActive$: Observable<boolean>;
  public isTrialExpired$: Observable<boolean>;
  public contactSalesUrl$: Observable<string>;

  /**
   * Creates an instance of IntroFeatureAlertComponent
   * @param {Store<CoreAppState>} store
   * @memberof IntroFeatureAlertComponent
   */
  constructor(private store: Store<CoreAppState>) {
    this.isTrialExpired$ = this.store.select(UserPreferenceTrialInfoSelectors.isTrialBannerExpired);
    this.contactSalesUrl$ = this.store.select(UserPreferenceAssetsSelectors.contactSalesUrl);
  }

  /**
   * ngOnInit
   * @memberof IntroFeatureAlertComponent
   */
  public ngOnInit() {
    this.isTrialNotActive$ = this.store.select(NavigationMenuSelectors.isTrialNotActiveForIntroType(this.introType));
  }

  /**
   * onActivateTrialBtnClick
   * @memberof IntroFeatureAlertComponent
   */
  public onActivateTrialBtnClick() {
    this.store.dispatch(UserPreferenceActions.setIntroModalMode({ introModalMode: IntroModalMode.ACTIVATE_TRIAL }));
  }
}
