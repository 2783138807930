/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector, Selector } from '@ngrx/store';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { getDefaultedTrendDate } from '@ws1c/dashboard-common/utils';
import { getFirstCounterValueFromSnapshot } from '@ws1c/intelligence-core/store/dashboard/dashboard-selector-helpers';
import { DashboardSelectors } from '@ws1c/intelligence-core/store/dashboard/dashboard.selectors';
import { filterColumnsByAttributeNames, IntegrationMetaSelectors } from '@ws1c/intelligence-core/store/integration-meta';
import {
  Column,
  COLUMN_NAMES,
  OperatingSystem,
  RuleGroup,
  StandardDashboardRequest,
  StandardWidgetSubtype,
  SuggestionFilterBy,
  SuggestionSearch,
  Trend,
  TrendDateRange,
  TrendDefinition,
  TrendResult,
} from '@ws1c/intelligence-models';
import { DEVICE_PLATFORM } from '@ws1c/intelligence-models/dashboard/device-platform.constant';
import {
  buildOsPatchesDashboardRequest,
  buildOsVersionsDashboardRequest,
  getAvailablePlatforms,
  getOsByPlatform,
  getSnapshotTrendValuesByBucketingAttribute,
  getSuggestionFilterBys,
  getVisibleOs,
} from './os-updates-dashboard-selector-helpers';
import { OsUpdatesDashboardState } from './os-updates-dashboard.state';

/**
 * OsUpdatesDashboardSelectors
 * @export
 * @class OsUpdatesDashboardSelectors
 */
export class OsUpdatesDashboardSelectors {
  /**
   * getState
   * @static
   * @param {MerlotState} state
   * @type {Selector<MerlotState, OsUpdatesDashboardState>}
   * @memberof OsUpdatesDashboardSelectors
   */
  public static getState: Selector<MerlotState, OsUpdatesDashboardState> = (state: MerlotState) =>
    state.standardDashboardState.osUpdatesDashboardState;

  /**
   * getPlatform
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof OsUpdatesDashboardSelectors
   */
  public static getPlatform: MemoizedSelector<MerlotState, string> = createSelector(
    OsUpdatesDashboardSelectors.getState,
    (state: OsUpdatesDashboardState) => state.platform,
  );

  /**
   * getPlatformHasPatchData
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof OsUpdatesDashboardSelectors
   */
  public static getPlatformHasPatchData: MemoizedSelector<MerlotState, boolean> = createSelector(
    OsUpdatesDashboardSelectors.getPlatform,
    (platform: string) => platform === DEVICE_PLATFORM.WINDOWS,
  );

  /**
   * getOsVersionsFilterRuleGroup
   * @static
   * @type {MemoizedSelector<MerlotState, RuleGroup>}
   * @memberof OsUpdatesDashboardSelectors
   */
  public static getOsVersionsFilterRuleGroup: MemoizedSelector<MerlotState, RuleGroup> = createSelector(
    OsUpdatesDashboardSelectors.getState,
    (state: OsUpdatesDashboardState) => state.versions.filterRuleGroup ?? new RuleGroup(),
  );

  /**
   * getOsVersionsTrendDateRange
   * @static
   * @type {MemoizedSelector<MerlotState, TrendDateRange>}
   * @memberof OsUpdatesDashboardSelectors
   */
  public static getOsVersionsTrendDateRange: MemoizedSelector<MerlotState, TrendDateRange> = createSelector(
    OsUpdatesDashboardSelectors.getState,
    (state: OsUpdatesDashboardState) => getDefaultedTrendDate(state.versions.trendDateRange),
  );

  /**
   * getOsVersionsDashboardRequest
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardRequest>}
   * @memberof OsUpdatesDashboardSelectors
   */
  public static getOsVersionsDashboardRequest: MemoizedSelector<MerlotState, StandardDashboardRequest> = createSelector(
    OsUpdatesDashboardSelectors.getPlatform,
    OsUpdatesDashboardSelectors.getOsVersionsFilterRuleGroup,
    OsUpdatesDashboardSelectors.getOsVersionsTrendDateRange,
    buildOsVersionsDashboardRequest,
  );

  /**
   * getOsPatchesFilterRuleGroup
   * @static
   * @type {MemoizedSelector<MerlotState, RuleGroup>}
   * @memberof OsUpdatesDashboardSelectors
   */
  public static getOsPatchesFilterRuleGroup: MemoizedSelector<MerlotState, RuleGroup> = createSelector(
    OsUpdatesDashboardSelectors.getState,
    (state: OsUpdatesDashboardState) => state.patches.filterRuleGroup ?? new RuleGroup(),
  );

  /**
   * getOsPatchesTrendDateRange
   * @static
   * @type {MemoizedSelector<MerlotState, TrendDateRange>}
   * @memberof OsUpdatesDashboardSelectors
   */
  public static getOsPatchesTrendDateRange: MemoizedSelector<MerlotState, TrendDateRange> = createSelector(
    OsUpdatesDashboardSelectors.getState,
    (state: OsUpdatesDashboardState) => getDefaultedTrendDate(state.patches.trendDateRange),
  );

  /**
   * getOsPatchesDashboardRequest
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardRequest>}
   * @memberof OsUpdatesDashboardSelectors
   */
  public static getOsPatchesDashboardRequest: MemoizedSelector<MerlotState, StandardDashboardRequest> = createSelector(
    OsUpdatesDashboardSelectors.getPlatform,
    OsUpdatesDashboardSelectors.getOsPatchesFilterRuleGroup,
    OsUpdatesDashboardSelectors.getOsPatchesTrendDateRange,
    buildOsPatchesDashboardRequest,
  );

  /**
   * getOsSummaryDeviceCountByPlatform
   * @static
   * @type {MemoizedSelector<MerlotState, Record<string, number>>}
   * @memberof OsUpdatesDashboardSelectors
   */
  public static getOsSummaryDeviceCountByPlatform: MemoizedSelector<MerlotState, Record<string, number>> = createSelector(
    DashboardSelectors.getStandardDashboardByType(StandardWidgetSubtype.OS_SUMMARY_DEVICES_BY_PLATFORM),
    getSnapshotTrendValuesByBucketingAttribute,
  );

  /**
   * getOsSummaryVersionCountByPlatform
   * @static
   * @type {MemoizedSelector<MerlotState, Record<string, number>>}
   * @memberof OsUpdatesDashboardSelectors
   */
  public static getOsSummaryVersionCountByPlatform: MemoizedSelector<MerlotState, Record<string, number>> = createSelector(
    DashboardSelectors.getStandardDashboardByType(StandardWidgetSubtype.OS_SUMMARY_VERSIONS_BY_PLATFORM),
    getSnapshotTrendValuesByBucketingAttribute,
  );

  /**
   * getSuggestionSearch
   * @static
   * @type {MemoizedSelector<MerlotState, SuggestionSearch>}
   * @memberof OsUpdatesDashboardSelectors
   */
  public static getSuggestionSearch: MemoizedSelector<MerlotState, SuggestionSearch> = createSelector(
    OsUpdatesDashboardSelectors.getState,
    (state: OsUpdatesDashboardState) => state.suggestionSearch,
  );

  /**
   * getAvailablePlatforms
   * @static
   * @type {MemoizedSelector<MerlotState, string[]>}
   * @memberof OsUpdatesDashboardSelectors
   */
  public static getAvailablePlatforms: MemoizedSelector<MerlotState, string[]> = createSelector(
    IntegrationMetaSelectors.getSuggestionValuesBySuggestionSearch,
    OsUpdatesDashboardSelectors.getSuggestionSearch,
    getAvailablePlatforms,
  );

  /**
   * getOsByPlatform
   * @static
   * @type {MemoizedSelector<MerlotState, Record<string, OperatingSystem>>}
   * @memberof OsUpdatesDashboardSelectors
   */
  public static getOsByPlatform: MemoizedSelector<MerlotState, Record<string, OperatingSystem>> = createSelector(
    OsUpdatesDashboardSelectors.getAvailablePlatforms,
    OsUpdatesDashboardSelectors.getOsSummaryDeviceCountByPlatform,
    OsUpdatesDashboardSelectors.getOsSummaryVersionCountByPlatform,
    getOsByPlatform,
  );

  /**
   * getVisibleOs
   * @static
   * @type {MemoizedSelector<MerlotState, OperatingSystem[]>}
   * @memberof OsUpdatesDashboardSelectors
   */
  public static getVisibleOs: MemoizedSelector<MerlotState, OperatingSystem[]> = createSelector(
    OsUpdatesDashboardSelectors.getOsByPlatform,
    getVisibleOs,
  );

  /**
   * getOsVersionsCount
   * @static
   * @type {MemoizedSelector<MerlotState, number>}
   * @memberof OsUpdatesDashboardSelectors
   */
  public static getOsVersionsCount: MemoizedSelector<MerlotState, number> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (standardDashboardData: Map<string, Trend>) => {
      return getFirstCounterValueFromSnapshot(standardDashboardData, StandardWidgetSubtype.OS_VERSIONS_COUNT_SUMMARY);
    },
  );

  /**
   * getOsPatchesCount
   * @static
   * @type {MemoizedSelector<MerlotState, number>}
   * @memberof OsUpdatesDashboardSelectors
   */
  public static getOsPatchesCount: MemoizedSelector<MerlotState, number> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (standardDashboardData: Map<string, Trend>) => {
      return getFirstCounterValueFromSnapshot(standardDashboardData, StandardWidgetSubtype.OS_WINDOWS_PATCH_COUNT);
    },
  );

  /**
   * isLoadingSuggestionSearch
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof OsUpdatesDashboardSelectors
   */
  public static isLoadingSuggestionSearch: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationMetaSelectors.getSuggestionLoadingBySuggestionSearch,
    OsUpdatesDashboardSelectors.getSuggestionSearch,
    (suggestionLoadingBySuggestionSearch: Map<SuggestionSearch, boolean>, suggestionSearch: SuggestionSearch) => {
      return suggestionLoadingBySuggestionSearch.get(suggestionSearch);
    },
  );

  /**
   * getSuggestionFilterBys
   * @static
   * @type {MemoizedSelector<MerlotState, SuggestionFilterBy[]>}
   * @memberof OsUpdatesDashboardSelectors
   */
  public static getSuggestionFilterBys: MemoizedSelector<MerlotState, SuggestionFilterBy[]> = createSelector(
    OsUpdatesDashboardSelectors.getPlatform,
    getSuggestionFilterBys,
  );

  /**
   * getOsVersionsDashboardColumns
   * @static
   * @type {MemoizedSelector<MerlotState, Column[]>}
   * @memberof OsUpdatesDashboardSelectors
   */
  public static getOsVersionsDashboardColumns: MemoizedSelector<MerlotState, Column[]> = createSelector(
    IntegrationMetaSelectors.getVisibleColumnsSortedByName,
    (allColumns: Column[]) =>
      filterColumnsByAttributeNames(allColumns, [
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_model,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_os_version,
      ]),
  );

  /**
   * getOsPatchesDashboardColumns
   * @static
   * @type {MemoizedSelector<MerlotState, Column[]>}
   * @memberof OsUpdatesDashboardSelectors
   */
  public static getOsPatchesDashboardColumns: MemoizedSelector<MerlotState, Column[]> = createSelector(
    IntegrationMetaSelectors.getVisibleColumnsSortedByName,
    (allColumns: Column[]) =>
      filterColumnsByAttributeNames(allColumns, [
        COLUMN_NAMES.byFullyQualifiedName.airwatch_windowspatch_winpatch_kb_number,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_windowspatch_winpatch_kb_subject,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_windowspatch_winpatch_update_classification,
      ]),
  );

  /**
   * getOsTrend
   * @static
   * @param {string} os
   * @returns {MemoizedSelector<MerlotState, Trend>}
   * @memberof OsUpdatesDashboardSelectors
   */
  public static getOsTrend = (os: string): MemoizedSelector<MerlotState, Trend> =>
    createSelector(DashboardSelectors.getStandardDashboardData, (standardDashboardData: Map<string, Trend>) => {
      const trend = standardDashboardData.get(StandardWidgetSubtype[StandardWidgetSubtype.OS_SUMMARY_BREAK_DOWN_BY_PLATFORM]);
      if (!trend || !trend.trendDefinition) {
        return new Trend();
      }
      return new Trend({
        trendDefinition: new TrendDefinition({
          ...trend.trendDefinition,
          bucketingAttributes: trend.trendDefinition.bucketingAttributes ? [trend.trendDefinition.bucketingAttributes[1]] : [],
        }),
        trendResults: trend.trendResults
          .filter((trendResult: TrendResult) => {
            return trendResult.bucketingAttributes[0].value === os;
          })
          .map((trendResult: TrendResult) => {
            return new TrendResult({
              ...trendResult,
              bucketingAttributes: [trendResult.bucketingAttributes[1]],
            });
          }),
      });
    });
}
