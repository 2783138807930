/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import {
  AppPlatform,
  FilterRule,
  RuleGroup,
  StandardDashboardRequest,
  StandardDashboardType,
  TrendDateRange,
} from '@ws1c/intelligence-models';

/**
 * buildStandardAppQuentinDashboardRequest
 * @param {string} appId
 * @param {string} apteligentId
 * @param {string} appPlatform
 * @param {RuleGroup} appDashboardFilterRuleGroup
 * @param {TrendDateRange} appDashboardTrendDateRange
 * @returns {StandardDashboardRequest}
 * @export
 */
export function buildStandardAppQuentinDashboardRequest(
  appId: string,
  apteligentId: string,
  appPlatform: string,
  appDashboardFilterRuleGroup: RuleGroup,
  appDashboardTrendDateRange: TrendDateRange,
): StandardDashboardRequest {
  appDashboardFilterRuleGroup.rules = appDashboardFilterRuleGroup.rules.filter((filterRule: FilterRule) => !filterRule.isMissingFields());
  return new StandardDashboardRequest(
    appPlatform === AppPlatform.ANDROID ? StandardDashboardType.APP_ERRORS_ANDROID : StandardDashboardType.APP_ERRORS_IOS,
    appDashboardFilterRuleGroup,
    appDashboardTrendDateRange,
    {
      app_package_id: appId,
      _device_platform: appPlatform,
      apteligent_id: apteligentId,
    },
  );
}
