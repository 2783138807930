<div
  *ngIf="!isBannerHidden"
  class="banner clr-col-12"
>
  <div class="clr-row">
    <div class="dpa-icon banner-left"></div>
    <div class="banner-body clr-col-sm">
      <h1>
        {{ header }}
      </h1>
      <h5>
        {{ body }}
      </h5>
    </div>
    <div>
      <button
        [attr.aria-label]="'COMMON_ACTIONS.CLOSE' | translate"
        (click)="hideBanner()"
        class="close"
        type="button"
      >
        <cds-icon
          size="30"
          aria-hidden="true"
          shape="window-close"
        ></cds-icon>
      </button>
    </div>
  </div>
  <div class="banner-footer clr-row clr-flex-items-sm-middle clr-flex-items-sm-between">
    <button
      *ngIf="linkText"
      [routerLink]="linkRoute"
      [queryParams]="linkRouteParams"
      class="btn btn-link"
    >
      {{ linkText }}
    </button>
    <div class="dont-show-checkbox">
      <input
        (click)="updateBannerUISettings()"
        (keyup.enter)="updateBannerUISettings()"
        type="checkbox"
        id="doNotShowAgain"
      />
      <label for="doNotShowAgain">
        {{ 'COMMON_MESSAGES.DONT_SHOW_AGAIN' | translate }}
      </label>
    </div>
  </div>
</div>
