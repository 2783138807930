<dpa-modal
  [modalOpen]="isOpen"
  (onModalClose)="modalClose.emit()"
>
  <dpa-modal-title>
    <span>
      {{ 'CONNECTIONS.RENAME_CONNECTOR' | translate }}
    </span>
  </dpa-modal-title>
  <div class="modal-body">
    <dpa-alert-banner [target]="ALERT_BANNER_TARGET.MODAL"></dpa-alert-banner>
    <form
      [formGroup]="connectorForm"
      class="clr-form clr-form-horizontal form"
    >
      <span class="clr-required-mark">{{ 'COMMON_MESSAGES.REQUIRED' | translate }}</span>
      <div class="clr-form-control form-group">
        <label
          for="edit-connection-label"
          class="clr-control-label required clr-required-mark"
        >
          {{ 'CUSTOM_CONNECTION.CONNECTION_NAME' | translate }}
        </label>
        <input
          type="text"
          id="edit-connection-label"
          class="clr-input edit-connection-label-input"
          formControlName="name"
          required
        />
      </div>
      <div class="clr-form-control form-group">
        <label
          for="edit-connection-description"
          class="clr-control-label clr-required-mark"
        >
          {{ 'CUSTOM_CONNECTION.CONNECTION_DESCRIPTION' | translate }}
        </label>
        <div class="clr-textarea-wrapper">
          <textarea
            id="edit-connection-description"
            class="clr-textarea"
            formControlName="description"
          ></textarea>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      (click)="modalClose.emit()"
      type="button"
      class="btn btn-link"
    >
      {{ 'COMMON_ACTIONS.CANCEL' | translate }}
    </button>
    <button
      [disabled]="connectorForm?.invalid"
      [clrLoading]="isLoading"
      (click)="confirmRename()"
      type="button"
      class="btn btn-primary"
    >
      {{ 'COMMON_ACTIONS.SAVE' | translate }}
    </button>
  </div>
</dpa-modal>
