<clr-alert
  [clrAlertType]="'warning'"
  [clrAlertClosable]="false"
>
  <clr-alert-item>
    <span class="alert-text">
      {{ alertText }}
    </span>
    <div
      *ngIf="isTrialNotActive$ | async"
      class="alert-actions"
    >
      <ng-container *ngIf="isTrialExpired$ | async; else trialNotActivated">
        <a
          [href]="contactSalesUrl$ | async"
          target="_blank"
          rel="noopener"
          class="alert-action"
        >
          {{ 'TRIAL.CONTACT_SALES' | translate }}
        </a>
      </ng-container>
      <ng-template #trialNotActivated>
        <a
          (click)="onActivateTrialBtnClick()"
          (keyup.enter)="onActivateTrialBtnClick()"
          class="alert-action"
          target="_blank"
          rel="noopener"
        >
          {{ 'TRIAL.START_TRIAL' | translate }}
        </a>
        <a
          [href]="contactSalesUrl$ | async"
          target="_blank"
          rel="noopener"
          class="alert-action"
        >
          {{ 'TRIAL.UPGRADE' | translate }}
        </a>
      </ng-template>
    </div>
  </clr-alert-item>
</clr-alert>
