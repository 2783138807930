/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { MarketplaceResource, MarketplaceResourceProperty, MarketplaceResourceType } from '@ws1c/intelligence-models';

/**
 * MarketplaceResourceMetadataComponent
 * @export
 * @class MarketplaceResourceMetadataComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-marketplace-resource-metadata',
  templateUrl: 'marketplace-resource-metadata.component.html',
  styleUrls: ['marketplace-resource-metadata.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketplaceResourceMetadataComponent implements OnChanges {
  @Input() public resource: MarketplaceResource;

  public metadata: Record<string, string>;

  public readonly MarketplaceResourceProperty = MarketplaceResourceProperty;
  public readonly MarketplaceResourceType = MarketplaceResourceType;

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof MarketplaceResourceMetadataComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.resource) {
      this.metadata = {
        chartType: this.resource?.metadataByProperty[MarketplaceResourceProperty.CHART_TYPE],
        trendMode: this.resource?.metadataByProperty[MarketplaceResourceProperty.TREND_MODE],
        reportType: this.resource?.metadataByProperty[MarketplaceResourceProperty.REPORT_TYPE],
        language: this.resource?.metadataByProperty[MarketplaceResourceProperty.LANGUAGE],
        platform: this.resource?.metadataByProperty[MarketplaceResourceProperty.PLATFORM],
      };
    }
  }
}
