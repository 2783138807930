/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector } from '@ngrx/store';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { FeatureSelectors } from '@ws1c/intelligence-core/store/rbac';
import { UserAdminAccountSelector } from '@ws1c/intelligence-core/store/user-admin-account';
import { UserPreferenceFeatureControlsSelectors, UserPreferenceSelectors } from '@ws1c/intelligence-core/store/user-preference';
import {
  Account,
  AccountGroup,
  AccountGroupSearchResponse,
  AccountRole,
  AccountSearchResponse,
  DataAccessPolicy,
  Directory,
  GenericSearchRequest,
  IntegrationGroup,
  LOAD_STATE,
  RequestStatusType,
  RolesSearchResponse,
  UserAdminAccount,
} from '@ws1c/intelligence-models';
import { AccountState } from './account.state';

/**
 * AccountSelectors
 *
 * @export
 * @class AccountSelectors
 */
export class AccountSelectors {
  public static selectAccountState = (state: MerlotState) => state.accountState;

  /**
   * isLoadingAccounts
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AccountSelectors
   */
  public static isLoadingAccounts: MemoizedSelector<MerlotState, boolean> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.isLoading,
  );

  /**
   * getAccountsSearchResponse
   * @static
   * @type {MemoizedSelector<MerlotState, AccountSearchResponse>}
   * @memberof AccountSelectors
   */
  public static getAccountsSearchResponse: MemoizedSelector<MerlotState, AccountSearchResponse> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.searchResponse,
  );

  /**
   * getAccounts
   * @static
   * @type {MemoizedSelector<MerlotState, Account[]>}
   * @memberof AccountSelectors
   */
  public static getAccounts: MemoizedSelector<MerlotState, Account[]> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => (state.searchResponse ? state.searchResponse.results : []),
  );

  /**
   * getAccountSearchRequest
   * @static
   * @type {MemoizedSelector<MerlotState, GenericSearchRequest>}
   * @memberof AccountSelectors
   */
  public static getAccountSearchRequest: MemoizedSelector<MerlotState, GenericSearchRequest> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.searchRequest,
  );

  /**
   * getSelectedAccounts
   * @static
   * @type {MemoizedSelector<MerlotState, Account[]>}
   * @memberof AccountSelectors
   */
  public static getSelectedAccounts: MemoizedSelector<MerlotState, Account[]> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.selectedAccounts,
  );

  /**
   * hasSelectedAccounts
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AccountSelectors
   */
  public static hasSelectedAccounts: MemoizedSelector<MerlotState, boolean> = createSelector(
    AccountSelectors.getSelectedAccounts,
    (accounts: Account[]) => accounts?.length > 0,
  );

  /**
   * getAccountRoles
   * @static
   * @type {MemoizedSelector<MerlotState, AccountRole[]>}
   * @memberof AccountSelectors
   */
  public static getAccountRoles: MemoizedSelector<MerlotState, AccountRole[]> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.roles,
  );

  /**
   * isLoadingUsers
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AccountSelectors
   */
  public static isLoadingUsers: MemoizedSelector<MerlotState, boolean> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.isLoadingUsers,
  );

  /**
   * isLoadingAccountGroups
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AccountSelectors
   */
  public static isLoadingAccountGroups: MemoizedSelector<MerlotState, boolean> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.isLoadingAccountGroups,
  );

  /**
   * getAccountGroups
   * @static
   * @type {MemoizedSelector<MerlotState, AccountGroupSearchResponse>}
   * @memberof AccountSelectors
   */
  public static getAccountGroups: MemoizedSelector<MerlotState, AccountGroupSearchResponse> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.accountGroupSearchResponse,
  );

  /**
   * hasAccountGroups
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AccountSelectors
   */
  public static hasAccountGroups: MemoizedSelector<MerlotState, boolean> = createSelector(
    AccountSelectors.getAccountGroups,
    (accountGroupSearchResponse: AccountGroupSearchResponse) => accountGroupSearchResponse?.results?.length > 0,
  );

  /**
   * isLoadingIntegrationGroups
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AccountSelectors
   */
  public static isLoadingIntegrationGroups: MemoizedSelector<MerlotState, boolean> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.isLoadingIntegrationGroups,
  );

  /**
   * getSelectedGroups
   * @static
   * @type {MemoizedSelector<MerlotState, AccountGroup[]>}
   * @memberof AccountSelectors
   */
  public static getSelectedGroups: MemoizedSelector<MerlotState, AccountGroup[]> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.selectedGroups,
  );

  /**
   * hasSelectedActiveGroups
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AccountSelectors
   */
  public static hasSelectedActiveGroups: MemoizedSelector<MerlotState, boolean> = createSelector(
    AccountSelectors.getSelectedGroups,
    (selectedGroups: AccountGroup[]) => selectedGroups?.some((selectedGroup: AccountGroup) => selectedGroup.active) ?? false,
  );

  /**
   * getIntegrationGroups
   * @static
   * @type {MemoizedSelector<MerlotState, IntegrationGroup[]>}
   * @memberof AccountSelectors
   */
  public static getIntegrationGroups: MemoizedSelector<MerlotState, IntegrationGroup[]> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.integrationGroups,
  );

  /**
   * getGroupByAzureId
   * @static
   * @type {MemoizedSelector<MerlotState, AccountGroup>}
   * @memberof AccountSelectors
   */
  public static getGroupByAzureId: MemoizedSelector<MerlotState, AccountGroup> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.groupByAzureId,
  );

  /**
   * getGroupSearchRequest
   * @static
   * @type {MemoizedSelector<MerlotState, GenericSearchRequest>}
   * @memberof AccountSelectors
   */
  public static getGroupSearchRequest: MemoizedSelector<MerlotState, GenericSearchRequest> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.accountGroupSearchRequest,
  );

  /**
   * intelligenceRolesLoadingStatus
   * @static
   * @type {MemoizedSelector<MerlotState, LOAD_STATE>}
   * @memberof AccountSelectors
   */
  public static intelligenceRolesLoadingStatus: MemoizedSelector<MerlotState, LOAD_STATE> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.intelligenceRolesLoadingStatus,
  );

  /**
   * getIntelligenceRolesSearchRequest
   * @static
   * @type {MemoizedSelector<MerlotState, GenericSearchRequest>}
   * @memberof AccountSelectors
   */
  public static getIntelligenceRolesSearchRequest: MemoizedSelector<MerlotState, GenericSearchRequest> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.intelligenceRolesRequest,
  );

  /**
   * getSelectedIntelligenceRoles
   * @static
   * @type {MemoizedSelector<MerlotState, AccountRole[]>}
   * @memberof AccountSelectors
   */
  public static getSelectedIntelligenceRoles: MemoizedSelector<MerlotState, AccountRole[]> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.selectedIntelligenceRoles,
  );

  /**
   * intelligenceRolesResponse
   * @static
   * @type {MemoizedSelector<MerlotState, RolesSearchResponse>}
   * @memberof AccountSelectors
   */
  public static intelligenceRolesResponse: MemoizedSelector<MerlotState, RolesSearchResponse> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.intelligenceRolesResponse,
  );

  /**
   * showChooseDataAccessPolicyModal
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AccountSelectors
   */
  public static showChooseDataAccessPolicyModal: MemoizedSelector<MerlotState, boolean> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.showChooseDataAccessPolicyModal,
  );

  /**
   * getSelectedDataAccessPolicyForAccount
   * @static
   * @type {MemoizedSelector<MerlotState, DataAccessPolicy>}
   * @memberof AccountSelectors
   */
  public static getSelectedDataAccessPolicyForAccount: MemoizedSelector<MerlotState, DataAccessPolicy> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.selectedDataAccessPolicy,
  );

  /**
   * getSelectedActiveAccounts
   * @export
   * @type {MemoizedSelector<MerlotState, Account[]>}
   * @returns {Account[]}
   * @memberof AccountSelectors
   */
  public static getSelectedActiveAccounts: MemoizedSelector<MerlotState, Account[]> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.selectedAccounts?.filter((account: Account) => account.active),
  );

  /**
   * hasSelectedActiveAccounts
   * @export
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @returns {boolean}
   * @memberof AccountSelectors
   */
  public static hasSelectedActiveAccounts: MemoizedSelector<MerlotState, boolean> = createSelector(
    AccountSelectors.getSelectedActiveAccounts,
    (accounts: Account[]) => accounts?.length > 0,
  );

  /**
   * getUsersForAddAccount
   * @static
   * @type {MemoizedSelector<MerlotState, UserAdminAccount[]>}
   * @memberof AccountSelectors
   */
  public static getUsersForAddAccount: MemoizedSelector<MerlotState, UserAdminAccount[]> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.usersForAddAccount,
  );

  /**
   * isRowSelectionEnabled
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AccountSelectors
   */
  public static isRowSelectionEnabled: MemoizedSelector<MerlotState, boolean> = createSelector(
    UserPreferenceFeatureControlsSelectors.isWorkspaceOneBrownfieldRbacEnabled,
    UserPreferenceSelectors.isBrownfieldWorkspaceOneCloudCustomer,
    UserPreferenceSelectors.isCspLoggedInUser,
    (isWorkspaceOneBrownfieldRbacEnabled: boolean, isBrownfieldWorkspaceOneCloudCustomer: boolean, isCspLoggedInUser: boolean) => {
      return (isWorkspaceOneBrownfieldRbacEnabled && isBrownfieldWorkspaceOneCloudCustomer) || !isCspLoggedInUser;
    },
  );

  /**
   * showHeaderTabsAndActions
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AccountSelectors
   */
  public static showHeaderTabsAndActions: MemoizedSelector<MerlotState, boolean> = createSelector(
    UserPreferenceSelectors.isBrownfieldWorkspaceOneCloudCustomer,
    UserPreferenceSelectors.isCspLoggedInUser,
    (isBrownfieldWorkspaceOneCloudCustomer: boolean, isCspLoggedInUser: boolean) => {
      return isBrownfieldWorkspaceOneCloudCustomer || !isCspLoggedInUser;
    },
  );

  /**
   * showInviteToCloudBtn
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AccountSelectors
   */
  public static showInviteToCloudBtn: MemoizedSelector<MerlotState, boolean> = createSelector(
    FeatureSelectors.hasGroupsUsersPermBrownfieldRbac,
    UserPreferenceSelectors.isBrownfieldWorkspaceOneCloudCustomer,
    UserPreferenceSelectors.isCspLoggedInUser,
    (hasGroupsUsersPermBrownfieldRbac: boolean, isBrownfieldWorkspaceOneCloudCustomer: boolean, isCspLoggedInUser: boolean) => {
      return hasGroupsUsersPermBrownfieldRbac && isBrownfieldWorkspaceOneCloudCustomer && isCspLoggedInUser;
    },
  );

  /**
   * disableInviteToCloudBtn
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AccountSelectors
   */
  public static disableInviteToCloudBtn: MemoizedSelector<MerlotState, boolean> = createSelector(
    AccountSelectors.hasSelectedAccounts,
    AccountSelectors.getSelectedAccounts,
    (hasSelectedAccounts: boolean, accounts: Account[]) => {
      return !hasSelectedAccounts || accounts[0].cspMigrationStatus === RequestStatusType.GRANTED;
    },
  );

  /**
   * disableEditBtn
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AccountSelectors
   */
  public static disableEditBtn: MemoizedSelector<MerlotState, boolean> = createSelector(
    AccountSelectors.hasSelectedAccounts,
    AccountSelectors.getSelectedAccounts,
    (hasSelectedAccounts: boolean, accounts: Account[]) => {
      return !hasSelectedAccounts || [RequestStatusType.REQUESTED, RequestStatusType.GRANTED].includes(accounts[0].cspMigrationStatus);
    },
  );

  /**
   * disableDeactivateBtn
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AccountSelectors
   */
  public static disableDeactivateBtn: MemoizedSelector<MerlotState, boolean> = createSelector(
    AccountSelectors.hasSelectedActiveAccounts,
    AccountSelectors.getSelectedAccounts,
    (hasSelectedActiveAccounts: boolean, accounts: Account[]) => {
      return !hasSelectedActiveAccounts || accounts[0].cspMigrationStatus === RequestStatusType.REQUESTED;
    },
  );

  /**
   * showSaveWarning
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AccountSelectors
   */
  public static showSaveWarning: MemoizedSelector<MerlotState, boolean> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.showSaveWarning,
  );

  /**
   * showPolicyWarning
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AccountSelectors
   */
  public static showPolicyWarning: MemoizedSelector<MerlotState, boolean> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.showPolicyWarning,
  );

  /**
   * showReplaceConfirm
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AccountSelectors
   */
  public static showReplaceConfirm: MemoizedSelector<MerlotState, boolean> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.showReplaceConfirm,
  );

  /**
   * showRemoveConfirm
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AccountSelectors
   */
  public static showRemoveConfirm: MemoizedSelector<MerlotState, boolean> = createSelector(
    AccountSelectors.selectAccountState,
    (state: AccountState) => state.showRemoveConfirm,
  );

  /**
   * showAccountGroupTab
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AccountSelectors
   */
  public static showAccountGroupTab: MemoizedSelector<MerlotState, boolean> = createSelector(
    FeatureSelectors.hasAccountGroupReadOnlyPerm,
    UserPreferenceFeatureControlsSelectors.isAzureAdSetupEnabled,
    UserPreferenceSelectors.isCspLoggedInUser,
    UserPreferenceSelectors.isBrownfieldWorkspaceOneCloudCustomer,
    UserAdminAccountSelector.getAzureDirectory,
    (
      accountGroupEnabled: boolean,
      isAzureAdSetupEnabled: boolean,
      isCspLoggedInUser: boolean,
      isBrownfieldWorkspaceOneCloudCustomer: boolean,
      azure: Directory,
    ) => {
      const showAccountGroupTab: boolean = accountGroupEnabled && isAzureAdSetupEnabled && !isCspLoggedInUser;
      if (!isBrownfieldWorkspaceOneCloudCustomer) {
        return showAccountGroupTab;
      }
      return showAccountGroupTab && !!azure;
    },
  );

  /**
   * showUserIdentityTab
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AccountSelectors
   */
  public static showUserIdentityTab: MemoizedSelector<MerlotState, boolean> = createSelector(
    UserPreferenceFeatureControlsSelectors.isAzureAdSetupEnabled,
    UserPreferenceSelectors.isCspLoggedInUser,
    UserPreferenceSelectors.isBrownfieldWorkspaceOneCloudCustomer,
    UserAdminAccountSelector.getAzureDirectory,
    (isAzureAdSetupEnabled: boolean, isCspLoggedInUser: boolean, isBrownfieldWorkspaceOneCloudCustomer: boolean, azure: Directory) => {
      const showUserIdentityTab: boolean = isAzureAdSetupEnabled && !isCspLoggedInUser;
      if (!isBrownfieldWorkspaceOneCloudCustomer) {
        return showUserIdentityTab;
      }
      return showUserIdentityTab && !!azure;
    },
  );
}
