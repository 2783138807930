<clr-radio-container clrInline>
  <label
    [for]="getFieldId()"
    [class.clr-required-mark]="required"
  >
    <dpa-ellipsis-with-tooltip>
      {{ metadata?.label ?? name }}
    </dpa-ellipsis-with-tooltip>
  </label>
  <clr-radio-wrapper *ngFor="let enum of enumList; index as enumIndex">
    <input
      clrRadio
      [id]="getFieldId(enumIndex)"
      [value]="enum.value"
      [checked]="formControl?.value === enum.value"
      [name]="name"
      (change)="onRadioChange($event)"
      type="radio"
    />
    <label>
      {{ enum.label }}
    </label>
  </clr-radio-wrapper>
</clr-radio-container>
