/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, PagedResponse } from '@dpa/ui-common';
import { createSelector, MemoizedSelector, Selector } from '@ngrx/store';
import { keyBy } from 'lodash-es';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { getDefaultTrendDate } from '@ws1c/dashboard-common/utils';
import { comprehensiveSpecifierAttributeValue } from '@ws1c/intelligence-core/store/dashboard/dashboard-selector-helpers';
import { DashboardSelectors } from '@ws1c/intelligence-core/store/dashboard/dashboard.selectors';
import { filterColumnsByAttributeNames, IntegrationMetaSelectors } from '@ws1c/intelligence-core/store/integration-meta';
import {
  Column,
  COLUMN_NAMES,
  ComparisonQueryResponse,
  Cve,
  CveDetailPage,
  CveSearchRequest,
  CveSearchResponse,
  DashboardConfig,
  RuleGroup,
  StandardDashboardRequest,
  StandardDashboardType,
  StandardWidgetSubtype,
  Trend,
  TrendDateRange,
  TrendResult,
} from '@ws1c/intelligence-models';
import { helpers } from './security-dashboard-selector-helpers';
import { SecurityDashboardState } from './security-dashboard.state';

export const securityDashboardHelpers = {
  ...helpers,
};

/**
 * SecurityDashboardSelectors
 * @export
 * @class SecurityDashboardSelectors
 */
export class SecurityDashboardSelectors {
  /**
   * getState
   * @param {MerlotState} state
   * @returns {SecurityDashboardState}
   * @memberOf SecurityDashboardSelectors
   */
  public static getState: Selector<MerlotState, SecurityDashboardState> = (state: MerlotState) =>
    state.standardDashboardState.securityDashboardState;

  /**
   * getDashboardFilterRuleGroup
   * @static
   * @type {MemoizedSelector<MerlotState, RuleGroup>}
   * @memberof SecurityDashboardSelectors
   */
  public static getDashboardFilterRuleGroup: MemoizedSelector<MerlotState, RuleGroup> = createSelector(
    SecurityDashboardSelectors.getState,
    (state: SecurityDashboardState) => state.filterRuleGroup ?? new RuleGroup(),
  );

  /**
   * getDashboardTrendDateRange
   * @static
   * @type {MemoizedSelector<MerlotState, TrendDateRange>}
   * @memberof SecurityDashboardSelectors
   */
  public static getDashboardTrendDateRange: MemoizedSelector<MerlotState, TrendDateRange> = createSelector(
    SecurityDashboardSelectors.getState,
    (state: SecurityDashboardState) => state.trendDateRange ?? getDefaultTrendDate(),
  );

  /**
   * getStandardDashboardRequest
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardRequest>}
   * @memberof SecurityDashboardSelectors
   */
  public static getStandardDashboardRequest: MemoizedSelector<MerlotState, StandardDashboardRequest> = createSelector(
    SecurityDashboardSelectors.getDashboardFilterRuleGroup,
    SecurityDashboardSelectors.getDashboardTrendDateRange,
    (ruleGroup: RuleGroup, trendDateRange: TrendDateRange) => {
      return new StandardDashboardRequest(StandardDashboardType.SECURITY, ruleGroup, trendDateRange);
    },
  );

  /**
   * getVulnerabilities
   * @static
   * @type {MemoizedSelector<MerlotState, GenericObject>}
   * @memberof SecurityDashboardSelectors
   */
  public static getVulnerabilities: MemoizedSelector<MerlotState, GenericObject> = createSelector(
    SecurityDashboardSelectors.getState,
    (state: SecurityDashboardState) => state.vulnerabilities,
  );

  /**
   * getVulnerabilitiesDashboardFilterRuleGroup
   * @static
   * @type {MemoizedSelector<MerlotState, RuleGroup>}
   * @memberof SecurityDashboardSelectors
   */
  public static getVulnerabilitiesDashboardFilterRuleGroup: MemoizedSelector<MerlotState, RuleGroup> = createSelector(
    SecurityDashboardSelectors.getState,
    (state: SecurityDashboardState) => state.vulnerabilities.filterRuleGroup ?? new RuleGroup(),
  );

  /**
   * getVulnerabilitiesDashboardTrendDateRange
   * @static
   * @type {MemoizedSelector<MerlotState, TrendDateRange>}
   * @memberof SecurityDashboardSelectors
   */
  public static getVulnerabilitiesDashboardTrendDateRange: MemoizedSelector<MerlotState, TrendDateRange> = createSelector(
    SecurityDashboardSelectors.getState,
    (state: SecurityDashboardState) => state.vulnerabilities.trendDateRange ?? getDefaultTrendDate(),
  );

  /**
   * getVulnerabilitiesMacOsStandardDashboardRequest
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardRequest>}
   * @memberof SecurityDashboardSelectors
   */
  public static getVulnerabilitiesMacOsStandardDashboardRequest: MemoizedSelector<MerlotState, StandardDashboardRequest> = createSelector(
    SecurityDashboardSelectors.getVulnerabilitiesDashboardFilterRuleGroup,
    SecurityDashboardSelectors.getVulnerabilitiesDashboardTrendDateRange,
    (ruleGroup: RuleGroup, trendDateRange: TrendDateRange) => {
      return new StandardDashboardRequest(StandardDashboardType.SECURITY_VULNERABILITIES_MACOS, ruleGroup, trendDateRange);
    },
  );

  /**
   * getCveInsightCardVisible
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof SecurityDashboardSelectors
   */
  public static getCveInsightCardVisible: MemoizedSelector<MerlotState, boolean> = createSelector(
    SecurityDashboardSelectors.getState,
    (state: SecurityDashboardState) => state.vulnerabilities.cveInsightCardVisible,
  );

  /**
   * getVulnerabilitiesSummaryCves
   * @static
   * @type {MemoizedSelector<MerlotState, Cve[]>}
   * @memberof SecurityDashboardSelectors
   */
  public static getVulnerabilitiesSummaryCves: MemoizedSelector<MerlotState, Cve[]> = createSelector(
    DashboardSelectors.getStandardDashboardByType(StandardWidgetSubtype.SECURITY_VULNERABILITIES_SUMMARY_LIST),
    (trend: Trend) => {
      return trend?.trendResults
        ?.filter((trendResult: TrendResult) => trendResult.bucketingAttributes[0].value !== undefined)
        .map((trendResult: TrendResult) => {
          return new Cve({
            id: trendResult.bucketingAttributes[0].value,
          });
        });
    },
  );

  /**
   * getCveTableSearchRequest
   * @static
   * @type {MemoizedSelector<MerlotState, CveSearchRequest>}
   * @memberof SecurityDashboardSelectors
   */
  public static getCveTableSearchRequest: MemoizedSelector<MerlotState, CveSearchRequest> = createSelector(
    SecurityDashboardSelectors.getState,
    (state: SecurityDashboardState) => state.vulnerabilities.cveTableSearchRequest,
  );

  /**
   * getCveTableSearchResponse
   * @static
   * @type {MemoizedSelector<MerlotState, CveSearchResponse>}
   * @memberof SecurityDashboardSelectors
   */
  public static getCveTableSearchResponse: MemoizedSelector<MerlotState, CveSearchResponse> = createSelector(
    SecurityDashboardSelectors.getState,
    (state: SecurityDashboardState) => state.vulnerabilities.cveTableSearchResponse,
  );

  /**
   * getCveSearchResponse
   * @static
   * @type {MemoizedSelector<MerlotState, CveSearchResponse>}
   * @memberof SecurityDashboardSelectors
   */
  public static getCveSearchResponse: MemoizedSelector<MerlotState, CveSearchResponse> = createSelector(
    SecurityDashboardSelectors.getState,
    (state: SecurityDashboardState) => state.vulnerabilities.cveSearchResponse,
  );

  /**
   * getCveTableData
   * @static
   * @type {MemoizedSelector<MerlotState, PagedResponse>}
   * @memberof SecurityDashboardSelectors
   */
  public static getCveTableData: MemoizedSelector<MerlotState, PagedResponse> = createSelector(
    SecurityDashboardSelectors.getCveTableSearchResponse,
    (response: CveSearchResponse) => ({ results: response?.data.slice() }) as PagedResponse,
  );

  /**
   * getCvesById
   * @static
   * @type {MemoizedSelector<MerlotState, Record<string, Cve>>}
   * @memberof SecurityDashboardSelectors
   */
  public static getCvesById: MemoizedSelector<MerlotState, Record<string, Cve>> = createSelector(
    SecurityDashboardSelectors.getCveTableSearchResponse,
    SecurityDashboardSelectors.getCveSearchResponse,
    (tableSearchResponse: CveSearchResponse, searchResponse: CveSearchResponse) =>
      keyBy([...(tableSearchResponse ? tableSearchResponse.data : []), ...(searchResponse ? searchResponse.data : [])], 'id'),
  );

  /**
   * getCveInsightCardData
   * @static
   * @type {MemoizedSelector<MerlotState, Cve[]>}
   * @memberof SecurityDashboardSelectors
   */
  public static getCveInsightCardData: MemoizedSelector<MerlotState, Cve[]> = createSelector(
    SecurityDashboardSelectors.getVulnerabilitiesSummaryCves,
    SecurityDashboardSelectors.getCvesById,
    securityDashboardHelpers.getCveInsightCardData,
  );

  /**
   * showCveInsightCard
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof SecurityDashboardSelectors
   */
  public static showCveInsightCard: MemoizedSelector<MerlotState, boolean> = createSelector(
    SecurityDashboardSelectors.getCveInsightCardVisible,
    SecurityDashboardSelectors.getCveInsightCardData,
    (isCveInsightCardVisible: boolean, cveInsightCardData: Cve[]) => isCveInsightCardVisible && cveInsightCardData.length > 0,
  );

  /**
   * getCveIdsMissingData
   * @static
   * @type {MemoizedSelector<MerlotState, string[]>}
   * @memberof SecurityDashboardSelectors
   */
  public static getCveIdsMissingData: MemoizedSelector<MerlotState, string[]> = createSelector(
    SecurityDashboardSelectors.getVulnerabilitiesSummaryCves,
    SecurityDashboardSelectors.getCvesById,
    (summaryCves: Cve[], cvesById: Record<string, Cve>) => summaryCves?.filter((cve: Cve) => !cvesById[cve.id]).map((cve: Cve) => cve.id),
  );

  /**
   * getMacOsCveInsightCardVisible
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof SecurityDashboardSelectors
   */
  public static getMacOsCveInsightCardVisible: MemoizedSelector<MerlotState, boolean> = createSelector(
    SecurityDashboardSelectors.getState,
    (state: SecurityDashboardState) => state.vulnerabilities.macOSCveInsightCardVisible,
  );

  /**
   * getVulnerabilitiesMacOsSummaryCves
   * @static
   * @type {MemoizedSelector<MerlotState, Cve[]>}
   * @memberof SecurityDashboardSelectors
   */
  public static getVulnerabilitiesMacOsSummaryCves: MemoizedSelector<MerlotState, Cve[]> = createSelector(
    DashboardSelectors.getStandardDashboardByType(StandardWidgetSubtype.MACOS_SECURITY_VULNERABILITIES_SUMMARY_LIST),
    (trend: Trend) => {
      return trend?.trendResults
        .filter((trendResult: TrendResult) => trendResult.bucketingAttributes[0].value !== undefined)
        .map((trendResult: TrendResult) => {
          return new Cve({
            id: trendResult.bucketingAttributes[0].value,
            score: trendResult.bucketingAttributes[1].value,
            devicesAffected: trendResult.counters[0].result.value,
          });
        })
        .sort((a: Cve, b: Cve) => b.score - a.score);
    },
  );

  /**
   * showMacOsCveInsightCard
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof SecurityDashboardSelectors
   */
  public static showMacOsCveInsightCard: MemoizedSelector<MerlotState, boolean> = createSelector(
    SecurityDashboardSelectors.getMacOsCveInsightCardVisible,
    SecurityDashboardSelectors.getVulnerabilitiesMacOsSummaryCves,
    (isCardVisible: boolean, cardData: Cve[]) => isCardVisible && cardData.length > 0,
  );

  /**
   * getThreatSummaryCounter
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof SecurityDashboardSelectors
   */
  public static getThreatSummaryCounter: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (standardDashboardData: Map<string, Trend>) => {
      const subTypes = [StandardWidgetSubtype.SECURITY_COMPROMISED_DEVICES_SUMMARY, StandardWidgetSubtype.SECURITY_THREAT_SUMMARY];
      return securityDashboardHelpers.getSubTypesSummary(standardDashboardData, subTypes);
    },
  );

  /**
   * getPolicyRiskCounter
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof SecurityDashboardSelectors
   */
  public static getPolicyRiskCounter: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (standardDashboardData: Map<string, Trend>) => {
      const subTypes = [StandardWidgetSubtype.SECURITY_PASSCODE_RISK_SUMMARY, StandardWidgetSubtype.SECURITY_ENCRYPTION_STATUS_SUMMARY];
      return securityDashboardHelpers.getSubTypesSummary(standardDashboardData, subTypes);
    },
  );

  /**
   * getCveDetailPage
   * @static
   * @type {MemoizedSelector<MerlotState, CveDetailPage>}
   * @memberof SecurityDashboardSelectors
   */
  public static getCveDetailPage: MemoizedSelector<MerlotState, CveDetailPage> = createSelector(
    SecurityDashboardSelectors.getState,
    (state: SecurityDashboardState) => state.vulnerabilities.cveDetailPage,
  );

  /**
   * getCvesById
   * @static
   * @type {MemoizedSelector<MerlotState, Record<string, Cve>>}
   * @memberof DashboardSelectors
   */
  public static getVulnerabilitiesCvesById: MemoizedSelector<MerlotState, Record<string, Cve>> = createSelector(
    SecurityDashboardSelectors.getVulnerabilities,
    (vulnerabilities: GenericObject) => {
      const tableSearchResponse = vulnerabilities.cveTableSearchResponse;
      const searchResponse = vulnerabilities.cveSearchResponse;
      return keyBy([...(tableSearchResponse ? tableSearchResponse.data : []), ...(searchResponse ? searchResponse.data : [])], 'id');
    },
  );

  /**
   * getWidgetDetailCveId
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailCveId: MemoizedSelector<MerlotState, string> = createSelector(
    DashboardSelectors.isCveWidgetDetailState,
    DashboardSelectors.getWidgetDetailRuleGroup,
    (isCveWidgetDetail: boolean, ruleGroup: RuleGroup) => {
      if (isCveWidgetDetail && ruleGroup) {
        return comprehensiveSpecifierAttributeValue(ruleGroup, DashboardConfig.LOOK_UP_ATTRIBUTE_FOR_WIDGET_SKIN.cveIdList);
      }
    },
  );

  /**
   * getWidgetDetailCve
   * @static
   * @type {MemoizedSelector<MerlotState, Cve>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailCve: MemoizedSelector<MerlotState, Cve> = createSelector(
    SecurityDashboardSelectors.getWidgetDetailCveId,
    SecurityDashboardSelectors.getCvesById,
    (cveId: string, cvesById: Record<string, Cve>) => {
      return cvesById?.[cveId];
    },
  );

  /**
   * getWidgetDetailMicrosoftLink
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailMicrosoftLink: MemoizedSelector<MerlotState, string> = createSelector(
    SecurityDashboardSelectors.getWidgetDetailCveId,
    (cveId: string) => DashboardConfig.getCveMicrosoftLink(cveId),
  );

  /**
   * getWidgetDetailCveNistLink
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof DashboardSelectors
   */
  public static getWidgetDetailCveNistLink: MemoizedSelector<MerlotState, string> = createSelector(
    SecurityDashboardSelectors.getWidgetDetailCveId,
    (cveId: string) => DashboardConfig.getCveNistLink(cveId),
  );

  /**
   * getDashboardColumns
   * @static
   * @type {MemoizedSelector<MerlotState, Column[]>}
   * @memberof SecurityDashboardSelectors
   */
  public static getDashboardColumns: MemoizedSelector<MerlotState, Column[]> = createSelector(
    IntegrationMetaSelectors.getVisibleColumnsSortedByName,
    (allColumns: Column[]) =>
      filterColumnsByAttributeNames(allColumns, [
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_platform,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_os_version,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_model,
      ]),
  );

  /**
   * getVulnerabilitiesMacOsDashboardColumns
   * @static
   * @type {MemoizedSelector<MerlotState, Column[]>}
   * @memberof SecurityDashboardSelectors
   */
  public static getVulnerabilitiesMacOsDashboardColumns: MemoizedSelector<MerlotState, Column[]> = createSelector(
    IntegrationMetaSelectors.getVisibleColumnsSortedByName,
    (allColumns: Column[]) =>
      filterColumnsByAttributeNames(allColumns, [
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_os_version,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_model,
      ]),
  );

  /**
   * getVulnerabilitiesWindowsDashboardColumns
   * @static
   * @type {MemoizedSelector<MerlotState, Column[]>}
   * @memberof SecurityDashboardSelectors
   */
  public static getVulnerabilitiesWindowsDashboardColumns: MemoizedSelector<MerlotState, Column[]> = createSelector(
    IntegrationMetaSelectors.getVisibleColumnsSortedByName,
    (allColumns: Column[]) =>
      filterColumnsByAttributeNames(allColumns, [
        COLUMN_NAMES.byFullyQualifiedName.airwatch_windowspatch_device_os_version,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_windowspatch_device_model,
      ]),
  );

  // Convenience selectors defined as curried functions
  public static getHistoricalTopVulnerabilites = DashboardSelectors.getStandardDashboardByType(
    StandardWidgetSubtype.SECURITY_VULNERABILITIES_TOP_BY_DEVICES_IMPACTED,
  );
  public static getHistoricalTopVulnerabilitesMacOs = DashboardSelectors.getStandardDashboardByType(
    StandardWidgetSubtype.MACOS_SECURITY_VULNERABILITIES_TOP_BY_DEVICES_IMPACTED,
  );
}
