/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, WebError } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import { EntityProp } from '@ws1c/deem-solution/models';
import {
  DashboardThresholdSummary,
  DateRangeFilters,
  DevicesDesktopErrorDetailsPage,
  EntityThresholds,
  RawThresholdAttribute,
  StandardDashboardType,
} from '@ws1c/intelligence-models';

/**
 * DevicesDashboardActions
 * @export
 * @class DevicesDashboardActions
 */
export class DevicesDashboardActions {
  public static readonly CATEGORY = '[DASHBOARD][DEVICES]';

  public static loadNetworkSignalDashboardWithMergedRequests = createAction(
    `${DevicesDashboardActions.CATEGORY} LOAD_NETWORK_SIGNAL_DASHBAORD_WITH_MERGED_REQUESTS`,
    props<{ dashboardTypeList: StandardDashboardType[] }>(),
  );

  public static unloadNetworkSignalDashboardWithMergedRequests = createAction(
    `${DevicesDashboardActions.CATEGORY} UNLOAD_NETWORK_SIGNAL_DASHBAORD_WITH_MERGED_REQUESTS`,
  );

  public static loadDashboard = createAction(
    `${DevicesDashboardActions.CATEGORY} LOAD_DASHBOARD`,
    props<{ dashboardType: StandardDashboardType; additionalParams?: GenericObject }>(),
  );

  public static loadDesktopPerformanceDashboard = createAction(`${DevicesDashboardActions.CATEGORY} LOAD_DESKTOP_PERFORMANCE_DASHBOARD`);

  public static refreshDashboard = createAction(`${DevicesDashboardActions.CATEGORY} REFRESH_DASHBOARD`);

  public static updateDashboardFilter = createAction(
    `${DevicesDashboardActions.CATEGORY} UPDATE_DASHBOARD_FILTER`,
    props<DateRangeFilters>(),
  );

  public static loadDeviceHealthThresholdsV2 = createAction(
    `${DevicesDashboardActions.CATEGORY} LOAD_DEVICE_HEALTH_THRESHOLDS_V2`,
    props<EntityProp>(),
  );

  public static loadDeviceHealthThresholdsV2Success = createAction(
    `${DevicesDashboardActions.CATEGORY} LOAD_DEVICE_HEALTH_THRESHOLDS_V2_SUCCESS`,
    props<{ data: EntityThresholds }>(),
  );

  public static loadDeviceHealthThresholdsV2Failure = createAction(
    `${DevicesDashboardActions.CATEGORY} LOAD_DEVICE_HEALTH_THRESHOLDS_V2_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static setSelectedPerformanceThresholdAttribute = createAction(
    `${DevicesDashboardActions.CATEGORY} SET_SELECTED_PERFORMANCE_THRESHOLD_ATTRIBUTE`,
    props<{ thresholdAttribute: RawThresholdAttribute }>(),
  );

  public static setIsPerformanceModalOpen = createAction(
    `${DevicesDashboardActions.CATEGORY} SET_IS_PERFORMANCE_MODAL_OPEN`,
    props<{ isOpen: boolean }>(),
  );

  public static savePerformanceThresholdSummary = createAction(
    `${DevicesDashboardActions.CATEGORY} SAVE_PERFORMANCE_THRESHOLD_SUMMARY`,
    props<{ thresholdSummary: DashboardThresholdSummary }>(),
  );

  public static savePerformanceThresholdSummarySuccess = createAction(
    `${DevicesDashboardActions.CATEGORY} SAVE_PERFORMANCE_THRESHOLD_SUMMARY_SUCCESS`,
    props<{ thresholdSummary: DashboardThresholdSummary }>(),
  );

  public static savePerformanceThresholdSummaryFailure = createAction(
    `${DevicesDashboardActions.CATEGORY} SAVE_PERFORMANCE_THRESHOLD_SUMMARY_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static loadPerformanceThresholdSummary = createAction(`${DevicesDashboardActions.CATEGORY} LOAD_PERFORMANCE_THRESHOLD_SUMMARY`);

  public static loadPerformanceThresholdSummarySuccess = createAction(
    `${DevicesDashboardActions.CATEGORY} LOAD_PERFORMANCE_THRESHOLD_SUMMARY_SUCCESS`,
    props<{ thresholdSummary: DashboardThresholdSummary }>(),
  );

  public static loadPerformanceThresholdSummaryFailure = createAction(
    `${DevicesDashboardActions.CATEGORY} LOAD_PERFORMANCE_THRESHOLD_SUMMARY_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static loadDevicesDesktopErrorDetailsDashboard = createAction(
    `${DevicesDashboardActions.CATEGORY} LOAD_DEVICES_DESKTOP_ERROR_DETAILS_DASHBOARD`,
  );

  public static updateDevicesDesktopErrorDetailsFilters = createAction(
    `${DevicesDashboardActions.CATEGORY} UPDATE_DEVICES_DESKTOP_ERROR_DETAILS_FILTERS`,
    props<DateRangeFilters>(),
  );

  public static goToDevicesDesktopErrorDetailsPage = createAction(
    `${DevicesDashboardActions.CATEGORY} GO_TO_DEVICES_DESKTOP_ERROR_DETAILS_PAGE`,
    props<{ detailsPage: DevicesDesktopErrorDetailsPage }>(),
  );

  public static initDevicesDesktopErrorDetailsPage = createAction(
    `${DevicesDashboardActions.CATEGORY} INIT_DEVICES_DESKTOP_ERROR_DETAILS_PAGE`,
    props<{ detailsPage: DevicesDesktopErrorDetailsPage }>(),
  );

  public static loadDeviceHealth = createAction(`${DevicesDashboardActions.CATEGORY} LOAD_DEVICE_HEALTH`, props<{ deviceId: string }>());

  public static loadDeviceHealthSuccess = createAction(
    `${DevicesDashboardActions.CATEGORY} LOAD_DEVICE_HEALTH_SUCCESS`,
    props<{
      deviceId: string;
      response: any;
    }>(),
  );

  public static loadDeviceHealthFailure = createAction(`${DevicesDashboardActions.CATEGORY} LOAD_DEVICE_HEALTH_FAILURE`);
}
