/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Event, EventType, Router } from '@angular/router';
import { CommonErrorCode, GenericObject, getFailureReason, SortOn, WebError, WindowService } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { GridsterItem } from 'angular-gridster2';
import { each, fromPairs, isEmpty, last, merge } from 'lodash-es';
import { bufferTime, finalize, forkJoin, Observable, of, merge as rxJsMerge, takeUntil, timer } from 'rxjs';
import { catchError, debounceTime, filter, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { AppsDashboardActions, AppsDashboardSelectors, SystemLimitsActions } from '@dpa-shared-merlot/store';
import { getUserFlowsCrumbListMultiRequest } from '@dpa-shared-merlot/store/dashboard/apps/app-deployment-dashboard-selector-helpers';
import { AppConfig, DownloadService, I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import { AccountService, TrendComposerService } from '@ws1c/intelligence-core/services';
import { DashboardService } from '@ws1c/intelligence-core/services/dashboard.service';
import { ReportMetaService } from '@ws1c/intelligence-core/services/report-meta.service';
import {
  ActiveWidgetDialogMode,
  AlertBannerActions,
  BookmarksActions,
  BookmarksSelectors,
  CoreAppState,
  DashboardActionOrigin,
  DashboardActions,
  DashboardSelectors,
  DashboardState,
  DashboardTrendDefinitionOverridesSelectors,
  IntegrationMetaActions,
  IntegrationMetaSelectors,
  NavigationActions,
  RbacActions,
  TemplateCommonActions,
  UserPreferenceActions,
  UserPreferenceFeatureControlsSelectors,
  UserPreferenceUIPreferenceSelectors,
} from '@ws1c/intelligence-core/store';
import {
  Account,
  AggregationWidget,
  AggregationWidgetChartType,
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  AppPlatform,
  BlockerStatus,
  Bookmark,
  Category,
  CategoryIndex,
  ChartDrilldownEvent,
  COLUMN_NAMES,
  CompositeTrendDefinition,
  CompositeTrendDrilldownApplier,
  CustomReportPreviewSearchResponse,
  Dashboard,
  DashboardConfig,
  DashboardSearchRequest,
  DashboardSearchResponse,
  DashboardSkinTypeConfig,
  DashboardTagFilter,
  DashboardType,
  DashboardUpdateLayoutRequest,
  DashboardView,
  DrilldownTrail,
  Entity,
  getFQNFunction,
  getModifiedSearchRequest,
  getUniqueId,
  IdentifiersRequest,
  IncrementailLoadingWidgetId,
  IncrementalLoadingResponseTrendStatus,
  IncrementalLoadingTrendPreviewResponse,
  IncrementalLoadingWidgetDetails,
  Integration,
  PreviewReportContentRequest,
  ROUTE_NAMES,
  RuleGroup,
  SearchTagFilterField,
  SearchTerm,
  StandardDashboard,
  StandardDashboardRequest,
  StandardDashboardType,
  StandardWidgetSubtype,
  TransferOwnershipRequest,
  Trend,
  TrendDateRange,
  TrendDefinition,
  TrendDefinitionDrilldownApplier,
  TrendDefinitionIndex,
  TrendMode,
  TrendPreviewResponse,
  UIPreference,
  UserAccess,
  UserAdminAccount,
  UserDescriptor,
  UserDetailPageType,
  WidgetDataset,
  WidgetDatasetPreferenceRequest,
  WidgetDetailDefinition,
  WidgetDetailPage,
  WidgetDetailPageSkinType,
  WidgetPosition,
  WidgetPreference,
  WidgetPreferences,
  WidgetSequence,
  WidgetSubTypeToPageTitle,
  WidgetUpdateDataRequest,
} from '@ws1c/intelligence-models';
import { helper } from './dashboard.effects.helpers';

/**
 * Handles side effects for Dashboard UI
 * @export
 * @class DashboardEffects
 */
@Injectable()
export class DashboardEffects {
  /**
   * addWidgetToBookmarks$
   *
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public addWidgetToBookmarks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.addWidgetToBookmarks),
      switchMap(({ widgetDetailPage, bookmark }: ReturnType<typeof DashboardActions.addWidgetToBookmarks>) => {
        const detailPageUrl: string = helper.getWidgetDetailPageUrl(widgetDetailPage, this.routerExtensions);
        const newBookmark: Bookmark = new Bookmark({
          ...bookmark,
          resourcePath: decodeURIComponent(detailPageUrl),
        });
        return of(BookmarksActions.addBookmark({ bookmark: newBookmark }));
      }),
    ),
  );

  /**
   * initializeWidgetDetail$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public initializeWidgetDetail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.initializeWidgetDetail),
      withLatestFrom(
        this.store.select(DashboardSelectors.getWidgetDetailWidgetId),
        this.store.select(DashboardSelectors.getWidgetDetailPage),
        this.store.select(DashboardSelectors.getCurrentDashboard),
      ),
      switchMap(([_action, widgetId, widgetDetailPage, currentDashboard]: [Action, string, WidgetDetailPage, string]) => {
        const widgetDetailUrl: string = helper.getWidgetDetailPageUrl(widgetDetailPage, this.routerExtensions);
        const loadWidgetActions = [];
        // widgetId will be defined only for custom dashboard widgets
        if (widgetId) {
          loadWidgetActions.push(DashboardActions.loadWidget({ widgetId }));
        } else {
          // For standard dashboard load the dashboard only if it is not already loaded
          // That will happen only if the widget details link is loaded directly
          // dashboardId is not populated for horizon dashboards as of now. This should be fixed.
          if (!currentDashboard && widgetDetailPage.widgetDetailDefinition?.dashboardId) {
            loadWidgetActions.push(
              DashboardActions.loadStandardDashboard({
                request: new StandardDashboardRequest(
                  StandardDashboardType[widgetDetailPage.widgetDetailDefinition.dashboardId],
                  undefined,
                  undefined,
                  widgetDetailPage.widgetDetailDefinition.additionalParams,
                ),
                isCrossCategory: widgetDetailPage.widgetDetailDefinition.isCrossCategory,
              }),
            );
          }
          loadWidgetActions.push(DashboardActions.loadWidgetDetailTrend());
        }
        return [DashboardActions.setWidgetDetailUrl({ widgetDetailUrl }), ...loadWidgetActions];
      }),
    ),
  );

  /**
   * widgetChangeOnWidgetDetailPage$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public widgetChangeOnWidgetDetailPage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadWidgetSuccess, DashboardActions.updateWidgetSuccess),
      withLatestFrom(this.store.select(DashboardSelectors.getWidgetDetailWidgetId), (action: Action, widgetId: string) => widgetId),
      filter(Boolean),
      map(() => DashboardActions.loadWidgetDetailTrend()),
    ),
  );

  /**
   * loadWidgetDetailTrend$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadWidgetDetailTrend$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadWidgetDetailTrend),
      withLatestFrom(
        this.store.select(DashboardSelectors.isWidgetDetailCompositeState),
        this.store.select(DashboardSelectors.getWidgetDetailTrendDefinition),
        this.store.select(DashboardSelectors.getWidgetDetailOverlayTrendDefinition),
        this.store.select(UserPreferenceFeatureControlsSelectors.isWidgetsIncrementalLoadingEnabled),
      ),
      switchMap(
        ([_action, isTrendDefinitionComposite, trendDefinition, overlayTrendDefinition, isWidgetIncrementalLoadingEnabled]: [
          Action,
          boolean,
          TrendDefinition,
          TrendDefinition,
          boolean,
        ]) => {
          if (isTrendDefinitionComposite) {
            return of(DashboardActions.loadCompositeWidgetDetail());
          }

          if (isWidgetIncrementalLoadingEnabled) {
            const actions = [
              DashboardActions.loadIncrementalWidgetDataByTrendDefinition({
                trackingId: IncrementailLoadingWidgetId.WIDGET_DETAIL_MAIN,
                details: {
                  trendDefinition,
                  onSuccess: (trendPreviewPartialResponse: IncrementalLoadingTrendPreviewResponse) => {
                    const trend = trendPreviewPartialResponse.getTrend(IncrementailLoadingWidgetId.WIDGET_DETAIL_MAIN);
                    return DashboardActions.loadWidgetDetailSuccess({ widgetDetailTrend: trend });
                  },
                },
              }),
            ];

            if (overlayTrendDefinition) {
              actions.push(
                DashboardActions.loadIncrementalWidgetDataByTrendDefinition({
                  trackingId: IncrementailLoadingWidgetId.WIDGET_DETAIL_OVERLAY,
                  details: {
                    trendDefinition: overlayTrendDefinition,
                    onSuccess: (trendPreviewPartialResponse: IncrementalLoadingTrendPreviewResponse) => {
                      const trend = trendPreviewPartialResponse.getTrend(IncrementailLoadingWidgetId.WIDGET_DETAIL_OVERLAY);
                      return DashboardActions.loadWidgetDetailSuccess({ widgetDetailTrend: undefined, widgetDetailOverlayTrend: trend });
                    },
                  },
                }),
              );
            }

            return actions;
          }

          if (overlayTrendDefinition) {
            return forkJoin([
              this.dashboardService.previewWidgetDataV2(trendDefinition),
              this.dashboardService.previewWidgetDataV2(overlayTrendDefinition),
            ]).pipe(
              map(([widgetDetailTrend, widgetDetailOverlayTrend]: [Trend, Trend]) =>
                DashboardActions.loadWidgetDetailSuccess({ widgetDetailTrend, widgetDetailOverlayTrend }),
              ),
              catchError((error: WebError) => of(DashboardActions.loadWidgetDetailFailure({ error }))),
            );
          }
          const previewWidgetData = trendDefinition.isV2
            ? this.dashboardService.previewWidgetDataV2(trendDefinition)
            : this.dashboardService.previewWidgetData(trendDefinition);
          return previewWidgetData.pipe(
            map((widgetDetailTrend: Trend) => DashboardActions.loadWidgetDetailSuccess({ widgetDetailTrend })),
            catchError((error: WebError) => of(DashboardActions.loadWidgetDetailFailure({ error }))),
          );
        },
      ),
    ),
  );

  /**
   * loadWidgetDetailUserFlowsStandardDashboard$
   * needs to wait for trend to load before loading standard dashboard
   * API breaks the trendDefinition's filter string into FilterRules which can contain the appId
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadWidgetDetailUserFlowsStandardDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadWidgetDetailSuccess),
      withLatestFrom(
        this.store.select(DashboardSelectors.isWidgetDetailUserFlowInfoAvailableState),
        this.store.select(DashboardSelectors.getWidgetDetailUserFlowInfo),
        this.store.pipe(select(DashboardSelectors.getWidgetDetailTrendDefinition)),
      ),
      map(
        ([_action, isWidgetDetailUserFlowInfoAvailable, widgetDetailUserFlowInfo, widgetDetailTrendDefinition]: [
          Action,
          boolean,
          { name: string; appId: string },
          TrendDefinition,
        ]) => {
          if (!isWidgetDetailUserFlowInfoAvailable) {
            return false;
          }
          const request = new StandardDashboardRequest(
            StandardDashboardType.USER_FLOW_DETAILS,
            undefined,
            widgetDetailTrendDefinition.dateRange,
            {
              apteligent_id: widgetDetailUserFlowInfo.appId,
              user_flow_name: widgetDetailUserFlowInfo.name,
            },
          );
          return DashboardActions.loadStandardDashboard({ request });
        },
      ),
      filter((value: any) => !!value),
    ),
  );

  /**
   * loadWidgetDetailColumns$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadWidgetDetailColumns$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadWidgetDetailTrend),
      withLatestFrom(
        this.store.select(IntegrationMetaSelectors.getCategoriesByCategoryId),
        this.store.select(DashboardSelectors.getWidgetDetailTrendDefinition),
        this.store.select(DashboardSelectors.isCrossCategoryActive),
      ),
      map(([_action, categoryIndex, trendDefinition, isCrossCategory]: [Action, CategoryIndex, TrendDefinition, boolean]) => {
        if (trendDefinition.isV2) {
          return IntegrationMetaActions.loadColumns({
            categoryId: getUniqueId(trendDefinition.integration, trendDefinition.entity),
            isCrossCategory,
          });
        } else {
          const categories = trendDefinition.categories
            .map((categoryId) => categoryIndex[categoryId])
            .filter((category: Category) => !!category);
          return IntegrationMetaActions.loadColumnsForMultiCategory({
            entitiesByIntegration: trendDefinition.entitiesByIntegration,
            activeCategories: categories,
          });
        }
      }),
    ),
  );

  /**
   * setWidgetDetailSelectedCompositeTableSubtype$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public setWidgetDetailSelectedCompositeTableSubtype$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.setWidgetDetailSelectedCompositeTableSubtype, DashboardActions.loadCompositeWidgetDetailSuccess),
      withLatestFrom(
        this.store.select(IntegrationMetaSelectors.getCategoriesByCategoryId),
        this.store.select(DashboardSelectors.getWidgetDetailTableCategoryId),
        this.store.select(DashboardSelectors.isWidgetDetailCrossCategory),
        (action: Action, categoriesById: CategoryIndex, categoryId: string, isCrossCategory: boolean) => {
          return { category: categoriesById[categoryId], isCrossCategory };
        },
      ),
      map(({ category, isCrossCategory }: { category: Category; isCrossCategory: boolean }) => {
        return IntegrationMetaActions.loadColumns({
          categoryId: category.categoryId,
          isCrossCategory,
        });
      }),
    ),
  );

  /**
   * widgetDetailTableOnDefinitionChanges$
   * Unfortunately, we need to wait for loadWidgetDetailSuccess before loading the table data
   * This is because if the trendDateRange is based on trendSpan instead of start/end,
   * I need the chart preview API to convert that into a start/end based format before calling the table API.
   * Even if the table API can accept a trendDateRange based on trendSpan instead of trendDateRanges,
   * there is no guarantee that the two APIs will come up with the same start/end values.
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public widgetDetailTableOnDefinitionChanges$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DashboardActions.loadWidgetDetailSuccess,
        DashboardActions.loadCompositeWidgetDetailSuccess,
        DashboardActions.setWidgetDetailSelectedCompositeTableSubtype,
        DashboardActions.setWidgetDetailPagedRequest,
        DashboardActions.setWidgetTableSortOns,
        DashboardActions.getDefaultWidgetColumnsSuccess,
        DashboardActions.setWidgetDetailTrend,
      ),
      map(() => DashboardActions.loadWidgetDetailTablePreview()),
    ),
  );

  /**
   * setWidgetDetailTableColumnNames$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public setWidgetDetailTableColumnNames$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.setWidgetDetailTableColumnNames),
      switchMap(
        ({
          columnNames,
          widgetId,
          dashboardId,
          widgetDatasetId,
          fromDetails = true,
          forOverlay = false,
        }: ReturnType<typeof DashboardActions.setWidgetDetailTableColumnNames>) => {
          if (!widgetId) {
            return of(DashboardActions.loadPreviewTable({ forOverlay }));
          }
          const serviceCall = widgetDatasetId
            ? this.dashboardService.saveWidgetDatasetPreferences(widgetId, dashboardId, [
                new WidgetDatasetPreferenceRequest({ columns: columnNames, widgetDatasetId }),
              ])
            : this.dashboardService.saveWidgetColumns(columnNames, widgetId, dashboardId);
          return serviceCall.pipe(
            map(() => {
              return fromDetails ? DashboardActions.loadWidgetDetailTablePreview() : DashboardActions.loadPreviewTable({ forOverlay });
            }),
          );
        },
      ),
    ),
  );

  /**
   * widgetPreviewTableChanges$
   * Called in widget-side-panel component, when the user navigates through records in the preview grid or clicks on a column for sorting
   * loadWidgetDetailTablePreview requires getWidgetDetailTablePreviewRequest$ to be loaded but in the widget-preview
   * not all the state variables are available
   * loadPreviewTable uses getWidgetPreviewTableRequest$ to resolve the required state variables
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public widgetPreviewTableChanges$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.setWidgetPreviewPagedRequest, DashboardActions.setWidgetPreviewTableSortOns),
      map(() => DashboardActions.loadPreviewTable({})),
    ),
  );

  /**
   * loadWidgetDetailTablePreview$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadWidgetDetailTablePreview$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadWidgetDetailTablePreview),
      // switchMap needed because getWidgetDetailTablePreviewRequest$ needs to wait for categories call
      withLatestFrom(
        this.store.select(DashboardSelectors.getWidgetDetailTablePreviewRequest),
        this.store.select(DashboardSelectors.getWidgetDetailOverlayTablePreviewRequest),
        this.store.select(DashboardSelectors.isWidgetDetailV2),
      ),
      filter(([_action, tablePreviewRequest]: [Action, PreviewReportContentRequest, PreviewReportContentRequest, boolean]) => {
        return tablePreviewRequest?.isReportPreviewEnabled;
      }),
      switchMap(
        ([_action, tablePreviewRequest, overlayPreviewRequest, isV2]: [
          Action,
          PreviewReportContentRequest,
          PreviewReportContentRequest,
          boolean,
        ]) => {
          if (overlayPreviewRequest) {
            return forkJoin([
              this.reportMetaService.previewReportAny(tablePreviewRequest, isV2),
              this.reportMetaService.previewReportAny(overlayPreviewRequest, isV2),
            ]).pipe(
              map(([response, overlayResponse]: [CustomReportPreviewSearchResponse, CustomReportPreviewSearchResponse]) => {
                return DashboardActions.loadWidgetDetailTablePreviewSuccess({ response, overlayResponse });
              }),
              catchError((error: WebError) => of(DashboardActions.loadWidgetDetailTablePreviewFailure({ error }))),
            );
          }
          return this.reportMetaService.previewReportAny(tablePreviewRequest, isV2).pipe(
            map((response: CustomReportPreviewSearchResponse) => {
              return DashboardActions.loadWidgetDetailTablePreviewSuccess({ response });
            }),
            catchError((error: WebError) => of(DashboardActions.loadWidgetDetailTablePreviewFailure({ error }))),
          );
        },
      ),
    ),
  );

  /**
   * loadUserFlowsCrumbList$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadUserFlowsCrumbList$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadUserFlowsCrumbList),
      withLatestFrom(this.store.select(AppsDashboardSelectors.getAppDashboardModifiedFilterRuleGroup)),
      mergeMap(([{ crumbListLocator }, ruleGroup]: [ReturnType<typeof DashboardActions.loadUserFlowsCrumbList>, RuleGroup]) => {
        const crumbsRequests = getUserFlowsCrumbListMultiRequest(crumbListLocator, ruleGroup);
        return this.reportMetaService.previewReportContentMultiV2(crumbsRequests).pipe(
          map((responses: CustomReportPreviewSearchResponse[]) => {
            return DashboardActions.loadUserFlowsCrumbListSuccess({
              crumbListLocator,
              responses,
            });
          }),
          catchError((error: WebError) => {
            return of(
              DashboardActions.loadUserFlowsCrumbListFailure({
                crumbListLocator,
                error,
              }),
            );
          }),
        );
      }),
    ),
  );

  /**
   * securityWidgetDrilldown$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public securityWidgetDrilldown$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.securityWidgetDrilldown),
      withLatestFrom(
        this.store.select(DashboardSelectors.getStandardDashboardData),
        ({ drilldownEvent }: ReturnType<typeof DashboardActions.securityWidgetDrilldown>, standardDashboardData: Map<string, Trend>) => {
          // Can not use the trendDefinition in trendDefinitionsByStandardWidgetSubtype
          // The trendDefinition returned from standard dashboard call has an invalid dateRange
          // The dateRange has both a trendSpan and startDateMillis/endDateMillis
          const trendDefinition = standardDashboardData.get(drilldownEvent.widgetSubType).trendDefinition;
          const drilledWidgetDetailDefinition = Object.assign(new WidgetDetailDefinition(), {
            ...drilldownEvent.widgetDetailDefinition,
            trendDefinition,
          });
          return DashboardActions.goToWidgetDetailPage({
            widgetDetailDefinition: drilledWidgetDetailDefinition,
            drilldownEvents: [],
          });
        },
      ),
    ),
  );

  /**
   * setWidgetDetailDrilldownEvent$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public setWidgetDetailDrilldownEvent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.setWidgetDetailDrilldownEventIndex),
      withLatestFrom(
        this.store.select(DashboardSelectors.getWidgetDetailPage),
        (action: Action, widgetDetailPage: WidgetDetailPage) => widgetDetailPage,
      ),
      map((widgetDetailPage: WidgetDetailPage) => DashboardActions.goToWidgetDetailPage(widgetDetailPage)),
    ),
  );

  /**
   * pushWidgetDetailDrilldownEvent$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public pushWidgetDetailDrilldownEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.pushWidgetDetailDrilldownEvent),
      withLatestFrom(
        this.store.select(DashboardSelectors.getWidgetDetailPage),
        (action: Action, widgetDetailPage: WidgetDetailPage) => widgetDetailPage,
      ),
      map((widgetDetailPage: WidgetDetailPage) => DashboardActions.goToWidgetDetailPage(widgetDetailPage)),
    ),
  );

  /**
   * goToCustomWidgetDetailPage$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public goToCustomWidgetDetailPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.goToCustomWidgetDetailPage),
      withLatestFrom(
        this.store.select(DashboardSelectors.getDrilldownEventsById),
        this.store.select(DashboardSelectors.getStandardDashboardDataState),
      ),
      map(
        ([{ widgetDetailPage }, drilldownEventsById, standardDashboardData]: [
          ReturnType<typeof DashboardActions.goToCustomWidgetDetailPage>,
          Record<string, ChartDrilldownEvent[]>,
          Map<string, Trend>,
        ]) => {
          widgetDetailPage.skinType ??= WidgetDetailPageSkinType.CUSTOM;
          const drilldownEvents = drilldownEventsById[widgetDetailPage.widgetId];
          if (widgetDetailPage.widgetId) {
            return DashboardActions.goToWidgetDetailPage({
              ...widgetDetailPage,
              drilldownEvents,
            });
          }
          // if widgetId is not available get trend definition from standard widget data
          const trend = standardDashboardData.get(widgetDetailPage.widgetDetailDefinition.id);
          const widgetDetailDefinition = trend
            ? new WidgetDetailDefinition({
                ...widgetDetailPage.widgetDetailDefinition,
                trendDefinition: trend.trendDefinition,
              })
            : widgetDetailPage.widgetDetailDefinition;
          return DashboardActions.goToWidgetDetailPage({
            ...widgetDetailPage,
            widgetDetailDefinition,
          });
        },
      ),
    ),
  );

  /**
   * goToWidgetDetailPage$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public goToWidgetDetailPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.goToWidgetDetailPage),
        withLatestFrom(this.store.select(DashboardSelectors.dashboardState)),
        tap(([action, dashboardState]: [ReturnType<typeof DashboardActions.goToWidgetDetailPage>, DashboardState]) => {
          let chartType;
          let trendDefinition;
          const widgetId = action.widgetId ? action.widgetId : action.widgetDetailDefinition.widgetId;
          if (dashboardState?.widgetData?.[widgetId]) {
            chartType = dashboardState.widgetData[widgetId].chartType;
            trendDefinition = dashboardState.widgetData[widgetId].result?.trendDefinition;
          } else if (dashboardState?.standardDashboardData?.get(widgetId)) {
            chartType = action.widgetDetailDefinition.chartType;
            trendDefinition = dashboardState.standardDashboardData.get(widgetId).trendDefinition;
          }
          const nextWidgetDetailDefinition = Object.assign(new WidgetDetailDefinition(), {
            ...action.widgetDetailDefinition,
            showInvertMode: chartType === AggregationWidgetChartType.LINE && !!trendDefinition?.bucketingAttributes?.length,
          });
          const detailPageUrl: string = helper.getWidgetDetailPageUrl(
            {
              ...action,
              widgetDetailDefinition: nextWidgetDetailDefinition,
            },
            this.routerExtensions,
          );
          return this.routerExtensions.navigateByUrl(detailPageUrl);
        }),
      ),
    { dispatch: false },
  );

  /**
   * goToDeviceDetailPage$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public goToDeviceDetailPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.goToDeviceDetailPage),
        withLatestFrom(this.store.select(UserPreferenceFeatureControlsSelectors.isDeviceProfileOverviewDashboardEnabled)),
        tap(
          ([{ device, userDetailPageType, subDashboardId, platform, additionalParams, openNewTab }, profileEnabled]: [
            ReturnType<typeof DashboardActions.goToDeviceDetailPage>,
            boolean,
          ]) => {
            let path: string;
            if (userDetailPageType === UserDetailPageType.DEEM_USER) {
              path = ROUTE_NAMES.SOLUTIONS_DEEM_SUMMARY_PHYSICAL_DEVICE_DETAIL(subDashboardId, platform);
            } else {
              path = profileEnabled ? ROUTE_NAMES.DASHBOARD.DEVICES_PROFILE : ROUTE_NAMES.DASHBOARD.DEVICES;
            }
            const detailPageUrl: string = helper.getDeviceDetailPageUrl(device, path, this.routerExtensions, additionalParams);
            if (openNewTab) {
              this.windowService.open(`#${detailPageUrl}`, '_blank');
            } else {
              return this.routerExtensions.navigateByUrl(detailPageUrl);
            }
          },
        ),
      ),
    { dispatch: false },
  );

  /**
   * Load all dashboards
   *
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadDashboardList$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadDashboardList),
      withLatestFrom(
        this.store.select(DashboardSelectors.getDashboardSearchRequest),
        this.store.select(DashboardSelectors.getDashboardListFilter),
        this.store.select(DashboardSelectors.getSearchDashboardType),
      ),
      switchMap(([_action, request, listFilter, type]: [Action, DashboardSearchRequest, DashboardTagFilter, string]) => {
        const modifiedRequest = new DashboardSearchRequest(getModifiedSearchRequest(request, listFilter, type));
        return this.dashboardService.searchDashboards(modifiedRequest).pipe(
          map((searchResponse: DashboardSearchResponse) => DashboardActions.loadDashboardListSuccess({ searchResponse })),
          catchError((error: WebError) => of(DashboardActions.loadDashboardListFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * Load a dashboard by id
   *
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadDashboard),
      switchMap(({ id }: ReturnType<typeof DashboardActions.loadDashboard>) => {
        return this.dashboardService.getDashboard(id).pipe(
          map((dashboardView: DashboardView) => DashboardActions.loadActiveDashboardSuccess({ dashboardView })),
          catchError((error: WebError) => [DashboardActions.loadActiveDashboardFailure({ error }), RbacActions.goToResourceNotFoundPage()]),
        );
      }),
    ),
  );

  /**
   * checkAndLoadDashboard$
   *
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public checkAndLoadDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.checkAndLoadDashboard),
      withLatestFrom(this.store.select(DashboardSelectors.getActiveDashboardState)),
      filter(([{ id }, activeDashboard]: [ReturnType<typeof DashboardActions.checkAndLoadDashboard>, DashboardView]) => {
        return activeDashboard?.id !== id;
      }),
      switchMap(([{ id }]: [ReturnType<typeof DashboardActions.checkAndLoadDashboard>, DashboardView]) => {
        return of(DashboardActions.loadDashboard({ id }));
      }),
    ),
  );

  /**
   * Search dashboards
   *
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public searchDashboards$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.searchDashboards),
      switchMap((action: ReturnType<typeof DashboardActions.searchDashboards>) => {
        // grab top 50
        const globalSearchRequest = new DashboardSearchRequest({
          from: 0,
          size: 50,
          searchTerms: [
            new SearchTerm({
              value: action.query,
              fields: [COLUMN_NAMES.byName.name, COLUMN_NAMES.byName.description],
            }),
            new SearchTerm({
              value: DashboardType.CUSTOM,
              fields: [COLUMN_NAMES.byName.dashboard_type],
            }),
          ],
          sortOns: [
            Object.assign(new SortOn(), {
              by: COLUMN_NAMES.byName.name,
              reverse: false,
            }),
          ],
        });
        if (action.access) {
          globalSearchRequest.searchTerms.push(
            new SearchTerm({
              value: action.access,
              fields: [COLUMN_NAMES.byName.account_access_level],
            }),
          );
        }
        return this.dashboardService.searchDashboards(globalSearchRequest).pipe(
          map((searchResponse: DashboardSearchResponse) => DashboardActions.searchDashboardsSuccess({ searchResponse })),
          catchError((error: WebError) => of(DashboardActions.searchDashboardsFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public goToDashboardPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.goToDashboardPage),
        withLatestFrom(this.store.select(DashboardSelectors.getRootUrl)),
        tap(([{ dashboardId }, rootUrl]: [ReturnType<typeof DashboardActions.goToDashboardPage>, string]) =>
          this.routerExtensions.navigate([`${rootUrl}/dashboard/list/${dashboardId}`]),
        ),
      ),
    { dispatch: false },
  );

  /**
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public goToDashboardPageEditLayout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.goToDashboardPageEditLayout),
        withLatestFrom(this.store.select(DashboardSelectors.getRootUrl)),
        tap(([{ filter: isFilterOn, dashboard }, rootUrl]: [ReturnType<typeof DashboardActions.goToDashboardPageEditLayout>, string]) => {
          const queryParams = { editLayout: true, isFilterOn };
          this.routerExtensions.navigate([`${rootUrl}/dashboard/list/`, dashboard?.id], { queryParams });
        }),
      ),
    { dispatch: false },
  );

  /**
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public goToCreateDashboardPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.goToCreateDashboardPage),
        withLatestFrom(this.store.select(DashboardSelectors.getRootUrl)),
        tap(([_action, rootUrl]: [Action, string]) => this.routerExtensions.navigate([`${rootUrl}/dashboard/add`])),
      ),
    { dispatch: false },
  );

  /**
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public goToDashboardListPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.goToDashboardListPage),
        withLatestFrom(this.store.select(DashboardSelectors.getRootUrl)),
        tap(([_action, rootUrl]: [Action, string]) => {
          // Navigate to rootUrl only if it's overridden
          return this.routerExtensions.navigate([
            rootUrl !== ROUTE_NAMES.WORKSPACE.HOME ? rootUrl : `/${ROUTE_NAMES.DASHBOARD.MY_DASHBOARDS}`,
          ]);
        }),
      ),
    { dispatch: false },
  );

  /**
   * createCustomDashboard$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public createCustomDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.createCustomDashboard),
      switchMap((action: ReturnType<typeof DashboardActions.createCustomDashboard>) => {
        return this.dashboardService
          .createDashboard(
            new Dashboard({
              name: action.name,
              description: action.description,
              incidentId: action.incidentId,
            }),
          )
          .pipe(
            mergeMap((dashboardView: DashboardView) =>
              [
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.SUCCESS,
                  target: action.fromTemplateId ? AlertBannerTarget.MODAL : AlertBannerTarget.PAGE,
                  message: this.i18nService.translate('DASHBOARD_ACTIONS.ADD_DASHBOARD_SUCCESS_MSG', { name: action.name }),
                }),
                action.incidentId &&
                  DashboardActions.setIncidentCustomDashboard({
                    dashboard: new Dashboard({
                      ...dashboardView,
                    }),
                  }),
                action.fromTemplateId &&
                  TemplateCommonActions.goToCreateWidgetFromTemplate({
                    templateId: action.fromTemplateId,
                    dashboardId: dashboardView.id,
                    sourceTemplateId: action.sourceTemplateId,
                  }),
              ].filter((newAction: Action) => !!newAction),
            ),
            catchError((error: WebError) => {
              const reason = getFailureReason(
                error,
                this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_DASHBOARD_KEY_DUPLICATE_ERROR'),
                this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_DASHBOARD_GENERIC_ERROR'),
              );
              return [
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.PAGE,
                  message: this.i18nService.translate('DASHBOARD_ACTIONS.ADD_DASHBOARD_ERROR_MSG', { reason }),
                }),
              ];
            }),
          );
      }),
    ),
  );

  /**
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public saveAndGotoDashboardPage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.saveAndGotoDashboardPage),
      withLatestFrom(this.store.select(DashboardSelectors.getIncidentId)),
      switchMap(([{ name, description }, incidentId]: [ReturnType<typeof DashboardActions.saveAndGotoDashboardPage>, string]) => {
        return this.dashboardService
          .createDashboard(
            new Dashboard({
              name,
              description,
              incidentId,
            }),
          )
          .pipe(
            mergeMap((dashboardView: DashboardView) => {
              return [
                DashboardActions.loadActiveDashboardSuccess({ dashboardView }),
                DashboardActions.goToDashboardPage({ dashboardId: dashboardView.id }),
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.SUCCESS,
                  message: this.i18nService.translate('DASHBOARD_ACTIONS.ADD_DASHBOARD_SUCCESS_MSG', { name }),
                }),
              ];
            }),
            catchError((error: WebError) => {
              const reason = getFailureReason(
                error,
                this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_DASHBOARD_KEY_DUPLICATE_ERROR'),
                this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_DASHBOARD_GENERIC_ERROR'),
              );
              return [
                DashboardActions.loadActiveDashboardFailure({ error }),
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.MODAL,
                  message: this.i18nService.translate('DASHBOARD_ACTIONS.ADD_DASHBOARD_ERROR_MSG', { reason }),
                }),
              ];
            }),
          );
      }),
    ),
  );

  /**
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public importDashboards$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.importDashboards),
      switchMap(({ files }: ReturnType<typeof DashboardActions.importDashboards>) => {
        return this.dashboardService.importDashboards(files).pipe(
          switchMap(() => [
            DashboardActions.importDashboardsSuccess(),
            DashboardActions.closeDashboardDialog(),
            DashboardActions.loadDashboardList(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              message: this.i18nService.translate('DASHBOARD_ACTIONS.IMPORT_DASHBOARD_SUCCESS_MSG'),
            }),
          ]),
          catchError((error: WebError) => {
            const reason = getFailureReason(
              error,
              this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_DASHBOARD_KEY_DUPLICATE_ERROR'),
              this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_DASHBOARD_GENERIC_ERROR'),
            );
            return [
              DashboardActions.importDashboardsFailure(),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.IMPORT_DASHBOARD_ERROR_MSG', { reason }),
              }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public requestExportDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.requestExportDashboard),
      switchMap(({ dashboard }: ReturnType<typeof DashboardActions.requestExportDashboard>) => {
        return this.dashboardService.exportDashboard(dashboard.id).pipe(
          mergeMap((response: GenericObject) => {
            this.downloadService.downloadJsonAsFile(response, `dashboard-${dashboard.id}.json`);
            return [DashboardActions.requestExportDashboardSuccess()];
          }),
          catchError(() => {
            const reason = dashboard.name;
            return [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.PAGE,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.EXPORT_DASHBOARD_ERROR_MSG', { reason }),
              }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public confirmDeleteDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.confirmDeleteDashboard),
      withLatestFrom(
        this.store.select(DashboardSelectors.getDashboardDialogModel),
        this.store.select(DashboardSelectors.isIncidentSubPage),
      ),
      switchMap(([_action, dashboard, isSubPage]: [Action, DashboardView | Dashboard, boolean]) => {
        const nextAction = isSubPage ? DashboardActions.fetchIncidentDashboard() : DashboardActions.loadDashboardList();
        return this.dashboardService.deleteDashboard(dashboard.id).pipe(
          mergeMap(() => {
            const name = dashboard.name;
            return [
              DashboardActions.requestDeleteDashboardSuccess({ dashboard }),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.SUCCESS,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.DELETE_DASHBOARD_SUCCESS_MSG', { name }),
              }),
              nextAction,
            ];
          }),
          catchError(() => {
            const reason = dashboard.name;
            return [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.WARNING,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.DELETE_DASHBOARD_ERROR_MSG', { reason }),
              }),
              nextAction,
            ];
          }),
        );
      }),
    ),
  );

  /**
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public confirmRenameDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.confirmRenameDashboard),
      withLatestFrom(
        this.store.select(DashboardSelectors.getDashboardDialogModel),
        this.store.select(DashboardSelectors.isIncidentSubPage),
      ),
      switchMap(
        ([{ name, description }, dashboard, isSubPage]: [
          ReturnType<typeof DashboardActions.confirmRenameDashboard>,
          Dashboard | DashboardView,
          boolean,
        ]) => {
          const dashboard2 = new Dashboard({
            id: dashboard.id,
            name,
            description,
          });
          return this.dashboardService.updateDashboard(dashboard2).pipe(
            mergeMap(() => {
              const nextAction = isSubPage ? DashboardActions.fetchIncidentDashboard() : DashboardActions.loadDashboardList();
              return [
                DashboardActions.updateDashboardSuccess({ dashboard: dashboard2 }),
                nextAction,
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.SUCCESS,
                  message: this.i18nService.translate('DASHBOARD_ACTIONS.RENAME_DASHBOARD_SUCCESS_MSG'),
                }),
              ];
            }),
            catchError((error: WebError) => of(DashboardActions.requestRenameDashboardFailure({ error }))),
          );
        },
      ),
    ),
  );

  /**
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public confirmCloneDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.confirmCloneDashboard),
      withLatestFrom(this.store.select(DashboardSelectors.getDashboardDialogModel)),
      switchMap(([{ name }, dashboard]: [ReturnType<typeof DashboardActions.confirmCloneDashboard>, Dashboard | DashboardView]) => {
        const cloneDashboard = new Dashboard({
          id: dashboard.id,
          name,
        });
        return this.dashboardService.cloneDashboard(cloneDashboard).pipe(
          mergeMap(() => [
            DashboardActions.requestCloneDashboardSuccess(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              message: this.i18nService.translate('DASHBOARD_ACTIONS.DUPLICATE_DASHBOARD_SUCCESS_MSG'),
            }),
          ]),
          catchError((error: WebError) => {
            const reason = error.getFullReason({
              [CommonErrorCode.DUPLICATE_KEY]: this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_DASHBOARD_KEY_DUPLICATE_ERROR'),
            });
            return [
              DashboardActions.requestCloneDashboardFailure({ error }),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.DUPLICATE_DASHBOARD_FAILURE_MSG', { reason }),
              }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public setDashboardBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.setDashboardBookmark),
      switchMap(({ dashboardId }: ReturnType<typeof DashboardActions.setDashboardBookmark>) => {
        return this.dashboardService.setDashboardBookmark(dashboardId).pipe(
          mergeMap((dashboardView: DashboardView) => {
            return [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.SUCCESS,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.SET_DASHBOARD_BOOKMARKS_SUCCESS_MSG', { name: dashboardView.name }),
              }),
              DashboardActions.setDashboardBookmarksSuccess({ dashboardView }),
              DashboardActions.refreshDashboardList(),
            ];
          }),
          catchError((error: WebError) => {
            return [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.WARNING,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.SET_DASHBOARD_BOOKMARKS_FAILURE_MSG', {
                  reason: getFailureReason(error),
                }),
              }),
              DashboardActions.setDashboardBookmarksFailure({ error }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public clearDashboardBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.clearDashboardBookmark),
      switchMap(({ dashboardId }: ReturnType<typeof DashboardActions.clearDashboardBookmark>) => {
        return this.dashboardService.clearDashboardBookmark(dashboardId).pipe(
          mergeMap((dashboardView: DashboardView) => {
            return [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.SUCCESS,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.SET_DASHBOARD_BOOKMARKS_SUCCESS_MSG', { name: dashboardView.name }),
              }),
              DashboardActions.setDashboardBookmarksSuccess({ dashboardView }),
              DashboardActions.refreshDashboardList(),
            ];
          }),
          catchError((error: WebError) => {
            return [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.WARNING,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.SET_DASHBOARD_BOOKMARKS_FAILURE_MSG', {
                  reason: getFailureReason(error),
                }),
              }),
              DashboardActions.setDashboardBookmarksFailure({ error }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public requestCloneDashboardSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.requestCloneDashboardSuccess),
      map(() => DashboardActions.loadDashboardList()),
    ),
  );

  /**
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadDashboardListSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadDashboardListSuccess),
      map(() => DashboardActions.closeDashboardDialog()),
    ),
  );

  /**
   * Update dashboard layout
   *
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public changeDashboardLayout: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.changeDashboardLayout),
      debounceTime(300),
      withLatestFrom(this.store.select(DashboardSelectors.dashboardState), this.store.select(DashboardSelectors.getWidgetListState)),
      switchMap(([_action, dashboardState, widgetList]: [Action, DashboardState, GridsterItem[]]) => {
        const data: DashboardUpdateLayoutRequest = Object.assign(new DashboardUpdateLayoutRequest(), {
          widgets: widgetList.map((gridsterItem: GridsterItem) => gridsterItem.widget),
        });
        return this.dashboardService.updateDashboardLayout(dashboardState.activeDashboard.id, data).pipe(
          mergeMap(() => {
            return [
              DashboardActions.goToDashboardPage({ dashboardId: dashboardState.activeDashboard?.id }),
              DashboardActions.changeDashboardLayoutSuccess(),
            ];
          }),
          catchError((error: WebError) => of(DashboardActions.changeDashboardLayoutFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public resetDashboardLayout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.resetDashboardLayout),
        withLatestFrom(this.store.select(DashboardSelectors.getRootUrl)),
        tap(([{ dashboardId }, rootUrl]: [ReturnType<typeof DashboardActions.resetDashboardLayout>, string]) => {
          return this.routerExtensions.navigate([`${rootUrl}/dashboard/list/${dashboardId}`]);
        }),
      ),
    { dispatch: false },
  );

  /**
   * Delete Current Active Widget
   *
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public deleteWidget: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.confirmDeleteWidget),
      withLatestFrom(this.store.select(DashboardSelectors.dashboardState)),
      switchMap(([_action, dashboardState]: [ReturnType<typeof DashboardActions.confirmDeleteWidget>, DashboardState]) => {
        return this.dashboardService.deleteWidget(dashboardState.activeWidget.id).pipe(
          mergeMap(() => {
            return [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.SUCCESS,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.DELETE_WIDGET_SUCCESS_MSG'),
              }),
              DashboardActions.deleteWidgetSuccess({ message: dashboardState.activeDashboard.id }),
              DashboardActions.removeWidgetFromBookmarks({ widget: dashboardState.activeWidget }),
            ];
          }),
          catchError((error: WebError) => of(DashboardActions.deleteWidgetFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * Redirects to dashboard after deleting a widget
   *
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public deleteWidgetSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.deleteWidgetSuccess),
      switchMap(({ message }: ReturnType<typeof DashboardActions.deleteWidgetSuccess>) => {
        return this.dashboardService
          .getDashboard(message)
          .pipe(map((dashboard: DashboardView) => DashboardActions.goToDashboardPage({ dashboardId: dashboard?.id })));
      }),
    ),
  );

  /**
   * Copy Current Active Widget
   *
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public copyWidget$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.confirmCopyWidget),
      withLatestFrom(this.store.select(DashboardSelectors.dashboardState)),
      switchMap(([{ copyWidget }, dashboardState]: [ReturnType<typeof DashboardActions.confirmCopyWidget>, DashboardState]) => {
        const dashboard = copyWidget.dashboard;
        const widget = new AggregationWidget({
          name: copyWidget.widget.name,
          description: copyWidget.widget.description,
          dashboardId: copyWidget.widget.dashboardId,
          trend: copyWidget.widget.trend,
          widgetDatasets: this.getCopyWidgetDatasets(copyWidget.widget),
          chartType: copyWidget.widget.chartType,
          ...this.getNewWidgetPosition(dashboardState),
        });
        if (!dashboard.id) {
          return of(
            DashboardActions.copyWidgetToNewDashboard({
              copyWidget: {
                dashboard,
                widget,
              },
            }),
          );
        }
        return of(
          DashboardActions.copyWidgetToExistingDashboard({
            copyWidget: {
              dashboard,
              widget,
            },
          }),
        );
      }),
    ),
  );

  /**
   * Copy Current Active Widget to Existing Dashboard
   *
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public copyWidgetToExistingDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.copyWidgetToExistingDashboard),
      switchMap(({ copyWidget }: ReturnType<typeof DashboardActions.copyWidgetToExistingDashboard>) => {
        return this.dashboardService.createWidget(copyWidget.widget).pipe(
          mergeMap((newWidget: AggregationWidget) => {
            return [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.SUCCESS,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.COPY_WIDGET_SUCCESS_MSG'),
              }),
              DashboardActions.resetRootUrl(),
              DashboardActions.addWidgetSuccess({ widget: newWidget }),
              DashboardActions.goToDashboardPage({ dashboardId: copyWidget.dashboard?.id }),
            ];
          }),
          catchError((error: WebError) => {
            const reason = getFailureReason(
              error,
              this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_WIDGET_KEY_DUPLICATE_ERROR'),
              this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_WIDGET_GENERIC_ERROR'),
            );
            return [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.ADD_WIDGET_ERROR_MSG', { reason }),
              }),
              DashboardActions.addWidgetFailure({ error }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * Copy Current Active Widget to a New Dashboard
   *
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public copyWidgetToNewDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.copyWidgetToNewDashboard),
      switchMap(({ copyWidget }: ReturnType<typeof DashboardActions.copyWidgetToNewDashboard>) => {
        return this.dashboardService.createDashboard(copyWidget.dashboard).pipe(
          mergeMap((dashboardView: DashboardView) => {
            const widget = new AggregationWidget();
            Object.assign(
              widget,
              {
                ...copyWidget.widget,
                dashboardId: dashboardView.id,
              },
              this.getNewDashboardWidgetPosition(),
            );
            return this.dashboardService.createWidget(widget).pipe(
              mergeMap((newWidget: AggregationWidget) => {
                return [
                  AlertBannerActions.showAlertBanner({
                    alertType: ALERT_BANNER_TYPE.SUCCESS,
                    message: this.i18nService.translate('DASHBOARD_ACTIONS.COPY_WIDGET_SUCCESS_MSG'),
                  }),
                  DashboardActions.resetRootUrl(),
                  DashboardActions.addWidgetSuccess({ widget: newWidget }),
                  DashboardActions.goToDashboardPage({ dashboardId: dashboardView?.id }),
                ];
              }),
            );
          }),
          catchError((error: WebError) => {
            const reason = getFailureReason(
              error,
              this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_DASHBOARD_KEY_DUPLICATE_ERROR'),
              this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_DASHBOARD_GENERIC_ERROR'),
            );
            return [
              DashboardActions.loadActiveDashboardFailure({ error }),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.ADD_DASHBOARD_ERROR_MSG', { reason }),
              }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * Add a new widget to default dashboard
   *
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public addDashboardWidget$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.confirmAddWidget),
      withLatestFrom(
        this.store.select(DashboardSelectors.dashboardState),
        this.store.select(DashboardSelectors.getActiveWidget),
        this.store.select(UserPreferenceFeatureControlsSelectors.isWidgetOverlaysEnabled),
      ),
      switchMap(
        ([_payload, dashboardState, activeWidget, isWidgetOverlaysEnabled]: [
          ReturnType<typeof DashboardActions.confirmAddWidget>,
          DashboardState,
          AggregationWidget,
          boolean,
        ]) => {
          let defaultActions: Action[] = [];
          const widget: AggregationWidget = new AggregationWidget({
            ...activeWidget,
            dashboardId: dashboardState.activeDashboard.id,
            ...this.getNewWidgetPosition(dashboardState),
          });
          defaultActions = [NavigationActions.setBlockerStatus({ blockerStatus: BlockerStatus.SKIP_NEXT_BLOCK })];

          if (!isWidgetOverlaysEnabled) {
            widget.widgetDatasets = undefined;
          }
          return this.dashboardService.createWidget(widget).pipe(
            mergeMap((newWidget: AggregationWidget) => {
              return [
                ...defaultActions,
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.SUCCESS,
                  message: this.i18nService.translate('DASHBOARD_ACTIONS.ADD_WIDGET_SUCCESS_MSG'),
                }),
                DashboardActions.addWidgetSuccess({ widget: newWidget }),
                DashboardActions.resetDashboardWidgetList(),
                DashboardActions.goToDashboardPage({ dashboardId: dashboardState.activeDashboard?.id }),
              ];
            }),
            catchError((error: WebError) => {
              const reason = getFailureReason(
                error,
                this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_WIDGET_KEY_DUPLICATE_ERROR'),
                this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_WIDGET_GENERIC_ERROR'),
              );
              return [
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.MODAL,
                  message: this.i18nService.translate('DASHBOARD_ACTIONS.ADD_WIDGET_ERROR_MSG', { reason }),
                }),
                DashboardActions.addWidgetFailure({ error }),
              ];
            }),
          );
        },
      ),
    ),
  );

  /**
   * Duplicate a widget on the default dashboard
   *
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public duplicateDashboardWidget$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.confirmDuplicateWidget),
      withLatestFrom(this.store.select(DashboardSelectors.dashboardState)),
      switchMap(([{ widget }, dashboardState]: [ReturnType<typeof DashboardActions.confirmDuplicateWidget>, DashboardState]) => {
        widget = Object.assign(new AggregationWidget(), widget, this.getNewWidgetPosition(dashboardState));
        return this.dashboardService.createWidget(widget).pipe(
          mergeMap((newWidget: AggregationWidget) => {
            return [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.SUCCESS,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.DUPLICATE_WIDGET_SUCCESS_MSG'),
              }),
              DashboardActions.duplicateWidgetSuccess({ widget: newWidget }),
              DashboardActions.loadWidget({ widgetId: newWidget.id }),
              DashboardActions.loadWidgetData({ widget: newWidget }),
              DashboardActions.setActiveWidgetDialogMode({ mode: ActiveWidgetDialogMode.CLOSE }),
            ];
          }),
          catchError((error: WebError) => {
            const reason = getFailureReason(
              error,
              this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_WIDGET_KEY_DUPLICATE_ERROR'),
              this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_WIDGET_GENERIC_ERROR'),
            );
            return [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.DUPLICATE_WIDGET_ERROR_MSG', { reason }),
              }),
              DashboardActions.duplicateWidgetFailure({ error }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * loadWidgetDataSuccess$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadWidgetDataSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadWidgetDataSuccess),
      withLatestFrom(this.store.select(DashboardSelectors.getDashboardAddedOrDuplicated)),
      map(([_, addedOrDuplicated]: [ReturnType<typeof DashboardActions.loadWidgetDataSuccess>, boolean]) => {
        return DashboardActions.scrollToBottom({ isScroll: addedOrDuplicated });
      }),
    ),
  );

  /**
   * Load a widget
   *
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadWidget: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadWidget),
      switchMap(({ widgetId }: ReturnType<typeof DashboardActions.loadWidget>) => {
        return this.dashboardService.getWidget(widgetId).pipe(
          mergeMap((widget: AggregationWidget) => {
            return [
              DashboardActions.loadWidgetSuccess({ widget }),
              DashboardActions.setActiveWidget({ widget }),
              DashboardActions.loadDashboard({ id: widget.dashboardId }),
            ];
          }),
          catchError((error: WebError) => of(DashboardActions.loadWidgetFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * setActiveWidget$
   *
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public setActiveWidget$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.setActiveWidget),
      withLatestFrom(this.store.select(UserPreferenceFeatureControlsSelectors.isWidgetJoinEnabled)),
      filter(([{ forQuickMode }, _isWidgetJoinEnabled]: [ReturnType<typeof DashboardActions.setActiveWidget>, boolean]) => !forQuickMode),
      mergeMap(([{ widget }, isWidgetJoinEnabled]: [ReturnType<typeof DashboardActions.setActiveWidget>, boolean]) => {
        const actions = [];
        widget.widgetDatasets.forEach((widgetDataset: WidgetDataset) => {
          const categoryId = widgetDataset.categoryId;
          const setActiveCategory = widgetDataset.overlaySequence === WidgetSequence.MAIN;
          actions.push(
            widgetDataset.trendMode === TrendMode[TrendMode.SNAPSHOT_PERIODICAL]
              ? IntegrationMetaActions.getPrecomputedAggregations({
                  categoryId,
                  setActiveCategory,
                })
              : IntegrationMetaActions.loadColumns({
                  categoryId,
                  isCrossCategory: isWidgetJoinEnabled,
                  setActiveCategory,
                }),
          );
        });
        return actions;
      }),
    ),
  );

  /**
   * addOverlay$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public addOverlay$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.addOverlay),
      withLatestFrom(this.store.select(UserPreferenceFeatureControlsSelectors.isWidgetJoinEnabled)),
      switchMap(([{ widgetDataset }, isWidgetJoinEnabled]: [ReturnType<typeof DashboardActions.addOverlay>, boolean]) => {
        return widgetDataset.trendMode === TrendMode[TrendMode.SNAPSHOT_PERIODICAL]
          ? of(
              IntegrationMetaActions.getPrecomputedAggregations({
                categoryId: widgetDataset.categoryId,
                setActiveCategory: false,
              }),
            )
          : of(
              IntegrationMetaActions.loadColumnsQuietly({
                categoryId: widgetDataset.trend.trendDefinition.categoryId,
                isCrossCategory: isWidgetJoinEnabled,
              }),
            );
      }),
    ),
  );

  /**
   * updateActiveWidgetTrendDefinition$
   *
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public updateActiveWidgetTrendDefinition$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.updateActiveWidgetTrendDefinition),
      withLatestFrom(
        this.store.select(DashboardSelectors.isValidActiveWidgetTrendDefinition),
        this.store.select(DashboardSelectors.getActiveWidget),
      ),
      debounceTime(300),
      switchMap(
        ([_1, isValidTrendDefinition, widget]: [
          ReturnType<typeof DashboardActions.updateActiveWidgetTrendDefinition>,
          boolean,
          AggregationWidget,
        ]) => {
          if (!isValidTrendDefinition) {
            return of(DashboardActions.previewWidgetDataFailure({ error: null, forOverlay: false }));
          }
          const request = new WidgetUpdateDataRequest(widget, widget.trend.trendDefinition);
          return of(DashboardActions.previewWidgetDataV2({ request }));
        },
      ),
    ),
  );

  /**
   * updateActiveWidgetOverlayTrendDefinition$
   *
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public updateActiveWidgetOverlayTrendDefinition$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.updateActiveWidgetOverlayTrendDefinition),
      withLatestFrom(
        this.store.select(DashboardSelectors.isValidOverlayTrendDefinition),
        this.store.select(DashboardSelectors.getActiveWidget),
      ),
      debounceTime(300),
      switchMap(
        ([_1, isValidTrendDefinition, widget]: [
          ReturnType<typeof DashboardActions.updateActiveWidgetOverlayTrendDefinition>,
          boolean,
          AggregationWidget,
        ]) => {
          if (!isValidTrendDefinition) {
            return of(DashboardActions.previewWidgetDataFailure({ error: null, forOverlay: true }));
          }
          const request = new WidgetUpdateDataRequest(widget, widget.overlayTrend.trendDefinition);
          return of(DashboardActions.previewWidgetDataV2({ request, forOverlay: true }));
        },
      ),
    ),
  );

  /**
   * Edit an existing widget dashboard
   *
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public updateDashboardWidget$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.confirmUpdateWidget),
      withLatestFrom(
        this.store.select(DashboardSelectors.dashboardState),
        this.store.select(DashboardSelectors.getActiveWidget),
        this.store.select(UserPreferenceFeatureControlsSelectors.isWidgetOverlaysEnabled),
      ),
      switchMap(
        ([_payload, dashboardState, activeWidget, isWidgetOverlaysEnabled]: [
          ReturnType<typeof DashboardActions.confirmUpdateWidget>,
          DashboardState,
          AggregationWidget,
          boolean,
        ]) => {
          const widget: AggregationWidget = new AggregationWidget({ ...activeWidget });
          const actions = [NavigationActions.setBlockerStatus({ blockerStatus: BlockerStatus.SKIP_NEXT_BLOCK })];
          if (!isWidgetOverlaysEnabled) {
            widget.widgetDatasets = undefined;
          }
          return this.dashboardService.updateWidget(widget).pipe(
            mergeMap((updatedWidget: AggregationWidget) => {
              const loadActions = dashboardState.returnCrumbs
                ? [
                    ...actions,
                    DashboardActions.goToWidgetDetailPage({
                      widgetId: updatedWidget.id,
                      dashboardId: updatedWidget.dashboardId,
                      widgetDetailDefinition: new WidgetDetailDefinition({
                        trendDefinition: updatedWidget.trend.trendDefinition,
                        returnCrumbs: dashboardState.returnCrumbs,
                      }),
                      drilldownEvents: [],
                      skinType: DashboardSkinTypeConfig.SKIN_TYPE_BY_CATEGORY_ID[updatedWidget.trend.trendDefinition.categoryId],
                    }),
                  ]
                : [
                    ...actions,
                    DashboardActions.goToDashboardPage({ dashboardId: updatedWidget.dashboardId }),
                    DashboardActions.loadWidgetData({ widget: updatedWidget }),
                  ];
              return [
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.SUCCESS,
                  message: this.i18nService.translate('COMMON_MESSAGES.UPDATE_SUCCESS_MSG'),
                }),
                // Need to load widget data to refresh widget in Dashboard View
                DashboardActions.loadWidgetData({ widget: updatedWidget }),
                DashboardActions.updateWidgetSuccess({ widget: updatedWidget }),
                ...loadActions,
              ];
            }),
            catchError((error: WebError) => {
              const reason = getFailureReason(
                error,
                this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_WIDGET_KEY_DUPLICATE_ERROR'),
                this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_WIDGET_GENERIC_ERROR'),
              );
              return [
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  message: this.i18nService.translate('DASHBOARD_ACTIONS.UPDATE_WIDGET_ERROR_MSG', { reason }),
                  target: AlertBannerTarget.MODAL,
                }),
                DashboardActions.updateWidgetFailure({ error }),
              ];
            }),
          );
        },
      ),
    ),
  );

  /**
   * Rename an existing widget dashboard
   *
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public renameDashboardWidget$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.confirmRenameWidget),
      switchMap(({ widget }: ReturnType<typeof DashboardActions.confirmRenameWidget>) => {
        const dashboardWidget: AggregationWidget = Object.assign(new AggregationWidget(), widget);
        return this.dashboardService.updateWidget(dashboardWidget).pipe(
          mergeMap((updatedWidget: AggregationWidget) => {
            return [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.SUCCESS,
                message: this.i18nService.translate('COMMON_MESSAGES.UPDATE_SUCCESS_MSG'),
              }),
              DashboardActions.updateWidgetSuccess({ widget: updatedWidget }),
              DashboardActions.loadWidget({ widgetId: updatedWidget.id }),
              // Need to load widget data to refresh widget in Dashboard View
              DashboardActions.loadWidgetData({ widget: updatedWidget }),
              DashboardActions.setActiveWidgetDialogMode({ mode: ActiveWidgetDialogMode.CLOSE }),
            ];
          }),
          catchError((error: WebError) => {
            const reason = getFailureReason(
              error,
              this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_WIDGET_KEY_DUPLICATE_ERROR'),
              this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_WIDGET_GENERIC_ERROR'),
            );
            return [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.UPDATE_WIDGET_ERROR_MSG', { reason }),
                target: AlertBannerTarget.MODAL,
              }),
              DashboardActions.updateWidgetFailure({ error }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * updateTrendDateRange
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public updateTrendDateRange: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.updateTrendDateRange),
      switchMap(({ request }: ReturnType<typeof DashboardActions.updateTrendDateRange>) => {
        const widget: AggregationWidget = request.widget as AggregationWidget;
        const trendDateRange: TrendDateRange = request.trendDateRange as TrendDateRange;
        widget.trend.trendDefinition.dateRange = trendDateRange;
        return this.dashboardService.updateWidget(widget).pipe(
          mergeMap((updatedWidget: AggregationWidget) => {
            return [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.SUCCESS,
                message: this.i18nService.translate('COMMON_MESSAGES.UPDATE_SUCCESS_MSG'),
              }),
              DashboardActions.loadWidgetData({ widget: updatedWidget }),
              DashboardActions.updateTrendDateRangeSuccess({ widget: updatedWidget }),
            ];
          }),
          catchError((error: WebError) => {
            const reason: string = this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_WIDGET_GENERIC_ERROR');
            return [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.UPDATE_WIDGET_ERROR_MSG', { reason }),
              }),
              DashboardActions.updateTrendDateRangeFailure({ error }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * setDashboardThumbnail$
   *
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public setDashboardThumbnail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.setDashboardThumbnail),
      switchMap(({ widget }: ReturnType<typeof DashboardActions.setDashboardThumbnail>) => {
        return this.dashboardService.setDashboardThumbnail(widget).pipe(
          mergeMap((dashboard: DashboardView) => {
            return [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.SUCCESS,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.SET_DASHBOARD_THUMBNAIL_SUCCESS_MSG'),
              }),
              DashboardActions.setDashboardThumbnailSuccess({ dashboard }),
            ];
          }),
          catchError((error: WebError) => {
            return [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.WARNING,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.SET_DASHBOARD_THUMBNAIL_FAILURE_MSG', {
                  reason: getFailureReason(error),
                }),
              }),
              DashboardActions.setDashboardThumbnailFailure({ error }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * loadWidgetDataById$
   *
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadWidgetDataById$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.loadWidgetDataById),
      withLatestFrom(this.store.select(UserPreferenceFeatureControlsSelectors.isWidgetsIncrementalLoadingEnabled)),
      mergeMap(([{ widgetId }, isIncrementalLoadingEnabled]: [ReturnType<typeof DashboardActions.loadWidgetDataById>, boolean]) => {
        let getWidgetDataStream$: Observable<TrendPreviewResponse>;

        if (isIncrementalLoadingEnabled) {
          getWidgetDataStream$ = of(undefined);
        } else {
          getWidgetDataStream$ = this.dashboardService.getWidgetData(widgetId);
        }

        return forkJoin([this.dashboardService.getWidget(widgetId), getWidgetDataStream$]).pipe(
          switchMap(([widget, trendPreview]: [AggregationWidget, TrendPreviewResponse]) => {
            const actions: Action[] = [DashboardActions.loadWidgetDataByIdSuccess({ widget, trendPreview })];
            if (isIncrementalLoadingEnabled) {
              actions.push(
                DashboardActions.loadIncrementalWidgetDataByWidgetId({
                  trackingId: widgetId,
                  details: {
                    onSuccess: (trendPreviewPartialResponse: IncrementalLoadingTrendPreviewResponse) => {
                      return DashboardActions.loadWidgetDataByIdSuccess({
                        widget,
                        trendPreview: trendPreviewPartialResponse.toTrendPreviewResponse(),
                      });
                    },
                  },
                }),
              );
            }
            return actions;
          }),
          catchError((error: WebError) =>
            of(
              DashboardActions.loadWidgetDataFailure({
                widget: new AggregationWidget({
                  id: widgetId,
                }),
                error,
              }),
            ),
          ),
        );
      }),
    );
  });

  /**
   * clearCustomDrilldownEvents$
   * @memberof DashboardEffects
   */
  public clearCustomDrilldownEvents$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.clearCustomDrilldownEvents, DashboardActions.popCustomDrilldownEvent),
      map(({ widget }: ReturnType<typeof DashboardActions.clearCustomDrilldownEvents>) => {
        return DashboardActions.loadWidgetData({ widget });
      }),
    ),
  );

  /**
   * pushCustomWidgetDrilldownEvent
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public pushCustomWidgetDrilldownEvent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.pushCustomWidgetDrilldownEvent),
      withLatestFrom(
        this.store.select(DashboardSelectors.getWidgetsById),
        this.store.select(DashboardTrendDefinitionOverridesSelectors.getTrendDefinitionsByStandardWidgetSubtypeWithOverrides),
        (
          { widgetId }: ReturnType<typeof DashboardActions.pushCustomWidgetDrilldownEvent>,
          widgetsById: Record<string, AggregationWidget>,
          trendDefinitionsByStandardWidgetSubtype: Record<string, TrendDefinition>,
        ) => {
          if (trendDefinitionsByStandardWidgetSubtype[widgetId]) {
            return DashboardActions.loadVisibleWidgetSubtypes({ widgetSubtypes: [widgetId] });
          }
          return DashboardActions.loadWidgetData({ widget: widgetsById[widgetId] });
        },
      ),
    ),
  );

  /**
   * loadWidgetData
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadWidgetData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadWidgetData),
      withLatestFrom(
        this.store.select(DashboardSelectors.getDrilldownEventsById),
        this.store.select(UserPreferenceFeatureControlsSelectors.isWidgetsIncrementalLoadingEnabled),
      ),
      mergeMap(
        ([{ widget }, drilldownEventsById, isIncrementalLoadingEnabled]: [
          ReturnType<typeof DashboardActions.loadWidgetData>,
          Record<string, ChartDrilldownEvent[]>,
          boolean,
        ]) => {
          const drilldownEvents = drilldownEventsById[widget.id] || [];
          const originalTrendDefinition = widget.trend.trendDefinition;
          const tdda = new TrendDefinitionDrilldownApplier();
          const nextTrendDefinition = tdda.applyDrilldownEventsToTrendDefinition(originalTrendDefinition, drilldownEvents);
          let overlayTrendDefinition;
          let overlayNextTrendDefinition;
          if (widget.hasOverlay) {
            overlayTrendDefinition = widget.overlayTrend.trendDefinition;
            overlayNextTrendDefinition = tdda.applyDrilldownEventsToTrendDefinition(overlayTrendDefinition, drilldownEvents);
          }

          if (isIncrementalLoadingEnabled) {
            const actions = [
              DashboardActions.loadIncrementalWidgetDataByTrendDefinition({
                trackingId: widget.mainWidgetId,
                details: {
                  trendDefinition: nextTrendDefinition,
                  onSuccess: (trendPreviewPartialResponse: IncrementalLoadingTrendPreviewResponse) => {
                    return DashboardActions.loadWidgetDataSuccess({
                      widget,
                      trend: trendPreviewPartialResponse.getTrend(widget.mainWidgetId),
                      overlayTrend: undefined,
                    });
                  },
                },
              }),
            ];

            if (widget.hasOverlay) {
              actions.push(
                DashboardActions.loadIncrementalWidgetDataByTrendDefinition({
                  trackingId: widget.overlayId,
                  details: {
                    trendDefinition: overlayNextTrendDefinition,
                    onSuccess: (trendPreviewPartialResponse: IncrementalLoadingTrendPreviewResponse) => {
                      return DashboardActions.loadWidgetDataSuccess({
                        widget,
                        trend: undefined,
                        overlayTrend: trendPreviewPartialResponse.getTrend(widget.overlayId),
                      });
                    },
                  },
                }),
              );
            }

            return actions;
          }

          if (widget.hasOverlay) {
            return forkJoin([
              this.dashboardService.previewWidgetDataV2(nextTrendDefinition),
              this.dashboardService.previewWidgetDataV2(overlayNextTrendDefinition),
            ]).pipe(
              map(([trend, overlayTrend]: [Trend, Trend]) => DashboardActions.loadWidgetDataSuccess({ widget, trend, overlayTrend })),
              catchError((error: WebError) => of(DashboardActions.loadWidgetDataFailure({ widget, error }))),
            );
          }

          return this.dashboardService.previewWidgetDataV2(nextTrendDefinition).pipe(
            map((trend: Trend) => DashboardActions.loadWidgetDataSuccess({ widget, trend })),
            catchError((error: WebError) => of(DashboardActions.loadWidgetDataFailure({ widget, error }))),
          );
        },
      ),
    ),
  );

  /**
   * previewWidgetData$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public previewWidgetData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.previewWidgetData),
      debounceTime(300),
      withLatestFrom(this.store.select(UserPreferenceFeatureControlsSelectors.isWidgetsIncrementalLoadingEnabled)),
      mergeMap(
        ([{ request, forOverlay = false }, isIncrementalLoadingEnabled]: [
          ReturnType<typeof DashboardActions.previewWidgetData>,
          boolean,
        ]) => {
          if (isIncrementalLoadingEnabled) {
            const key = forOverlay ? IncrementailLoadingWidgetId.WIDGET_PREVIEW_OVERLAY : IncrementailLoadingWidgetId.WIDGET_PREVIEW_MAIN;
            return of(
              DashboardActions.loadIncrementalWidgetDataByTrendDefinition({
                trackingId: key,
                details: {
                  trendDefinition: request.trendDefinition,
                  onSuccess: (trendPreviewPartialResponse: IncrementalLoadingTrendPreviewResponse) => {
                    const trend = trendPreviewPartialResponse.getTrend(key);
                    return DashboardActions.previewWidgetDataSuccess({ trend, forOverlay });
                  },
                },
              }),
            );
          }

          return this.dashboardService.previewWidgetDataV2(request.trendDefinition).pipe(
            map((trend: Trend) => {
              return DashboardActions.previewWidgetDataSuccess({ trend, forOverlay });
            }),
            catchError((error: WebError) => {
              const failureMsgKey = forOverlay ? 'DASHBOARD_ACTIONS.OVERLAY_PREVIEW_FAILURE_MSG' : 'DASHBOARD_ACTIONS.PREVIEW_FAILURE_MSG';
              return [
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.MODAL,
                  message: this.i18nService.translate(failureMsgKey, {
                    reason: error.getFullReason({
                      [CommonErrorCode.AGGREGATION_101]: this.i18nService.translate('DASHBOARD_ACTIONS.INVALID_AGGREGATION_MSG'),
                    }),
                  }),
                }),
                DashboardActions.previewWidgetDataFailure({ error, forOverlay }),
              ];
            }),
          );
        },
      ),
    ),
  );

  /**
   * previewWidgetDataV2$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public previewWidgetDataV2$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.previewWidgetDataV2),
      withLatestFrom(this.store.select(UserPreferenceFeatureControlsSelectors.isWidgetsIncrementalLoadingEnabled)),
      mergeMap(
        ([{ request, forOverlay = false }, isIncrementalLoadingEnabled]: [
          ReturnType<typeof DashboardActions.previewWidgetDataV2>,
          boolean,
        ]) => {
          if (isIncrementalLoadingEnabled) {
            const key = forOverlay ? IncrementailLoadingWidgetId.WIDGET_PREVIEW_OVERLAY : IncrementailLoadingWidgetId.WIDGET_PREVIEW_MAIN;
            return of(
              DashboardActions.loadIncrementalWidgetDataByTrendDefinition({
                trackingId: key,
                details: {
                  trendDefinition: request.trendDefinition,
                  onSuccess: (trendPreviewPartialResponse: IncrementalLoadingTrendPreviewResponse) => {
                    const trend = trendPreviewPartialResponse.getTrend(key);
                    return DashboardActions.previewWidgetDataSuccess({ trend, forOverlay });
                  },
                },
              }),
            );
          }
          return this.dashboardService.previewWidgetDataV2(request.trendDefinition).pipe(
            map((trend: Trend) => {
              return DashboardActions.previewWidgetDataSuccess({ trend, forOverlay });
            }),
            catchError((error: WebError) => {
              const failureMsgKey = forOverlay ? 'DASHBOARD_ACTIONS.OVERLAY_PREVIEW_FAILURE_MSG' : 'DASHBOARD_ACTIONS.PREVIEW_FAILURE_MSG';
              return [
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.MODAL,
                  message: this.i18nService.translate(failureMsgKey, {
                    reason: error.getFullReason({
                      [CommonErrorCode.AGGREGATION_101]: this.i18nService.translate('DASHBOARD_ACTIONS.INVALID_AGGREGATION_MSG'),
                    }),
                  }),
                }),
                DashboardActions.previewWidgetDataFailure({ error, forOverlay }),
              ];
            }),
          );
        },
      ),
    ),
  );

  /**
   * loadWidgetPreviewTable$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadWidgetPreviewTable$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadPreviewTable, DashboardActions.previewWidgetDataSuccess),
      withLatestFrom(this.routerExtensions.url$),
      mergeMap(([{ forOverlay }, url]: [ReturnType<typeof DashboardActions.previewWidgetDataSuccess>, string]) => {
        return this.store.pipe(
          select(forOverlay ? DashboardSelectors.getWidgetOverlayPreviewTableRequest : DashboardSelectors.getWidgetPreviewTableRequest),
          filter((tablePreviewRequest: PreviewReportContentRequest) => {
            // Block report preview calls for SNAPSHOT_PERIODICAL
            const isDataExplorer = url.includes(`/${ROUTE_NAMES.WORKSPACE.DATA_EXPLORER}`);
            return tablePreviewRequest && tablePreviewRequest.trendMode !== TrendMode.SNAPSHOT_PERIODICAL && !isDataExplorer;
          }),
          first(),
          switchMap((tablePreviewRequest: PreviewReportContentRequest) => {
            return this.reportMetaService.previewReportAny(tablePreviewRequest, true).pipe(
              map((response: CustomReportPreviewSearchResponse) => {
                return DashboardActions.loadWidgetPreviewTableSuccess({ response, forOverlay });
              }),
              catchError((error: WebError) => of(DashboardActions.loadWidgetDetailTablePreviewFailure({ error }))),
            );
          }),
        );
      }),
    ),
  );

  /**
   * loadMergedStandardDashboard$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadMergedStandardDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadMergedStandardDashboard),
      withLatestFrom(
        this.store.select(DashboardSelectors.getUnMergeWidgetSubTypeList),
        this.store.select(UserPreferenceFeatureControlsSelectors.isUemV2DashboardToggleEnabled),
        this.store.select(UserPreferenceUIPreferenceSelectors.isUemV2DashboardToggleEnabled),
      ),
      switchMap(
        ([{ requests }, omitSubTypeList, isUemV2DashboardToggleEnabledInFF, isUemV2DashboardToggleEnabledInUI]: [
          ReturnType<typeof DashboardActions.loadMergedStandardDashboard>,
          string[],
          boolean,
          boolean,
        ]) => {
          const isUemV2Enabled: boolean = isUemV2DashboardToggleEnabledInFF && isUemV2DashboardToggleEnabledInUI;
          const serviceCalls = requests.map((request: StandardDashboardRequest) => {
            return this.dashboardService.getStandardDashboardByType(request, true, isUemV2Enabled);
          });
          return forkJoin(serviceCalls).pipe(
            switchMap((dashboards: StandardDashboard[]) => {
              const widgetIdToDashboardIdMap = {};
              dashboards.forEach((dashboard: StandardDashboard) => {
                Object.keys(dashboard.trendDefinitionMap).forEach((widgetId: string) => {
                  widgetIdToDashboardIdMap[widgetId] = dashboard.dashboardType;
                });
              });
              const mergedStandardDashboard = dashboards.reduce((mergedDashboard: any, dashboard: StandardDashboard) => {
                return merge(dashboard, mergedDashboard);
              }, new StandardDashboard());
              if (omitSubTypeList.length && mergedStandardDashboard) {
                omitSubTypeList.forEach((id: string) => {
                  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                  delete mergedStandardDashboard.trendDefinitionMap[id];
                });
              }
              return [
                DashboardActions.setWidgetIdToDashboardIdMap({ widgetIdToDashboardIdMap }),
                DashboardActions.loadStandardDashboardSuccess({ standardDashboard: mergedStandardDashboard }),
              ];
            }),
          );
        },
      ),
    ),
  );

  /**
   * loadNestedTrendDashboard$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadNestedTrendDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadNestedTrendDashboard),
      withLatestFrom(
        this.store.select(UserPreferenceFeatureControlsSelectors.isUemV2DashboardToggleEnabled),
        this.store.select(UserPreferenceUIPreferenceSelectors.isUemV2DashboardToggleEnabled),
      ),
      mergeMap(
        ([{ request }, isUemV2DashboardToggleEnabledInFF, isUemV2DashboardToggleEnabledInUI]: [
          ReturnType<typeof DashboardActions.loadNestedTrendDashboard>,
          boolean,
          boolean,
        ]) => {
          const isUemV2Enabled: boolean = isUemV2DashboardToggleEnabledInFF && isUemV2DashboardToggleEnabledInUI;
          return this.dashboardService.getStandardDashboardByType(request, true, isUemV2Enabled).pipe(
            switchMap((standardDashboard: StandardDashboard) => {
              const widgetIdToDashboardIdMap = {};
              Object.keys(standardDashboard.trendDefinitionMap).forEach((widgetId: string) => {
                widgetIdToDashboardIdMap[widgetId] = standardDashboard.dashboardType;
              });
              return [
                DashboardActions.setWidgetIdToDashboardIdMap({ widgetIdToDashboardIdMap }),
                DashboardActions.loadStandardDashboardSuccess({ standardDashboard }),
              ];
            }),
            catchError((error: WebError) => of(DashboardActions.loadStandardDashboardFailure({ error }))),
          );
        },
      ),
    ),
  );

  /**
   * loadStandardDashboard$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadStandardDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadStandardDashboard),
      withLatestFrom(
        this.store.select(UserPreferenceFeatureControlsSelectors.isUemV2DashboardToggleEnabled),
        this.store.select(UserPreferenceUIPreferenceSelectors.isUemV2DashboardToggleEnabled),
      ),
      mergeMap(
        ([{ request, preferenceDataNeeded, maskAttribute = true }, isUemV2DashboardToggleEnabledInFF, isUemV2DashboardToggleEnabledInUI]: [
          ReturnType<typeof DashboardActions.loadStandardDashboard>,
          boolean,
          boolean,
        ]) => {
          const isUemV2Enabled: boolean = isUemV2DashboardToggleEnabledInFF && isUemV2DashboardToggleEnabledInUI;
          return this.dashboardService.getStandardDashboardByType(request, maskAttribute, isUemV2Enabled).pipe(
            switchMap((standardDashboard: StandardDashboard) => {
              const widgetIdToDashboardIdMap = {};
              Object.keys(standardDashboard.trendDefinitionMap).forEach((widgetId: string) => {
                widgetIdToDashboardIdMap[widgetId] = standardDashboard.dashboardType;
              });
              return [
                DashboardActions.setWidgetIdToDashboardIdMap({ widgetIdToDashboardIdMap }),
                DashboardActions.loadStandardDashboardSuccess({ standardDashboard, preferenceDataNeeded }),
              ];
            }),
            catchError((error: WebError) => of(DashboardActions.loadStandardDashboardFailure({ error }))),
          );
        },
      ),
    ),
  );

  /**
   * loadStandardDashboardSuccess$
   * Triggers loading data after loaded standard dashboard definition
   * SET_NETWORK_INSIGHTS_GROUP_BY and SET_NETWORK_INSIGHTS_DETAILS_GROUP_BY jumps in at the trend definiton override level
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadStandardDashboardSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DashboardActions.loadStandardDashboardSuccess,
        DashboardActions.loadStandardDashboardFailure,
        AppsDashboardActions.setNetworkInsightsGroupBy,
        AppsDashboardActions.setNetworkInsightsDetailsGroupBy,
      ),
      map(() => DashboardActions.loadVisibleWidgetSubtypes({ widgetSubtypes: undefined })),
    ),
  );

  /**
   * addVisibleWidgetSubtypes$
   * @memberof DashboardEffects
   */
  public addVisibleWidgetSubtypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.addVisibleWidgetSubtypes),
      map(({ widgetSubtypes }: ReturnType<typeof DashboardActions.addVisibleWidgetSubtypes>) => {
        return DashboardActions.loadVisibleWidgetSubtypes({ widgetSubtypes });
      }),
    ),
  );

  /**
   * pushDrilldownEvent$
   * @memberof DashboardEffects
   */
  public pushDrilldownEvent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.pushDrilldownEvent),
      map(({ widgetId }: ReturnType<typeof DashboardActions.pushDrilldownEvent>) => {
        return DashboardActions.loadVisibleWidgetSubtypes({ widgetSubtypes: [widgetId] });
      }),
    ),
  );

  /**
   * clearDrilldownEvents$
   * @memberof DashboardEffects
   */
  public clearDrilldownEvents$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.clearDrilldownEvents),
      withLatestFrom(
        this.store.select(DashboardSelectors.getWidgetsById),
        ({ widgetId }: ReturnType<typeof DashboardActions.clearDrilldownEvents>, widgetsById: Record<string, AggregationWidget>) => {
          if (widgetsById?.[widgetId]) {
            return DashboardActions.loadWidgetData({ widget: widgetsById[widgetId] });
          }
          return DashboardActions.loadVisibleWidgetSubtypes({ widgetSubtypes: [widgetId] });
        },
      ),
    ),
  );

  /**
   * popDrilldownEvent$
   * @memberof DashboardEffects
   */
  public popDrilldownEvent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.popDrilldownEvent),
      map(({ widgetId }: ReturnType<typeof DashboardActions.popDrilldownEvent>) => {
        return DashboardActions.loadVisibleWidgetSubtypes({ widgetSubtypes: [widgetId] });
      }),
    ),
  );

  /**
   * addWidgetDrilldownTrail$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public addWidgetDrilldownTrail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.addWidgetDrilldownTrail),
      map(({ drilldownTrail }: ReturnType<typeof DashboardActions.addWidgetDrilldownTrail>) => {
        return DashboardActions.pushDrilldownEvent({
          widgetId: drilldownTrail.widgetId,
          drilldownEvent: drilldownTrail.drilldownEvent,
        });
      }),
    ),
  );

  /**
   * popWidgetDrilldownTrail$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public popWidgetDrilldownTrail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.popWidgetDrilldownTrail),
      withLatestFrom(this.store.select(DashboardSelectors.getWidgetDrilldownTrail)),
      mergeMap(([{ drilldownKey }, drilldownTrails]: [ReturnType<typeof DashboardActions.popWidgetDrilldownTrail>, DrilldownTrail[]]) => {
        const actions = [];
        let trailIndex = drilldownTrails.length - 1;
        while (trailIndex >= 0) {
          const trail = drilldownTrails[trailIndex];
          if (trail.drilldownKey !== drilldownKey) {
            actions.push(DashboardActions.popDrilldownEventWithoutLoad({ widgetId: trail.widgetId }));
          } else {
            break;
          }
          trailIndex--;
        }
        if (drilldownTrails.length) {
          actions.push(
            DashboardActions.popWidgetDrilldownTrailSuccess({
              drilldownKey,
            }),
          );
        }
        return actions;
      }),
    ),
  );

  /**
   * popWidgetDrilldownTrailSuccess$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public popWidgetDrilldownTrailSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.popWidgetDrilldownTrailSuccess),
      withLatestFrom(this.store.select(DashboardSelectors.getWidgetDrilldownTrail)),
      map(([_, drilldownTrails]: [ReturnType<typeof DashboardActions.popWidgetDrilldownTrailSuccess>, DrilldownTrail[]]) => {
        return DashboardActions.loadVisibleWidgetSubtypes({ widgetSubtypes: [last(drilldownTrails).widgetId] });
      }),
    ),
  );

  /**
   * cleanWidgetDrilldownTrail$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public cleanWidgetDrilldownTrail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.clearWidgetDrilldownTrail),
      withLatestFrom(this.store.select(DashboardSelectors.getWidgetDrilldownTrail)),
      mergeMap(([_action, drilldownTrails]: [ReturnType<typeof DashboardActions.clearWidgetDrilldownTrail>, DrilldownTrail[]]) => {
        const widgetIds = drilldownTrails.map((trail: DrilldownTrail) => trail.widgetId);
        return [
          ...widgetIds.map((widgetId: string) => DashboardActions.clearDrilldownEvents({ widgetId })),
          DashboardActions.clearWidgetDrilldownTrailSuccess(),
        ];
      }),
    ),
  );

  /**
   * loadVisibleWidgetSubtypes$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadVisibleWidgetSubtypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadVisibleWidgetSubtypes),
      withLatestFrom(
        this.store.select(DashboardSelectors.getVisibleWidgetSubtypes),
        this.store.select(DashboardTrendDefinitionOverridesSelectors.getTrendDefinitionsByStandardWidgetSubtypeWithOverrides),
        this.store.select(DashboardSelectors.getDrilldownEventsById),
      ),
      mergeMap(
        ([{ widgetSubtypes }, visibleWidgetSubtypes, trendDefinitionsByStandardWidgetSubtype, drilldownEventsById]: [
          ReturnType<typeof DashboardActions.loadVisibleWidgetSubtypes>,
          string[],
          Record<string, TrendDefinition>,
          Record<string, ChartDrilldownEvent[]>,
        ]) => {
          const subtypesToLoad = widgetSubtypes || visibleWidgetSubtypes;

          // convert everything to compositeTrendDefinitions, then apply drilldownEvents
          const compositeTrendDefinitions = subtypesToLoad.map((subtype: string) => {
            const compositeTrendDefinition = this.tcs.getCompositeTrendDefinition(
              StandardWidgetSubtype[subtype],
              trendDefinitionsByStandardWidgetSubtype,
            );
            const drilldownEvents = drilldownEventsById[subtype];

            if (!drilldownEvents) {
              return compositeTrendDefinition;
            } else {
              const compositeTrendDefinitiona = new CompositeTrendDrilldownApplier();
              return compositeTrendDefinitiona.applyDrilldownEvents(compositeTrendDefinition, drilldownEvents);
            }
          });

          const visibleWidgetSubtypesIndex: GenericObject = {};
          const subtypesToLoadSeparately: GenericObject = {};
          compositeTrendDefinitions.forEach((compositeTrendDefinition: CompositeTrendDefinition) => {
            each(compositeTrendDefinition.trendDefinitions, (trendDefinition: TrendDefinition, subtype: string) => {
              const versionKey = trendDefinition.hasV2Attributes ? 'v2' : 'v1';
              if (DashboardConfig.WIDGET_SUBTYPES_TO_LOAD_SEPARATELY.has(subtype)) {
                if (!subtypesToLoadSeparately[versionKey]) {
                  subtypesToLoadSeparately[versionKey] = {};
                }
                subtypesToLoadSeparately[versionKey][subtype] = trendDefinition;
              } else {
                if (!visibleWidgetSubtypesIndex[versionKey]) {
                  visibleWidgetSubtypesIndex[versionKey] = {};
                }
                visibleWidgetSubtypesIndex[versionKey][subtype] = trendDefinition;
              }
            });
          });

          // Some tds take a long time to load, those are loaded in a separate request
          const actions = [];
          if (isEmpty(subtypesToLoadSeparately)) {
            if (visibleWidgetSubtypesIndex.v2) {
              actions.push(
                DashboardActions.loadStandardDashboardDataV2({
                  trendDefinitionIndex: visibleWidgetSubtypesIndex.v2,
                }),
              );
            }
            if (visibleWidgetSubtypesIndex.v1) {
              actions.push(DashboardActions.loadStandardDashboardData({ trendDefinitionIndex: visibleWidgetSubtypesIndex.v1 }));
            }
          } else {
            if (visibleWidgetSubtypesIndex.v2) {
              actions.push(
                DashboardActions.loadStandardDashboardDataV2({
                  trendDefinitionIndex: visibleWidgetSubtypesIndex.v2,
                }),
              );
            }
            if (visibleWidgetSubtypesIndex.v1) {
              actions.push(DashboardActions.loadStandardDashboardData({ trendDefinitionIndex: visibleWidgetSubtypesIndex.v1 }));
            }
            if (subtypesToLoadSeparately.v2) {
              actions.push(
                DashboardActions.loadStandardDashboardDataV2({
                  trendDefinitionIndex: subtypesToLoadSeparately.v2,
                }),
              );
            }
            if (subtypesToLoadSeparately.v1) {
              actions.push(DashboardActions.loadStandardDashboardData({ trendDefinitionIndex: subtypesToLoadSeparately.v1 }));
            }
          }
          return actions;
        },
      ),
    ),
  );

  /**
   * loadStandardDashboardData$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadStandardDashboardData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadStandardDashboardData),
      filter(({ trendDefinitionIndex }: ReturnType<typeof DashboardActions.loadStandardDashboardData>) => !isEmpty(trendDefinitionIndex)),
      mergeMap(({ trendDefinitionIndex }: ReturnType<typeof DashboardActions.loadStandardDashboardData>) => {
        const widgetSubtypes = Object.keys(trendDefinitionIndex);
        return this.dashboardService.getStandardWidgetsData(trendDefinitionIndex).pipe(
          map((standardDashboardData: Map<string, Trend>) =>
            DashboardActions.loadStandardDashboardDataSuccess({ widgetSubtypes, standardDashboardData }),
          ),
          catchError((error: WebError) => of(DashboardActions.loadStandardDashboardDataFailure({ widgetSubtypes, error }))),
        );
      }),
    ),
  );

  /**
   * loadStandardDashboardDataV2$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadStandardDashboardDataV2$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadStandardDashboardDataV2),
      map((action: ReturnType<typeof DashboardActions.loadStandardDashboardDataV2>) => action.trendDefinitionIndex),
      filter((trendDefinitionIndex: TrendDefinitionIndex) => !isEmpty(trendDefinitionIndex)),
      mergeMap((trendDefinitionIndex: TrendDefinitionIndex) => {
        const widgetSubtypes = Object.keys(trendDefinitionIndex);
        return this.dashboardService.getStandardWidgetsData(trendDefinitionIndex, true).pipe(
          map((standardDashboardData: Map<string, Trend>) => {
            return DashboardActions.loadStandardDashboardDataSuccess({
              widgetSubtypes,
              standardDashboardData,
            });
          }),
          catchError((error: WebError) => of(DashboardActions.loadStandardDashboardDataFailure({ widgetSubtypes, error }))),
        );
      }),
    ),
  );

  /**
   * updateDashboardList$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public updateDashboardList$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DashboardActions.changePagination,
        DashboardActions.refreshDashboardList,
        DashboardActions.filterDashboards,
        DashboardActions.sortDashboards,
      ),
      withLatestFrom(this.store.select(DashboardSelectors.dashboardState)),
      switchMap(([action, state]: [any, DashboardState]) => {
        const actions: Action[] = [];
        actions.push(DashboardActions.loadDashboardFilter({ searchRequest: state.dashboardSearchRequest }));
        if (action.sorts?.length) {
          actions.push(
            UserPreferenceActions.updateUISettings({
              uiSettings: {
                [UIPreference.DASHBOARD_VIEW_SORT]: [Object.assign({}, action.sorts[0])],
              },
            }),
          );
        }
        return actions;
      }),
    ),
  );

  /**
   * loadIncidentDashboard$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadIncidentDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.fetchIncidentDashboard),
      withLatestFrom(this.store.select(DashboardSelectors.getIncidentId)),
      switchMap(([_action, incidentId]: [Action, string]) => {
        return this.dashboardService.getDashboardByIncidentId(incidentId).pipe(
          map((dashboard: Dashboard) => {
            return DashboardActions.setIncidentCustomDashboard({ dashboard });
          }),
          catchError(() => of(DashboardActions.resetIncidentCustomDashboard())),
        );
      }),
    ),
  );

  /**
   * loadDashboardFilter$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadDashboardFilter$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadDashboardFilter),
      withLatestFrom(
        this.store.select(DashboardSelectors.getDashboardListFilter),
        this.store.select(DashboardSelectors.getSearchDashboardType),
      ),
      switchMap(
        ([{ searchRequest }, listFilter, type]: [ReturnType<typeof DashboardActions.loadDashboardFilter>, DashboardTagFilter, string]) => {
          const modifiedRequest = new DashboardSearchRequest(getModifiedSearchRequest(searchRequest, listFilter, type));
          return this.dashboardService.searchDashboards(modifiedRequest).pipe(
            map((searchResponse: DashboardSearchResponse) => DashboardActions.loadDashboardFilterSuccess({ searchResponse })),
            catchError((error: WebError) => of(DashboardActions.loadDashboardFilterFailure({ error }))),
          );
        },
      ),
    ),
  );

  /**
   * updateDashboardListFilter$
   *
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public updateDashboardListFilter$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.updateDashboardListFilter),
      withLatestFrom(
        this.store.select(DashboardSelectors.getDashboardSearchRequest),
        this.store.select(DashboardSelectors.getDashboardBookmarkFilterEnabled),
        this.store.select(DashboardSelectors.getDashboardListFilter),
        this.store.select(DashboardSelectors.getSearchDashboardType),
      ),
      switchMap(
        ([_action, request, isBookmarkFilterEnabled, listFilter, type]: [
          Action,
          DashboardSearchRequest,
          boolean,
          DashboardTagFilter,
          string,
        ]) => {
          const modifiedRequest = new DashboardSearchRequest(getModifiedSearchRequest(request, listFilter, type));
          // INTEL-23196 note: remove the listFilter until 'v1/dashboard/search' searchTerms fix
          return this.dashboardService.searchDashboards(modifiedRequest).pipe(
            switchMap((searchResponse: DashboardSearchResponse) => {
              return [
                BookmarksActions.toggleBookmarkFilter({ isBookmarkFilterEnabled }),
                DashboardActions.loadDashboardFilterSuccess({ searchResponse }),
                DashboardActions.updateDashboardListFilterSuccess({ request }),
              ];
            }),
            catchError((error: WebError) => of(DashboardActions.loadDashboardFilterFailure({ error }))),
          );
        },
      ),
    ),
  );

  /**
   * setWidgetRangeFilter$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public setWidgetRangeFilter$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.setWidgetRangeFilter),
      switchMap(({ dashboardId, widgetId, widgetPreferences }: ReturnType<typeof DashboardActions.setWidgetRangeFilter>) => {
        return this.dashboardService.saveWidgetPreferences(widgetPreferences, widgetId, dashboardId).pipe(
          switchMap(() => {
            return [DashboardActions.setWidgetRangeFilterSuccess()];
          }),
          catchError(() => [
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate('DASHBOARD_ACTIONS.EDIT_RANGE_FAILURE_MSG'),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * saveEditThemeWidget$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public saveEditThemeWidget$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.saveEditThemeWidget),
      withLatestFrom(this.store.select(DashboardSelectors.getActiveWidget)),
      switchMap(([{ colorSchemas }, widget]: [ReturnType<typeof DashboardActions.saveEditThemeWidget>, AggregationWidget]) => {
        // if attribute name is present in preference take it otherwise set it from bucketing attribute
        const attributeName: string =
          widget.widgetAttributePreferences?.[0].attributeName || widget.trend?.trendDefinition?.bucketingAttributes?.[0];
        const widgetAttributePreferences: WidgetPreference[] = [
          new WidgetPreference({
            ...widget.widgetAttributePreferences?.[0],
            colorSchemas,
            attributeName,
          }),
        ];
        const widgetPreferences: WidgetPreferences = new WidgetPreferences({
          widgetAttributePreferences,
        });
        return this.dashboardService.saveWidgetPreferences(widgetPreferences, widget?.id, widget?.dashboardId).pipe(
          switchMap(() => {
            return [
              DashboardActions.setActiveWidgetDialogMode({ mode: ActiveWidgetDialogMode.CLOSE }),
              DashboardActions.setWidgetAttributePreferences({
                widgetId: widget?.id,
                widgetPreferences,
              }),
            ];
          }),
          catchError(() => [
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.MODAL,
              message: this.i18nService.translate('DASHBOARD_ACTIONS.EDIT_THEME_FAILURE_MSG'),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * loadCompositeWidgetDetail
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadCompositeWidgetDetail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadCompositeWidgetDetail),
      withLatestFrom(this.store.pipe(select(DashboardSelectors.getWidgetDetailCompositeTrendDefinitionDependencies))),
      switchMap(([_action, trendDefinitionIndex]: [Action, TrendDefinitionIndex]) => {
        const isV2 = Object.values(trendDefinitionIndex).every((trendDefinition: TrendDefinition) => {
          return trendDefinition.isV2;
        });
        return this.dashboardService.getStandardWidgetsData(trendDefinitionIndex, isV2).pipe(
          map((trends: Map<string, Trend>) => DashboardActions.loadCompositeWidgetDetailSuccess({ compositeTrendData: trends })),
          catchError((webError: WebError) => of(DashboardActions.loadCompositeWidgetDetailFailure({ webError }))),
        );
      }),
    ),
  );

  /**
   * removeWidgetFromBookmarks$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public removeWidgetFromBookmarks$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.removeWidgetFromBookmarks),
      withLatestFrom(this.store.select(BookmarksSelectors.getActiveBookmarksMap)),
      filter(([{ widget }, activeBookmarksMap]: [ReturnType<typeof DashboardActions.removeWidgetFromBookmarks>, Map<string, Bookmark>]) =>
        activeBookmarksMap.has(widget.id),
      ),
      switchMap(([{ widget }]: [ReturnType<typeof DashboardActions.removeWidgetFromBookmarks>, Map<string, Bookmark>]) => {
        return of(BookmarksActions.removeBookmarksFromCache({ resourceIds: [widget.id] }));
      }),
    ),
  );

  /**
   * requestDeleteDashboardSuccess$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public requestDeleteDashboardSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.requestDeleteDashboardSuccess),
      withLatestFrom(this.store.select(BookmarksSelectors.getActiveBookmarksMap)),
      filter(
        ([{ dashboard }, activeBookmarksMap]: [ReturnType<typeof DashboardActions.requestDeleteDashboardSuccess>, Map<string, Bookmark>]) =>
          activeBookmarksMap.has(dashboard.id),
      ),
      switchMap(([{ dashboard }]: [ReturnType<typeof DashboardActions.requestDeleteDashboardSuccess>, Map<string, Bookmark>]) => {
        return of(BookmarksActions.removeBookmarksFromCache({ resourceIds: [dashboard.id] }));
      }),
    ),
  );

  /**
   * updateDashboardSuccess$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public updateDashboardSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.updateDashboardSuccess),
      withLatestFrom(this.store.select(BookmarksSelectors.getActiveBookmarksMap)),
      filter(([{ dashboard }, activeBookmarksMap]: [ReturnType<typeof DashboardActions.updateDashboardSuccess>, Map<string, Bookmark>]) =>
        activeBookmarksMap.has(dashboard.id),
      ),
      switchMap(
        ([{ dashboard }, activeBookmarksMap]: [ReturnType<typeof DashboardActions.updateDashboardSuccess>, Map<string, Bookmark>]) => {
          const bookmark: Bookmark = activeBookmarksMap.get(dashboard.id);
          return of(
            BookmarksActions.updateBookmark({
              bookmark: new Bookmark({
                ...bookmark,
                title: dashboard.name,
              }),
            }),
          );
        },
      ),
    ),
  );

  /**
   * updateWidgetSuccess$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public updateWidgetSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.updateWidgetSuccess),
      withLatestFrom(this.store.select(BookmarksSelectors.getActiveBookmarksMap)),
      filter(([{ widget }, activeBookmarksMap]: [ReturnType<typeof DashboardActions.updateWidgetSuccess>, Map<string, Bookmark>]) =>
        activeBookmarksMap.has(widget.id),
      ),
      switchMap(([{ widget }, activeBookmarksMap]: [ReturnType<typeof DashboardActions.updateWidgetSuccess>, Map<string, Bookmark>]) => {
        const bookmark: Bookmark = activeBookmarksMap.get(widget.id);
        return of(
          BookmarksActions.updateBookmark({
            bookmark: new Bookmark({
              ...bookmark,
              title: widget.name,
            }),
          }),
        );
      }),
    ),
  );

  /**
   * goToWidgetIdDetailPage$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public goToWidgetIdDetailPage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.goToWidgetIdDetailPage),
      withLatestFrom(
        this.store.select(DashboardTrendDefinitionOverridesSelectors.getTrendDefinitionsByStandardWidgetSubtypeWithOverrides),
        this.store.select(DashboardSelectors.getDrilldownEventsById),
      ),
      map(
        ([{ widgetDetailPage }, tdsById, drilldownEventsById]: [
          ReturnType<typeof DashboardActions.goToWidgetIdDetailPage>,
          Record<string, TrendDefinition>,
          Record<string, ChartDrilldownEvent[]>,
        ]) => {
          const widgetId = widgetDetailPage.widgetDetailDefinition.widgetId;
          const drilldownEvents = drilldownEventsById[widgetId];
          // load trendDefinition from widgetDetailPage in case of no getTrendDefinitionsByStandardWidgetSubtypeWithOverrides
          const trendDefinition = isEmpty(tdsById[widgetId]) ? widgetDetailPage.widgetDetailDefinition.trendDefinition : tdsById[widgetId];
          const nextWidgetDetailDefinition = Object.assign(new WidgetDetailDefinition(), {
            ...widgetDetailPage.widgetDetailDefinition,
            trendDefinition,
          });
          const nextWidgetDetailPage = {
            ...widgetDetailPage,
            drilldownEvents,
            widgetDetailDefinition: nextWidgetDetailDefinition,
          } as WidgetDetailPage;
          return DashboardActions.goToWidgetDetailPage(nextWidgetDetailPage);
        },
      ),
    ),
  );

  /**
   * goToAppInsightWidgetDetail$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public goToAppInsightWidgetDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.goToAppInsightWidgetDetail),
      withLatestFrom(this.store.select(DashboardTrendDefinitionOverridesSelectors.getTrendDefinitionsByStandardWidgetSubtypeWithOverrides)),
      map(
        ([appInsightWidgetDetail, tdsById]: [
          ReturnType<typeof DashboardActions.goToAppInsightWidgetDetail>,
          Record<string, TrendDefinition>,
        ]) => {
          // widgetSubType
          const compositeTrendDefinition = this.tcs.getCompositeTrendDefinition(appInsightWidgetDetail.widgetSubType, tdsById);
          // Standard Dasboard call adds in "startDateMillis" and "endDateMillis" values of 0
          each(compositeTrendDefinition.trendDefinitions, (trendDefinition: TrendDefinition) => {
            if (!trendDefinition.dateRange.endDateMillis) {
              delete trendDefinition.dateRange.startDateMillis;
              delete trendDefinition.dateRange.endDateMillis;
            }
            if (appInsightWidgetDetail.platform !== AppPlatform.APPLE_IOS) {
              const newEntities = [];
              trendDefinition.entitiesByIntegration.apteligent.forEach((entity: string) => {
                newEntities.push(entity.replace(DashboardConfig.entityByTypeExtension.IOS, DashboardConfig.entityByTypeExtension.ANDROID));
              });
              trendDefinition.entitiesByIntegration.apteligent = newEntities;
            }
          });
          const nextWidgetDetailPage = {
            drilldownEvents: [],
            widgetDetailDefinition: new WidgetDetailDefinition({
              chartType: AggregationWidgetChartType.LINE,
              compositeTrendDefinition,
              chartTitle: this.i18nService.translate(WidgetSubTypeToPageTitle[appInsightWidgetDetail.widgetSubType]),
              returnCrumbs: appInsightWidgetDetail.detailsReturnCrumbs,
              showTable: true,
            }),
          } as WidgetDetailPage;
          return DashboardActions.goToWidgetDetailPage(nextWidgetDetailPage);
        },
      ),
    ),
  );

  /**
   * requestAddWidget$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public requestAddWidget$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.requestAddWidget),
        withLatestFrom(this.store.select(DashboardSelectors.getRootUrl)),
        tap(([{ dashboard }, rootUrl]: [ReturnType<typeof DashboardActions.requestAddWidget>, string]) => {
          return this.routerExtensions.navigate([ROUTE_NAMES.DASHBOARD.ADD_WIDGET(rootUrl, dashboard.id)]);
        }),
      ),
    { dispatch: false },
  );

  /**
   * requestAddCustomWidget$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public requestAddCustomWidget$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.requestAddCustomWidget),
        withLatestFrom(this.store.select(DashboardSelectors.getRootUrl)),
        tap(([payload, rootUrl]: [ReturnType<typeof DashboardActions.requestAddCustomWidget>, string]) =>
          this.routerExtensions.navigate([ROUTE_NAMES.DASHBOARD.ADD_WIDGET(rootUrl, payload.dashboard.id)], {
            queryParams: { [AppConfig.QUERY_PARAM_CUSTOM]: true },
          }),
        ),
      ),
    { dispatch: false },
  );

  /**
   * requestUpdateWidget$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public requestUpdateWidget$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.requestUpdateWidget),
        withLatestFrom(this.store.select(DashboardSelectors.getRootUrl)),
        tap(([{ widget }, rootUrl]: [ReturnType<typeof DashboardActions.requestUpdateWidget>, string]) => {
          return this.routerExtensions.navigate([ROUTE_NAMES.DASHBOARD.EDIT_WIDGET(rootUrl, widget.dashboardId, widget.id)]);
        }),
      ),
    { dispatch: false },
  );

  /**
   * shareDashboard$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public shareDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.shareDashboard),
      withLatestFrom(
        this.store.select(DashboardSelectors.getDashboardActionOrigin),
        this.store.select(DashboardSelectors.getDashboardDialogModel),
        this.store.select(DashboardSelectors.getDashboardRecipients),
      ),
      switchMap(
        ([_action, actionOrigin, dashboardDialogModel, dashboardRecipients]: [
          Action,
          DashboardActionOrigin,
          Dashboard | DashboardView,
          UserAdminAccount[],
        ]) => {
          const userShareDetails: UserAccess[] = dashboardRecipients.map(
            (account: UserAdminAccount) =>
              new UserAccess({
                userDescriptor: new UserDescriptor({
                  id: account.id,
                }),
                accessLevel: account.accessLevel,
              }),
          );
          return this.dashboardService.shareDashboard(dashboardDialogModel.id, userShareDetails).pipe(
            switchMap(() => [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.SUCCESS,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.SHARE_DASHBOARD_SUCCESS_MSG'),
              }),
              DashboardActions.getDashboardRecipientsSuccess(),
              DashboardActions.closeDashboardDialog(),
              DashboardActions.addDashboardRecipients({ userAdminAccounts: [] }),
              actionOrigin === DashboardActionOrigin.DETAILS
                ? DashboardActions.loadDashboard({ id: dashboardDialogModel.id })
                : DashboardActions.loadDashboardList(),
            ]),
            catchError(() => [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.SHARE_DASHBOARD_FAILURE_MSG'),
              }),
              DashboardActions.getDashboardRecipientsFailure(),
            ]),
          );
        },
      ),
    ),
  );

  /**
   * getDashboardRecipients$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public getDashboardRecipients$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.getDashboardRecipients),
      switchMap(({ dashboardId }: ReturnType<typeof DashboardActions.getDashboardRecipients>) => {
        return this.dashboardService.getDashboardRecipients(dashboardId).pipe(
          switchMap((userAdminAccounts: UserAdminAccount[]) => {
            return [DashboardActions.getDashboardRecipientsSuccess(), DashboardActions.addDashboardRecipients({ userAdminAccounts })];
          }),
          catchError(() =>
            of(
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.GET_DASHBOARD_RECIPIENTS_FAILURE_MESSAGE'),
              }),
            ),
          ),
        );
      }),
    ),
  );

  /**
   * transferOwnershipForDashboards$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public transferOwnershipForDashboards$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.transferOwnershipForDashboards),
      withLatestFrom(
        this.store.select(DashboardSelectors.getDashboardsDialogModel),
        this.store.select(DashboardSelectors.isTransferOwnershipActionOriginFromSystemLimitsPage),
      ),
      switchMap(
        ([{ newOwner }, dashboardDialogModel, isTransferOwnershipActionOriginFromSystemLimitsPage]: [
          ReturnType<typeof DashboardActions.transferOwnershipForDashboards>,
          Dashboard[],
          boolean,
        ]) => {
          const identifiersRequest: IdentifiersRequest = new IdentifiersRequest({
            identifiers: dashboardDialogModel.map((dashbaord: Dashboard) => dashbaord.id),
          });
          const transferOwnershipRequest: TransferOwnershipRequest = new TransferOwnershipRequest({
            newOwner,
            identifiersRequest,
          });
          return this.dashboardService.transferOwnershipForDashboards(transferOwnershipRequest).pipe(
            switchMap(() => {
              const actions: Action[] = [DashboardActions.closeDashboardDialog()];
              if (isTransferOwnershipActionOriginFromSystemLimitsPage) {
                actions.push(
                  AlertBannerActions.showAlertBanner({
                    alertType: ALERT_BANNER_TYPE.SUCCESS,
                    target: AlertBannerTarget.SECTION,
                    message: this.i18nService.translate('SYSTEM_LIMITS.TRANSFER_OWNERSHIP_SUCCESS_MSG', {
                      count: dashboardDialogModel.length,
                    }),
                  }),
                );
                actions.push(
                  DashboardActions.selectDashboards({
                    selectedDashboards: [],
                  }),
                );
                actions.push(SystemLimitsActions.refreshUserDashboards());
                actions.push(SystemLimitsActions.loadUserDashboardsUsageSummary());
                actions.push(SystemLimitsActions.loadUserServiceLimits());
              } else {
                actions.push(
                  AlertBannerActions.showAlertBanner({
                    alertType: ALERT_BANNER_TYPE.SUCCESS,
                    message: this.i18nService.translate('DASHBOARD_ACTIONS.TRANSFER_OWNERSHIP_DASHBOARDS_SUCCESS_MSG', {
                      count: dashboardDialogModel.length,
                    }),
                  }),
                );
                actions.push(DashboardActions.loadDashboardList());
              }
              return actions;
            }),
            catchError(() => [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.TRANSFER_OWNERSHIP_DASHBOARDS_FAILURE_MSG'),
              }),
            ]),
          );
        },
      ),
    ),
  );

  /**
   * setTableTrendDefinitionsById$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public setTableTrendDefinitionsById$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.setTableTrendDefinitionsById),
      filter(
        ({ trendDefinitionsById }: ReturnType<typeof DashboardActions.setTableTrendDefinitionsById>) => !isEmpty(trendDefinitionsById),
      ),
      mergeMap(({ trendDefinitionsById }: ReturnType<typeof DashboardActions.setTableTrendDefinitionsById>) => {
        const isV2 = Object.values(trendDefinitionsById).every((trendDefinition: TrendDefinition) => {
          return trendDefinition.isV2;
        });
        return this.dashboardService.getStandardWidgetsData(trendDefinitionsById, isV2).pipe(
          map((results: Map<string, Trend>) => {
            const tableTrendsById = fromPairs([...results.entries()]);
            return DashboardActions.setTableTrendsById({ tableTrendsById });
          }),
          catchError((error: WebError) =>
            of(DashboardActions.loadStandardDashboardDataFailure({ widgetSubtypes: Object.keys(trendDefinitionsById), error })),
          ),
        );
      }),
    ),
  );

  /**
   * getUnownedDashboards$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public getUnownedDashboards$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.getUnownedDashboards),
      switchMap(() => {
        return this.dashboardService
          .searchDashboards(
            new DashboardSearchRequest({
              size: 1,
              searchTerms: [
                new SearchTerm({
                  value: 'true',
                  fields: [SearchTagFilterField.IS_ORPHANED],
                }),
              ],
              sortOns: [
                new SortOn({
                  by: COLUMN_NAMES.byName.modified_at,
                }),
              ],
            }),
          )
          .pipe(
            filter((response: DashboardSearchResponse) => response.total > 0),
            map((response: DashboardSearchResponse) =>
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.WARNING,
                target: AlertBannerTarget.SECTION,
                autoDismiss: false,
                actionText: this.i18nService.translate('COMMON_ACTIONS.VIEW'),
                message: this.i18nService.translate('DASHBOARD_ACTIONS.UNOWNED_DASHBOARD_MESSAGE', {
                  count: response.total,
                }),
              }),
            ),
            catchError(() => of(DashboardActions.getUnownedDashboardsFailure())),
          );
      }),
    ),
  );

  /**
   * getSelectedDashboardOwnersDAPIds$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public getSelectedDashboardOwnersDAPIds$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.getSelectedDashboardOwnersDAPIds),
      withLatestFrom(this.store.select(DashboardSelectors.getDashboardsDialogModel)),
      switchMap(([_action, dashboardDialogModel]: [Action, Dashboard[]]) => {
        const identifiersRequest: IdentifiersRequest = new IdentifiersRequest({
          identifiers: dashboardDialogModel.map((dashboard: Dashboard) => dashboard?.createdByDetails?.id),
        });
        return this.accountService.getAccountAssignmentsByIds(identifiersRequest).pipe(
          map((accounts: Account[]) => {
            const selectedDashboardOwnersDAPIds: Set<string> = new Set();
            accounts.forEach((account: Account) => {
              if (account.dataAccessPolicies?.[0]?.id) {
                selectedDashboardOwnersDAPIds.add(account.dataAccessPolicies[0].id);
              }
            });
            return DashboardActions.getSelectedDashboardOwnersDAPIdsSuccess({
              selectedDashboardOwnersDAPIds,
            });
          }),
          catchError(() => [
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.MODAL,
              message: this.i18nService.translate('COMMON_ERRORS.TRANSFER_OWNERSHIP_DAP_INFO_FAILURE_MSG'),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * loadRiskIndicatorDetails$
   * @type {Observable<Action>}
   * @memberOf DashboardEffects
   */
  public loadRiskIndicatorDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadRiskIndicatorDetails),
      withLatestFrom(this.store.select(DashboardSelectors.getResolvedWidgetDetailTrendDateRange)),
      switchMap(([action, trendDateRange]: [ReturnType<typeof DashboardActions.loadRiskIndicatorDetails>, TrendDateRange]) => {
        const scoreTypeList: string = action.indicators.join("','");
        const timestamp: number = action.timestamp;
        const getFqn = getFQNFunction(Integration.AIRWATCH, Entity.DEVICE_RISK_SCORE);
        const filterString = [
          `${getFqn(COLUMN_NAMES.byName.device_guid)} = '${action.guid}'`,
          `AND ${getFqn(COLUMN_NAMES.byName.score_type)} IN ('${scoreTypeList}')`,
          `AND ${getFqn(COLUMN_NAMES.byName.score_calculated_at)} = ${timestamp}`,
        ].join(' ');
        const request = new PreviewReportContentRequest({
          filter: filterString,
          fields: [
            getFqn(COLUMN_NAMES.byName.score_type),
            getFqn(COLUMN_NAMES.byName.score_type_name),
            getFqn(COLUMN_NAMES.byName.score_meta_data),
          ],
          entitiesByIntegration: {
            airwatch: [Entity.DEVICE_RISK_SCORE],
          },
          startDateMillis: trendDateRange.startDateMillis,
          endDateMillis: trendDateRange.endDateMillis,
          offset: 0,
          pageSize: 10,
          sortOns: [],
        });
        return this.reportMetaService.previewReportContentForMultiIntegrationV2(request).pipe(
          map((response: CustomReportPreviewSearchResponse) =>
            DashboardActions.loadRiskIndicatorDetailsSuccess({
              guid: action.guid,
              timestamp,
              results: response.results,
            }),
          ),
          catchError(() =>
            of(
              DashboardActions.loadRiskIndicatorDetailsFailure({
                guid: action.guid,
                timestamp,
                results: [],
              }),
            ),
          ),
        );
      }),
    ),
  );

  /**
   * initializeIssueDetail$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public initializeIssueDetail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.initializeIssueDetail),
      withLatestFrom(this.store.select(DashboardSelectors.getWidgetDetailPage)),
      switchMap(([_action, issueDetailPage]: [Action, WidgetDetailPage]) => {
        const widgetDetailUrl: string = helper.getWidgetDetailPageUrl(issueDetailPage, this.routerExtensions);
        return [DashboardActions.setWidgetDetailUrl({ widgetDetailUrl }), DashboardActions.loadCompositeIssueDetail()];
      }),
    ),
  );

  /**
   * loadCompositeIssueDetail$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadCompositeIssueDetail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadCompositeIssueDetail),
      withLatestFrom(this.store.select(DashboardSelectors.getWidgetDetailCompositeTrendDefinitionDependencies)),
      switchMap(([_action, trendDefinitionIndex]: [Action, TrendDefinitionIndex]) => {
        return this.dashboardService.getStandardWidgetsData(trendDefinitionIndex).pipe(
          map((trends: Map<string, Trend>) => DashboardActions.loadCompositeIssueDetailSuccess({ compositeTrendData: trends })),
          catchError((webError: WebError) => of(DashboardActions.loadCompositeIssueDetailFailure({ webError }))),
        );
      }),
    ),
  );

  /**
   * updateCompositeIssueDetailSubtype
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public updateCompositeIssueDetailSubtype$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadCompositeIssueDetailSuccess),
      withLatestFrom(
        this.store.select(DashboardSelectors.getWidgetDetailSelectedCompositeTableSubtype),
        this.store.select(DashboardSelectors.getWidgetDetailSortOns),
      ),
      map(([_action, widgetSubtype, sortOns]: [Action, string, SortOn[]]) => {
        return DashboardActions.setWidgetDetailSelectedCompositeTableSubtype({ widgetSubtype, sortOns });
      }),
    ),
  );

  /**
   * getDefaultWidgetColumns$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public getDefaultWidgetColumns$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.getDefaultWidgetColumns),
      withLatestFrom(
        this.store.select(IntegrationMetaSelectors.getCategoriesByCategoryId),
        this.store.select(DashboardSelectors.getDefaultColumns),
        this.store.select(DashboardSelectors.getDefaultNonJoinColumns),
        (
          { categoryId, isCrossCategory = false }: ReturnType<typeof DashboardActions.getDefaultWidgetColumns>,
          columnsByCategory: CategoryIndex,
          defaultColumns: Record<string, string[]>,
          defaultNonJoinColumns: Record<string, string[]>,
        ) => {
          const columns = isCrossCategory ? defaultColumns[categoryId] : defaultNonJoinColumns[categoryId];
          return {
            defaultColumnsLoaded: !!columns,
            category: columnsByCategory[categoryId],
            isCrossCategory,
          };
        },
      ),
      filter(({ defaultColumnsLoaded }) => !defaultColumnsLoaded),
      switchMap(({ category, isCrossCategory }) => {
        return this.dashboardService
          .getDefaultAttributesForIntegrationEntity(category.integration.name, category.entity.name, !isCrossCategory)
          .pipe(
            map((columns: string[]) => DashboardActions.getDefaultWidgetColumnsSuccess({ columns })),
            catchError(() => of(DashboardActions.getDefaultWidgetColumnsFailure())),
          );
      }),
    ),
  );

  /**
   * importDashboardTemplates$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public importDashboardTemplates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.importDashboardTemplates),
      switchMap(({ templateIds }: ReturnType<typeof DashboardActions.importDashboardTemplates>) => {
        return this.dashboardService.importDashboardsByTemplateIds(templateIds).pipe(
          map(() => DashboardActions.importDashboardTemplatesSuccess()),
          catchError(() => [
            DashboardActions.importDashboardTemplatesFailure(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate('DEEM_SETUP.FAILED_ENABLING_FRONTLINE'),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * setActiveWidgetDialogMode
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public setActiveWidgetDialogMode$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.setActiveWidgetDialogMode),
      map(({ mode }: ReturnType<typeof DashboardActions.setActiveWidgetDialogMode>) => {
        if (mode === ActiveWidgetDialogMode.CLOSE) {
          return DashboardActions.unsetActiveWidget();
        }
      }),
    ),
  );

  /**
   * createWidgetFromDataExplorer$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public createWidgetFromDataExplorer$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.createWidgetFromDataExplorer),
      withLatestFrom(this.store.select(DashboardSelectors.getActiveWidget), this.store.select(DashboardSelectors.dashboardState)),
      switchMap(
        ([{ dashboardId }, widget, dashboardState]: [
          ReturnType<typeof DashboardActions.createWidgetFromDataExplorer>,
          AggregationWidget,
          DashboardState,
        ]) => {
          return this.dashboardService
            .createWidget(
              new AggregationWidget({
                ...widget,
                dashboardId,
                ...this.getNewWidgetPosition(dashboardState),
              }),
            )
            .pipe(
              mergeMap((newWidget: AggregationWidget) => {
                return [
                  AlertBannerActions.showAlertBanner({
                    alertType: ALERT_BANNER_TYPE.SUCCESS,
                    message: this.i18nService.translate('DASHBOARD_ACTIONS.ADD_WIDGET_SUCCESS_MSG'),
                  }),
                  DashboardActions.addWidgetSuccess({ widget: newWidget }),
                  DashboardActions.goToDashboardPage({ dashboardId }),
                ];
              }),
              catchError((error: WebError) => {
                const reason = getFailureReason(
                  error,
                  this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_WIDGET_KEY_DUPLICATE_ERROR'),
                  this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_WIDGET_GENERIC_ERROR'),
                );
                return [
                  AlertBannerActions.showAlertBanner({
                    alertType: ALERT_BANNER_TYPE.DANGER,
                    target: AlertBannerTarget.MODAL,
                    message: this.i18nService.translate('DASHBOARD_ACTIONS.ADD_WIDGET_ERROR_MSG', { reason }),
                  }),
                  DashboardActions.addWidgetFailure({ error }),
                ];
              }),
            );
        },
      ),
    ),
  );

  /**
   * addWidgetInNewDashboard$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public addWidgetInNewDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.addWidgetInNewDashboard),
      switchMap(({ name, description }: ReturnType<typeof DashboardActions.addWidgetInNewDashboard>) => {
        return this.dashboardService.createDashboard(new Dashboard({ name, description })).pipe(
          mergeMap((dashboardView: DashboardView) => {
            return [DashboardActions.createWidgetFromDataExplorer({ dashboardId: dashboardView.id })];
          }),
          catchError((error: WebError) => {
            const reason = getFailureReason(
              error,
              this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_DASHBOARD_KEY_DUPLICATE_ERROR'),
              this.i18nService.translate('DASHBOARD_ACTIONS.SAVE_UPDATE_DASHBOARD_GENERIC_ERROR'),
            );
            return [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.ADD_DASHBOARD_ERROR_MSG', { reason }),
              }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * loadIncrementalWidgetDataById$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadIncrementalWidgetDataByWidgetId$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.loadIncrementalWidgetDataByWidgetId),
      bufferTime(AppConfig.WIDGETS_INCREMENTAL_LOADING_CONFIG.BUFFER_SPAN),
      map((trackingIdDetailsArray: Array<ReturnType<typeof DashboardActions.loadIncrementalWidgetDataByWidgetId>>) => {
        const acc = new Map<string, IncrementalLoadingWidgetDetails>();
        trackingIdDetailsArray.forEach(
          ({ trackingId, details }: ReturnType<typeof DashboardActions.loadIncrementalWidgetDataByWidgetId>) => {
            acc.set(trackingId, details);
          },
        );
        return acc;
      }),
      filter((trackingIdDetailsMap: Map<string, IncrementalLoadingWidgetDetails>) => trackingIdDetailsMap.size > 0),
      mergeMap((trackingIdDetailsMap: Map<string, IncrementalLoadingWidgetDetails>) => {
        return [
          DashboardActions.loadIncrementalWidgetData({
            trackingIdDetailsMap,
            isWidgetIdsMap: true,
          }),
        ];
      }),
    );
  });

  /**
   * loadIncrementalWidgetDataByTrendDefinition$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadIncrementalWidgetDataByTrendDefinition$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.loadIncrementalWidgetDataByTrendDefinition),
      bufferTime(AppConfig.WIDGETS_INCREMENTAL_LOADING_CONFIG.BUFFER_SPAN),
      map((trackingIdDetailsArray: Array<ReturnType<typeof DashboardActions.loadIncrementalWidgetDataByTrendDefinition>>) => {
        const acc = new Map<string, IncrementalLoadingWidgetDetails>();
        trackingIdDetailsArray.forEach(
          ({ trackingId, details }: ReturnType<typeof DashboardActions.loadIncrementalWidgetDataByTrendDefinition>) => {
            acc.set(trackingId, details);
          },
        );
        return acc;
      }),
      filter((trackingIdDetailsMap: Map<string, IncrementalLoadingWidgetDetails>) => trackingIdDetailsMap.size > 0),
      mergeMap((trackingIdDetailsMap: Map<string, IncrementalLoadingWidgetDetails>) => {
        return [DashboardActions.loadIncrementalWidgetData({ trackingIdDetailsMap })];
      }),
    );
  });

  /**
   * loadIncrementalWidgetData
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadIncrementalWidgetData$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.loadIncrementalWidgetData),
      withLatestFrom(this.store.select(DashboardSelectors.getTrackingIdDetailsMap)),
      switchMap(
        ([{ trackingIdDetailsMap, isWidgetIdsMap }, existingTrackingIdDetailsMap]: [
          ReturnType<typeof DashboardActions.loadIncrementalWidgetData>,
          Map<string, IncrementalLoadingWidgetDetails>,
        ]) => {
          const { queryIdsToCancel, trackingIdDetailsMap: newTrackingIdDetailsMap } = helper.getUiQueryIdsToCancel(
            trackingIdDetailsMap,
            existingTrackingIdDetailsMap,
          );
          const uiQueryId = helper.getNewUiQueryId();
          const trackingIds = Array.from(newTrackingIdDetailsMap.keys());

          helper.registerUiQueryId(uiQueryId, undefined);
          helper.updateUiQueryIdVsTrackingIdsMap(uiQueryId, trackingIds);

          const actions: Action[] = [
            DashboardActions.setTrackingIdsStatusAsInProgress({ trackingIds }),
            DashboardActions.getQueryIdForTrendDefinitions({
              trackingIdDetailsMap: newTrackingIdDetailsMap,
              uiQueryId,
              isWidgetIdsMap,
            }),
          ];

          if (queryIdsToCancel?.length > 0) {
            helper.markUiQueryIdsAsDeleted(queryIdsToCancel);
            actions.unshift(DashboardActions.cancelPollingByQueryId({ uiQueryIds: queryIdsToCancel }));
          }

          return actions;
        },
      ),
    );
  });

  /**
   * getQueryIdForTrendDefinitions$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public getQueryIdForTrendDefinitions$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.getQueryIdForTrendDefinitions),
      mergeMap(({ trackingIdDetailsMap, isWidgetIdsMap, uiQueryId }: ReturnType<typeof DashboardActions.getQueryIdForTrendDefinitions>) => {
        if (!helper.isActiveUiQueryId(uiQueryId)) {
          return [DashboardActions.noop()];
        }

        let stream$: Observable<string>;

        if (isWidgetIdsMap) {
          stream$ = this.dashboardService.getQueryIdForListOfWidgetIds(Array.from(trackingIdDetailsMap.keys()));
        } else {
          const trackingIdTrendDefMap: Record<string, TrendDefinition> = {};
          for (const [trackingId, details] of trackingIdDetailsMap) {
            trackingIdTrendDefMap[trackingId] = details.trendDefinition;
          }
          stream$ = this.dashboardService.getQueryIdForTrendDefinitions(trackingIdTrendDefMap);
        }

        return stream$.pipe(
          switchMap((backendQueryId: string) => {
            if (helper.isActiveUiQueryId(uiQueryId)) {
              helper.registerUiQueryId(uiQueryId, backendQueryId);
              return [
                DashboardActions.updateTrackingIdDetailsMap({ trackingIdDetailsMap }),
                DashboardActions.initPollingWidgetDataByQueryId({ backendQueryId, uiQueryId }),
              ];
            }
            if (helper.isDeletedUiQueryId(uiQueryId)) {
              return [DashboardActions.cancelPollingByQueryId({ uiQueryIds: [uiQueryId] })];
            }
            return [DashboardActions.noop()];
          }),
        );
      }),
    );
  });

  /**
   * initPollingWidgetDataByQueryId$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public initPollingWidgetDataByQueryId$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.initPollingWidgetDataByQueryId),
      mergeMap(({ backendQueryId, uiQueryId }: ReturnType<typeof DashboardActions.initPollingWidgetDataByQueryId>) => {
        return timer(0, AppConfig.WIDGETS_INCREMENTAL_LOADING_CONFIG.POLLING_INTERVAL).pipe(
          takeUntil(
            rxJsMerge(
              this.actions$.pipe(
                ofType(DashboardActions.deleteQueryId),
                filter(({ uiQueryIds }: ReturnType<typeof DashboardActions.deleteQueryId>) => {
                  return uiQueryIds.includes(uiQueryId);
                }),
              ),
              this.router.events.pipe(filter((event: Event) => event.type === EventType.NavigationEnd)),
            ),
          ),
          map(() => {
            if (helper.isActiveUiQueryId(uiQueryId)) {
              return DashboardActions.loadWidgetDataByQueryId({ backendQueryId, uiQueryId });
            }

            if (helper.isDeletedUiQueryId(uiQueryId)) {
              return DashboardActions.cancelPollingByQueryId({ uiQueryIds: [uiQueryId] });
            }

            return DashboardActions.noop();
          }),
          finalize(() => DashboardActions.cancelPollingByQueryId({ uiQueryIds: [uiQueryId] })),
        );
      }),
    );
  });

  /**
   * loadWidgetDataByQueryId$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadWidgetDataByQueryId$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.loadWidgetDataByQueryId),
      withLatestFrom(this.store.select(DashboardSelectors.getTrackingIdDetailsMap)),
      mergeMap(
        ([{ uiQueryId, backendQueryId }, trackingIdDetailsMap]: [
          ReturnType<typeof DashboardActions.loadWidgetDataByQueryId>,
          Map<string, IncrementalLoadingWidgetDetails>,
        ]) => {
          return this.dashboardService.getWidgetDataByQueryId(backendQueryId).pipe(
            switchMap((response: IncrementalLoadingTrendPreviewResponse) => {
              if (!helper.isActiveUiQueryId(uiQueryId)) {
                return [DashboardActions.cancelPollingByQueryId({ uiQueryIds: [uiQueryId] })];
              }

              const trackingIdsFromResponse = Object.keys(response.trendById ?? {});
              if (!trackingIdsFromResponse.length) {
                return [DashboardActions.noop()];
              }
              const trackingIdsByUiQueryId = helper.getTrackingIdsByUiQueryId(uiQueryId);
              const actions = [];
              // if every response by tracking id is complete, no need to poll further with the queryId
              if (
                trackingIdsFromResponse.length > 0 &&
                trackingIdsFromResponse.every((trackingId: string) => {
                  return [
                    IncrementalLoadingResponseTrendStatus.COMPLETED_WITH_SUCCESS,
                    IncrementalLoadingResponseTrendStatus.COMPLETED_WITH_FAILURE,
                  ].includes(response.trendById[trackingId].status);
                })
              ) {
                helper.cleanupUiQueryId(uiQueryId);
                actions.push(DashboardActions.deleteQueryId({ uiQueryIds: [uiQueryId] }));
              }
              trackingIdsByUiQueryId?.forEach((trackingId: string) => {
                const trackingDetails = trackingIdDetailsMap.get(trackingId);
                if (!trackingDetails?.onSuccess) {
                  return;
                }
                const trend = response.getTrend(trackingId);
                const status = response?.trendById[trackingId]?.status;

                if (!trend && status === IncrementalLoadingResponseTrendStatus.INPROGRESS) {
                  return;
                }
                actions.push(trackingDetails.onSuccess(response));
              });

              actions.push(DashboardActions.loadWidgetDataByQueryIdSuccess({ trendPreviewResponse: response }));

              return actions;
            }),
          );
        },
      ),
    );
  });

  /**
   * cancelPollingByQueryId$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public cancelPollingByQueryId$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.cancelPollingByQueryId),
      filter(({ uiQueryIds }: ReturnType<typeof DashboardActions.cancelPollingByQueryId>) => uiQueryIds.length > 0),
      mergeMap(({ uiQueryIds }: ReturnType<typeof DashboardActions.cancelPollingByQueryId>) => {
        const serviceRequests = [];
        uiQueryIds.forEach((uiQueryId: string) => {
          const backendQueryId = helper.getBackendQueryId(uiQueryId);
          if (backendQueryId) {
            serviceRequests.push(this.dashboardService.cancelPollingByQueryId(backendQueryId));
          }
        });
        uiQueryIds.forEach(helper.cleanupUiQueryId);
        if (serviceRequests.length > 0) {
          return forkJoin(serviceRequests).pipe(map(() => DashboardActions.noop()));
        }
        return [DashboardActions.noop()];
      }),
    );
  });

  /**
   * Creates an instance of DashboardEffects.
   * @param {Store<CoreAppState>} store
   * @param {Actions} actions$
   * @param {DashboardService} dashboardService
   * @param {ReportMetaService} reportMetaService
   * @param {AccountService} accountService
   * @param {DownloadService} downloadService
   * @param {I18NService} i18nService
   * @param {RouterExtensions} routerExtensions
   * @param {TrendComposerService} tcs
   * @param {WindowService} windowService
   * @param {Router} router
   * @memberof DashboardEffects
   */
  constructor(
    private store: Store<CoreAppState>,
    private actions$: Actions,
    private dashboardService: DashboardService,
    private reportMetaService: ReportMetaService,
    private accountService: AccountService,
    private downloadService: DownloadService,
    private i18nService: I18NService,
    private routerExtensions: RouterExtensions,
    private tcs: TrendComposerService,
    private windowService: WindowService,
    private router: Router,
  ) {}

  /**
   * Calculate new widget position
   *
   * @param {DashboardState} dashboardState
   * @returns {Partial<AggregationWidget>}
   */
  private getNewWidgetPosition = (dashboardState: DashboardState): Partial<AggregationWidget> => {
    let maxRow = 0;
    const maxCol = 6;
    const widgetList: GridsterItem[] = dashboardState.widgetList;
    widgetList.forEach((item: any) => {
      maxRow = maxRow > item.widget.bottomRight.rowNumber ? maxRow : item.widget.bottomRight.rowNumber;
    });
    const topLeft: WidgetPosition = Object.assign(new WidgetPosition(), {
      rowNumber: maxRow,
      columnNumber: 0,
    });
    const bottomRight: WidgetPosition = Object.assign(new WidgetPosition(), {
      rowNumber: maxRow + 2,
      columnNumber: maxCol,
    });
    return {
      topLeft,
      bottomRight,
    } as Partial<AggregationWidget>;
  };

  /**
   * getCopyWidgetDatasets
   * @private
   * @param {AggregationWidget} widget
   * @returns {WidgetDataset[]}
   * @memberof DashboardEffects
   */
  private getCopyWidgetDatasets(widget: AggregationWidget): WidgetDataset[] {
    const widgetDatasets = widget?.widgetDatasets.length
      ? widget.widgetDatasets
      : [new WidgetDataset({ trend: widget.trend, chartType: widget.chartType })];
    return widgetDatasets?.map((widgetDataset: WidgetDataset) => new WidgetDataset({ ...widgetDataset, id: null, widgetId: null }));
  }

  /**
   * first widget position in newly created Dashboard
   *
   * @returns {Partial<AggregationWidget>}
   */
  private getNewDashboardWidgetPosition = (): Partial<AggregationWidget> => {
    const maxRow = 0;
    const maxCol = 6;
    const topLeft: WidgetPosition = Object.assign(new WidgetPosition(), {
      rowNumber: 0,
      columnNumber: 0,
    });
    const bottomRight: WidgetPosition = Object.assign(new WidgetPosition(), {
      rowNumber: maxRow + 2,
      columnNumber: maxCol,
    });
    return {
      topLeft,
      bottomRight,
    } as Partial<AggregationWidget>;
  };
}
