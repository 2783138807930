/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalSize } from '@dpa/ui-common/model';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { AppConfig } from '@ws1c/intelligence-common';
import { ConnectorCommonActions, ConnectorCommonSelectors, UserPreferenceFeatureControlsSelectors } from '@ws1c/intelligence-core/store';
import {
  AlertBannerTarget,
  COLUMN_NAMES,
  ConnectorCreateParams,
  ConnectorSchema,
  GenericSearchRequest,
  ManagedConnector,
  ManagedConnectorsSearchResponse,
} from '@ws1c/intelligence-models';

/**
 * ConnectorAddModalComponent
 *
 * @export
 * @class ConnectorAddModalComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-connector-add-modal',
  templateUrl: 'connector-add-modal.component.html',
  styleUrls: ['connector-add-modal.component.scss'],
})
export class ConnectorAddModalComponent implements OnInit {
  @Input() public isLoading: boolean = false;
  @Input() public connectorConfigSchema: ConnectorSchema;
  @Input() public userGuideUrl: string;
  @Output() public create: EventEmitter<ConnectorCreateParams> = new EventEmitter();
  @Output() public modalClose: EventEmitter<void> = new EventEmitter();

  public isManagedConnectorsLoading$: Observable<boolean>;
  public isCustomConnectorEnabled$: Observable<boolean>;

  public managedConnectorsSearchResponse: ManagedConnectorsSearchResponse;
  public selectedManagedConnector: ManagedConnector;
  public readonly ADD_CONNECTOR_TAB: Record<string, string> = {
    MANAGED_CONNECTORS: 'MANAGED_CONNECTORS',
    CUSTOM_CONNECTORS: 'CUSTOM_CONNECTORS',
  };
  public selectedTab = this.ADD_CONNECTOR_TAB.MANAGED_CONNECTORS;
  public readonly ALERT_BANNER_TARGET = AlertBannerTarget;
  public readonly COLUMN_NAMES = COLUMN_NAMES;
  public readonly ModalSize = ModalSize;
  private sub = new Subscription();

  /**
   * Creates an instance of ConnectorAddModalComponent
   * @param {Store} store
   * @memberof ConnectorAddModalComponent
   */
  constructor(private store: Store) {
    this.isManagedConnectorsLoading$ = this.store.select(ConnectorCommonSelectors.isManagedConnectorsLoading);
    this.isCustomConnectorEnabled$ = this.store.select(UserPreferenceFeatureControlsSelectors.isCustomConnectorEnabled);
  }

  /**
   * ngOnInit
   * @memberof ConnectorAddModalComponent
   */
  public ngOnInit() {
    this.sub.add(
      this.store
        .select(ConnectorCommonSelectors.getManagedConnectorsSearchResponse)
        .subscribe((managedConnectorsSearchResponse: ManagedConnectorsSearchResponse) => {
          this.managedConnectorsSearchResponse = managedConnectorsSearchResponse;
          this.onSelectionChange(this.managedConnectorsSearchResponse?.results[0]);
        }),
    );
    this.store.dispatch(
      ConnectorCommonActions.loadManagedConnectors({
        request: new GenericSearchRequest({
          from: 0,
          size: 10,
        }),
      }),
    );
  }

  /**
   * onSetUp
   * @memberof ConnectorAddModalComponent
   */
  public onSetUp() {
    this.store.dispatch(ConnectorCommonActions.createManagedConnector({ managedConnector: this.selectedManagedConnector }));
  }

  /**
   * onSelectionChange
   * @param {ManagedConnector} selectedConnector
   * @memberof ConnectorAddModalComponent
   */
  public onSelectionChange(selectedConnector: ManagedConnector) {
    this.selectedManagedConnector = selectedConnector;
  }

  /**
   * trackByConnectorId
   * @param {number} index
   * @param {ManagedConnector} managedConnector
   * @returns {string}
   * @memberof AutomationConnectorActionSelectorComponent
   */
  public trackByConnectorId(index: number, managedConnector: ManagedConnector): string {
    return managedConnector?.id;
  }

  /**
   * selectTab
   *
   * @param {string} tab
   * @memberof AutomationConnectorActionSelectorComponent
   */
  public selectTab(tab: string) {
    if (tab === this.ADD_CONNECTOR_TAB.CUSTOM_CONNECTORS) {
      this.store.dispatch(ConnectorCommonActions.getConnectorConfigSchema({ configSchemaId: AppConfig.CONNECTOR_GLOBAL_CONFIG_V1 }));
    }
    this.selectedTab = tab;
  }

  /**
   * onCreate
   * @param {ConnectorCreateParams} params
   * @memberof AutomationConnectorActionSelectorComponent
   */
  public onCreate(params: ConnectorCreateParams) {
    this.create.emit(params);
  }
}
