/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { AfterContentInit, Component, ContentChild, TemplateRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { MarketplaceActions, MarketplaceSelectors } from '@ws1c/intelligence-core/store';
import { MarketplaceResource, MarketplaceResourceType } from '@ws1c/intelligence-models';

/**
 * MarketplaceResourcePreviewComponent
 * @export
 * @class MarketplaceResourcePreviewComponent
 * @implements {AfterContentInit}
 */
@Component({
  selector: 'dpa-marketplace-resource-preview',
  templateUrl: 'marketplace-resource-preview.component.html',
  styleUrls: ['marketplace-resource-preview.component.scss'],
})
export class MarketplaceResourcePreviewComponent implements AfterContentInit {
  @ContentChild('widget', { read: TemplateRef }) public widgetPreview: TemplateRef<any>;
  @ContentChild('report', { read: TemplateRef }) public reportPreview: TemplateRef<any>;
  @ContentChild('dashboard', { read: TemplateRef }) public dashboardPreview: TemplateRef<any>;
  @ContentChild('uemFreestyleWorkflow', { read: TemplateRef }) public uemFreestyleWorkflowPreview: TemplateRef<any>;
  @ContentChild('automation', { read: TemplateRef }) public automationPreview: TemplateRef<any>;
  @ContentChild('script', { read: TemplateRef }) public scriptPreview: TemplateRef<any>;
  @ContentChild('sensor', { read: TemplateRef }) public sensorPreview: TemplateRef<any>;

  public previewTemplateByResourceType: Record<string, TemplateRef<any>>;
  public isVisible$: Observable<boolean>;
  public previousResource$: Observable<MarketplaceResource>;
  public nextResource$: Observable<MarketplaceResource>;
  public resource$: Observable<MarketplaceResource>;

  public readonly MarketplaceResourceType = MarketplaceResourceType;

  /**
   * Creates an instance of MarketplaceResourcePreviewComponent.
   * @param {Store} store
   * @memberof MarketplaceResourcePreviewComponent
   */
  constructor(private store: Store) {
    this.isVisible$ = this.store.select(MarketplaceSelectors.isResourcePreviewVisible);
    this.previousResource$ = this.store.select(MarketplaceSelectors.getPreviousPreviewResource);
    this.nextResource$ = this.store.select(MarketplaceSelectors.getNextPreviewResource);
    this.resource$ = this.store.select(MarketplaceSelectors.getPreviewResource);
  }

  /**
   * ngAfterContentInit
   * @memberof MarketplaceResourcePreviewComponent
   */
  public ngAfterContentInit() {
    this.previewTemplateByResourceType = {
      [MarketplaceResourceType.WIDGET_TEMPLATE]: this.widgetPreview,
      [MarketplaceResourceType.REPORT_TEMPLATE]: this.reportPreview,
      [MarketplaceResourceType.DASHBOARD_TEMPLATE]: this.dashboardPreview,
      [MarketplaceResourceType.UEM_FREESTYLE_WORKFLOW_TEMPLATE]: this.uemFreestyleWorkflowPreview,
      [MarketplaceResourceType.AUTOMATION_TEMPLATE]: this.automationPreview,
      [MarketplaceResourceType.SCRIPT_TEMPLATE]: this.scriptPreview,
      [MarketplaceResourceType.SENSOR_TEMPLATE]: this.sensorPreview,
    };
  }

  /**
   * onHidePreview
   * @memberof MarketplaceResourcePreviewComponent
   */
  public onHidePreview() {
    this.store.dispatch(MarketplaceActions.setResourcePreviewVisibility({ isVisible: false }));
  }

  /**
   * previewPrevResource
   * @param {MarketplaceResource} resource
   * @memberof MarketplaceResourcePreviewComponent
   */
  public previewPrevResource(resource: MarketplaceResource) {
    this.store.dispatch(MarketplaceActions.selectPrevPreviewResource({ resource }));
  }

  /**
   * previewNextResource
   * @param {MarketplaceResource} resource
   * @memberof MarketplaceResourcePreviewComponent
   */
  public previewNextResource(resource: MarketplaceResource) {
    this.store.dispatch(MarketplaceActions.selectNextPreviewResource({ resource }));
  }
}
