/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Params } from '@angular/router';
import { Tab } from '@dpa/ui-common';
import { createSelector, MemoizedSelector, Selector } from '@ngrx/store';

import { HorizonDashboardConfig } from '@dpa-shared-merlot/model/dashboard/horizon-dashboard.config';
import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { getDefaultedTrendDate } from '@ws1c/dashboard-common/utils';
import { getOffsetCounterData } from '@ws1c/intelligence-core/store/dashboard/dashboard-selector-helpers';
import { DashboardSelectors } from '@ws1c/intelligence-core/store/dashboard/dashboard.selectors';
import { RouterSelectors } from '@ws1c/intelligence-core/store/router';
import { UserPreferenceSelectors } from '@ws1c/intelligence-core/store/user-preference';
import {
  ComparisonQueryResponse,
  FilterRule,
  HorizonPodType,
  Integration,
  ROUTE_NAMES,
  RuleGroup,
  StandardDashboardRequest,
  StandardDashboardType,
  StandardWidgetSubtype,
  Trend,
  TrendDateRange,
} from '@ws1c/intelligence-models';
import * as horizonSelectorHelpers from './horizon-selector-helpers';
import { HorizonState } from './horizon.state';

/**
 * HorizonSelectors
 *
 * @export
 * @class HorizonSelectors
 */
export class HorizonSelectors {
  /**
   * selectHorizonState
   * @static
   * @param {MerlotState} state
   * @type {Selector<MerlotState, HorizonState>}
   * @memberof HorizonSelectors
   */
  public static selectHorizonState: Selector<MerlotState, HorizonState> = (state: MerlotState) =>
    state.standardDashboardState?.horizonState;

  /**
   * getHorizonFilterRuleGroup
   * @static
   * @type {MemoizedSelector<MerlotState, RuleGroup>}
   * @memberof HorizonSelectors
   */
  public static getHorizonFilterRuleGroup: MemoizedSelector<MerlotState, RuleGroup> = createSelector(
    HorizonSelectors.selectHorizonState,
    (state: HorizonState) => state.horizonFilterRuleGroup,
  );

  /**
   * getHorizonFilterRuleParams
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof HorizonSelectors
   */
  public static getHorizonFilterRuleParams: MemoizedSelector<MerlotState, string> = createSelector(
    HorizonSelectors.selectHorizonState,
    (state: HorizonState) => JSON.stringify(state.horizonFilterRuleGroup.rules),
  );

  /**
   * getHorizonDashboardTrendDateRange
   * @static
   * @type {MemoizedSelector<MerlotState, TrendDateRange>}
   * @memberof HorizonSelectors
   */
  public static getHorizonDashboardTrendDateRange: MemoizedSelector<MerlotState, TrendDateRange> = createSelector(
    HorizonSelectors.selectHorizonState,
    (state: HorizonState) => {
      return getDefaultedTrendDate(state.horizonDashboardTrendDateRange, StandardDashboardType.HORIZON_UTILIZATION_VMWARE_SDDC);
    },
  );

  /**
   * getHorizonTitlePrefix
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof HorizonSelectors
   */
  public static getHorizonTitlePrefix: MemoizedSelector<MerlotState, string> = createSelector(
    HorizonSelectors.selectHorizonState,
    (state: HorizonState) => state.titlePrefix,
  );

  /**
   * getHorizonSelectedPoolName
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof HorizonSelectors
   */
  public static getHorizonSelectedPoolName: MemoizedSelector<MerlotState, string> = createSelector(
    HorizonSelectors.selectHorizonState,
    (state: HorizonState) => state.selectedPoolName,
  );

  /**
   * getHorizonTitanSelectedPoolTemplateName
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof HorizonSelectors
   */
  public static getHorizonTitanSelectedPoolTemplateName: MemoizedSelector<MerlotState, string> = createSelector(
    HorizonSelectors.selectHorizonState,
    (state: HorizonState) => state.selectedPoolTemplateName,
  );

  /**
   * selectHorizonDashboardType$
   * @static
   * @type MemoizedSelector<MerlotState, StandardDashboardType>
   * @returns {StandardDashboardType}
   * @memberof HorizonSelectors
   */
  public static selectHorizonDashboardType: MemoizedSelector<MerlotState, StandardDashboardType> = createSelector(
    HorizonSelectors.selectHorizonState,
    (state: HorizonState) => state.horizonDashboardType,
  );

  /**
   * getHorizonSelectedDeploymentTypes
   * @static
   * @type {MemoizedSelector<MerlotState, string[]>}
   * @memberof HorizonSelectors
   */
  public static getHorizonSelectedDeploymentTypes: MemoizedSelector<MerlotState, string[]> = createSelector(
    HorizonSelectors.selectHorizonState,
    (state: HorizonState) => state.selectedDeploymentTypes,
  );

  /**
   * getHorizonSelectedDeploymentTypesParams
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof HorizonSelectors
   */
  public static getHorizonSelectedDeploymentTypesParams: MemoizedSelector<MerlotState, string> = createSelector(
    HorizonSelectors.selectHorizonState,
    (state: HorizonState) => state.selectedDeploymentTypes?.join(','),
  );

  /**
   * getHorizonUtilizationVMwareSDDCLink
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof HorizonSelectors
   */
  public static getHorizonUtilizationVMwareSDDCLink: MemoizedSelector<MerlotState, string> = createSelector(
    HorizonSelectors.getHorizonSelectedDeploymentTypes,
    (selectedDeploymentTypes: string[]) => {
      const vmwareSDDCFirstDeploymentType = selectedDeploymentTypes?.find((type: string) => {
        return HorizonDashboardConfig.horizonDeploymentTypeToPodType[type] === HorizonPodType.VMWARE_SDDC;
      });
      return vmwareSDDCFirstDeploymentType
        ? `/${ROUTE_NAMES.DASHBOARD.HORIZON_MONITORING_UTILIZATION}/${vmwareSDDCFirstDeploymentType}`
        : undefined;
    },
  );

  /**
   * isAzureDeploymentSelected
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof HorizonSelectors
   */
  public static isAzureDeploymentSelected: MemoizedSelector<MerlotState, boolean> = createSelector(
    HorizonSelectors.getHorizonSelectedDeploymentTypes,
    (selectedDeploymentTypes: string[]) => {
      return selectedDeploymentTypes?.some((type: string) => {
        return HorizonDashboardConfig.horizonDeploymentTypeToPodType[type] === HorizonPodType.AZURE;
      });
    },
  );

  /**
   * getHorizonDeploymentTabs
   * @static
   * @type {(isTitan: boolean) => MemoizedSelector<MerlotState, Tab[]>}
   * @memberof HorizonSelectors
   */
  public static getHorizonDeploymentTabs = (isTitan: boolean): MemoizedSelector<MerlotState, Tab[]> =>
    createSelector(HorizonSelectors.getHorizonSelectedDeploymentTypes, (selectedDeploymentTypes: string[]) => {
      return selectedDeploymentTypes?.map((name: string) => ({
        name,
        link: isTitan
          ? `/${ROUTE_NAMES.DASHBOARD.HORIZON_MONITORING_TITAN_UTILIZATION}/${name}`
          : `/${ROUTE_NAMES.DASHBOARD.HORIZON_MONITORING_UTILIZATION}/${name}`,
      }));
    });

  /**
   * getHorizonUtilizationActivePodIds
   * @static
   * @type {MemoizedSelector<MerlotState, string[]>}
   * @memberof HorizonSelectors
   */
  public static getHorizonUtilizationActivePodIds: MemoizedSelector<MerlotState, string[]> = createSelector(
    HorizonSelectors.selectHorizonState,
    (state: HorizonState) => state.podIdsForActiveDeploymentTab,
  );

  /**
   * getStandardHorizonSummaryDashboardRequest
   *
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardRequest[]>}
   * @memberof HorizonSelectors
   */
  public static getStandardHorizonSummaryDashboardRequest: MemoizedSelector<MerlotState, StandardDashboardRequest[]> = createSelector(
    HorizonSelectors.selectHorizonDashboardType,
    HorizonSelectors.getHorizonFilterRuleGroup,
    HorizonSelectors.getHorizonDashboardTrendDateRange,
    HorizonSelectors.getHorizonUtilizationActivePodIds,
    (dashboardType: StandardDashboardType, filterRuleGroup: RuleGroup, trendDateRange: TrendDateRange, podIds: string[]) => {
      return [horizonSelectorHelpers.buildHorizonDashboardRequest(dashboardType, filterRuleGroup, trendDateRange, podIds)];
    },
  );

  /**
   * getHorizonDashboardRequestType
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof HorizonSelectors
   */
  public static getHorizonDashboardTypeList: MemoizedSelector<MerlotState, StandardDashboardType[]> = createSelector(
    HorizonSelectors.selectHorizonState,
    (state: HorizonState) => state.dashboardTypeList,
  );

  /**
   * getHorizonDashboardWithMergedRequests
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardRequest[]>}
   * @memberof HorizonSelectors
   */
  public static getHorizonDashboardWithMergedRequests: MemoizedSelector<MerlotState, StandardDashboardRequest[]> = createSelector(
    HorizonSelectors.getHorizonDashboardTypeList,
    HorizonSelectors.getHorizonFilterRuleGroup,
    HorizonSelectors.getHorizonDashboardTrendDateRange,
    HorizonSelectors.getHorizonUtilizationActivePodIds,
    RouterSelectors.currentQueryParam$,
    (
      dashboardTypeList: StandardDashboardType[],
      filterRuleGroup: RuleGroup,
      trendDateRange: TrendDateRange,
      podIds: string[],
      currentURLParams: Params,
    ) => {
      return dashboardTypeList.map((dashboardType: StandardDashboardType) => {
        if (filterRuleGroup.rules.length === 0 && currentURLParams.filterRule) {
          filterRuleGroup.rules = JSON.parse(currentURLParams.filterRule) as FilterRule[];
        }
        return horizonSelectorHelpers.buildHorizonDashboardRequest(dashboardType, filterRuleGroup, trendDateRange, podIds);
      });
    },
  );

  /**
   * getTotalSessionCounterData
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof HorizonSelectors
   */
  public static getTotalSessionCounterData: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return getOffsetCounterData(trendsByWidgetSubtype, undefined, StandardWidgetSubtype.HORIZON_SESSION_TOTAL_COUNT);
    },
  );

  /**
   * getTotalActiveSessionCounterData
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof HorizonSelectors
   */
  public static getTotalActiveSessionCounterData: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return getOffsetCounterData(trendsByWidgetSubtype, undefined, StandardWidgetSubtype.HORIZON_SESSION_TOTAL_ACTIVE_COUNT);
    },
  );

  /**
   * getUniqueUserCounterData
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof HorizonSelectors
   */
  public static getUniqueUserCounterData: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return horizonSelectorHelpers.getCounterDataFromSessions(
        trendsByWidgetSubtype,
        StandardWidgetSubtype.HORIZON_SESSION_UNIQUE_USER_COUNT,
        StandardWidgetSubtype.HORIZON_SESSION_UNIQUE_USER_COUNT_BY_SESSIONS,
      );
    },
  );

  /**
   * getImpactedUserCounterData
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof HorizonSelectors
   */
  public static getImpactedUserCounterData: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return horizonSelectorHelpers.getCounterDataFromSessions(
        trendsByWidgetSubtype,
        StandardWidgetSubtype.HORIZON_SESSION_IMPACTED_USERS,
        StandardWidgetSubtype.HORIZON_SESSION_IMPACTED_USERS_BY_SESSIONS,
      );
    },
  );

  /**
   * getTotalIdleSessionCounterData
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof HorizonSelectors
   */
  public static getTotalIdleSessionCounterData: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return getOffsetCounterData(trendsByWidgetSubtype, undefined, StandardWidgetSubtype.HORIZON_SESSION_TOTAL_IDLE_COUNT);
    },
  );

  /**
   * getTotalDisconnectedSessionCounterData
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof HorizonSelectors
   */
  public static getTotalDisconnectedSessionCounterData: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return getOffsetCounterData(trendsByWidgetSubtype, undefined, StandardWidgetSubtype.HORIZON_SESSION_TOTAL_DISCONNECTED_COUNT);
    },
  );

  /**
   * isHorizonIntegrationReady
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof HorizonSelectors
   */
  public static isHorizonIntegrationReady: MemoizedSelector<MerlotState, boolean> = createSelector(
    UserPreferenceSelectors.isIntegrationAuthorized(Integration.HORIZON),
    (isIntegrationAuthorized: boolean) => isIntegrationAuthorized,
  );
}
