/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * loadBundle
 * @export
 * @function
 * @param {string} locale
 * @returns {Promise<unknown>}
 */
export function loadBundle(locale: string): Promise<unknown> {
  return import(`./${locale}.json`).then((m) => m.default);
}
