/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { getFailureReason, WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import { ConnectorCommonService } from '@ws1c/intelligence-core/services/connector-common.service';
import { AutomationCommonActions } from '@ws1c/intelligence-core/store';
import { AlertBannerActions } from '@ws1c/intelligence-core/store/alert-banner';
import { ConnectorCommonActions, ConnectorCommonSelectors } from '@ws1c/intelligence-core/store/connector-common';
import {
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  AllManagedConnectorsResponse,
  AutomationRunHistoryResponse,
  Connector,
  ConnectorConfig,
  ConnectorModalType,
  ConnectorsAllActionsResponse,
  ConnectorSchema,
  ConnectorsSearchResponse,
  ConnectorsWithActionsResponse,
  GenericSearchRequest,
  ManagedConnectorsSearchResponse,
  ROUTE_NAMES,
} from '@ws1c/intelligence-models';

/**
 * ConnectorCommonEffects
 *
 * @export
 * @class ConnectorCommonEffects
 */
@Injectable()
export class ConnectorCommonEffects {
  /**
   * loadConnectors$
   * @type {Observable<Action>}
   * @memberof ConnectorCommonEffects
   */
  public loadConnectors$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ConnectorCommonActions.changePagination,
        ConnectorCommonActions.loadConnectors,
        ConnectorCommonActions.loadConnectorsWithSort,
        ConnectorCommonActions.loadConnectorsWithSearch,
        ConnectorCommonActions.loadConnectorsWithQuickFilters,
      ),
      withLatestFrom(this.store.select(ConnectorCommonSelectors.getConnectorsSearchRequest)),
      switchMap(([_action, request]: [Action, GenericSearchRequest]) => {
        return this.connectorCommonService.searchConnectors(request).pipe(
          map((response: ConnectorsSearchResponse) => ConnectorCommonActions.loadConnectorsSuccess({ response })),
          catchError(() => [
            ConnectorCommonActions.loadConnectorsFailure(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate('CONNECTIONS.FAILED_LOADING_CONNECTORS'),
            }),
          ]),
        );
      }),
    );
  });

  /**
   * loadAllActions$
   * @type {Observable<Action>}
   * @memberof ConnectorCommonEffects
   */
  public loadAllActions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.loadAllActions),
      debounceTime(300),
      switchMap(({ request, sourceObjectType }: ReturnType<typeof AutomationCommonActions.loadAllActions>) => {
        return this.connectorCommonService.searchActions(request, sourceObjectType).pipe(
          map((connectorsActionsResponse: ConnectorsAllActionsResponse) =>
            AutomationCommonActions.loadAllActionsSuccess({ connectorsActionsResponse }),
          ),
          catchError(() => [
            AutomationCommonActions.loadAllActionsFailure(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate('CONNECTIONS.FAILED_LOADING_ACTIONS'),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * listConnectorsWithActions$
   * @type {Observable<Action>}
   * @memberof ConnectorCommonEffects
   */
  public listConnectorsWithActions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectorCommonActions.listConnectorsWithActions),
      debounceTime(300),
      switchMap(({ request, sourceObjectType }: ReturnType<typeof ConnectorCommonActions.listConnectorsWithActions>) => {
        return this.connectorCommonService.listConnectorsWithActions(request, sourceObjectType).pipe(
          map((connectorsWithActionsResponse: ConnectorsWithActionsResponse) =>
            ConnectorCommonActions.listConnectorsWithActionsSuccess({ connectorsWithActionsResponse }),
          ),
          catchError(() => [
            ConnectorCommonActions.listConnectorsWithActionsFailure(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate('CONNECTORS.FAILED_LOADING_CONNECTORS'),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * updateConnector$
   * @type {Observable<Action>}
   * @memberof ConnectorCommonEffects
   */
  public updateConnector$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectorCommonActions.updateConnector),
      switchMap(({ id, params }: ReturnType<typeof ConnectorCommonActions.updateConnector>) => {
        return this.connectorCommonService.updateConnector(id, params).pipe(
          switchMap(() => [
            ConnectorCommonActions.updateConnectorSuccess(),
            ConnectorCommonActions.loadConnectors({}),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate('CONNECTIONS.CONNECTOR_UPDATED_SUCCESSFULLY'),
            }),
          ]),
          catchError((error: WebError) => {
            const reason = getFailureReason(error, this.i18nService.translate('CONNECTIONS.SAVE_UPDATE_CONNECTOR_KEY_DUPLICATE_ERROR'));
            return [
              ConnectorCommonActions.updateConnectorFailure(),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('CONNECTORS.FAILED_UPDATING_CONNECTOR_REASON', { reason }),
              }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * createCustomConnector$
   * @type {Observable<Action>}
   * @memberof ConnectorCommonEffects
   */
  public createCustomConnector$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectorCommonActions.createCustomConnector),
      switchMap(({ params }: ReturnType<typeof ConnectorCommonActions.createCustomConnector>) => {
        return this.connectorCommonService.createCustomConnector(params).pipe(
          switchMap((connector: Connector) => [
            ConnectorCommonActions.createCustomConnectorSuccess(),
            ConnectorCommonActions.navigateToConnectorDetails({ id: connector?.id }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate('CONNECTIONS.CONNECTOR_CREATED_SUCCESSFULLY'),
            }),
          ]),
          catchError((error: WebError) => {
            const reason = getFailureReason(error, this.i18nService.translate('CONNECTIONS.SAVE_UPDATE_CONNECTOR_KEY_DUPLICATE_ERROR'));
            return [
              ConnectorCommonActions.createCustomConnectorFailure(),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('CONNECTIONS.FAILED_CREATING_CONNECTOR_REASON', { reason }),
              }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * deleteConnectorConfig$
   * @type {Observable<Action>}
   * @memberof ConnectorCommonEffects
   */
  public deleteConnectorConfig$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectorCommonActions.deleteConnectorConfig),
      switchMap(({ connector }: ReturnType<typeof ConnectorCommonActions.deleteConnectorConfig>) => {
        return this.connectorCommonService.deleteConnectorConfig(connector.configId).pipe(
          switchMap(() => {
            if (connector.managed) {
              return [
                ConnectorCommonActions.deleteConnectorConfigSuccess(),
                ConnectorCommonActions.loadConnectors({}),
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.SUCCESS,
                  target: AlertBannerTarget.PAGE,
                  message: this.i18nService.translate('CONNECTIONS.CONNECTOR_DELETED_SUCCESSFULLY'),
                }),
              ];
            } else {
              return [ConnectorCommonActions.deleteConnector({ id: connector.id })];
            }
          }),
          catchError(() => [
            ConnectorCommonActions.deleteConnectorConfigFailure(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.MODAL,
              message: this.i18nService.translate('CONNECTIONS.FAILED_DELETING_CONNECTOR'),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * loadConnectorConfig$
   * @type {Observable<Action>}
   * @memberof ConnectorCommonEffects
   */
  public loadConnectorConfig$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectorCommonActions.loadConnectorConfig),
      switchMap(({ id }: ReturnType<typeof ConnectorCommonActions.loadConnectorConfig>) => {
        return this.connectorCommonService.getConnectorConfig(id).pipe(
          map((config: ConnectorConfig) => ConnectorCommonActions.loadConnectorConfigSuccess({ config })),
          catchError(() => [
            ConnectorCommonActions.loadConnectorConfigFailure(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate('CONNECTIONS.FAILED_LOADING_CONNECTOR_DATA'),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * createConnectorConfig$
   * @type {Observable<Action>}
   * @memberof ConnectorCommonEffects
   */
  public createConnectorConfig$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectorCommonActions.createConnectorConfig),
      switchMap(({ id, configData }: ReturnType<typeof ConnectorCommonActions.createConnectorConfig>) => {
        return this.connectorCommonService.createConnectorConfig(id, configData).pipe(
          switchMap((config: ConnectorConfig) => [
            ConnectorCommonActions.createConnectorConfigSuccess({ config }),
            ConnectorCommonActions.navigateToConnectorDetails({ id }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate('CONNECTIONS.CONNECTOR_UPDATED_SUCCESSFULLY'),
            }),
          ]),
          catchError(() => [
            ConnectorCommonActions.createConnectorConfigFailure(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.MODAL,
              message: this.i18nService.translate('CONNECTORS.FAILED_UPDATING_CONNECTOR'),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * updateConnectorConfig$
   * @type {Observable<Action>}
   * @memberof ConnectorCommonEffects
   */
  public updateConnectorConfig$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectorCommonActions.updateConnectorConfig),
      switchMap(({ id, params }: ReturnType<typeof ConnectorCommonActions.updateConnectorConfig>) => {
        return this.connectorCommonService.updateConnectorConfig(id, params).pipe(
          switchMap(() => [
            ConnectorCommonActions.updateConnectorConfigSuccess(),
            ConnectorCommonActions.loadConnectors({}),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate('CONNECTIONS.CONNECTOR_UPDATED_SUCCESSFULLY'),
            }),
          ]),
          catchError((error: WebError) => {
            const reason = getFailureReason(error, this.i18nService.translate('CONNECTIONS.SAVE_UPDATE_CONNECTOR_KEY_DUPLICATE_ERROR'));
            return [
              ConnectorCommonActions.updateConnectorConfigFailure(),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('CONNECTORS.FAILED_UPDATING_CONNECTOR_REASON', { reason }),
              }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * deleteConnector$
   * @type {Observable<Action>}
   * @memberof ConnectorCommonEffects
   */
  public deleteConnector$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectorCommonActions.deleteConnector),
      switchMap(({ id }: ReturnType<typeof ConnectorCommonActions.deleteConnector>) => {
        return this.connectorCommonService.deleteConnector(id).pipe(
          switchMap(() => [
            ConnectorCommonActions.deleteConnectorSuccess(),
            ConnectorCommonActions.loadConnectors({}),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate('CONNECTIONS.CONNECTOR_DELETED_SUCCESSFULLY'),
            }),
          ]),
          catchError(() => [
            ConnectorCommonActions.deleteConnectorFailure(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.MODAL,
              message: this.i18nService.translate('CONNECTIONS.FAILED_DELETING_CONNECTOR'),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * getConnectorConfigSchema$
   *
   * @type {Observable<Action>}
   * @memberof ConnectorCommonEffects
   */
  public getConnectorConfigSchema$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectorCommonActions.getConnectorConfigSchema),
      switchMap(({ configSchemaId }: ReturnType<typeof ConnectorCommonActions.getConnectorConfigSchema>) => {
        return this.connectorCommonService.getConnectorConfigSchema(configSchemaId).pipe(
          map((connectorConfigSchema: ConnectorSchema) => {
            return ConnectorCommonActions.getConnectorConfigSchemaSuccess({ connectorConfigSchema });
          }),
          catchError(() => [
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate('CONNECTIONS.FAILED_LOADING_CONNECTOR_CONFIG_SCHEMA'),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * loadConnectorsUsage$
   * @type {Observable<Action>}
   * @memberof ConnectorCommonEffects
   */
  public loadConnectorsUsage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectorCommonActions.loadConnectorsUsage, ConnectorCommonActions.loadConnectorsUsageWithSort),
      switchMap(({ request, connectorId }: ReturnType<typeof ConnectorCommonActions.loadConnectorsUsage>) => {
        return this.connectorCommonService.getConnectorUsage(connectorId, request).pipe(
          map((response: AutomationRunHistoryResponse) => {
            return ConnectorCommonActions.loadConnectorsUsageSuccess({ response });
          }),
          catchError(() => [
            ConnectorCommonActions.loadConnectorsUsageFailure(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate('CONNECTORS.FAILED_LOADING_CONNECTOR_USAGE'),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * loadManagedConnectors$
   * @type {Observable<Action>}
   * @memberof ConnectorEffects
   */
  public loadManagedConnectors$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectorCommonActions.loadManagedConnectors),
      switchMap(({ request }: ReturnType<typeof ConnectorCommonActions.loadManagedConnectors>) => {
        return this.connectorCommonService.loadManagedConnectors(request).pipe(
          map((response: ManagedConnectorsSearchResponse) => ConnectorCommonActions.loadManagedConnectorsSuccess({ response })),
          catchError(() => [
            ConnectorCommonActions.loadManagedConnectorsFailure(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate('CONNECTIONS.FAILED_LOADING_CONNECTORS'),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * loadAllManagedConnectors$
   * @type {Observable<Action>}
   * @memberof ConnectorEffects
   */
  public loadAllManagedConnectors$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectorCommonActions.loadAllManagedConnectors),
      switchMap(() => {
        return this.connectorCommonService.loadAllManagedConnectors().pipe(
          map((response: AllManagedConnectorsResponse) => {
            return ConnectorCommonActions.loadAllManagedConnectorsSuccess({ response });
          }),
          catchError(() => [
            ConnectorCommonActions.loadAllManagedConnectorsFailure(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate('CONNECTIONS.FAILED_LOADING_CONNECTORS'),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * createManagedConnector$
   * @type {Observable<Action>}
   * @memberof ConnectorEffects
   */
  public createManagedConnector$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectorCommonActions.createManagedConnector),
      switchMap(({ managedConnector }: ReturnType<typeof ConnectorCommonActions.createManagedConnector>) => {
        if (managedConnector.configRequired) {
          return [
            ConnectorCommonActions.showModal({
              modalType: ConnectorModalType.AUTHORIZE,
              connector: new Connector(managedConnector),
            }),
          ];
        } else {
          return [
            ConnectorCommonActions.closeModal(),
            ConnectorCommonActions.createManagedConnectorSuccess(),
            ConnectorCommonActions.loadConnectors({}),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate('CONNECTIONS.CONNECTOR_CREATED_SUCCESSFULLY'),
            }),
          ];
        }
      }),
    ),
  );

  /**
   * navigateToConnectorDetails$
   * @memberof ConnectorEffects
   */
  public navigateToConnectorDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConnectorCommonActions.navigateToConnectorDetails),
        tap(({ id, tab }: ReturnType<typeof ConnectorCommonActions.navigateToConnectorDetails>) =>
          this.routerExt.navigate([`/${ROUTE_NAMES.INTEGRATIONS.WORKFLOW_CONNECTORS_DETAILS(id)}/${tab ?? ''}`]),
        ),
      ),
    { dispatch: false },
  );

  /**
   * Creates an instance of ConnectorCommonEffects.
   * @param {Store} store
   * @param {Actions} actions$
   * @param {I18NService} i18nService
   * @param {RouterExtensions} routerExt
   * @param {ConnectorCommonService} connectorCommonService
   * @memberof ConnectorCommonEffects
   */
  constructor(
    private store: Store,
    private actions$: Actions,
    private i18nService: I18NService,
    private routerExt: RouterExtensions,
    private connectorCommonService: ConnectorCommonService,
  ) {}
}
