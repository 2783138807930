/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';
import { createSelector, MemoizedSelector, Selector } from '@ngrx/store';

import { appDashboardHelpers } from '@dpa-shared-merlot/store/dashboard/apps/app-deployment-dashboard.selectors';
import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { getDefaultedTrendDate } from '@ws1c/dashboard-common/utils';
import { DashboardSelectors } from '@ws1c/intelligence-core/store/dashboard/dashboard.selectors';
import { FeatureSelectors } from '@ws1c/intelligence-core/store/rbac';
import { UserPreferenceFeatureControlsSelectors, UserPreferenceSelectors } from '@ws1c/intelligence-core/store/user-preference';
import {
  ComparisonQueryResponse,
  RuleGroup,
  StandardDashboardRequest,
  StandardDashboardType,
  StandardWidgetSubtype,
  Trend,
  TrendDateRange,
} from '@ws1c/intelligence-models';
import { UserRiskDashboardState } from './user-risk-dashboard.state';

/**
 * UserRiskDashboardSelectors
 * @export
 * @class UserRiskDashboardSelectors
 */
export class UserRiskDashboardSelectors {
  /**
   * selectUserRiskDashboardState
   * @static
   * @param {MerlotState} state
   * @type {Selector<MerlotState, UserRiskDashboardState>}
   * @memberof UserRiskDashboardSelectors
   */
  public static selectUserRiskDashboardState: Selector<MerlotState, UserRiskDashboardState> = (state: MerlotState) =>
    state.standardDashboardState.userRiskDashboardState;

  /**
   * getRuleGroup
   * @static
   * @type {MemoizedSelector<MerlotState, RuleGroup>}
   * @memberof UserRiskDashboardSelectors
   */
  public static getRuleGroup: MemoizedSelector<MerlotState, RuleGroup> = createSelector(
    UserRiskDashboardSelectors.selectUserRiskDashboardState,
    (state: UserRiskDashboardState) => state.ruleGroup ?? new RuleGroup(),
  );

  /**
   * getRiskyUserByTimestampByEmail
   * @static
   * @type {MemoizedSelector<MerlotState, GenericObject>}
   * @memberof UserRiskDashboardSelectors
   */
  public static getRiskyUserByTimestampByEmail: MemoizedSelector<MerlotState, GenericObject> = createSelector(
    UserRiskDashboardSelectors.selectUserRiskDashboardState,
    (state: UserRiskDashboardState) => state.riskyUserByTimestampByEmail,
  );

  /**
   * isUserRiskBannerHidden
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof UserRiskDashboardSelectors
   */
  public static isUserRiskBannerHidden: MemoizedSelector<MerlotState, boolean> = createSelector(
    UserRiskDashboardSelectors.selectUserRiskDashboardState,
    (state: UserRiskDashboardState) => state.hideBanner,
  );

  /**
   * isIDMReadyToBeSetup
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof UserRiskDashboardSelectors
   */
  public static isIDMReadyToBeSetup: MemoizedSelector<MerlotState, boolean> = createSelector(
    FeatureSelectors.hasIntegrationReadOnlyPerm,
    UserPreferenceFeatureControlsSelectors.isIDMEnabled,
    UserPreferenceSelectors.isIDMAuthorized,
    UserRiskDashboardSelectors.isUserRiskBannerHidden,
    (isIntegrationAllowed: boolean, isIDMEnabled: boolean, isIDMAuthorized: boolean, isBannerHidden: boolean) =>
      isIntegrationAllowed && isIDMEnabled && !isIDMAuthorized && !isBannerHidden,
  );

  /**
   * getDashboardTrendDateRange
   * @static
   * @type {MemoizedSelector<MerlotState, TrendDateRange>}
   * @memberof UserRiskDashboardSelectors
   */
  public static getDashboardTrendDateRange: MemoizedSelector<MerlotState, TrendDateRange> = createSelector(
    UserRiskDashboardSelectors.selectUserRiskDashboardState,
    (state: UserRiskDashboardState) => getDefaultedTrendDate(state.trendDateRange),
  );

  /**
   * getStandardDashboardRequest
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardRequest>}
   * @memberof UserRiskDashboardSelectors
   */
  public static getStandardDashboardRequest: MemoizedSelector<MerlotState, StandardDashboardRequest> = createSelector(
    UserRiskDashboardSelectors.getRuleGroup,
    UserRiskDashboardSelectors.getDashboardTrendDateRange,
    (ruleGroup: RuleGroup, trendDateRange: TrendDateRange) => {
      return new StandardDashboardRequest(StandardDashboardType.USER_RISK, ruleGroup, trendDateRange);
    },
  );

  /**
   * getUsersAtHighRiskCounterData
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof UserRiskDashboardSelectors
   */
  public static getUsersAtHighRiskCounterData: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return appDashboardHelpers.getOffsetCounterData(
        trendsByWidgetSubtype,
        StandardWidgetSubtype.USERS_AT_HIGH_RISK_OFFSET_1,
        StandardWidgetSubtype.USERS_AT_HIGH_RISK,
      );
    },
  );

  /**
   * getUsersAtMediumRiskCounterData
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof UserRiskDashboardSelectors
   */
  public static getUsersAtMediumRiskCounterData: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return appDashboardHelpers.getOffsetCounterData(
        trendsByWidgetSubtype,
        StandardWidgetSubtype.USERS_AT_MEDIUM_RISK_OFFSET_1,
        StandardWidgetSubtype.USERS_AT_MEDIUM_RISK,
      );
    },
  );

  /**
   * getUsersAtLowRiskCounterData
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof UserRiskDashboardSelectors
   */
  public static getUsersAtLowRiskCounterData: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return appDashboardHelpers.getOffsetCounterData(
        trendsByWidgetSubtype,
        StandardWidgetSubtype.USERS_AT_LOW_RISK_OFFSET_1,
        StandardWidgetSubtype.USERS_AT_LOW_RISK,
      );
    },
  );

  /**
   * getRiskyUserDeviceSummaryByTimestampByEmail
   * @static
   * @param {string} userEmail
   * @param {number} timestamp
   * @type {(userEmail: string, timestamp: number) => MemoizedSelector<MerlotState, GenericObject>}
   * @memberof UserRiskDashboardSelectors
   */
  public static getRiskyUserDeviceSummaryByTimestampByEmail = (
    userEmail: string,
    timestamp: number,
  ): MemoizedSelector<MerlotState, GenericObject[]> =>
    createSelector(
      UserRiskDashboardSelectors.getRiskyUserByTimestampByEmail,
      (userSummary: GenericObject) => userSummary?.[userEmail]?.[timestamp],
    );
}
