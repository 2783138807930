/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Params } from '@angular/router';
import { BreadCrumb } from '@dpa/ui-common';
import { uniqueId } from 'lodash-es';

import { DeemDashboardConfig, SubDashboardId, SubDashboardPlatform } from '@ws1c/deem-solution/const';
import { AppConfig, RouterExtensions } from '@ws1c/intelligence-common';
import {
  Device,
  DevicesProfileTabType,
  IncrementalLoadingWidgetDetails,
  ROUTE_NAMES,
  WidgetDetailPage,
  WidgetDetailPageSkinType,
} from '@ws1c/intelligence-models';

export const widgetDetailsUrlBySkinType: Record<number, string> = {
  [WidgetDetailPageSkinType.APPS]: ROUTE_NAMES.DASHBOARD.APP_WIDGET_DETAILS,
  [WidgetDetailPageSkinType.CARBON_BLACK]: ROUTE_NAMES.DASHBOARD.CARBON_BLACK_DETAILS,
  [WidgetDetailPageSkinType.CVE]: ROUTE_NAMES.DASHBOARD.SECURITY_CVE_WIDGET,
  [WidgetDetailPageSkinType.CVE_DASHBOARD]: ROUTE_NAMES.SOLUTIONS_CVE_WIDGET_DETAILS,
  [WidgetDetailPageSkinType.DEEM_INCIDENTS]: ROUTE_NAMES.SOLUTIONS_DEEM_WIDGET_DETAILS,
  [WidgetDetailPageSkinType.DEEM_SURVEYS]: ROUTE_NAMES.SOLUTIONS_DEEM_SURVEYS_WIDGET_DETAILS,
  [WidgetDetailPageSkinType.DEVICES]: ROUTE_NAMES.DASHBOARD.DEVICES_WIDGET_DETAILS,
  [WidgetDetailPageSkinType.DEVICE_DESKTOP_NETWORK]: ROUTE_NAMES.DASHBOARD.DESKTOP_NETWORK_SIGNAL,
  [WidgetDetailPageSkinType.DEVICE_RISK]: ROUTE_NAMES.DASHBOARD.SECURITY_DEVICE_WIDGET,
  [WidgetDetailPageSkinType.HORIZON_COUNT_TITAN]: ROUTE_NAMES.DASHBOARD.HORIZON_MONITORING_TITAN_COUNTER_DETAILS,
  [WidgetDetailPageSkinType.HORIZON_SESSIONS]: ROUTE_NAMES.DASHBOARD.HORIZON_MONITORING_WIDGET_DETAILS,
  [WidgetDetailPageSkinType.HORIZON_SESSIONS_TITAN]: ROUTE_NAMES.DASHBOARD.HORIZON_MONITORING_TITAN_WIDGET_DETAILS,
  [WidgetDetailPageSkinType.LOGIN_RISK]: ROUTE_NAMES.DASHBOARD.LOGIN_RISK_DETAILS,
  [WidgetDetailPageSkinType.OS_UPDATES]: ROUTE_NAMES.DASHBOARD.OS_DETAILS,
  [WidgetDetailPageSkinType.SECURITY_RISK]: ROUTE_NAMES.DASHBOARD.SECURITY_WIDGET,
  [WidgetDetailPageSkinType.UAG_TUNNEL]: ROUTE_NAMES.DASHBOARD.UAG_TUNNEL_DETAILS,
  [WidgetDetailPageSkinType.USER_FLOWS]: ROUTE_NAMES.DASHBOARD.APP_USER_FLOWS_DETAILS,
  [WidgetDetailPageSkinType.USER_RISK]: ROUTE_NAMES.DASHBOARD.USER_RISK_DETAILS,
  [WidgetDetailPageSkinType.WORKSPACE_ONE_MTD]: ROUTE_NAMES.DASHBOARD.WORKSPACE_ONE_MTD_DETAILS,
};

/**
 * getWidgetDetailPageUrl
 * Get the URL for the WidgetDetailPage
 * @param {WidgetDetailPage} widgetDetailPageInfo
 * @param {RouterExtensions} routerExtensions
 * @returns {string}
 */
export function getWidgetDetailPageUrl(widgetDetailPageInfo: WidgetDetailPage, routerExtensions: RouterExtensions): string {
  const widgetId = widgetDetailPageInfo.widgetId || '';
  const widgetDetailDefinition = widgetDetailPageInfo.widgetDetailDefinition;
  const widgetDetailDefinitionString = JSON.stringify(widgetDetailDefinition);
  const dashboardId = widgetDetailPageInfo.dashboardId;
  const drilldownEventsString = JSON.stringify(widgetDetailPageInfo.drilldownEvents);
  const queryParams: any = {
    // widgetId is used for Pendo Tracking Only
    widgetId: widgetId || widgetDetailDefinition?.widgetId || widgetDetailDefinition?.id,
    widgetDetailDefinition: widgetDetailDefinitionString,
    drilldownEvents: drilldownEventsString,
    drilldownIndex: widgetDetailPageInfo.drilldownIndex,
  };
  if (widgetDetailPageInfo.skinType === WidgetDetailPageSkinType.CUSTOM) {
    return routerExtensions.getNavigationUrl([ROUTE_NAMES.DASHBOARD.CUSTOM_WIDGET_DETAILS(dashboardId), widgetId], { queryParams });
  }
  if (widgetDetailPageInfo.skinType === WidgetDetailPageSkinType.DEVICE_DESKTOP_NETWORK) {
    return routerExtensions.getNavigationUrl([ROUTE_NAMES.DASHBOARD.DESKTOP_NETWORK_SIGNAL], { queryParams });
  }
  const widgetDetailsUrl = widgetDetailsUrlBySkinType[widgetDetailPageInfo.skinType];
  return routerExtensions.getNavigationUrl([widgetDetailsUrl ?? ROUTE_NAMES.DASHBOARD.WIDGET_DETAILS, widgetId], {
    queryParams,
  });
}

/**
 * getDeviceDetailPageUrl
 * @param {Device} deviceInfo
 * @param {string} path
 * @param {RouterExtensions} routerExtensions
 * @param {Params} additionalParams
 * @returns {string}
 */
export function getDeviceDetailPageUrl(
  deviceInfo: Device,
  path: string,
  routerExtensions: RouterExtensions,
  additionalParams: Params = {},
): string {
  const id = deviceInfo.id;
  const commands = path.includes(ROUTE_NAMES.DASHBOARD.DEVICES) ? [path, id, DevicesProfileTabType.OVERVIEW] : [path, id];
  const parentConfigKey: string = `${SubDashboardId.DEVICES}_${SubDashboardPlatform.ALL}`.toUpperCase();
  const breadCrumbs: BreadCrumb[] = [
    new BreadCrumb({
      pathUrl: DeemDashboardConfig.BreadCrumbUrlConfig[parentConfigKey]?.pathUrl,
      pathLabelKey: DeemDashboardConfig.BreadCrumbUrlConfig[parentConfigKey]?.pathLabelKey,
    }),
  ];
  const queryParams: Params = {
    ...deviceInfo,
    [AppConfig.QUERY_PARAM_PAGE_TITLE]: deviceInfo.name,
    [AppConfig.QUERY_PARAM_BREAD_CRUMBS]: JSON.stringify(breadCrumbs),
    ...additionalParams,
  };
  return routerExtensions.getNavigationUrl(commands, { queryParams });
}

// Widget Incremental Loading

const uiQueryIdVsBackendQueryIdMap = new Map<string, string>();
const uiQueryIdVsTrackingIdsMap = new Map<string, string[]>();
const deletedUiQueryIds = new Set<string>();

/**
 * getNewUiQueryId
 * @returns {string}
 */
const getNewUiQueryId = () => uniqueId('ui_query_id_');

/**
 * registerUiQueryId
 * @param {string} uiQueryId
 * @param {string} backendQueryId
 */
const registerUiQueryId = (uiQueryId: string, backendQueryId: string) => {
  uiQueryIdVsBackendQueryIdMap.set(uiQueryId, backendQueryId);
};

/**
 * cleanupUiQueryId
 * @param {string} uiQueryId
 */
const cleanupUiQueryId = (uiQueryId: string) => {
  uiQueryIdVsBackendQueryIdMap.delete(uiQueryId);
  deletedUiQueryIds.delete(uiQueryId);
  uiQueryIdVsTrackingIdsMap.delete(uiQueryId);
};

/**
 * markUiQueryIdsAsDeleted
 * @param {string[]} uiQueryIds
 */
const markUiQueryIdsAsDeleted = (uiQueryIds: string[]) => {
  uiQueryIds?.forEach((uiQueryId: string) => {
    deletedUiQueryIds.add(uiQueryId);
  });
};

/**
 * isActiveUiQueryId
 * @param {string} uiQueryId
 * @returns {boolean}
 */
const isActiveUiQueryId = (uiQueryId: string): boolean => {
  return uiQueryIdVsBackendQueryIdMap.has(uiQueryId) && !deletedUiQueryIds.has(uiQueryId);
};

/**
 * isDeletedUiQueryId
 * @param {string} uiQueryId
 * @returns {boolean}
 */
const isDeletedUiQueryId = (uiQueryId: string): boolean => {
  return deletedUiQueryIds.has(uiQueryId);
};

/**
 * getBackendQueryId
 * @param {string} uiQueryId
 * @returns {string}
 */
const getBackendQueryId = (uiQueryId: string): string => {
  return uiQueryIdVsBackendQueryIdMap.get(uiQueryId);
};

/**
 * updateUiQueryIdVsTrackingIdsMap
 * @param {string} uiQueryId
 * @param {string[]} trackingIds
 */
const updateUiQueryIdVsTrackingIdsMap = (uiQueryId: string, trackingIds: string[]) => {
  uiQueryIdVsTrackingIdsMap.set(uiQueryId, trackingIds);
};

/**
 * getTrackingIdsByUiQueryId
 * @param {string} uiQueryId
 * @returns {string[]}
 */
const getTrackingIdsByUiQueryId = (uiQueryId: string): string[] => {
  return uiQueryIdVsTrackingIdsMap.get(uiQueryId) ?? [];
};

/**
 * getQueryIdsToCancel
 * @param {Map<string, IncrementalLoadingWidgetDetails>} trackingIdDetailsMap
 * @param {Map<string, Map<string, IncrementalLoadingWidgetDetails>>} existingQueryIdTrackingIdMap
 * @returns {{ queryIdsToCancel: string[], trackingIdDetailsMap: Map<string, IncrementalLoadingWidgetDetails>}}
 */
const getUiQueryIdsToCancel = (
  trackingIdDetailsMap: Map<string, IncrementalLoadingWidgetDetails>,
  existingQueryIdTrackingIdMap: Map<string, IncrementalLoadingWidgetDetails>,
): { queryIdsToCancel: string[]; trackingIdDetailsMap: Map<string, IncrementalLoadingWidgetDetails> } => {
  /*
    existingQueryIdTrackingIdMap = {
      query_1: {
        track_1: {_q1_t1},
        track_2: {_q1_t2},
        track_3: {_q1_t3},
      },
      query_2: {
        track_4: {_q2_t4},
        track_5: {_q2_t5},
      },
      query_3: {
        track_6: {_q3_t6},
      }
    };

    newTrackingIdMap: {
      track_1: {_n1_t1},
      track_4: {_n1_t4},
    }

    return value
    trackingIdVsTrendDefMap: {
      track_1: {_n1_t1},
      track_2: {_q1_t2},
      track_3: {_q1_t3},
      track_4: {_n1_t4},
      track_5: {_q2_t5},
    }
    queryIdsToCancel: [query_1, query_2]
   */

  const queryIdsToCancel = new Set<string>();
  const newTrackingDetailsMap: Map<string, IncrementalLoadingWidgetDetails> = new Map(trackingIdDetailsMap);

  const trackingIdVsQueryIdMap = new Map<string, string>();
  uiQueryIdVsTrackingIdsMap.forEach((trackingIdsArr: string[], qId: string) => {
    trackingIdsArr.forEach((trackingId: string) => {
      trackingIdVsQueryIdMap.set(trackingId, qId);
    });
  });

  for (const newTrackingId of trackingIdDetailsMap.keys()) {
    // if newTrackingId already exists, cancel the associated queryId
    if (existingQueryIdTrackingIdMap.has(newTrackingId)) {
      queryIdsToCancel.add(trackingIdVsQueryIdMap.get(newTrackingId));
    }
  }

  // for each queryId, get the entries other than new tracking ids and created merged map
  queryIdsToCancel.forEach((queryId: string) => {
    const trackingIdsAssociatedWithQueryId = uiQueryIdVsTrackingIdsMap.get(queryId);

    trackingIdsAssociatedWithQueryId?.forEach((trackingId: string) => {
      if (trackingIdDetailsMap.has(trackingId)) {
        return;
      }

      newTrackingDetailsMap.set(trackingId, existingQueryIdTrackingIdMap.get(trackingId));
    });
  });

  return {
    queryIdsToCancel: Array.from(queryIdsToCancel),
    trackingIdDetailsMap: newTrackingDetailsMap,
  };
};

export const helper = {
  getWidgetDetailPageUrl,
  getDeviceDetailPageUrl,
  getNewUiQueryId,
  registerUiQueryId,
  cleanupUiQueryId,
  getBackendQueryId,
  markUiQueryIdsAsDeleted,
  isActiveUiQueryId,
  isDeletedUiQueryId,
  updateUiQueryIdVsTrackingIdsMap,
  getUiQueryIdsToCancel,
  getTrackingIdsByUiQueryId,
};
