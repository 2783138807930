/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { createAction, props } from '@ngrx/store';

import { Ws1BrokerAllDirectories } from '@ws1c/intelligence-models';

/**
 * WS1BrokerActions
 * @export
 * @class WS1BrokerActions
 */
export class WS1BrokerActions {
  public static readonly CATEGORY = '[WS1_BROKER]';

  public static getBrokerDirectories = createAction(
    `${WS1BrokerActions.CATEGORY} GET_BROKER_DIRECTORIES`,
    props<{ integrationId: string }>(),
  );

  public static getBrokerDirectoriesSuccess = createAction(
    `${WS1BrokerActions.CATEGORY} GET_BROKER_DIRECTORIES_SUCCESS`,
    props<{ ws1BrokerDirectories: Ws1BrokerAllDirectories }>(),
  );

  public static getBrokerDirectoriesFailure = createAction(`${WS1BrokerActions.CATEGORY} GET_BROKER_DIRECTORIES_FAILURE`);
}
