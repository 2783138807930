/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { IntelligenceCommonModule } from '@ws1c/intelligence-common';
import { DashboardSelectors } from '@ws1c/intelligence-core/store/dashboard';
import { UserPreferenceFeatureControlsSelectors } from '@ws1c/intelligence-core/store/user-preference';
import { IncrementalLoadingResponseTrendStatus } from '@ws1c/intelligence-models';

/**
 * WidgetIncrementalLoadingStatusComponent
 *
 * @export
 * @class WidgetIncrementalLoadingStatusComponent
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-widget-incremental-loading-status',
  templateUrl: 'widget-incremental-loading-status.component.html',
  styleUrls: ['widget-incremental-loading-status.component.scss'],
  standalone: true,
  imports: [IntelligenceCommonModule],
})
export class WidgetIncrementalLoadingStatusComponent implements OnChanges, OnDestroy {
  @Input() public trackingIds: string[];
  @Input() public showAsSpinner: boolean = false;
  @Input() public showAsProgressBar: boolean = false;

  public readonly IncrementalLoadingResponseTrendStatus = IncrementalLoadingResponseTrendStatus;

  public isIncrementalLoadingEnabled$: Observable<boolean>;
  public status: IncrementalLoadingResponseTrendStatus;
  public tooltipText: string;

  private sub = new Subscription();

  /**
   * constructor
   * @param {Store<MerlotState>} store
   * @param {ChangeDetectorRef} cdRef
   * @memberof WidgetIncrementalLoadingStatusComponent
   */
  constructor(
    private store: Store<MerlotState>,
    private cdRef: ChangeDetectorRef,
  ) {
    this.isIncrementalLoadingEnabled$ = this.store.select(UserPreferenceFeatureControlsSelectors.isWidgetsIncrementalLoadingEnabled);
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof WidgetIncrementalLoadingStatusComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (!changes.trackingIds?.currentValue) {
      return;
    }

    this.updateStatus();
  }

  /**
   * ngOnDestroy
   * @memberof WidgetIncrementalLoadingStatusComponent
   */
  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /**
   * updateStatus
   * @memberof WidgetIncrementalLoadingStatusComponent
   */
  private updateStatus() {
    this.sub.unsubscribe();
    if (!this.trackingIds?.length) {
      return;
    }
    this.sub = this.store
      .select(DashboardSelectors.getLoadingStatusByTrackingIds(this.trackingIds))
      .pipe(
        distinctUntilChanged(isEqual),
        map((widgetStatus: IncrementalLoadingResponseTrendStatus[]) => {
          return widgetStatus.filter((status: IncrementalLoadingResponseTrendStatus) => !!status);
        }),
        filter((widgetStatus: IncrementalLoadingResponseTrendStatus[]) => widgetStatus.length > 0),
      )
      .subscribe((widgetStatus: IncrementalLoadingResponseTrendStatus[]) => {
        if (widgetStatus.includes(IncrementalLoadingResponseTrendStatus.INPROGRESS)) {
          this.status = IncrementalLoadingResponseTrendStatus.INPROGRESS;
          this.tooltipText = 'INCREMENTAL_LOADING.IN_PROGRESS';
        } else if (widgetStatus.includes(IncrementalLoadingResponseTrendStatus.COMPLETED_WITH_FAILURE)) {
          this.status = IncrementalLoadingResponseTrendStatus.COMPLETED_WITH_FAILURE;
          this.tooltipText = 'INCREMENTAL_LOADING.FAILED';
          return;
        } else if (
          widgetStatus.length &&
          widgetStatus.every(
            (status: IncrementalLoadingResponseTrendStatus) => status === IncrementalLoadingResponseTrendStatus.COMPLETED_WITH_SUCCESS,
          )
        ) {
          this.status = IncrementalLoadingResponseTrendStatus.COMPLETED_WITH_SUCCESS;
          this.tooltipText = 'INCREMENTAL_LOADING.COMPLETED';
        }

        this.cdRef.detectChanges();
      });
  }
}
