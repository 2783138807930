/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DateTimePickerType, FuzzySubgroup, FuzzySubgroupConfig, GenericObject } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { isNumber, isUndefined, uniq } from 'lodash-es';
import { combineLatest, Connectable, connectable, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { I18NService } from '@ws1c/intelligence-common';
import { CoreAppState, IntegrationMetaActions, IntegrationMetaSelectors, OrgSelectors } from '@ws1c/intelligence-core/store';
import {
  Category,
  Column,
  DataType,
  FilterRule,
  isIntegerDataType,
  isNumberDataType,
  MetaFormFieldPresentationType,
  Operator,
  OrgTreeNode,
  SuggestionCriteria,
  SuggestionFilterBy,
  SuggestionSearch,
  SuggestionSearchItem,
} from '@ws1c/intelligence-models';

/**
 * FilterValueComponent
 * @export
 * @class FilterValueComponent
 * @implements {OnChanges}
 * @implements {OnInit}
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-filter-value',
  templateUrl: 'filter-value.component.html',
  styleUrls: ['filter-value.component.scss'],
})
export class FilterValueComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  public static readonly MAX_SUGGESTION_COUNT = 100;

  @Input() public disabled: boolean;
  @Input() public filterRule: FilterRule;
  @Input() public column: Column;
  @Input() public selectedValue: any;
  @Input() public suggestionFilterBys: SuggestionFilterBy[];
  @Input() public dropdownAlignRight?: boolean = false;
  @Input() public placeholderText?: string = '';
  @Input() public suggestionCategory?: Category;
  @Input() public selectedCondition: Operator;
  @Input() public delimiterSupported?: boolean = false;
  @Output() public selectedValueChange = new EventEmitter<any>();

  public orgHierachy$: Observable<OrgTreeNode>;
  public startValue: any;
  public endValue: any;
  public startDate: any;
  public endDate: any;
  public formGroup = new UntypedFormGroup({});
  public fieldName: string;

  public selectedValues: any[] = [];
  public selectedItems: any[] = [];
  public autoCompletedItems: SuggestionSearchItem[] = [];
  public subs = new Subscription();
  public DATA_TYPE = DataType;
  public query$: Subject<string> = new Subject();
  public suggestionSearch$: Connectable<SuggestionSearch>;
  public suggestionValuesBySuggestionSearch: Map<SuggestionSearch, any[]>;
  public fuzzySubgroupConfig: FuzzySubgroupConfig;
  public isSuggestionLoading$: Observable<boolean>;
  public isNumber = isNumberDataType;
  public booleanItems = ['true', 'false'];
  public DATE_TIME_PICKER_TYPE = DateTimePickerType;
  public isQueryMaxInvalid: boolean = false;
  public isQueryMinInvalid: boolean = false;
  public noValueFoundText: string = 'NO_VALUE_FOUND';
  public defaultMinWarnText: string;

  public readonly MetaFormFieldPresentationType = MetaFormFieldPresentationType;
  public readonly FILTER_CONDITION = FilterRule.FILTER_CONDITION;
  public readonly MAX_TEXT_LENGTH: number = 1000;
  public readonly MIN_TEXT_LENGTH: number = 2;

  /**
   * Creates an instance of FilterValueComponent.
   * @param {Store<CoreAppState>} store
   * @param {ChangeDetectorRef} changeDetector
   * @param {I18NService} i18NService
   * @memberof FilterValueComponent
   */
  constructor(
    private store: Store<CoreAppState>,
    public changeDetector: ChangeDetectorRef,
    public i18NService: I18NService,
  ) {
    this.orgHierachy$ = this.store.select(OrgSelectors.getOrgHierarchy);
    this.suggestionSearch$ = connectable(this.query$.pipe(map((query: string) => this.buildSuggestionSearch(query))), {
      connector: () => new Subject(),
    });

    this.formGroup = new UntypedFormGroup({
      ruleValue: new UntypedFormControl('', Validators.required),
    });

    this.defaultMinWarnText = this.i18NService.translate('GLOBAL_SEARCH.SEARCH_DEVICES_MIN_LENGTH', {
      n: this.MIN_TEXT_LENGTH,
    });
  }

  /**
   * ngOnInit
   * @memberof FilterValueComponent
   */
  public ngOnInit() {
    this.subs.add(
      this.suggestionSearch$.subscribe((suggestionSearch: SuggestionSearch) => {
        this.isSuggestionLoading$ = this.store.select(IntegrationMetaSelectors.isLoadingSuggestionSearch(suggestionSearch));
        if (this.isQueryMaxInvalid || this.isQueryMinInvalid) {
          return;
        }
        this.store.dispatch(
          IntegrationMetaActions.searchFilterValues({
            suggestionSearch,
          }),
        );
      }),
    );

    this.subs.add(
      combineLatest([this.store.select(IntegrationMetaSelectors.getSuggestionValuesBySuggestionSearch), this.suggestionSearch$])
        .pipe(
          map(([suggestionValuesBySuggestionSearch, suggestionSearch]: [Map<SuggestionSearch, any[]>, SuggestionSearch]) => {
            return suggestionValuesBySuggestionSearch.get(suggestionSearch);
          }),
          filter((filterValues: SuggestionSearchItem[]) => !!filterValues),
        )
        .subscribe((filterValues: SuggestionSearchItem[]) => this.onFilterValueChange(filterValues)),
    );
    this.subs.add(
      this.formGroup.valueChanges
        .pipe(
          distinctUntilChanged((prev: GenericObject, next: GenericObject) => {
            return prev.ruleValue === next.ruleValue;
          }),
          debounceTime(30),
        )
        .subscribe((values: GenericObject) => {
          this.onChange(values.ruleValue);
        }),
    );

    this.suggestionSearch$.connect();
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof FilterValueComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedValue) {
      const selectedValues = this.toArrayFormat(changes.selectedValue.currentValue);
      this.selectedValues = selectedValues;
      this.selectedItems = selectedValues.map((value) => this.wrapValue(value));
      this.startValue = selectedValues[0];
      this.endValue = selectedValues[1];
      // Make sure startDate/endDate are valid numbers since current selected values could
      // be object (relative date)
      this.startDate = isNumber(selectedValues[0]) ? selectedValues[0] : null;
      this.endDate = isNumber(selectedValues[1]) ? selectedValues[1] : null;
      this.setSelectedValueForTextInput();
    }
    if (changes.selectedCondition?.currentValue) {
      const minLength = this.selectedCondition?.minLength > 0 ? this.selectedCondition?.minLength : 0;
      if (this.getUiPresentationType() === MetaFormFieldPresentationType.TEXT_INPUT) {
        this.formGroup.get('ruleValue').setValidators(Validators.minLength(minLength));
      }
      this.setSelectedValueForTextInput();
    }
  }

  /**
   * ngAfterViewInit
   * This method sets the right value when edit page is loaded directly.
   * @memberof FilterValueComponent
   */
  public ngAfterViewInit() {
    this.setSelectedValueForTextInput();
  }

  /**
   * ngOnDestroy
   * @memberof FilterValueComponent
   */
  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * onFilterValueChange
   * @param {string[]} filterValues
   */
  public onFilterValueChange(filterValues: SuggestionSearchItem[]) {
    this.autoCompletedItems = filterValues || [];
    this.setFuzzySubgroupConfig(filterValues);

    // The searchFilterValues action and corresponding store update is not detected for some reason
    this.changeDetector.markForCheck();
  }

  /**
   * setFuzzySubgroupConfig
   * @param {SuggestionSearchItem[]} filterValues
   */
  public setFuzzySubgroupConfig(filterValues: SuggestionSearchItem[]) {
    const allGroupNames = filterValues
      .map((filterValue: SuggestionSearchItem) => filterValue.groupName)
      .filter((groupName: string) => !!groupName);
    const uniqueGroupNames = uniq(allGroupNames);

    if (!uniqueGroupNames.length) {
      this.fuzzySubgroupConfig = undefined;
    } else {
      this.fuzzySubgroupConfig = {
        getKey: (suggestionSearchItem: SuggestionSearchItem) => {
          return suggestionSearchItem.groupName;
        },
        subgroups: uniqueGroupNames.map((filterValueGroupName: string) => {
          return {
            title: filterValueGroupName,
            key: filterValueGroupName,
          } as FuzzySubgroup;
        }) as FuzzySubgroup[],
      } as FuzzySubgroupConfig;
    }
  }

  /**
   * toArrayFormat
   * @param {any | any[]} value
   * @returns {any[]}
   * @memberof FilterValueComponent
   */
  public toArrayFormat(value: any | any[]): any[] {
    if (isUndefined(value)) {
      return [];
    }
    if (!Array.isArray(value)) {
      return [value];
    }
    return value;
  }

  /**
   * buildSuggestionSearch
   * @param  {string} query
   * @returns {SuggestionSearch}
   * @memberof FilterValueComponent
   */
  public buildSuggestionSearch(query: string): SuggestionSearch {
    if (!this.column) {
      return;
    }
    return {
      category: this.suggestionCategory || Category.fromEntityIntegrationNames(this.column.integration, this.column.entity),
      suggestionCriteria: new SuggestionCriteria({
        filterBys: this.suggestionFilterBys,
        columnName: this.column.attributeName,
        query,
        size: FilterValueComponent.MAX_SUGGESTION_COUNT,
        groupFieldName: this.column.groupableBy,
      }),
    } as SuggestionSearch;
  }

  /**
   * onOrganizationGroupSelection
   * @param {number[]} orgGroups
   * @memberof FilterValueComponent
   */
  public onOrganizationGroupSelection(orgGroups: number[]) {
    const changeVal = this.filterRule.condition === 'CONTAINS' ? orgGroups[0] : orgGroups;
    this.onChange(changeVal);
  }

  /**
   * onChange
   * @param {any[] | any} values
   * @memberof FilterValueComponent
   */
  public onChange(values: any[] | any) {
    if (!this.column) {
      return;
    }
    if (this.column.dataType === DataType[DataType.BOOLEAN]) {
      values = values === 'true';
    }
    this.selectedValueChange.emit(values);
  }

  /**
   * onStartDateChange
   * @param {Date} startDate
   * @memberof FilterValueComponent
   */
  public onStartDateChange(startDate: Date) {
    this.onChange(startDate?.valueOf());
  }

  /**
   * onEndDateChange
   * @param {Date} endDate [description]
   * @memberof FilterValueComponent
   */
  public onEndDateChange(endDate: Date) {
    this.onChange([this.startValue, endDate?.valueOf()]);
  }

  /**
   * getDateFromTimestamp
   *
   * @param {number} timestamp
   * @returns {Date}
   * @memberof FilterValueComponent
   */
  public getDateFromTimestamp(timestamp: number): Date {
    if (!timestamp) {
      return;
    }
    return new Date(timestamp);
  }

  /**
   * getUiPresentationType
   * @returns {MetaFormFieldPresentationType}
   * @memberof FilterValueComponent
   */
  public getUiPresentationType(): MetaFormFieldPresentationType {
    if (
      [
        FilterRule.FILTER_CONDITION.startsWith,
        FilterRule.FILTER_CONDITION.notStartsWith,
        FilterRule.FILTER_CONDITION.contains,
        FilterRule.FILTER_CONDITION.containsSubstring,
        FilterRule.FILTER_CONDITION.notContainsSubstring,
        FilterRule.FILTER_CONDITION.endsWith,
        FilterRule.FILTER_CONDITION.notEndsWith,
      ].includes(this.filterRule.condition) &&
      [DataType[DataType.STRING], DataType[DataType.UUID]].includes(this.filterRule.dataType)
    ) {
      return MetaFormFieldPresentationType.TEXT_INPUT;
    }
    if ([DataType[DataType.IPV4], DataType[DataType.IPV4LIST]].includes(this.filterRule.dataType ?? this.column?.dataType)) {
      return this.getUiPresentationTypeForIPV4();
    }
    if ([FilterRule.FILTER_CONDITION.within, FilterRule.FILTER_CONDITION.notWithin].includes(this.filterRule.condition)) {
      return MetaFormFieldPresentationType.WITHIN;
    }
    if (this.usingBackendSuggestionSource()) {
      return [FilterRule.FILTER_CONDITION.equals, FilterRule.FILTER_CONDITION.notEquals].includes(this.filterRule.condition)
        ? MetaFormFieldPresentationType.SELECT
        : MetaFormFieldPresentationType.MULTISELECT;
    }
  }

  /**
   * getUiPresentationTypeForIPV4
   * @returns {MetaFormFieldPresentationType}
   * @memberof FilterValueComponent
   */
  public getUiPresentationTypeForIPV4(): MetaFormFieldPresentationType {
    if (this.filterRule.condition === FilterRule.FILTER_CONDITION.between) {
      return MetaFormFieldPresentationType.IPV4_RANGE_INPUT;
    } else if (
      [FilterRule.FILTER_CONDITION.constainsAllOf, FilterRule.FILTER_CONDITION.constainsAnyOf].includes(this.filterRule.condition)
    ) {
      return MetaFormFieldPresentationType.IPV4_LIST_INPUT;
    } else {
      return MetaFormFieldPresentationType.IPV4_INPUT;
    }
  }

  /**
   * getSearchableItems
   * @returns {SuggestionSearchItem[]}
   * @memberof FilterValueComponent
   */
  public getSearchableItems(): SuggestionSearchItem[] {
    return this.usingBackendSuggestionSource() ? this.autoCompletedItems : [];
  }

  /**
   * getFuzzySubgroupConfig
   * @returns {FuzzySubgroupConfig}
   * @memberof FilterValueComponent
   */
  public getFuzzySubgroupConfig(): FuzzySubgroupConfig {
    return this.usingBackendSuggestionSource() ? this.fuzzySubgroupConfig : undefined;
  }

  /**
   * onSelectedItemsChange
   * @param {any[]} selectedItems
   * @memberof FilterValueComponent
   */
  public onSelectedItemsChange(selectedItems) {
    const values = selectedItems.map((item) => this.unwrapValue(item));
    this.selectedValueChange.emit(values);
  }

  /**
   * onQueryChange - onSearch updates filterValues in store
   * @param {string} query
   * @memberof FilterValueComponent
   */
  public onQueryChange(query: string) {
    const queryLength = query?.length || 0;
    this.isQueryMaxInvalid = queryLength > this.MAX_TEXT_LENGTH;
    // no suggestion source then do nothing
    if (!this.usingBackendSuggestionSource()) {
      return;
    }
    // over max
    if (this.isQueryMaxInvalid) {
      return;
    }
    // non empty query with length < MIN_TEXT_LENGTH should give warning
    this.isQueryMinInvalid = queryLength > 0 && queryLength < this.MIN_TEXT_LENGTH;
    if (this.isQueryMinInvalid) {
      this.noValueFoundText = this.defaultMinWarnText;
      return;
    }
    this.noValueFoundText = 'NO_VALUE_FOUND';
    this.query$.next(query);
  }

  /**
   * usingBackendSuggestionSource
   * @returns {boolean}
   * @memberof FilterValueComponent
   */
  public usingBackendSuggestionSource(): boolean {
    return this.column && this.column.suggestionSupported;
  }

  /**
   * wrapValue
   * @param {string} value
   * @returns {{ value: string }}}
   * @memberof FilterValueComponent
   */
  public wrapValue(value: string): { value: string } {
    return { value };
  }

  /**
   * unwrapValue
   * @param {any} item
   * @returns {any}
   * @memberof FilterValueComponent
   */
  public unwrapValue(item: any): any {
    return item.value;
  }

  /**
   * getSupportedStep for input type number
   *
   * @param {string} dataType
   * @returns {(number | string)}
   * @memberof FilterValueComponent
   */
  public getSupportedStep(dataType: string): number | string {
    switch (DataType[dataType]) {
      case DataType.FLOAT:
      case DataType.DOUBLE:
        return 'any';
      case DataType.INTEGER:
      case DataType.LONG:
        return 1;
      default:
        return '';
    }
  }

  /**
   * getDateTimePickerTitle
   *
   * @param {('start' | 'end')} pickerName
   * @returns {any}
   * @memberof FilterValueComponent
   */
  public getDateTimePickerTitle(pickerName: 'start' | 'end') {
    if (!this.filterRule || !['BETWEEN', 'NOT BETWEEN'].includes(this.filterRule.condition)) {
      return;
    }

    const titleKey = pickerName === 'start' ? 'COMMON_MESSAGES.TIME_START' : 'COMMON_MESSAGES.TIME_END';

    return this.i18NService.translate(titleKey);
  }

  /**
   * Keydown event handler for preventing user inputting decimals for Integer data type.
   *
   * @export
   * @param {KeyboardEvent} $event - click event object
   */
  public onKeyDownForCheckingInputForIntegerDataType($event: KeyboardEvent) {
    if (isIntegerDataType(this.column.dataType) && $event.key === '.') {
      $event.preventDefault();
    }
  }

  /**
   * Paste event handler for preventing user inputting decimals for Integer data type.
   *
   * @export
   * @param {ClipboardEvent} $event - paste event object
   */
  public onPasteForCheckingInputForIntegerDataType($event: ClipboardEvent) {
    if (isIntegerDataType(this.column.dataType) && $event.clipboardData?.getData('text')?.includes('.')) {
      $event.preventDefault();
    }
  }
  /**
   * setSelectedValueForTextInput
   * @private
   * @memberof FilterValueComponent
   */
  private setSelectedValueForTextInput() {
    if (this.getUiPresentationType() === MetaFormFieldPresentationType.TEXT_INPUT) {
      this.selectedValue = Array.isArray(this.selectedValue) ? this.selectedValue[0] : this.selectedValue;
      this.formGroup.get('ruleValue').setValue(this.selectedValue);
    }
  }
}
