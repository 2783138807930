/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';

import {
  CustomReportPreviewSearchResponse,
  DashboardThresholdSummary,
  DevicesDesktopErrorDetailsPage,
  EntityThresholds,
  RawThresholdAttribute,
  RuleGroup,
  StandardDashboardType,
  TrendDateRange,
} from '@ws1c/intelligence-models';

/**
 * DevicesDashboardState
 * @export
 * @interface DevicesDashboardState
 */
export interface DevicesDashboardState {
  dashboardType: StandardDashboardType;
  filterRuleGroup: RuleGroup;
  trendDateRange: TrendDateRange;
  additionalParams: GenericObject;
  deviceId: string;
  errors: {
    detailsPage: DevicesDesktopErrorDetailsPage;
    detailsTrendDateRange: TrendDateRange;
  };
  performance: {
    selectedThresholdAttribute: RawThresholdAttribute;
    thresholdSummary: DashboardThresholdSummary;
    isModalOpen: boolean;
    deviceHealthResponse: Record<string, CustomReportPreviewSearchResponse>;
  };
  dashboardTypeList: StandardDashboardType[];
  thresholdsV2: EntityThresholds;
}

export const initialDevicesDashboardState: DevicesDashboardState = {
  dashboardType: undefined,
  filterRuleGroup: undefined,
  trendDateRange: undefined,
  additionalParams: {},
  deviceId: undefined,
  errors: {
    detailsPage: undefined,
    detailsTrendDateRange: undefined,
  },
  performance: {
    selectedThresholdAttribute: undefined,
    thresholdSummary: undefined,
    isModalOpen: false,
    deviceHealthResponse: undefined,
  },
  dashboardTypeList: [],
  thresholdsV2: undefined,
};
