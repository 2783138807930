<div class="trigger-if">
  <span
    [attr.data-service-type]="automation?.integration || automationTemplate?.integration | uppercase"
    class="trigger-action connection-icon mr-x1"
  ></span>
  <cds-icon
    shape="arrow"
    direction="right"
    size="22"
    class="mr-x1"
  ></cds-icon>
  <ng-container *ngIf="automation.iconLinks?.length > 0; else noAction">
    <div
      *ngFor="let iconLink of automation.iconLinks"
      class="badge-container"
    >
      <span
        [style.background-image]="iconLink | urlProperty"
        class="trigger-action connection-icon mr-x1"
      ></span>
    </div>
  </ng-container>

  <ng-template #noAction>
    <dpa-tooltip [tooltipText]="'WORKFLOWS.CANNOT_ENABLE_REASON' | translate">
      <cds-icon
        shape="ban"
        solid
        status="danger"
        size="28"
      ></cds-icon>
    </dpa-tooltip>
  </ng-template>
</div>
