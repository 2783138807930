/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { AccordionWizardConfig, I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import { SolutionSettingMetricService, SolutionSetupService } from '@ws1c/intelligence-core/services';
import {
  AlertBannerActions,
  SolutionSetupActions,
  SolutionSetupSelectors,
  UserPreferenceFeatureControlsSelectors,
} from '@ws1c/intelligence-core/store';
import {
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  Entity,
  ROUTE_NAMES,
  Solution,
  SOLUTION_ACCORDION_WIZARD_BY_ID,
  SolutionConfig,
  SolutionConfigType,
  SolutionSettingMetricKey,
  SolutionStepConfig,
  SolutionStepGroup,
} from '@ws1c/intelligence-models';

/**
 * SolutionSetupEffects
 * @export
 * @class SolutionSetupEffects
 */
@Injectable()
export class SolutionSetupEffects {
  /**
   * loadAllSolutionStepConfigs$
   * @type {Observable<Action>}
   * @memberof SolutionSetupEffects
   */
  public loadAllSolutionStepConfigs$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SolutionSetupActions.loadAllSolutionStepConfigs),
      withLatestFrom(
        this.store.select(SolutionSetupSelectors.isSolutionEnabled(Solution.CVE)),
        this.store.select(SolutionSetupSelectors.isSolutionEnabled(Solution.DEEM)),
      ),
      concatMap(([_, isCveEnabled, isDeemEnabled]: [Action, boolean, boolean]) =>
        [
          isCveEnabled && SolutionSetupActions.loadSolutionStepConfig({ solutionConfigType: SolutionConfigType.CVE }),
          isDeemEnabled && SolutionSetupActions.loadSolutionStepConfig({ solutionConfigType: SolutionConfigType.DEEM_V2 }),
        ].filter(Boolean),
      ),
    ),
  );

  /**
   * loadSolutionStepConfig$
   * @type {Observable<Action>}
   * @memberof SolutionSetupEffects
   */
  public loadSolutionStepConfig$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SolutionSetupActions.loadSolutionStepConfig),
      mergeMap(({ solutionConfigType }: ReturnType<typeof SolutionSetupActions.loadSolutionStepConfig>) => {
        return this.solutionSetupService.getSolutionStepConfig(solutionConfigType).pipe(
          map((solutionStepConfig: SolutionStepConfig) => SolutionSetupActions.loadSolutionStepConfigSuccess({ solutionStepConfig })),
          catchError((error: WebError) => of(SolutionSetupActions.loadSolutionStepConfigFailure({ solutionConfigType, error }))),
        );
      }),
    ),
  );

  /**
   * setAccordionWizardConfig$
   * @type {Observable<Action>}
   * @memberof SolutionSetupEffects
   */
  public setAccordionWizardConfig$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SolutionSetupActions.setAccordionWizardConfig),
      withLatestFrom(this.store.select(SolutionSetupSelectors.getSolutionStepConfigBySolution)),
      mergeMap(
        ([{ accordionWizardId }, solutionStepConfigBySolution]: [{ accordionWizardId: string }, Record<string, SolutionStepConfig>]) => {
          let solutionConfigType: string;
          if (accordionWizardId in SOLUTION_ACCORDION_WIZARD_BY_ID[SolutionConfigType.CVE]) {
            solutionConfigType = SolutionConfigType.CVE;
          }
          if (accordionWizardId in SOLUTION_ACCORDION_WIZARD_BY_ID[SolutionConfigType.DEEM_V2]) {
            solutionConfigType = SolutionConfigType.DEEM_V2;
          }
          return this.solutionSetupService.setSolutionStepConfig(solutionStepConfigBySolution[solutionConfigType]).pipe(
            map(() => SolutionSetupActions.setSolutionStepConfigSuccess()),
            catchError((error: WebError) => of(SolutionSetupActions.setSolutionStepConfigFailure({ error }))),
          );
        },
      ),
    ),
  );

  /**
   * navigateToDeemMarketplace$
   * @type {Observable<Action>}
   * @memberof DeemSetupEffects
   */
  public navigateToDeemMarketplace$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SolutionSetupActions.navigateToDeemMarketplace),
        tap(() => {
          this.routerExtensions.navigate([ROUTE_NAMES.MARKETPLACE.SOLUTIONS_DEEM]);
        }),
      ),
    { dispatch: false },
  );

  /**
   * enableDeemV2Virtual$
   * @type {Observable<Action>}
   * @memberof DeemSetupEffects
   */
  public enableDeemV2Virtual$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SolutionSetupActions.enableDeemV2Virtual),
      withLatestFrom(this.store.select(UserPreferenceFeatureControlsSelectors.isDeemVirtualInfraDashboardEnabled)),
      switchMap(([, isDeemVirtualInfraDashboardEnabled]: [ReturnType<typeof SolutionSetupActions.enableDeemV2Virtual>, boolean]) => {
        const steps = SolutionConfig.deemV2StepNames[SolutionStepGroup.HORIZON_CLOUD_SERVICES];
        const accordionWizardConfig = new AccordionWizardConfig({
          finishedStepIndices: new Set<any>(steps.map((_: string, index: number) => index)),
          stepCount: steps.length,
        });
        const resetRequests = [
          this.solutionSettingMetricService.resetSolutionSettingMetrics(
            new SolutionSettingMetricKey({ solutionType: Solution.DEEM, solutionSetting: Entity.EXPERIENCESCORE_HORIZON_SESSION }),
          ),
          this.solutionSettingMetricService.resetSolutionSettingMetrics(
            new SolutionSettingMetricKey({ solutionType: Solution.DEEM, solutionSetting: Entity.HORIZON_EXPERIENCESCORE_ORG }),
          ),
          this.solutionSettingMetricService.resetSolutionSettingMetrics(
            new SolutionSettingMetricKey({ solutionType: Solution.DEEM, solutionSetting: Entity.HORIZON_EXPERIENCESCORE_APP }),
          ),
        ];
        if (isDeemVirtualInfraDashboardEnabled) {
          resetRequests.push(
            this.solutionSettingMetricService.resetSolutionSettingMetrics(
              new SolutionSettingMetricKey({
                solutionType: Solution.DEEM,
                solutionSetting: Entity.HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
              }),
            ),
            this.solutionSettingMetricService.resetSolutionSettingMetrics(
              new SolutionSettingMetricKey({
                solutionType: Solution.DEEM,
                solutionSetting: Entity.HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
              }),
            ),
          );
        }
        return forkJoin(resetRequests).pipe(
          switchMap(() => [
            SolutionSetupActions.setAccordionWizardConfig({
              accordionWizardId: SOLUTION_ACCORDION_WIZARD_BY_ID[SolutionConfigType.DEEM_V2].DEEM_V2_HORIZON,
              accordionWizardConfig,
            }),
            SolutionSetupActions.navigateToDeemMarketplace(),
          ]),
          catchError(() => [
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate('SOLUTIONS.DEEM_ENABLE_FAILED'),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * Creates an instance of SolutionSetupEffects.
   * @param {Store} store
   * @param {Actions} actions$
   * @param {I18NService} i18nService
   * @param {RouterExtensions} routerExtensions
   * @param {SolutionSetupService} solutionSetupService
   * @param {SolutionSettingMetricService} solutionSettingMetricService
   * @memberof SolutionSetupEffects
   */
  constructor(
    private store: Store,
    private actions$: Actions,
    private i18nService: I18NService,
    private routerExtensions: RouterExtensions,
    private solutionSetupService: SolutionSetupService,
    private solutionSettingMetricService: SolutionSettingMetricService,
  ) {}
}
