/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { SortOn, WebError } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import { CveDetailPage, CveSearchRequest, CveSearchResponse, DateRangeFilters } from '@ws1c/intelligence-models';

/**
 * SecurityDashboardActions
 * @export
 * @class SecurityDashboardActions
 */
export class SecurityDashboardActions {
  public static readonly CATEGORY = '[DASHBOARD][SECURITY]';

  public static setDashboardFilters = createAction(`${SecurityDashboardActions.CATEGORY} SET_DASHBOARD_FILTERS`, props<DateRangeFilters>());

  public static loadDashboard = createAction(`${SecurityDashboardActions.CATEGORY} LOAD_DASHBOARD`);

  public static setVulnerabilitiesDashboardFilters = createAction(
    `${SecurityDashboardActions.CATEGORY} SET_VULNERABILITIES_DASHBOARD_FILTERS`,
    props<DateRangeFilters>(),
  );

  public static loadVulnerabilitiesMacOsDashboard = createAction(
    `${SecurityDashboardActions.CATEGORY} LOAD_VULNERABILITIES_MACOS_DASHBOARD`,
  );

  public static loadCveTable = createAction(`${SecurityDashboardActions.CATEGORY} LOAD_CVE_TABLE`, props<{ request: CveSearchRequest }>());

  public static loadCveTableSuccess = createAction(
    `${SecurityDashboardActions.CATEGORY} LOAD_CVE_TABLE_SUCCESS`,
    props<{ response: CveSearchResponse }>(),
  );

  public static loadCveTableFailure = createAction(
    `${SecurityDashboardActions.CATEGORY} LOAD_CVE_TABLE_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static searchCveTable = createAction(`${SecurityDashboardActions.CATEGORY} SEARCH_CVE_TABLE`, props<{ query: string }>());

  public static sortCveTable = createAction(`${SecurityDashboardActions.CATEGORY} SORT_CVE_TABLE`, props<{ sortOns: SortOn[] }>());

  public static loadCveSearch = createAction(
    `${SecurityDashboardActions.CATEGORY} LOAD_CVE_SEARCH`,
    props<{ request: CveSearchRequest }>(),
  );

  public static loadCveSearchSuccess = createAction(
    `${SecurityDashboardActions.CATEGORY} LOAD_CVE_SEARCH_SUCCESS`,
    props<{ response: CveSearchResponse }>(),
  );

  public static goToCveDetailPage = createAction(
    `${SecurityDashboardActions.CATEGORY} GO_TO_CVE_DETAIL_PAGE`,
    props<{ detailPage: CveDetailPage }>(),
  );

  public static clearCveDetailPage = createAction(`${SecurityDashboardActions.CATEGORY} CLEAR_CVE_DETAIL_PAGE`);

  public static setCveInsightCardVisible = createAction(
    `${SecurityDashboardActions.CATEGORY} SET_CVE_INSIGHT_CARD_VISIBLE`,
    props<{ isVisible: boolean }>(),
  );

  public static setMacOsCveInsightCardVisible = createAction(
    `${SecurityDashboardActions.CATEGORY} SET_MAC_OS_CVE_INSIGHT_CARD_VISIBLE`,
    props<{ isVisible: boolean }>(),
  );
}
