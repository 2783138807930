/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { PreviewReportContentRequest } from '@ws1c/intelligence-models';
import { UserProfileActions } from './user-profile.actions';
import { initialUserProfileState, UserProfileState } from './user-profile.state';

const _reducer: ActionReducer<UserProfileState> = createReducer(
  initialUserProfileState,
  on(
    UserProfileActions.setAppDetailUser,
    (state: UserProfileState, props: ReturnType<typeof UserProfileActions.setAppDetailUser>): UserProfileState => ({
      ...state,
      appDetailUser: props.user,
    }),
  ),
  on(
    UserProfileActions.setAppDetailUserProfileTab,
    (state: UserProfileState, props: ReturnType<typeof UserProfileActions.setAppDetailUserProfileTab>): UserProfileState => ({
      ...state,
      appDetailUserProfileTab: props.tab,
    }),
  ),
  on(
    UserProfileActions.loadUserProfileExperienceTables,
    (state: UserProfileState): UserProfileState => ({
      ...state,
      userProfile: {
        ...state.userProfile,
        experienceDeviceResponses: [],
      },
    }),
  ),
  on(
    UserProfileActions.loadUserProfileExperienceTablesSuccess,
    (state: UserProfileState, props: ReturnType<typeof UserProfileActions.loadUserProfileExperienceTablesSuccess>): UserProfileState => ({
      ...state,
      userProfile: {
        ...state.userProfile,
        experienceDeviceRequests: props.requests,
        experienceDeviceResponses: props.responses,
      },
    }),
  ),
  on(
    UserProfileActions.loadUserProfileExperienceMobile,
    (state: UserProfileState): UserProfileState => ({
      ...state,
      userProfile: {
        ...state.userProfile,
        experienceMobileDeviceResponse: undefined,
      },
    }),
  ),
  on(
    UserProfileActions.loadUserProfileExperienceMobileSuccess,
    (state: UserProfileState, props: ReturnType<typeof UserProfileActions.loadUserProfileExperienceMobileSuccess>): UserProfileState => ({
      ...state,
      userProfile: {
        ...state.userProfile,
        experienceMobileDeviceRequest: props.request,
        experienceMobileDeviceResponse: props.response,
      },
    }),
  ),
  on(
    UserProfileActions.cleanUserDeviceAndApp,
    (state: UserProfileState): UserProfileState => ({
      ...state,
      userProfile: {
        ...state.userProfile,
        experienceDeviceRequests: [],
        experienceDeviceResponses: [],
        experienceAppRequests: [],
        experienceAppResponses: [],
      },
    }),
  ),
  on(
    UserProfileActions.setDeviceTablePagedRequest,
    (state: UserProfileState, props: ReturnType<typeof UserProfileActions.setDeviceTablePagedRequest>): UserProfileState => {
      state.userProfile.experienceDeviceRequests[props.index] = new PreviewReportContentRequest({
        ...state.userProfile.experienceDeviceRequests[props.index],
        offset: props.request.from,
        pageSize: props.request.size,
      });
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          experienceDeviceRequests: [...state.userProfile.experienceDeviceRequests],
        },
      };
    },
  ),
  on(
    UserProfileActions.setDeviceTableSortOns,
    (state: UserProfileState, props: ReturnType<typeof UserProfileActions.setDeviceTableSortOns>): UserProfileState => {
      state.userProfile.experienceDeviceRequests[props.index] = new PreviewReportContentRequest({
        ...state.userProfile.experienceDeviceRequests[props.index],
        sortOns: props.sortOns,
      });
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          experienceDeviceRequests: [...state.userProfile.experienceDeviceRequests],
        },
      };
    },
  ),
  on(
    UserProfileActions.loadUserProfileExperienceApps,
    (state: UserProfileState): UserProfileState => ({
      ...state,
      userProfile: {
        ...state.userProfile,
        experienceAppResponses: [],
      },
    }),
  ),
  on(
    UserProfileActions.loadUserProfileExperienceAppsSuccess,
    (state: UserProfileState, props: ReturnType<typeof UserProfileActions.loadUserProfileExperienceAppsSuccess>): UserProfileState => ({
      ...state,
      userProfile: {
        ...state.userProfile,
        experienceAppRequests: props.requests,
        experienceAppResponses: props.responses,
      },
    }),
  ),
  on(
    UserProfileActions.setAppsTablePagedRequest,
    (state: UserProfileState, props: ReturnType<typeof UserProfileActions.setAppsTablePagedRequest>): UserProfileState => {
      state.userProfile.experienceAppRequests[props.index] = new PreviewReportContentRequest({
        ...state.userProfile.experienceAppRequests[props.index],
        offset: props.request.from,
        pageSize: props.request.size,
      });
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          experienceAppRequests: [...state.userProfile.experienceAppRequests],
        },
      };
    },
  ),
  on(
    UserProfileActions.setAppsTableSortOns,
    (state: UserProfileState, props: ReturnType<typeof UserProfileActions.setAppsTableSortOns>): UserProfileState => {
      state.userProfile.experienceAppRequests[props.index] = new PreviewReportContentRequest({
        ...state.userProfile.experienceAppRequests[props.index],
        sortOns: props.sortOns,
      });
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          experienceAppRequests: [...state.userProfile.experienceAppRequests],
        },
      };
    },
  ),
  on(
    UserProfileActions.setUserProfileInfo,
    (state: UserProfileState, { user }: ReturnType<typeof UserProfileActions.setUserProfileInfo>): UserProfileState => ({
      ...state,
      userProfile: {
        ...state.userProfile,
        user,
      },
    }),
  ),
);

/**
 * userProfileState
 * @export
 * @param {UserProfileState} state
 * @param {Action} action
 * @returns {UserProfileState}
 */
export function userProfileState(state: UserProfileState, action: Action): UserProfileState {
  return _reducer(state, action);
}
