/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import {
  Account,
  AccountUsageDetails,
  AutomationSearchRequest,
  AutomationSearchResponse,
  CustomReportSearchRequest,
  CustomReportSearchResponse,
  DashboardSearchRequest,
  DashboardSearchResponse,
  GenericSearchRequest,
  ServiceLimits,
  SystemLimitsFeatureTag,
  SystemLimitsSearchResponse,
  SystemLimitsUsageDetails,
} from '@ws1c/intelligence-models';

/**
 * Defines UI State for System Limits
 *
 * @export
 * @interface SystemLimitsState
 */
export interface SystemLimitsState {
  usageDetails: SystemLimitsUsageDetails[];
  usageDetailsLoading: boolean;
  activeTab: SystemLimitsFeatureTag;
  systemLimitsSearchRequest: GenericSearchRequest;
  systemLimitsSearchResponse: SystemLimitsSearchResponse;
  isSystemLimitsSearchInProgress: boolean;
  systemLimitsModalOpen: boolean;
  serviceLimitsLoading: boolean;
  serviceLimits: ServiceLimits[];
  userSystemLimit: {
    selectedAccountId: string;
    selectedAccount: Account;
    automationsUsageSummary: AccountUsageDetails;
    automationsUsageSummaryLoading: boolean;
    customReportsUsageSummary: AccountUsageDetails;
    customReportsUsageSummaryLoading: boolean;
    dashboardsUsageSummary: AccountUsageDetails;
    dashboardsUsageSummaryLoading: boolean;
    serviceLimitsLoading: boolean;
    serviceLimits: ServiceLimits[];
    automationSearchRequest: AutomationSearchRequest;
    automationSearchResponse: AutomationSearchResponse;
    isAutomationSearchInProgress: boolean;
    customReportSearchRequest: CustomReportSearchRequest;
    customReportSearchResponse: CustomReportSearchResponse;
    isCustomReportSearchInProgress: boolean;
    dashboardSearchRequest: DashboardSearchRequest;
    dashboardSearchResponse: DashboardSearchResponse;
    isDashboardSearchInProgress: boolean;
    userSystemLimitsModalOpen: boolean;
  };
}

export const initialSystemLimitsState: SystemLimitsState = {
  usageDetails: undefined,
  usageDetailsLoading: false,
  activeTab: undefined,
  systemLimitsSearchRequest: undefined,
  systemLimitsSearchResponse: undefined,
  isSystemLimitsSearchInProgress: false,
  systemLimitsModalOpen: false,
  serviceLimitsLoading: undefined,
  serviceLimits: undefined,
  userSystemLimit: {
    selectedAccountId: undefined,
    selectedAccount: undefined,
    automationsUsageSummary: undefined,
    automationsUsageSummaryLoading: false,
    customReportsUsageSummary: undefined,
    customReportsUsageSummaryLoading: false,
    dashboardsUsageSummary: undefined,
    dashboardsUsageSummaryLoading: false,
    serviceLimitsLoading: undefined,
    serviceLimits: undefined,
    automationSearchRequest: undefined,
    automationSearchResponse: undefined,
    isAutomationSearchInProgress: false,
    customReportSearchRequest: undefined,
    customReportSearchResponse: undefined,
    isCustomReportSearchInProgress: false,
    dashboardSearchRequest: undefined,
    dashboardSearchResponse: undefined,
    isDashboardSearchInProgress: false,
    userSystemLimitsModalOpen: false,
  },
};
