/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';

/**
 * SidePanelExpandedBodyComponent
 * @export
 * @class SidePanelExpandedBodyComponent
 */
@Component({
  selector: 'dpa-side-panel-expanded-body',
  template: '<ng-content></ng-content>',
})
export class SidePanelExpandedBodyComponent {}
