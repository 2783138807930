/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { TrendDateRange } from '@ws1c/intelligence-models';

/**
 * Defines UI State for WorkspaceOne Mobile Threat Defence Dashboard Feature
 *
 * @export
 * @interface WorkspaceOneMtdDashboardState
 */
export interface WorkspaceOneMtdDashboardState {
  trendDateRange: TrendDateRange;
}

export const initialWorkspaceOneMtdDashboardState: WorkspaceOneMtdDashboardState = {
  trendDateRange: undefined,
};
