<dpa-modal
  *ngIf="copyWidgetDialogOpen$ | async"
  [modalOpen]="copyWidgetDialogOpen$ | async"
  (onModalClose)="closeModal()"
>
  <dpa-modal-title>
    {{ 'DASHBOARD_ACTIONS.COPY_WIDGET_LABEL' | translate }}
  </dpa-modal-title>
  <div class="modal-body type-head-box">
    <div class="container">
      <form
        [formGroup]="copyWidgetForm"
        class="clr-form clr-form-horizontal"
      >
        <dpa-alert-banner
          *ngIf="copyWidgetDialogOpen$ | async"
          [target]="AlertBannerTarget.MODAL"
          class="bad-request-alert"
        ></dpa-alert-banner>

        <div class="form-group">
          <dpa-inline-edit
            [autofocus]="true"
            [isInvalid]="isRequiredWarningVisible('widgetName')"
            [invalidTooltip]="'FORM_VALIDATION.REQUIRED_FIELD' | translate"
            [placeholder]="'WIDGET_CUSTOMIZE.WIDGET_CHART_TITLE' | translate"
            [isRequired]="true"
            formControlName="widgetName"
            size="lg"
          ></dpa-inline-edit>
          <dpa-inline-edit
            [placeholder]="'COMMON_MESSAGES.ADD_DESCRIPTION_OPTIONAL' | translate"
            formControlName="widgetDescription"
          ></dpa-inline-edit>
        </div>
        <dpa-dashboard-selector
          (dashboardFormChange)="onDashboardFormChange($event)"
          (toggleNewDashboard)="onToggleNewDashboard($event)"
        ></dpa-dashboard-selector>
      </form>
    </div>
  </div>
  <div class="modal-footer pt-x0">
    <button
      (click)="closeModal()"
      class="btn btn-outline"
    >
      {{ 'COMMON_ACTIONS.CANCEL' | translate }}
    </button>
    <button
      [disabled]="dashboardForm?.invalid || copyWidgetForm?.invalid"
      (click)="confirmCopyWidget()"
      type="button"
      class="btn btn-primary"
    >
      <div *ngIf="isNewDashboard; else copyButtonText">
        {{ 'DASHBOARD_ACTIONS.CREATE_NEW_DASHBOARD' | translate }}
      </div>
      <ng-template #copyButtonText>
        {{ 'COMMON_ACTIONS.COPY' | translate }}
      </ng-template>
    </button>
  </div>
</dpa-modal>
