/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { ServiceAccount } from '@ws1c/intelligence-models';

/**
 * Defines UI State for Service Accounts
 *
 * @export
 * @interface ServiceAccountsState
 */
export interface ServiceAccountsState {
  serviceAccounts: ServiceAccount[];
  selectedServiceAccount: ServiceAccount;
  showRegenerateConfirmModal: boolean;
  showDeleteConfirmModal: boolean;
}

export const initialServiceAccountsState: ServiceAccountsState = {
  serviceAccounts: [],
  selectedServiceAccount: undefined,
  showRegenerateConfirmModal: false,
  showDeleteConfirmModal: false,
};
