/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DataGridColumn, PagedRequest } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { flatten, isEmpty } from 'lodash-es';

import { CoreAppState, IntegrationMetaSelectors } from '@ws1c/intelligence-core/store';
import { Column, ColumnIndex, CustomReportPreviewSearchResponse, EntityColumns, ReportColumnMapper } from '@ws1c/intelligence-models';

/**
 * FilterResultsComponent
 *
 * @export
 * @class FilterResultsComponent
 * @implements {OnChanges}
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-filter-results',
  templateUrl: 'filter-results.component.html',
  styleUrls: ['filter-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterResultsComponent implements OnChanges, OnInit {
  @Input() public availableColumns?: any[] = [];
  @Input() public filterResults: CustomReportPreviewSearchResponse;
  @Input() public refreshedAt?: number;
  @Input() public isLoadingPreview?: boolean = false;
  @Input() public shouldRefreshPreview?: boolean = false;
  @Input() public isReadOnly?: boolean = false;
  @Input() public isPredefinedReport?: boolean = false;
  @Input() public customPreviewMessage?: string;
  @Input() public placeholderMessageKey: string = 'REPORT_CUSTOMIZE.REFRESH_PREVIEW_MESSAGE';
  @Output() public onColumnSelection = new EventEmitter<Column[]>();
  @Output() public onPreviewChange = new EventEmitter<PagedRequest>();
  @Output() public onRunReport = new EventEmitter();

  public columnsByName: ColumnIndex = {};
  public allColumns: DataGridColumn[] = [];
  public dataGridColumns: DataGridColumn[] = [];
  public totalCount: number = 0;
  public ReportColumnMapper = ReportColumnMapper;
  public pagedRequest: PagedRequest;
  public isPreviewTimedout: boolean = false;

  private store: Store<CoreAppState> = inject(Store<CoreAppState>);
  private destroyRef: DestroyRef = inject(DestroyRef);

  /**
   * ngOnInit
   * @memberof FilterResultsComponent
   */
  public ngOnInit() {
    this.store
      .select(IntegrationMetaSelectors.getTableColumnsByEntityUnfiltered)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((entityColumns: EntityColumns[]) => {
        const columns = flatten(entityColumns?.map((entityColumn: EntityColumns) => entityColumn.columns));
        this.allColumns = ReportColumnMapper.getDataGridColumnsNew(columns);
      });
    this.store
      .select(IntegrationMetaSelectors.getColumnsByName)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((columnsByName: ColumnIndex) => {
        this.columnsByName = columnsByName || {};
      });
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof FilterResultsComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.availableColumns && changes.availableColumns.currentValue) {
      const availableColumns = changes.availableColumns.currentValue;
      this.dataGridColumns = ReportColumnMapper.getDataGridColumnsNew(availableColumns);
    }
    if (changes.filterResults) {
      const filterResults = changes.filterResults.currentValue;
      this.totalCount = filterResults?.total || filterResults?.total_count || 0;
      this.isPreviewTimedout = filterResults?.isTimedout;
    }
  }

  /**
   * onSelectedColumnsChange
   * @param {DataGridColumn[]} dataGridColumns
   */
  public onSelectedColumnsChange(dataGridColumns: DataGridColumn[]) {
    const columns = dataGridColumns.map((column: DataGridColumn) => this.columnsByName[column.value]);
    this.onColumnSelection.emit(columns);
  }

  /**
   * onPageChange
   * @param {PagedRequest} pagedRequest
   * @memberof FilterResultsComponent
   */
  public onPageChange(pagedRequest: PagedRequest = new PagedRequest()) {
    this.pagedRequest = pagedRequest;
    this.onPreviewChange.emit(pagedRequest);
  }

  /**
   * canViewDataGrid
   * @returns {boolean}
   * @memberof FilterResultsComponent
   */
  public canViewDataGrid(): boolean {
    return !isEmpty(this.dataGridColumns) && this.totalCount > 0;
  }

  /**
   * getNoResultsText
   * @returns {string}
   * @memberof FilterResultsComponent
   */
  public getNoResultsText(): string {
    if (isEmpty(this.dataGridColumns)) {
      return 'REPORT_CUSTOMIZE.ADD_YOUR_FIRST_COLUMN';
    }
    if (this.isPreviewTimedout) {
      return 'REPORT_CUSTOMIZE.REPORT_PREVIEW_UNAVAILABLE';
    }
    if (this.refreshedAt) {
      return 'REPORT_CUSTOMIZE.NO_RECORDS_FOUND';
    }
    if (this.isLoadingPreview) {
      return 'REPORT_CUSTOMIZE.LOADING_PREVIEW';
    }
    return this.placeholderMessageKey;
  }

  /**
   * runReport
   * @memberof FilterResultsComponent
   */
  public runReport() {
    this.onRunReport.emit();
  }
}
