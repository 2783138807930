/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { getFailureReason, WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { iif, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, startWith, switchMap, withLatestFrom } from 'rxjs/operators';

import { I18NService } from '@ws1c/intelligence-common';
import { BookmarksService } from '@ws1c/intelligence-core/services';
import {
  AlertBannerActions,
  BookmarksActions,
  BookmarksSelectors,
  CoreAppState,
  UserPreferenceFeatureControlsSelectors,
} from '@ws1c/intelligence-core/store';
import { ALERT_BANNER_TYPE, Bookmark, LabelValue } from '@ws1c/intelligence-models';

/**
 * BookmarksEffects
 *
 * @export
 * @class BookmarksEffects
 */
@Injectable()
export class BookmarksEffects {
  /**
   * addBookmark$
   * @type {Observable<Action>}
   * @memberof BookmarksEffects
   */
  public addBookmark$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(BookmarksActions.addBookmark),
      withLatestFrom(this.store.select(BookmarksSelectors.getCurrentBookmarkPath)),
      switchMap(
        ([action, bookmarkPath]: [
          {
            type: string;
            bookmark: Bookmark;
          },
          string,
        ]) => {
          const addBookmarkPayload: Bookmark = action.bookmark.getPayloadWithFallbackValues(bookmarkPath);
          return this.bookmarksService.addBookmark(addBookmarkPayload).pipe(
            mergeMap((bookmark: Bookmark) => [
              BookmarksActions.addBookmarkSuccess({ bookmark }),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.SUCCESS,
                message: this.i18nService.translate('BOOKMARKS.ADD_BOOKMARK_SUCCESS_MSG', {
                  name: this.i18nService.translate(bookmark.title),
                }),
              }),
            ]),
            catchError((error: WebError) =>
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  message: this.i18nService.translate('BOOKMARKS.ADD_BOOKMARK_ERROR_MSG', {
                    name: this.i18nService.translate(addBookmarkPayload.title),
                    reason: getFailureReason(error, '', this.i18nService.translate('COMMON_MESSAGES.GENERIC_ERROR_MSG')),
                  }),
                }),
              ),
            ),
          );
        },
      ),
    ),
  );

  /**
   * getBookmarks$
   * @type {Observable<Action>}
   * @memberof BookmarksEffects
   */
  public getBookmarks$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(BookmarksActions.getBookmarks),
      startWith(BookmarksActions.getBookmarks),
      switchMap(() => this.store.select(UserPreferenceFeatureControlsSelectors.isUserBookmarksEnabled)),
      switchMap((isUserBookmarksEnabled) =>
        iif(
          () => isUserBookmarksEnabled,
          this.bookmarksService.getBookmarks().pipe(
            map((bookmarks: Bookmark[]) => BookmarksActions.getBookmarksSuccess({ bookmarks })),
            catchError((error: WebError) =>
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  message: this.i18nService.translate('BOOKMARKS.GET_BOOKMARKS_ERROR_MSG', {
                    reason: error.getFullReason(),
                  }),
                }),
              ),
            ),
          ),
          of(AlertBannerActions.doNothing()),
        ),
      ),
    ),
  );

  /**
   * removeBookmark$
   * @type {Observable<Action>}
   * @memberof BookmarksEffects
   */
  public removeBookmark$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(BookmarksActions.removeBookmark),
      withLatestFrom(this.store.select(BookmarksSelectors.getCurrentBookmarkPath)),
      switchMap(
        ([action, bookmarkPath]: [
          {
            type: string;
            bookmark: Bookmark;
          },
          string,
        ]) => {
          const bookmark: Bookmark = action.bookmark.getPayloadWithFallbackValues(bookmarkPath);
          return this.bookmarksService.removeBookmark(bookmark).pipe(
            mergeMap(() => [
              BookmarksActions.removeBookmarkSuccess({ resourceId: bookmark.resourceId }),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.INFO,
                message: this.i18nService.translate('BOOKMARKS.REMOVE_BOOKMARK_SUCCESS_MSG', {
                  name: this.i18nService.translate(bookmark.title),
                }),
              }),
            ]),
            catchError((error: WebError) =>
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  message: this.i18nService.translate('BOOKMARKS.REMOVE_BOOKMARK_ERROR_MSG', {
                    name: this.i18nService.translate(bookmark.title),
                    reason: getFailureReason(error, '', this.i18nService.translate('COMMON_MESSAGES.GENERIC_ERROR_MSG')),
                  }),
                }),
              ),
            ),
          );
        },
      ),
    ),
  );

  /**
   * removeBookmarkSuccess$
   * @type {Observable<Action>}
   * @memberof BookmarksEffects
   */
  public removeBookmarkSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(BookmarksActions.removeBookmarkSuccess),
      withLatestFrom(
        this.store.select(BookmarksSelectors.getAvailableBookmarkCategories),
        this.store.select(BookmarksSelectors.getSelectedBookmarkFilterOption),
      ),
      switchMap(([_action, availableBookmarkCategories, selectedBookmarkFilterOption]: [Action, string[], LabelValue]) => {
        const bookmarkCategory: string = selectedBookmarkFilterOption && selectedBookmarkFilterOption.value;
        return iif(
          () => !availableBookmarkCategories.includes(bookmarkCategory),
          of(BookmarksActions.setSelectedBookmarkFilterOption({ selectedBookmarkFilterOption: null })),
          of(undefined),
        );
      }),
    ),
  );

  /**
   * toggleBookmark$
   * @type {Observable<Action>}
   * @memberof BookmarksEffects
   */
  public toggleBookmark$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(BookmarksActions.toggleBookmark),
      map((action: { bookmark: Bookmark; isAddBookmark: boolean }) => {
        return action.isAddBookmark
          ? BookmarksActions.addBookmark({ bookmark: action.bookmark })
          : BookmarksActions.removeBookmark({ bookmark: action.bookmark });
      }),
    ),
  );

  /**
   * Creates an instance of BookmarksEffects
   * @param {Actions} actions$ - Actions observable instance
   * @param {BookmarksService} bookmarksService - Instance of BookmarksService
   * @param {I18NService} i18nService - I18N Service instance
   * @param {Store<CoreAppState>} store - Merlot store observable instance
   * @memberof BookmarksEffects
   */
  constructor(
    private actions$: Actions,
    private bookmarksService: BookmarksService,
    private i18nService: I18NService,
    private store: Store<CoreAppState>,
  ) {}
}
