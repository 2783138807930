/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { getFailureReason, PagedRequest, WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { size } from 'lodash-es';
import { merge, Observable, of, TimeoutError } from 'rxjs';
import { catchError, debounceTime, mergeMap, switchMap, tap, timeout, withLatestFrom } from 'rxjs/operators';

import { AppConfig, I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import { CustomReportService } from '@ws1c/intelligence-core/services';
import { ReportMetaService } from '@ws1c/intelligence-core/services/report-meta.service';
import {
  AlertBannerActions,
  CoreAppState,
  IntegrationMetaSelectors,
  ReportCommonActions,
  ReportCommonSelectors,
} from '@ws1c/intelligence-core/store';
import {
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  Column,
  CustomReport,
  CustomReportPreviewSearchResponse,
  CustomReportSearchRequest,
  PreviewReportContentRequest,
  ReportType,
  ROUTE_NAMES,
} from '@ws1c/intelligence-models';

/**
 * ReportCommonEffects
 *
 * @export
 * @class ReportCommonEffects
 */
@Injectable()
export class ReportCommonEffects {
  /**
   * createReportFromWidgetDetails
   */
  public createReportFromWidgetDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReportCommonActions.createReportFromWidgetDetails),
        withLatestFrom(this.store.select(ReportCommonSelectors.getAddRoute)),
        tap(([action, addRoute]: [ReturnType<typeof ReportCommonActions.createReportFromWidgetDetails>, string]) => {
          return this.routerExtensions.navigateByUrl(`${addRoute}?returnPath=${action.returnPath}`);
        }),
      ),
    { dispatch: false },
  );

  /**
   * goToReportDetailsPage$
   * @type {Observable<Action>}
   * @memberof ReportCommonEffects
   */
  public goToReportDetailsPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReportCommonActions.goToReportDetailsPage),
        tap(({ reportId }: ReturnType<typeof ReportCommonActions.goToReportDetailsPage>) => {
          this.routerExtensions.navigate([`/${ROUTE_NAMES.REPORT.LIST}/${reportId}`]);
        }),
      ),
    { dispatch: false },
  );

  /**
   * loadPreview$
   * @type {Observable<Action>}
   * @memberof ReportMetaEffects
   */
  public loadPreview$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportCommonActions.loadPreview),
      debounceTime(350),
      withLatestFrom(
        this.store.select(IntegrationMetaSelectors.getAllActiveCrossCategoryColumns),
        this.store.select(ReportCommonSelectors.getCustomReportDetails),
        this.store.select(ReportCommonSelectors.getPreviewPageRequest),
      ),
      switchMap(([{ report, queryString }, allColumns, customReport, request]: [any, Column[], CustomReport, PagedRequest]) => {
        const reportData = report || customReport;

        if (!reportData || size(reportData.columnNames) === 0 || size(allColumns) === 0) {
          return of(
            ReportCommonActions.loadPreviewFailure({
              isTimedout: false,
            }),
          );
        }

        const pagedRequest = request || new PagedRequest();
        const fields = reportData.columnNames;
        const entitiesByIntegration = {
          [reportData.integration]: [reportData.entity],
        };
        const response =
          reportData.reportType === ReportType.SNAPSHOT
            ? this.customReportService.previewReport(
                new CustomReportSearchRequest({
                  entitiesByIntegration,
                  filter: queryString,
                  fields,
                  from: pagedRequest.from,
                  size: pagedRequest.size,
                }),
              )
            : this.reportMetaService.previewReportContentForMultiIntegrationV2(
                new PreviewReportContentRequest({
                  entitiesByIntegration,
                  filter: queryString,
                  fields,
                  offset: pagedRequest.from,
                  pageSize: pagedRequest.size,
                  ignoreCase: true,
                  ...reportData.dateRange?.getStartEndMillis(),
                }),
              );
        return response.pipe(
          timeout(AppConfig.TIMEOUT_60S),
          mergeMap((searchResponse: CustomReportPreviewSearchResponse) => [
            ReportCommonActions.loadPreviewSuccess({ searchResponse }),
            // Dismiss error banner that might be displayed due to a previous error
            AlertBannerActions.dismissAlertBanner({
              target: AlertBannerTarget.MODAL,
            }),
          ]),
          catchError((error: WebError) => {
            if (error instanceof TimeoutError) {
              return of(
                ReportCommonActions.loadPreviewFailure({
                  isTimedout: true,
                }),
              );
            }
            const reason = getFailureReason(error, '', this.i18nService.translate('REPORT_SAVE.SAVE_UPDATE_REPORT_GENERIC_ERROR'));
            return merge(
              of(
                ReportCommonActions.loadPreviewFailure({
                  isTimedout: false,
                }),
              ),
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.MODAL,
                  message: this.i18nService.translate('REPORT_CUSTOMIZE.REPORT_PREVIEW_ERROR', { reason }),
                }),
              ),
            );
          }),
        );
      }),
    ),
  );

  /**
   * Creates an instance of ReportCommonEffects.
   * @param {Actions} actions$
   * @param {RouterExtensions} routerExtensions
   * @param {ReportMetaService} reportMetaService
   * @param {CustomReportService} customReportService
   * @param {I18nService} i18nService
   * @param {Store<CoreAppState>} store
   * @memberof ReportCommonEffects
   */
  constructor(
    private actions$: Actions,
    private routerExtensions: RouterExtensions,
    private reportMetaService: ReportMetaService,
    private customReportService: CustomReportService,
    private i18nService: I18NService,
    private store: Store<CoreAppState>,
  ) {}
}
