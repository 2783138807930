/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { DashboardActions, DashboardSelectors } from '@ws1c/intelligence-core/store';
import { AccessType, Dashboard } from '@ws1c/intelligence-models';

/**
 * Dashboard Selector
 * @export
 * @class DashboardSelectorComponent
 * @implements {OnInit, OnDestroy}
 */
@Component({
  selector: 'dpa-dashboard-selector',
  templateUrl: 'dashboard-selector.component.html',
  styleUrls: ['dashboard-selector.component.scss'],
})
export class DashboardSelectorComponent implements OnInit, OnDestroy {
  @Input() public loadSelectedDashboard: boolean = false;
  @Output() public dashboardFormChange: EventEmitter<UntypedFormGroup> = new EventEmitter();
  @Output() public toggleNewDashboard: EventEmitter<boolean> = new EventEmitter();

  public dashboardList$: Observable<Dashboard[]>;
  public isDashboardSearchInProgress$: Observable<boolean>;
  public addNewSelected: boolean = false;
  public newDashboardForm: UntypedFormGroup;
  public existingDashboardForm: UntypedFormGroup;
  public sub: Subscription = new Subscription();

  /**
   * Creates an instance of DashboardSelectorComponent.
   * @param {Store} store
   * @param {UntypedFormBuilder} fb
   * @memberof DashboardSelectorComponent
   */
  constructor(
    private store: Store,
    private fb: UntypedFormBuilder,
  ) {
    this.newDashboardForm = this.fb.group({
      name: ['', Validators.required],
      description: [''],
    });
    this.existingDashboardForm = this.fb.group({
      id: ['', Validators.required],
    });
    this.dashboardList$ = this.store.select(DashboardSelectors.getDashboardSearchResults);
    this.isDashboardSearchInProgress$ = this.store.select(DashboardSelectors.isDashboardSearchInProgress);
  }

  /**
   * ngOnInit
   * @memberof DashboardSelectorComponent
   */
  public ngOnInit() {
    this.sub.add(
      this.newDashboardForm.valueChanges.subscribe(() => {
        this.toggleAddNewDashboard(true);
        this.dashboardFormChange.emit(this.newDashboardForm);
      }),
    );
    this.sub.add(
      this.existingDashboardForm.valueChanges.subscribe(() => {
        this.toggleAddNewDashboard(false);
        this.dashboardFormChange.emit(this.existingDashboardForm);
      }),
    );
  }

  /**
   * ngOnDestroy
   * @memberof DashboardSelectorComponent
   */
  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /**
   * onSearch
   * @param {string} query
   * @memberof DashboardSelectorComponent
   */
  public onSearch(query: string) {
    this.store.dispatch(
      DashboardActions.searchDashboards({
        query,
        access: AccessType.FULL,
      }),
    );
  }

  /**
   * onSelect
   * @param {Dashboard} dashboard
   * @memberof DashboardSelectorComponent
   */
  public onSelect(dashboard: Dashboard) {
    this.existingDashboardForm.patchValue({ ...dashboard });
    if (this.loadSelectedDashboard) {
      this.store.dispatch(DashboardActions.loadDashboard({ id: dashboard.id }));
    }
  }

  /**
   * dashboardById
   * @param {Dashboard} dashboard
   * @returns {string}
   * @memberof DashboardSelectorComponent
   */
  public dashboardById(dashboard: Dashboard): string {
    return dashboard.id;
  }

  /**
   * dashboardNameFormatter
   * @param {Dashboard} dashboard
   * @returns {string}
   * @memberof DashboardSelectorComponent
   */
  public dashboardNameFormatter(dashboard: Dashboard): string {
    return dashboard.name;
  }

  /**
   * toggleAddNewDashboard
   * @param {boolean} newSelected
   * @memberof DashboardSelectorComponent
   */
  public toggleAddNewDashboard(newSelected: boolean) {
    if (this.addNewSelected !== newSelected) {
      this.addNewSelected = newSelected;
      this.toggleNewDashboard.emit(newSelected);
      this.dashboardFormChange.emit(this.addNewSelected ? this.newDashboardForm : this.existingDashboardForm);
    }
  }
}
