/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { UserPreferenceSelectors } from '@ws1c/intelligence-core/store/user-preference';
import { ServiceAccount } from '@ws1c/intelligence-models';
import { ServiceAccountsState } from './service-accounts.state';

/**
 * ServiceAccountsSelectors
 * @export
 * @class ServiceAccountsSelectors
 */
export class ServiceAccountsSelectors {
  public static serviceAccountsState = (state: MerlotState) => state.serviceAccountsState;

  /**
   * getServiceAccounts
   * @static
   * @type {MemoizedSelector<MerlotState, ServiceAccount[]>}
   * @memberof ServiceAccountsSelectors
   */
  public static getServiceAccounts: MemoizedSelector<MerlotState, ServiceAccount[]> = createSelector(
    ServiceAccountsSelectors.serviceAccountsState,
    (state: ServiceAccountsState) => state.serviceAccounts,
  );

  /**
   * hasServiceAccounts
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof ServiceAccountsSelectors
   */
  public static hasServiceAccounts: MemoizedSelector<MerlotState, boolean> = createSelector(
    ServiceAccountsSelectors.serviceAccountsState,
    (state: ServiceAccountsState) => state.serviceAccounts.length > 0,
  );

  /**
   * getSelectedServiceAccount
   * @static
   * @type {MemoizedSelector<MerlotState, ServiceAccount>}
   * @memberof ServiceAccountsSelectors
   */
  public static getSelectedServiceAccount: MemoizedSelector<MerlotState, ServiceAccount> = createSelector(
    ServiceAccountsSelectors.serviceAccountsState,
    (state: ServiceAccountsState) => state.selectedServiceAccount,
  );

  /**
   * getSelectedServiceAccountWithEndpoint
   * @static
   * @type {MemoizedSelector<MerlotState, ServiceAccount>}
   * @memberof ServiceAccountsSelectors
   */
  public static getSelectedServiceAccountWithEndpoint: MemoizedSelector<MerlotState, ServiceAccount> = createSelector(
    UserPreferenceSelectors.getTokenEndPoint,
    ServiceAccountsSelectors.getSelectedServiceAccount,
    (tokenEndpoint: string, serviceAccount: ServiceAccount) => {
      return serviceAccount
        ? new ServiceAccount({
            ...serviceAccount,
            tokenEndpoint,
          })
        : null;
    },
  );

  /**
   * getShowRenegerateConfirmModal
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof ServiceAccountsSelectors
   */
  public static getShowRenegerateConfirmModal: MemoizedSelector<MerlotState, boolean> = createSelector(
    ServiceAccountsSelectors.serviceAccountsState,
    (state: ServiceAccountsState) => state.showRegenerateConfirmModal,
  );

  /**
   * getShowDeleteConfirmModal
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof ServiceAccountsSelectors
   */
  public static getShowDeleteConfirmModal: MemoizedSelector<MerlotState, boolean> = createSelector(
    ServiceAccountsSelectors.serviceAccountsState,
    (state: ServiceAccountsState) => state.showDeleteConfirmModal,
  );
}
