/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { createAction, props } from '@ngrx/store';

import { FilterRule, StandardDashboardType, TrendDateRange } from '@ws1c/intelligence-models';

/**
 * HorizonActions
 * @export
 * @class HorizonActions
 */
export class HorizonActions {
  public static readonly CATEGORY = '[DASHBOARD][HORIZON]';

  public static initHorizonDashboard = createAction(`${HorizonActions.CATEGORY} INIT_HORIZON_DASHBOARD`);

  public static convertHorizonFilterRules = createAction(
    `${HorizonActions.CATEGORY} CONVERT_HORIZON_FILTER_RULES`,
    props<{ filterRules: FilterRule[] }>(),
  );

  public static convertHorizonTitanFilterRules = createAction(
    `${HorizonActions.CATEGORY} CONVERT_HORIZON_TITAN_FILTER_RULES`,
    props<{ filterRules: FilterRule[] }>(),
  );

  public static setHorizonFilterRuleGroup = createAction(
    `${HorizonActions.CATEGORY} SET_HORIZON_FILTER_RULE_GROUP`,
    props<{ filterRules: FilterRule[] }>(),
  );

  public static setHorizonTrendDateRange = createAction(
    `${HorizonActions.CATEGORY} SET_HORIZON_TREND_DATE_RANGE`,
    props<{ dateRange: TrendDateRange }>(),
  );

  public static setHorizonTitlePrefix = createAction(
    `${HorizonActions.CATEGORY} SET_HORIZON_TITLE_PREFIX`,
    props<{ titlePrefix: string }>(),
  );

  public static setHorizonSelectedPoolName = createAction(
    `${HorizonActions.CATEGORY} SET_HORIZON_SELECTED_POOL_NAME`,
    props<{ selectedPoolName: string }>(),
  );

  public static setSelectedDeploymentTypes = createAction(
    `${HorizonActions.CATEGORY} SET_HORIZON_SELECTED_DEPLOYMENT_TYPES`,
    props<{ selectedDeploymentTypes: string[] }>(),
  );

  public static selectDeploymentTab = createAction(
    `${HorizonActions.CATEGORY} SELECT_HORIZON_DEPLOYMENT_TAB`,
    props<{ selectedDeploymentTab: string; isTitan: boolean }>(),
  );

  public static setPodIdsForActiveDeploymentTab = createAction(
    `${HorizonActions.CATEGORY} SET_POD_IDS_FOR_ACTIVE_DEPLOYMENT_TAB`,
    props<{ podIdsForActiveDeploymentTab: string[] }>(),
  );

  public static loadHorizonDashboardWithMergedRequests = createAction(
    `${HorizonActions.CATEGORY} LOAD_HORIZON_DASHBAORD_WITH_MERGED_REQUESTS`,
    props<{ dashboardTypeList: StandardDashboardType[] }>(),
  );

  public static setHorizonTitanSelectedPoolTemplateName = createAction(
    `${HorizonActions.CATEGORY} SET_HORIZON_TITAN_SELECTED_POOL_TEMPLATE_NAME`,
    props<{ selectedPoolTemplateName: string }>(),
  );

  public static loadHorizonTitanAzureUtilization = createAction(`${HorizonActions.CATEGORY} LOAD_HORIZON_TITAN_AZURE_UTILIZATION`);

  public static updateWidgetDetailDefinitionFilterByUserName = createAction(
    `${HorizonActions.CATEGORY} UPDATE_WIDGET_DETAIL_DEFINITION_FILTER_BY_USERNAME`,
    props<{ attribute: string; userName: string }>(),
  );
}
