/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input } from '@angular/core';
import { SidePanelService } from '@dpa/ui-common';

/**
 * SidePanelMainBodyCardComponent
 * @export
 * @class SidePanelMainBodyCardComponent
 */
@Component({
  selector: 'dpa-side-panel-main-body-card',
  templateUrl: 'side-panel-main-body-card.component.html',
  styleUrls: ['side-panel-main-body-card.component.scss'],
})
export class SidePanelMainBodyCardComponent {
  @Input() public title: string;
  @Input() public tooltip?: string;
  @Input() public showFooterLinkToExpandedBody?: boolean = false;

  /**
   * @param {SidePanelService} sidePanelService
   */
  constructor(private sidePanelService: SidePanelService) {}

  /**
   * toggleSidePanel
   * @param {boolean} isExpanded
   * @memberof SidePanelMainBodyCardComponent
   */
  public toggleSidePanel(isExpanded: boolean) {
    this.sidePanelService.toggleSidePanel(isExpanded);
  }
}
