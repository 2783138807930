/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { PagedRequest, SortOn, WebError } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import { CustomReportPreviewSearchResponse, DeviceUser, PreviewReportContentRequest, UserDetailPageType } from '@ws1c/intelligence-models';
import { UserProfileAdditionalParams } from './user-profile.state';

/**
 * UserProfileActions
 * @export
 * @class UserProfileActions
 */
export class UserProfileActions {
  public static readonly CATEGORY = '[USER_PROFILE]';

  private static type = (actionName: string): string => `${UserProfileActions.CATEGORY} ${actionName}`;

  // APP DETAIL
  public static loadAppDetailUserProfileDashboard = createAction(UserProfileActions.type('LOAD_APP_DETAIL_USER_PROFILE_DASHBOARD'));
  public static setAppDetailUser = createAction(UserProfileActions.type('SET_APP_DETAIL_USER'), props<{ user: string }>());
  public static setAppDetailUserProfileTab = createAction(
    UserProfileActions.type('SET_APP_DETAIL_USER_PROFILE_TAB'),
    props<{ tab: string }>(),
  );

  // USER PROFILE
  public static loadUserProfileDashboard = createAction(
    UserProfileActions.type('LOAD_USER_PROFILE_DASHBOARD'),
    props<{ user: DeviceUser }>(),
  );

  public static goToUserProfilePage = createAction(
    UserProfileActions.type('GO_TO_USER_PROFILE_PAGE'),
    props<{ user: DeviceUser; userDetailPageType?: UserDetailPageType }>(),
  );

  public static loadUserProfileExperienceDashboard = createAction(
    UserProfileActions.type('LOAD_USER_PROFILE_EXPERIENCE_DASHBOARD'),
    props<{ user: DeviceUser }>(),
  );

  public static loadUserProfileExperienceTables = createAction(
    UserProfileActions.type('LOAD_USER_PROFILE_EXPERIENCE_TABLES'),
    props<{
      additionalParams: UserProfileAdditionalParams;
      userDetailPageType?: UserDetailPageType;
    }>(),
  );

  public static loadUserProfileExperienceTablesSuccess = createAction(
    UserProfileActions.type('LOAD_USER_PROFILE_EXPERIENCE_TABLES_SUCCESS'),
    props<{
      requests: PreviewReportContentRequest[];
      responses: CustomReportPreviewSearchResponse[];
    }>(),
  );

  public static loadUserProfileExperienceTablesFailure = createAction(
    UserProfileActions.type('LOAD_USER_PROFILE_EXPERIENCE_TABLES_FAILURE'),
    props<{ error: WebError }>(),
  );

  public static loadUserProfileExperienceMobile = createAction(
    UserProfileActions.type('LOAD_USER_PROFILE_EXPERIENCE_MOBILE'),
    props<{
      additionalParams: UserProfileAdditionalParams;
      userDetailPageType?: UserDetailPageType;
    }>(),
  );

  public static loadUserProfileExperienceMobileSuccess = createAction(
    UserProfileActions.type('LOAD_USER_PROFILE_EXPERIENCE_MOBILE_SUCCESS'),
    props<{
      request: PreviewReportContentRequest;
      response: CustomReportPreviewSearchResponse;
    }>(),
  );

  public static loadUserProfileExperienceMobileFailure = createAction(
    UserProfileActions.type('LOAD_USER_PROFILE_EXPERIENCE_MOBILE_FAILURE'),
    props<{ error: WebError }>(),
  );

  public static cleanUserDeviceAndApp = createAction(UserProfileActions.type('CLEAN_USER_DEVICE_AND_APP'));

  public static setDeviceTablePagedRequest = createAction(
    UserProfileActions.type('SET_DEVICE_TABLE_PAGED_REQUEST'),
    props<{
      request: PagedRequest;
      index: number;
      userDetailPageType?: UserDetailPageType;
    }>(),
  );

  public static setDeviceTableSortOns = createAction(
    UserProfileActions.type('SET_DEVICE_TABLE_SORTONS'),
    props<{
      sortOns: SortOn[];
      index: number;
      userDetailPageType?: UserDetailPageType;
    }>(),
  );

  public static loadUserProfileExperienceApps = createAction(
    UserProfileActions.type('LOAD_USER_PROFILE_EXPERIENCE_APPS'),
    props<{
      response: CustomReportPreviewSearchResponse;
      userDetailPageType?: UserDetailPageType;
    }>(),
  );

  public static loadUserProfileExperienceAppsSuccess = createAction(
    UserProfileActions.type('LOAD_USER_PROFILE_EXPERIENCE_APPS_SUCCESS'),
    props<{
      requests: PreviewReportContentRequest[];
      responses: CustomReportPreviewSearchResponse[];
    }>(),
  );

  public static loadUserProfileExperienceAppsFailure = createAction(
    UserProfileActions.type('LOAD_USER_PROFILE_EXPERIENCE_APPS_FAILURE'),
    props<{ error: WebError }>(),
  );

  public static setAppsTablePagedRequest = createAction(
    UserProfileActions.type('SET_APPS_TABLE_PAGED_REQUEST'),
    props<{
      request: PagedRequest;
      index: number;
      userDetailPageType?: UserDetailPageType;
    }>(),
  );

  public static setAppsTableSortOns = createAction(
    UserProfileActions.type('SET_APPS_TABLE_SORTONS'),
    props<{
      sortOns: SortOn[];
      index: number;
      userDetailPageType?: UserDetailPageType;
    }>(),
  );

  public static loadScoreDashboard = createAction(
    UserProfileActions.type('LOAD_SCORE_DASHBOARD'),
    props<{ id: string; userDetailPageType: UserDetailPageType }>(),
  );

  public static getUserProfileDetailRequestFailure = createAction(
    UserProfileActions.type('GET_USER_PROFILE_DETAIL_REQEUST_FAILURE'),
    props<{ error: WebError }>(),
  );

  public static loadUserProfileSecurityDashboard = createAction(
    UserProfileActions.type('LOAD_USER_PROFILE_SECURITY_DASHBOARD'),
    props<{ user: DeviceUser }>(),
  );

  public static setUserProfileInfo = createAction(UserProfileActions.type('SET_USER_PROFILE_INFO'), props<{ user: DeviceUser }>());
}
