/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Automation, CustomReport, Device, DeviceUser } from '@ws1c/intelligence-models';
import { GlobalSearchWidget } from '@ws1c/intelligence-models/dashboard/global-search-widget.model';

/**
 * Defines UI State for Global Search
 *
 * @export
 * @interface GlobalSearchState
 */
export interface GlobalSearchState {
  osMetaData: {};
  osData: OsData[];
  widgetsData: GlobalSearchWidget[];
  devicesData: Device[];
  reportsData: CustomReport[];
  automationsData: Automation[];
  loading: boolean;
  usersData: DeviceUser[];
}

export const initialGlobalSearchState = {
  osMetaData: {},
  osData: [],
  widgetsData: [],
  devicesData: [],
  reportsData: [],
  automationsData: [],
  loading: false,
  usersData: [],
};

export interface OsData {
  name: string;
  devicesCount: number;
  versionsCount: number;
  integration: string;
}
