/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector, Selector } from '@ngrx/store';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { getDefaultedTrendDate } from '@ws1c/dashboard-common/utils';
import { RuleGroup, StandardDashboardRequest, StandardDashboardType, TrendDateRange } from '@ws1c/intelligence-models';
import { CarbonBlackDashboardState } from './carbon-black-dashboard.state';

/**
 * CarbonBlackDashboardSelectors
 * @export
 * @class CarbonBlackDashboardSelectors
 */
export class CarbonBlackDashboardSelectors {
  /**
   * selectCarbonBlackDashboardState
   * @static
   * @param {MerlotState} state
   * @type {Selector<MerlotState, CarbonBlackDashboardState>}
   * @memberof CarbonBlackDashboardSelectors
   */
  public static selectCarbonBlackDashboardState: Selector<MerlotState, CarbonBlackDashboardState> = (state: MerlotState) =>
    state.standardDashboardState.carbonBlackDashboardState;

  /**
   * getTrendDateRange
   * @static
   * @type {MemoizedSelector<MerlotState, TrendDateRange>}
   * @memberof CarbonBlackDashboardSelectors
   */
  public static getTrendDateRange: MemoizedSelector<MerlotState, TrendDateRange> = createSelector(
    CarbonBlackDashboardSelectors.selectCarbonBlackDashboardState,
    (state: CarbonBlackDashboardState) => {
      return getDefaultedTrendDate(state.trendDateRange, StandardDashboardType.CARBON_BLACK);
    },
  );

  /**
   * getDashboardRequest
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardRequest>}
   * @memberof CarbonBlackDashboardSelectors
   */
  public static getDashboardRequest: MemoizedSelector<MerlotState, StandardDashboardRequest> = createSelector(
    CarbonBlackDashboardSelectors.getTrendDateRange,
    (trendDateRange: TrendDateRange) => {
      return new StandardDashboardRequest(StandardDashboardType.CARBON_BLACK, new RuleGroup(), trendDateRange);
    },
  );
}
