/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { CustomReport, ReportTrackingSearchResponse } from '@ws1c/intelligence-models';
import { NonAdminState } from './non-admin.state';

/**
 * NonAdminSelectors
 * @exports
 * @class NonAdminSelectors
 */
export class NonAdminSelectors {
  /**
   * nonAdminState
   * @static
   * @type {MemoizedSelector<MerlotState, NonAdminState>}
   * @memberOf NonAdminSelectors
   */
  public static nonAdminState: MemoizedSelector<MerlotState, NonAdminState> = createFeatureSelector<NonAdminState>('nonAdminState');

  /**
   * getCustomReportSearchString
   * @static
   * @type {MemoizedSelector<MerlotState, ReportTrackingSearchResponse>}
   * @memberof NonAdminSelectors
   */
  public static getReportShareList: MemoizedSelector<MerlotState, ReportTrackingSearchResponse> = createSelector(
    NonAdminSelectors.nonAdminState,
    (state: NonAdminState) => state.reportShareList,
  );

  /**
   * getReportShareDetails
   * @static
   * @type {MemoizedSelector<MerlotState, CustomReport>}
   * @memberof NonAdminSelectors
   */
  public static getReportShareDetails: MemoizedSelector<MerlotState, CustomReport> = createSelector(
    NonAdminSelectors.nonAdminState,
    (state: NonAdminState) => state.reportDetails,
  );
}
