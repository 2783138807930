/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GenericObject } from '@dpa/ui-common';

import {
  AutomationActionFieldForLookup,
  AutomationActionFieldLookupRequestPayload,
  NameValue,
  SectionsMetaForm,
} from '@ws1c/intelligence-models';

/**
 * SectionsMetaFormComponent
 *
 * @export
 * @class SectionsMetaFormComponent
 */
@Component({
  selector: 'dpa-sections-meta-form',
  templateUrl: 'sections-meta-form.component.html',
  styleUrls: ['sections-meta-form.component.scss'],
})
export class SectionsMetaFormComponent implements OnInit {
  @Input() public columnLookupVariables?: any[] = [];
  @Input() public formGroup: UntypedFormGroup = new UntypedFormGroup({});
  @Input() public isEditMode?: boolean = false;
  @Input() public metaForm: SectionsMetaForm;
  @Input() public settings: object | undefined;
  @Input() public searchableActionsEnabled?: boolean = false;
  @Input() public lookupMap: Record<string, NameValue[]> = {};
  @Input() public lookupMapLoading?: boolean = false;
  @Input() public nestedLookups?: GenericObject = {};
  @Output() public formGroupChange: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
  @Output() public lookupChoiceChange = new EventEmitter<AutomationActionFieldForLookup>();
  @Output() public onRequestLookup = new EventEmitter<AutomationActionFieldLookupRequestPayload>();

  public fieldsBySection = {};
  public formGroupNames: string[] = [];

  /**
   * ngOnInit
   *
   * @memberof SectionsMetaFormComponent
   */
  public ngOnInit() {
    this.groupFieldsBySection(this.metaForm);
  }

  /**
   * getFormGroup
   *
   * @param {string} formGroupName
   * @returns {UntypedFormGroup}
   * @memberof SectionsMetaFormComponent
   */
  public getFormGroup(formGroupName: string): UntypedFormGroup {
    return this.formGroup.get(formGroupName) as UntypedFormGroup;
  }

  /**
   * groupFieldsBySection
   *
   * @param {SectionsMetaForm} metaForm
   * @memberof SectionsMetaFormComponent
   */
  public groupFieldsBySection(metaForm: SectionsMetaForm) {
    metaForm.sections.forEach((section) => {
      this.formGroupNames.push(section.type);
      this.formGroup.addControl(section.type, new UntypedFormGroup({}));
      this.fieldsBySection[section.type] = section;
    });
  }

  /**
   * onFormChange
   *
   * @param {UntypedFormGroup} formGroup
   * @param {string} sectionName
   * @memberof SectionsMetaFormComponent
   */
  public onFormChange = (formGroup: UntypedFormGroup, sectionName: string) => {
    this.formGroup.setControl(sectionName, formGroup);
    this.formGroupChange.emit(this.formGroup);
  };

  /**
   * onLookupChoiceChange - re-emit lookup choice changes
   *
   * @param {AutomationActionFieldForLookup} fieldForLookup
   * @memberof SectionsMetaFormComponent
   */
  public onLookupChoiceChange(fieldForLookup: AutomationActionFieldForLookup) {
    this.lookupChoiceChange.emit(fieldForLookup);
  }

  /**
   * onRequestFieldLookup - re-emit lookup request
   *
   * @param {AutomationActionFieldLookupRequestPayload} fieldRequest
   * @memberof SectionsMetaFormComponent
   */
  public onRequestFieldLookup(fieldRequest: AutomationActionFieldLookupRequestPayload) {
    this.onRequestLookup.emit(fieldRequest);
  }
}
