/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { Automation, CustomReport, Device, DeviceUser, GlobalSearchWidget } from '@ws1c/intelligence-models';
import { GlobalSearchState, OsData } from './global-search.state';

/**
 * GlobalSearchSelectors
 *
 * @export
 * @class GlobalSearchSelectors
 */
export class GlobalSearchSelectors {
  public static getGlobalSearchState = (state: MerlotState) => state.globalSearchState;

  /**
   * getOsSearchMetadata
   * @static
   * @type {MemoizedSelector<MerlotState, GenericObject>}
   * @memberOf GlobalSearchSelectors
   */
  public static getOsSearchMetadata: MemoizedSelector<MerlotState, GenericObject> = createSelector(
    GlobalSearchSelectors.getGlobalSearchState,
    (state: GlobalSearchState) => state.osMetaData,
  );

  /**
   * getOsData
   * @static
   * @type {MemoizedSelector<MerlotState, OsData[]>}
   * @memberOf GlobalSearchSelectors
   */
  public static getOsData: MemoizedSelector<MerlotState, OsData[]> = createSelector(
    GlobalSearchSelectors.getGlobalSearchState,
    (state: GlobalSearchState) => state.osData,
  );

  /**
   * getWidgetsData
   * @static
   * @type {MemoizedSelector<MerlotState, GlobalSearchWidget[]>}
   * @memberOf GlobalSearchSelectors
   */
  public static getWidgetsData: MemoizedSelector<MerlotState, GlobalSearchWidget[]> = createSelector(
    GlobalSearchSelectors.getGlobalSearchState,
    (state: GlobalSearchState) => state.widgetsData,
  );

  /**
   * getDevicesData
   * @static
   * @type {MemoizedSelector<MerlotState, Device[]>}
   * @memberOf GlobalSearchSelectors
   */
  public static getDevicesData: MemoizedSelector<MerlotState, Device[]> = createSelector(
    GlobalSearchSelectors.getGlobalSearchState,
    (state: GlobalSearchState) => state.devicesData,
  );

  /**
   * getUsersData
   * @static
   * @type {MemoizedSelector<MerlotState, DeviceUser[]>}
   * @memberOf GlobalSearchSelectors
   */
  public static getUsersData: MemoizedSelector<MerlotState, DeviceUser[]> = createSelector(
    GlobalSearchSelectors.getGlobalSearchState,
    (state: GlobalSearchState) => state.usersData,
  );

  /**
   * isGlobalSearchLoading
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberOf GlobalSearchSelectors
   */
  public static isGlobalSearchLoading: MemoizedSelector<MerlotState, boolean> = createSelector(
    GlobalSearchSelectors.getGlobalSearchState,
    (state: GlobalSearchState) => state.loading,
  );

  /**
   * getReportsData
   * @static
   * @type {MemoizedSelector<MerlotState, CustomReport[]>}
   * @memberOf GlobalSearchSelectors
   */
  public static getReportsData: MemoizedSelector<MerlotState, CustomReport[]> = createSelector(
    GlobalSearchSelectors.getGlobalSearchState,
    (state: GlobalSearchState) => state.reportsData,
  );

  /**
   * getAutomationsData
   * @static
   * @type {MemoizedSelector<MerlotState, Automation[]>}
   * @memberOf GlobalSearchSelectors
   */
  public static getAutomationsData: MemoizedSelector<MerlotState, Automation[]> = createSelector(
    GlobalSearchSelectors.getGlobalSearchState,
    (state: GlobalSearchState) => state.automationsData,
  );
}
