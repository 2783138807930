/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ReportTemplateSearchComponent } from './report-template-search/report-template-search.component';
import { ScriptTemplateSearchComponent } from './script-template-search/script-template-search.component';
import { SensorTemplateSearchComponent } from './sensor-template-search/sensor-template-search.component';
import { WidgetTemplateSearchComponent } from './widget-template-search/widget-template-search.component';
import { WorkflowTemplateSearchComponent } from './workflow-template-search/workflow-template-search.component';

export const TEMPLATE_SEARCH_COMPONENTS = [
  ReportTemplateSearchComponent,
  ScriptTemplateSearchComponent,
  SensorTemplateSearchComponent,
  WidgetTemplateSearchComponent,
  WorkflowTemplateSearchComponent,
];

export * from './report-template-search/report-template-search.component';
export * from './script-template-search/script-template-search.component';
export * from './sensor-template-search/sensor-template-search.component';
export * from './widget-template-search/widget-template-search.component';
export * from './workflow-template-search/workflow-template-search.component';
export * from './base-template-search.component';
