/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { BreadCrumb, GenericObject } from '@dpa/ui-common';
import { createSelector, MemoizedSelector, Selector } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { getDefaultedTrendDate, getDefaultTrendDateByDashboardType } from '@ws1c/dashboard-common/utils';
import { commonHelpers, DashboardSelectors, helpers } from '@ws1c/intelligence-core/store/dashboard/dashboard.selectors';
import { IntegrationMetaSelectors } from '@ws1c/intelligence-core/store/integration-meta';
import {
  AppPlatform,
  ChartDrilldownEvent,
  Column,
  COLUMN_NAMES,
  ComparisonQueryResponse,
  CustomReportPreviewSearchResponse,
  DashboardThresholdSummary,
  DevicesDesktopErrorDetailsPage,
  EntityThresholds,
  FilterRule,
  RawThresholdAttribute,
  RuleGroup,
  StandardDashboardRequest,
  StandardDashboardType,
  StandardWidgetSubtype,
  ThresholdsByAttribute,
  Trend,
  TrendDateRange,
} from '@ws1c/intelligence-models';
import {
  buildDrilldownMergedDashboardRequest,
  getDesktopErrorDetailsStandardDashboardRequest,
  getPerformanceThresholdsAdditionalParams,
} from './devices-dashboard-selector-helpers';
import { DevicesDashboardState } from './devices-dashboard.state';

/**
 * DevicesDashboardSelectors
 * @export
 * @class DevicesDashboardSelectors
 */
export class DevicesDashboardSelectors {
  /**
   * selectDevicesDashboardState
   * @param {MerlotState} state
   * @returns {DevicesDashboardState}
   * @memberOf DevicesDashboardSelectors
   */
  public static selectDevicesDashboardState: Selector<MerlotState, DevicesDashboardState> = (state: MerlotState) =>
    state.standardDashboardState.devicesDashboardState;

  /**
   * getDashboardType
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardType>}
   * @memberof DevicesDashboardSelectors
   */
  public static getDashboardType: MemoizedSelector<MerlotState, StandardDashboardType> = createSelector(
    DevicesDashboardSelectors.selectDevicesDashboardState,
    (state: DevicesDashboardState) => state.dashboardType,
  );

  /**
   * getAdditionalParams
   * @static
   * @type {MemoizedSelector<MerlotState, GenericObject>}
   * @memberof DevicesDashboardSelectors
   */
  public static getAdditionalParams: MemoizedSelector<MerlotState, GenericObject> = createSelector(
    DevicesDashboardSelectors.selectDevicesDashboardState,
    (state: DevicesDashboardState) => state.additionalParams,
  );

  /**
   * getFilterRuleGroup
   * @static
   * @type {MemoizedSelector<MerlotState, RuleGroup>}
   * @memberof DevicesDashboardSelectors
   */
  public static getFilterRuleGroup: MemoizedSelector<MerlotState, RuleGroup> = createSelector(
    DevicesDashboardSelectors.selectDevicesDashboardState,
    (state: DevicesDashboardState) => state.filterRuleGroup ?? new RuleGroup(),
  );

  /**
   * getDevicesDashboardColumns
   * @static
   * @type {MemoizedSelector<MerlotState, Column[]>}
   */
  public static getDevicesDashboardColumns: MemoizedSelector<MerlotState, Column[]> = createSelector(
    IntegrationMetaSelectors.getVisibleColumnsSortedByName,
    (allColumns: Column[]) =>
      commonHelpers.filterColumnsByAttributeNames(allColumns, [
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_platform,
        COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_location_group_name,
      ]),
  );

  /**
   * getTrendDateRange
   * @static
   * @type {MemoizedSelector<MerlotState, TrendDateRange>}
   * @memberof DevicesDashboardSelectors
   */
  public static getTrendDateRange: MemoizedSelector<MerlotState, TrendDateRange> = createSelector(
    DevicesDashboardSelectors.selectDevicesDashboardState,
    (state: DevicesDashboardState) => state.trendDateRange ?? getDefaultTrendDateByDashboardType(state.dashboardType),
  );

  /**
   * getTotalEnrolledDevices
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof DevicesDashboardSelectors
   */
  public static getTotalEnrolledDevices: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return helpers.getOffsetCounterData(
        trendsByWidgetSubtype,
        null,
        StandardWidgetSubtype.DEVICES_TOTAL_ENROLLED_COUNT,
        StandardWidgetSubtype.DEVICES_TOTAL_ENROLLED_COUNT_OFFSET_1_TREND,
      );
    },
  );

  /**
   * getTotalActiveDevices
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof DevicesDashboardSelectors
   */
  public static getTotalActiveDevices: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return helpers.getOffsetCounterData(
        trendsByWidgetSubtype,
        null,
        StandardWidgetSubtype.DEVICES_TOTAL_ACTIVE_COUNT,
        StandardWidgetSubtype.DEVICES_TOTAL_ACTIVE_COUNT_OFFSET_1_TREND,
      );
    },
  );

  /**
   * getTotalInactiveDevices
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof DevicesDashboardSelectors
   */
  public static getTotalInactiveDevices: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return helpers.getOffsetCounterData(trendsByWidgetSubtype, null, StandardWidgetSubtype.DEVICES_TOTAL_INACTIVE_COUNT);
    },
  );

  /**
   * getDeviceHealthResponse
   * @static
   * @param {string} id
   * @type {MemoizedSelector<MerlotState, CustomReportPreviewSearchResponse>}
   * @memberof DevicesDashboardSelectors
   */
  public static getDeviceHealthResponse = (id: string): MemoizedSelector<MerlotState, CustomReportPreviewSearchResponse> => {
    return createSelector(
      DevicesDashboardSelectors.selectDevicesDashboardState,
      (state: DevicesDashboardState) => state.performance.deviceHealthResponse?.[id] || undefined,
    );
  };

  /**
   * getDeviceAppCrash
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof DevicesDashboardSelectors
   */
  public static getDeviceAppCrash: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return helpers.getOffsetCounterData(trendsByWidgetSubtype, null, StandardWidgetSubtype.DEVICES_DETAILS_APP_CRASH_COUNT);
    },
  );

  /**
   * getDeviceOsCrash
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof DevicesDashboardSelectors
   */
  public static getDeviceOsCrash: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return helpers.getOffsetCounterData(trendsByWidgetSubtype, null, StandardWidgetSubtype.DEVICES_DETAILS_OS_CRASH_COUNT);
    },
  );

  /**
   * getTotalCrashDevices
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof DevicesDashboardSelectors
   */
  public static getTotalCrashDevices: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return helpers.getOffsetCounterData(
        trendsByWidgetSubtype,
        null,
        StandardWidgetSubtype.DEVICES_TOTAL_CRASH_COUNT,
        StandardWidgetSubtype.DEVICES_TOTAL_CRASH_COUNT_OFFSET_1,
      );
    },
  );

  /**
   * getSelectedPerformanceThresholdAttribute
   * @static
   * @type {MemoizedSelector<MerlotState, RawThresholdAttribute>}
   * @memberof DevicesDashboardSelectors
   */
  public static getSelectedPerformanceThresholdAttribute: MemoizedSelector<MerlotState, RawThresholdAttribute> = createSelector(
    DevicesDashboardSelectors.selectDevicesDashboardState,
    (state: DevicesDashboardState) => state.performance.selectedThresholdAttribute ?? RawThresholdAttribute.BOOT_TIME,
  );

  /**
   * isPerformanceModalOpen
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof DevicesDashboardSelectors
   */
  public static isPerformanceModalOpen: MemoizedSelector<MerlotState, boolean> = createSelector(
    DevicesDashboardSelectors.selectDevicesDashboardState,
    (state: DevicesDashboardState) => state.performance.isModalOpen,
  );

  /**
   * getPerformanceThresholdSummary
   * @static
   * @type {MemoizedSelector<MerlotState, DashboardThresholdSummary>}
   * @memberof DevicesDashboardSelectors
   */
  public static getPerformanceThresholdSummary: MemoizedSelector<MerlotState, DashboardThresholdSummary> = createSelector(
    DevicesDashboardSelectors.selectDevicesDashboardState,
    (state: DevicesDashboardState) => state.performance.thresholdSummary,
  );

  /**
   * getPerformanceThresholds
   * @static
   * @type {MemoizedSelector<MerlotState, ThresholdsByAttribute>}
   * @memberof DevicesDashboardSelectors
   */
  public static getPerformanceThresholds: MemoizedSelector<MerlotState, ThresholdsByAttribute> = createSelector(
    DevicesDashboardSelectors.getPerformanceThresholdSummary,
    (thresholdSummary: DashboardThresholdSummary) => thresholdSummary?.thresholds,
  );

  /**
   * getPerformanceThresholdsAdditionalParams
   * @static
   * @type {MemoizedSelector<MerlotState, GenericObject>}
   * @memberof DevicesDashboardSelectors
   */
  public static getPerformanceThresholdsAdditionalParams: MemoizedSelector<MerlotState, GenericObject> = createSelector(
    DevicesDashboardSelectors.getPerformanceThresholds,
    getPerformanceThresholdsAdditionalParams,
  );

  /**
   * getDesktopErrorDetailsTrendDateRange
   * @static
   * @type {MemoizedSelector<MerlotState, TrendDateRange>}
   * @memberof DevicesDashboardSelectors
   */
  public static getDesktopErrorDetailsTrendDateRange: MemoizedSelector<MerlotState, TrendDateRange> = createSelector(
    DevicesDashboardSelectors.selectDevicesDashboardState,
    (state: DevicesDashboardState) => getDefaultedTrendDate(state.errors.detailsTrendDateRange),
  );

  /**
   * getDesktopErrorDetailsDashboardRequest
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardRequest>}
   * @memberof DevicesDashboardSelectors
   */
  public static getDesktopErrorDetailsDashboardRequest: MemoizedSelector<MerlotState, StandardDashboardRequest> = createSelector(
    DevicesDashboardSelectors.selectDevicesDashboardState,
    DevicesDashboardSelectors.getDesktopErrorDetailsTrendDateRange,
    (state: DevicesDashboardState, trendDateRange: TrendDateRange) => {
      if (state.errors.detailsPage) {
        return getDesktopErrorDetailsStandardDashboardRequest(state.errors.detailsPage, trendDateRange);
      }
    },
  );

  /**
   * getDesktopErrorDetailsBreadCrumbs
   * @static
   * @type {MemoizedSelector<MerlotState, BreadCrumb[]>}
   * @memberof DevicesDashboardSelectors
   */
  public static getDesktopErrorDetailsBreadCrumbs: MemoizedSelector<MerlotState, BreadCrumb[]> = createSelector(
    DevicesDashboardSelectors.selectDevicesDashboardState,
    (state: DevicesDashboardState) => state.errors.detailsPage?.breadCrumbs,
  );

  /**
   * getDesktopErrorDetailsPageTitle
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof DevicesDashboardSelectors
   */
  public static getDesktopErrorDetailsPageTitle: MemoizedSelector<MerlotState, string> = createSelector(
    DevicesDashboardSelectors.selectDevicesDashboardState,
    (state: DevicesDashboardState) => {
      const { platform, module, process, error, crashPath }: DevicesDesktopErrorDetailsPage = Object(state.errors.detailsPage);
      return platform === AppPlatform.WINDOWS_DESKTOP ? `${module}-${process}-${error}` : `${process}-${crashPath}`;
    },
  );

  /**
   * getThresholdsV2
   * @static
   * @type {MemoizedSelector<MerlotState, EntityThresholds>}
   * @memberof DevicesDashboardSelectors
   */
  public static getThresholdsV2: MemoizedSelector<MerlotState, EntityThresholds> = createSelector(
    DevicesDashboardSelectors.selectDevicesDashboardState,
    (state: DevicesDashboardState) => state.thresholdsV2,
  );

  /**
   * getDashboardTypeList
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardType[]>}
   * @memberof DevicesDashboardSelectors
   */
  public static getDashboardTypeList: MemoizedSelector<MerlotState, StandardDashboardType[]> = createSelector(
    DevicesDashboardSelectors.selectDevicesDashboardState,
    (state: DevicesDashboardState) => state.dashboardTypeList,
  );

  /**
   * getNetworkSignalDashboardWithMergedRequests
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardRequest[]>}
   * @memberof DevicesDashboardSelectors
   */
  public static getNetworkSignalDashboardWithMergedRequests: MemoizedSelector<MerlotState, StandardDashboardRequest[]> = createSelector(
    DevicesDashboardSelectors.getDashboardTypeList,
    DevicesDashboardSelectors.getFilterRuleGroup,
    DevicesDashboardSelectors.getTrendDateRange,
    DashboardSelectors.getDrilldownEventsById,
    (
      dashboardTypeList: StandardDashboardType[],
      filterRuleGroup: RuleGroup,
      trendDateRange: TrendDateRange,
      drilldownEventsById: Record<string, ChartDrilldownEvent[]>,
    ) => {
      const mergeFilterRuleGroup = cloneDeep(filterRuleGroup);
      return dashboardTypeList.map((dashboardType: StandardDashboardType) => {
        // create new fitlerRuleGroup
        const drilldownEventsByIdList = [
          StandardWidgetSubtype.__DEVICES_NETWORK_DESKTOP_WIFI_SIGNAL,
          StandardWidgetSubtype.__DEVICES_NETWORK_DESKTOP_DOWNLOAD_SIGNAL,
          StandardWidgetSubtype.__DEVICES_NETWORK_DESKTOP_UPLOAD_SIGNAL,
        ];
        drilldownEventsByIdList.forEach((id: string) => {
          if (!drilldownEventsById[id]?.length) {
            return;
          }
          const drilldown = drilldownEventsById[id].slice(-1)[0].selectedBuckets[0];
          const drilldownFilterRule = new FilterRule({
            attribute: drilldown.bucketName,
            condition: FilterRule.FILTER_CONDITION.equals,
            data: [drilldown.selectedValue],
            dataType: drilldown.bucketDataType,
          });
          const found = filterRuleGroup.rules.find(
            (rule: FilterRule) => rule.attribute === drilldown.bucketName && rule.data.includes(drilldown.selectedValue),
          );
          if (!found) {
            mergeFilterRuleGroup.rules.push(drilldownFilterRule);
          }
        });
        return buildDrilldownMergedDashboardRequest(dashboardType, mergeFilterRuleGroup, trendDateRange);
      });
    },
  );
}
