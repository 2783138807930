/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { CarbonBlackDashboardActions } from './carbon-black-dashboard.actions';
import { CarbonBlackDashboardState, initialCarbonBlackDashboardState } from './carbon-black-dashboard.state';

const _reducer: ActionReducer<CarbonBlackDashboardState> = createReducer(
  initialCarbonBlackDashboardState,
  on(
    CarbonBlackDashboardActions.setTrendDateRange,
    (
      state: CarbonBlackDashboardState,
      { trendDateRange }: ReturnType<typeof CarbonBlackDashboardActions.setTrendDateRange>,
    ): CarbonBlackDashboardState => ({
      ...state,
      trendDateRange,
    }),
  ),
);

/**
 * carbonBlackDashboardState
 * @export
 * @param {CarbonBlackDashboardState} state
 * @param {Action} action
 * @returns {CarbonBlackDashboardState}
 */
export function carbonBlackDashboardState(state: CarbonBlackDashboardState, action: Action): CarbonBlackDashboardState {
  return _reducer(state, action);
}
