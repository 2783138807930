<div class="card mt-0">
  <div class="card-header usage-header pb-0">
    <h3 class="card-title">
      {{ heading }}
    </h3>
  </div>
  <div class="card-block usage-row p-x0">
    <ng-container *ngIf="!isLoading; else loading">
      <ng-container *ngIf="used !== undefined && total !== undefined; else noResult">
        <div class="usage-item-1">
          <dpa-standard-chart
            [chartData]="trend"
            [showLabels]="false"
            [stackedTooltip]="donutTooltip"
            [activeChartType]="CHART_TYPE.DONUT"
            [customColors]="usageColors"
            [totalHeaderValue]="used"
            [totalHeaderKey]="'COMMON_MESSAGES.USED' | translate"
            [totalHeaderClass]="'overlay-total-big'"
            class="usage-chart"
          ></dpa-standard-chart>
        </div>
        <div class="usage-item-2">
          <div class="usage-item-comparision">{{ used | number }} / {{ total | number }}</div>
          <div class="usage-item-remaining">
            <ng-container *ngIf="total - used >= 0; else overUsage">
              {{ 'COMMON_MESSAGES.N_REMAINING' | translate: { n: total - used } }}
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div
    *ngIf="showFooter && footerText"
    class="card-footer text-center pt-0"
  >
    {{ footerText }}
    <dpa-tooltip
      *ngIf="footerTooltipText"
      [tooltipText]="footerTooltipText"
      role="tooltip"
    >
      <cds-icon
        shape="info-standard"
        size="20"
        class="title-tip"
      ></cds-icon>
    </dpa-tooltip>
  </div>
</div>
<ng-template #loading>
  <dpa-timeout-loader
    [skeletonClassName]="SkeletonClassName.RESULTS_DATA"
    class="timeout-loader"
  ></dpa-timeout-loader>
</ng-template>
<ng-template #noResult>
  <div class="pt-x6 pb-x6">
    <dpa-no-search-results [message]="'COMMON_MESSAGES.NO_DATA_AVAILABLE' | translate"></dpa-no-search-results>
  </div>
</ng-template>
<ng-template
  #donutTooltip
  let-ngxModel="ngxModel"
  let-tooltip="tooltip"
>
  <div class="tool-tip-container">
    <div
      *ngFor="let filter of tooltip.filters"
      class="key-value-pair"
    >
      <span class="key-label">{{ filter.value }}:</span>
      <span class="value-label">
        {{ ngxModel.value }}
      </span>
    </div>
  </div>
</ng-template>
<ng-template #overUsage>
  {{ overUsageText }}
</ng-template>
