/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input, TemplateRef } from '@angular/core';
import { ModalSize } from '@dpa/ui-common/model';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { OrgActions, OrgSelectors } from '@ws1c/intelligence-core/store/org';
import { AlertBannerTarget, OptInFeatureFlag } from '@ws1c/intelligence-models';

/**
 * Component to display feature opt-in/opt-out confirmation
 * @export
 * @class ConfirmFeatureOptinOptoutComponent
 */
@Component({
  selector: 'dpa-confirm-feature-optin-optout',
  templateUrl: 'confirm-feature-optin-optout.component.html',
  styleUrls: ['confirm-feature-optin-optout.component.scss'],
})
export class ConfirmFeatureOptinOptoutComponent {
  @Input() public sendEmailApplicable?: boolean = false;
  @Input() public applicableAdminLabel?: string = '';
  @Input() public supportLink?: string = '';
  @Input() public additionalContentTemplate?: TemplateRef<any>;

  public sendEmail: boolean = false;
  public showConfirmEnableModal$: Observable<boolean>;
  public feature$: Observable<OptInFeatureFlag>;
  public loading$: Observable<boolean>;

  public readonly AlertBannerTarget = AlertBannerTarget;
  public readonly ModalSize = ModalSize;

  /**
   * Creates an instance of ConfirmFeatureOptinOptoutComponent.
   * @param {Store<CoreAppState>} store
   * @memberof ConfirmFeatureOptinOptoutComponent
   */
  constructor(private store: Store<CoreAppState>) {
    this.showConfirmEnableModal$ = this.store.select(OrgSelectors.showConfirmEnableFeatureModal);
    this.feature$ = this.store.select(OrgSelectors.getCurrentOptingFeature);
    this.loading$ = this.store.select(OrgSelectors.isCurrentFeatureOperationInProgress);
  }

  /**
   * enableFeature
   * @memberof ConfirmFeatureOptinOptoutComponent
   */
  public enableFeature() {
    this.store.dispatch(
      OrgActions.enableFeature({
        sendEmail: this.sendEmail,
      }),
    );
  }

  /**
   * onEnableFeatureCancelClick
   * @memberof ConfirmFeatureOptinOptoutComponent
   */
  public onEnableFeatureCancelClick() {
    this.store.dispatch(OrgActions.closeConfirmEnableFeatureModal());
  }
}
