<div class="chart-container">
  <div
    *ngIf="showTableFilter"
    class="query-input-container"
  >
    <dpa-local-search (onSearch)="onQueryChange($event)"></dpa-local-search>
  </div>
  <dpa-local-data-grid
    [availableColumns]="availableColumns"
    [responseData]="tableData"
    [localDataGridSettings]="localDataGridSettings$ | async"
    [formattersByColumnName]="formattersByColumnName"
    [filterSearchKeys]="filterSearchKeys"
    [loading]="false"
    [cellTemplatesByColumnValue]="cellTemplatesByColumnValue"
    [showDetailView]="showDetailView"
    [detailHeaderTemplate]="detailHeaderTemplate"
    [detailBodyTemplate]="detailBodyTemplate"
    [expandedRowTemplate]="expandedRowTemplate"
    [selectable]="selectable"
    [enablePagination]="enablePagination"
    [columnWidthByKey]="columnWidthByKey"
    (localDataGridSettingsChange)="onLocalDataGridSettingsChange($event)"
    (selectionChange)="onSelectionChange($event)"
  ></dpa-local-data-grid>
</div>

<ng-template
  #counterTemplate
  let-dataItem="dataItem"
>
  <div>
    <a
      *ngIf="isCountersClickable; else nonClickableCounter"
      (click)="viewDetails(dataItem)"
      (keyup.enter)="viewDetails(dataItem)"
      tabindex="0"
      class="clickable cell-value link-normal"
    >
      <ng-container
        [ngTemplateOutlet]="decoratedValueTemplate"
        [ngTemplateOutletContext]="{ dataItem: dataItem }"
      ></ng-container>
    </a>
    <ng-template #nonClickableCounter>
      <ng-container
        [ngTemplateOutlet]="decoratedValueTemplate"
        [ngTemplateOutletContext]="{ dataItem: dataItem }"
      ></ng-container>
    </ng-template>
  </div>
</ng-template>

<ng-template
  #decoratedValueTemplate
  let-dataItem="dataItem"
>
  <dpa-decorated-value
    [value]="dataItem[COUNTER_KEY]"
    [valueDecorator]="ngxChart?.indices?.dataDecoratorsByCounterKey[COUNTER_KEY]"
  ></dpa-decorated-value>
</ng-template>
