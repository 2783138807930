<div class="lookup-variable-selector-container">
  <div
    #menuOpenerContainer
    (mousedown)="preventDefault($event)"
    class="menu-opener-container"
  >
    <cds-icon
      *ngIf="hideLabel; else buttonTpl"
      (click)="openMenuHandler()"
      (keyup.enter)="openMenuHandler()"
      class="menu-opener"
      shape="add-text"
      role="button"
      tabindex="0"
    ></cds-icon>
    <ng-template #buttonTpl>
      <button
        (click)="openMenuHandler()"
        class="menu-opener-button"
      >
        <cds-icon
          class="menu-opener"
          shape="add-text"
        ></cds-icon>
        {{ 'COMMON_MESSAGES.DYNAMIC_VALUES' | translate }}
      </button>
    </ng-template>
  </div>
  <div
    #keySelectorWrapper
    class="key-selector-offset"
  >
    <dpa-key-selector
      #keySelector
      [show]="menuOpen"
      [showSearchTextBox]="true"
      [isCrossCategory]="true"
      (onDone)="onDoneHandler($event)"
    ></dpa-key-selector>
  </div>
</div>
