<input
  #passwordField
  [formControl]="control"
  [attr.disabled]="isDisabled ? true : null"
  (blur)="passwordLockAfterBlur()"
  class="clr-input"
  required
  type="password"
/>
<cds-icon
  *ngIf="isDisabled && editable"
  (click)="editLockedPassword($event)"
  (keyup.enter)="editLockedPassword($event)"
  (keyup.space)="editLockedPassword($event)"
  shape="pencil"
  size="20"
  role="button"
  tabindex="0"
></cds-icon>
<cds-icon
  *ngIf="!isDisabled && editable"
  (click)="onUndoMouseDown()"
  (keyup.enter)="onUndoMouseDown()"
  (keyup.space)="onUndoMouseDown()"
  shape="undo"
  size="20"
  role="button"
  tabindex="0"
></cds-icon>
