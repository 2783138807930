/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { debounce } from 'lodash-es';
import { Observable } from 'rxjs';

import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { UserAdminAccountActions, UserAdminAccountSelector } from '@ws1c/intelligence-core/store/user-admin-account';
import { CLARITY_TOOLTIP_POSITION, UserAdminAccount, UserAdminAccountSearchRequest } from '@ws1c/intelligence-models';

/**
 * AdminUserSelectorComponent
 *
 * @export
 * @implements { OnInit }
 * @implements { OnChanges }
 * @class AdminUserSelectorComponent
 */
@Component({
  selector: 'dpa-admin-user-selector',
  templateUrl: 'admin-user-selector.component.html',
  styleUrls: ['admin-user-selector.component.scss'],
})
export class AdminUserSelectorComponent implements OnInit, OnChanges {
  public static readonly DEBOUNCE_TIME: number = 300;

  @Input() public selectedUsers?: UserAdminAccount[] = [];
  @Input() public policyTemplate?: TemplateRef<any>;
  @Input() public customSelectedRowTemplate?: TemplateRef<any>;
  @Input() public addAccountDetails?: boolean = true;
  @Input() public isSelectedUserLoading?: boolean = false;
  @Input() public ownerAccountId?: string;
  @Input() public multiUserSelect?: boolean = true;
  @Input() public hideLabels?: boolean = true;
  @Input() public disabled?: boolean = false;
  @Input() public allowCustomItems?: boolean = false;
  @Input() public isDisabled: (item: UserAdminAccount) => boolean;
  @Input() public getDisabledTooltipText: (item: any) => string = (_item: any) => '';

  /* eslint-disable @typescript-eslint/member-ordering */
  @Output() public selectedUsersChange: EventEmitter<UserAdminAccount[]> = new EventEmitter<UserAdminAccount[]>();
  @Output() public selectedUserChange: EventEmitter<UserAdminAccount> = new EventEmitter<UserAdminAccount>();

  public adminUsers$: Observable<UserAdminAccount[]>;
  public isLoading$: Observable<boolean>;
  public debouncedSearchAdminUsers: (query: string) => void;
  public readonly CLARITY_TOOLTIP_POSITION = CLARITY_TOOLTIP_POSITION;

  /**
   * Creates an instance of AdminUserSelectorComponent.
   * @param {Store<CoreAppState>} store
   * @memberof AdminUserSelectorComponent
   */
  constructor(private store: Store<CoreAppState>) {
    this.adminUsers$ = this.store.select(UserAdminAccountSelector.getUserSearchResponse);
    this.isLoading$ = this.store.select(UserAdminAccountSelector.isUserSearchLoading);
    this.debouncedSearchAdminUsers = debounce((query: string) => {
      this.getAdminUsers(query);
    }, AdminUserSelectorComponent.DEBOUNCE_TIME);
  }

  /**
   * ngOnChanges
   *
   * @param {SimpleChanges} changes
   * @memberof AdminUserSelectorComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.ownerAccountId) {
      this.store.dispatch(
        UserAdminAccountActions.setOwnerAccountId({
          accountId: changes.ownerAccountId.currentValue,
        }),
      );
    }
  }

  /**
   * ngOnInit
   * @memberof AdminUserSelectorComponent
   */
  public ngOnInit() {
    if (!this.isDisabled) {
      this.isDisabled = (item: UserAdminAccount) => !item;
    }
  }

  /**
   * getAdminUsers
   * @param {string} searchTerm
   * @memberof AdminUserSelectorComponent
   */
  public getAdminUsers(searchTerm: string) {
    this.store.dispatch(
      UserAdminAccountActions.searchUserAdminAccounts({
        userAdminAccountSearchRequest: new UserAdminAccountSearchRequest({
          searchTerm,
        }),
        addAccountDetails: this.addAccountDetails,
      }),
    );
  }

  /**
   * onQueryChange
   * @param {string} query
   * @memberof AdminUserSelectorComponent
   */
  public onQueryChange(query: string) {
    this.debouncedSearchAdminUsers(query);
  }

  /**
   * searchFormatter
   * @param {UserAdminAccount} user
   * @returns {string}
   * @memberof AdminUserSelectorComponent
   */
  public searchFormatter(user: UserAdminAccount): string {
    return `${user.email} (${user.firstName} ${user.lastName})`;
  }

  /**
   * labelFormatter
   * @param {UserAdminAccount} user
   * @returns {string}
   * @memberof AdminUserSelectorComponent
   */
  public labelFormatter(user: UserAdminAccount): string {
    return user.userInfo;
  }

  /**
   * keyBy
   * @param {UserAdminAccount} user
   * @returns {string}
   * @memberof AdminUserSelectorComponent
   */
  public keyBy(user: UserAdminAccount): string {
    return user.id;
  }

  /**
   * removeAllUsers
   * @memberof AdminUserSelectorComponent
   */
  public removeAllUsers() {
    this.selectedUsersChange.emit([]);
  }

  /**
   * removeUser
   * @param {UserAdminAccount} user
   * @memberof AdminUserSelectorComponent
   */
  public removeUser(user: UserAdminAccount) {
    this.selectedUsersChange.emit(
      this.selectedUsers.filter((selectedUser: UserAdminAccount) => {
        return selectedUser.id !== user.id;
      }),
    );
  }

  /**
   * wrapEmail
   * @param {string} email
   * @memberof AdminUserSelectorComponent
   * @returns {UserAdminAccount}
   */
  public wrapEmail(email: string): UserAdminAccount {
    return new UserAdminAccount({
      id: email,
      email,
    });
  }
}
