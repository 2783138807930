/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector, Selector } from '@ngrx/store';
import { get } from 'lodash-es';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { OrgSelectors } from '@ws1c/intelligence-core/store/org';
import { FeatureSelectors } from '@ws1c/intelligence-core/store/rbac';
import { UserPreferenceFeatureControlsSelectors, UserPreferenceSelectors } from '@ws1c/intelligence-core/store/user-preference';
import {
  GenericSearchRequest,
  Integration,
  IntegrationActionsSearchResponse,
  IntegrationDetails,
  IntegrationDetailView,
  IntegrationHealthReport,
  IntegrationObjectsCounts,
  IntegrationObjectsSearchResponse,
  IntegrationObjectsType,
  IntegrationRegistrationSource,
  IntegrationReprovisionStatus,
  IntegrationReprovisionStatusType,
  IntegrationResultCode,
  LOAD_STATE,
  LoadStateByKey,
} from '@ws1c/intelligence-models';
import { getIntegrationTenants, isSidePanelAllowedForIntegration } from './integration-selector-helpers';
import { IntegrationState } from './integration.state';

/**
 * IntegrationSelectors
 * @exports
 * @class IntegrationSelectors
 */
export class IntegrationSelectors {
  /**
   * selectIntegrationState
   * @static
   * @type {(state: MerlotState) => IntegrationState}
   * @memberof IntegrationSelectors
   */
  public static selectIntegrationState: Selector<MerlotState, IntegrationState> = (state: MerlotState): IntegrationState => {
    return state.integrationState;
  };

  /**
   * integrationsOptInStatusSelector
   * @static
   * @type {MemoizedSelector<MerlotState, LoadStateByKey>}
   * @memberof IntegrationSelectors
   */
  public static integrationsOptInStatusSelector: MemoizedSelector<MerlotState, LoadStateByKey> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.integrationsOptInStatus,
  );

  /**
   * areIntegrationsOptingInSelector
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static areIntegrationsOptingInSelector: MemoizedSelector<MerlotState, boolean> = createSelector(
    OrgSelectors.isUpdatingOptInPreference,
    IntegrationSelectors.integrationsOptInStatusSelector,
    (isUpdatingOptInPreference: boolean, integrationsOptInStatus: LoadStateByKey) => {
      const isIntegrationOptInPending: boolean = Object.values(integrationsOptInStatus ?? {}).includes(LOAD_STATE.IN_FLIGHT);
      return isUpdatingOptInPreference || isIntegrationOptInPending;
    },
  );

  /**
   * areIntegrationsOptInCompleteSelector
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static areIntegrationsOptInCompleteSelector: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationSelectors.integrationsOptInStatusSelector,
    (integrationsOptInStatus: LoadStateByKey) => {
      return Object.values(integrationsOptInStatus ?? {}).every((integrationLoadState: LOAD_STATE) => {
        return [LOAD_STATE.SUCCESS, LOAD_STATE.FAILURE].includes(integrationLoadState);
      });
    },
  );

  /**
   * integrationListByOptInStatusSelector
   * @static
   * @param {LOAD_STATE} loadState
   * @type {MemoizedSelector<MerlotState, IntegrationDetails[]>}
   * @memberof IntegrationSelectors
   */
  public static integrationListByOptInStatusSelector = (loadState: LOAD_STATE): MemoizedSelector<MerlotState, IntegrationDetails[]> =>
    createSelector(
      UserPreferenceSelectors.integrationsToOptInList,
      IntegrationSelectors.integrationsOptInStatusSelector,
      (integrationsToOptInList: IntegrationDetails[], integrationsOptInStatus: LoadStateByKey) => {
        return integrationsToOptInList?.filter((integrationDetails: IntegrationDetails) => {
          return integrationsOptInStatus[integrationDetails.id] === loadState;
        });
      },
    );

  /**
   * getAuthorizedState
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static getAuthorizedState: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.authorized,
  );

  /**
   * getLoadingState
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static getLoadingState: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.loading,
  );

  /**
   * getShowDeAuthConfirmModal
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static getShowDeAuthConfirmModal: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.showDeauthConfirmModal,
  );

  /**
   * getShowAuthConfirmModal
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static getShowAuthConfirmModal: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.showAuthConfirmModal,
  );

  /**
   * getShowItsmSetupModal
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static getShowItsmSetupModal: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.showItsmSetupModal,
  );

  /**
   * getShowItsmAuthConfirmModal
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static getShowItsmAuthConfirmModal: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.showItsmAuthConfirmModal,
  );

  /**
   * getShowItsmDeauthConfirmModal
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static getShowItsmDeauthConfirmModal: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.showItsmDeauthConfirmModal,
  );

  /**
   * getShowUagSetupModal
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static getShowUagSetupModal: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.showUagSetupModal,
  );

  /**
   * getShowUagDeauthConfirmModal
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static getShowUagDeauthConfirmModal: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.showUagDeauthConfirmModal,
  );

  /**
   * getShowUagAuthConfirmModal
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static getShowUagAuthConfirmModal: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.showUagAuthConfirmModal,
  );

  /**
   * getFinishAuthConfirmModalSuccess
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static getFinishAuthConfirmModalSuccess: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.finishAuthConfirmModalSuccess,
  );

  /**
   * getFinishAuthConfirmModalFailure
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static getFinishAuthConfirmModalFailure: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.finishAuthConfirmModalFailure,
  );

  /**
   * getIntegrationResultCode
   * @static
   * @type {MemoizedSelector<MerlotState, IntegrationResultCode>}
   * @memberof IntegrationSelectors
   */
  public static getIntegrationResultCode: MemoizedSelector<MerlotState, IntegrationResultCode> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.integrationResultCode,
  );

  /**
   * getIntegrationErrorMessage
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof IntegrationSelectors
   */
  public static getIntegrationErrorMessage: MemoizedSelector<MerlotState, string> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.errorMessage,
  );

  /**
   * getShowSetupModal
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static getShowSetupModal: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.showSetupModal,
  );

  /**
   * getShowAzureSetupModal
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static getShowAzureSetupModal: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.showAzureSetupModal,
  );

  /**
   * getDeleteAzureConnectionModal
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static getDeleteAzureConnectionModal: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.showDeleteAzureConnectionModal,
  );

  /**
   * getShowReauthorizeAzureConnectionModal
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static getShowReauthorizeAzureConnectionModal: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.showReauthorizeAzureConnectionModal,
  );

  /**
   * activeIntegrationDetails
   * @static
   * @type {MemoizedSelector<MerlotState, IntegrationDetails>}
   * @memberof IntegrationSelectors
   */
  public static activeIntegrationDetails: MemoizedSelector<MerlotState, IntegrationDetails> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.activeIntegrationDetails,
  );

  /**
   * accessIntegrationReprovisioningStatusList
   * @static
   * @type {MemoizedSelector<MerlotState, IntegrationReprovisionStatus[]>}
   * @memberof IntegrationSelectors
   */
  public static accessIntegrationReprovisioningStatusList: MemoizedSelector<MerlotState, IntegrationReprovisionStatus[]> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.accessIntegrationReprovisioningStatusList,
  );

  /**
   * integrationReprovisioningIdsSet
   * @static
   * @type {MemoizedSelector<MerlotState, Set<string>>}
   * @memberof IntegrationSelectors
   */
  public static integrationReprovisioningIdsSet: MemoizedSelector<MerlotState, Set<string>> = createSelector(
    UserPreferenceFeatureControlsSelectors.isWorkspaceOneCloudEnabled,
    UserPreferenceFeatureControlsSelectors.isRenameAccessTenantEnabled,
    IntegrationSelectors.accessIntegrationReprovisioningStatusList,
    (
      isWorkspaceOneCloudEnabled: boolean,
      isRenameAccessTenantEnabled: boolean,
      accessIntegrationReprovisioningStatusList: IntegrationReprovisionStatus[],
    ) => {
      if (!(isWorkspaceOneCloudEnabled && isRenameAccessTenantEnabled)) {
        return new Set();
      }
      return new Set(
        accessIntegrationReprovisioningStatusList
          ?.filter((integrationReprovisioningStatus: IntegrationReprovisionStatus) => {
            return [IntegrationReprovisionStatusType.IN_PROGRESS, IntegrationReprovisionStatusType.QUEUED].includes(
              integrationReprovisioningStatus.status,
            );
          })
          .map((integrationReprovisioningStatus: IntegrationReprovisionStatus) => integrationReprovisioningStatus.integrationId),
      );
    },
  );

  /**
   * currentIntegration
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof IntegrationSelectors
   */
  public static currentIntegration: MemoizedSelector<MerlotState, string> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.current,
  );

  /**
   * isPreviewIntegrationSlideOverOpen
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static isPreviewIntegrationSlideOverOpen: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.isPreviewIntegrationSlideOverOpen,
  );

  /**
   * isSlideOverOpenForIntegration
   * @static
   * @param {string} integration
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static isSlideOverOpenForIntegration = (integration: string): MemoizedSelector<MerlotState, boolean> =>
    createSelector(
      IntegrationSelectors.currentIntegration,
      IntegrationSelectors.isPreviewIntegrationSlideOverOpen,
      (currentIntegration: Integration, isPreviewIntegrationSlideOverOpen: boolean) => {
        return isPreviewIntegrationSlideOverOpen && currentIntegration === integration;
      },
    );

  /**
   * currentIntegrationTenantsSelector
   * @static
   * @type {MemoizedSelector<MerlotState, IntegrationDetails[]>}
   * @memberof IntegrationSelectors
   */
  public static currentIntegrationTenantsSelector: MemoizedSelector<MerlotState, IntegrationDetails[]> = createSelector(
    UserPreferenceSelectors.availableGemProvisionedIntegrationsForOptIn,
    UserPreferenceSelectors.availableAdminProvisionedIntegrations,
    IntegrationSelectors.currentIntegration,
    getIntegrationTenants,
  );

  /**
   * integrationTenantsByIntegrationSelector
   * @static
   * @param {string} integration
   * @type {MemoizedSelector<MerlotState, IntegrationDetails[]>}
   * @memberof IntegrationSelectors
   */
  public static integrationTenantsByIntegrationSelector = (integration: string): MemoizedSelector<MerlotState, IntegrationDetails[]> =>
    createSelector(
      UserPreferenceSelectors.availableGemProvisionedIntegrationsForOptIn,
      UserPreferenceSelectors.availableAdminProvisionedIntegrations,
      (availableGemIntegrationDetailsList: IntegrationDetails[], availableAdminIntegrationList: IntegrationDetails[]) =>
        getIntegrationTenants(availableGemIntegrationDetailsList, availableAdminIntegrationList, integration),
    );

  /**
   * currentGemProvisionedIntegrationsSelector
   * @static
   * @type {MemoizedSelector<MerlotState, IntegrationDetails[]>}
   * @memberof IntegrationSelectors
   */
  public static currentGemProvisionedIntegrationsSelector: MemoizedSelector<MerlotState, IntegrationDetails[]> = createSelector(
    UserPreferenceSelectors.isIntegrationAutoOptinForCSPEnabled,
    IntegrationSelectors.currentIntegration,
    UserPreferenceSelectors.availableGemProvisionedIntegrationsForOptIn,
    (isIntegrationAutoOptinEnabled: boolean, currentIntegration: string, integrationDetailsList: IntegrationDetails[]) => {
      if (!isIntegrationAutoOptinEnabled || !integrationDetailsList) {
        return [];
      }
      return integrationDetailsList.filter((integrationDetails: IntegrationDetails) => {
        return integrationDetails.integration === currentIntegration;
      });
    },
  );

  /**
   * isSidePanelAllowedForCurrentIntegrationSelector
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static isSidePanelAllowedForCurrentIntegrationSelector: MemoizedSelector<MerlotState, boolean> = createSelector(
    UserPreferenceFeatureControlsSelectors.isMultiSubscriptionsEnabled,
    UserPreferenceSelectors.availableGemProvisionedIntegrationsForOptIn,
    IntegrationSelectors.currentIntegration,
    isSidePanelAllowedForIntegration,
  );

  /**
   * integrationDetailsToBeDeauthorizedSelector
   * @static
   * @type {MemoizedSelector<MerlotState, IntegrationDetails>}
   * @memberof IntegrationSelectors
   */
  public static integrationDetailsToBeDeauthorizedSelector: MemoizedSelector<MerlotState, IntegrationDetails> = createSelector(
    IntegrationSelectors.activeIntegrationDetails,
    UserPreferenceSelectors.availableIntegrations,
    (activeIntegrationDetails: IntegrationDetails, availableIntegrations: IntegrationDetails[]) => {
      return (availableIntegrations ?? []).find((integrationDetails: IntegrationDetails) => {
        return integrationDetails.id === activeIntegrationDetails?.id;
      });
    },
  );

  /**
   * isIntegrationDeauthorizeAllowedSelector
   * @static
   * @param {string} integration
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static isIntegrationDeauthorizeAllowedSelector = (integration: string): MemoizedSelector<MerlotState, boolean> =>
    createSelector(
      UserPreferenceSelectors.isIntegrationAuthorized(integration),
      UserPreferenceSelectors.isCspLoggedInUser,
      (isIntegrationAuthorized: boolean, isCspLoggedInUser: boolean) => {
        // Don't allow deauthorize on UEM integration card if Intelligence launched from UEM
        if (integration?.toUpperCase() === Integration.AIRWATCH.toUpperCase() && !isCspLoggedInUser) {
          return false;
        }
        return isIntegrationAuthorized;
      },
    );

  /**
   * isOptInIntegrationNotConnectedSelector
   * @static
   * @param {string} integration
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static isOptInIntegrationNotConnectedSelector = (integration: string): MemoizedSelector<MerlotState, boolean> =>
    createSelector(
      UserPreferenceSelectors.isIntegrationAutoOptinForCSPEnabled,
      UserPreferenceSelectors.nonOptedGemProvisionedIntegrations,
      (isIntegrationAutoOptionEnabled: boolean, integrationDetailsList: IntegrationDetails[]) => {
        if (!isIntegrationAutoOptionEnabled) {
          return false;
        }
        return (integrationDetailsList ?? []).some((integrationDetails: IntegrationDetails) => {
          return (
            get(integrationDetails, 'integration', '') === integration &&
            !!get(integrationDetails, 'tenantHost') &&
            !get(integrationDetails, 'active', true)
          );
        });
      },
    );

  /**
   * isSidePanelAllowedForIntegrationSelector
   * @static
   * @param {string} integration
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static isSidePanelAllowedForIntegrationSelector = (integration: string): MemoizedSelector<MerlotState, boolean> =>
    createSelector(
      UserPreferenceFeatureControlsSelectors.isMultiSubscriptionsEnabled,
      UserPreferenceSelectors.availableGemProvisionedIntegrationsForOptIn,
      (isMultiSubscriptionsEnabled: boolean, availableGemProvisionedIntegrationsForOptIn: IntegrationDetails[]): boolean =>
        isSidePanelAllowedForIntegration(isMultiSubscriptionsEnabled, availableGemProvisionedIntegrationsForOptIn, integration),
    );

  /**
   * getIntegrationDetailViewModel
   * @static
   * @type {MemoizedSelector<MerlotState, IntegrationDetailView>}
   * @memberof IntegrationSelectors
   */
  public static getIntegrationDetailViewModel: MemoizedSelector<MerlotState, IntegrationDetailView> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => state.integrationDetailViewModel,
  );

  /**
   * getSelectedIntegrationHealthReport
   * @static
   * @type {MemoizedSelector<MerlotState, IntegrationHealthReport>}
   * @memberof IntegrationSelectors
   */
  public static getSelectedIntegrationHealthReport: MemoizedSelector<MerlotState, IntegrationHealthReport> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    IntegrationSelectors.getIntegrationDetailViewModel,
    (state: IntegrationState, integrationDetailView: IntegrationDetailView) => {
      return state.integrationHealthReport[integrationDetailView?.integration];
    },
  );

  /**
   * isUrlConfigurationTabAllowedSelector
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static isUrlConfigurationTabAllowedSelector: MemoizedSelector<MerlotState, boolean> = createSelector(
    UserPreferenceFeatureControlsSelectors.isRenameAccessTenantEnabled,
    UserPreferenceSelectors.availableIntegrations,
    IntegrationSelectors.getIntegrationDetailViewModel,
    (isRenameAccessTenantEnabled: boolean, integrationDetailsList: IntegrationDetails[], integrationDetailView: IntegrationDetailView) => {
      if (!isRenameAccessTenantEnabled) {
        return false;
      }
      const integrationDetails: IntegrationDetails = integrationDetailsList?.find((currentIntegrationDetails: IntegrationDetails) => {
        return integrationDetailView.id === currentIntegrationDetails.id;
      });
      return (
        integrationDetails?.integration === Integration.IDM && integrationDetails?.registrationSource === IntegrationRegistrationSource.GEM
      );
    },
  );

  /**
   * showAzureAdSetup
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static showAzureAdSetup: MemoizedSelector<MerlotState, boolean> = createSelector(
    UserPreferenceFeatureControlsSelectors.isRbacEnabled,
    UserPreferenceFeatureControlsSelectors.isAzureAdSetupEnabled,
    FeatureSelectors.hasIamIntegrationReadOnlyPerm,
    (isRbacEnabled: boolean, isAzureAdSetupEnabled: boolean, hasIamIntegrationPerm: boolean) =>
      isRbacEnabled && isAzureAdSetupEnabled && hasIamIntegrationPerm,
  );

  /**
   * isIntegrationHealthReportLoading
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static isIntegrationHealthReportLoading: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => {
      return state.integrationHealthReportLoading;
    },
  );

  /**
   * isIntegrationObjectsCountsLoading
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static isIntegrationObjectsCountsLoading: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => {
      return state.integrationUsage.objectsCountsLoading;
    },
  );

  /**
   * getIntegrationObjectsCounts
   * @static
   * @type {MemoizedSelector<MerlotState, IntegrationObjectsCounts>}
   * @memberof IntegrationSelectors
   */
  public static getIntegrationObjectsCounts: MemoizedSelector<MerlotState, IntegrationObjectsCounts> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => {
      return state.integrationUsage.objectsCounts;
    },
  );

  /**
   * isIntegrationObjectsLoading
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static isIntegrationObjectsLoading: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => {
      return state.integrationUsage.objectsLoading;
    },
  );

  /**
   * getIntegrationselectedObjectType
   * @static
   * @type {MemoizedSelector<MerlotState, IntegrationObjectsType>}
   * @memberof IntegrationSelectors
   */
  public static getIntegrationselectedObjectType: MemoizedSelector<MerlotState, IntegrationObjectsType> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => {
      return state.integrationUsage.selectedObjectType;
    },
  );

  /**
   * getIntegrationObjectsSearchRequest
   * @static
   * @type {MemoizedSelector<MerlotState, GenericSearchRequest>}
   * @memberof IntegrationSelectors
   */
  public static getIntegrationObjectsSearchRequest: MemoizedSelector<MerlotState, GenericSearchRequest> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => {
      return state.integrationUsage.objectsSearchRequest;
    },
  );

  /**
   * getIntegrationObjectsSearchResponse
   * @static
   * @type {MemoizedSelector<MerlotState, IntegrationObjectsSearchResponse>}
   * @memberof IntegrationSelectors
   */
  public static getIntegrationObjectsSearchResponse: MemoizedSelector<MerlotState, IntegrationObjectsSearchResponse> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => {
      return state.integrationUsage.objectsSearchResponse;
    },
  );

  /**
   * isIntegrationActionsLoading
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof IntegrationSelectors
   */
  public static isIntegrationActionsLoading: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => {
      return state.integrationActions.loading;
    },
  );

  /**
   * getIntegrationActionsSearchRequest
   * @static
   * @type {MemoizedSelector<MerlotState, GenericSearchRequest>}
   * @memberof IntegrationSelectors
   */
  public static getIntegrationActionsSearchRequest: MemoizedSelector<MerlotState, GenericSearchRequest> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => {
      return state.integrationActions.searchRequest;
    },
  );

  /**
   * getIntegrationActionsSearchResponse
   * @static
   * @type {MemoizedSelector<MerlotState, IntegrationActionsSearchResponse>}
   * @memberof IntegrationSelectors
   */
  public static getIntegrationActionsSearchResponse: MemoizedSelector<MerlotState, IntegrationActionsSearchResponse> = createSelector(
    IntegrationSelectors.selectIntegrationState,
    (state: IntegrationState) => {
      return state.integrationActions.searchResponse;
    },
  );
}
