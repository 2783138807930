/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector } from '@ngrx/store';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import {
  Account,
  AccountSearchResponse,
  DataAccessPolicy,
  DataAccessPolicyAssignPreview,
  DataAccessPolicySearchResponse,
  GenericSearchRequest,
} from '@ws1c/intelligence-models';
import { DataAccessPolicyState } from './data-access-policy.state';

/**
 * DataAccessPolicySelectors
 * @export
 * @class DataAccessPolicySelectors
 */
export class DataAccessPolicySelectors {
  /**
   * getDataAccessPolicyState
   * @static
   * @param {MerlotState} state
   * @returns {DataAccessPolicyState}
   * @memberof DataAccessPolicySelectors
   */
  public static selectDataAccessPolicyState = (state: MerlotState) => state.dataAccessPolicyState;

  /**
   * hasDataAccessPolicy
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof DataAccessPolicySelectors
   */
  public static hasDataAccessPolicy: MemoizedSelector<MerlotState, boolean> = createSelector(
    DataAccessPolicySelectors.selectDataAccessPolicyState,
    (state: DataAccessPolicyState) => {
      return (
        state.isLoading ||
        (state.dataAccessPolicySearchRequest &&
          state.dataAccessPolicySearchRequest.searchTerm &&
          !!state.dataAccessPolicySearchRequest.searchTerm.value) ||
        (state.dataAccessPolicySearchResponse && state.dataAccessPolicySearchResponse.total > 0)
      );
    },
  );

  /**
   * isLoadingDataAccessPolicy
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof DataAccessPolicySelectors
   */
  public static isLoadingDataAccessPolicy: MemoizedSelector<MerlotState, boolean> = createSelector(
    DataAccessPolicySelectors.selectDataAccessPolicyState,
    (state: DataAccessPolicyState) => state.isLoading,
  );

  /**
   * getAssignedAccountSearchResponse
   * @static
   * @type {MemoizedSelector<MerlotState, AccountSearchResponse>}
   * @memberof DataAccessPolicySelectors
   */
  public static getAssignedAccountSearchResponse: MemoizedSelector<MerlotState, AccountSearchResponse> = createSelector(
    DataAccessPolicySelectors.selectDataAccessPolicyState,
    (state: DataAccessPolicyState) => state.assignedAccountsSearchResponse,
  );

  /**
   * getAssignedAccounts
   * @static
   * @type {MemoizedSelector<MerlotState, Account[]>}
   * @memberof DataAccessPolicySelectors
   */
  public static getAssignedAccounts: MemoizedSelector<MerlotState, Account[]> = createSelector(
    DataAccessPolicySelectors.getAssignedAccountSearchResponse,
    (searchResponse: AccountSearchResponse) => (searchResponse ? searchResponse.results : []),
  );

  /**
   *
   * getDataAccessPolicySearchResponse
   * @static
   * @type {MemoizedSelector<MerlotState, DataAccessPolicySearchResponse>}
   * @memberof DataAccessPolicySelectors
   */
  public static getDataAccessPolicySearchResponse: MemoizedSelector<MerlotState, DataAccessPolicySearchResponse> = createSelector(
    DataAccessPolicySelectors.selectDataAccessPolicyState,
    (state: DataAccessPolicyState) => state.dataAccessPolicySearchResponse,
  );

  /**
   * getDataAccessPoliciesCount
   * @static
   * @type {MemoizedSelector<MerlotState, number>}
   * @memberof DataAccessPolicySelectors
   */
  public static getDataAccessPoliciesCount: MemoizedSelector<MerlotState, number> = createSelector(
    DataAccessPolicySelectors.getDataAccessPolicySearchResponse,
    (response: DataAccessPolicySearchResponse) => response?.total ?? 0,
  );

  /**
   * getDataAccessPolicySearchRequest
   * @static
   * @type {MemoizedSelector<MerlotState, GenericSearchRequest>}
   * @memberof DataAccessPolicySelectors
   */
  public static getDataAccessPolicySearchRequest: MemoizedSelector<MerlotState, GenericSearchRequest> = createSelector(
    DataAccessPolicySelectors.selectDataAccessPolicyState,
    (state: DataAccessPolicyState) => state.dataAccessPolicySearchRequest,
  );

  /**
   * getSelectedDataAccessPolicy
   * @static
   * @type {MemoizedSelector<MerlotState, DataAccessPolicy>}
   * @memberof DataAccessPolicySelectors
   */
  public static getSelectedDataAccessPolicy: MemoizedSelector<MerlotState, DataAccessPolicy> = createSelector(
    DataAccessPolicySelectors.selectDataAccessPolicyState,
    (state: DataAccessPolicyState) => state.selectedDataAccessPolicy,
  );

  /**
   * getDataAccessPolicyDeleteConfirmModal
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof DataAccessPolicySelectors
   */
  public static getDataAccessPolicyDeleteConfirmModal: MemoizedSelector<MerlotState, boolean> = createSelector(
    DataAccessPolicySelectors.selectDataAccessPolicyState,
    (state: DataAccessPolicyState) => state.showDeleteConfirmModal,
  );

  /**
   * getDataAccessPolicyById
   * @static
   * @type {MemoizedSelector<MerlotState, DataAccessPolicy>}
   * @memberof DataAccessPolicySelectors
   */
  public static getDataAccessPolicyById: MemoizedSelector<MerlotState, DataAccessPolicy> = createSelector(
    DataAccessPolicySelectors.selectDataAccessPolicyState,
    (state: DataAccessPolicyState) => state.dataAccessPolicyById,
  );

  /**
   * showDataAccessPolicyPreview
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof DataAccessPolicySelectors
   */
  public static showDataAccessPolicyPreview: MemoizedSelector<MerlotState, boolean> = createSelector(
    DataAccessPolicySelectors.selectDataAccessPolicyState,
    (state: DataAccessPolicyState) => !!state.dataAccessPolicyPreview,
  );

  /**
   * getDataAccessPolicyPreview
   * @static
   * @type {MemoizedSelector<MerlotState, DataAccessPolicyAssignPreview>}
   * @memberof DataAccessPolicySelectors
   */
  public static getDataAccessPolicyPreview: MemoizedSelector<MerlotState, DataAccessPolicyAssignPreview> = createSelector(
    DataAccessPolicySelectors.selectDataAccessPolicyState,
    (state: DataAccessPolicyState) => state.dataAccessPolicyPreview,
  );

  /**
   * isLoadingPolicy
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof DataAccessPolicySelectors
   */
  public static isLoadingPolicy: MemoizedSelector<MerlotState, boolean> = createSelector(
    DataAccessPolicySelectors.selectDataAccessPolicyState,
    (state: DataAccessPolicyState) => state.isLoadingPolicy,
  );

  /**
   *
   * isLoadingUsers
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof DataAccessPolicySelectors
   */
  public static isLoadingUsers: MemoizedSelector<MerlotState, boolean> = createSelector(
    DataAccessPolicySelectors.selectDataAccessPolicyState,
    (state: DataAccessPolicyState) => state.isLoadingUsers,
  );
}
