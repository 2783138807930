/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GenericObject, WebError } from '@dpa/ui-common';
import { uniqueId } from 'lodash-es';

import {
  AutomationActionFieldForLookup,
  AutomationActionFieldLookupRequestPayload,
  DefaultValueType,
  MetaFormField,
  NameValue,
} from '@ws1c/intelligence-models';

/**
 * LookupFormComponent
 *
 * @export
 * @class LookupFormComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-lookup-form',
  templateUrl: 'lookup-form.component.html',
  styleUrls: ['lookup-form.component.scss'],
})
export class LookupFormComponent implements OnInit {
  @Input() public field: MetaFormField = new MetaFormField({});
  @Input() public formControlValue: string;
  @Input() public lookupMap: Record<string, NameValue[]> = {};
  @Input() public lookupMapError?: WebError;
  @Input() public lookupMapLoading?: boolean = false;
  @Input() public nestedLookups?: GenericObject = {};
  @Output() public customValueChoiceChange = new EventEmitter<MetaFormField>();
  @Output() public lookupChoiceChange = new EventEmitter<MetaFormField>();
  @Output() public onRequestLookup = new EventEmitter<AutomationActionFieldLookupRequestPayload>();
  @Output() public onSetFieldValueFromLookup = new EventEmitter<string>();

  public formGroup: UntypedFormGroup = new UntypedFormGroup({});
  public selectedLookupValues: Array<Partial<MetaFormField>> = [];
  public id: string = uniqueId('lookup-form-component');

  public readonly DEFAULT_VALUE_TYPE = DefaultValueType;

  /**
   * ngOnInit
   *
   * @memberof LookupFormComponent
   */
  public ngOnInit() {
    if (!this.field.lookupConfig) {
      return;
    }
    const lookupValueControl = new UntypedFormControl(this.formControlValue ? DefaultValueType.CUSTOM : DefaultValueType.LOOKUP);
    this.formGroup.setControl(this.getLookupFieldName(this.field.name), lookupValueControl);
    Promise.resolve().then(() => {
      if (this.formControlValue) {
        this.onCustomValueChoiceClick(this.field);
      } else {
        this.onLookupChoiceClick(this.field);
      }
    });
  }

  /**
   * onLookupChoiceClick - emits lookup field
   *
   * @param {MetaFormField} field
   * @memberof LookupFormComponent
   */
  public onLookupChoiceClick(field: MetaFormField) {
    this.lookupChoiceChange.emit(field);
  }

  /**
   * onCustomValueChoiceClick - emits field and clears selectedLookupValues
   *
   * @param {MetaFormField} field
   * @memberof LookupFormComponent
   */
  public onCustomValueChoiceClick(field: MetaFormField) {
    this.customValueChoiceChange.emit(field);
    this.selectedLookupValues = [];
  }

  /**
   * onSelectLookupValue
   *
   * @param {Partial<MetaFormField>} selectedLookupValue
   * @param {number} indexOfField
   * @param {string} initialFieldName
   * @memberof LookupFormComponent
   */
  public onSelectLookupValue(selectedLookupValue: Partial<MetaFormField>, indexOfField: number, initialFieldName: string) {
    if (selectedLookupValue) {
      this.selectedLookupValues[indexOfField] = selectedLookupValue;
      if (indexOfField === Object.keys(this.nestedLookups[initialFieldName]).length - 1) {
        this.onSetFieldValueFromLookup.emit(selectedLookupValue.value);
      }
    }
  }

  /**
   *  onRequestFieldLookup - emits field lookup request payload
   *
   * @param {string} query
   * @param {AutomationActionFieldForLookup} fieldForLookup
   * @param {number} index
   * @memberof LookupFormComponent
   */
  public onRequestFieldLookup(query: string, fieldForLookup: AutomationActionFieldForLookup, index: number) {
    this.onRequestLookup.emit(
      new AutomationActionFieldLookupRequestPayload({
        fieldForLookup,
        selectedLookupValue: this.selectedLookupValues[index - 1],
        query,
      }),
    );
  }

  /**
   * formatLookupName
   *
   * @param {Partial<MetaFormField>} lookup
   * @returns {string}
   * @memberof LookupFormComponent
   */
  public formatLookupName(lookup: Partial<MetaFormField>): string {
    return lookup.name || '';
  }

  /**
   * getFieldId
   *
   * @param {string} fieldName
   * @param {number} index
   * @returns {string}
   * @memberof LookupFormComponent
   */
  public getFieldId(fieldName: string, index: number = 0): string {
    return `${this.id}-${fieldName}${index}`;
  }

  /**
   * isLookupFormVisible
   *
   * @param {string} fieldName
   * @returns {boolean}
   * @memberof LookupFormComponent
   */
  public isLookupFormVisible(fieldName: string): boolean {
    return this.formGroup.value[this.getLookupFieldName(fieldName)] === DefaultValueType.LOOKUP && this.nestedLookups[fieldName]?.length;
  }

  /**
   * getSearchableItems
   *
   * @param {AutomationActionFieldForLookup} nestedLookup
   * @returns {NameValue[]}
   * @memberof LookupFormComponent
   */
  public getSearchableItems(nestedLookup: AutomationActionFieldForLookup): NameValue[] {
    return this.lookupMap[this.getLookupMapKey(nestedLookup)] ? this.lookupMap[this.getLookupMapKey(nestedLookup)] : [];
  }

  /**
   * getPaddingLeftByIndex
   *
   * @param {number} index
   * @returns {GenericObject}
   * @memberof LookupFormComponent
   */
  public getPaddingLeftByIndex(index: number): GenericObject {
    return {
      'padding-left': `${0.6 * index}rem`,
    };
  }

  /**
   * getLookupMapKey
   *
   * @param {AutomationActionFieldForLookup} fieldForLookup
   * @returns {string}
   * @memberof LookupFormComponent
   */
  public getLookupMapKey(fieldForLookup: AutomationActionFieldForLookup): string {
    return `${fieldForLookup.field.name}_${fieldForLookup.lookupConfig.operationId ?? fieldForLookup.lookupConfig.actionTemplateId}`;
  }

  /**
   * getLookupFieldName
   *
   * @private
   * @param {string} fieldName
   * @returns {string}
   * @memberof LookupFormComponent
   */
  private getLookupFieldName(fieldName: string): string {
    return `${fieldName}-${this.DEFAULT_VALUE_TYPE.LOOKUP}`;
  }
}
