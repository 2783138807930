/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input, OnChanges } from '@angular/core';

import { AutomationRunHistoryStatus, RatioIndicatorColors, Trend } from '@ws1c/intelligence-models';
import { helpers } from '@ws1c/intelligence-models/helpers';

/**
 * AutomationRatioIndicatorComponent
 *
 * @export
 * @class AutomationRatioIndicatorComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-automation-ratio-indicator',
  templateUrl: 'automation-ratio-indicator.component.html',
  styleUrls: ['automation-ratio-indicator.component.scss'],
})
export class AutomationRatioIndicatorComponent implements OnChanges {
  @Input() public automationId: string;
  @Input() public runHistoryTrend: Trend;

  public runHistory: AutomationRunHistoryStatus;

  public readonly RatioIndicatorColors = RatioIndicatorColors;

  /**
   * ngOnChanges
   *
   * @memberof AutomationRatioIndicatorComponent
   */
  public ngOnChanges() {
    if (this.automationId && this.runHistoryTrend) {
      this.runHistory = helpers.getRunHistoryForId(this.automationId, this.runHistoryTrend) ?? this.runHistory;
    }
  }
}
