/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import {
  ConnectionDeauthorizeRequest,
  ConnectionSetupRequest,
  EventForwarderTemplate,
  ServiceConfigTemplateIndex,
} from '@dpa-shared-merlot/model';
import {
  AutomationsByActionIdSearchRequest,
  AutomationSearchResponse,
  AvailableEventForwarder,
  AvailablePartner,
  AvailableService,
  AvailableServiceTenant,
  ConnectionModalMode,
  EventForwarderConnectionModalMode,
  EventForwarderConnectionModalSection,
  ObjectById,
  TrustNetworkPartnerConnectionModalMode,
  TrustNetworkPartnerConnectionModalSection,
} from '@ws1c/intelligence-models';

/**
 * Defines UI State for Connection Feature
 *
 * @export
 * @interface ConnectionState
 */
export interface ConnectionState {
  serviceTemplatesByType: ServiceConfigTemplateIndex;
  automationsByActionIdRequest: AutomationsByActionIdSearchRequest;
  automationsByActionIdResponse: AutomationSearchResponse;
  trustNetworkServices: AvailableService[];

  connectionModalMode: ConnectionModalMode;
  connectionModalModel: AvailableService;
  connectionModalTenantModel: AvailableServiceTenant;
  connectionSetupRequest: ConnectionSetupRequest;
  connectionDeauthorizeRequest: ConnectionDeauthorizeRequest;
  connectionSlideOver: {
    isOpen: boolean;
    model: AvailableService;
  };

  actionTemplateUploadLoading: boolean;

  availablePartners: AvailablePartner[];
  selectedPartner: AvailablePartner;
  trustNetworkPartnerConnectionModalMode: TrustNetworkPartnerConnectionModalMode;
  trustNetworkPartnerConnectionModalSection: TrustNetworkPartnerConnectionModalSection;
  isSavingOrUpdatingPartnerConnection: boolean;
  isRegeneratingPartnerConnectionToken: boolean;
  isDeauthorizingPartnerConnection: boolean;
  isRedirectAuthorizeModalActive: boolean;

  eventForwardersTemplatesByName: ObjectById<EventForwarderTemplate>;
  trustNetworkEventForwarders: AvailableEventForwarder[];
  selectedEventForwarder: AvailableEventForwarder;
  eventForwarderConnectionModalMode: EventForwarderConnectionModalMode;
  eventForwarderConnectionModalSection: EventForwarderConnectionModalSection;
  isSavingOrUpdatingEventForwarderConnection: boolean;
  isDeauthorizingEventForwarderConnection: boolean;
}

/**
 * initialConnectionState
 */
export const initialConnectionState: ConnectionState = {
  serviceTemplatesByType: undefined,
  automationsByActionIdRequest: new AutomationsByActionIdSearchRequest(),
  automationsByActionIdResponse: undefined,
  trustNetworkServices: [],

  connectionModalMode: ConnectionModalMode.CLOSE,
  connectionModalModel: undefined,
  connectionModalTenantModel: undefined,
  connectionSetupRequest: undefined,
  connectionDeauthorizeRequest: undefined,
  connectionSlideOver: {
    isOpen: false,
    model: undefined,
  },

  actionTemplateUploadLoading: false,

  availablePartners: [],
  selectedPartner: undefined,
  trustNetworkPartnerConnectionModalMode: TrustNetworkPartnerConnectionModalMode.CLOSE,
  trustNetworkPartnerConnectionModalSection: TrustNetworkPartnerConnectionModalSection.NONE,
  isSavingOrUpdatingPartnerConnection: false,
  isRegeneratingPartnerConnectionToken: false,
  isDeauthorizingPartnerConnection: false,
  isRedirectAuthorizeModalActive: false,

  eventForwardersTemplatesByName: undefined,
  trustNetworkEventForwarders: [],
  selectedEventForwarder: undefined,
  eventForwarderConnectionModalMode: EventForwarderConnectionModalMode.CLOSE,
  eventForwarderConnectionModalSection: EventForwarderConnectionModalSection.NONE,
  isSavingOrUpdatingEventForwarderConnection: false,
  isDeauthorizingEventForwarderConnection: false,
};
