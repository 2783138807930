<div *ngIf="!isReadOnly">
  <dpa-tooltip
    *ngIf="!isPredefinedReport"
    dpaTabEvent
    [attr.aria-disabled]="!shouldRefreshPreview"
    [tooltipText]="(shouldRefreshPreview ? 'COMMON_ACTIONS.REFRESH_PREVIEW' : 'REPORT_CUSTOMIZE.DISABLED_REFRESH_MESSAGE') | translate"
    [attr.aria-label]="(shouldRefreshPreview ? 'COMMON_ACTIONS.REFRESH_PREVIEW' : 'REPORT_CUSTOMIZE.DISABLED_REFRESH_MESSAGE') | translate"
    (keyup.enter)="onPageChange(pagedRequest)"
    role="button"
  >
    <button
      [disabled]="!shouldRefreshPreview"
      (click)="onPageChange(pagedRequest)"
      class="btn btn-primary btn-sm mt-x2"
    >
      {{ 'COMMON_ACTIONS.REFRESH_PREVIEW' | translate }}
    </button>
  </dpa-tooltip>
  <div
    *ngIf="isLoadingPreview"
    class="mv-x1"
  >
    <span>
      {{ 'REPORT_CUSTOMIZE.LOADING_RESULTS' | translate }}
    </span>
  </div>
  <div
    *ngIf="totalCount && !isLoadingPreview"
    class="mb-x2 mt-x1"
  >
    <span>
      {{ customPreviewMessage ?? 'REPORT_CUSTOMIZE.REPORT_FILTERS_PREVIEW_RESULTS_COUNT' | translate: { totalCount: totalCount } }}
    </span>
    <dpa-from-now
      [timestamp]="refreshedAt"
      [formatString]="'COMMON_MESSAGES.REFRESHED'"
      class="mt-x2"
    ></dpa-from-now>
  </div>
</div>
<div>
  <clr-alert
    *ngIf="!isPredefinedReport && isPreviewTimedout"
    clrAlertType="warning"
    [clrAlertClosable]="true"
    class="mt-x2"
  >
    <clr-alert-item class="alert-item">
      <span class="alert-text">
        {{ (isReadOnly ? 'REPORT_CUSTOMIZE.TIMEOUT_WARNING_READONLY' : 'REPORT_CUSTOMIZE.TIMEOUT_WARNING') | translate }}
      </span>
      <a
        *ngIf="isReadOnly"
        (click)="runReport()"
        (keyup.enter)="runReport()"
      >
        {{ 'COMMON_ACTIONS.RUN' | translate }}
      </a>
    </clr-alert-item>
  </clr-alert>
</div>
<div
  [class.read-only]="isReadOnly"
  class="result-table"
>
  <ng-container *ngIf="canViewDataGrid(); else emptyTable">
    <ng-container *ngIf="filterResults">
      <dpa-data-grid
        [loading]="isLoadingPreview"
        [response]="filterResults"
        [sortOns]="[]"
        [selectable]="{ enabled: false }"
        [pagination]="false"
        [enableColumnSelector]="!isReadOnly"
        [enableColumnHiding]="false"
        [availableColumns]="allColumns"
        [selectedColumns]="dataGridColumns"
        (selectedColumnsChange)="onSelectedColumnsChange($event)"
        (pageChange)="onPageChange($event)"
      >
        <div dpaDataGridColumnDefault>
          <ng-template
            dpaDataGridCell
            let-dataItem
            let-column="column"
          >
            <dpa-decorated-value
              [value]="dataItem[column.value]"
              [valueDecorator]="column?.detail?.valueDecorator"
            ></dpa-decorated-value>
          </ng-template>
        </div>
        <ng-template
          dpaDataGridColumnSelectorDetail
          let-label
          let-detail="detail"
        >
          <dpa-column-selector-detail
            [label]="label"
            [detail]="detail"
          ></dpa-column-selector-detail>
        </ng-template>
      </dpa-data-grid>
    </ng-container>
  </ng-container>
  <ng-template #emptyTable>
    <!-- We have three scenarios here
      1. No columns are selected - display empty datagrid with "Add first column" message
      2. Preview is still not loaded - display empty datagrid with "Refresh preview" message
      3. Preview results is empty - display empty datagrid with "No records" message
    -->
    <dpa-data-grid
      [loading]="false"
      [selectable]="{ enabled: false }"
      [noResultsText]="getNoResultsText() | translate"
      [showEmptyGridWithColumnHeader]="true"
      [enableColumnSelector]="!dataGridColumns?.length || (!isReadOnly && !isPredefinedReport)"
      [enableColumnHiding]="false"
      [availableColumns]="allColumns"
      [selectedColumns]="dataGridColumns"
      (selectedColumnsChange)="onSelectedColumnsChange($event)"
      class="empty-grid mt-x1"
    >
      <ng-template
        dpaDataGridColumnSelectorDetail
        let-label
        let-detail="detail"
      >
        <dpa-column-selector-detail
          [label]="label"
          [detail]="detail"
        ></dpa-column-selector-detail>
      </ng-template>
    </dpa-data-grid>
  </ng-template>
</div>
