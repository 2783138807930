/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { PagedRequest, SortOn, WebError } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import {
  Account,
  AccountUsageDetails,
  AutomationSearchRequest,
  AutomationSearchResponse,
  CustomReportSearchRequest,
  CustomReportSearchResponse,
  DashboardSearchRequest,
  DashboardSearchResponse,
  GenericSearchRequest,
  SearchTerm,
  ServiceLimits,
  ServiceLimitsName,
  SystemLimitsFeatureTag,
  SystemLimitsSearchResponse,
  SystemLimitsUsageDetails,
} from '@ws1c/intelligence-models';

/**
 * SystemLimitsActions
 * @export
 * @class SystemLimitsActions
 */
export class SystemLimitsActions {
  public static readonly CATEGORY = '[SYSTEM_LIMITS]';

  public static loadUsageSummary = createAction(`${SystemLimitsActions.CATEGORY} LOAD_USAGE_SUMMARY`);

  public static loadUsageSummarySuccess = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_USAGE_SUMMARY_SUCCESS`,
    props<{ usageDetails: SystemLimitsUsageDetails[] }>(),
  );

  public static loadUsageSummaryFailure = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_USAGE_SUMMARY_FAILURE`,
    props<{ error?: WebError }>(),
  );

  public static setActiveTab = createAction(
    `${SystemLimitsActions.CATEGORY} SET_ACTIVE_TAB`,
    props<{ activeTab: SystemLimitsFeatureTag }>(),
  );

  public static loadSystemLimits = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_SYSTEM_LIMITS`,
    props<{ systemLimitsSearchRequest: GenericSearchRequest }>(),
  );

  public static loadSystemLimitsSuccess = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_SYSTEM_LIMITS_SUCCESS`,
    props<{ systemLimitsSearchResponse: SystemLimitsSearchResponse }>(),
  );

  public static loadSystemLimitsFailure = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_SYSTEM_LIMITS_FAILURE`,
    props<{ error?: WebError }>(),
  );

  public static refreshSystemLimits = createAction(`${SystemLimitsActions.CATEGORY} REFRESH_SYSTEM_LIMITS`);

  public static changeSystemLimitsPagination = createAction(
    `${SystemLimitsActions.CATEGORY} CHANGE_SYSTEM_LIMITS_PAGINATION`,
    props<{ pagedRequest: PagedRequest }>(),
  );

  public static sortSystemLimits = createAction(`${SystemLimitsActions.CATEGORY} SORT_SYSTEM_LIMITS`, props<{ sortOns: SortOn[] }>());

  public static filterSystemLimits = createAction(
    `${SystemLimitsActions.CATEGORY} FILTER_SYSTEM_LIMITS`,
    props<{ searchTerms: SearchTerm[] }>(),
  );

  public static openSystemLimitsModal = createAction(`${SystemLimitsActions.CATEGORY} OPEN_SYSTEM_LIMITS_MODAL`);

  public static closeSystemLimitsModal = createAction(`${SystemLimitsActions.CATEGORY} CLOSE_SYSTEM_LIMITS_MODAL`);

  public static loadServiceLimits = createAction(`${SystemLimitsActions.CATEGORY} LOAD_SERVICE_LIMITS`);

  public static loadServiceLimitsSuccess = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_SERVICE_LIMITS_SUCCESS`,
    props<{ serviceLimits: ServiceLimits[] }>(),
  );

  public static loadServiceLimitsFailure = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_SERVICE_LIMITS_FAILURE`,
    props<{ error?: WebError }>(),
  );

  public static saveServiceLimits = createAction(
    `${SystemLimitsActions.CATEGORY} SAVE_SERVICE_LIMITS`,
    props<{
      serviceLimitsForUpdate?: { [key in ServiceLimitsName]: number } | {};
      serviceLimitsForDelete?: ServiceLimitsName[];
    }>(),
  );

  public static setSelectedAccountId = createAction(
    `${SystemLimitsActions.CATEGORY} SET_SELECTED_ACCOUNT_ID`,
    props<{ selectedAccountId: string }>(),
  );

  public static getSelectedAccountDetails = createAction(`${SystemLimitsActions.CATEGORY} GET_SELECTED_ACCOUNT_DETAILS`);

  public static getSelectedAccountDetailsSuccess = createAction(
    `${SystemLimitsActions.CATEGORY} GET_SELECTED_ACCOUNT_DETAILS_SUCCESS`,
    props<{ selectedAccount: Account }>(),
  );

  public static loadUserAutomationsUsageSummary = createAction(`${SystemLimitsActions.CATEGORY} LOAD_USER_AUTOMATIONS_USAGE_SUMMARY`);

  public static loadUserAutomationsUsageSummarySuccess = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_USER_AUTOMATIONS_USAGE_SUMMARY_SUCCESS`,
    props<{ automationsUsageSummary: AccountUsageDetails }>(),
  );

  public static loadUserAutomationsUsageSummaryFailure = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_USER_AUTOMATIONS_USAGE_SUMMARY_FAILURE`,
    props<{ error?: WebError }>(),
  );

  public static loadUserCustomReportsUsageSummary = createAction(`${SystemLimitsActions.CATEGORY} LOAD_USER_CUSTOM_REPORTS_USAGE_SUMMARY`);

  public static loadUserCustomReportsUsageSummarySuccess = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_USER_CUSTOM_REPORTS_USAGE_SUMMARY_SUCCESS`,
    props<{ customReportsUsageSummary: AccountUsageDetails }>(),
  );

  public static loadUserCustomReportsUsageSummaryFailure = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_USER_CUSTOM_REPORTS_USAGE_SUMMARY_FAILURE`,
    props<{ error?: WebError }>(),
  );

  public static loadUserDashboardsUsageSummary = createAction(`${SystemLimitsActions.CATEGORY} LOAD_USER_DASHBOARDS_USAGE_SUMMARY`);

  public static loadUserDashboardsUsageSummarySuccess = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_USER_DASHBOARDS_USAGE_SUMMARY_SUCCESS`,
    props<{ dashboardsUsageSummary: AccountUsageDetails }>(),
  );

  public static loadUserDashboardsUsageSummaryFailure = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_USER_DASHBOARDS_USAGE_SUMMARY_FAILURE`,
    props<{ error?: WebError }>(),
  );

  public static loadUserServiceLimits = createAction(`${SystemLimitsActions.CATEGORY} LOAD_USER_SERVICE_LIMITS`);

  public static loadUserServiceLimitsSuccess = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_USER_SERVICE_LIMITS_SUCCESS`,
    props<{ serviceLimits: ServiceLimits[] }>(),
  );

  public static loadUserServiceLimitsFailure = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_USER_SERVICE_LIMITS_FAILURE`,
    props<{ error?: WebError }>(),
  );

  public static loadUserAutomations = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_USER_AUTOMATIONS`,
    props<{ automationSearchRequest: AutomationSearchRequest }>(),
  );

  public static loadUserAutomationsSuccess = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_USER_AUTOMATIONS_SUCCESS`,
    props<{ automationSearchResponse: AutomationSearchResponse }>(),
  );

  public static loadUserAutomationsFailure = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_USER_AUTOMATIONS_FAILURE`,
    props<{ error?: WebError }>(),
  );

  public static refreshUserAutomations = createAction(`${SystemLimitsActions.CATEGORY} REFRESH_USER_AUTOMATIONS`);

  public static changeUserAutomationsPagination = createAction(
    `${SystemLimitsActions.CATEGORY} CHANGE_USER_AUTOMATIONS_PAGINATION`,
    props<{ pagedRequest: PagedRequest }>(),
  );

  public static sortUserAutomations = createAction(`${SystemLimitsActions.CATEGORY} SORT_USER_AUTOMATIONS`, props<{ sortOns: SortOn[] }>());

  public static loadUserCustomReports = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_USER_CUSTOM_REPORTS`,
    props<{ customReportSearchRequest: CustomReportSearchRequest }>(),
  );

  public static loadUserCustomReportsSuccess = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_USER_CUSTOM_REPORTS_SUCCESS`,
    props<{ customReportSearchResponse: CustomReportSearchResponse }>(),
  );

  public static loadUserCustomReportsFailure = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_USER_CUSTOM_REPORTS_FAILURE`,
    props<{ error?: WebError }>(),
  );

  public static refreshUserCustomReports = createAction(`${SystemLimitsActions.CATEGORY} REFRESH_USER_CUSTOM_REPORTS`);

  public static changeUserCustomReportsPagination = createAction(
    `${SystemLimitsActions.CATEGORY} CHANGE_USER_CUSTOM_REPORTS_PAGINATION`,
    props<{ pagedRequest: PagedRequest }>(),
  );

  public static sortUserCustomReports = createAction(
    `${SystemLimitsActions.CATEGORY} SORT_USER_CUSTOM_REPORTS`,
    props<{ sortOns: SortOn[] }>(),
  );

  public static loadUserDashboards = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_USER_DASHBOARDS`,
    props<{ dashboardSearchRequest: DashboardSearchRequest }>(),
  );

  public static loadUserDashboardsSuccess = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_USER_DASHBOARDS_SUCCESS`,
    props<{ dashboardSearchResponse: DashboardSearchResponse }>(),
  );

  public static loadUserDashboardsFailure = createAction(
    `${SystemLimitsActions.CATEGORY} LOAD_USER_DASHBOARDS_FAILURE`,
    props<{ error?: WebError }>(),
  );

  public static refreshUserDashboards = createAction(`${SystemLimitsActions.CATEGORY} REFRESH_USER_DASHBOARDS`);

  public static changeUserDashboardsPagination = createAction(
    `${SystemLimitsActions.CATEGORY} CHANGE_USER_DASHBOARDS_PAGINATION`,
    props<{ pagedRequest: PagedRequest }>(),
  );

  public static sortUserDashboards = createAction(`${SystemLimitsActions.CATEGORY} SORT_USER_DASHBOARDS`, props<{ sortOns: SortOn[] }>());

  public static openUserSystemLimitsModal = createAction(`${SystemLimitsActions.CATEGORY} OPEN_USER_SYSTEM_LIMITS_MODAL`);

  public static closeUserSystemLimitsModal = createAction(`${SystemLimitsActions.CATEGORY} CLOSE_USER_SYSTEM_LIMITS_MODAL`);

  public static saveUserServiceLimits = createAction(
    `${SystemLimitsActions.CATEGORY} SAVE_USER_SERVICE_LIMITS`,
    props<{
      userServiceLimitsForUpdate?: { [key in ServiceLimitsName]: number } | {};
      userServiceLimitsForDelete?: ServiceLimitsName[];
    }>(),
  );
}
