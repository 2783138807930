<dpa-query-builder
  [maxLevels]="MAX_ALLOWED_RULE_NESTING_LEVELS"
  [maxRules]="MAX_ALLOWED_RULES_PER_FILTER"
  [allowNestedRules]="isNestedGroupsFilterEnabled$ | async"
  [ruleGroup]="ruleGroup"
  [getNewRule]="getNewRule"
  [getNewRuleGroup]="getNewRuleGroup"
  [trackByFn]="trackByFn"
  [isRuleActive]="isRuleActive"
  [isRuleInValid]="isRuleInValid"
  [filterTemplate]="filterTemplate"
  [filterActionsTemplate]="filterActionTemplate"
  (queryChange)="onQueryChange($event)"
></dpa-query-builder>

<ng-template
  #filterTemplate
  let-rule="rule"
  let-index="index"
  let-parentRuleGroup="parentRuleGroup"
  let-ruleContainerNode="ruleContainerNode"
  let-onRuleChange="onRuleChange"
>
  <div
    *ngLet="rule && focusedRule !== rule && isRuleValid(rule) as readMode"
    (focusin)="onFocus(rule)"
    (focusout)="onFocusOut(rule, $event)"
    class="filter-rule"
  >
    <dpa-filter-group-rule
      *ngIf="!readMode"
      [rule]="rule"
      [suggestionCategory]="suggestionCategory"
      [suggestionFilterBys]="suggestionFilterBys"
      [showThreeColumnFilter]="showThreeColumnFilter"
      [alwaysShowKeySelector]="alwaysShowKeySelector"
      [allColumnsByName]="allColumnsByName"
      [isCrossCategory]="isCrossCategory"
      [showColumnsFromInput]="showColumnsFromInput"
      [visibleColumnsSortedByName]="visibleColumnsSortedByName"
      [delimiterSupported]="delimiterSupported"
      (ruleChange)="onRuleChange($event, index, parentRuleGroup)"
      tabindex="0"
    ></dpa-filter-group-rule>
    <div
      *ngIf="readMode"
      class="clr-col-sm rule-text-wrapper"
    >
      <div class="rule-text">
        <dpa-rule-text
          [rule]="rule"
          [allColumnsByName]="allColumnsByName"
          (valuesSelect)="onValuesSelect(rule, ruleContainerNode)"
        ></dpa-rule-text>
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #filterActionTemplate
  let-rule="rule"
  let-ruleContainerNode="ruleContainerNode"
>
  <ng-container *ngIf="editable">
    <dpa-tooltip
      *ngIf="rule && focusedRule !== rule && isRuleValid(rule)"
      [attr.aria-label]="'COMMON_ACTIONS.EDIT' | translate"
      [tooltipText]="'COMMON_ACTIONS.EDIT' | translate"
      [focusEnabled]="true"
      (click)="enableRuleEdit(rule, ruleContainerNode)"
      (keyup.enter)="enableRuleEdit(rule, ruleContainerNode)"
      role="tooltip"
      class="clickable"
    >
      <cds-icon
        shape="pencil"
        size="14"
      ></cds-icon>
    </dpa-tooltip>
  </ng-container>
</ng-template>
