/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { gql } from 'apollo-angular';

export const CONNECTORS_USAGE = gql`
  query AutomationRunHistoryView($pagedSearchRequestInput: PagedSearchRequestInput!, $connectorId: UUID!) {
    automationRunHistory(input: $pagedSearchRequestInput, connector_id: $connectorId) {
      paged_response {
        offset
        page_size
        total_count
      }
      automation_run_history_views {
        id
        name
        description
        active
        created_by
        execution_statuses {
          execution_status
          count
        }
      }
    }
  }
`;
