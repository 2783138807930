/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import {
  AccountSearchResponse,
  DataAccessPolicy,
  DataAccessPolicyAssignPreview,
  DataAccessPolicySearchResponse,
  GenericSearchRequest,
} from '@ws1c/intelligence-models';

/**
 * Defines UI State for Service Accounts
 *
 * @export
 * @interface DataAccessPolicyState
 */
export interface DataAccessPolicyState {
  dataAccessPolicySearchResponse: DataAccessPolicySearchResponse;
  dataAccessPolicySearchRequest: GenericSearchRequest;
  isLoading: boolean;
  selectedDataAccessPolicy: DataAccessPolicy;
  showDeleteConfirmModal: boolean;
  assignedAccountsSearchResponse: AccountSearchResponse;
  dataAccessPolicyById: DataAccessPolicy;
  dataAccessPolicyPreview: DataAccessPolicyAssignPreview;
  isLoadingPolicy: boolean;
  isLoadingUsers: boolean;
}

export const initialDataAccessPolicyState: DataAccessPolicyState = {
  dataAccessPolicySearchResponse: undefined,
  dataAccessPolicySearchRequest: undefined,
  isLoading: false,
  selectedDataAccessPolicy: undefined,
  showDeleteConfirmModal: false,
  assignedAccountsSearchResponse: undefined,
  dataAccessPolicyById: undefined,
  dataAccessPolicyPreview: undefined,
  isLoadingPolicy: false,
  isLoadingUsers: false,
};
