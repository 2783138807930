/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, startWith, switchMap, tap } from 'rxjs/operators';

import { I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import { RbacService, UserPreferenceService } from '@ws1c/intelligence-core/services';
import { AlertBannerActions, CoreAppState, RbacActions, UserPreferenceSelectors } from '@ws1c/intelligence-core/store';
import { ALERT_BANNER_TYPE, ROUTE_NAMES } from '@ws1c/intelligence-models';

/**
 * RbacEffects
 * @export
 * @class RbacEffects
 */
@Injectable()
export class RbacEffects {
  /**
   * getUserScopes$
   * Get the User scopes
   *
   * @type {Observable<Action>}
   * @memberof RbacEffects
   */
  public getUserScopes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RbacActions.getUserScopes),
      startWith(RbacActions.getUserScopes()),
      switchMap(() => {
        return this.userPreferenceService.getUserScopes().pipe(
          mergeMap((response: string[]) => {
            const actions = [];
            actions.push(RbacActions.getUserScopesSuccess({ userSessionScopes: response }));
            if (response.length !== 0) {
              actions.push(RbacActions.setUserScopes({ userSessionScopes: response }));
            }
            return actions;
          }),
          catchError(() => of(RbacActions.setUserScopes({ userSessionScopes: [] }))),
        );
      }),
    ),
  );

  /**
   * getUserScopesSuccess$
   *
   * @type {Observable<Action>}
   * @memberof RbacEffects
   */
  public getUserScopesSuccess$: Observable<Action> = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(RbacActions.getUserScopesSuccess)),
      this.store.select(UserPreferenceSelectors.isBrownfieldNotificationEnabled),
    ]).pipe(
      filter(([{ userSessionScopes }]: [ReturnType<typeof RbacActions.getUserScopesSuccess>, boolean]) => {
        return !userSessionScopes.length;
      }),
      map(([_action, isBrownfieldNotificationEnabled]: [Action, boolean]) => {
        return isBrownfieldNotificationEnabled ? RbacActions.goToCloudAccountRestrictedPage() : RbacActions.goToAccessRestrictedPage();
      }),
    ),
  );

  /**
   * goToAccessDeniedPage$
   *
   * @type {Observable<Action>}
   * @memberof RbacEffects
   */
  public goToAccessDeniedPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RbacActions.goToAccessDeniedPage),
        tap(() => this.routerExtensions.navigate([`/${ROUTE_NAMES.ACCESS_DENIED}`])),
      ),
    { dispatch: false },
  );

  /**
   * goToResourceNotFoundPage$
   *
   * @type {Observable<Action>}
   * @memberof RbacEffects
   */
  public goToResourceNotFoundPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RbacActions.goToResourceNotFoundPage),
        tap(() => this.routerExtensions.navigate([`/${ROUTE_NAMES.RESOURCE_NOT_FOUND}`])),
      ),
    { dispatch: false },
  );

  /**
   * goToAccessRestrictedPage$
   *
   * @type {Observable<Action>}
   * @memberof RbacEffects
   */
  public goToAccessRestrictedPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RbacActions.goToAccessRestrictedPage),
        tap(() => this.routerExtensions.navigate([`/${ROUTE_NAMES.ACCESS_RESTRICTED}`])),
      ),
    { dispatch: false },
  );

  /**
   * goToCloudAccountRestrictedPage$
   *
   * @type {Observable<Action>}
   * @memberof RbacEffects
   */
  public goToCloudAccountRestrictedPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RbacActions.goToCloudAccountRestrictedPage),
        tap(() => this.routerExtensions.navigate([`/${ROUTE_NAMES.CLOUD_ACCOUNT_RESTRICTED}`])),
      ),
    { dispatch: false },
  );

  /**
   * requestAccess$
   *
   * @type {Observable<Action>}
   * @memberof RbacEffects
   */
  public requestAccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RbacActions.requestAccess),
      switchMap(() => {
        return this.rbacService.requestAccess().pipe(
          map((adminCountForAccessRequest: number) => RbacActions.requestAccessSuccess({ adminCountForAccessRequest })),
          catchError((error: WebError) =>
            of(
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                autoDismiss: true,
                message: this.i18nService.getLocalizedError('RESTRICTED_ACCESS.FAILED_TO_REQUEST_ACCESS', error),
              }),
            ),
          ),
        );
      }),
    ),
  );

  /**
   * Creates an instance of RbacEffects.
   * @param {Store<CoreAppState>} store
   * @param {Actions} actions$
   * @param {RbacService} rbacService
   * @param {I18NService} i18nService
   * @param {RouterExtensions} routerExtensions
   * @param {UserPreferenceService} userPreferenceService
   * @memberof RbacEffects
   */
  constructor(
    private store: Store<CoreAppState>,
    private actions$: Actions,
    private rbacService: RbacService,
    private i18nService: I18NService,
    private routerExtensions: RouterExtensions,
    private userPreferenceService: UserPreferenceService,
  ) {}
}
