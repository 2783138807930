<ng-container *ngLet="userGuideUrl$ | async as userGuide">
  <ng-container *ngIf="connectorModalState$ | async as modalState">
    <ng-container [ngSwitch]="modalState.type">
      <dpa-connector-add-modal
        *ngSwitchCase="ConnectorModalType.ADD"
        [isLoading]="modalState.isConfirmLoading"
        [connectorConfigSchema]="modalState.connectorConfigSchema"
        [userGuideUrl]="userGuide"
        (create)="onCreate($event)"
        (modalClose)="closeModal()"
      ></dpa-connector-add-modal>
      <dpa-connector-rename-modal
        *ngSwitchCase="ConnectorModalType.EDIT_CONNECTOR"
        [isLoading]="modalState.isConfirmLoading"
        [connector]="modalState.connector"
        (rename)="onRename(modalState.connector.id, $event)"
        (modalClose)="closeModal()"
      ></dpa-connector-rename-modal>
      <dpa-connector-edit-modal
        *ngSwitchCase="ConnectorModalType.EDIT_CONFIG"
        [isLoading]="modalState.isConfirmLoading"
        [connectorConfigSchema]="modalState.connectorConfigSchema"
        [connector]="modalState.connector"
        [connectorConfig]="modalState.connectorConfig"
        (edit)="onEdit(modalState.connector.configId, $event)"
        (modalClose)="closeModal()"
      ></dpa-connector-edit-modal>
      <dpa-connector-authorize-modal
        *ngSwitchCase="ConnectorModalType.AUTHORIZE"
        [isLoading]="modalState.isConfirmLoading"
        [connectorConfigSchema]="modalState.connectorConfigSchema"
        [connector]="modalState.connector"
        (authorize)="onAuthorize(modalState.connector.id, $event)"
        (modalClose)="closeModal()"
      ></dpa-connector-authorize-modal>
      <dpa-connector-delete-modal
        *ngSwitchCase="ConnectorModalType.DELETE"
        [isLoading]="modalState.isConfirmLoading"
        [connector]="modalState.connector"
        [connectorUsageCount]="modalState.connectorUsageCount"
        (delete)="onDelete(modalState.connector)"
        (viewWorkflows)="onViewWorkflows(modalState.connector)"
        (modalClose)="closeModal()"
      ></dpa-connector-delete-modal>
    </ng-container>
  </ng-container>
</ng-container>
