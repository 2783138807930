/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DateTimeFormat } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { I18NService } from '@ws1c/intelligence-common';
import { BaseTemplateSearchComponent } from '@ws1c/intelligence-core/components/template-search/base-template-search.component';
import { UserPreferenceFeatureControlsSelectors } from '@ws1c/intelligence-core/store';
import { Column, COLUMN_NAMES, DataType, ReportTemplate, ReportType, Tag, TAGS_TYPE, TEMPLATE_TYPE } from '@ws1c/intelligence-models';

/**
 * ReportTemplateSearchComponent
 * @export
 * @class ReportTemplateSearchComponent
 * @extends {BaseTemplateSearchComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-report-template-search',
  templateUrl: 'report-template-search.component.html',
  styleUrls: ['report-template-search.component.scss'],
})
export class ReportTemplateSearchComponent extends BaseTemplateSearchComponent implements OnInit, OnDestroy {
  @Input() public selectedTag: string;
  @Output() public addTemplate: EventEmitter<void> = new EventEmitter();
  @Output() public editTemplate: EventEmitter<ReportTemplate> = new EventEmitter();
  @Output() public deleteTemplate: EventEmitter<ReportTemplate> = new EventEmitter();

  public isTimeseriesReportingEnabled: boolean;
  public sub: Subscription = new Subscription();

  public readonly DateTimeFormat = DateTimeFormat;
  public readonly REPORT_TYPE = ReportType;
  public readonly columnWidthByKey = {
    name: 8,
    description: 10,
  };

  /**
   * Creates an instance of ReportTemplateSearchComponent.
   * @param {Store} store
   * @param {I18NService} i18nService
   * @memberof ReportTemplateSearchComponent
   */
  constructor(
    public store: Store,
    public i18nService: I18NService,
  ) {
    super(store, i18nService, TEMPLATE_TYPE.REPORTS);
    this.actions = [
      {
        label: this.i18nService.translate('COMMON_ACTIONS.PREVIEW'),
        onClick: this.onPreviewTemplate.bind(this),
      },
      {
        label: this.i18nService.translate('COMMON_ACTIONS.START'),
        onClick: this.onSelectTemplate.bind(this),
      },
    ];
    this.dropdownActions = [
      {
        label: this.i18nService.translate('COMMON_ACTIONS.EDIT'),
        onClick: this.onEditTemplate.bind(this),
      },
      {
        label: this.i18nService.translate('COMMON_ACTIONS.DELETE'),
        className: 'text-danger',
        onClick: this.onDeleteTemplate.bind(this),
      },
    ];
    this.tableColumns = [
      new Column({
        name: COLUMN_NAMES.byName.name,
        label: this.i18nService.translate('LIST_VIEW.COLUMNS.TEMPLATE_NAME'),
        dataType: DataType[DataType.STRING],
        className: 'dpa-dg-col-lg',
      }),
      new Column({
        name: COLUMN_NAMES.byName.description,
        label: this.i18nService.translate('LIST_VIEW.COLUMNS.DESCRIPTION'),
        dataType: DataType[DataType.STRING],
        className: '',
      }),
      new Column({
        name: COLUMN_NAMES.byField.createdByDetails,
        label: this.i18nService.translate('LIST_VIEW.COLUMNS.ADDED_BY'),
        dataType: DataType[DataType.STRING],
        className: '',
      }),
      new Column({
        name: COLUMN_NAMES.byField.createdAt,
        label: this.i18nService.translate('LIST_VIEW.COLUMNS.ADDED_DATE'),
        dataType: DataType[DataType.DATETIME],
        className: '',
      }),
      new Column({
        name: COLUMN_NAMES.byField.modifiedAt,
        label: this.i18nService.translate('LIST_VIEW.COLUMNS.UPDATED_DATE'),
        dataType: DataType[DataType.DATETIME],
        className: '',
      }),
      new Column({
        name: COLUMN_NAMES.byName.actions,
        label: this.i18nService.translate('COMMON_MESSAGES.TAGS'),
        className: 'clr-justify-content-end dpa-dg-col-lg',
      }),
    ];
    this.additionalTags = [
      new Tag({
        name: 'type',
        label: this.i18nService.translate('CUSTOM_REPORTS_ACTIONS.SAVED_TEMPLATES_TAG'),
        type: TAGS_TYPE.CUSTOM_TEMPLATE,
      }),
    ];
  }

  /**
   * ngOnInit
   * @memberof ReportTemplateSearchComponent
   */
  public ngOnInit() {
    super.ngOnInit();
    this.sub.add(
      this.store
        .select(UserPreferenceFeatureControlsSelectors.isTimeseriesReportingEnabled)
        .subscribe((isTimeseriesReportingEnabled: boolean) => {
          this.isTimeseriesReportingEnabled = isTimeseriesReportingEnabled;
        }),
    );
  }

  /**
   * ngOnDestroy
   * @memberof ReportTemplateSearchComponent
   */
  public ngOnDestroy() {
    this.sub.unsubscribe();
    super.ngOnDestroy();
  }

  /**
   * onEditTemplate
   * @param {ReportTemplate} template
   * @memberof ReportTemplateSearchComponent
   */
  public onEditTemplate(template: ReportTemplate) {
    this.editTemplate.emit(template);
  }

  /**
   * onDeleteTemplate
   * @param {ReportTemplate} template
   * @memberof ReportTemplateSearchComponent
   */
  public onDeleteTemplate(template: ReportTemplate) {
    this.deleteTemplate.emit(template);
  }

  /**
   * showDataType
   * @param {ReportTemplate} template
   * @returns {boolean}
   * @memberof ReportTemplateSearchComponent
   */
  public showDataType(template: ReportTemplate): boolean {
    return this.isTimeseriesReportingEnabled && !template.isPredefinedReportTemplate;
  }

  /**
   * onAddTemplate
   * @memberof ReportTemplateSearchComponent
   */
  public onAddTemplate() {
    this.addTemplate.emit();
  }
}
