/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { BreadCrumb, WebError } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { Marker, MarkerContext } from '@ws1c/dashboard-common/chart';
import { isEditRangeAvailable } from '@ws1c/dashboard-common/utils';
import { DownloadService, I18NService } from '@ws1c/intelligence-common';
import { StandardChartComponent } from '@ws1c/intelligence-core/components/dashboard/standard-chart/standard-chart.component';
import { PageService } from '@ws1c/intelligence-core/services';
import {
  AutomationCommonActions,
  CoreAppState,
  DashboardActions,
  DashboardSelectors,
  DashboardTrendDefinitionOverridesSelectors,
  FeatureSelectors,
  UserPreferenceFeatureControlsSelectors,
} from '@ws1c/intelligence-core/store';
import {
  AggregationWidget,
  AggregationWidgetChartType,
  ChartDrilldownEvent,
  CLARITY_TOOLTIP_POSITION,
  CompositeTrendDefinition,
  CounterDefinition,
  DashboardConfig,
  FocusedSeries,
  NameValue,
  StandardDashboardCardSize,
  Tooltip,
  TooltipSeverity,
  Trend,
  TrendDefinition,
  TrendMode,
  TrendResult,
  WidgetColorSchema,
  WidgetDetailDefinition,
  WidgetDetailPageSkinType,
  WidgetPreference,
  WidgetRangeFilter,
} from '@ws1c/intelligence-models';

/**
 * StandardWidgetComponent
 * @export
 * @class StandardWidgetComponent
 * @implements {OnChanges}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-standard-widget',
  templateUrl: 'standard-widget.component.html',
  styleUrls: ['standard-widget.component.scss'],
})
export class StandardWidgetComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public chartData: Trend;
  @Input() public chartTitle: string;
  @Input() public activeChartType?: AggregationWidgetChartType = AggregationWidgetChartType.LINE;
  @Input() public showChartTypeSwitcher?: boolean = true;
  @Input() public disableViewLink?: boolean = false;
  @Input() public disableColumnSelector?: boolean = false;
  @Input() public detailsReturnCrumbs?: BreadCrumb[];
  @Input() public detailsChartTitleOverride?: string;
  @Input() public detailsCompositeTrendDefinition?: CompositeTrendDefinition;
  @Input() public detailsShowTable?: boolean = true;
  @Input() public groupedTableAttrs?: string[];
  @Input() public groupedTableCounters?: CounterDefinition[];
  @Input() public detailTableAttrs?: string[];
  @Input() public detailTableCounters?: CounterDefinition[];
  @Input() public showSeriesNames?: boolean = true;
  @Input() public tooltip?: string;
  @Input() public tooltipWarning?: string;
  @Input() public isPercentageOverride?: boolean;
  @Input() public stackedTooltip?: TemplateRef<any>;
  @Input() public detailsSkinType: WidgetDetailPageSkinType;
  @Input() public groupByTemplate?: TemplateRef<any>;
  @Input() public subheaderTemplate?: TemplateRef<any>;
  @Input() public cardBlockHeaderTemplate?: TemplateRef<any>;
  @Input() public cardBlockFooter?: TemplateRef<any>;
  @Input() public xAxisLabelOverride?: string;
  @Input() public yAxisLabelOverride?: string;
  @Input() public customColors?: any[];
  @Input() public noBucketingColor?: string;
  @Input() public outerActionsTemplate?: TemplateRef<any>;
  @Input() public extraActionsTemplate?: TemplateRef<any>;
  @Input() public customFooterTemplate?: TemplateRef<any>;
  @Input() public showAllAvailableLabels?: boolean = false;
  @Input() public noDataMessage?: string;
  @Input() public noDataTooltip?: string;
  @Input() public dropdownPosition?: string = CLARITY_TOOLTIP_POSITION.TOP_RIGHT;
  @Input() public widgetId: string;
  @Input() public widgetIdForUserPreference?: string;
  @Input() public showLabels?: boolean = true;
  @Input() public showXAxis?: boolean = true;
  @Input() public showYAxis?: boolean = true;
  @Input() public showXAxisLabel?: boolean = true;
  @Input() public showDataLabel?: boolean = true;
  @Input() public hideViewLink?: boolean = false;
  @Input() public showTimeline?: boolean = false;
  @Input() public showRefLines?: boolean = false;
  @Input() public showRefLabels?: boolean = true;
  @Input() public referenceLines?: Array<NameValue<number>>;
  @Input() public showSubHeader?: boolean = true;
  @Input() public showDateRangePicker?: boolean = true;
  @Input() public tableColumnNames?: string[];
  @Input() public tableColumnLabelsByName: Record<string, string> = {};
  @Input() public tableCellTemplatesByName?: Record<string, TemplateRef<any>>;
  @Input() public tableSettings?: {};
  @Input() public chartCurveType?: any = DashboardConfig.chartCurveTypes.Default;
  @Input() public isRangedData?: boolean = false;
  @Input() public trimXAxisTicks?: boolean = true;
  @Input() public yScaleMax?: number;
  @Input() public yScaleMin?: number;
  @Input() public isCountersClickable?: boolean = true;
  @Input() public size?: StandardDashboardCardSize = StandardDashboardCardSize.LG;
  @Input() public rotateXAxisTicks?: boolean = false;
  @Input() public xAxisLabelFactor?: number = 1;
  @Input() public dashboardAdditionalParams?: Record<string, any>;
  @Input() public showCopyTo?: boolean = true;
  @Input() public showCardFooter?: boolean = true;
  @Input() public markers?: Marker[];
  @Input() public markerTooltipTemplate?: TemplateRef<any>;
  @Input() public yAxisTickFormatting?: any;
  @Input() public yAxisTicks?: any[];
  @Input() public skipSort?: boolean = false;
  @Input() public hideSeriesTooltipSubtotal?: boolean = false;
  @Output() public onMarkerClick: EventEmitter<MarkerContext> = new EventEmitter();

  @ViewChild('standardChart') public standardChartComponent: StandardChartComponent;

  public focusedSeries$: Observable<FocusedSeries>;
  public drilldownEvents$: Observable<ChartDrilldownEvent[]>;
  public selectedChartType$: Observable<AggregationWidgetChartType>;
  public widgetId$: BehaviorSubject<string> = new BehaviorSubject(undefined);
  public isInvertMode$: Observable<boolean>;
  public hasAutomationWritePerm$: Observable<boolean>;
  public isControlBarVisible$: Observable<boolean>;
  public isControlBarExpanded: boolean = false;
  public isWidgetJoinEnabled: boolean = false;
  public isControlBarExpanded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isEditRangeDialogOpen$: Observable<boolean>;
  public isEditRangeAvailable$: Observable<boolean>;
  public rangeFilter$: Observable<WidgetRangeFilter>;
  public colorSchemas$: Observable<WidgetColorSchema[]>;

  public pageServiceReturnCrumbs: BreadCrumb[];
  public tooltips: Tooltip[];
  public selectedChartType = this.activeChartType;
  public CHART_TYPES = AggregationWidgetChartType;
  public dashboardName: string;
  public dashboardId: string;
  public isCrossCategory?: boolean = false;
  public isWidgetEditThemeEnabled: boolean = false;
  public isWidgetEditThemeAvailable: boolean = false;
  public widgetAttributePreferences: WidgetPreference[];

  public sub: Subscription = new Subscription();

  /**
   * constructor
   * @param {Store<CoreAppState>} store
   * @param {I18NService} i18nService
   * @param {DownloadService} downloadService
   * @param {PageService} pageService
   * @memberof StandardWidgetComponent
   */
  constructor(
    private store: Store<CoreAppState>,
    private i18nService: I18NService,
    private downloadService: DownloadService,
    private pageService: PageService,
  ) {
    this.hasAutomationWritePerm$ = this.store.select(FeatureSelectors.hasAutomationPerm);
    this.drilldownEvents$ = combineLatest([this.store.select(DashboardSelectors.getDrilldownEventsById), this.widgetId$]).pipe(
      map(([drilldownEventsById, widgetId]: [Record<string, ChartDrilldownEvent[]>, string]) => {
        return drilldownEventsById[widgetId]?.filter(
          (cdde: ChartDrilldownEvent) => cdde.setFocusedSeries !== undefined || cdde.selectedBuckets?.length || cdde.addFilters?.length,
        );
      }),
    );
    this.focusedSeries$ = combineLatest([this.store.select(DashboardSelectors.getFocusedSeriesById), this.widgetId$]).pipe(
      map(([focusedSeriesById, widgetId]: [Record<string, FocusedSeries>, string]) => focusedSeriesById[widgetId]),
    );

    this.selectedChartType$ = this.store
      .select(DashboardSelectors.getChartTypesById)
      .pipe(map((chartTypesById: Record<string, AggregationWidgetChartType>) => chartTypesById[this.widgetId] || this.activeChartType));

    this.isInvertMode$ = this.store
      .select(DashboardSelectors.getInvertModeById)
      .pipe(map((invertModeById: Record<string, boolean>) => invertModeById[this.widgetId] || false));

    this.isControlBarVisible$ = combineLatest([
      this.store.select(DashboardTrendDefinitionOverridesSelectors.getTrendDefinitionsById),
      this.widgetId$,
    ]).pipe(
      map(([trendDefinitionsById, widgetId]: [Record<string, TrendDefinition>, string]) => {
        return this.showSubHeader && !!trendDefinitionsById[widgetId];
      }),
    );

    this.isEditRangeDialogOpen$ = combineLatest([
      this.store.select(DashboardSelectors.isEditRangeDialogOpen),
      this.store.select(DashboardSelectors.getEditRangeWidgetId),
    ]).pipe(
      map(([isOpen, widgetId]: [boolean, string]) => {
        return isOpen && widgetId === this.widgetId;
      }),
    );

    this.rangeFilter$ = combineLatest([this.store.select(DashboardSelectors.getWidgetRangeFiltersById), this.widgetId$]).pipe(
      map(([rangeFiltersById, widgetId]: [Record<string, WidgetRangeFilter>, string]) => {
        return rangeFiltersById?.[widgetId];
      }),
    );

    this.colorSchemas$ = combineLatest([this.store.select(DashboardSelectors.getWidgetColorSchemasById), this.widgetId$]).pipe(
      map(([colorSchemasById, widgetId]: [Record<string, WidgetColorSchema[]>, string]) => {
        return colorSchemasById?.[widgetId];
      }),
    );

    this.isEditRangeAvailable$ = combineLatest([
      this.store.select(UserPreferenceFeatureControlsSelectors.isWidgetEditRangeEnabled),
      this.drilldownEvents$,
    ]).pipe(
      map(([isEnabled, drilldownEvents]: [boolean, ChartDrilldownEvent[]]) => {
        return (
          !this.chartData.trendDefinition.isCompositeTrend(this.widgetId) &&
          isEditRangeAvailable(isEnabled, drilldownEvents, this.chartData)
        );
      }),
    );
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof StandardWidgetComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.tooltip || changes.tooltipWarning) {
      this.setTooltips();
    }
    if (changes.activeChartType) {
      this.selectedChartType = this.activeChartType;
    }
    if (changes.detailsReturnCrumbs?.currentValue?.length) {
      const firstCrumb = this.detailsReturnCrumbs[0];
      this.dashboardName = this.i18nService.translate(firstCrumb.pathLabelKey);
    }
    if (changes.widgetId) {
      this.widgetId$.next(this.widgetId);
    }
    if (changes.chartData) {
      this.isWidgetEditThemeAvailable = !!(
        this.isWidgetEditThemeEnabled &&
        this.chartData?.trendDefinition?.bucketingAttributes?.length &&
        this.chartData?.trendResults?.some((trendResult: TrendResult) => trendResult.counters[0].result.value > 0)
      );
    }
  }

  /**
   * ngOnInit
   * @memberof StandardWidgetComponent
   */
  public ngOnInit() {
    this.sub.add(
      this.store.select(DashboardSelectors.getCurrentDashboard).subscribe((dashboardId: string) => {
        this.dashboardId = dashboardId;
      }),
    );
    this.sub.add(
      combineLatest([this.store.select(DashboardSelectors.getWidgetIdToDashboardIdMap), this.widgetId$]).subscribe(
        ([mergedDashboardWidgetMap, widgetId]: [Record<string, string>, string]) => {
          const curWidgetId: string = this.widgetIdForUserPreference ?? widgetId;
          if (mergedDashboardWidgetMap?.[curWidgetId]) {
            this.dashboardId = mergedDashboardWidgetMap?.[curWidgetId];
          }
        },
      ),
    );
    this.sub.add(
      this.store.select(DashboardSelectors.isCrossCategoryActive).subscribe((isCrossCategory: boolean) => {
        this.isCrossCategory = isCrossCategory;
      }),
    );
    this.sub.add(
      this.store.select(UserPreferenceFeatureControlsSelectors.isWidgetEditThemeEnabled).subscribe((isWidgetEditThemeEnabled: boolean) => {
        this.isWidgetEditThemeEnabled = isWidgetEditThemeEnabled;
      }),
    );
    this.sub.add(
      combineLatest([this.store.select(DashboardSelectors.getWidgetAttributePreferencesById), this.widgetId$]).subscribe(
        ([widgetAttributePreferencesById, widgetId]: [Record<string, WidgetPreference[]>, string]) => {
          this.widgetAttributePreferences = widgetAttributePreferencesById?.[widgetId];
        },
      ),
    );
    this.sub.add(
      this.pageService.returnBreadCrumbs$.subscribe((breadCrumbs: BreadCrumb[]) => {
        this.pageServiceReturnCrumbs = breadCrumbs;
      }),
    );
    this.sub.add(
      this.store.select(UserPreferenceFeatureControlsSelectors.isWidgetJoinEnabled).subscribe((isWidgetJoinEnabled: boolean) => {
        this.isWidgetJoinEnabled = isWidgetJoinEnabled;
      }),
    );
  }

  /**
   * ngOnDestroy
   * @memberof StandardWidgetComponent
   */
  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /**
   * setTooltips
   * @memberof StandardWidgetComponent
   */
  public setTooltips() {
    this.tooltips = [];
    if (this.tooltip) {
      this.tooltips.push({
        textKey: this.tooltip,
        severity: TooltipSeverity.INFO,
      } as Tooltip);
    }
    if (this.tooltipWarning) {
      this.tooltips.push({
        textKey: this.tooltipWarning,
        severity: TooltipSeverity.WARNING,
      } as Tooltip);
    }
  }

  /**
   * pushDrilldownEvent
   * @param {ChartDrilldownEvent} drilldownEvent
   * @memberof StandardWidgetComponent
   */
  public pushDrilldownEvent(drilldownEvent: ChartDrilldownEvent) {
    this.store.dispatch(
      DashboardActions.pushDrilldownEvent({
        widgetId: this.widgetId,
        drilldownEvent,
      }),
    );
  }

  /**
   * goToWidgetDetail
   * @memberof StandardWidgetComponent
   */
  public goToWidgetDetail() {
    const dashboardName = this.dashboardName ?? this.pageServiceReturnCrumbs[0]?.pathLabelKey;
    const returnCrumbs = this.detailsReturnCrumbs ?? this.pageServiceReturnCrumbs;

    const widgetDetailDefinition = new WidgetDetailDefinition({
      widgetId: this.widgetIdForUserPreference ?? this.widgetId,
      dashboardName,
      dashboardId: this.dashboardId,
      chartTitle: this.detailsChartTitleOverride || this.chartTitle,
      trendDefinition: this.detailsCompositeTrendDefinition ? undefined : this.chartData.trendDefinition,
      compositeTrendDefinition: this.detailsCompositeTrendDefinition,
      returnCrumbs,
      chartType: this.selectedChartType,
      showSeriesNames: this.showSeriesNames,
      showTable: this.detailsShowTable,
      groupedTableAttrs: this.groupedTableAttrs,
      groupedTableCounters: this.groupedTableCounters,
      detailTableAttrs: this.detailTableAttrs,
      detailTableCounters: this.detailTableCounters,
      yAxisLabelOverride: this.yAxisLabelOverride,
      customColors: this.customColors,
      noBucketingColor: this.noBucketingColor,
      disableColumnSelector: this.disableColumnSelector,
      isCrossCategory: this.isCrossCategory,
      additionalParams: this.dashboardAdditionalParams,
    });

    // widgetId is needed to retrieve drilldownEvents and original trend definition
    this.store.dispatch(
      DashboardActions.goToWidgetIdDetailPage({
        widgetDetailPage: {
          widgetDetailDefinition,
          drilldownEvents: [],
          skinType: this.detailsSkinType,
        },
      }),
    );
  }

  /**
   * automateWidget
   * @memberof StandardWidgetComponent
   */
  public automateWidget() {
    const lastCrumb = this.detailsReturnCrumbs?.[this.detailsReturnCrumbs.length - 1];
    this.store.dispatch(
      AutomationCommonActions.automateFromStandardDashboard({
        dashboardName: this.dashboardName,
        widgetName: this.chartTitle,
        trendDefinition: this.chartData.trendDefinition,
        returnUrl: lastCrumb && lastCrumb.pathUrl,
        detailsSkinType: this.detailsSkinType,
      }),
    );
  }

  /**
   * expandOrCollpaseControlBar
   * @memberof WidgetComponent
   */
  public expandOrCollapseControlBar() {
    this.isControlBarExpanded = !this.isControlBarExpanded;
    // Since the collapsed section is using dpa-fluid-height to add animation, and the chart type select component
    // is using dpa-fluid-height as well, the embeded dpa-fluid-height cannot be hidden successfully when outside
    // dpa-fluid-height tries to hide (Because dpa-fluid-height is using visibility property in css to show/hide
    // the component, the child css property would override the parent ones.) Using this delayed observable to make
    // sure chart type option is hidden when collapsing the control bar section and annimation is visible.
    setTimeout(() => {
      this.isControlBarExpanded$.next(this.isControlBarExpanded);
    }, 200);
  }

  /**
   * selectChartType
   * @param {string} chartType
   * @memberof StandardWidgetComponent
   */
  public selectChartType(chartType: string) {
    this.selectedChartType = AggregationWidgetChartType[chartType];
  }

  /**
   * exportAsCsv
   * @memberof StandardWidgetComponent
   */
  public exportAsCsv() {
    this.downloadService.downloadAsCsv(
      this.standardChartComponent.getCsvData(),
      this.detailsChartTitleOverride || this.chartTitle || this.widgetId,
    );
  }

  /**
   * popDrilldown
   * @memberof StandardWidgetComponent
   */
  public popDrilldown() {
    this.store.dispatch(DashboardActions.popDrilldownEvent({ widgetId: this.widgetId }));
  }

  /**
   * clearDrilldown
   * @memberof StandardWidgetComponent
   */
  public clearDrilldown() {
    this.store.dispatch(DashboardActions.clearDrilldownEvents({ widgetId: this.widgetId }));
  }

  /**
   * getWebError
   * @returns {WebError}
   * @memberof StandardWidgetComponent
   */
  public getWebError(): WebError {
    return new WebError([this.chartData.errorMessage]);
  }

  /**
   * requestCopyToWidget
   * @memberof StandardWidgetComponent
   */
  public requestCopyToWidget() {
    const widget: AggregationWidget = new AggregationWidget({
      name: this.chartTitle,
      description: this.tooltip,
      chartType: this.CHART_TYPES[this.activeChartType],
      trend: new Trend({
        trendDefinition: cloneDeep(this.chartData.trendDefinition),
      }),
    });
    if (widget.trend.trendDefinition.trendMode === TrendMode[TrendMode.HISTORICAL] && widget.trend.trendDefinition.dateRange?.trendSpan) {
      widget.trend.trendDefinition.dateRange.startDateMillis = 0;
      widget.trend.trendDefinition.dateRange.endDateMillis = 0;
    }
    this.store.dispatch(DashboardActions.requestCopyWidget({ widget }));
    this.store.dispatch(DashboardActions.loadActiveDashboard());
  }

  /**
   * isCopyVisible
   * @returns {boolean}
   * @memberof StandardWidgetComponent
   */
  public isCopyVisible(): boolean {
    return !this.chartData.trendDefinition.isCopyDisabled(this.widgetId) && this.showCopyTo;
  }

  /**
   * onEditTheme
   * @memberof StandardWidgetComponent
   */
  public onEditTheme() {
    const widget: AggregationWidget = new AggregationWidget({
      id: this.widgetId,
      dashboardId: this.dashboardId,
      name: this.chartTitle,
      description: this.tooltip,
      chartType: this.CHART_TYPES[this.activeChartType],
      trend: new Trend({
        trendDefinition: cloneDeep(this.chartData.trendDefinition),
      }),
      widgetAttributePreferences: this.widgetAttributePreferences,
    });
    this.store.dispatch(
      DashboardActions.requestEditThemeWidget({
        widget,
        widgetTheme: this.standardChartComponent.getWidgetTheme(),
      }),
    );
  }

  /**
   * openEditRangeDialog
   * @param {boolean} isOpen
   * @memberof StandardWidgetComponent
   */
  public openEditRangeDialog(isOpen: boolean) {
    this.store.dispatch(
      DashboardActions.openEditRangeDialog({
        isOpen,
        groupByLabel: this.standardChartComponent.getYAxisLabelString(),
        editRangeWidgetId: this.widgetId,
      }),
    );
  }

  /**
   * handleMarkerClick
   * @param {MarkerContext} event
   * @memberof StandardWidgetComponent
   */
  public handleMarkerClick(event: MarkerContext) {
    this.onMarkerClick.emit(event);
  }
}
