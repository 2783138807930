/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { unsubscribe } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { I18NService } from '@ws1c/intelligence-common';
import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { IntegrationMetaSelectors } from '@ws1c/intelligence-core/store/integration-meta';
import { Category, CLARITY_TOOLTIP_POSITION, Trend } from '@ws1c/intelligence-models';

/**
 * WidgetTagComponent
 *
 * @export
 * @class WidgetTagComponent
 * @implements {OnChanges}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-widget-tag',
  templateUrl: 'widget-tag.component.html',
  styleUrls: ['widget-tag.component.scss'],
})
export class WidgetTagComponent implements OnChanges, OnDestroy, OnInit {
  public tagNames: string[] = [];
  public tagLabelsByName: any = {};
  public integrationCategories$: Observable<Category[]>;
  public subs: Subscription[];
  public tagTooltipsByName: any = {
    HISTORICAL: this.i18nService.translate('WIDGET_TEMPLATE.HISTORICAL'),
    SNAPSHOT: this.i18nService.translate('WIDGET_TEMPLATE.SNAPSHOT'),
  };

  @Input() public trend: Trend;
  @Input() public maxNumTags?: number = 2;
  @Input() public isMinimized?: boolean = false;

  public readonly CLARITY_TOOLTIP_POSITION = CLARITY_TOOLTIP_POSITION;

  /**
   * Creates an instance of WidgetTagComponent.
   * @param {Store<CoreAppState>} store
   * @param {I18NService} i18nService
   * @memberof WidgetTagComponent
   */
  constructor(
    private store: Store<CoreAppState>,
    private i18nService: I18NService,
  ) {
    this.integrationCategories$ = this.store.select(IntegrationMetaSelectors.getAvailableCategoriesState);
    this.tagLabelsByName = {
      HISTORICAL: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_MODE_HISTORICAL'),
      SNAPSHOT: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_MODE_SNAPSHOT'),
      SNAPSHOT_PERIODICAL: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_MODE_SNAPSHOT_PERIODICAL'),
    };
  }

  /**
   * ngOnInit
   * @memberof WidgetTagComponent
   */
  public ngOnInit() {
    this.subs = [
      this.integrationCategories$.subscribe((categories: Category[]) => {
        if (categories) {
          categories.forEach((cat: Category) => {
            this.tagLabelsByName[cat.categoryId] = cat.fullLabel;
          });
        }
      }),
    ];
  }

  /**
   * ngOnDestroy
   * @memberof WidgetTagComponent
   */
  public ngOnDestroy() {
    unsubscribe(this.subs);
  }

  /**
   * ngOnChanges
   * @memberof WidgetTagComponent
   * @param {SimpleChanges} changes
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.trend && changes.trend.currentValue) {
      this.tagNames = this.buildTags(changes.trend.currentValue);
    }
  }

  /**
   * buildTags
   * @param {Trend} currentTrend
   * @returns {string[]}
   * @memberof WidgetTagComponent
   */
  public buildTags(currentTrend: Trend): string[] {
    if (!currentTrend.trendDefinition) {
      return [];
    }
    const categories = currentTrend.trendDefinition.categories || [];
    const trendMode = currentTrend.trendDefinition.trendMode ? [currentTrend.trendDefinition.trendMode] : [];
    return [...trendMode, ...categories].map((key: string) => key.toUpperCase());
  }

  /**
   * getTooltipText
   * @memberof WidgetTagComponent
   * @param {string} tooltipName
   * @returns {string}
   */
  public getTooltipText(tooltipName: string): string {
    return this.tagTooltipsByName[tooltipName] || '';
  }
}
