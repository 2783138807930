/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseFormFieldComponent } from '@ws1c/intelligence-core/components/dynamic-form/components/form-field/components/base-form-field.component';
import { MetaFormConfig } from '@ws1c/intelligence-models';

/**
 * UrlFormFieldComponent
 * @export
 * @class UrlFormFieldComponent
 * @extends {BaseFormFieldComponent}
 */
@Component({
  selector: 'dpa-url-form-field',
  templateUrl: 'url-form-field.component.html',
  styleUrls: ['url-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UrlFormFieldComponent extends BaseFormFieldComponent {
  public readonly MetaFormConfig = MetaFormConfig;
}
