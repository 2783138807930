/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { UserPreferenceTrialInfoSelectors } from '@ws1c/intelligence-core/store/user-preference';

/**
 * Preview Tag View
 * @export
 * @class PreviewTagComponent
 */
@Component({
  selector: 'dpa-preview-tag',
  templateUrl: 'preview-tag.component.html',
  styleUrls: ['preview-tag.component.scss'],
})
export class PreviewTagComponent {
  @Input() public expired: boolean;
  public isPreviewTag$: Observable<boolean>;

  /**
   * Creates an instance of PreviewTagComponent.
   * @param {Store<CoreAppState>} store
   * @memberof PreviewTagComponent
   */
  constructor(public store: Store<CoreAppState>) {
    this.isPreviewTag$ = this.store.select(UserPreferenceTrialInfoSelectors.isTrialApplicableAndActiveOrExpired);
  }
}
