/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { TrendDateRange } from '@ws1c/intelligence-models';

/**
 * Defines UI State for UAG Tunnel Dashboard Feature
 *
 * @export
 * @interface UagTunnelDashboardState
 */
export interface UagTunnelDashboardState {
  trendDateRange: TrendDateRange;
}

export const initialUagTunnelDashboardState: UagTunnelDashboardState = {
  trendDateRange: undefined,
};
