/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { Automation, AutomationTemplate } from '@ws1c/intelligence-models';

/**
 * Automation Trigger Action Component View
 * Display only available connections used by automation on card/list view
 * @export
 * @class AutomationTriggerActionComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-automation-trigger-action',
  templateUrl: 'automation-trigger-action.component.html',
  styleUrls: ['automation-trigger-action.component.scss'],
})
export class AutomationTriggerActionComponent implements OnChanges {
  public static readonly MAX_VISIBLE_LIST_COUNT = 3;
  @Input() public automation?: Automation;
  @Input() public automationTemplate?: AutomationTemplate;
  @Input() public isTemplate?: boolean = false;

  public initialList: Array<{ trigger: string; size: number }> = [];
  public overflowCount: number = 0;

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof AutomationTriggerActionComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    const { automation, automationTemplate } = changes;
    if (
      (!automation?.currentValue || automation?.currentValue === automation?.previousValue) &&
      (!automationTemplate?.currentValue || automationTemplate?.currentValue === automationTemplate?.previousValue)
    ) {
      return;
    }
    this.initOverflowCountAndInitialList();
  }

  /**
   * initOverflowCountAndInitialList
   * @memberof AutomationTriggerActionComponent
   */
  private initOverflowCountAndInitialList() {
    this.initialList = [];
    this.overflowCount = 0;

    const templateData = this.automationTemplate?.connectorIds;
    const dataForBucketization = this.isTemplate ? templateData : [];
    if (!dataForBucketization?.length) {
      return;
    }

    // Bucket repeated service IDs
    const buckets = dataForBucketization.reduce<string[][]>((acc, item) => {
      const prev = acc.length ? acc[acc.length - 1] : null;
      if (prev && prev[0] === item) {
        prev.push(item);
      } else {
        acc.push([item]);
      }
      return acc;
    }, []);

    let totalVisible = 0;
    this.initialList = buckets.reduce<Array<{ trigger: string; size: number }>>((acc, bucket) => {
      if (acc.length < AutomationTriggerActionComponent.MAX_VISIBLE_LIST_COUNT) {
        acc.push({
          trigger: bucket[0],
          size: bucket.length,
        });
        totalVisible += bucket.length;
      }

      return acc;
    }, []);
    this.overflowCount = dataForBucketization.length - totalVisible;
  }
}
