/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { CommonErrorCode, WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import { MarketplaceService, TemplateService } from '@ws1c/intelligence-core/services';
import { AlertBannerActions, MarketplaceSelectors, TemplateCommonActions } from '@ws1c/intelligence-core/store';
import { MarketplaceActions } from '@ws1c/intelligence-core/store/marketplace/marketplace.actions';
import {
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  AutomationTemplate,
  DashboardTemplateImport,
  DashboardView,
  MarketplaceResourceType,
  MarketplaceSearchRequest,
  MarketplaceSearchResponse,
  ReportTemplate,
  ROUTE_NAMES,
} from '@ws1c/intelligence-models';

/**
 * MarketplaceEffects
 *
 * @export
 * @class MarketplaceEffects
 */
@Injectable()
export class MarketplaceEffects {
  /**
   * searchMarketplace$
   * @type {Observable<Action>}
   * @memberof MarketplaceEffects
   */
  public searchMarketplace$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MarketplaceActions.searchMarketplace),
      mergeMap(({ request, previewResource }: ReturnType<typeof MarketplaceActions.searchMarketplace>) => {
        let resourceType: MarketplaceResourceType;
        if (request.resourceTypes?.length === 1) {
          resourceType = request.resourceTypes[0];
        }
        return this.marketplaceService.searchMarketplace(request).pipe(
          map((response: MarketplaceSearchResponse) =>
            MarketplaceActions.searchMarketplaceSuccess({ response, previewResource, resourceType }),
          ),
          catchError((error: WebError) => of(MarketplaceActions.searchMarketplaceFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * addToWorkspace$
   * @type {Observable<Action>}
   * @memberof MarketplaceEffects
   */
  public addToWorkspace$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MarketplaceActions.addToWorkspace),
      switchMap(({ resource, name, description, navigateToWorkspace }: ReturnType<typeof MarketplaceActions.addToWorkspace>) => {
        switch (resource?.resourceType) {
          case MarketplaceResourceType.REPORT_TEMPLATE: {
            return this.templateService.getReportTemplateByName(resource.integration, resource.entity, resource.resourceLookupKey).pipe(
              switchMap((template: ReportTemplate) => [
                MarketplaceActions.addToWorkspaceSuccess({ resource }),
                TemplateCommonActions.goToCreateReportFromTemplate({
                  templateId: template.templateId,
                  sourceTemplateId: resource.sourceTemplateId,
                }),
              ]),
              catchError(() => [
                MarketplaceActions.addToWorkspaceFailure(),
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  message: this.i18nService.translate('MARKETPLACE.FAILED_TO_LOAD_TEMPLATE'),
                }),
              ]),
            );
          }
          case MarketplaceResourceType.AUTOMATION_TEMPLATE: {
            return this.templateService
              .getAutomationTemplateByNameV3(resource.integration, resource.entity, resource.resourceLookupKey)
              .pipe(
                switchMap((template: AutomationTemplate) => [
                  MarketplaceActions.addToWorkspaceSuccess({ resource }),
                  TemplateCommonActions.goToCreateAutomationFromTemplate({
                    templateId: template.templateId,
                    sourceTemplateId: resource.sourceTemplateId,
                  }),
                ]),
                catchError(() => [
                  MarketplaceActions.addToWorkspaceFailure(),
                  AlertBannerActions.showAlertBanner({
                    alertType: ALERT_BANNER_TYPE.DANGER,
                    message: this.i18nService.translate('MARKETPLACE.FAILED_TO_LOAD_TEMPLATE'),
                  }),
                ]),
              );
          }
          case MarketplaceResourceType.DASHBOARD_TEMPLATE: {
            return this.templateService
              .importDashboardTemplates([
                new DashboardTemplateImport({
                  templateId: resource.resourceLookupKey,
                  name,
                  description,
                }),
              ])
              .pipe(
                switchMap((dashboards: DashboardView[]) => {
                  const dashboard = dashboards[0];
                  if (navigateToWorkspace) {
                    this.routerExtensions.navigate([ROUTE_NAMES.DASHBOARD.MY_DASHBOARDS]);
                  }
                  const alertAction = {
                    actionLink: `#/${ROUTE_NAMES.DASHBOARD.MY_DASHBOARDS}`,
                    actionText: this.i18nService.translate('MARKETPLACE.GO_TO_WORKSPACE'),
                  };
                  return [
                    MarketplaceActions.addToWorkspaceSuccess({ resource }),
                    AlertBannerActions.showAlertBanner({
                      hideAfter: 10000,
                      alertType: ALERT_BANNER_TYPE.SUCCESS,
                      message: this.i18nService.translate('MARKETPLACE.RESOURCE_ADDED_TO_WORKSPACE', { name: dashboard.name }),
                      ...(navigateToWorkspace ? {} : alertAction),
                    }),
                  ];
                }),
                catchError((error: WebError) => {
                  const reason = error.getFullReason({
                    [CommonErrorCode.DUPLICATE_KEY]: this.i18nService.translate(
                      'DASHBOARD_ACTIONS.SAVE_UPDATE_DASHBOARD_KEY_DUPLICATE_ERROR',
                    ),
                  });
                  return [
                    MarketplaceActions.addToWorkspaceFailure(),
                    AlertBannerActions.showAlertBanner({
                      alertType: ALERT_BANNER_TYPE.DANGER,
                      message: this.i18nService.translate('MARKETPLACE.FAILED_TO_ADD_TEMPLATE', { reason }),
                      target: AlertBannerTarget.MODAL,
                    }),
                  ];
                }),
              );
          }
        }
      }),
    ),
  );

  /**
   * setPreviewResource$
   * @type {Observable<Action>}
   * @memberof MarketplaceEffects
   */
  public setPreviewResource$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MarketplaceActions.setPreviewResource,
        MarketplaceActions.selectNextPreviewResource,
        MarketplaceActions.selectPrevPreviewResource,
      ),
      withLatestFrom(this.store.select(MarketplaceSelectors.getResourcePreviewRequest)),
      switchMap(([{ resource }, request]: [ReturnType<typeof MarketplaceActions.setPreviewResource>, MarketplaceSearchRequest]) => {
        if (!request) {
          return [];
        }
        return [
          MarketplaceActions.searchMarketplace({
            request,
            previewResource: resource,
          }),
        ];
      }),
    ),
  );

  /**
   * fetchMarketplaceResourceCounts$
   * @type {Observable<Action>}
   * @memberof MarketplaceEffects
   */
  public fetchMarketplaceResourceCounts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MarketplaceActions.fetchMarketplaceResourceCounts),
      switchMap(({ request }: ReturnType<typeof MarketplaceActions.searchMarketplace>) => {
        return this.marketplaceService.searchMarketplace(request).pipe(
          map((response: MarketplaceSearchResponse) => {
            const resourceCountByType: Partial<Record<MarketplaceResourceType, number>> = {};
            response.facetCountsByType.RESOURCE_TYPE.forEach((resource) => {
              resourceCountByType[resource.fieldValue] = resource.count;
            });
            return MarketplaceActions.fetchMarketplaceResourceCountsSuccess({ resourceCountByType });
          }),
          catchError((error: WebError) => of(MarketplaceActions.fetchMarketplaceResourceCountsFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * Creates an instance of MarketplaceEffects.
   * @param {Actions} actions$
   * @param {Store} store
   * @param {MarketplaceService} marketplaceService
   * @param {TemplateService} templateService
   * @param {I18NService} i18nService
   * @param {RouterExtensions} routerExtensions
   * @memberof MarketplaceEffects
   */
  constructor(
    private actions$: Actions,
    private store: Store,
    private marketplaceService: MarketplaceService,
    private templateService: TemplateService,
    private i18nService: I18NService,
    private routerExtensions: RouterExtensions,
  ) {}
}
