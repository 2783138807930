/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { OsUpdatesDashboardActions } from './os-updates-dashboard.actions';
import { initialOsUpdatesDashboardState, OsUpdatesDashboardState } from './os-updates-dashboard.state';

const _reducer: ActionReducer<OsUpdatesDashboardState> = createReducer(
  initialOsUpdatesDashboardState,

  on(
    OsUpdatesDashboardActions.setOsUpdateDevicePlatformSuggestionSearch,
    (
      state: OsUpdatesDashboardState,
      { suggestionSearch }: ReturnType<typeof OsUpdatesDashboardActions.setOsUpdateDevicePlatformSuggestionSearch>,
    ): OsUpdatesDashboardState => ({
      ...state,
      suggestionSearch,
    }),
  ),

  on(
    OsUpdatesDashboardActions.setOsUpdateDevicePlatform,
    (
      state: OsUpdatesDashboardState,
      { platform }: ReturnType<typeof OsUpdatesDashboardActions.setOsUpdateDevicePlatform>,
    ): OsUpdatesDashboardState => ({
      ...state,
      platform,
    }),
  ),

  on(
    OsUpdatesDashboardActions.updateOsVersionsDashboardFilters,
    (
      state: OsUpdatesDashboardState,
      { ruleGroup, trendDateRange }: ReturnType<typeof OsUpdatesDashboardActions.updateOsVersionsDashboardFilters>,
    ): OsUpdatesDashboardState => ({
      ...state,
      versions: {
        ...state.versions,
        filterRuleGroup: ruleGroup,
        trendDateRange,
      },
    }),
  ),

  on(
    OsUpdatesDashboardActions.updateOsPatchesDashboardFilters,
    (
      state: OsUpdatesDashboardState,
      { ruleGroup, trendDateRange }: ReturnType<typeof OsUpdatesDashboardActions.updateOsPatchesDashboardFilters>,
    ): OsUpdatesDashboardState => ({
      ...state,
      patches: {
        ...state.patches,
        filterRuleGroup: ruleGroup,
        trendDateRange,
      },
    }),
  ),
);

/**
 * osUpdatesDashboardState
 * @param {OsUpdatesDashboardState} state
 * @param {Action} action
 * @returns {OsUpdatesDashboardState}
 */
export function osUpdatesDashboardState(state: OsUpdatesDashboardState, action: Action): OsUpdatesDashboardState {
  return _reducer(state, action);
}
