/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Tab } from '@dpa/ui-common';

import { CustomReportPreviewSearchResponse, DeviceUser, PreviewReportContentRequest, TrendDateRange } from '@ws1c/intelligence-models';

/**
 * UserProfileAdditionalParams
 * @export
 * @interface UserProfileAdditionalParams
 */
export interface UserProfileAdditionalParams {
  user_guid: string;
  user_name: string;
}

/**
 * Defines UI State for User Profile
 *
 * @export
 * @interface UserProfileInterface
 */
export interface UserProfileInterface {
  user: DeviceUser;
  trendDateRange: TrendDateRange;
  experienceDeviceRequests: PreviewReportContentRequest[];
  experienceDeviceResponses: CustomReportPreviewSearchResponse[];
  experienceMobileDeviceRequest: PreviewReportContentRequest;
  experienceMobileDeviceResponse: CustomReportPreviewSearchResponse;
  experienceAppRequests: PreviewReportContentRequest[];
  experienceAppResponses: CustomReportPreviewSearchResponse[];
}

/**
 * initialUserProfileInterface
 */
export const initialUserProfileInterface: UserProfileInterface = {
  user: undefined,
  trendDateRange: undefined,
  experienceDeviceRequests: [],
  experienceDeviceResponses: [],
  experienceMobileDeviceRequest: undefined,
  experienceMobileDeviceResponse: undefined,
  experienceAppRequests: [],
  experienceAppResponses: [],
};

/**
 * Defines UI State for User Profile Feature
 *
 * @export
 * @interface UserProfileState
 */
export interface UserProfileState {
  appDetailUser: string;
  appDetailUserProfileTab: string;
  appDetailUserProfileVisibleTabs: Tab[];
  userProfile: UserProfileInterface;
}

/**
 * initialUserProfileState
 */
export const initialUserProfileState: UserProfileState = {
  appDetailUser: undefined,
  appDetailUserProfileTab: undefined,
  appDetailUserProfileVisibleTabs: [],
  userProfile: initialUserProfileInterface,
};
