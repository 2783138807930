/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { GenericObject } from '@dpa/ui-common';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';

import { FilterRuleConfig } from '@ws1c/intelligence-models';

/**
 * IPValueSelectorComponent
 * @export
 * @class IPValueSelectorComponent
 * @implements {OnChanges}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-ip-value-selector',
  templateUrl: 'ip-value-selector.component.html',
  styleUrls: ['ip-value-selector.component.scss'],
})
export class IPValueSelectorComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public selectedValues?: string[] = [];
  @Input() public rangeSelection?: boolean = false;
  @Input() public listSelection?: boolean = false;
  @Output() public onChange = new EventEmitter<string | string[]>();

  public selectedItems: GenericObject[] = [];
  public formGroup: FormGroup;
  public subs = new Subscription();

  public readonly validators: ValidatorFn[] = [Validators.required, Validators.pattern(FilterRuleConfig.IPV4_PATTERN)];

  /**
   * Creates an instance of IPValueSelectorComponent.
   * @memberof IPValueSelectorComponent
   */
  constructor() {
    this.formGroup = new FormGroup({
      ipStartValue: new FormControl('', this.validators),
    });
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof IPValueSelectorComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    // Add form-control for ipEndValue when range selection is enabled otherwise remove it
    if (changes.rangeSelection) {
      this.modifyFormControlForRangeSelection();
    }
    // Set initial values
    if (changes.selectedValues) {
      this.patchValues();
    }
  }

  /**
   * ngOnInit
   * @memberof IPValueSelectorComponent
   */
  public ngOnInit() {
    this.subs.add(
      this.formGroup.valueChanges
        .pipe(
          distinctUntilChanged((prev: GenericObject, next: GenericObject) => {
            return prev?.ipStartValue === next?.ipStartValue && prev?.ipEndValue === next?.ipEndValue;
          }),
          debounceTime(30),
        )
        .subscribe((values: GenericObject) => {
          if (values?.ipStartValue && values?.ipEndValue) {
            this.onChange.emit([values.ipStartValue, values.ipEndValue]);
          } else {
            this.onChange.emit(values.ipStartValue);
          }
        }),
    );
  }

  /**
   * ngOnDestroy
   * @memberof IPValueSelectorComponent
   */
  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * wrapValue
   * @param {string} value
   * @returns {GenericObject}
   * @memberof IPValueSelectorComponent
   */
  public wrapValue(value: string): GenericObject {
    return { value };
  }

  /**
   * unwrapValue
   * @param {GenericObject} item
   * @returns {string}
   * @memberof IPValueSelectorComponent
   */
  public unwrapValue(item: GenericObject): string {
    return item.value;
  }

  /**
   * onSelectedItemsChange
   * @param {GenericObject[]} selectedItems
   * @memberof IPValueSelectorComponent
   */
  public onSelectedItemsChange(selectedItems: GenericObject[]) {
    const values: string[] = selectedItems.map((item: GenericObject) => this.unwrapValue(item));
    this.onChange.emit(values);
  }

  /**
   * modifyFormControlForRangeSelection
   * @private
   * @memberof IPValueSelectorComponent
   */
  private modifyFormControlForRangeSelection() {
    if (this.rangeSelection) {
      this.formGroup.addControl('ipEndValue', new FormControl('', this.validators));
    } else {
      this.formGroup.removeControl('ipEndValue');
    }
  }

  /**
   * patchValues
   * @private
   * @memberof IPValueSelectorComponent
   */
  private patchValues() {
    if (this.listSelection) {
      this.selectedItems = this.selectedValues.map((value) => {
        return { value, error: !FilterRuleConfig.IPV4_PATTERN.test(value) };
      });
    } else {
      if (this.selectedValues?.[0]) {
        this.formGroup.get('ipStartValue')?.setValue(this.selectedValues[0]);
      }
      if (this.selectedValues?.[1]) {
        this.formGroup.get('ipEndValue')?.setValue(this.selectedValues[1]);
      }
    }
  }
}
