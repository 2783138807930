/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { createAction, props } from '@ngrx/store';

import { TrendDateRange } from '@ws1c/intelligence-models';

/**
 * UagTunnelDashboardActions
 * @export
 * @class UagTunnelDashboardActions
 */
export class UagTunnelDashboardActions {
  public static readonly CATEGORY = '[DASHBOARD][UAG_TUNNEL]';

  public static loadDashboard = createAction(`${UagTunnelDashboardActions.CATEGORY} LOAD_UAG_TUNNEL_DASHBOARD`);

  public static setTrendDateRange = createAction(
    `${UagTunnelDashboardActions.CATEGORY} SET_UAG_TUNNEL_TREND_DATE_RANGE`,
    props<{ trendDateRange: TrendDateRange }>(),
  );
}
