/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { ActiveWidgetCopyModalComponent } from './active-widget-copy-modal/active-widget-copy-modal.component';
import { ActiveWidgetModalComponent } from './active-widget-modal/active-widget-modal.component';
import { ColumnAttributeSelectorComponent } from './column-attribute-selector/column-attribute-selector.component';
import { DashboardSelectorComponent } from './dashboard-selector/dashboard-selector.component';
import { EditThemeModalComponent } from './edit-theme-modal/edit-theme-modal.component';
import { LegendComponent } from './legend/legend.component';
import { RiskIndicatorDetailsComponent } from './risk-indicator-details/risk-indicator-details.component';
import { StandardChartComponent } from './standard-chart/standard-chart.component';
import { StandardDashboardCardComponent } from './standard-dashboard-card/standard-dashboard-card.component';
import { StandardWidgetComponent } from './standard-widget/standard-widget.component';
import { TableChartComponent } from './table-chart/table-chart.component';
import { TreeMapDrilldownComponent } from './tree-map-drilldown/tree-map-drilldown.component';
import { UsageCardComponent } from './usage-card/usage-card.component';
import { WidgetComponent } from './widget/widget.component';
import { WidgetControlBarComponent } from './widget-control-bar/widget-control-bar.component';
import { WidgetEditRangeDialogComponent } from './widget-edit-range-dialog/widget-edit-range-dialog.component';
import { WidgetTagComponent } from './widget-tag/widget-tag.component';
import { WidgetTagFilterComponent } from './widget-tag-filter/widget-tag-filter.component';

export const DASHBOARD_COMPONENTS: any[] = [
  ActiveWidgetCopyModalComponent,
  ActiveWidgetModalComponent,
  ColumnAttributeSelectorComponent,
  DashboardSelectorComponent,
  EditThemeModalComponent,
  LegendComponent,
  RiskIndicatorDetailsComponent,
  StandardChartComponent,
  StandardDashboardCardComponent,
  StandardWidgetComponent,
  TableChartComponent,
  TreeMapDrilldownComponent,
  UsageCardComponent,
  WidgetComponent,
  WidgetControlBarComponent,
  WidgetEditRangeDialogComponent,
  WidgetTagComponent,
  WidgetTagFilterComponent,
];

export * from './active-widget-copy-modal/active-widget-copy-modal.component';
export * from './active-widget-modal/active-widget-modal.component';
export * from './column-attribute-selector/column-attribute-selector.component';
export * from './dashboard-selector/dashboard-selector.component';
export * from './edit-theme-modal/edit-theme-modal.component';
export * from './legend/legend.component';
export * from './risk-indicator-details/risk-indicator-details.component';
export * from './standard-chart/standard-chart.component';
export * from './standard-dashboard-card/standard-dashboard-card.component';
export * from './standard-widget/standard-widget.component';
export * from './table-chart/table-chart.component';
export * from './tree-map-drilldown/tree-map-drilldown.component';
export * from './usage-card/usage-card.component';
export * from './widget/widget.component';
export * from './widget-control-bar/widget-control-bar.component';
export * from './widget-edit-range-dialog/widget-edit-range-dialog.component';
export * from './widget-incremental-loading-status/widget-incremental-loading-status.component';
export * from './widget-tag/widget-tag.component';
export * from './widget-tag-filter/widget-tag-filter.component';
