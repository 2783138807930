/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { LookupVariableInputComponent } from './lookup-variable-input.component';
import { LookupVariableSelectorComponent } from './lookup-variable-selector/lookup-variable-selector.component';

export const LOOKUP_VARIABLE_INPUT_COMPONENTS: any[] = [LookupVariableInputComponent, LookupVariableSelectorComponent];

export * from './lookup-variable-input.component';
export * from './lookup-variable-selector/lookup-variable-selector.component';
