/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { CustomReport, ReportTrackingSearchResponse } from '@ws1c/intelligence-models';

/**
 * Defines UI State for Non Admin Flow
 *
 * @export
 * @interface NonAdminState
 */
export interface NonAdminState {
  reportShareList: ReportTrackingSearchResponse;
  reportDetails: CustomReport;
}

export const initialNonAdminState: NonAdminState = {
  reportShareList: undefined,
  reportDetails: undefined,
};
