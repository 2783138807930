/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Automation, LayoutType } from '@ws1c/intelligence-models';

/**
 * Automation Status Badge
 *
 * @export
 * @class AutomationStatusBadgeComponent
 */
@Component({
  selector: 'dpa-automation-status-badge',
  templateUrl: './automation-status-badge.component.html',
  styleUrls: ['./automation-status-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutomationStatusBadgeComponent {
  @Input() public automation: Automation;
  @Input() public layoutType: LayoutType;

  public readonly LayoutType = LayoutType;
}
