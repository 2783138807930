/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GenericObject } from '@dpa/ui-common';
import { cloneDeep, isEqual, keyBy } from 'lodash-es';

import {
  Category,
  Column,
  DashboardConfig,
  DateRangeFilters,
  FilterRule,
  RuleGroup,
  SuggestionFilterBy,
  TrendDateRange,
} from '@ws1c/intelligence-models';

/**
 * FilterGroupDateRangeComponent
 * @export
 * @class FilterGroupDateRangeComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-filter-group-date-range',
  templateUrl: 'filter-group-date-range.component.html',
  styleUrls: ['filter-group-date-range.component.scss'],
})
export class FilterGroupDateRangeComponent implements OnChanges {
  @Input() public showIncludesAllText?: boolean = true;
  @Input() public showFilters?: boolean = true;
  @Input() public showTrendDateRange?: boolean = true;
  @Input() public columns?: Column[] = [];
  @Input() public isMinimized?: boolean = false;
  @Input() public editing?: boolean = false;
  @Input() public defaultTrendDateRange?: TrendDateRange = DashboardConfig.DEFAULT_DATE_RANGE;
  @Input() public trendDateRange?: TrendDateRange;
  @Input() public isTrendDateRangeReadonly?: boolean = false;
  // This default filter is set when "RESET" is clicked
  @Input() public defaultRuleGroup?: RuleGroup = new RuleGroup([new FilterRule()]);
  @Input() public isCrossCategory?: boolean = false;
  @Input() public ruleGroup: RuleGroup = new RuleGroup();
  @Input() public suggestionFilterBys: SuggestionFilterBy[];
  @Input() public suggestionCategory: Category;
  @Input() public hideEmptyRules?: boolean = false;
  @Input() public showThreeColumnFilter?: boolean = false;
  @Input() public editable?: boolean = true;
  @Input() public maxCustomRange?: number;
  @Input() public customTimePeriodHelpText?: string;
  @Input() public selectedFilterCount: number = 0;

  @Output() public onApply: EventEmitter<DateRangeFilters> = new EventEmitter<DateRangeFilters>();
  @Output() public editingChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public selectedFilterCountChange: EventEmitter<number> = new EventEmitter<number>();

  public ruleGroupEdit: RuleGroup;
  public columnsByName: GenericObject = {};

  /**
   * @param {ChangeDetectorRef} cdr
   * @memberof FilterGroupDateRangeComponent
   */
  constructor(private cdr: ChangeDetectorRef) {}

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof FilterGroupDateRangeComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.editing) {
      this.ruleGroupEdit = cloneDeep(this.ruleGroup);
      this.cdr.detectChanges();
    }

    if (changes.columns) {
      this.columnsByName = keyBy(changes.columns.currentValue, 'attributeName');
    }
  }

  /**
   * onRuleChange
   * @param {RuleGroup} ruleGroup
   * @memberof FilterGroupDateRangeComponent
   */
  public onRuleChange(ruleGroup: RuleGroup) {
    this.ruleGroupEdit = cloneDeep(ruleGroup);
  }

  /**
   * updateTrendDateRange
   * @param {TrendDateRange} trendDateRange
   * @memberof FilterGroupDateRangeComponent
   */
  public updateTrendDateRange(trendDateRange: TrendDateRange) {
    this.trendDateRange = trendDateRange;
    this.onApply.emit({
      ruleGroup: this.ruleGroup,
      trendDateRange: this.trendDateRange,
    });
    this.updateSelectedFilterCount();
  }

  /**
   * applyFilter
   * @memberof FilterGroupDateRangeComponent
   */
  public applyFilter() {
    this.emitOnApply();
    this.onCollapsedChange(true);
  }

  /**
   * cancelFilter
   * @memberof FilterGroupDateRangeComponent
   */
  public cancelFilter() {
    this.ruleGroupEdit = cloneDeep(this.ruleGroup);
    this.onCollapsedChange(true);
  }

  /**
   * resetFilter
   * @memberof FilterGroupDateRangeComponent
   */
  public resetFilter() {
    this.ruleGroupEdit = cloneDeep(this.defaultRuleGroup);
  }

  /**
   * resetDateRange
   * @memberof FilterGroupDateRangeComponent
   */
  public resetDateRange() {
    this.updateTrendDateRange(this.defaultTrendDateRange);
  }

  /**
   * canResetDateRange
   * @returns {boolean}
   * @memberof FilterGroupDateRangeComponent
   */
  public canResetDateRange(): boolean {
    return this.trendDateRange && !isEqual(this.trendDateRange, this.defaultTrendDateRange);
  }

  /**
   * canResetFilter
   * @returns {boolean}
   * @memberof FilterGroupDateRangeComponent
   */
  public canResetFilter(): boolean {
    return !isEqual(this.ruleGroupEdit, this.defaultRuleGroup);
  }

  /**
   * emitOnApply
   * @memberof FilterGroupDateRangeComponent
   */
  public emitOnApply() {
    this.onApply.emit({
      ruleGroup: this.ruleGroupEdit,
      trendDateRange: this.trendDateRange,
    });
    this.updateSelectedFilterCount();
  }

  /**
   * onCollapsedChange
   * @param {boolean} collapsed
   * @memberof FilterGroupDateRangeComponent
   */
  public onCollapsedChange(collapsed: boolean) {
    this.editing = !collapsed;
    this.cdr.detectChanges();
    this.editingChange.emit(this.editing);
  }

  /**
   * updateSelectedFilterCount
   * @private
   * @memberof FilterGroupDateRangeComponent
   */
  private updateSelectedFilterCount() {
    this.selectedFilterCount = !isEqual(this.ruleGroupEdit, this.defaultRuleGroup) ? this.ruleGroupEdit?.rules?.length : 0;
    this.selectedFilterCountChange.emit(this.selectedFilterCount);
  }
}
