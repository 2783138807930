<div
  [ngClass]="isSingleLine() ? 'clr-input-wrapper' : 'clr-textarea-wrapper'"
  class="mt-0"
>
  <input
    *ngIf="isSingleLine(); else textAreaRef"
    #input
    [attr.id]="id"
    [placeholder]="placeholderText"
    [formControl]="textFormControl$ | async"
    [attr.required]="required ? 'true' : undefined"
    class="clr-input lookup-variable-input"
  />
  <ng-template #textAreaRef>
    <textarea
      #textArea
      [attr.id]="id"
      [placeholder]="placeholderText"
      [formControl]="textFormControl$ | async"
      [attr.required]="required ? 'true' : undefined"
      class="clr-textarea text-area"
    ></textarea>
  </ng-template>

  <dpa-lookup-variable-selector
    *ngIf="!disabledField"
    [lookupVariables]="lookupVariables"
    (onLookupVariableSelect)="onLookupVariableSelect($event)"
  ></dpa-lookup-variable-selector>
</div>
