/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, WebError } from '@dpa/ui-common';

import {
  GenericSearchRequest,
  Integration,
  IntegrationActionsSearchResponse,
  IntegrationDetails,
  IntegrationDetailView,
  IntegrationObjectsCounts,
  IntegrationObjectsSearchResponse,
  IntegrationObjectsType,
  IntegrationReprovisionStatus,
  IntegrationResultCode,
  LoadStateByKey,
} from '@ws1c/intelligence-models';

/**
 * Defines UI State for Integration
 *
 * @export
 * @interface IntegrationState
 */
export interface IntegrationState {
  accessIntegrationReprovisioningStatusList: IntegrationReprovisionStatus[];
  activeIntegrationDetails: IntegrationDetails;
  current: string;
  authorized: boolean;
  loading: boolean;
  error: WebError;
  errorMessage: string;
  showSetupModal: boolean;
  showAuthConfirmModal: boolean;
  showDeauthConfirmModal: boolean;
  finishAuthConfirmModalSuccess: boolean;
  finishAuthConfirmModalFailure: boolean;
  integrationsOptInStatus: LoadStateByKey;
  integrationResultCode: IntegrationResultCode;
  isPreviewIntegrationSlideOverOpen: boolean;
  showAzureSetupModal: boolean;
  showDeleteAzureConnectionModal: boolean;
  showReauthorizeAzureConnectionModal: boolean;
  showUagSetupModal: boolean;
  showUagAuthConfirmModal: boolean;
  showUagDeauthConfirmModal: boolean;
  showItsmSetupModal: boolean;
  showItsmAuthConfirmModal: boolean;
  showItsmDeauthConfirmModal: boolean;
  integrationDetailViewModel: IntegrationDetailView;
  integrationHealthReport: GenericObject;
  integrationHealthReportLoading: boolean;
  integrationUsage: {
    objectsCounts: IntegrationObjectsCounts;
    objectsCountsLoading: boolean;
    selectedObjectType: IntegrationObjectsType;
    objectsSearchRequest: GenericSearchRequest;
    objectsSearchResponse: IntegrationObjectsSearchResponse;
    objectsLoading: boolean;
  };
  integrationActions: {
    loading: boolean;
    searchRequest: GenericSearchRequest;
    searchResponse: IntegrationActionsSearchResponse;
  };
}

/**
 * initialIntegrationState
 */
export const initialIntegrationState: IntegrationState = {
  accessIntegrationReprovisioningStatusList: [],
  activeIntegrationDetails: null,
  current: Integration.IDM,
  authorized: false,
  loading: false,
  error: undefined,
  errorMessage: undefined,
  showSetupModal: false,
  showAuthConfirmModal: false,
  showDeauthConfirmModal: false,
  finishAuthConfirmModalSuccess: false,
  finishAuthConfirmModalFailure: false,
  integrationsOptInStatus: {},
  integrationResultCode: undefined,
  isPreviewIntegrationSlideOverOpen: false,
  showAzureSetupModal: false,
  showDeleteAzureConnectionModal: false,
  showReauthorizeAzureConnectionModal: false,
  showItsmSetupModal: false,
  showItsmAuthConfirmModal: false,
  showItsmDeauthConfirmModal: false,
  showUagSetupModal: false,
  showUagAuthConfirmModal: false,
  showUagDeauthConfirmModal: false,
  integrationDetailViewModel: undefined,
  integrationHealthReport: {},
  integrationHealthReportLoading: false,
  integrationUsage: {
    objectsCounts: undefined,
    objectsCountsLoading: false,
    selectedObjectType: IntegrationObjectsType.DASHBOARD,
    objectsSearchRequest: new GenericSearchRequest(),
    objectsSearchResponse: undefined,
    objectsLoading: false,
  },
  integrationActions: {
    loading: false,
    searchRequest: new GenericSearchRequest(),
    searchResponse: undefined,
  },
};
