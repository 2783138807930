<dpa-intro-feature-alert
  *ngIf="(isDashboardsEnabled$ | async) === false"
  [introType]="IntroType.DASHBOARD"
  [alertText]="'FEATURE.DASHBOARDS_NOT_ENABLED_ALERT' | translate"
></dpa-intro-feature-alert>
<dpa-template-search
  [templateType]="templateType"
  [templates]="templates$ | async"
  [tags]="tags"
  [templateTableColumns]="tableColumns"
  [customCardBlock]="customCardBlock"
  [cellTemplatesByColumnValue]="{
    chartType: chartTypeTemplate
  }"
  [bookmarkInProgress]="isBookmarkingInProgress$ | async"
  [showCustomContentTemplate]="showCustomButton"
  [tableName]="'COMMON_MESSAGES.WIDGET_TEMPLATES_TABLE' | translate"
  [actions]="actions"
  (toggleBookmark)="onToggleBookmark($event)"
  (clickCustomTemplate)="onClickCustomTemplate()"
></dpa-template-search>

<ng-template
  #customCardBlock
  let-template="template"
>
  <div class="card-block">
    <ng-template
      [ngTemplateOutlet]="chartTypeTemplate"
      [ngTemplateOutletContext]="{ dataItem: template }"
    ></ng-template>
  </div>
</ng-template>
<ng-template
  #chartTypeTemplate
  let-dataItem="dataItem"
>
  <dpa-chart-type
    [chartType]="dataItem.chartType"
    [trendMode]="dataItem.trendMode"
  ></dpa-chart-type>
</ng-template>
