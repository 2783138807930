/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { CredentialInputComponent } from './credential-input/credential-input.component';
import { FieldsMetaFormComponent } from './fields-meta-form/fields-meta-form.component';
import { LookupFormComponent } from './lookup-form/lookup-form.component';
import { MetaFormComponent } from './meta-form.component';
import { SectionsMetaFormComponent } from './sections-meta-form/sections-meta-form.component';

export const META_FORM_COMPONENTS = [
  CredentialInputComponent,
  MetaFormComponent,
  FieldsMetaFormComponent,
  SectionsMetaFormComponent,
  LookupFormComponent,
];

export * from './credential-input/credential-input.component';
export * from './fields-meta-form/fields-meta-form.component';
export * from './lookup-form/lookup-form.component';
export * from './meta-form.component';
export * from './sections-meta-form/sections-meta-form.component';
