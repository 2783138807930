/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { uniqBy } from 'lodash-es';

import { ComparisonQueryResponse, Cve, StandardWidgetSubtype, Trend, TrendResult } from '@ws1c/intelligence-models';

/**
 * Export helpers to support spy on functions
 */
export const helpers = {
  getSubTypesSummary,
  getCveInsightCardData,
};

/**
 * getSubTypesSummary
 *
 * @export
 * @param {Map<string, Trend>} standardDashboardData
 * @param {any[]} subTypes
 * @returns {ComparisonQueryResponse}
 */
export function getSubTypesSummary(standardDashboardData: Map<string, Trend>, subTypes): ComparisonQueryResponse {
  let firstCount = 0;
  let lastCount = 0;
  subTypes.forEach((subType: StandardWidgetSubtype) => {
    const summaryCounter: Trend = standardDashboardData.get(StandardWidgetSubtype[subType]);
    if (!summaryCounter || !summaryCounter.hasTrendResults) {
      return;
    }
    const firstResult: TrendResult = summaryCounter.trendResults[0];
    const lastResult: TrendResult = summaryCounter.trendResults[summaryCounter.trendResults.length - 1];
    firstCount += firstResult.counters[0].result.value;
    lastCount += lastResult.counters[0].result.value;
  });
  const ratio = firstCount !== 0 ? (lastCount - firstCount) / Math.abs(firstCount) : 1;

  return {
    firstCount,
    lastCount,
    ratio,
  } as ComparisonQueryResponse;
}

/**
 * getCveInsightCardData
 * augments summaryCves with score and devices impacted
 * @param {Cve[]} summaryCves
 * @param {any} cvesById
 * @returns {Cve[]}
 */
export function getCveInsightCardData(summaryCves: Cve[], cvesById: any): Cve[] {
  const summaryCvesWithData = summaryCves?.map((cve: Cve) => {
    const cveData: Cve = cvesById[cve.id];
    return Object.assign(new Cve(), cve, cveData);
  });

  return uniqBy(summaryCvesWithData, (cve: Cve) => cve.id).sort((cve1: Cve, cve2: Cve) => cve2.devicesAffected - cve1.devicesAffected);
}
