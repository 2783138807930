<!-- Note that default values can not be applied by this component -->
<!-- This would cause an ExpressionChangedAfterItHasBeenCheckedError -->
<!-- The component would indirectly change app state based on its metaForm attribute -->
<form
  [formGroup]="formGroup"
  class="clr-form clr-form-horizontal clr-form-compact"
>
  <ng-container *ngFor="let field of filteredFormFields; trackBy: trackByFn">
    <dpa-lookup-form
      *ngIf="searchableActionsEnabled && field.lookupConfig"
      [field]="field"
      [lookupMap]="lookupMap"
      [lookupMapLoading]="lookupMapLoading"
      [nestedLookups]="nestedLookups"
      (lookupChoiceChange)="onLookupChoiceClick($event)"
      (customValueChoiceChange)="onCustomValueChoiceClick($event)"
      (onRequestLookup)="onRequestFieldLookup($event)"
      (onSetFieldValueFromLookup)="setFieldValueFromLookup($event, field.name)"
    ></dpa-lookup-form>
    <div
      *ngIf="isCustomValueInputShown(field)"
      [ngSwitch]="getPresentationTypeDisplay(field)"
      class="meta-form-group"
    >
      <div
        *ngSwitchCase="MetaFormFieldPresentationType.LIST"
        class="clr-form-control form-group"
      >
        <label
          [for]="getFieldId(field.name)"
          [class.required]="field.required"
          class="clr-control-label label-width"
        >
          {{ field.label }}
        </label>
        <dpa-type-ahead
          [id]="getFieldId(field.name)"
          [selectedItem]="getSelectedChoice(field)"
          [searchableItems]="field.choiceValues"
          [customFormatter]="formatter"
          [labelFormatter]="formatter"
          [keyBy]="getColumnKey"
          [disabled]="isDisabledField(field)"
          (selectedItemChange)="setTypeaheadControlValue(field, $event)"
        ></dpa-type-ahead>
      </div>

      <div
        *ngSwitchCase="MetaFormFieldPresentationType.CHECKBOX"
        class="clr-form-control form-group"
      >
        <label
          [for]="getFieldId(field.name)"
          class="clr-control-label label-width"
        >
          {{ field.label }}
        </label>
        <div class="clr-toggle-wrapper toggle-switch">
          <input
            [id]="getFieldId(field.name)"
            [formControlName]="field.name"
            type="checkbox"
          />

          <!-- Clarity needs this empty label or the toggle becomes invisible -->
          <label [for]="getFieldId(field.name)"></label>
        </div>
      </div>

      <div
        *ngSwitchCase="MetaFormFieldPresentationType.RADIO"
        class="clr-form-control form-group"
      >
        <label
          [for]="getFieldId(field.name)"
          [class.required]="field.required"
          class="clr-control-label label-width"
        >
          {{ field.label }}
        </label>
        <div class="clr-control-container clr-control-inline">
          <div
            *ngFor="let choice of field.choiceValues; let radioId = index"
            [class.clr-form-control-disabled]="isDisabledField(field)"
            class="clr-radio-wrapper"
          >
            <input
              [formControlName]="field.name"
              [id]="getFieldId(field.name, radioId)"
              [value]="choice.value"
              [attr.required]="field.required ? 'true' : undefined"
              (change)="onChoiceChange(field, choice.value)"
              type="radio"
              class="clr-radio"
            />
            <label
              [for]="getFieldId(field.name, radioId)"
              class="clr-control-label"
            >
              {{ choice.label }}
            </label>
          </div>
        </div>
      </div>

      <div *ngSwitchCase="MetaFormFieldPresentationType.TEXT_AREA_WITH_COLUMN_VARIABLES">
        <div class="lookup-variable-input-container clr-form-control form-group">
          <label
            [class.required]="field.required"
            [for]="getFieldId(field.name)"
            class="clr-control-label label-width"
          >
            {{ field.label }}
          </label>
          <div class="clr-control-container">
            <dpa-lookup-variable-input
              [id]="getFieldId(field.name)"
              [attr.required]="field.required ? 'true' : undefined"
              [formControlName]="field.name"
              [presentationType]="field.presentationType"
              [placeholderText]="getFieldPlaceholder(field)"
              [lookupVariables]="columnLookupVariables"
              [disabledField]="isDisabledField(field)"
            ></dpa-lookup-variable-input>
          </div>
        </div>
      </div>

      <div
        *ngSwitchCase="MetaFormFieldPresentationType.PHONE"
        class="clr-form-control form-group"
      >
        <label
          [for]="getFieldId(field.name)"
          [class.required]="field.required"
          class="clr-control-label label-width"
        >
          {{ field.label }}
        </label>
        <label
          [for]="getFieldId(field.name)"
          [class.invalid]="getWarningVisible(field)"
          [class.clr-form-control-disabled]="isDisabledField(field)"
          aria-haspopup="true"
          role="tooltip"
          class="tooltip tooltip-validation tooltip-sm meta-form-label phone-label tooltip-top-left"
        >
          <div class="phone-plus-sign">+</div>
          <input
            [id]="getFieldId(field.name)"
            [placeholder]="getFieldPlaceholder(field)"
            [formControlName]="field.name"
            [attr.required]="field.required ? 'true' : undefined"
            type="text"
            class="clr-input meta-form-input phone-input"
          />
          <span
            *ngIf="!isDisabledField(field)"
            class="tooltip-content"
          >
            <div>
              {{ this.getWarningMessage(field) }}
            </div>
          </span>
        </label>
      </div>

      <div
        *ngSwitchCase="MetaFormFieldPresentationType.RICH_TEXT_EDITOR"
        class="clr-form-control form-group"
      >
        <label
          [class.required]="field.required"
          [for]="getFieldId(field.name)"
          class="clr-control-label label-width"
        >
          {{ field.label }}
        </label>
        <quill-editor
          dpaRichTextEditorConfig
          [readOnly]="isDisabledField(field)"
          [formControlName]="field.name"
          [placeholder]="getFieldPlaceholder(field)"
          [class.clr-form-control-disabled]="isDisabledField(field)"
          (onEditorCreated)="onEditorCreation($event)"
        >
          <div quill-editor-toolbar>
            <dpa-rich-text-editor-toolbar
              [editorInstance]="editorInstance"
              [alwaysShowExtraOptions]="true"
            >
              <span
                rich-text-editor-toolbar-end
                class="pl-x2"
              >
                <dpa-lookup-variable-selector
                  [lookupVariables]="columnLookupVariables"
                  [hideLabel]="false"
                  (onLookupVariableSelect)="onLookupVariableSelect($event)"
                ></dpa-lookup-variable-selector>
              </span>
            </dpa-rich-text-editor-toolbar>
          </div>
        </quill-editor>
      </div>

      <div
        *ngSwitchCase="MetaFormFieldPresentationType.EMAIL_MULTISELECT"
        class="clr-form-control form-group"
      >
        <label
          [for]="getFieldId(field.name)"
          [class.required]="field.required"
          class="clr-control-label label-width"
        >
          {{ field.label }}
        </label>
        <dpa-admin-user-selector
          [selectedUsers]="getSelectedUsers(field.name)"
          [addAccountDetails]="false"
          [hideLabels]="false"
          [allowCustomItems]="true"
          [disabled]="isDisabledField(field)"
          (selectedUsersChange)="onSelectedUsersChange($event, field.name)"
          id="send-email-form-to"
        ></dpa-admin-user-selector>
      </div>

      <div
        *ngSwitchDefault
        class="clr-form-control form-group"
      >
        <label
          [for]="getFieldId(field.name)"
          [class.required]="field.required"
          class="clr-control-label label-width"
        >
          {{ field.label }}
        </label>
        <div
          [ngClass]="{ 'clr-error': getWarningVisible(field) }"
          class="clr-control-container"
        >
          <div class="clr-input-wrapper">
            <dpa-credential-input
              *ngIf="isEditPasswordField(field); else normalInput"
              [control]="getFormControl(field.name)"
              [editable]="!isDisabledField(field)"
            ></dpa-credential-input>
            <ng-template #normalInput>
              <input
                [type]="getFieldInputType(field)"
                [id]="getFieldId(field.name)"
                [placeholder]="getFieldPlaceholder(field)"
                [formControlName]="field.name"
                [attr.required]="field.required ? 'true' : undefined"
                class="clr-input meta-form-input"
              />
              <dpa-tooltip
                *ngIf="field.required"
                [attr.aria-label]="getWarningMessage(field)"
                [attr.tabindex]="getWarningVisible(field) ? 0 : -1"
                [tooltipText]="getWarningMessage(field)"
                class="tooltip tooltip-xs tooltip-top-left"
                role="tooltip"
                aria-haspopup="true"
              >
                <cds-icon
                  class="clr-validate-icon"
                  shape="exclamation-circle"
                ></cds-icon>
              </dpa-tooltip>
            </ng-template>
          </div>
        </div>
      </div>
      <dpa-tooltip
        *ngIf="field.note"
        [attr.aria-label]="field.note"
        [tooltipText]="field.note"
        class="mt-x2"
        role="tooltip"
        tabindex="0"
      >
        <cds-icon
          shape="info-circle"
          size="24"
        ></cds-icon>
      </dpa-tooltip>
      <div
        *ngIf="field.label && !field.required && showOptionalLabel(field.presentationType)"
        class="optional ml-x2 mt-x2"
      >
        {{ 'COMMON_MESSAGES.OPTIONAL_NO_PARENTS' | translate }}
      </div>
    </div>
  </ng-container>
</form>
