/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { PagedRequest } from '@dpa/ui-common';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { LocalDataGridSettings } from '@ws1c/intelligence-models';
import { AppsDashboardActions } from './apps-dashboard.actions';
import { AppsDashboardState, initialAppsDashboardState } from './apps-dashboard.state';

const _reducer: ActionReducer<AppsDashboardState> = createReducer(
  initialAppsDashboardState,

  on(
    AppsDashboardActions.selectNetworkInsightsHost,
    (state: AppsDashboardState, { host }: ReturnType<typeof AppsDashboardActions.selectNetworkInsightsHost>): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        host,
        groupBy: undefined,
        subFilterRuleGroup: undefined,
      },
    }),
  ),
  on(
    AppsDashboardActions.openNetworkInsightsHostUrlsModal,
    (state: AppsDashboardState): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        urlsModalHostUrl: state.networkInsights.host,
      },
    }),
  ),
  on(
    AppsDashboardActions.fetchAppHealthStatus,
    (state: AppsDashboardState, { appHealthStatus }: ReturnType<typeof AppsDashboardActions.fetchAppHealthStatus>): AppsDashboardState => ({
      ...state,
      appHealth: undefined,
      appName: appHealthStatus.name,
    }),
  ),
  on(
    AppsDashboardActions.setUserFlowsCardsSortOn,
    (state: AppsDashboardState, { sortOn }: ReturnType<typeof AppsDashboardActions.setUserFlowsCardsSortOn>): AppsDashboardState => ({
      ...state,
      userFlowsCardsSortOn: sortOn,
    }),
  ),
  on(
    AppsDashboardActions.fetchAppHealthStatusSuccess,
    AppsDashboardActions.fetchAppHealthStatusFailure,
    (
      state: AppsDashboardState,
      {
        status,
      }: ReturnType<typeof AppsDashboardActions.fetchAppHealthStatusSuccess | typeof AppsDashboardActions.fetchAppHealthStatusFailure>,
    ): AppsDashboardState => ({
      ...state,
      appHealth: status,
    }),
  ),
  on(
    AppsDashboardActions.setNetworkInsightsAllHostTab,
    (
      state: AppsDashboardState,
      { allHostTab }: ReturnType<typeof AppsDashboardActions.setNetworkInsightsAllHostTab>,
    ): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        allHostTab,
      },
    }),
  ),
  on(
    AppsDashboardActions.setNetworkInsightsGroupBy,
    (state: AppsDashboardState, { groupBy }: ReturnType<typeof AppsDashboardActions.setNetworkInsightsGroupBy>): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        groupBy,
      },
    }),
  ),
  on(
    AppsDashboardActions.setNetworkInsightsSubFilterRuleGroup,
    (
      state: AppsDashboardState,
      { ruleGroup }: ReturnType<typeof AppsDashboardActions.setNetworkInsightsSubFilterRuleGroup>,
    ): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        subFilterRuleGroup: ruleGroup,
      },
    }),
  ),
  on(
    AppsDashboardActions.setNetworkInsightsDetailsGroupBy,
    (
      state: AppsDashboardState,
      { groupBy }: ReturnType<typeof AppsDashboardActions.setNetworkInsightsDetailsGroupBy>,
    ): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        detailsGroupBy: groupBy,
      },
    }),
  ),
  on(
    AppsDashboardActions.setNetworkInsightsErrorTab,
    (state: AppsDashboardState, { tab }: ReturnType<typeof AppsDashboardActions.setNetworkInsightsErrorTab>): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        errorTab: tab,
      },
    }),
  ),
  on(
    AppsDashboardActions.loadAppErrorCrashTable,
    (state: AppsDashboardState): AppsDashboardState => ({
      ...state,
      appCrash: {
        ...state.appCrash,
        isGroupLoading: true,
        groupError: undefined,
      },
    }),
  ),
  on(
    AppsDashboardActions.loadAppErrorCrashTableSuccess,
    (
      state: AppsDashboardState,
      { response }: ReturnType<typeof AppsDashboardActions.loadAppErrorCrashTableSuccess>,
    ): AppsDashboardState => ({
      ...state,
      appCrash: {
        ...state.appCrash,
        groupResponse: response,
        isGroupLoading: false,
      },
    }),
  ),
  on(
    AppsDashboardActions.loadAppErrorCrashTableFailure,
    (state: AppsDashboardState, { error }: ReturnType<typeof AppsDashboardActions.loadAppErrorCrashTableFailure>): AppsDashboardState => ({
      ...state,
      appCrash: {
        ...state.appCrash,
        groupResponse: undefined,
        groupError: error,
        isGroupLoading: false,
      },
    }),
  ),
  on(
    AppsDashboardActions.patchAppErrorCrashTableSettings,
    (
      state: AppsDashboardState,
      { settings }: ReturnType<typeof AppsDashboardActions.patchAppErrorCrashTableSettings>,
    ): AppsDashboardState => ({
      ...state,
      appCrash: {
        ...state.appCrash,
        groupLocalDataGridSettings: new LocalDataGridSettings({
          ...state.appCrash.groupLocalDataGridSettings,
          ...settings,
        }),
      },
    }),
  ),
  on(
    AppsDashboardActions.setAppErrorCrashTableSettings,
    (
      state: AppsDashboardState,
      { settings }: ReturnType<typeof AppsDashboardActions.setAppErrorCrashTableSettings>,
    ): AppsDashboardState => ({
      ...state,
      appCrash: {
        ...state.appCrash,
        groupLocalDataGridSettings: settings,
      },
    }),
  ),
  on(
    AppsDashboardActions.loadAppErrorStacktraceThreads,
    AppsDashboardActions.loadAppErrorStacktraceThreadsFailure,
    (state: AppsDashboardState): AppsDashboardState => ({
      ...state,
      appError: {
        ...state.appError,
        stackTraceSearchResponse: undefined,
      },
    }),
  ),
  on(
    AppsDashboardActions.loadAppErrorStacktraceThreadsSuccess,
    (
      state: AppsDashboardState,
      { response }: ReturnType<typeof AppsDashboardActions.loadAppErrorStacktraceThreadsSuccess>,
    ): AppsDashboardState => ({
      ...state,
      appError: {
        ...state.appError,
        stackTraceSearchResponse: response,
      },
    }),
  ),
  on(
    AppsDashboardActions.setAppErrorBreadcrumbsVisible,
    (
      state: AppsDashboardState,
      { isVisible }: ReturnType<typeof AppsDashboardActions.setAppErrorBreadcrumbsVisible>,
    ): AppsDashboardState => ({
      ...state,
      appError: {
        ...state.appError,
        breadcrumbsVisible: isVisible,
        breadcrumbsSortOns: isVisible ? state.appError.breadcrumbsSortOns : undefined,
        breadcrumbsPagedRequest: isVisible ? state.appError.breadcrumbsPagedRequest : undefined,
      },
    }),
  ),
  on(
    AppsDashboardActions.setAppErrorBreadcrumbsPagedRequest,
    (
      state: AppsDashboardState,
      { pagedRequest }: ReturnType<typeof AppsDashboardActions.setAppErrorBreadcrumbsPagedRequest>,
    ): AppsDashboardState => ({
      ...state,
      appError: {
        ...state.appError,
        breadcrumbsPagedRequest: pagedRequest,
      },
    }),
  ),
  on(
    AppsDashboardActions.setAppErrorBreadcrumbsSortOns,
    (
      state: AppsDashboardState,
      { sortOns }: ReturnType<typeof AppsDashboardActions.setAppErrorBreadcrumbsSortOns>,
    ): AppsDashboardState => ({
      ...state,
      appError: {
        ...state.appError,
        breadcrumbsSortOns: sortOns,
        breadcrumbsPagedRequest: undefined,
      },
    }),
  ),
  on(
    AppsDashboardActions.loadAppErrorBreadcrumbs,
    (state: AppsDashboardState): AppsDashboardState => ({
      ...state,
      appError: {
        ...state.appError,
        breadcrumbsLoading: true,
      },
    }),
  ),
  on(
    AppsDashboardActions.loadAppErrorBreadcrumbsSuccess,
    (
      state: AppsDashboardState,
      { response }: ReturnType<typeof AppsDashboardActions.loadAppErrorBreadcrumbsSuccess>,
    ): AppsDashboardState => ({
      ...state,
      appError: {
        ...state.appError,
        breadcrumbsResponse: response,
        breadcrumbsLoading: false,
      },
    }),
  ),
  on(
    AppsDashboardActions.loadAppErrorBreadcrumbsFailure,
    (state: AppsDashboardState): AppsDashboardState => ({
      ...state,
      appError: {
        ...state.appError,
        breadcrumbsResponse: undefined,
        breadcrumbsLoading: false,
      },
    }),
  ),
  on(
    AppsDashboardActions.patchAppErrorHeTableSettings,
    (
      state: AppsDashboardState,
      { settings }: ReturnType<typeof AppsDashboardActions.patchAppErrorHeTableSettings>,
    ): AppsDashboardState => ({
      ...state,
      appHeGroupLocalDataGridSettings: new LocalDataGridSettings({
        ...state.appHeGroupLocalDataGridSettings,
        ...settings,
      }),
    }),
  ),
  on(
    AppsDashboardActions.setAppErrorHeTableSettings,
    (state: AppsDashboardState, { settings }: ReturnType<typeof AppsDashboardActions.setAppErrorHeTableSettings>): AppsDashboardState => ({
      ...state,
      appHeGroupLocalDataGridSettings: settings,
    }),
  ),
  on(
    AppsDashboardActions.setIdmAppDashboardDateRange,
    (
      state: AppsDashboardState,
      { trendDateRange }: ReturnType<typeof AppsDashboardActions.setIdmAppDashboardDateRange>,
    ): AppsDashboardState => ({
      ...state,
      idmAppDashboardTrendDateRange: trendDateRange,
    }),
  ),
  on(
    AppsDashboardActions.setNetworkInsightsViewingHistorical,
    (
      state: AppsDashboardState,
      { isView }: ReturnType<typeof AppsDashboardActions.setNetworkInsightsViewingHistorical>,
    ): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        viewingHistorical: isView,
      },
    }),
  ),
  on(
    AppsDashboardActions.loadAppErrorHeTable,
    (state: AppsDashboardState): AppsDashboardState => ({
      ...state,
      appCrash: {
        ...state.appCrash,
        isHeGroupLoading: true,
        heGroupError: undefined,
      },
    }),
  ),
  on(
    AppsDashboardActions.setNetworkInsightsSortOns,
    (state: AppsDashboardState, { sortOns }: ReturnType<typeof AppsDashboardActions.setNetworkInsightsSortOns>): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        sortOns,
      },
    }),
  ),
  on(
    AppsDashboardActions.loadAppErrorHeTableSuccess,
    (state: AppsDashboardState, { response }: ReturnType<typeof AppsDashboardActions.loadAppErrorHeTableSuccess>): AppsDashboardState => ({
      ...state,
      appCrash: {
        ...state.appCrash,
        isHeGroupLoading: false,
        heGroupResponse: response,
      },
    }),
  ),
  on(
    AppsDashboardActions.setNetworkInsightsTablePagedRequest,
    (
      state: AppsDashboardState,
      { pagedRequest }: ReturnType<typeof AppsDashboardActions.setNetworkInsightsTablePagedRequest>,
    ): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        tablePagedRequest: pagedRequest,
      },
    }),
  ),
  on(
    AppsDashboardActions.loadAppErrorHeTableFailure,
    (state: AppsDashboardState, { error }: ReturnType<typeof AppsDashboardActions.loadAppErrorHeTableFailure>): AppsDashboardState => ({
      ...state,
      appCrash: {
        ...state.appCrash,
        isHeGroupLoading: false,
        heGroupResponse: undefined,
        heGroupError: error,
      },
    }),
  ),
  on(
    AppsDashboardActions.setNetworkInsightsFilter,
    (state: AppsDashboardState, { filter }: ReturnType<typeof AppsDashboardActions.setNetworkInsightsFilter>): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        filter,
        tablePagedRequest: new PagedRequest({
          ...state.networkInsights.tablePagedRequest,
          from: 0,
        }),
      },
    }),
  ),
  on(
    AppsDashboardActions.loadAppErrorCrashUploadsTable,
    (state: AppsDashboardState): AppsDashboardState => ({
      ...state,
      appCrash: {
        ...state.appCrash,
        isUploadsLoading: true,
      },
    }),
  ),
  on(
    AppsDashboardActions.loadNetworkInsightsTable,
    (state: AppsDashboardState): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        tableSearchResponse: undefined,
        tableSearchError: undefined,
      },
    }),
  ),
  on(
    AppsDashboardActions.loadAppErrorCrashUploadsTableSuccess,
    (
      state: AppsDashboardState,
      { response }: ReturnType<typeof AppsDashboardActions.loadAppErrorCrashUploadsTableSuccess>,
    ): AppsDashboardState => ({
      ...state,
      appCrash: {
        ...state.appCrash,
        isUploadsLoading: false,
        uploadsResponse: response,
      },
    }),
  ),
  on(
    AppsDashboardActions.loadNetworkInsightsTableSuccess,
    (
      state: AppsDashboardState,
      { response }: ReturnType<typeof AppsDashboardActions.loadNetworkInsightsTableSuccess>,
    ): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        tableSearchResponse: response,
        tableSearchError: undefined,
      },
    }),
  ),
  on(
    AppsDashboardActions.loadAppErrorCrashUploadsTableFailure,
    (
      state: AppsDashboardState,
      { error }: ReturnType<typeof AppsDashboardActions.loadAppErrorCrashUploadsTableFailure>,
    ): AppsDashboardState => ({
      ...state,
      appCrash: {
        ...state.appCrash,
        isUploadsLoading: false,
        uploadsResponse: undefined,
        uploadsError: error,
      },
    }),
  ),
  on(
    AppsDashboardActions.loadNetworkInsightsTableFailure,
    (
      state: AppsDashboardState,
      { error }: ReturnType<typeof AppsDashboardActions.loadNetworkInsightsTableFailure>,
    ): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        tableSearchResponse: undefined,
        tableSearchError: error,
      },
    }),
  ),
  on(
    AppsDashboardActions.setAppErrorCrashUploadsTableFilterString,
    (
      state: AppsDashboardState,
      { query }: ReturnType<typeof AppsDashboardActions.setAppErrorCrashUploadsTableFilterString>,
    ): AppsDashboardState => ({
      ...state,
      appCrash: {
        ...state.appCrash,
        uploadsTableFilterString: query,
      },
    }),
  ),
  on(
    AppsDashboardActions.setNetworkInsightsUrlsModalHostUrl,
    (
      state: AppsDashboardState,
      { url }: ReturnType<typeof AppsDashboardActions.setNetworkInsightsUrlsModalHostUrl>,
    ): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        urlsModalHostUrl: url,
      },
    }),
  ),
  on(
    AppsDashboardActions.setAppErrorCrashUploadsTablePagedRequest,
    (
      state: AppsDashboardState,
      { request }: ReturnType<typeof AppsDashboardActions.setAppErrorCrashUploadsTablePagedRequest>,
    ): AppsDashboardState => ({
      ...state,
      appCrash: {
        ...state.appCrash,
        uploadsTablePagedRequest: request,
      },
    }),
  ),
  on(
    AppsDashboardActions.loadNetworkInsightsUrls,
    (state: AppsDashboardState): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        urlsResponse: undefined,
        urlsError: undefined,
      },
    }),
  ),
  on(
    AppsDashboardActions.setAppErrorCrashUploadsTableSortOns,
    (
      state: AppsDashboardState,
      { sortOns }: ReturnType<typeof AppsDashboardActions.setAppErrorCrashUploadsTableSortOns>,
    ): AppsDashboardState => ({
      ...state,
      appCrash: {
        ...state.appCrash,
        uploadsTableSortOns: sortOns,
      },
    }),
  ),
  on(
    AppsDashboardActions.loadNetworkInsightsUrlsSuccess,
    (
      state: AppsDashboardState,
      { response }: ReturnType<typeof AppsDashboardActions.loadNetworkInsightsUrlsSuccess>,
    ): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        urlsResponse: response,
      },
    }),
  ),
  on(
    AppsDashboardActions.loadNetworkInsightsUrlsFailure,
    (state: AppsDashboardState, { error }: ReturnType<typeof AppsDashboardActions.loadNetworkInsightsUrlsFailure>): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        urlsError: error,
      },
    }),
  ),
  on(
    AppsDashboardActions.setNetworkInsightsLogModalIsOpen,
    (
      state: AppsDashboardState,
      { isOpen }: ReturnType<typeof AppsDashboardActions.setNetworkInsightsLogModalIsOpen>,
    ): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        logModalIsOpen: isOpen,
        logSearchQuery: undefined,
        logSortOns: undefined,
        logPagedRequest: undefined,
      },
    }),
  ),
  on(
    AppsDashboardActions.setNetworkInsightsLogSelectedTabName,
    (
      state: AppsDashboardState,
      { tab }: ReturnType<typeof AppsDashboardActions.setNetworkInsightsLogSelectedTabName>,
    ): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        logSelectedTabName: tab,
        logSortOns: undefined,
        logPagedRequest: undefined,
      },
    }),
  ),
  on(
    AppsDashboardActions.setNetworkInsightsLogSelectedSortOns,
    (
      state: AppsDashboardState,
      { sortOns }: ReturnType<typeof AppsDashboardActions.setNetworkInsightsLogSelectedSortOns>,
    ): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        logSortOns: sortOns,
      },
    }),
  ),
  on(
    AppsDashboardActions.setNetworkLogsPagedRequest,
    (
      state: AppsDashboardState,
      { pagedRequest }: ReturnType<typeof AppsDashboardActions.setNetworkLogsPagedRequest>,
    ): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        logPagedRequest: pagedRequest,
      },
    }),
  ),
  on(
    AppsDashboardActions.setNetworkInsightsLogSearchQuery,
    (
      state: AppsDashboardState,
      { query }: ReturnType<typeof AppsDashboardActions.setNetworkInsightsLogSearchQuery>,
    ): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        logSearchQuery: query,
        logSortOns: undefined,
        logPagedRequest: undefined,
      },
    }),
  ),
  on(
    AppsDashboardActions.uploadCrashMapping,
    (state: AppsDashboardState): AppsDashboardState => ({
      ...state,
      appCrash: {
        ...state.appCrash,
        isUploadingFile: true,
        uploadFileError: undefined,
      },
    }),
  ),
  on(
    AppsDashboardActions.loadNetworkInsightsLog,
    (state: AppsDashboardState): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        logResponse: undefined,
        logError: undefined,
      },
    }),
  ),
  on(
    AppsDashboardActions.uploadCrashMappingSuccess,
    (state: AppsDashboardState): AppsDashboardState => ({
      ...state,
      appCrash: {
        ...state.appCrash,
        isUploadingFile: false,
      },
    }),
  ),
  on(
    AppsDashboardActions.loadNetworkInsightsLogSuccess,
    (
      state: AppsDashboardState,
      { response }: ReturnType<typeof AppsDashboardActions.loadNetworkInsightsLogSuccess>,
    ): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        logResponse: response,
        logError: undefined,
      },
    }),
  ),
  on(
    AppsDashboardActions.uploadCrashMappingFailure,
    (state: AppsDashboardState, { error }: ReturnType<typeof AppsDashboardActions.uploadCrashMappingFailure>): AppsDashboardState => ({
      ...state,
      appCrash: {
        ...state.appCrash,
        isUploadingFile: false,
        uploadFileError: error,
      },
    }),
  ),
  on(
    AppsDashboardActions.loadNetworkInsightsLogFailure,
    (state: AppsDashboardState, { error }: ReturnType<typeof AppsDashboardActions.loadNetworkInsightsLogFailure>): AppsDashboardState => ({
      ...state,
      networkInsights: {
        ...state.networkInsights,
        logResponse: undefined,
        logError: error,
      },
    }),
  ),
  on(
    AppsDashboardActions.setMappingUploadAppVersion,
    (
      state: AppsDashboardState,
      { appVersion }: ReturnType<typeof AppsDashboardActions.setMappingUploadAppVersion>,
    ): AppsDashboardState => ({
      ...state,
      appCrash: {
        ...state.appCrash,
        mappingUploadAppVersion: appVersion,
      },
    }),
  ),
  on(
    AppsDashboardActions.updateAppDashboardFilters,
    (
      state: AppsDashboardState,
      { ruleGroup, trendDateRange }: ReturnType<typeof AppsDashboardActions.updateAppDashboardFilters>,
    ): AppsDashboardState => ({
      ...state,
      filterRuleGroup: ruleGroup,
      trendDateRange,
    }),
  ),
  on(
    AppsDashboardActions.setApteligentDashboardFilters,
    (
      state: AppsDashboardState,
      { ruleGroup, trendDateRange }: ReturnType<typeof AppsDashboardActions.setApteligentDashboardFilters>,
    ): AppsDashboardState => ({
      ...state,
      filterRuleGroup: ruleGroup,
      trendDateRange,
    }),
  ),
  on(
    AppsDashboardActions.setAppsDashboardFilters,
    (
      state: AppsDashboardState,
      { ruleGroup, trendDateRange }: ReturnType<typeof AppsDashboardActions.setAppsDashboardFilters>,
    ): AppsDashboardState => ({
      ...state,
      filterRuleGroup: ruleGroup,
      trendDateRange,
    }),
  ),
);

/**
 * AppsDashboardState
 * @param {AppsDashboardState} state
 * @param {Action} action
 * @returns {AppsDashboardState}
 */
export function appsDashboardState(state: AppsDashboardState, action: Action): AppsDashboardState {
  return _reducer(state, action);
}
