/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DateTimeFormat, PagedRequest, SortOn } from '@dpa/ui-common';

import {
  COLUMN_NAMES,
  Connector,
  ConnectorActionEvent,
  ConnectorActionType,
  ConnectorConfigStatus,
  ConnectorsSearchResponse,
} from '@ws1c/intelligence-models';

/**
 * Create a ConnectorGridComponent
 *
 * @export
 * @class ConnectorGridComponent
 */
@Component({
  selector: 'dpa-connector-grid',
  templateUrl: 'connector-grid.component.html',
  styleUrls: ['connector-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectorGridComponent {
  @Input() public loading?: boolean = true;
  @Input() public pageSize: number;
  @Input() public sortOns?: SortOn[];
  @Input() public showActions?: boolean = false;
  @Input() public response: ConnectorsSearchResponse;
  @Output() public sortChange: EventEmitter<SortOn[]> = new EventEmitter();
  @Output() public pageChange: EventEmitter<PagedRequest> = new EventEmitter();
  @Output() public refreshClick: EventEmitter<void> = new EventEmitter();
  @Output() public actionClick: EventEmitter<ConnectorActionEvent> = new EventEmitter();

  public readonly COLUMN_NAMES = COLUMN_NAMES;
  public readonly CONNECTOR_ACTION_TYPE = ConnectorActionType;
  public readonly DateTimeFormat = DateTimeFormat;

  public readonly connectorStatusKeyByStatus: Record<string, string> = {
    [ConnectorConfigStatus.AUTHORIZED]: 'CONNECTIONS.STATUS_AUTHORIZED',
    [ConnectorConfigStatus.FAILED_TO_CONNECT]: 'CONNECTIONS.FAILED_TO_CONNECT',
  };

  /**
   * isActionDisabled
   * @param {ConnectorActionType} action
   * @returns {(connector: Connector) => boolean}
   * @memberof ConnectorGridComponent
   */
  public isActionDisabled(action: ConnectorActionType): (connector: Connector) => boolean {
    if (action === ConnectorActionType.DELETE) {
      return (connector: Connector) => connector?.isAuthorized;
    }
  }

  /**
   * isActionHidden
   * @param {ConnectorActionType} action
   * @returns {(connector: Connector) => boolean}
   * @memberof ConnectorGridComponent
   */
  public isActionHidden(action: ConnectorActionType): (connector: Connector) => boolean {
    switch (action) {
      case ConnectorActionType.SET_UP: {
        return (connector: Connector) => !connector?.allowAuthorize;
      }
      case ConnectorActionType.EDIT: {
        return (connector: Connector) => !connector?.allowEdit;
      }
      case ConnectorActionType.RENAME: {
        return (connector: Connector) => !connector?.allowRename;
      }
    }
  }

  /**
   * onAction
   * @param {ConnectorActionType} action
   * @param {Connector} data
   * @memberof ConnectorGridComponent
   */
  public onAction(action: ConnectorActionType, data: Connector) {
    this.actionClick.emit({
      action,
      data,
    });
  }
}
