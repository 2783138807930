<dpa-lookup-form
  *ngIf="searchableActionsEnabled"
  [field]="field"
  [formControlValue]="formControl?.value"
  [lookupMap]="lookupMap$ | async"
  [lookupMapError]="lookupMapError$ | async"
  [lookupMapLoading]="lookupMapLoading$ | async"
  [nestedLookups]="nestedLookups"
  (lookupChoiceChange)="onLookupChoiceClick($event)"
  (customValueChoiceChange)="onCustomValueChoiceClick()"
  (onRequestLookup)="onRequestFieldLookup($event)"
  (onSetFieldValueFromLookup)="setFieldValueFromLookup($event, field.name)"
></dpa-lookup-form>
<dpa-form-field
  *ngIf="showCustomInput"
  [name]="name"
  [control]="formControl"
  [metadata]="customMetadata"
  [required]="required"
  [enumList]="enumList"
  [defaultValue]="defaultValue"
  [columnLookupVariables]="lookupVariables"
  [searchableActionsEnabled]="searchableActionsEnabled"
></dpa-form-field>
