<dpa-modal
  *ngIf="duplicateWidgetDialogOpen$ | async as duplicateWidgetDialogOpen"
  [modalOpen]="duplicateWidgetDialogOpen"
  (onModalClose)="closeModal()"
>
  <dpa-modal-title>
    {{ 'DASHBOARD_ACTIONS.DUPLICATE_A_WIDGET' | translate }}
  </dpa-modal-title>
  <div class="modal-body">
    <div class="container">
      <dpa-alert-banner
        *ngIf="duplicateWidgetDialogOpen"
        [target]="AlertBannerTarget.MODAL"
      ></dpa-alert-banner>
      <form
        [formGroup]="duplicateWidgetForm"
        class="clr-form clr-form-horizontal"
      >
        <span class="clr-required-mark">{{ 'COMMON_MESSAGES.REQUIRED' | translate }}</span>
        <div class="clr-form-control form-group">
          <label
            class="clr-control-label clr-required-mark"
            for="widgetName"
          >
            {{ 'DASHBOARD_ACTIONS.WIDGET_TITLE' | translate }}
          </label>
          <div
            [class.clr-error]="!duplicateWidgetForm.controls.name.valid"
            class="clr-control-container"
          >
            <div class="clr-input-wrapper">
              <input
                required
                type="text"
                class="clr-input"
                id="widgetName"
                formControlName="name"
              />
            </div>
            <span class="clr-subtext">
              {{ 'FORM_VALIDATION.REQUIRED_FIELD' | translate }}
            </span>
          </div>
        </div>
        <div class="clr-form-control form-group">
          <label
            for="widgetDescription"
            class="clr-control-label"
          >
            {{ 'DASHBOARD_ACTIONS.WIDGET_DESCRIPTION' | translate }}
          </label>
          <div class="clr-textarea-wrapper">
            <textarea
              id="widgetDescription"
              formControlName="description"
              class="clr-textarea textarea-widget-description"
            ></textarea>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <button
      (click)="closeModal()"
      class="btn btn-outline"
    >
      {{ 'COMMON_ACTIONS.CANCEL' | translate }}
    </button>
    <button
      [disabled]="duplicateWidgetForm.get('name').invalid"
      (click)="confirmDuplicateWidget(activeWidget, duplicateWidgetForm.value.name)"
      class="btn btn-primary"
    >
      {{ 'COMMON_ACTIONS.SAVE' | translate }}
    </button>
  </div>
</dpa-modal>

<dpa-modal
  *ngIf="deleteWidgetDialogOpen$ | async as deleteWidgetDialogOpen"
  [modalOpen]="deleteWidgetDialogOpen"
  (onModalClose)="closeModal()"
>
  <dpa-modal-title>
    {{ 'DASHBOARD_ACTIONS.DELETE_WIDGET' | translate }}
  </dpa-modal-title>
  <div class="modal-body">
    <clr-alert
      *ngIf="dashboard?.shareCount > 0"
      clrAlertType="warning"
      [clrAlertClosable]="false"
    >
      <clr-alert-item class="alert-item">
        <span class="alert-text">
          {{ 'DASHBOARD_ACTIONS.DASHBOARD_DELETE_SHARE_WARNING_MSG' | translate: { count: dashboard.shareCount } }}
        </span>
      </clr-alert-item>
    </clr-alert>
    <p>
      {{ 'DASHBOARD_ACTIONS.DELETE_WIDGET_CONFIRM_MSG' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <button
      (click)="closeModal()"
      class="btn btn-outline"
    >
      {{ 'COMMON_ACTIONS.CANCEL' | translate }}
    </button>
    <button
      (click)="confirmDeleteWidget()"
      class="btn btn-danger"
    >
      {{ 'COMMON_ACTIONS.DELETE' | translate }}
    </button>
  </div>
</dpa-modal>

<dpa-modal
  *ngIf="renameWidgetDialogOpen$ | async as renameWidgetDialogOpen"
  [modalOpen]="renameWidgetDialogOpen"
  (onModalClose)="closeModal()"
>
  <dpa-modal-title>
    {{ 'DASHBOARD_ACTIONS.RENAME_A_WIDGET' | translate }}
  </dpa-modal-title>
  <div class="modal-body">
    <div class="container">
      <dpa-alert-banner
        *ngIf="renameWidgetDialogOpen"
        [target]="AlertBannerTarget.MODAL"
        class="bad-request-alert"
      ></dpa-alert-banner>
      <form
        [formGroup]="renameWidgetForm"
        class="clr-form clr-form-horizontal"
      >
        <span class="clr-required-mark">{{ 'COMMON_MESSAGES.REQUIRED' | translate }}</span>
        <div class="clr-form-control form-group">
          <label
            class="clr-control-label clr-required-mark"
            for="widgetName"
          >
            {{ 'DASHBOARD_ACTIONS.WIDGET_TITLE' | translate }}
          </label>
          <div
            [class.clr-error]="!renameWidgetForm.controls.name.valid"
            class="clr-control-container"
          >
            <div class="clr-input-wrapper">
              <input
                required
                type="text"
                class="clr-input"
                id="widgetName"
                formControlName="name"
              />
            </div>
            <span class="clr-subtext">
              {{ 'FORM_VALIDATION.REQUIRED_FIELD' | translate }}
            </span>
          </div>
        </div>
        <div class="clr-form-control form-group">
          <label
            for="widgetDescription"
            class="clr-control-label"
          >
            {{ 'DASHBOARD_ACTIONS.WIDGET_DESCRIPTION' | translate }}
          </label>
          <div class="clr-textarea-wrapper">
            <textarea
              id="widgetDescription"
              formControlName="description"
              class="clr-textarea textarea-widget-description"
            ></textarea>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <button
      (click)="closeModal()"
      class="btn btn-outline"
    >
      {{ 'COMMON_ACTIONS.CANCEL' | translate }}
    </button>
    <button
      [disabled]="renameWidgetForm.get('name').invalid"
      (click)="confirmRenameWidget(activeWidget, renameWidgetForm.value.name, renameWidgetForm.value.description)"
      class="btn btn-primary"
    >
      {{ 'COMMON_ACTIONS.SAVE' | translate }}
    </button>
  </div>
</dpa-modal>

<dpa-modal
  *ngIf="infoWidgetDialogOpen$ | async as infoWidgetDialogOpen"
  [modalOpen]="infoWidgetDialogOpen"
  (onModalClose)="closeModal()"
>
  <dpa-modal-title>
    {{ activeWidget?.name }}
  </dpa-modal-title>
  <div class="modal-body">
    <ng-container *ngIf="categoryLabel$ | async as categoryLabel">
      <div class="clr-row p-x1">
        <div class="clr-col-3">{{ 'COMMON_MESSAGES.CATEGORY' | translate }}:</div>
        <div class="clr-col-9 pl-x2">
          {{ categoryLabel }}
        </div>
      </div>
    </ng-container>
    <div class="clr-row p-x1">
      <div class="clr-col-3">{{ 'COMMON_MESSAGES.DESCRIPTION' | translate }}:</div>
      <div class="clr-col-9 pl-x2">
        {{ activeWidget?.description }}
      </div>
    </div>
    <div class="clr-row p-x1">
      <div class="clr-col-3">{{ 'COMMON_MESSAGES.TYPE' | translate }}:</div>
      <div class="clr-col-9 pl-x2">
        {{ 'WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_MODE_' + activeWidget?.trend?.trendDefinition?.trendMode | translate }}
      </div>
    </div>
    <div class="clr-row p-x1">
      <div class="clr-col-3">{{ 'LIST_VIEW.COLUMNS.CREATED_BY' | translate }}:</div>
      <div class="clr-col-9 pl-x2">
        {{ activeWidget?.createdByDetails | userInfo }}
      </div>
    </div>
    <div class="clr-row p-x1">
      <div class="clr-col-3">{{ 'COMMON_MESSAGES.DATE_CREATED' | translate }}:</div>
      <div class="clr-col-9 pl-x2">
        {{ activeWidget?.createdAt | formattedDate: DateTimeFormat.MOMENT_MEDIUM_DATETIME_FORMAT }}
      </div>
    </div>
    <div class="clr-row p-x1">
      <div class="clr-col-3">{{ 'COMMON_MESSAGES.DATE_MODIFIED' | translate }}:</div>
      <div class="clr-col-9 pl-x2">
        {{ activeWidget?.modifiedAt | formattedDate: DateTimeFormat.MOMENT_MEDIUM_DATETIME_FORMAT }}
      </div>
    </div>
  </div>
</dpa-modal>

<ng-container *ngIf="editThemeWidgetModalOpen$ | async">
  <dpa-edit-theme-modal></dpa-edit-theme-modal>
</ng-container>
