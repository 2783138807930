/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector } from '@ngrx/store';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { DashboardSelectors, helpers } from '@ws1c/intelligence-core/store';
import { ComparisonQueryResponse, StandardWidgetSubtype, Trend } from '@ws1c/intelligence-models';

/**
 * CveDashboardSelectors
 * @export
 * @class CveDashboardSelectors
 */
export class CveDashboardSelectors {
  /**
   * getHighCVSSCount
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof CveDashboardSelectors
   */
  public static getHighCVSSCount: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return helpers.getOffsetCounterData(trendsByWidgetSubtype, null, StandardWidgetSubtype.VULNERABILITIES_WITH_HIGH_CVSS_COUNT);
    },
  );

  /**
   * getMediumCVSSCount
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof CveDashboardSelectors
   */
  public static getMediumCVSSCount: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return helpers.getOffsetCounterData(trendsByWidgetSubtype, null, StandardWidgetSubtype.VULNERABILITIES_WITH_MEDIUM_CVSS_COUNT);
    },
  );

  /**
   * getLowCVSSCount
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof CveDashboardSelectors
   */
  public static getLowCVSSCount: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return helpers.getOffsetCounterData(trendsByWidgetSubtype, null, StandardWidgetSubtype.VULNERABILITIES_WITH_LOW_CVSS_COUNT);
    },
  );

  /**
   * getCriticalCVSSCount
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof CveDashboardSelectors
   */
  public static getCriticalCVSSCount: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return helpers.getOffsetCounterData(trendsByWidgetSubtype, null, StandardWidgetSubtype.VULNERABILITIES_WITH_CRITICAL_CVSS_COUNT);
    },
  );

  /**
   * getCriticalUpdatesCount
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof CveDashboardSelectors
   */
  public static getCriticalUpdatesCount: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return helpers.getOffsetCounterData(trendsByWidgetSubtype, null, StandardWidgetSubtype.CRITICAL_UPDATES_COUNT);
    },
  );

  /**
   * getVulnerableDevicesCount
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof CveDashboardSelectors
   */
  public static getVulnerableDevicesCount: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return helpers.getOffsetCounterData(trendsByWidgetSubtype, null, StandardWidgetSubtype.VULNERABLE_DEVICES_COUNT);
    },
  );

  /**
   * getCveDevicesPatchedPercentage
   * @static
   * @type {MemoizedSelector<MerlotState, number>}
   * @memberof CveDashboardSelectors
   */
  public static getCveDevicesPatchedPercentage: MemoizedSelector<MerlotState, number> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      const patchedDevicesWidgetSubtype = StandardWidgetSubtype.NON_VULNERABLE_DEVICES_WITH_GIVEN_CVE;
      const totalDevicesWidgetSubtype = StandardWidgetSubtype.DEVICES_WITH_GIVEN_CVE;
      const fraction =
        helpers.getLastCount(trendsByWidgetSubtype.get(patchedDevicesWidgetSubtype)) /
        helpers.getLastCount(trendsByWidgetSubtype.get(totalDevicesWidgetSubtype));
      if (!isNaN(fraction)) {
        return Math.round((fraction + Number.EPSILON) * 100) / 100;
      }
    },
  );

  /**
   * getInstallFailedDevicesCount
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof CveDashboardSelectors
   */
  public static getInstallFailedDevicesCount: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return helpers.getOffsetCounterData(trendsByWidgetSubtype, null, StandardWidgetSubtype.INSTALL_FAILED_DEVICES_COUNT);
    },
  );

  /**
   * getCvesPublishedLastWeekCount
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof CveDashboardSelectors
   */
  public static getCvesPublishedLastWeekCount: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return helpers.getOffsetCounterData(trendsByWidgetSubtype, null, StandardWidgetSubtype.CVES_PUBLISHED_LAST_7_DAYS_COUNT);
    },
  );

  /**
   * getCvssPublishMetadata
   * @static
   * @type {MemoizedSelector<MerlotState, Record<string, number>>}
   * @memberof CveDashboardSelectors
   */
  public static getCvssPublishMetadata: MemoizedSelector<MerlotState, Record<string, number>> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      const trend = trendsByWidgetSubtype.get(StandardWidgetSubtype.IMPACTED_DEVICES_BY_VULNERABILITY);
      return {
        cvssScore: helpers.getBucketingAttributeValue(trend, 0),
        cvePublishDateMillis: helpers.getBucketingAttributeValue(trend, 1),
      };
    },
  );
}
