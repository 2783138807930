/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { FeatureSelectors } from '@ws1c/intelligence-core/store/rbac';
import {
  Account,
  AutomationSearchRequest,
  AutomationSearchResponse,
  CustomReportSearchRequest,
  CustomReportSearchResponse,
  DashboardSearchRequest,
  DashboardSearchResponse,
  GenericSearchRequest,
  ServiceLimits,
  ServiceLimitsName,
  SystemLimitsFeatureTag,
  SystemLimitsSearchResponse,
  SystemLimitsUsageDetails,
} from '@ws1c/intelligence-models';
import { SystemLimitsState } from './system-limits.state';

/**
 * SystemLimitsSelectors
 * @export
 * @class SystemLimitsSelectors
 */
export class SystemLimitsSelectors {
  public static systemLimitsState = (state: MerlotState): SystemLimitsState => state.systemLimitsState;

  /**
   * isUsageDetailsLoading
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof SystemLimitsSelectors
   */
  public static isUsageDetailsLoading: MemoizedSelector<MerlotState, boolean> = createSelector(
    SystemLimitsSelectors.systemLimitsState,
    (state: SystemLimitsState) => state.usageDetailsLoading,
  );

  /**
   * getUsageDetails
   * @static
   * @param {SystemLimitsFeatureTag} featureTag
   * @type {MemoizedSelector<MerlotState, SystemLimitsUsageDetails>}
   * @memberof SystemLimitsSelectors
   */
  public static getUsageDetails = (featureTag: SystemLimitsFeatureTag): MemoizedSelector<MerlotState, SystemLimitsUsageDetails> =>
    createSelector(SystemLimitsSelectors.systemLimitsState, (state: SystemLimitsState) =>
      state.usageDetails?.find((systemLimitsUsageDetails: SystemLimitsUsageDetails) => systemLimitsUsageDetails.featureTag === featureTag),
    );

  /**
   * getActiveTab
   * @static
   * @type {MemoizedSelector<MerlotState, SystemLimitsFeatureTag>}
   * @memberof SystemLimitsSelectors
   */
  public static getActiveTab: MemoizedSelector<MerlotState, SystemLimitsFeatureTag> = createSelector(
    SystemLimitsSelectors.systemLimitsState,
    (state: SystemLimitsState) => state.activeTab,
  );

  /**
   * getSystemLimitsSearchRequest
   * @static
   * @type {MemoizedSelector<MerlotState, GenericSearchRequest>}
   * @memberof SystemLimitsSelectors
   */
  public static getSystemLimitsSearchRequest: MemoizedSelector<MerlotState, GenericSearchRequest> = createSelector(
    SystemLimitsSelectors.systemLimitsState,
    (state: SystemLimitsState) => state.systemLimitsSearchRequest,
  );

  /**
   * getSystemLimitsSearchResponse
   * @static
   * @type {MemoizedSelector<MerlotState, SystemLimitsSearchResponse>}
   * @memberof SystemLimitsSelectors
   */
  public static getSystemLimitsSearchResponse: MemoizedSelector<MerlotState, SystemLimitsSearchResponse> = createSelector(
    SystemLimitsSelectors.systemLimitsState,
    (state: SystemLimitsState) => state.systemLimitsSearchResponse,
  );

  /**
   * isSystemLimitsSearchInProgress
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof SystemLimitsSelectors
   */
  public static isSystemLimitsSearchInProgress: MemoizedSelector<MerlotState, boolean> = createSelector(
    SystemLimitsSelectors.systemLimitsState,
    (state: SystemLimitsState) => state.isSystemLimitsSearchInProgress,
  );

  /**
   * getEnabledServiceLimits
   * @static
   * @type {MemoizedSelector<MerlotState, ServiceLimitsName[]>}
   * @memberof SystemLimitsSelectors
   */
  public static getEnabledServiceLimits: MemoizedSelector<MerlotState, ServiceLimitsName[]> = createSelector(
    FeatureSelectors.hasWorkflowManagePerm,
    FeatureSelectors.hasReportManagePerm,
    FeatureSelectors.hasDashboardManagePerm,
    (hasWorkflowManagePerm: boolean, hasReportManagePerm: boolean, hasDashboardManagePerm: boolean) =>
      Object.values(ServiceLimitsName).filter((serviceLimitName: ServiceLimitsName) => {
        switch (serviceLimitName) {
          case ServiceLimitsName.AUTOMATIONS:
            return hasWorkflowManagePerm;
          case ServiceLimitsName.REPORTS:
            return hasReportManagePerm;
          case ServiceLimitsName.DASHBOARDS:
            return hasDashboardManagePerm;
        }
      }),
  );

  /**
   * isSystemLimitsModalOpen
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof SystemLimitsSelectors
   */
  public static isSystemLimitsModalOpen: MemoizedSelector<MerlotState, boolean> = createSelector(
    SystemLimitsSelectors.systemLimitsState,
    (state: SystemLimitsState) => state.systemLimitsModalOpen,
  );

  /**
   * isServiceLimitsLoading
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof SystemLimitsSelectors
   */
  public static isServiceLimitsLoading: MemoizedSelector<MerlotState, boolean> = createSelector(
    SystemLimitsSelectors.systemLimitsState,
    (state: SystemLimitsState) => state.serviceLimitsLoading,
  );

  /**
   * getServiceLimits
   * @static
   * @type {MemoizedSelector<MerlotState, ServiceLimits>}
   * @memberof SystemLimitsSelectors
   */
  public static getServiceLimits = (serviceLimitName: ServiceLimitsName): MemoizedSelector<MerlotState, ServiceLimits> =>
    createSelector(SystemLimitsSelectors.systemLimitsState, (state: SystemLimitsState) => {
      return state.serviceLimits?.find((serviceLimit: ServiceLimits) => serviceLimit.name === serviceLimitName);
    });

  /**
   * getUserSystemLimit
   * @static
   * @type {MemoizedSelector<MerlotState, GenericObject>}
   * @memberof SystemLimitsSelectors
   */
  public static getUserSystemLimit: MemoizedSelector<MerlotState, GenericObject> = createSelector(
    SystemLimitsSelectors.systemLimitsState,
    (state: SystemLimitsState) => state.userSystemLimit,
  );

  /**
   * getSelectedAccountId
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof SystemLimitsSelectors
   */
  public static getSelectedAccountId: MemoizedSelector<MerlotState, string> = createSelector(
    SystemLimitsSelectors.getUserSystemLimit,
    (userSystemLimit: GenericObject) => userSystemLimit.selectedAccountId,
  );

  /**
   * getSelectedAccount
   * @static
   * @type {MemoizedSelector<MerlotState, Account>}
   * @memberof SystemLimitsSelectors
   */
  public static getSelectedAccount: MemoizedSelector<MerlotState, Account> = createSelector(
    SystemLimitsSelectors.getUserSystemLimit,
    (userSystemLimit: GenericObject) => userSystemLimit.selectedAccount,
  );

  /**
   * isUserAutomationsUsageSummaryOrServiceLimitsLoading
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof SystemLimitsSelectors
   */
  public static isUserAutomationsUsageSummaryOrServiceLimitsLoading: MemoizedSelector<MerlotState, boolean> = createSelector(
    SystemLimitsSelectors.getUserSystemLimit,
    (userSystemLimit: GenericObject) => userSystemLimit.automationsUsageSummaryLoading || userSystemLimit.serviceLimitsLoading,
  );

  /**
   * getUserAutomationsUsageCount
   * @static
   * @type {MemoizedSelector<MerlotState, number>}
   * @memberof SystemLimitsSelectors
   */
  public static getUserAutomationsUsageCount: MemoizedSelector<MerlotState, number> = createSelector(
    SystemLimitsSelectors.getUserSystemLimit,
    (userSystemLimit: GenericObject) => userSystemLimit.automationsUsageSummary?.resourceCount,
  );

  /**
   * isUserCustomReportsUsageSummaryOrServiceLimitsLoading
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof SystemLimitsSelectors
   */
  public static isUserCustomReportsUsageSummaryOrServiceLimitsLoading: MemoizedSelector<MerlotState, boolean> = createSelector(
    SystemLimitsSelectors.getUserSystemLimit,
    (userSystemLimit: GenericObject) => userSystemLimit.customReportsUsageSummaryLoading || userSystemLimit.serviceLimitsLoading,
  );

  /**
   * getUserCustomReportsUsageCount
   * @static
   * @type {MemoizedSelector<MerlotState, number>}
   * @memberof SystemLimitsSelectors
   */
  public static getUserCustomReportsUsageCount: MemoizedSelector<MerlotState, number> = createSelector(
    SystemLimitsSelectors.getUserSystemLimit,
    (userSystemLimit: GenericObject) => userSystemLimit.customReportsUsageSummary?.resourceCount,
  );

  /**
   * isUserDashboardsUsageSummaryOrServiceLimitsLoading
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof SystemLimitsSelectors
   */
  public static isUserDashboardsUsageSummaryOrServiceLimitsLoading: MemoizedSelector<MerlotState, boolean> = createSelector(
    SystemLimitsSelectors.getUserSystemLimit,
    (userSystemLimit: GenericObject) => userSystemLimit.dashboardsUsageSummaryLoading || userSystemLimit.serviceLimitsLoading,
  );

  /**
   * getUserDashboardsUsageCount
   * @static
   * @type {MemoizedSelector<MerlotState, number>}
   * @memberof SystemLimitsSelectors
   */
  public static getUserDashboardsUsageCount: MemoizedSelector<MerlotState, number> = createSelector(
    SystemLimitsSelectors.getUserSystemLimit,
    (userSystemLimit: GenericObject) => userSystemLimit.dashboardsUsageSummary?.resourceCount,
  );

  /**
   * getUserServiceLimits
   * @static
   * @type {MemoizedSelector<MerlotState, ServiceLimits>}
   * @memberof SystemLimitsSelectors
   */
  public static getUserServiceLimits = (serviceLimitName: ServiceLimitsName): MemoizedSelector<MerlotState, ServiceLimits> =>
    createSelector(SystemLimitsSelectors.getUserSystemLimit, (userSystemLimit: GenericObject) => {
      return userSystemLimit.serviceLimits?.find((serviceLimit: ServiceLimits) => serviceLimit.name === serviceLimitName);
    });

  /**
   * getUserAutomationSearchRequest
   * @static
   * @type {MemoizedSelector<MerlotState, AutomationSearchRequest>}
   * @memberof SystemLimitsSelectors
   */
  public static getUserAutomationSearchRequest: MemoizedSelector<MerlotState, AutomationSearchRequest> = createSelector(
    SystemLimitsSelectors.getUserSystemLimit,
    (userSystemLimit: GenericObject) => userSystemLimit.automationSearchRequest,
  );

  /**
   * isUserAutomationSearchInProgress
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof SystemLimitsSelectors
   */
  public static isUserAutomationSearchInProgress: MemoizedSelector<MerlotState, boolean> = createSelector(
    SystemLimitsSelectors.getUserSystemLimit,
    (userSystemLimit: GenericObject) => userSystemLimit.isAutomationSearchInProgress,
  );

  /**
   * getUserAutomationSearchResponse
   * @static
   * @type {MemoizedSelector<MerlotState, AutomationSearchResponse>}
   * @memberof SystemLimitsSelectors
   */
  public static getUserAutomationSearchResponse: MemoizedSelector<MerlotState, AutomationSearchResponse> = createSelector(
    SystemLimitsSelectors.getUserSystemLimit,
    (userSystemLimit: GenericObject) => userSystemLimit.automationSearchResponse,
  );

  /**
   * getUserCustomReportSearchRequest
   * @static
   * @type {MemoizedSelector<MerlotState, CustomReportSearchRequest>}
   * @memberof SystemLimitsSelectors
   */
  public static getUserCustomReportSearchRequest: MemoizedSelector<MerlotState, CustomReportSearchRequest> = createSelector(
    SystemLimitsSelectors.getUserSystemLimit,
    (userSystemLimit: GenericObject) => userSystemLimit.customReportSearchRequest,
  );

  /**
   * isUserCustomReportSearchInProgress
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof SystemLimitsSelectors
   */
  public static isUserCustomReportSearchInProgress: MemoizedSelector<MerlotState, boolean> = createSelector(
    SystemLimitsSelectors.getUserSystemLimit,
    (userSystemLimit: GenericObject) => userSystemLimit.isCustomReportSearchInProgress,
  );

  /**
   * getUserCustomReportSearchResponse
   * @static
   * @type {MemoizedSelector<MerlotState, CustomReportSearchResponse>}
   * @memberof SystemLimitsSelectors
   */
  public static getUserCustomReportSearchResponse: MemoizedSelector<MerlotState, CustomReportSearchResponse> = createSelector(
    SystemLimitsSelectors.getUserSystemLimit,
    (userSystemLimit: GenericObject) => userSystemLimit.customReportSearchResponse,
  );

  /**
   * getUserDashboardSearchRequest
   * @static
   * @type {MemoizedSelector<MerlotState, DashboardSearchRequest>}
   * @memberof SystemLimitsSelectors
   */
  public static getUserDashboardSearchRequest: MemoizedSelector<MerlotState, DashboardSearchRequest> = createSelector(
    SystemLimitsSelectors.getUserSystemLimit,
    (userSystemLimit: GenericObject) => userSystemLimit.dashboardSearchRequest,
  );

  /**
   * isUserDashboardSearchInProgress
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof SystemLimitsSelectors
   */
  public static isUserDashboardSearchInProgress: MemoizedSelector<MerlotState, boolean> = createSelector(
    SystemLimitsSelectors.getUserSystemLimit,
    (userSystemLimit: GenericObject) => userSystemLimit.isDashboardSearchInProgress,
  );

  /**
   * getUserDashboardSearchResponse
   * @static
   * @type {MemoizedSelector<MerlotState, DashboardSearchResponse>}
   * @memberof SystemLimitsSelectors
   */
  public static getUserDashboardSearchResponse: MemoizedSelector<MerlotState, DashboardSearchResponse> = createSelector(
    SystemLimitsSelectors.getUserSystemLimit,
    (userSystemLimit: GenericObject) => userSystemLimit.dashboardSearchResponse,
  );

  /**
   * isUserSystemLimitsModalOpen
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof SystemLimitsSelectors
   */
  public static isUserSystemLimitsModalOpen: MemoizedSelector<MerlotState, boolean> = createSelector(
    SystemLimitsSelectors.getUserSystemLimit,
    (userSystemLimit: GenericObject) => userSystemLimit.userSystemLimitsModalOpen,
  );

  /**
   * isUserSystemLimitsModalDetailsLoading
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof SystemLimitsSelectors
   */
  public static isUserSystemLimitsModalDetailsLoading: MemoizedSelector<MerlotState, boolean> = createSelector(
    SystemLimitsSelectors.systemLimitsState,
    SystemLimitsSelectors.getUserSystemLimit,
    (state: SystemLimitsState, userSystemLimit: GenericObject) =>
      state.usageDetailsLoading ||
      userSystemLimit.serviceLimitsLoading ||
      userSystemLimit.automationsUsageSummaryLoading ||
      userSystemLimit.customReportsUsageSummaryLoading ||
      userSystemLimit.dashboardsUsageSummaryLoading,
  );
}
