/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IntegrationMetaSelectors } from '@ws1c/intelligence-core/store';
import { Category, IntegrationCategories, TEMPLATE_TYPE } from '@ws1c/intelligence-models';

/**
 * TemplateCategorySelectorComponent
 * @export
 * @class TemplateCategorySelectorComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-template-category-selector',
  templateUrl: 'template-category-selector.component.html',
  styleUrls: ['template-category-selector.component.scss'],
})
export class TemplateCategorySelectorComponent implements OnInit {
  @Input() public templateType: TEMPLATE_TYPE;
  @Output() public selectedCategoryChange: EventEmitter<Category> = new EventEmitter<Category>();

  public integrationCategories$: Observable<IntegrationCategories[]>;

  /**
   * Creates an instance of TemplateCategorySelectorComponent.
   * @param {Store} store
   * @memberof TemplateCategorySelectorComponent
   */
  constructor(public store: Store) {}

  /**
   * ngOnInit
   * @memberof TemplateCategorySelectorComponent
   */
  public ngOnInit() {
    this.integrationCategories$ = this.store.select(IntegrationMetaSelectors.getTemplateIntegrationCategories(this.templateType));
  }
}
