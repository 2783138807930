/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Component, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, ViewChild } from '@angular/core';

import { KeySelectorComponent } from '@ws1c/intelligence-core/components/key-selector/key-selector.component';
import { columnToLookupVariable } from '@ws1c/intelligence-core/store';
import { Column, LookupVariable } from '@ws1c/intelligence-models';

/**
 * Create a dropdown for selecting LookupVariables
 * @export
 * @class LookupVariableSelectorComponent
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'dpa-lookup-variable-selector',
  templateUrl: 'lookup-variable-selector.component.html',
  styleUrls: ['lookup-variable-selector.component.scss'],
})
export class LookupVariableSelectorComponent {
  @Input() public lookupVariables?: LookupVariable[] = [];
  @Input() public hideLabel: boolean = true;
  @Output() public onLookupVariableSelect: EventEmitter<LookupVariable> = new EventEmitter<LookupVariable>();
  @ViewChild('keySelector') public keySelector: KeySelectorComponent;
  @ViewChild('keySelectorWrapper', { read: ElementRef }) public keySelectorWrapper: ElementRef;
  @ViewChild('menuOpenerContainer', { read: ElementRef }) public menuOpenerContainer: ElementRef;
  public keySelectorWrapperWidth = 480;
  public documentElement = document.documentElement;
  public window = window;
  public menuOpen = false;

  /**
   * Creates an instance of LookupVariableSelectorComponent.
   * @param {Renderer2} renderer
   * @memberof LookupVariableSelectorComponent
   */
  constructor(public renderer: Renderer2) {}

  /**
   * openMenuHandler
   * @memberof LookupVariableSelectorComponent
   */
  public openMenuHandler() {
    const menuOpenerBoundingClientRect = this.menuOpenerContainer.nativeElement.getBoundingClientRect();
    const viewPortWidth = Math.max(this.documentElement.clientWidth, this.window.innerWidth || 0);
    if (menuOpenerBoundingClientRect.right + this.keySelectorWrapperWidth > viewPortWidth) {
      this.renderer.setStyle(this.keySelectorWrapper.nativeElement, 'right', `${this.keySelectorWrapperWidth}px`);
    } else {
      this.renderer.removeStyle(this.keySelectorWrapper.nativeElement, 'right');
    }
    this.menuOpen = true;
    // invisible elements can not be focused, this waits for it to render first
    setTimeout(() => {
      this.keySelector.localSearch.focusInput();
    }, 0);
  }

  /**
   * onDoneHandler
   * @param {Column} $event
   * @memberof LookupVariableSelectorComponent
   */
  public onDoneHandler($event: Column) {
    this.onLookupVariableSelect.emit(columnToLookupVariable($event));
    this.resetMenu();
  }

  /**
   * resetMenu
   * @memberof LookupVariableSelectorComponent
   */
  public resetMenu() {
    this.menuOpen = false;
  }

  /**
   * hideMenuOnClickOutside
   * @param {MouseEvent} event
   * @memberof LookupVariableSelectorComponent
   */
  @HostListener('document:click', ['$event'])
  public hideMenuOnClickOutside(event: MouseEvent) {
    // filter out the initial click on the lookup variable icon
    if (this.menuOpenerContainer.nativeElement.contains(event.target) || !this.menuOpen) {
      return;
    }
    if (!this.keySelectorWrapper.nativeElement.contains(event.target)) {
      this.resetMenu();
    }
  }

  /**
   * preventDefault prevents bluring of input
   * @param {any} $event
   * @memberof LookupVariableSelectorComponent
   */
  public preventDefault($event: any) {
    $event.preventDefault();
  }
}
