/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';
import { keyBy, set, sortBy, values } from 'lodash-es';

import {
  BucketingAttribute,
  COLUMN_NAMES,
  OperatingSystem,
  RuleGroup,
  StandardDashboardRequest,
  StandardDashboardType,
  SuggestionFilterBy,
  SuggestionSearch,
  Trend,
  TrendDateRange,
  TrendResult,
} from '@ws1c/intelligence-models';

/**
 * Export helpers to support spy on functions
 */
export const helpers = {
  buildOsVersionsDashboardRequest,
  buildOsPatchesDashboardRequest,
  getVisibleOs,
  getOsByPlatform,
  getAvailablePlatforms,
  getSnapshotTrendValuesByBucketingAttribute,
  getSuggestionFilterBys,
};

/**
 * buildOsVersionsDashboardRequest
 * @param {string} devicePlatform
 * @param {RuleGroup} osUpdateDashboardFilterRuleGroup
 * @param {TrendDateRange} osUpdateDashboardTrendDateRange
 * @returns {StandardDashboardRequest}
 */
export function buildOsVersionsDashboardRequest(
  devicePlatform: string,
  osUpdateDashboardFilterRuleGroup: RuleGroup,
  osUpdateDashboardTrendDateRange: TrendDateRange,
): StandardDashboardRequest {
  return new StandardDashboardRequest(
    StandardDashboardType.OS_VERSIONS,
    osUpdateDashboardFilterRuleGroup,
    osUpdateDashboardTrendDateRange,
    {
      _device_platform: devicePlatform,
    },
  );
}

/**
 * buildOsPatchesDashboardRequest
 * @param {string} devicePlatform
 * @param {RuleGroup} osUpdateDashboardFilterRuleGroup
 * @param {TrendDateRange} osUpdateDashboardTrendDateRange
 * @returns {StandardDashboardRequest}
 */
export function buildOsPatchesDashboardRequest(
  devicePlatform: string,
  osUpdateDashboardFilterRuleGroup: RuleGroup,
  osUpdateDashboardTrendDateRange: TrendDateRange,
): StandardDashboardRequest {
  return new StandardDashboardRequest(
    StandardDashboardType.OS_WINDOWS_PATCHES,
    osUpdateDashboardFilterRuleGroup,
    osUpdateDashboardTrendDateRange,
    {
      _device_platform: devicePlatform,
    },
  );
}

/**
 * getVisibleOs
 * @param {Record<string, OperatingSystem>} availableOsByPlatform
 * @returns {OperatingSystem[]}
 */
export function getVisibleOs(availableOsByPlatform: Record<string, OperatingSystem>): OperatingSystem[] {
  const visiblePlatforms = values(availableOsByPlatform);

  return sortBy(visiblePlatforms, [
    (platform: OperatingSystem) => -platform.versionsCount,
    (platform: OperatingSystem) => -platform.devicesCount,
    (platform: OperatingSystem) => platform.name.toLowerCase(),
  ]);
}

/**
 * getOsByPlatform
 * @param {string[]} availablePlatformNames
 * @param {[platformName: string]: number} osSummaryDeviceCountByPlatformName
 * @param {[platformName: string]: number} versionCountByPlatform
 * @returns {Record<string, OperatingSystem>}
 */
export function getOsByPlatform(
  availablePlatformNames: string[],
  osSummaryDeviceCountByPlatformName: { [platformName: string]: number },
  versionCountByPlatform: { [platformName: string]: number },
): Record<string, OperatingSystem> {
  const availablePlatforms = availablePlatformNames.map((savedPlatformName: string) => {
    return {
      name: savedPlatformName,
      devicesCount: osSummaryDeviceCountByPlatformName[savedPlatformName] || 0,
      versionsCount: versionCountByPlatform[savedPlatformName] || 0,
    } as OperatingSystem;
  });
  return keyBy(availablePlatforms, 'name');
}

/**
 * getAvailablePlatforms
 *
 * @export
 * @param {Map<SuggestionSearch, GenericObject[]>} suggestionValuesBySuggestionSearch
 * @param {SuggestionSearch} devicePlatformSuggestionSearch
 * @returns {string[]}
 */
export function getAvailablePlatforms(
  suggestionValuesBySuggestionSearch: Map<SuggestionSearch, GenericObject[]>,
  devicePlatformSuggestionSearch: SuggestionSearch,
): string[] {
  const searchResults = suggestionValuesBySuggestionSearch.get(devicePlatformSuggestionSearch);
  return (searchResults || []).map((item: GenericObject) => item.value as string);
}

/**
 * getSnapshotTrendValuesByBucketingAttribute
 * @param {Trend} trend
 * @returns {any}
 */
export function getSnapshotTrendValuesByBucketingAttribute(trend: Trend): any {
  if (!trend) {
    return {};
  }
  const flatSnapshotTrend = {};
  trend.trendResults?.forEach((trendResult: TrendResult) => {
    const bucketingAttributes = trendResult.bucketingAttributes.map((bucketingAttribute: BucketingAttribute) => bucketingAttribute.value);
    const value = trendResult.counters[0].result.value;
    set(flatSnapshotTrend, bucketingAttributes, value);
  });
  return flatSnapshotTrend;
}

/**
 * getSuggestionFilterBys
 * @param {string} platformName
 * @returns {SuggestionFilterBy[]}
 */
export function getSuggestionFilterBys(platformName: string): SuggestionFilterBy[] {
  return [
    new SuggestionFilterBy({
      fieldName: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_platform,
      values: [platformName],
    }),
  ];
}
