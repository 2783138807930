/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { TrendSpan } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { cloneDeep, first, isEqual } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  CoreAppState,
  DashboardActions,
  DashboardSelectors,
  DashboardTrendDefinitionOverridesSelectors,
} from '@ws1c/intelligence-core/store';
import {
  AggregationWidgetChartType,
  ChartDrilldownEvent,
  Column,
  DashboardConfig,
  Trend,
  TrendDateRange,
  TrendDefinition,
  TrendDefinitionDrilldownApplier,
} from '@ws1c/intelligence-models';

/**
 * WidgetControlBarComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 * @export
 * @class WidgetControlBarComponent
 */
@Component({
  selector: 'dpa-widget-control-bar',
  templateUrl: 'widget-control-bar.component.html',
  styleUrls: ['widget-control-bar.component.scss'],
})
export class WidgetControlBarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public widgetId: string;
  @Input() public trend?: Trend = undefined;
  @Input() public selectedChartTypeOverride: AggregationWidgetChartType;
  @Input() public showChartTypeSwitcher?: boolean = true;
  @Input() public showDateRangePicker?: boolean = true;
  @Input() public splitCol?: boolean = false;
  @Input() public hasFullAccess?: boolean = true;
  @Input() public isCrossCategory?: boolean = false;
  @Output() public drilldownChange = new EventEmitter<ChartDrilldownEvent>();

  public trendDefinition$: Observable<TrendDefinition>;
  public trend$: BehaviorSubject<Trend> = new BehaviorSubject(this.trend);
  public chartTypeOptions$: Observable<string[]>;
  public selectedChartType$: Observable<string>;
  public selectedColumnName$: Observable<string>;
  public isGroupByVisible$: Observable<boolean>;
  public selectedChartTypeOverride$: BehaviorSubject<AggregationWidgetChartType> = new BehaviorSubject(undefined);
  public sub: Subscription = new Subscription();

  public dateRange: TrendDateRange;
  public selectedChartType: string;
  public CHART_TYPES = AggregationWidgetChartType;
  public isInvertMode: boolean = false;
  public showInvertMode: boolean = false;
  public showInvertModeToggle: boolean = false;
  public samplingFrequency: TrendSpan;
  public CHART_TYPE_SUPPORT_INVERT_MODE = new Set([AggregationWidgetChartType.LINE]);
  public drilldownEvents$: Observable<ChartDrilldownEvent[]>;

  private timeout: ReturnType<typeof setTimeout>;

  /**
   * constructor
   * @param {Store<CoreAppState>} store
   * @memberof WidgetControlBarComponent
   */
  constructor(private store: Store<CoreAppState>) {}

  /**
   * ngOnInit
   * @memberof WidgetControlBarComponent
   */
  public ngOnInit() {
    this.drilldownEvents$ = this.store
      .select(DashboardSelectors.getDrilldownEventsById)
      .pipe(map((drilldownEventsById: Record<string, ChartDrilldownEvent[]>) => drilldownEventsById[this.widgetId]));
    this.trendDefinition$ = combineLatest([
      this.store.select(DashboardTrendDefinitionOverridesSelectors.getTrendDefinitionsById),
      this.drilldownEvents$,
    ]).pipe(
      map(([trendDefinitionsById, drilldownEvents = []]: [Record<string, TrendDefinition>, ChartDrilldownEvent[]]) => {
        const trendDefinition = trendDefinitionsById[this.widgetId];
        if (!trendDefinition) {
          return;
        }
        const tdda = new TrendDefinitionDrilldownApplier();
        const trendDefinitionWithDrilldownEvents = tdda.applyDrilldownEventsToTrendDefinition(cloneDeep(trendDefinition), drilldownEvents);
        this.dateRange = trendDefinitionWithDrilldownEvents.dateRange;
        if (this.dateRange?.trendSpan) {
          this.dateRange.startDateMillis = this.dateRange.endDateMillis = undefined;
        }
        return trendDefinitionWithDrilldownEvents;
      }),
      filter((trendDefinition: TrendDefinition) => !!trendDefinition),
    );

    this.chartTypeOptions$ = this.trendDefinition$.pipe(
      map((trendDefinition: TrendDefinition) => DashboardConfig.chartTypesByTrendMode[trendDefinition.trendMode]),
    );
    this.selectedChartType$ = combineLatest([
      this.store.select(DashboardSelectors.getChartTypesById),
      this.selectedChartTypeOverride$,
    ]).pipe(
      map(([chartTypesById, chartTypeOverride]: [Record<string, AggregationWidgetChartType>, AggregationWidgetChartType]) => {
        this.selectedChartType = AggregationWidgetChartType[chartTypeOverride] || chartTypesById[this.widgetId];
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.showInvertModeToggle = this.isInvertModeSupported(this.selectedChartType);
        });
        return this.selectedChartType;
      }),
    );
    this.selectedColumnName$ = this.trendDefinition$.pipe(
      map((trendDefinition: TrendDefinition) => first(trendDefinition.bucketingAttributes)),
    );
    this.isGroupByVisible$ = combineLatest([this.selectedChartType$, this.trendDefinition$]).pipe(
      map(([selectedChartType, trendDefinition]: [string, TrendDefinition]) => {
        const isMetricChart = selectedChartType === AggregationWidgetChartType.METRIC;
        const isRolling = trendDefinition.getIsRolling();
        this.showInvertMode = !!trendDefinition.bucketingAttributes?.length && selectedChartType === AggregationWidgetChartType.LINE;
        return !isMetricChart && !isRolling;
      }),
    );
    this.sub.add(
      this.store
        .select(DashboardSelectors.getInvertModeById)
        .pipe(map((invertModeById: Record<string, boolean>) => invertModeById[this.widgetId]))
        .subscribe((isInvertMode: boolean) => {
          this.isInvertMode = isInvertMode;
        }),
    );
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof WidgetControlBarComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedChartTypeOverride) {
      this.selectedChartTypeOverride$.next(this.selectedChartTypeOverride);
    }
    if (!isEqual(changes.trend?.currentValue, changes.trend?.previousValue)) {
      this.trend$.next(this.trend);
      this.dateRange = changes.trend?.currentValue?.trendDefinition.dateRange;
    }
  }

  /**
   * ngOnDestroy
   * @memberof WidgetControlBarComponent
   */
  public ngOnDestroy() {
    this.sub.unsubscribe();
    clearTimeout(this.timeout);
  }

  /**
   * updateTrendDateRange
   * @param {TrendDateRange} trendDateRange
   * @memberof WidgetControlBarComponent
   */
  public updateTrendDateRange(trendDateRange: TrendDateRange) {
    const pushDrilldownAction = DashboardActions.pushCustomWidgetDrilldownEvent({
      widgetId: this.widgetId,
      drilldownEvent: {
        trendDateRangeOverride: trendDateRange,
      } as ChartDrilldownEvent,
    });
    this.store.dispatch(pushDrilldownAction);
  }

  /**
   * selectChartType
   * @param {string} chartType
   * @memberof WidgetControlBarComponent
   */
  public selectChartType(chartType: string) {
    let trendDateRangeOverride;
    if ([this.selectedChartType, chartType].includes(this.CHART_TYPES[this.CHART_TYPES.METRIC]) && this.dateRange) {
      const samplingFrequency = chartType === this.CHART_TYPES[this.CHART_TYPES.METRIC] ? undefined : this.samplingFrequency;
      trendDateRangeOverride = new TrendDateRange({ ...this.dateRange, samplingFrequency });
    }
    this.selectedChartType = chartType;
    this.samplingFrequency = this.dateRange?.samplingFrequency;
    const pushDrilldownAction = DashboardActions.pushCustomWidgetDrilldownEvent({
      widgetId: this.widgetId,
      drilldownEvent: {
        isInvertMode: this.isInvertModeSupported(chartType) ? this.isInvertMode : false,
        setChartType: chartType,
        trendDateRangeOverride,
      } as ChartDrilldownEvent,
    });
    this.store.dispatch(pushDrilldownAction);
  }

  /**
   * onSelectedAttributeChange
   * @param {Column} selectedColumn
   * @memberof WidgetControlBarComponent
   */
  public onSelectedAttributeChange(selectedColumn: Column) {
    const pushDrilldownAction = DashboardActions.pushCustomWidgetDrilldownEvent({
      widgetId: this.widgetId,
      drilldownEvent: {
        bucketAttributeChange: {
          bucketAttributeIndex: 0,
          nextAttributeName: selectedColumn && selectedColumn.attributeName,
          nextAttributeLabel: selectedColumn && selectedColumn.label,
        },
      } as ChartDrilldownEvent,
    });
    this.store.dispatch(pushDrilldownAction);
  }

  /**
   * updateInvertMode
   * @memberof WidgetControlBarComponent
   */
  public updateInvertMode() {
    this.isInvertMode = !this.isInvertMode;
    const pushDrilldownAction = DashboardActions.pushCustomWidgetDrilldownEvent({
      widgetId: this.widgetId,
      drilldownEvent: {
        isInvertMode: this.isInvertMode,
      } as ChartDrilldownEvent,
    });
    this.store.dispatch(pushDrilldownAction);
  }

  /**
   * clearDrilldown
   * @memberof StandardWidgetComponent
   */
  public clearDrilldown() {
    this.store.dispatch(DashboardActions.clearDrilldownEvents({ widgetId: this.widgetId }));
  }

  /**
   * isInvertModeSupported
   * @private
   * @param {string} chartType
   * @returns {boolean}
   * @memberof WidgetControlBarComponent
   */
  private isInvertModeSupported(chartType: string): boolean {
    return this.showInvertMode && this.CHART_TYPE_SUPPORT_INVERT_MODE.has(AggregationWidgetChartType[chartType]);
  }
}
