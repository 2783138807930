/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector } from '@ngrx/store';

import {
  ConnectionDeauthorizeRequest,
  ConnectionSetupRequest,
  EventForwarderTemplate,
  ServiceConfigTemplate,
  ServiceConfigTemplateIndex,
} from '@dpa-shared-merlot/model';
import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { FeatureControl } from '@ws1c/intelligence-common';
import { UserPreferenceCommonSelectors, UserPreferenceFeatureControlsSelectors } from '@ws1c/intelligence-core/store/user-preference';
import {
  AutomationsByActionIdSearchRequest,
  AvailableEventForwarder,
  AvailablePartner,
  AvailableService,
  AvailableServiceTenant,
  ConnectionModalMode,
  EventForwarderConnectionModalMode,
  EventForwarderConnectionModalSection,
  FeatureControls,
  MetaForm,
  ObjectById,
  TrustNetworkPartnerConnectionModalMode,
  TrustNetworkPartnerConnectionModalSection,
} from '@ws1c/intelligence-models';
import {
  filterTrustNetworkServices,
  getDefaultAvailableEventForwarders,
  getDefaultAvailableOAuthPartners,
  getDefaultAvailableTrustNetworkPartners,
  isModalMode,
} from './connection-selector-helpers';
import { ConnectionState } from './connection.state';

/**
 * ConnectionSelectors
 *
 * @export
 * @class ConnectionSelectors
 */
export class ConnectionSelectors {
  /**
   * selectConnectionState
   *
   * @type {(state: MerlotState) => ConnectionState}
   * @memberOf ConnectionSelectors
   */
  public static selectConnectionState = (state: MerlotState) => state.connectionState;

  /**
   * getConnectionSetupRequest
   *
   * @static
   * @type {MemoizedSelector<MerlotState, ConnectionSetupRequest>}
   * @memberof ConnectionSelectors
   */
  public static getConnectionSetupRequest: MemoizedSelector<MerlotState, ConnectionSetupRequest> = createSelector(
    ConnectionSelectors.selectConnectionState,
    (state: ConnectionState) => state.connectionSetupRequest,
  );

  /**
   * getConnectionModalMode
   *
   * @static
   * @type {MemoizedSelector<MerlotState, ConnectionModalMode>}
   * @memberof ConnectionSelectors
   */
  public static getConnectionModalMode: MemoizedSelector<MerlotState, ConnectionModalMode> = createSelector(
    ConnectionSelectors.selectConnectionState,
    (state: ConnectionState) => state.connectionModalMode,
  );

  /**
   * getSetupConnectionModalOpen
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof ConnectionSelectors
   */
  public static getSetupConnectionModalOpen: MemoizedSelector<MerlotState, boolean> = createSelector(
    ConnectionSelectors.getConnectionModalMode,
    isModalMode(ConnectionModalMode.SETUP, ConnectionModalMode.EDIT, ConnectionModalMode.EDIT_CONFIRM),
  );

  /**
   * getConnectionEditMode
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof ConnectionSelectors
   */
  public static getConnectionEditMode: MemoizedSelector<MerlotState, boolean> = createSelector(
    ConnectionSelectors.getConnectionModalMode,
    isModalMode(ConnectionModalMode.EDIT, ConnectionModalMode.EDIT_CONFIRM),
  );

  /**
   * getConnectionConfirmEdit
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof ConnectionSelectors
   */
  public static getConnectionConfirmEdit: MemoizedSelector<MerlotState, boolean> = createSelector(
    ConnectionSelectors.getConnectionModalMode,
    isModalMode(ConnectionModalMode.EDIT_CONFIRM),
  );

  /**
   * getConnectionDeauthorizeModalOpen
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof ConnectionSelectors
   */
  public static getConnectionDeauthorizeModalOpen: MemoizedSelector<MerlotState, boolean> = createSelector(
    ConnectionSelectors.getConnectionModalMode,
    isModalMode(ConnectionModalMode.DEAUTHORIZE),
  );

  /**
   * getConnectionModalOpen
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof ConnectionSelectors
   */
  public static getConnectionModalOpen: MemoizedSelector<MerlotState, boolean> = createSelector(
    ConnectionSelectors.getConnectionModalMode,
    (connectionModalMode: ConnectionModalMode) => connectionModalMode !== ConnectionModalMode.CLOSE,
  );

  /**
   * getServiceConfigTemplateIndex
   *
   * @static
   * @type {MemoizedSelector<MerlotState, ServiceConfigTemplateIndex>}
   * @memberof ConnectionSelectors
   */
  public static getServiceConfigTemplateIndex: MemoizedSelector<MerlotState, ServiceConfigTemplateIndex> = createSelector(
    ConnectionSelectors.selectConnectionState,
    (state: ConnectionState) => state.serviceTemplatesByType,
  );

  /**
   * getConnectionModalModel
   *
   * @static
   * @type {MemoizedSelector<MerlotState, AvailableService>}
   * @memberof ConnectionSelectors
   */
  public static getConnectionModalModel: MemoizedSelector<MerlotState, AvailableService> = createSelector(
    ConnectionSelectors.selectConnectionState,
    (state: ConnectionState) => state.connectionModalModel,
  );

  /**
   * getConnectionModalTenantModel
   *
   * @static
   * @type {MemoizedSelector<MerlotState, AvailableServiceTenant>}
   * @memberof ConnectionSelectors
   */
  public static getConnectionModalTenantModel: MemoizedSelector<MerlotState, AvailableServiceTenant> = createSelector(
    ConnectionSelectors.selectConnectionState,
    (state: ConnectionState) => state.connectionModalTenantModel,
  );

  /**
   * getDeauthorizeConnectionModalTitle
   *
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof ConnectionSelectors
   */
  public static getDeauthorizeConnectionModalTitle: MemoizedSelector<MerlotState, string> = createSelector(
    ConnectionSelectors.getConnectionModalModel,
    ConnectionSelectors.getConnectionModalTenantModel,
    (availableService: AvailableService, availableServiceTenant: AvailableServiceTenant) => {
      return availableServiceTenant?.name ?? availableService?.label;
    },
  );

  /**
   * getConnectionModalModelMetaForm
   *
   * @static
   * @type {MemoizedSelector<MerlotState, MetaForm>}
   * @memberof ConnectionSelectors
   */
  public static getConnectionModalModelMetaForm: MemoizedSelector<MerlotState, MetaForm> = createSelector(
    ConnectionSelectors.getServiceConfigTemplateIndex,
    ConnectionSelectors.getConnectionModalModel,
    (serviceTemplatesByType: ServiceConfigTemplateIndex, service: AvailableService) => {
      if (!serviceTemplatesByType || !service) {
        return;
      }
      const serviceConfig: ServiceConfigTemplate = serviceTemplatesByType[service.compositeServiceType];
      return serviceConfig?.metaForm;
    },
  );

  /**
   * getAutomationsByActionIdRequest
   *
   * @static
   * @type {MemoizedSelector<MerlotState, AutomationsByActionIdSearchRequest>}
   * @memberof ConnectionSelectors
   */
  public static getAutomationsByActionIdRequest: MemoizedSelector<MerlotState, AutomationsByActionIdSearchRequest> = createSelector(
    ConnectionSelectors.selectConnectionState,
    (state: ConnectionState) => state.automationsByActionIdRequest,
  );

  /**
   * getEventForwardersTemplatesByName
   *
   * @static
   * @type {MemoizedSelector<MerlotState, ObjectById<EventForwarderTemplate>>}
   * @memberof ConnectionSelectors
   */
  public static getEventForwardersTemplatesByName: MemoizedSelector<MerlotState, ObjectById<EventForwarderTemplate>> = createSelector(
    ConnectionSelectors.selectConnectionState,
    (state: ConnectionState) => state.eventForwardersTemplatesByName,
  );

  /**
   * getTrustNetworkServices
   *
   * @static
   * @type {MemoizedSelector<MerlotState, AvailableService[]>}
   * @memberof ConnectionSelectors
   */
  public static getTrustNetworkServices: MemoizedSelector<MerlotState, AvailableService[]> = createSelector(
    ConnectionSelectors.selectConnectionState,
    (state: ConnectionState) => state.trustNetworkServices,
  );

  /**
   * getAvailableTrustNetworkServices
   *
   * @static
   * @type {MemoizedSelector<MerlotState, AvailableService[]>}
   * @memberof ConnectionSelectors
   */
  public static getAvailableTrustNetworkServices: MemoizedSelector<MerlotState, AvailableService[]> = createSelector(
    UserPreferenceFeatureControlsSelectors.isTrustNetworkFeatureEnabled,
    UserPreferenceFeatureControlsSelectors.isEventForwarderEnabled,
    ConnectionSelectors.getEventForwardersTemplatesByName,
    UserPreferenceCommonSelectors.getFeatureControls,
    ConnectionSelectors.getTrustNetworkServices,
    (
      isTrustNetworkEnabled: boolean,
      isEventForwarderEnabled: boolean,
      eventForwardersTemplatesByName: ObjectById<EventForwarderTemplate>,
      featureControls: FeatureControls,
      services: AvailableService[],
    ) => {
      if (!isTrustNetworkEnabled) {
        return [];
      }
      // Filter services based on feature flags
      const trustNetworkServices = services.filter((service: AvailableService) => {
        // Trust Network feature flags are: CARBONBLACK_ENABLED, LOOKOUT_ENABLED, NETSKOPE_ENABLED or WS_ONE_MTD_ENABLED
        const featureFlag: string = FeatureControl[`${service.key}_ENABLED`];
        return featureControls[featureFlag];
      });

      // Set multi tenancy flag for each service
      trustNetworkServices.forEach((service: AvailableService) => {
        // Trust Network multi tenancy feature flags are: WS_ONE_MTD_MULTI_TENANCY_ENABLED
        const featureFlag: string = FeatureControl[`${service.key}_MULTI_TENANCY_ENABLED`];
        service.multiTenancyEnabled = !!featureControls[featureFlag];
      });

      // Event forwarder is the new methodology for trust network integration introduced for Carbon Black, since backend
      // want support for switching mechanism where based on feature flag we want to show new Carbon Black instead of old
      // card, the services needs to be filtered based on the same feature flag.
      // Thus, filtering/removing all those services which are also coming to us as event forwarders when feature flag is enabled
      return isEventForwarderEnabled
        ? filterTrustNetworkServices(trustNetworkServices, featureControls, eventForwardersTemplatesByName)
        : trustNetworkServices;
    },
  );

  /**
   * getTrustNetworkEventForwarders
   *
   * @static
   * @type {MemoizedSelector<MerlotState, AvailableEventForwarder[]>}
   * @memberof ConnectionSelectors
   */
  public static getTrustNetworkEventForwarders: MemoizedSelector<MerlotState, AvailableEventForwarder[]> = createSelector(
    ConnectionSelectors.selectConnectionState,
    (state: ConnectionState) => state.trustNetworkEventForwarders,
  );

  /**
   * getAvailableTrustNetworkEventForwarders
   *
   * This selector will return all event forwarders which are supported in
   * the UI and enabled with feature flag, the event forwarders which are returned from
   * the state will be clubbed with default event forwarders using helper if they are
   * not present. Helper takes care of feature flag as well.
   *
   * @static
   * @type {MemoizedSelector<MerlotState, AvailableEventForwarder[]>}
   * @memberof ConnectionSelectors
   */
  public static getAvailableTrustNetworkEventForwarders: MemoizedSelector<MerlotState, AvailableEventForwarder[]> = createSelector(
    UserPreferenceFeatureControlsSelectors.isTrustNetworkFeatureEnabled,
    UserPreferenceFeatureControlsSelectors.isEventForwarderEnabled,
    ConnectionSelectors.getEventForwardersTemplatesByName,
    UserPreferenceCommonSelectors.getFeatureControls,
    ConnectionSelectors.getTrustNetworkEventForwarders,
    (
      isTrustNetworkEnabled: boolean,
      isEventForwarderEnabled: boolean,
      eventForwardersTemplatesByName: ObjectById<EventForwarderTemplate>,
      featureControls: FeatureControls,
      eventForwarders: AvailableEventForwarder[],
    ) => {
      if (!isTrustNetworkEnabled || !isEventForwarderEnabled || !eventForwardersTemplatesByName) {
        return [];
      }
      return getDefaultAvailableEventForwarders(eventForwarders, featureControls);
    },
  );

  /**
   * getSelectedEventForwarder
   *
   * @static
   * @type {MemoizedSelector<MerlotState, AvailableEventForwarder>}
   * @memberof ConnectionSelectors
   */
  public static getSelectedEventForwarder: MemoizedSelector<MerlotState, AvailableEventForwarder> = createSelector(
    ConnectionSelectors.selectConnectionState,
    (state: ConnectionState) => state.selectedEventForwarder,
  );

  /**
   * getSelectedEventForwarderTemplate
   *
   * @static
   * @type {MemoizedSelector<MerlotState, EventForwarderTemplate>}
   * @memberof ConnectionSelectors
   */
  public static getSelectedEventForwarderTemplate: MemoizedSelector<MerlotState, EventForwarderTemplate> = createSelector(
    ConnectionSelectors.getEventForwardersTemplatesByName,
    ConnectionSelectors.getSelectedEventForwarder,
    (eventForwardersTemplatesByName: ObjectById<EventForwarderTemplate>, selectedEventForwarder: AvailableEventForwarder) => {
      if (!eventForwardersTemplatesByName || !selectedEventForwarder) {
        return;
      }
      return eventForwardersTemplatesByName[selectedEventForwarder.name];
    },
  );

  /**
   * getConnectionDeauthorizeRequest
   *
   * @static
   * @type {MemoizedSelector<MerlotState, ConnectionDeauthorizeRequest>}
   * @memberof ConnectionSelectors
   */
  public static getConnectionDeauthorizeRequest: MemoizedSelector<MerlotState, ConnectionDeauthorizeRequest> = createSelector(
    ConnectionSelectors.selectConnectionState,
    (state: ConnectionState) => state.connectionDeauthorizeRequest,
  );

  /**
   * isPreviewConnectionSlideOverOpen
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof ConnectionSelectors
   */
  public static isPreviewConnectionSlideOverOpen: MemoizedSelector<MerlotState, boolean> = createSelector(
    ConnectionSelectors.selectConnectionState,
    (state: ConnectionState) => state.connectionSlideOver.isOpen,
  );

  /**
   * getPreviewConnectionSlideOverModel
   *
   * @static
   * @type {MemoizedSelector<MerlotState, AvailableService>}
   * @memberof ConnectionSelectors
   */
  public static getPreviewConnectionSlideOverModel: MemoizedSelector<MerlotState, AvailableService> = createSelector(
    ConnectionSelectors.selectConnectionState,
    (state: ConnectionState) => state.connectionSlideOver.model,
  );

  /**
   * isActionTemplateUploadLoading
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof ConnectionSelectors
   */
  public static isActionTemplateUploadLoading: MemoizedSelector<MerlotState, boolean> = createSelector(
    ConnectionSelectors.selectConnectionState,
    (state: ConnectionState) => state.actionTemplateUploadLoading,
  );

  /**
   * getAvailablePartners
   *
   * @static
   * @type {MemoizedSelector<MerlotState, AvailablePartner[]>}
   * @memberof ConnectionSelectors
   */
  public static getAvailablePartners: MemoizedSelector<MerlotState, AvailablePartner[]> = createSelector(
    ConnectionSelectors.selectConnectionState,
    (state: ConnectionState) => state.availablePartners,
  );

  /**
   * getAvailableTrustNetworkPartners
   *
   * This selector will return all trust network partners which are supported in
   * the UI and enabled with feature flag, the partners which are returned from
   * the state will be clubbed with default partners data using helper if they are
   * not present. Helper takes care of feature flag as well.
   *
   * @static
   * @type {MemoizedSelector<MerlotState, AvailablePartner[]>}
   * @memberof ConnectionSelectors
   */
  public static getAvailableTrustNetworkPartners: MemoizedSelector<MerlotState, AvailablePartner[]> = createSelector(
    UserPreferenceFeatureControlsSelectors.isTrustNetworkFeatureEnabled,
    UserPreferenceCommonSelectors.getFeatureControls,
    ConnectionSelectors.getAvailablePartners,
    (isTrustNetworkEnabled: boolean, featureControls: FeatureControls, availablePartners: AvailablePartner[]) => {
      if (!isTrustNetworkEnabled) {
        return [];
      }
      return getDefaultAvailableTrustNetworkPartners(availablePartners, featureControls);
    },
  );

  /**
   * getAvailableOAuthPartners
   * @static
   * @type {MemoizedSelector<MerlotState, AvailablePartner[]>}
   * @memberof ConnectionSelectors
   */
  public static getAvailableOAuthPartners: MemoizedSelector<MerlotState, AvailablePartner[]> = createSelector(
    UserPreferenceCommonSelectors.getFeatureControls,
    ConnectionSelectors.getAvailablePartners,
    (featureControls: FeatureControls, availablePartners: AvailablePartner[]) => {
      return getDefaultAvailableOAuthPartners(availablePartners, featureControls);
    },
  );

  /**
   * getTrustNetworkPartnerConnectionModalMode
   *
   * @static
   * @type {MemoizedSelector<MerlotState, TrustNetworkPartnerConnectionModalMode>}
   * @memberof ConnectionSelectors
   */
  public static getTrustNetworkPartnerConnectionModalMode: MemoizedSelector<MerlotState, TrustNetworkPartnerConnectionModalMode> =
    createSelector(ConnectionSelectors.selectConnectionState, (state: ConnectionState) => state.trustNetworkPartnerConnectionModalMode);

  /**
   * isSetupOrEditTrustNetworkPartnerConnectionModalOpen
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof ConnectionSelectors
   */
  public static isSetupOrEditTrustNetworkPartnerConnectionModalOpen: MemoizedSelector<MerlotState, boolean> = createSelector(
    ConnectionSelectors.getTrustNetworkPartnerConnectionModalMode,
    (trustNetworkPartnerConnectionModalMode: TrustNetworkPartnerConnectionModalMode) =>
      trustNetworkPartnerConnectionModalMode === TrustNetworkPartnerConnectionModalMode.SETUP_OR_EDIT,
  );

  /**
   * isDeauthorizeTrustNetworkPartnerConnectionModalOpen
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof ConnectionSelectors
   */
  public static isDeauthorizeTrustNetworkPartnerConnectionModalOpen: MemoizedSelector<MerlotState, boolean> = createSelector(
    ConnectionSelectors.getTrustNetworkPartnerConnectionModalMode,
    (trustNetworkPartnerConnectionModalMode: TrustNetworkPartnerConnectionModalMode) =>
      trustNetworkPartnerConnectionModalMode === TrustNetworkPartnerConnectionModalMode.DEAUTHORIZE,
  );

  /**
   * getTrustNetworkPartnerConnectionModalSection
   *
   * trustNetworkPartnerConnectionModalSection is used to control different accordians inside the modal for Trust Network Partners,
   * it retunrs the value used to manage active accordian.
   *
   * @static
   * @type {MemoizedSelector<MerlotState, TrustNetworkPartnerConnectionModalSection>}
   * @memberof ConnectionSelectors
   */
  public static getTrustNetworkPartnerConnectionModalSection: MemoizedSelector<MerlotState, TrustNetworkPartnerConnectionModalSection> =
    createSelector(ConnectionSelectors.selectConnectionState, (state: ConnectionState) => state.trustNetworkPartnerConnectionModalSection);

  /**
   * getSelectedPartner
   *
   * @static
   * @type {MemoizedSelector<MerlotState, AvailablePartner>}
   * @memberof ConnectionSelectors
   */
  public static getSelectedPartner: MemoizedSelector<MerlotState, AvailablePartner> = createSelector(
    ConnectionSelectors.selectConnectionState,
    (state: ConnectionState) => state.selectedPartner,
  );

  /**
   * isSavingOrUpdatingPartnerConnection
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof ConnectionSelectors
   */
  public static isSavingOrUpdatingPartnerConnection: MemoizedSelector<MerlotState, boolean> = createSelector(
    ConnectionSelectors.selectConnectionState,
    (state: ConnectionState) => state.isSavingOrUpdatingPartnerConnection,
  );

  /**
   * isRegeneratingPartnerConnectionToken
   * Flag for showing loader at `Regenerate Token` link while regenrating the loader.
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof ConnectionSelectors
   */
  public static isRegeneratingPartnerConnectionToken: MemoizedSelector<MerlotState, boolean> = createSelector(
    ConnectionSelectors.selectConnectionState,
    (state: ConnectionState) => state.isRegeneratingPartnerConnectionToken,
  );

  /**
   * isDeauthorizingPartnerConnection
   * Flag for showing loader in the `Deauthorize` button while deauthorizing the Trust Network Partner.
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof ConnectionSelectors
   */
  public static isDeauthorizingPartnerConnection: MemoizedSelector<MerlotState, boolean> = createSelector(
    ConnectionSelectors.selectConnectionState,
    (state: ConnectionState) => state.isDeauthorizingPartnerConnection,
  );

  /**
   * getEventForwarderConnectionModalMode
   * Return Event Forwarder Connection Modal Modes like SETUP_OR_EDIT, DEAUTHORIZE.
   *
   * @static
   * @type {MemoizedSelector<MerlotState, EventForwarderConnectionModalMode>}
   * @memberof ConnectionSelectors
   */
  public static getEventForwarderConnectionModalMode: MemoizedSelector<MerlotState, EventForwarderConnectionModalMode> = createSelector(
    ConnectionSelectors.selectConnectionState,
    (state: ConnectionState) => state.eventForwarderConnectionModalMode,
  );

  /**
   * isSetupOrEditEventForwarderConnectionModalOpen
   * Returns true for SETUP_OR_EDIT modal mode for Event Forwarder Connection set up and edit modal flow.
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof ConnectionSelectors
   */
  public static isSetupOrEditEventForwarderConnectionModalOpen: MemoizedSelector<MerlotState, boolean> = createSelector(
    ConnectionSelectors.getEventForwarderConnectionModalMode,
    (eventForwarderConnectionModalMode: EventForwarderConnectionModalMode) =>
      eventForwarderConnectionModalMode === EventForwarderConnectionModalMode.SETUP_OR_EDIT,
  );

  /**
   * isConfirmEventForwarderConnectionModalOpen
   * Returns true for CONFIRM modal mode for Event Forwarder Connection confimation in edit modal flow.
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof ConnectionSelectors
   */
  public static isConfirmEventForwarderConnectionModalOpen: MemoizedSelector<MerlotState, boolean> = createSelector(
    ConnectionSelectors.getEventForwarderConnectionModalMode,
    (eventForwarderConnectionModalMode: EventForwarderConnectionModalMode) =>
      eventForwarderConnectionModalMode === EventForwarderConnectionModalMode.CONFIRM,
  );

  /**
   * isDeauthorizeEventForwarderConnectionModalOpen
   * Returns true for DEAUTHORIZE modal mode for Event Forwarder Connection confimation in edit modal flow.
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof ConnectionSelectors
   */
  public static isDeauthorizeEventForwarderConnectionModalOpen: MemoizedSelector<MerlotState, boolean> = createSelector(
    ConnectionSelectors.getEventForwarderConnectionModalMode,
    (eventForwarderConnectionModalMode: EventForwarderConnectionModalMode) =>
      eventForwarderConnectionModalMode === EventForwarderConnectionModalMode.DEAUTHORIZE,
  );

  /**
   * getEventForwarderConnectionModalSection
   * Return Event Forwarder Connection Modal Modes like SETUP_OR_EDIT, DEAUTHORIZE.
   *
   * @static
   * @type {MemoizedSelector<MerlotState, EventForwarderConnectionModalSection>}
   * @memberof ConnectionSelectors
   */
  public static getEventForwarderConnectionModalSection: MemoizedSelector<MerlotState, EventForwarderConnectionModalSection> =
    createSelector(ConnectionSelectors.selectConnectionState, (state: ConnectionState) => state.eventForwarderConnectionModalSection);

  /**
   * isSavingOrUpdatingEventForwarderConnection
   * Flag for showing loader in the `next` button while creating or updating the Event Forwarder Connection.
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof ConnectionSelectors
   */
  public static isSavingOrUpdatingEventForwarderConnection: MemoizedSelector<MerlotState, boolean> = createSelector(
    ConnectionSelectors.selectConnectionState,
    (state: ConnectionState) => state.isSavingOrUpdatingEventForwarderConnection,
  );

  /**
   * isDeauthorizingEventForwarderConnection
   * Flag for showing loader in the `Deauthorize` button while deauthorizing the Event Forwarder Connection.
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof ConnectionSelectors
   */
  public static isDeauthorizingEventForwarderConnection: MemoizedSelector<MerlotState, boolean> = createSelector(
    ConnectionSelectors.selectConnectionState,
    (state: ConnectionState) => state.isDeauthorizingEventForwarderConnection,
  );
}
