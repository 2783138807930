/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Params } from '@angular/router';
import { GenericObject } from '@dpa/ui-common';

import { AggregationWidgetChartType, StandardDashboardCardSize, Trend } from '@ws1c/intelligence-models';

/**
 * Standard Dashboard Card
 * @export
 * @class StandardDashboardCardComponent
 */
@Component({
  selector: 'dpa-standard-dashboard-card',
  templateUrl: 'standard-dashboard-card.component.html',
  styleUrls: ['standard-dashboard-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandardDashboardCardComponent {
  @Input() public hasBookmark: boolean;
  @Input() public isUserBookmarksEnabled: boolean;
  @Input() public trend: Trend;
  @Input() public title: string;
  @Input() public link: string;
  @Input() public customColors?: GenericObject[];
  @Input() public type?: AggregationWidgetChartType = AggregationWidgetChartType.DONUT;
  @Input() public size?: StandardDashboardCardSize = StandardDashboardCardSize.MD;
  @Input() public showXAxisLabel?: boolean = true;
  @Input() public xAxisLabelOverride?: string;
  @Input() public yAxisLabelOverride?: string;
  @Input() public yScaleMax?: number;
  @Input() public isPercentageOverride?: boolean;
  @Input() public showSeriesNames: boolean = false;
  @Input() public queryParams?: Params | null;
  @Output() public toggleBookmark: EventEmitter<boolean> = new EventEmitter();

  /**
   * onBookmarkClick
   * Handler for bookmark button click. Dispatches toggle bookmark event.
   * @memberof StandardDashboardCardComponent
   */
  public onBookmarkClick() {
    this.toggleBookmark.emit(!this.hasBookmark);
  }
}
