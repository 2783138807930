/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector, Selector } from '@ngrx/store';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { Ws1BrokerDirectory, WS1BrokerDirectoryType } from '@ws1c/intelligence-models';
import { WS1BrokerState } from './ws1-broker.state';

/**
 * WS1BrokerSelectors
 * @exports
 * @class WS1BrokerSelectors
 */
export class WS1BrokerSelectors {
  /**
   * selectWS1BrokerState
   * @static
   * @type {(state: MerlotState) => selectWS1BrokerState}
   * @memberof WS1BrokerSelectors
   */
  public static selectWS1BrokerState: Selector<MerlotState, WS1BrokerState> = (state: MerlotState): WS1BrokerState => {
    return state.ws1BrokerState;
  };

  /**
   * isWS1BrokerDirectoryConfigured
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof WS1BrokerSelectors
   */
  public static isWS1BrokerDirectoryConfigured: MemoizedSelector<MerlotState, boolean> = createSelector(
    WS1BrokerSelectors.selectWS1BrokerState,
    (state: WS1BrokerState) => {
      return state.ws1BrokerDirectories?.items?.some((directoryDetails: Ws1BrokerDirectory) => {
        return directoryDetails.type === WS1BrokerDirectoryType.PROVISIONED;
      });
    },
  );
}
