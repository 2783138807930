<clr-dropdown
  [clrCloseMenuOnItemClick]="false"
  (openChange)="onOpenChange($event)"
  class="selector-container dropdown"
>
  <dpa-local-search
    clrDropdownTrigger
    [showIcon]="false"
    [placeHolderText]="'COMMON_ACTIONS.SEARCH' | translate"
    [iconClass]="'search'"
    (onSearch)="searchValues($event)"
  ></dpa-local-search>
  <clr-dropdown-menu
    *clrIfOpen
    class="dropdown-menu"
  >
    <div>
      <div *ngIf="!singleSelection">
        <div class="clr-row clr-flex-items-sm-around">
          <a
            (click)="onAllCheckedChange(true)"
            (keyup.enter)="onAllCheckedChange(true)"
          >
            {{ 'REPORT_CUSTOMIZE.REPORT_FILTERS_SELECT_ALL' | translate }}
          </a>
          <a
            (click)="onAllCheckedChange(false)"
            (keyup.enter)="onAllCheckedChange(false)"
          >
            {{ 'REPORT_CUSTOMIZE.REPORT_FILTERS_CLEAR_ALL' | translate }}
          </a>
        </div>
        <div class="dropdown-divider"></div>
      </div>
      <div #valuesContainer>
        <div
          *ngIf="filterItems.length > 0"
          class="inner-dropdown-list"
        >
          <label
            *ngFor="let item of filterItems"
            clrDropdownItem
          >
            <input
              [ngModel]="item.checked"
              (ngModelChange)="onSelectItem($event, item)"
              type="checkbox"
            />
            <span [innerHTML]="inputFormatter ? inputFormatter(item.value) : item.label"></span>
          </label>
        </div>
        <div
          *ngIf="filterItems.length === 0"
          clrDropdownItem
        >
          {{ 'REPORT_CUSTOMIZE.REPORT_FILTERS_NO_VALUE_FOUND' | translate }}
        </div>
      </div>
      <div class="dropdown-divider"></div>
      <div class="clr-row clr-flex-items-sm-around">
        <a
          *ngIf="!isAddingCustomValue && allowCustomValue"
          (click)="isAddingCustomValue = true"
          (keyup.enter)="isAddingCustomValue = true"
          class="text-primary"
        >
          {{ 'REPORT_CUSTOMIZE.REPORT_FILTERS_ADD_CUSTOM_VALUE' | translate }}
        </a>
        <div
          *ngIf="isAddingCustomValue"
          (click)="$event.stopPropagation()"
          (keyup.enter)="$event.stopPropagation()"
        >
          <input
            [(ngModel)]="customValue"
            type="text"
          />
          <a
            [class.disabled]="!customValue"
            (click)="onAddCustomValue()"
            (keyup.enter)="onAddCustomValue()"
            class="text-primary"
          >
            {{ 'COMMON_ACTIONS.ADD' | translate }}
          </a>
          <a
            (click)="resetCustomValue()"
            (keyup.enter)="resetCustomValue()"
            class="text-primary"
          >
            {{ 'COMMON_ACTIONS.CANCEL' | translate }}
          </a>
        </div>
        <a
          *ngIf="!isAddingCustomValue"
          clrDropdownTrigger
          [class.disabled]="!filterItems.length || isAddingCustomValue"
          (click)="onApplySelectedValues()"
          (keyup.enter)="onApplySelectedValues()"
          class="text-primary"
        >
          {{ 'REPORT_CUSTOMIZE.REPORT_FILTERS_APPLY' | translate }}
        </a>
      </div>
    </div>
  </clr-dropdown-menu>
</clr-dropdown>
<!-- Display selected results -->
<ul
  *ngIf="selectedValues.length > 0"
  class="list-unstyled"
>
  <li *ngFor="let child of selectedValues.slice(0, 3); let i = index">
    <div>
      <span class="label">
        <span [innerHTML]="inputFormatter ? inputFormatter(child) : child"></span>
        <a
          (click)="removeSelectedValue(child)"
          (keyup.enter)="removeSelectedValue(child)"
          class="badge badge-clear"
          aria-label="Close"
          aria-hidden="true"
        >
          <cds-icon shape="times"></cds-icon>
        </a>
      </span>
    </div>
  </li>
  <li *ngIf="selectedValues.length > 3">
    <div class="signpost-item">
      <div class="trigger-item">
        <clr-signpost>
          <button
            clrSignpostTrigger
            class="btn label"
          >
            {{ 'REPORT_CUSTOMIZE.REPORT_FILTERS_ITEMS_MORE' | translate: { count: selectedValues.length - 3 } }}
          </button>
          <clr-signpost-content *clrIfOpen>
            <ul>
              <li
                *ngFor="let child of selectedValues.slice(3); let i = index"
                class="label"
              >
                <span [innerHTML]="inputFormatter ? inputFormatter(child) : child"></span>
                <a
                  (click)="removeSelectedValue(child)"
                  class="badge badge-clear"
                  aria-label="Close"
                  aria-hidden="true"
                >
                  <cds-icon shape="times"></cds-icon>
                </a>
              </li>
            </ul>
          </clr-signpost-content>
        </clr-signpost>
      </div>
    </div>
  </li>
</ul>
