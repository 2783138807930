/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { WS1BrokerActions } from './ws1-broker.actions';
import { initialWS1BrokerState, WS1BrokerState } from './ws1-broker.state';

/**
 * WS1 Broker State Reducer
 * @type {ActionReducer<WS1BrokerState>}
 */
const _reducer: ActionReducer<WS1BrokerState> = createReducer(
  initialWS1BrokerState,

  on(
    WS1BrokerActions.getBrokerDirectoriesSuccess,
    (state: WS1BrokerState, { ws1BrokerDirectories }: ReturnType<typeof WS1BrokerActions.getBrokerDirectoriesSuccess>): WS1BrokerState => ({
      ...state,
      ws1BrokerDirectories,
    }),
  ),

  on(
    WS1BrokerActions.getBrokerDirectoriesFailure,
    (state: WS1BrokerState): WS1BrokerState => ({
      ...state,
      ws1BrokerDirectories: null,
    }),
  ),
);

/**
 * WS1 Broker State Reducer
 *
 * @export
 * @param {WS1BrokerState} state
 * @param {Action} action
 * @returns {WS1BrokerState}
 */
export function ws1BrokerState(state: WS1BrokerState, action: Action): WS1BrokerState {
  return _reducer(state, action);
}
