/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { each, find } from 'lodash-es';

import { GlobalSearchWidget, StandardWidgetSubtype, Trend, TrendResult } from '@ws1c/intelligence-models';
import { GlobalSearchActions } from './global-search.actions';
import { GlobalSearchState, initialGlobalSearchState, OsData } from './global-search.state';

const _reducer: ActionReducer<GlobalSearchState> = createReducer(
  initialGlobalSearchState,
  on(
    GlobalSearchActions.getOsMetadataSuccess,
    (state: GlobalSearchState, { osMetaData }: ReturnType<typeof GlobalSearchActions.getOsMetadataSuccess>): GlobalSearchState => ({
      ...state,
      osMetaData,
    }),
  ),
  on(
    GlobalSearchActions.searchAutomations,
    GlobalSearchActions.searchDevices,
    GlobalSearchActions.searchOsUpdate,
    GlobalSearchActions.searchReports,
    GlobalSearchActions.searchUsers,
    GlobalSearchActions.searchWidgets,
    (state: GlobalSearchState): GlobalSearchState => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    GlobalSearchActions.searchAutomationsSuccess,
    (state: GlobalSearchState, { automations }: ReturnType<typeof GlobalSearchActions.searchAutomationsSuccess>): GlobalSearchState => ({
      ...state,
      loading: false,
      automationsData: automations,
    }),
  ),
  on(
    GlobalSearchActions.searchDevicesSuccess,
    (state: GlobalSearchState, { devices }: ReturnType<typeof GlobalSearchActions.searchDevicesSuccess>): GlobalSearchState => ({
      ...state,
      loading: false,
      devicesData: devices,
    }),
  ),
  on(
    GlobalSearchActions.searchOsUpdateSuccess,
    (state: GlobalSearchState, { results }: ReturnType<typeof GlobalSearchActions.searchOsUpdateSuccess>): GlobalSearchState => {
      const osData: OsData[] = [];
      const newData = results as Map<string, Trend>;
      newData.forEach((trend: Trend, subType: string) => {
        each(trend.trendResults, (trendResult: TrendResult) => {
          let os: OsData = find(osData, (data: OsData) => data.name === trendResult.bucketingAttributes[0].value);
          if (!os) {
            os = {
              name: trendResult.bucketingAttributes[0].value,
              integration: trend.trendDefinition.integration,
              devicesCount: 0,
              versionsCount: 0,
            };
            osData.push(os);
          }
          if (subType === StandardWidgetSubtype[StandardWidgetSubtype.OS_SUMMARY_DEVICES_BY_PLATFORM]) {
            os.devicesCount = trendResult.counters[0].result.value;
          } else if (subType === StandardWidgetSubtype[StandardWidgetSubtype.OS_SUMMARY_VERSIONS_BY_PLATFORM]) {
            os.versionsCount = trendResult.counters[0].result.value;
          }
        });
      });
      return {
        ...state,
        loading: false,
        osData,
      };
    },
  ),
  on(
    GlobalSearchActions.searchReportsSuccess,
    (state: GlobalSearchState, { reports }: ReturnType<typeof GlobalSearchActions.searchReportsSuccess>): GlobalSearchState => ({
      ...state,
      loading: false,
      reportsData: reports,
    }),
  ),
  on(
    GlobalSearchActions.searchWidgetsSuccess,
    (state: GlobalSearchState, { dashboards }: ReturnType<typeof GlobalSearchActions.searchWidgetsSuccess>): GlobalSearchState => {
      const widgetsData: GlobalSearchWidget[] = [];
      each(dashboards, (dashboard) => {
        dashboard.widgets.forEach((widget) => {
          widgetsData.push(
            Object.assign(new GlobalSearchWidget(), {
              ...widget,
              dashboardName: dashboard.name,
              dashboardId: dashboard.id,
              integration: widget.trend.trendDefinition.integration,
            }),
          );
        });
      });
      return {
        ...state,
        loading: false,
        widgetsData,
      };
    },
  ),
  on(
    GlobalSearchActions.searchAutomationsFailure,
    GlobalSearchActions.searchDevicesFailure,
    GlobalSearchActions.searchOsUpdateFailure,
    GlobalSearchActions.searchReportsFailure,
    GlobalSearchActions.searchUsersFailure,
    GlobalSearchActions.searchWidgetsFailure,
    (state: GlobalSearchState): GlobalSearchState => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    GlobalSearchActions.searchUsersSuccess,
    (state: GlobalSearchState, { users }: ReturnType<typeof GlobalSearchActions.searchUsersSuccess>): GlobalSearchState => ({
      ...state,
      loading: false,
      usersData: users,
    }),
  ),
);

/**
 * GlobalSearch State Reducer
 * @param {GlobalSearchState} state
 * @param {Action} action
 * @returns {GlobalSearchState}
 */
export function globalSearchState(state: GlobalSearchState, action: Action) {
  return _reducer(state, action);
}
