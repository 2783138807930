/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector } from '@ngrx/store';

import { AppLoadsSelectors } from '@dpa-shared-merlot/store/app-loads/app-loads.selectors';
import { AppDeploymentDashboardSelectors } from '@dpa-shared-merlot/store/dashboard/apps/app-deployment-dashboard.selectors';
import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { getSummaryCounter } from '@ws1c/intelligence-core/store/dashboard/dashboard-selector-helpers';
import { DashboardSelectors } from '@ws1c/intelligence-core/store/dashboard/dashboard.selectors';
import {
  App,
  ComparisonQueryResponse,
  StandardDashboardRequest,
  StandardDashboardType,
  StandardWidgetSubtype,
  Trend,
  TrendDateRange,
} from '@ws1c/intelligence-models';

/**
 * AppIdmDashboardSelectors
 * @export
 * @class AppIdmDashboardSelectors
 */
export class AppIdmDashboardSelectors {
  /**
   * getAppIdmDashboardRequest
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardRequest>}
   * @memberof AppIdmDashboardSelectors
   */
  public static getAppIdmDashboardRequest: MemoizedSelector<MerlotState, StandardDashboardRequest> = createSelector(
    AppLoadsSelectors.getSelectedApp,
    AppDeploymentDashboardSelectors.getIdmAppDashboardTrendDateRange,
    (selectedApp: App, trendDateRange: TrendDateRange) => {
      return new StandardDashboardRequest(StandardDashboardType.IDM_APPS, undefined, trendDateRange, {
        // this is passed directly to additional_params in the query
        objectname: selectedApp?.name,
      });
    },
  );

  /**
   * getUniqueUsersThirtyDaysComparisonQueryResponse
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof AppIdmDashboardSelectors
   */
  public static getUniqueUsersThirtyDaysComparisonQueryResponse: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (standardDashboardData: Map<string, Trend>) => {
      return getSummaryCounter(standardDashboardData, StandardWidgetSubtype.IDM_APP_UNIQUE_USERS_SUMMARY_30_DAYS);
    },
  );

  /**
   * getUniqueUsersTwentyFourHoursComparisonQueryResponse
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof AppIdmDashboardSelectors
   */
  public static getUniqueUsersTwentyFourHoursComparisonQueryResponse: MemoizedSelector<MerlotState, ComparisonQueryResponse> =
    createSelector(DashboardSelectors.getStandardDashboardData, (standardDashboardData: Map<string, Trend>) => {
      return getSummaryCounter(standardDashboardData, StandardWidgetSubtype.IDM_APP_UNIQUE_USERS_SUMMARY_24_HOURS);
    });

  /**
   * getAppLaunchesTwentyFourHoursComparisonQueryResponse
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof AppIdmDashboardSelectors
   */
  public static getAppLaunchesTwentyFourHoursComparisonQueryResponse: MemoizedSelector<MerlotState, ComparisonQueryResponse> =
    createSelector(DashboardSelectors.getStandardDashboardData, (standardDashboardData: Map<string, Trend>) => {
      return getSummaryCounter(standardDashboardData, StandardWidgetSubtype.IDM_APP_LAUNCH_SUMMARY);
    });
}
