/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { unsubscribe } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { ActiveWidgetDialogMode, CoreAppState, DashboardActions, DashboardSelectors } from '@ws1c/intelligence-core/store';
import { AggregationWidget, AlertBannerTarget, Dashboard } from '@ws1c/intelligence-models';

/**
 * Active Widget Copy Modal
 * @export
 * @class ActiveWidgetCopyModalComponent
 * @implements {OnInit, OnDestroy}
 */
@Component({
  selector: 'dpa-active-widget-copy-modal',
  templateUrl: 'active-widget-copy-modal.component.html',
  styleUrls: ['active-widget-copy-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveWidgetCopyModalComponent implements OnInit, OnDestroy {
  public activeWidget: AggregationWidget;
  public copyWidgetDialogOpen$: Observable<boolean>;
  public copyWidgetForm: UntypedFormGroup;
  public dashboardForm: UntypedFormGroup;
  public isNewDashboard: boolean = false;
  public subs: Subscription[];

  public readonly AlertBannerTarget = AlertBannerTarget;

  /**
   * Creates an instance of ActiveWidgetCopyModalComponent.
   * @param {Store<CoreAppState>} store
   * @param {UntypedFormBuilder} fb
   * @memberof ActiveWidgetCopyModalComponent
   */
  constructor(
    private store: Store<CoreAppState>,
    private fb: UntypedFormBuilder,
  ) {
    this.copyWidgetForm = this.fb.group({
      widgetName: ['', Validators.required],
      widgetDescription: [''],
    });
    this.copyWidgetDialogOpen$ = this.store
      .select(DashboardSelectors.getActiveWidgetDialogModeState)
      .pipe(map((activeWidgetDialogMode: ActiveWidgetDialogMode) => activeWidgetDialogMode === ActiveWidgetDialogMode.COPY));
  }

  /**
   *
   * @memberof ActiveWidgetCopyModalComponent
   */
  public ngOnInit() {
    this.subs = [
      this.store.select(DashboardSelectors.getActiveWidgetState).subscribe((activeWidget: AggregationWidget) => {
        this.activeWidget = activeWidget;
        this.copyWidgetForm.patchValue({
          widgetName: activeWidget?.name ?? '',
          widgetDescription: activeWidget?.description ?? '',
        });
      }),
    ];
  }

  /**
   * @memberof ActiveWidgetCopyModalComponent
   */
  public closeModal() {
    this.store.dispatch(DashboardActions.setActiveWidgetDialogMode({ mode: ActiveWidgetDialogMode.CLOSE }));
  }

  /**
   * @memberof ActiveWidgetCopyModalComponent
   */
  public ngOnDestroy() {
    unsubscribe(this.subs);
  }

  /**
   * Trigger Confirm Copy Widget Action
   *
   * @memberof ActiveWidgetCopyModalComponent
   */
  public confirmCopyWidget() {
    const widget = new AggregationWidget({
      ...this.activeWidget,
      dashboardId: this.dashboardForm.get('id')?.value,
      name: this.copyWidgetForm.get('widgetName').value,
      description: this.copyWidgetForm.get('widgetDescription').value,
    });
    const dashboard = new Dashboard({ ...this.dashboardForm.value });
    this.store.dispatch(
      DashboardActions.confirmCopyWidget({
        copyWidget: {
          dashboard,
          widget,
        },
      }),
    );
  }

  /**
   * isRequiredWarningVisible
   * @param {string} fieldName
   * @returns {boolean}
   * @memberof ActiveWidgetCopyModalComponent
   */
  public isRequiredWarningVisible(fieldName: string): boolean {
    const formControl = this.copyWidgetForm.get(fieldName);
    return formControl.invalid && (formControl.dirty || formControl.touched);
  }

  /**
   * onDashboardFormChange
   * @param {UntypedFormGroup} form
   * @memberof ActiveWidgetCopyModalComponent
   */
  public onDashboardFormChange(form: UntypedFormGroup) {
    this.dashboardForm = form;
  }

  /**
   * onToggleNewDashboard
   * @param {boolean} isNewDashboard
   * @memberof ActiveWidgetCopyModalComponent
   */
  public onToggleNewDashboard(isNewDashboard: boolean) {
    this.isNewDashboard = isNewDashboard;
  }
}
