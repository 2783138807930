<form
  [formGroup]="newDashboardForm"
  class="container"
>
  <div
    dpaTabEvent
    [class.selected]="!addNewSelected"
    (click)="toggleAddNewDashboard(false)"
    (keyup.enter)="toggleAddNewDashboard(false)"
    class="selectable"
    role="button"
  >
    <cds-icon
      [attr.solid]="!addNewSelected"
      [attr.status]="!addNewSelected ? 'success' : ''"
      size="28"
      shape="success-standard"
    ></cds-icon>
    <div class="input-container">
      <h4 class="mt-0 mb-2">
        {{ 'DASHBOARD_ACTIONS.EXISTING_DASHBOARDS' | translate }}
      </h4>
      <dpa-type-ahead
        [searchableItems]="dashboardList$ | async"
        [isSuggestionLoading]="isDashboardSearchInProgress$ | async"
        [keyBy]="dashboardById"
        [customFormatter]="dashboardNameFormatter"
        [labelFormatter]="dashboardNameFormatter"
        [placeholderText]="'DASHBOARD_ACTIONS.SELECT_DESTINATION_FOR_WIDGET' | translate"
        [autoFocus]="true"
        [customRowTemplate]="customRowTemplate"
        (selectedItemChange)="onSelect($event)"
        (onQueryChange)="onSearch($event)"
      ></dpa-type-ahead>
    </div>
  </div>
  <div
    dpaTabEvent
    [class.selected]="addNewSelected"
    (click)="toggleAddNewDashboard(true)"
    (keyup.enter)="toggleAddNewDashboard(true)"
    class="selectable"
    role="button"
  >
    <cds-icon
      [class.is-selected]="addNewSelected"
      [attr.solid]="addNewSelected"
      [attr.status]="addNewSelected ? 'success' : ''"
      size="28"
      shape="success-standard"
    ></cds-icon>
    <div class="input-container">
      <h4 class="mt-0 mb-2">
        {{ 'DASHBOARD_ACTIONS.A_NEW_DASHBOARD' | translate }}
      </h4>
      <input
        [placeholder]="'DASHBOARD_ACTIONS.NAME_YOUR_DASHBOARD' | translate"
        class="clr-input text-input"
        formControlName="name"
        id="name"
        required
        type="text"
      />
      <input
        [placeholder]="'COMMON_MESSAGES.ADD_DESCRIPTION_OPTIONAL' | translate"
        class="clr-input text-input"
        formControlName="description"
        id="description"
        type="text"
      />
    </div>
  </div>
</form>

<ng-template
  #customRowTemplate
  let-filteredResult="filteredResult"
  let-inFocus="inFocus"
>
  <div class="search-row">
    <div class="search-row row-item-name">
      <dpa-ellipsis-with-tooltip>
        {{ filteredResult.original.name | translate }}
      </dpa-ellipsis-with-tooltip>
    </div>
  </div>
</ng-template>
