<clr-input-container [class.rich-text-editor]="metadata?.presentationType === MetaFormFieldPresentationType.RICH_TEXT_EDITOR">
  <label>
    {{ metadata?.label ?? name }}
  </label>
  <input
    *ngIf="metadata?.presentationType !== MetaFormFieldPresentationType.RICH_TEXT_EDITOR; else quillViewHtml"
    clrInput
    [attr.placeholder]="metadata?.placeholderValue"
    [formControl]="formControl"
    [readOnly]="true"
    type="text"
  />
  <ng-template #quillViewHtml>
    <quill-view-html [content]="formControl.value"></quill-view-html>
  </ng-template>
</clr-input-container>
