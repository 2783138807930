/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Column, FocusedSeries, NgxChart } from '@ws1c/intelligence-models';

/**
 * LegendComponent
 * @export
 * @class LegendComponent
 */
@Component({
  selector: 'dpa-legend',
  templateUrl: 'legend.component.html',
  styleUrls: ['legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegendComponent {
  @Input() public ngxChart: NgxChart;
  @Input() public activeSeries: Set<string> = new Set<string>();
  @Input() public focusedSeries: FocusedSeries;
  @Input() public isExploreMode = false;
  @Input() public availableColumns: Column[] = [];
  @Input() public colorizedColumn: Column = undefined;
  @Output() public selectedColumnChange = new EventEmitter<Column>();
  @Output() public clearFocusedSeries = new EventEmitter();
  @Output() public selectSeries = new EventEmitter<string>();
  @Output() public activateSeries = new EventEmitter<string>();
  @Output() public deactivateSeries = new EventEmitter<string>();
}
