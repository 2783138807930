/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { AlertBannerEffects } from './alert-banner.effects';
import { AutomationCommonEffects } from './automation-common.effects';
import { BookmarksEffects } from './bookmarks.effects';
import { ConnectorCommonEffects } from './connector-common.effects';
import { DashboardEffects } from './dashboard.effects';
import { DeemCommonEffects } from './deem-common.effects';
import { DeviceProfileEffects } from './device-profile.effects';
import { IntegratedServicesEffects } from './integrated-services.effects';
import { IntegrationMetaEffects } from './integration-meta.effects';
import { MarketplaceEffects } from './marketplace.effects';
import { NavigationMenuEffects } from './navigation-menu.effects';
import { OrgEffects } from './org.effects';
import { PreviewCommonEffects } from './preview-common.effects';
import { QuickFilterEffects } from './quick-filter.effects';
import { RbacEffects } from './rbac.effects';
import { ReportCommonEffects } from './report-common.effects';
import { SolutionSetupEffects } from './solution-setup.effects';
import { TemplateCommonEffects } from './template-common.effects';
import { TrialServicesEffects } from './trial-services.effects';
import { UserPreferenceEffects } from './user-preference.effects';

export const CORE_EFFECTS = [
  // LOAD_USER_PREFERENCE effect happens before bootstrapping
  UserPreferenceEffects,
  AlertBannerEffects,
  AutomationCommonEffects,
  BookmarksEffects,
  ConnectorCommonEffects,
  DashboardEffects,
  DeemCommonEffects,
  DeviceProfileEffects,
  IntegratedServicesEffects,
  IntegrationMetaEffects,
  MarketplaceEffects,
  NavigationMenuEffects,
  OrgEffects,
  PreviewCommonEffects,
  QuickFilterEffects,
  RbacEffects,
  ReportCommonEffects,
  SolutionSetupEffects,
  TemplateCommonEffects,
  TrialServicesEffects,
];

export * from './alert-banner.effects';
export * from './automation-common.effects';
export * from './bookmarks.effects';
export * from './connector-common.effects';
export * from './deem-common.effects';
export * from './device-profile.effects';
export * from './integrated-services.effects';
export * from './integration-meta.effects';
export * from './navigation-menu.effects';
export * from './org.effects';
export * from './quick-filter.effects';
export * from './rbac.effects';
export * from './report-common.effects';
export * from './solution-setup.effects';
export * from './template-common.effects';
export * from './marketplace.effects';
export * from './trial-services.effects';
