/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { RuleGroup, TrendDateRange } from '@ws1c/intelligence-models';

export interface LoginRiskDashboardState {
  ruleGroup: RuleGroup;
  trendDateRange: TrendDateRange;
  hideBanner: boolean;
}

export const initialLoginRiskDashboardState: LoginRiskDashboardState = {
  hideBanner: false,
  ruleGroup: undefined,
  trendDateRange: undefined,
};
