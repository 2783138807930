/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { PagedRequest, SortOn, WebError } from '@dpa/ui-common';

import {
  AllHostTab,
  AppCrashGroupResponse,
  AppErrorCrashUploadsTableResponse,
  AppHeGroupResponse,
  CustomReportPreviewSearchResponse,
  LocalDataGridSettings,
  NetworkInsightsSearchResponse,
  NetworkInsightsUrlResponse,
  RuleGroup,
  TrendDateRange,
} from '@ws1c/intelligence-models';

/**
 * AppsDashboardState
 * @export
 * @interface AppsDashboardState
 */
export interface AppsDashboardState {
  appCrash: {
    groupError: WebError;
    groupLocalDataGridSettings: LocalDataGridSettings;
    groupResponse: AppCrashGroupResponse;
    heGroupResponse: AppHeGroupResponse;
    heGroupError: WebError;
    isHeGroupLoading: boolean;
    isGroupLoading: boolean;
    isUploadingFile: boolean;
    isUploadsLoading: boolean;
    mappingUploadAppVersion: string;
    uploadFileError: WebError;
    uploadsError: WebError;
    uploadsResponse: AppErrorCrashUploadsTableResponse;
    uploadsTableFilterString: string;
    uploadsTablePagedRequest: PagedRequest;
    uploadsTableSortOns: SortOn[];
  };
  appError: {
    breadcrumbsLoading: boolean;
    breadcrumbsPagedRequest: PagedRequest;
    breadcrumbsResponse: CustomReportPreviewSearchResponse;
    breadcrumbsSortOns: SortOn[];
    breadcrumbsVisible: boolean;
    stackTraceSearchResponse: CustomReportPreviewSearchResponse;
  };
  appHealth: string;
  appHeGroupLocalDataGridSettings: LocalDataGridSettings;
  appName: string;
  idmAppDashboardTrendDateRange: TrendDateRange;
  networkInsights: {
    allHostTab: AllHostTab;
    detailsGroupBy: string;
    errorTab: string;
    filter: string;
    host: string;
    groupBy: string;
    logError: WebError;
    logModalIsOpen: boolean;
    logPagedRequest: PagedRequest;
    logResponse: CustomReportPreviewSearchResponse;
    logSearchQuery: string;
    logSelectedTabName: string;
    logSortOns: SortOn[];
    sortOns: SortOn[];
    subFilterRuleGroup: RuleGroup;
    tablePagedRequest: PagedRequest;
    tableSearchResponse: NetworkInsightsSearchResponse;
    tableSearchError: WebError;
    urlsModalHostUrl: string;
    urlsResponse: NetworkInsightsUrlResponse;
    urlsError: WebError;
    viewingHistorical: boolean;
  };
  filterRuleGroup: RuleGroup;
  trendDateRange: TrendDateRange;
  userFlowsCardsSortOn: SortOn;
}

export const initialAppsDashboardState: AppsDashboardState = {
  appCrash: {
    groupError: undefined,
    groupLocalDataGridSettings: undefined,
    groupResponse: undefined,
    heGroupResponse: undefined,
    heGroupError: undefined,
    isHeGroupLoading: false,
    isGroupLoading: false,
    isUploadingFile: false,
    isUploadsLoading: false,
    mappingUploadAppVersion: undefined,
    uploadFileError: undefined,
    uploadsError: undefined,
    uploadsResponse: undefined,
    uploadsTableFilterString: undefined,
    uploadsTablePagedRequest: undefined,
    uploadsTableSortOns: undefined,
  },
  appError: {
    breadcrumbsLoading: false,
    breadcrumbsPagedRequest: undefined,
    breadcrumbsResponse: undefined,
    breadcrumbsSortOns: undefined,
    breadcrumbsVisible: false,
    stackTraceSearchResponse: undefined,
  },
  appHealth: undefined,
  appHeGroupLocalDataGridSettings: undefined,
  appName: undefined,
  idmAppDashboardTrendDateRange: undefined,
  networkInsights: {
    allHostTab: undefined,
    detailsGroupBy: undefined,
    errorTab: undefined,
    filter: undefined,
    host: undefined,
    groupBy: undefined,
    logError: undefined,
    logModalIsOpen: false,
    logPagedRequest: undefined,
    logResponse: undefined,
    logSearchQuery: undefined,
    logSelectedTabName: undefined,
    logSortOns: undefined,
    sortOns: undefined,
    subFilterRuleGroup: undefined,
    tablePagedRequest: undefined,
    tableSearchResponse: undefined,
    tableSearchError: undefined,
    urlsModalHostUrl: undefined,
    urlsResponse: undefined,
    urlsError: undefined,
    viewingHistorical: true,
  },
  filterRuleGroup: undefined,
  trendDateRange: undefined,
  userFlowsCardsSortOn: undefined,
};
