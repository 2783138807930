/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { createAction, props } from '@ngrx/store';

import { DateRangeFilters, RiskyUserDeviceSummaryLocator } from '@ws1c/intelligence-models';

/**
 * UserRiskDashboardActions
 * @export
 * @class UserRiskDashboardActions
 */
export class UserRiskDashboardActions {
  public static readonly CATEGORY = '[USER_RISK_DASHBOARD]';

  public static setDashboardFilters = createAction(`${UserRiskDashboardActions.CATEGORY} SET_FILTERS`, props<DateRangeFilters>());

  public static loadDashboard = createAction(`${UserRiskDashboardActions.CATEGORY} LOAD_DASHBOARD`);

  public static hideBanner = createAction(`${UserRiskDashboardActions.CATEGORY} HIDE_DASHBOARD_BANNER`);

  public static loadRiskyUserDeviceSummary = createAction(
    `${UserRiskDashboardActions.CATEGORY} LOAD_RISKY_USER_DEVICE_SUMMARY`,
    props<RiskyUserDeviceSummaryLocator>(),
  );

  public static loadRiskyUserDeviceSummarySuccess = createAction(
    `${UserRiskDashboardActions.CATEGORY} LOAD_RISKY_USER_DEVICE_SUMMARY_SUCCESS`,
    props<RiskyUserDeviceSummaryLocator>(),
  );

  public static loadRiskyUserDeviceSummaryFailure = createAction(
    `${UserRiskDashboardActions.CATEGORY} LOAD_RISKY_USER_DEVICE_SUMMARY_FAILURE`,
    props<RiskyUserDeviceSummaryLocator>(),
  );

  public static goToIDMSetup = createAction(`${UserRiskDashboardActions.CATEGORY} GO_TO_IDM_SETUP`);
}
