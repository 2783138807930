/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { createAction, props } from '@ngrx/store';

import { DateRangeFilters, SuggestionSearch } from '@ws1c/intelligence-models';

/**
 * OsUpdatesDashboardActions
 * @export
 * @class OsUpdatesDashboardActions
 */
export class OsUpdatesDashboardActions {
  public static readonly CATEGORY = '[DASHBOARD][OS_UPDATES]';

  public static loadOsSummaryDashboard = createAction(
    `${OsUpdatesDashboardActions.CATEGORY} LOAD_OS_SUMMARY_DASHBOARD`,
    props<{ preferenceDataNeeded: boolean }>(),
  );

  public static loadOsMergedDashboard = createAction(`${OsUpdatesDashboardActions.CATEGORY} LOAD_OS_MERGED_DASHBOARD`);

  public static setOsUpdateDevicePlatform = createAction(
    `${OsUpdatesDashboardActions.CATEGORY} SET_OS_UPDATE_DEVICE_PLATFORM`,
    props<{ platform: string }>(),
  );

  public static setOsUpdateDevicePlatformSuggestionSearch = createAction(
    `${OsUpdatesDashboardActions.CATEGORY} SET_OS_UPDATE_DEVICE_PLATFORM_SUGGESTION_SEARCH`,
    props<{ suggestionSearch: SuggestionSearch }>(),
  );

  public static loadOsVersionsDashboard = createAction(`${OsUpdatesDashboardActions.CATEGORY} LOAD_OS_VERSIONS_DASHBOARD`);

  public static updateOsVersionsDashboardFilters = createAction(
    `${OsUpdatesDashboardActions.CATEGORY} UPDATE_OS_VERSIONS_DASHBOARD_FILTERS`,
    props<DateRangeFilters>(),
  );

  public static loadOsPatchesDashboard = createAction(`${OsUpdatesDashboardActions.CATEGORY} LOAD_OS_PATCHES_DASHBOARD`);

  public static updateOsPatchesDashboardFilters = createAction(
    `${OsUpdatesDashboardActions.CATEGORY} UPDATE_OS_PATCHES_DASHBOARD_FILTERS`,
    props<DateRangeFilters>(),
  );
}
