/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { UntypedFormControl } from '@angular/forms';

import { JsonSchemaMetadata, LookupVariable } from '@ws1c/intelligence-models';

/**
 * BaseFormFieldComponent
 * @export
 * @abstract
 * @class BaseFormFieldComponent
 */
export abstract class BaseFormFieldComponent {
  public formControl: UntypedFormControl;
  public name: string;
  public metadata: JsonSchemaMetadata;
  public required: boolean;
  public defaultValue: string;
  public lookupVariables: LookupVariable[];
  public note: string;
  public id: string;

  /**
   * getFieldId
   * @param {number} index
   * @returns {string}
   * @memberof BaseFormFieldComponent
   */
  public getFieldId(index: number = 0): string {
    return `${this.id}-${this.name}${index}`;
  }
}
