/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector } from '@ngrx/store';

import { AppLoadsSelectors } from '@dpa-shared-merlot/store/app-loads';
import { AppsDashboardSelectors } from '@dpa-shared-merlot/store/dashboard/apps/apps-dashboard.selectors';
import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import {
  getFilterSortPagedResponse,
  getFirstCounterValue,
  getWidgetsDefaultTableColumnNames,
} from '@ws1c/intelligence-core/store/dashboard/dashboard-selector-helpers';
import { RuleGroup, StandardDashboardRequest, TrendDateRange } from '@ws1c/intelligence-models';
import * as helpers from './quentin-dashboard-selector-helpers';

export const quentinDashboardHelpers = {
  ...helpers,
  getFilterSortPagedResponse,
  getWidgetsDefaultTableColumnNames,
  getFirstCounterValue,
};

/**
 * QuentinDashboardSelectors
 * @export
 * @class QuentinDashboardSelectors
 */
export class QuentinDashboardSelectors {
  /**
   * getStandardQuentinErrorsDashboardRequest
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardRequest>}
   * @memberof QuentinDashboardSelectors
   */
  public static getStandardQuentinErrorsDashboardRequest: MemoizedSelector<MerlotState, StandardDashboardRequest> = createSelector(
    AppLoadsSelectors.getSelectedAppId,
    AppLoadsSelectors.getSelectedApteligentId,
    AppLoadsSelectors.getSelectedAppPlatform,
    AppsDashboardSelectors.getAppDashboardFilterRuleGroup,
    AppsDashboardSelectors.getAppDashboardTrendDateRange,
    (
      appId: string,
      apteligentId: string,
      appPlatform: string,
      appDashboardFilterRuleGroup: RuleGroup,
      appDashboardTrendDateRange: TrendDateRange,
    ) =>
      quentinDashboardHelpers.buildStandardAppQuentinDashboardRequest(
        appId,
        apteligentId,
        appPlatform,
        appDashboardFilterRuleGroup,
        appDashboardTrendDateRange,
      ),
  );
}
