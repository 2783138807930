/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import {
  AutomationSearchRequest,
  CustomReportSearchRequest,
  DashboardSearchRequest,
  GenericSearchRequest,
} from '@ws1c/intelligence-models';
import { SystemLimitsActions } from './system-limits.actions';
import { initialSystemLimitsState, SystemLimitsState } from './system-limits.state';

/**
 * systemLimitReducer
 *
 * @type {ActionReducer<SystemLimitsState>}
 */
const systemLimitReducer: ActionReducer<SystemLimitsState> = createReducer(
  initialSystemLimitsState,

  on(SystemLimitsActions.loadUsageSummary, (state: SystemLimitsState): SystemLimitsState => {
    return {
      ...state,
      usageDetailsLoading: true,
    };
  }),

  on(
    SystemLimitsActions.loadUsageSummarySuccess,
    (state: SystemLimitsState, { usageDetails }: ReturnType<typeof SystemLimitsActions.loadUsageSummarySuccess>): SystemLimitsState => {
      return {
        ...state,
        usageDetails,
        usageDetailsLoading: false,
      };
    },
  ),

  on(SystemLimitsActions.loadUsageSummaryFailure, (state: SystemLimitsState): SystemLimitsState => {
    return {
      ...state,
      usageDetailsLoading: false,
    };
  }),

  on(
    SystemLimitsActions.setActiveTab,
    (state: SystemLimitsState, { activeTab }: ReturnType<typeof SystemLimitsActions.setActiveTab>): SystemLimitsState => {
      return {
        ...state,
        activeTab,
      };
    },
  ),

  on(
    SystemLimitsActions.loadSystemLimits,
    (
      state: SystemLimitsState,
      { systemLimitsSearchRequest }: ReturnType<typeof SystemLimitsActions.loadSystemLimits>,
    ): SystemLimitsState => {
      return {
        ...state,
        systemLimitsSearchRequest,
        isSystemLimitsSearchInProgress: true,
      };
    },
  ),

  on(
    SystemLimitsActions.loadSystemLimitsSuccess,
    (
      state: SystemLimitsState,
      { systemLimitsSearchResponse }: ReturnType<typeof SystemLimitsActions.loadSystemLimitsSuccess>,
    ): SystemLimitsState => {
      return {
        ...state,
        systemLimitsSearchResponse,
        isSystemLimitsSearchInProgress: false,
      };
    },
  ),

  on(SystemLimitsActions.loadSystemLimitsFailure, (state: SystemLimitsState): SystemLimitsState => {
    return {
      ...state,
      isSystemLimitsSearchInProgress: false,
    };
  }),

  on(SystemLimitsActions.refreshSystemLimits, (state: SystemLimitsState): SystemLimitsState => {
    return {
      ...state,
      isSystemLimitsSearchInProgress: true,
    };
  }),

  on(
    SystemLimitsActions.changeSystemLimitsPagination,
    (
      state: SystemLimitsState,
      { pagedRequest }: ReturnType<typeof SystemLimitsActions.changeSystemLimitsPagination>,
    ): SystemLimitsState => ({
      ...state,
      systemLimitsSearchRequest: new GenericSearchRequest({
        ...state.systemLimitsSearchRequest,
        ...pagedRequest,
      }),
      isSystemLimitsSearchInProgress: true,
    }),
  ),

  on(
    SystemLimitsActions.sortSystemLimits,
    (state: SystemLimitsState, { sortOns }: ReturnType<typeof SystemLimitsActions.sortSystemLimits>): SystemLimitsState => ({
      ...state,
      systemLimitsSearchRequest: new GenericSearchRequest({
        ...state.systemLimitsSearchRequest,
        sortOns,
        from: 0,
      }),
      isSystemLimitsSearchInProgress: true,
    }),
  ),

  on(
    SystemLimitsActions.filterSystemLimits,
    (state: SystemLimitsState, { searchTerms }: ReturnType<typeof SystemLimitsActions.filterSystemLimits>): SystemLimitsState => ({
      ...state,
      systemLimitsSearchRequest: new GenericSearchRequest({
        ...state.systemLimitsSearchRequest,
        searchTerms,
      }),
      isSystemLimitsSearchInProgress: true,
    }),
  ),

  on(SystemLimitsActions.openSystemLimitsModal, (state: SystemLimitsState): SystemLimitsState => {
    return {
      ...state,
      systemLimitsModalOpen: true,
    };
  }),

  on(SystemLimitsActions.closeSystemLimitsModal, (state: SystemLimitsState): SystemLimitsState => {
    return {
      ...state,
      systemLimitsModalOpen: false,
    };
  }),

  on(SystemLimitsActions.loadServiceLimits, (state: SystemLimitsState): SystemLimitsState => {
    return {
      ...state,
      serviceLimitsLoading: true,
    };
  }),

  on(
    SystemLimitsActions.loadServiceLimitsSuccess,
    (state: SystemLimitsState, { serviceLimits }: ReturnType<typeof SystemLimitsActions.loadServiceLimitsSuccess>): SystemLimitsState => {
      return {
        ...state,
        serviceLimits,
        serviceLimitsLoading: false,
      };
    },
  ),

  on(SystemLimitsActions.loadServiceLimitsFailure, (state: SystemLimitsState): SystemLimitsState => {
    return {
      ...state,
      serviceLimitsLoading: false,
    };
  }),

  on(
    SystemLimitsActions.setSelectedAccountId,
    (state: SystemLimitsState, { selectedAccountId }: ReturnType<typeof SystemLimitsActions.setSelectedAccountId>): SystemLimitsState => {
      return {
        ...state,
        userSystemLimit: {
          ...state.userSystemLimit,
          selectedAccountId,
        },
      };
    },
  ),

  on(
    SystemLimitsActions.getSelectedAccountDetailsSuccess,
    (
      state: SystemLimitsState,
      { selectedAccount }: ReturnType<typeof SystemLimitsActions.getSelectedAccountDetailsSuccess>,
    ): SystemLimitsState => {
      return {
        ...state,
        userSystemLimit: {
          ...state.userSystemLimit,
          selectedAccount,
        },
      };
    },
  ),

  on(SystemLimitsActions.loadUserAutomationsUsageSummary, (state: SystemLimitsState): SystemLimitsState => {
    return {
      ...state,
      userSystemLimit: {
        ...state.userSystemLimit,
        automationsUsageSummaryLoading: true,
      },
    };
  }),

  on(
    SystemLimitsActions.loadUserAutomationsUsageSummarySuccess,
    (
      state: SystemLimitsState,
      { automationsUsageSummary }: ReturnType<typeof SystemLimitsActions.loadUserAutomationsUsageSummarySuccess>,
    ): SystemLimitsState => {
      return {
        ...state,
        userSystemLimit: {
          ...state.userSystemLimit,
          automationsUsageSummary,
          automationsUsageSummaryLoading: false,
        },
      };
    },
  ),

  on(SystemLimitsActions.loadUserAutomationsUsageSummaryFailure, (state: SystemLimitsState): SystemLimitsState => {
    return {
      ...state,
      userSystemLimit: {
        ...state.userSystemLimit,
        automationsUsageSummaryLoading: false,
      },
    };
  }),

  on(SystemLimitsActions.loadUserCustomReportsUsageSummary, (state: SystemLimitsState): SystemLimitsState => {
    return {
      ...state,
      userSystemLimit: {
        ...state.userSystemLimit,
        customReportsUsageSummaryLoading: true,
      },
    };
  }),

  on(
    SystemLimitsActions.loadUserCustomReportsUsageSummarySuccess,
    (
      state: SystemLimitsState,
      { customReportsUsageSummary }: ReturnType<typeof SystemLimitsActions.loadUserCustomReportsUsageSummarySuccess>,
    ): SystemLimitsState => {
      return {
        ...state,
        userSystemLimit: {
          ...state.userSystemLimit,
          customReportsUsageSummary,
          customReportsUsageSummaryLoading: false,
        },
      };
    },
  ),

  on(SystemLimitsActions.loadUserCustomReportsUsageSummaryFailure, (state: SystemLimitsState): SystemLimitsState => {
    return {
      ...state,
      userSystemLimit: {
        ...state.userSystemLimit,
        customReportsUsageSummaryLoading: false,
      },
    };
  }),

  on(SystemLimitsActions.loadUserDashboardsUsageSummary, (state: SystemLimitsState): SystemLimitsState => {
    return {
      ...state,
      userSystemLimit: {
        ...state.userSystemLimit,
        dashboardsUsageSummaryLoading: true,
      },
    };
  }),

  on(
    SystemLimitsActions.loadUserDashboardsUsageSummarySuccess,
    (
      state: SystemLimitsState,
      { dashboardsUsageSummary }: ReturnType<typeof SystemLimitsActions.loadUserDashboardsUsageSummarySuccess>,
    ): SystemLimitsState => {
      return {
        ...state,
        userSystemLimit: {
          ...state.userSystemLimit,
          dashboardsUsageSummary,
          dashboardsUsageSummaryLoading: false,
        },
      };
    },
  ),

  on(SystemLimitsActions.loadUserDashboardsUsageSummaryFailure, (state: SystemLimitsState): SystemLimitsState => {
    return {
      ...state,
      userSystemLimit: {
        ...state.userSystemLimit,
        dashboardsUsageSummaryLoading: false,
      },
    };
  }),

  on(SystemLimitsActions.loadUserServiceLimits, (state: SystemLimitsState): SystemLimitsState => {
    return {
      ...state,
      userSystemLimit: {
        ...state.userSystemLimit,
        serviceLimitsLoading: true,
      },
    };
  }),

  on(
    SystemLimitsActions.loadUserServiceLimitsSuccess,
    (
      state: SystemLimitsState,
      { serviceLimits }: ReturnType<typeof SystemLimitsActions.loadUserServiceLimitsSuccess>,
    ): SystemLimitsState => {
      return {
        ...state,
        userSystemLimit: {
          ...state.userSystemLimit,
          serviceLimits,
          serviceLimitsLoading: false,
        },
      };
    },
  ),

  on(SystemLimitsActions.loadUserServiceLimitsFailure, (state: SystemLimitsState): SystemLimitsState => {
    return {
      ...state,
      userSystemLimit: {
        ...state.userSystemLimit,
        serviceLimitsLoading: false,
      },
    };
  }),

  on(
    SystemLimitsActions.loadUserAutomations,
    (
      state: SystemLimitsState,
      { automationSearchRequest }: ReturnType<typeof SystemLimitsActions.loadUserAutomations>,
    ): SystemLimitsState => {
      return {
        ...state,
        userSystemLimit: {
          ...state.userSystemLimit,
          automationSearchRequest,
          isAutomationSearchInProgress: true,
        },
      };
    },
  ),

  on(
    SystemLimitsActions.loadUserAutomationsSuccess,
    (
      state: SystemLimitsState,
      { automationSearchResponse }: ReturnType<typeof SystemLimitsActions.loadUserAutomationsSuccess>,
    ): SystemLimitsState => {
      return {
        ...state,
        userSystemLimit: {
          ...state.userSystemLimit,
          automationSearchResponse,
          isAutomationSearchInProgress: false,
        },
      };
    },
  ),

  on(SystemLimitsActions.loadUserAutomationsFailure, (state: SystemLimitsState): SystemLimitsState => {
    return {
      ...state,
      userSystemLimit: {
        ...state.userSystemLimit,
        isAutomationSearchInProgress: false,
      },
    };
  }),

  on(
    SystemLimitsActions.refreshUserAutomations,
    (state: SystemLimitsState): SystemLimitsState => ({
      ...state,
      userSystemLimit: {
        ...state.userSystemLimit,
        isAutomationSearchInProgress: true,
      },
    }),
  ),

  on(
    SystemLimitsActions.changeUserAutomationsPagination,
    (
      state: SystemLimitsState,
      { pagedRequest }: ReturnType<typeof SystemLimitsActions.changeUserAutomationsPagination>,
    ): SystemLimitsState => ({
      ...state,
      userSystemLimit: {
        ...state.userSystemLimit,
        automationSearchRequest: new AutomationSearchRequest({
          ...state.userSystemLimit.automationSearchRequest,
          ...pagedRequest,
        }),
        isAutomationSearchInProgress: true,
      },
    }),
  ),

  on(
    SystemLimitsActions.sortUserAutomations,
    (state: SystemLimitsState, { sortOns }: ReturnType<typeof SystemLimitsActions.sortUserAutomations>): SystemLimitsState => ({
      ...state,
      userSystemLimit: {
        ...state.userSystemLimit,
        automationSearchRequest: new AutomationSearchRequest({
          ...state.userSystemLimit.automationSearchRequest,
          sortOns,
          from: 0,
        }),
        isAutomationSearchInProgress: true,
      },
    }),
  ),

  on(
    SystemLimitsActions.loadUserCustomReports,
    (
      state: SystemLimitsState,
      { customReportSearchRequest }: ReturnType<typeof SystemLimitsActions.loadUserCustomReports>,
    ): SystemLimitsState => {
      return {
        ...state,
        userSystemLimit: {
          ...state.userSystemLimit,
          customReportSearchRequest,
          isCustomReportSearchInProgress: true,
        },
      };
    },
  ),

  on(
    SystemLimitsActions.loadUserCustomReportsSuccess,
    (
      state: SystemLimitsState,
      { customReportSearchResponse }: ReturnType<typeof SystemLimitsActions.loadUserCustomReportsSuccess>,
    ): SystemLimitsState => {
      return {
        ...state,
        userSystemLimit: {
          ...state.userSystemLimit,
          customReportSearchResponse,
          isCustomReportSearchInProgress: false,
        },
      };
    },
  ),

  on(SystemLimitsActions.loadUserCustomReportsFailure, (state: SystemLimitsState): SystemLimitsState => {
    return {
      ...state,
      userSystemLimit: {
        ...state.userSystemLimit,
        isCustomReportSearchInProgress: false,
      },
    };
  }),

  on(
    SystemLimitsActions.refreshUserCustomReports,
    (state: SystemLimitsState): SystemLimitsState => ({
      ...state,
      userSystemLimit: {
        ...state.userSystemLimit,
        isCustomReportSearchInProgress: true,
      },
    }),
  ),

  on(
    SystemLimitsActions.changeUserCustomReportsPagination,
    (
      state: SystemLimitsState,
      { pagedRequest }: ReturnType<typeof SystemLimitsActions.changeUserCustomReportsPagination>,
    ): SystemLimitsState => ({
      ...state,
      userSystemLimit: {
        ...state.userSystemLimit,
        customReportSearchRequest: new CustomReportSearchRequest({
          ...state.userSystemLimit.customReportSearchRequest,
          ...pagedRequest,
        }),
        isCustomReportSearchInProgress: true,
      },
    }),
  ),

  on(
    SystemLimitsActions.sortUserCustomReports,
    (state: SystemLimitsState, { sortOns }: ReturnType<typeof SystemLimitsActions.sortUserCustomReports>): SystemLimitsState => ({
      ...state,
      userSystemLimit: {
        ...state.userSystemLimit,
        customReportSearchRequest: new CustomReportSearchRequest({
          ...state.userSystemLimit.customReportSearchRequest,
          sortOns,
          from: 0,
        }),
        isCustomReportSearchInProgress: true,
      },
    }),
  ),

  on(
    SystemLimitsActions.loadUserDashboards,
    (
      state: SystemLimitsState,
      { dashboardSearchRequest }: ReturnType<typeof SystemLimitsActions.loadUserDashboards>,
    ): SystemLimitsState => {
      return {
        ...state,
        userSystemLimit: {
          ...state.userSystemLimit,
          dashboardSearchRequest,
          isDashboardSearchInProgress: true,
        },
      };
    },
  ),

  on(
    SystemLimitsActions.loadUserDashboardsSuccess,
    (
      state: SystemLimitsState,
      { dashboardSearchResponse }: ReturnType<typeof SystemLimitsActions.loadUserDashboardsSuccess>,
    ): SystemLimitsState => {
      return {
        ...state,
        userSystemLimit: {
          ...state.userSystemLimit,
          dashboardSearchResponse,
          isDashboardSearchInProgress: false,
        },
      };
    },
  ),

  on(SystemLimitsActions.loadUserDashboardsFailure, (state: SystemLimitsState): SystemLimitsState => {
    return {
      ...state,
      userSystemLimit: {
        ...state.userSystemLimit,
        isDashboardSearchInProgress: false,
      },
    };
  }),

  on(
    SystemLimitsActions.refreshUserDashboards,
    (state: SystemLimitsState): SystemLimitsState => ({
      ...state,
      userSystemLimit: {
        ...state.userSystemLimit,
        isDashboardSearchInProgress: true,
      },
    }),
  ),

  on(
    SystemLimitsActions.changeUserDashboardsPagination,
    (
      state: SystemLimitsState,
      { pagedRequest }: ReturnType<typeof SystemLimitsActions.changeUserDashboardsPagination>,
    ): SystemLimitsState => ({
      ...state,
      userSystemLimit: {
        ...state.userSystemLimit,
        dashboardSearchRequest: new DashboardSearchRequest({
          ...state.userSystemLimit.dashboardSearchRequest,
          ...pagedRequest,
        }),
        isDashboardSearchInProgress: true,
      },
    }),
  ),

  on(
    SystemLimitsActions.sortUserDashboards,
    (state: SystemLimitsState, { sortOns }: ReturnType<typeof SystemLimitsActions.sortUserDashboards>): SystemLimitsState => ({
      ...state,
      userSystemLimit: {
        ...state.userSystemLimit,
        dashboardSearchRequest: new DashboardSearchRequest({
          ...state.userSystemLimit.dashboardSearchRequest,
          sortOns,
          from: 0,
        }),
        isDashboardSearchInProgress: true,
      },
    }),
  ),

  on(SystemLimitsActions.openUserSystemLimitsModal, (state: SystemLimitsState): SystemLimitsState => {
    return {
      ...state,
      userSystemLimit: {
        ...state.userSystemLimit,
        userSystemLimitsModalOpen: true,
      },
    };
  }),

  on(SystemLimitsActions.closeUserSystemLimitsModal, (state: SystemLimitsState): SystemLimitsState => {
    return {
      ...state,
      userSystemLimit: {
        ...state.userSystemLimit,
        userSystemLimitsModalOpen: false,
      },
    };
  }),
);

/**
 * systemLimitsReducer
 * @param {SystemLimitsState} state
 * @param {Action} action
 * @returns {SystemLimitsState}
 */
export function systemLimitsState(state: SystemLimitsState, action: Action): SystemLimitsState {
  return systemLimitReducer(state, action);
}
