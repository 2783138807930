/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { NonAdminActions } from './non-admin.actions';
import { initialNonAdminState, NonAdminState } from './non-admin.state';

const _reducer: ActionReducer<NonAdminState> = createReducer(
  initialNonAdminState,

  on(
    NonAdminActions.getReportShareListSuccess,
    (state: NonAdminState, { reportShareList }: ReturnType<typeof NonAdminActions.getReportShareListSuccess>): NonAdminState => ({
      ...state,
      reportShareList,
    }),
  ),
  on(
    NonAdminActions.getReportShareDetailsSuccess,
    (state: NonAdminState, { reportDetails }: ReturnType<typeof NonAdminActions.getReportShareDetailsSuccess>): NonAdminState => ({
      ...state,
      reportDetails,
    }),
  ),
);

/**
 * Non Admin Reducer
 *
 * @export
 * @param {NonAdminState} state
 * @param {Action} action
 * @returns {NonAdminState}
 */
export function nonAdminState(state: NonAdminState, action: Action): NonAdminState {
  return _reducer(state, action);
}
