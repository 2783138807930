/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BaseFormFieldComponent } from '@ws1c/intelligence-core/components/dynamic-form/components/form-field/components/base-form-field.component';
import { MetaFormFieldPresentationType } from '@ws1c/intelligence-models';

/**
 * ReadOnlyFormFieldComponent
 * @export
 * @class ReadOnlyFormFieldComponent
 * @extends {BaseFormFieldComponent}
 */
@Component({
  selector: 'dpa-read-only-form-field',
  templateUrl: 'read-only-form-field.component.html',
  styleUrls: ['read-only-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadOnlyFormFieldComponent extends BaseFormFieldComponent {
  public readonly MetaFormFieldPresentationType = MetaFormFieldPresentationType;
}
