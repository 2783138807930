/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

// libs
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { LocalSearchComponent } from '@dpa/ui-common';
import { without } from 'lodash-es';

/**
 * Display MUTLISELECT Component with selected values
 *
 * @export
 * @class CommonValueSelectorComponent
 */
@Component({
  selector: 'dpa-common-value-selector',
  templateUrl: 'common-value-selector.component.html',
  styleUrls: ['common-value-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonValueSelectorComponent implements OnInit, OnChanges {
  @Input() public inputFormatter?: any;
  @Input() public allowCustomValue?: boolean = true;
  @Input() public required?: boolean = true;
  @Input() public filterValues?: string[] = [];
  @Input() public selectedValues?: string[] = [];
  @Input() public staticValues?: boolean = false;
  @Input() public singleSelection?: boolean = false;
  @Output() public onDone = new EventEmitter<any[]>();
  @Output() public onSearchValues = new EventEmitter<string>();

  @ViewChild('valuesContainer') public valuesContainer;
  @ViewChild(LocalSearchComponent, { static: true }) public localSearchComponent: LocalSearchComponent;

  public isAddingCustomValue: boolean = false;
  public customValue: string = '';
  public filterItems: any[] = [];

  private selectingItems: any = {};

  /**
   * Init View
   *
   * @memberof CommonValueSelectorComponent
   */
  public ngOnInit() {
    this.initFilters('');
  }

  /**
   * Update view when input values changed
   *
   * @param {SimpleChanges} changes
   * @memberof CommonValueSelectorComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.filterValues) {
      this.initFilters('');
    }
    if (changes.singleSelection && changes.singleSelection.currentValue !== changes.singleSelection.previousValue) {
      if (changes.singleSelection.currentValue === true) {
        this.onDone.emit(this.selectedValues.slice(0, 1));
      }
    }
  }

  /**
   * Handle search action
   * Filter local data (staticValues) or trigger suggestion API to load remote data
   *
   * @param {string} query
   * @memberof CommonValueSelectorComponent
   */
  public searchValues(query: string) {
    if (this.staticValues) {
      this.initFilters(query);
    } else {
      this.onSearchValues.emit(query);
    }
  }

  /**
   *  Handle item selection
   *  Support both single and multiple selection mode
   *
   * @param {any} checked
   * @param {any} currentItem
   * @memberof CommonValueSelectorComponent
   */
  public onSelectItem(checked, currentItem) {
    if (this.singleSelection) {
      this.filterItems.forEach((item) => {
        if (item !== currentItem) {
          item.checked = false;
        }
        this.selectingItems = {};
      });
    }
    currentItem.checked = checked;
    this.selectingItems[currentItem.value] = checked;
  }

  /**
   * Remove selected value from current list
   * Trigger event to host component
   *
   * @param {string} value
   * @memberof CommonValueSelectorComponent
   */
  public removeSelectedValue(value: string) {
    this.onDone.emit(without(this.selectedValues, value));
  }

  /**
   * Commit all selected values
   * Reset current filter
   *
   * @memberof CommonValueSelectorComponent
   */
  public onApplySelectedValues() {
    const selectedItems: any[] = [];
    Object.keys(this.selectingItems).forEach((key) => {
      if (this.selectingItems[key]) {
        selectedItems.push(key);
      }
    });
    this.onDone.emit(selectedItems);
    this.localSearchComponent.reset();
  }

  /**
   * Add custom value into existing list
   *
   * @memberof CommonValueSelectorComponent
   */
  public onAddCustomValue() {
    const newItem = {
      label: this.customValue,
      value: this.customValue,
      checked: true,
    };
    if (this.singleSelection) {
      this.onAllCheckedChange(false);
    }
    this.filterItems.unshift(newItem);
    this.selectingItems[newItem.value] = true;
    this.resetCustomValue();
    setTimeout(() => {
      this.valuesContainer.nativeElement.scrollTop = 0;
    }, 200);
  }

  /**
   * Reset custom value state on UI
   *
   * @memberof CommonValueSelectorComponent
   */
  public resetCustomValue() {
    this.isAddingCustomValue = false;
    this.customValue = '';
  }

  /**
   * Reset selected items of active list only
   * This doesn't effect any hidden selected items
   *
   * @param {boolean} checked
   * @memberof CommonValueSelectorComponent
   */
  public onAllCheckedChange(checked: boolean) {
    this.filterItems.forEach((item) => {
      item.checked = checked;
      this.selectingItems[item.value] = checked;
    });
  }

  /**
   * Dropdown event callback
   * Reset search box on showing dropdown
   *
   * @param {boolean} _open
   * @memberof CommonValueSelectorComponent
   */
  public onOpenChange(_open: boolean) {
    this.localSearchComponent.reset();
  }

  /**
   * Init filter items and internal selecting items
   *
   * @private
   * @param {string} [query='']
   * @memberof CommonValueSelectorComponent
   */
  private initFilters(query: string = '') {
    this.selectedValues.forEach((item) => {
      this.selectingItems[item] = true;
    });
    let filterValues = this.filterValues;
    if (this.staticValues && filterValues && filterValues.length > 0 && query) {
      filterValues = filterValues.filter((item) => item.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    this.filterItems = filterValues.map((value) => {
      const node = {
        label: value,
        value,
        checked: false,
      };
      if ((Array.isArray(this.selectedValues) && this.selectedValues.indexOf(value) >= 0) || this.selectedValues.toString() === value) {
        node.checked = true;
      }
      return node;
    });
  }
}
