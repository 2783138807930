/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { AccountActions } from './account/account.actions';
import { AppLoadsActions } from './app-loads/app-loads.actions';
import { ConnectionActions } from './connection/connection.actions';
import { DataAccessPolicyActions } from './data-access-policy/data-access-policy.actions';
import { GlobalSearchActions } from './global-search/global-search.actions';
import { IntegrationActions } from './integration/integration.actions';
import { NonAdminActions } from './non-admin/non-admin.actions';
import { ServiceAccountsActions } from './service-accounts/service-accounts.actions';
import { SystemLimitsActions } from './system-limits/system-limits.actions';
import { UserActions } from './user/user.actions';
import { WS1BrokerActions } from './ws1-broker/ws1-broker.actions';

export const MERLOT_ACTION_PROVIDERS: any[] = [
  AccountActions,
  AppLoadsActions,
  ConnectionActions,
  DataAccessPolicyActions,
  GlobalSearchActions,
  IntegrationActions,
  NonAdminActions,
  ServiceAccountsActions,
  SystemLimitsActions,
  UserActions,
  WS1BrokerActions,
];

export * from './account';
export * from './app-loads';
export * from './connection';
export * from './dashboard';
export * from './data-access-policy';
export * from './global-search';
export * from './global-search';
export * from './integration';
export * from './merlot.store';
export * from './non-admin';
export * from './service-accounts';
export * from './system-limits';
export * from './user';
export * from './ws1-broker';
