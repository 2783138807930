<ng-container *ngIf="isIncrementalLoadingEnabled$ | async">
  <ng-container *ngIf="showAsSpinner">
    <dpa-tooltip
      [tooltipText]="tooltipText | translate"
      [focusEnabled]="true"
      role="tooltip"
    >
      <span *ngIf="status === IncrementalLoadingResponseTrendStatus.INPROGRESS">
        <span class="spinner spinner-sm in-progress-indicator"></span>
        <span class="loading-text mr-1">{{ 'INCREMENTAL_LOADING.IN_PROGRESS' | translate }}</span>
      </span>

      <cds-icon
        *ngIf="status === IncrementalLoadingResponseTrendStatus.COMPLETED_WITH_SUCCESS"
        class="success-indicator"
        shape="check-circle"
        status="success"
        size="24"
      ></cds-icon>
      <cds-icon
        *ngIf="status === IncrementalLoadingResponseTrendStatus.COMPLETED_WITH_FAILURE"
        class="failure-indicator"
        shape="exclamation-circle"
        status="danger"
        size="24"
      ></cds-icon>
    </dpa-tooltip>
  </ng-container>

  <ng-container *ngIf="showAsProgressBar">
    <div
      *ngIf="status === IncrementalLoadingResponseTrendStatus.INPROGRESS"
      class="progress loop"
    >
      <progress></progress>
    </div>
  </ng-container>
</ng-container>
