/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GenericObject, ModalSize } from '@dpa/ui-common';
import { Store } from '@ngrx/store';

import { ConnectorCommonActions } from '@ws1c/intelligence-core/store';
import {
  AlertBannerTarget,
  Connector,
  ConnectorConfig,
  ConnectorConfigData,
  ConnectorConfigUpdateParams,
  ConnectorSchema,
} from '@ws1c/intelligence-models';

/**
 * ConnectorEditModalComponent
 *
 * migrated from EditConnectionModalsComponent
 * @export
 * @class ConnectorEditModalComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-connector-edit-modal',
  templateUrl: 'connector-edit-modal.component.html',
  styleUrls: ['connector-edit-modal.component.scss'],
})
export class ConnectorEditModalComponent implements OnInit, OnChanges {
  @Input() public isOpen?: boolean = true;
  @Input() public isLoading: boolean = false;
  @Input() public connectorConfigSchema: ConnectorSchema;
  @Input() public connectorConfig: ConnectorConfig;
  @Input() public connector: Connector;
  @Output() public modalClose: EventEmitter<void> = new EventEmitter();
  @Output() public edit: EventEmitter<ConnectorConfigUpdateParams> = new EventEmitter();

  public configForm: UntypedFormGroup;
  public formValues: GenericObject;
  public isConfirmModalOpen: boolean = false;
  public readonly AlertBannerTarget = AlertBannerTarget;
  public readonly ModalSize = ModalSize;

  /**
   * Creates an instance of ConnectorEditModalComponent
   * @param {Store} store
   * @memberof ConnectorEditModalComponent
   */
  constructor(private store: Store) {}

  /**
   * ngOnInit
   * @memberof ConnectorEditModalComponent
   */
  public ngOnInit() {
    this.store.dispatch(ConnectorCommonActions.getConnectorConfigSchema({ configSchemaId: this.connector?.configSchemaId }));
    this.store.dispatch(ConnectorCommonActions.loadConnectorConfig({ id: this.connector?.configId }));
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof ConnectorEditModalComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.connectorConfig?.currentValue) {
      this.formValues = ConnectorConfigData.getConfigFormFromConnectorConfigData(changes.connectorConfig?.currentValue.configData);
    }
  }

  /**
   * confirmEdit
   * @memberof ConnectorEditModalComponent
   */
  public confirmEdit() {
    const params = new ConnectorConfigUpdateParams({
      configData: ConnectorConfigData.getConnectorConfigDataFromConfigForm(this.configForm.getRawValue()),
    });
    this.isConfirmModalOpen = false;
    this.edit.emit(params);
  }
}
