/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { PagedRequest, SortOn } from '@dpa/ui-common';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import {
  AccountSearchResponse,
  DataAccessPolicy,
  DataAccessPolicyAssignPreview,
  DataAccessPolicySearchResponse,
  GenericSearchRequest,
  SearchTerm,
} from '@ws1c/intelligence-models';
import { DataAccessPolicyActions } from './data-access-policy.actions';
import { DataAccessPolicyState, initialDataAccessPolicyState } from './data-access-policy.state';

/**
 * _reducer
 * @type {ActionReducer<DataAccessPolicyState, Action>}
 */
const _reducer: ActionReducer<DataAccessPolicyState, Action> = createReducer(
  initialDataAccessPolicyState,

  on(
    DataAccessPolicyActions.getDataAccessPolicy,
    (state: DataAccessPolicyState, props: { genericSearchRequest: GenericSearchRequest }): DataAccessPolicyState => {
      return {
        ...state,
        dataAccessPolicySearchRequest: props.genericSearchRequest,
        isLoading: true,
      };
    },
  ),

  on(
    DataAccessPolicyActions.getDataAccessPolicySuccess,
    (state: DataAccessPolicyState, props: { dataAccessPolicySearchResponse: DataAccessPolicySearchResponse }): DataAccessPolicyState => {
      return {
        ...state,
        dataAccessPolicySearchResponse: props.dataAccessPolicySearchResponse,
        isLoading: false,
      };
    },
  ),

  on(DataAccessPolicyActions.getDataAccessPolicyFailure, (state: DataAccessPolicyState): DataAccessPolicyState => {
    return {
      ...state,
      isLoading: false,
    };
  }),

  on(DataAccessPolicyActions.getDataAccessPolicyById, (state: DataAccessPolicyState): DataAccessPolicyState => {
    return {
      ...state,
      isLoadingPolicy: true,
    };
  }),

  on(
    DataAccessPolicyActions.getDataAccessPolicyByIdSuccess,
    (state: DataAccessPolicyState, props: { dataAccessPolicy: DataAccessPolicy }): DataAccessPolicyState => {
      return {
        ...state,
        dataAccessPolicyById: props.dataAccessPolicy,
        isLoadingPolicy: false,
      };
    },
  ),

  on(DataAccessPolicyActions.getDataAccessPolicyByIdFailure, (state: DataAccessPolicyState): DataAccessPolicyState => {
    return {
      ...state,
      dataAccessPolicyById: undefined,
      isLoadingPolicy: false,
    };
  }),

  on(
    DataAccessPolicyActions.showDeleteConfirmModal,
    (
      state: DataAccessPolicyState,
      props: {
        showModal: boolean;
        dataAccessPolicy: DataAccessPolicy;
      },
    ): DataAccessPolicyState => {
      return {
        ...state,
        showDeleteConfirmModal: props.showModal,
        selectedDataAccessPolicy: props.dataAccessPolicy,
      };
    },
  ),

  on(DataAccessPolicyActions.deleteDataAccessPolicySuccess, (state: DataAccessPolicyState): DataAccessPolicyState => {
    return {
      ...state,
      showDeleteConfirmModal: false,
      selectedDataAccessPolicy: undefined,
    };
  }),

  on(DataAccessPolicyActions.deleteDataAccessPolicyFailure, (state: DataAccessPolicyState): DataAccessPolicyState => {
    return {
      ...state,
      showDeleteConfirmModal: false,
    };
  }),

  on(DataAccessPolicyActions.filterDataAccessPolicy, (state: DataAccessPolicyState, props: { query: string }): DataAccessPolicyState => {
    return {
      ...state,
      dataAccessPolicySearchRequest: new GenericSearchRequest({
        ...state.dataAccessPolicySearchRequest,
        searchTerm: new SearchTerm({
          value: props.query,
        }),
      }),
    };
  }),

  on(
    DataAccessPolicyActions.pageDataAccessPolicy,
    (state: DataAccessPolicyState, props: { pagedRequest: PagedRequest }): DataAccessPolicyState => {
      return {
        ...state,
        dataAccessPolicySearchRequest: new GenericSearchRequest({
          ...state.dataAccessPolicySearchRequest,
          ...props.pagedRequest,
        }),
      };
    },
  ),

  on(DataAccessPolicyActions.sortDataAccessPolicy, (state: DataAccessPolicyState, props: { sortOns: SortOn[] }): DataAccessPolicyState => {
    return {
      ...state,
      dataAccessPolicySearchRequest: new GenericSearchRequest({
        ...state.dataAccessPolicySearchRequest,
        sortOns: props.sortOns,
      }),
    };
  }),

  on(DataAccessPolicyActions.getAssignedUsers, (state: DataAccessPolicyState): DataAccessPolicyState => {
    return {
      ...state,
      isLoadingUsers: true,
    };
  }),

  on(
    DataAccessPolicyActions.getAssignedUsersSuccess,
    (state: DataAccessPolicyState, props: { assignedAccountsSearchResponse: AccountSearchResponse }): DataAccessPolicyState => {
      return {
        ...state,
        assignedAccountsSearchResponse: props.assignedAccountsSearchResponse,
        isLoadingUsers: false,
      };
    },
  ),

  on(
    DataAccessPolicyActions.previewEditDataAccessPolicy,
    DataAccessPolicyActions.previewAddDataAccessPolicy,
    (state: DataAccessPolicyState, props: { dataAccessPolicy: DataAccessPolicy }): DataAccessPolicyState => {
      return {
        ...state,
        selectedDataAccessPolicy: props.dataAccessPolicy,
      };
    },
  ),

  on(
    DataAccessPolicyActions.setAssignUsersPreview,
    (state: DataAccessPolicyState, props: { preview: DataAccessPolicyAssignPreview }): DataAccessPolicyState => {
      return {
        ...state,
        dataAccessPolicyPreview: props.preview,
      };
    },
  ),

  on(
    DataAccessPolicyActions.goToAddDataAccessPolicy,
    DataAccessPolicyActions.goToListView,
    (state: DataAccessPolicyState): DataAccessPolicyState => {
      return {
        ...state,
        selectedDataAccessPolicy: undefined,
        dataAccessPolicyById: undefined,
        assignedAccountsSearchResponse: undefined,
      };
    },
  ),

  on(
    DataAccessPolicyActions.goToEditDataAccessPolicy,
    (state: DataAccessPolicyState, props: { dataAccessPolicy: DataAccessPolicy }): DataAccessPolicyState => {
      return {
        ...state,
        selectedDataAccessPolicy: props.dataAccessPolicy,
        assignedAccountsSearchResponse: undefined,
      };
    },
  ),
);

/**
 * Creates and returns DataAccessPolicyState
 * @export
 * @param {DataAccessPolicyState} state - Holds state object of type BookmarksState
 * @param {Action} action - Holds the action which needs to be invoked in reducer
 * @returns {DataAccessPolicyState}
 */
export function dataAccessPolicyState(state: DataAccessPolicyState, action: Action): DataAccessPolicyState {
  return _reducer(state, action);
}
