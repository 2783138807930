/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { DevicesDashboardActions } from './devices-dashboard.actions';
import { DevicesDashboardState, initialDevicesDashboardState } from './devices-dashboard.state';

const _reducer: ActionReducer<DevicesDashboardState> = createReducer(
  initialDevicesDashboardState,

  on(
    DevicesDashboardActions.loadNetworkSignalDashboardWithMergedRequests,
    (
      state: DevicesDashboardState,
      { dashboardTypeList }: ReturnType<typeof DevicesDashboardActions.loadNetworkSignalDashboardWithMergedRequests>,
    ): DevicesDashboardState => ({
      ...state,
      dashboardTypeList,
    }),
  ),

  on(
    DevicesDashboardActions.updateDashboardFilter,
    (state: DevicesDashboardState, props: ReturnType<typeof DevicesDashboardActions.updateDashboardFilter>): DevicesDashboardState => {
      return {
        ...state,
        filterRuleGroup: props.ruleGroup,
        trendDateRange: props.trendDateRange,
      };
    },
  ),

  on(
    DevicesDashboardActions.loadDashboard,
    (state: DevicesDashboardState, props: ReturnType<typeof DevicesDashboardActions.loadDashboard>): DevicesDashboardState => ({
      ...state,
      dashboardType: props.dashboardType,
      additionalParams: props?.additionalParams || {},
    }),
  ),

  on(
    DevicesDashboardActions.loadDeviceHealth,
    (state: DevicesDashboardState, { deviceId }: ReturnType<typeof DevicesDashboardActions.loadDeviceHealth>): DevicesDashboardState => ({
      ...state,
      performance: {
        ...state.performance,
        deviceHealthResponse: {
          [deviceId]: undefined,
        },
      },
    }),
  ),

  on(
    DevicesDashboardActions.loadDeviceHealthSuccess,
    (
      state: DevicesDashboardState,
      { deviceId, response }: ReturnType<typeof DevicesDashboardActions.loadDeviceHealthSuccess>,
    ): DevicesDashboardState => ({
      ...state,
      performance: {
        ...state.performance,
        deviceHealthResponse: {
          [deviceId]: response,
        },
      },
    }),
  ),

  on(
    DevicesDashboardActions.setSelectedPerformanceThresholdAttribute,
    (
      state: DevicesDashboardState,
      { thresholdAttribute }: ReturnType<typeof DevicesDashboardActions.setSelectedPerformanceThresholdAttribute>,
    ): DevicesDashboardState => ({
      ...state,
      performance: {
        ...state.performance,
        selectedThresholdAttribute: thresholdAttribute,
      },
    }),
  ),

  on(
    DevicesDashboardActions.setIsPerformanceModalOpen,
    (
      state: DevicesDashboardState,
      { isOpen }: ReturnType<typeof DevicesDashboardActions.setIsPerformanceModalOpen>,
    ): DevicesDashboardState => ({
      ...state,
      performance: {
        ...state.performance,
        isModalOpen: isOpen,
      },
    }),
  ),

  on(
    DevicesDashboardActions.savePerformanceThresholdSummarySuccess,
    (
      state: DevicesDashboardState,
      { thresholdSummary }: ReturnType<typeof DevicesDashboardActions.savePerformanceThresholdSummarySuccess>,
    ): DevicesDashboardState => ({
      ...state,
      performance: {
        ...state.performance,
        thresholdSummary,
        isModalOpen: false,
      },
    }),
  ),

  on(
    DevicesDashboardActions.loadPerformanceThresholdSummary,
    DevicesDashboardActions.loadPerformanceThresholdSummaryFailure,
    (state: DevicesDashboardState): DevicesDashboardState => ({
      ...state,
      performance: {
        ...state.performance,
        thresholdSummary: undefined,
      },
    }),
  ),

  on(
    DevicesDashboardActions.loadPerformanceThresholdSummarySuccess,
    (
      state: DevicesDashboardState,
      { thresholdSummary }: ReturnType<typeof DevicesDashboardActions.loadPerformanceThresholdSummarySuccess>,
    ): DevicesDashboardState => ({
      ...state,
      performance: {
        ...state.performance,
        thresholdSummary,
      },
    }),
  ),

  on(
    DevicesDashboardActions.updateDevicesDesktopErrorDetailsFilters,
    (
      state: DevicesDashboardState,
      { trendDateRange }: ReturnType<typeof DevicesDashboardActions.updateDevicesDesktopErrorDetailsFilters>,
    ): DevicesDashboardState => ({
      ...state,
      errors: {
        ...state.errors,
        detailsTrendDateRange: trendDateRange,
      },
    }),
  ),

  on(
    DevicesDashboardActions.goToDevicesDesktopErrorDetailsPage,
    (state: DevicesDashboardState): DevicesDashboardState => ({
      ...state,
      errors: {
        ...state.errors,
        detailsTrendDateRange: state.trendDateRange,
      },
    }),
  ),

  on(
    DevicesDashboardActions.initDevicesDesktopErrorDetailsPage,
    (
      state: DevicesDashboardState,
      { detailsPage }: ReturnType<typeof DevicesDashboardActions.initDevicesDesktopErrorDetailsPage>,
    ): DevicesDashboardState => ({
      ...state,
      errors: {
        ...state.errors,
        detailsPage,
      },
    }),
  ),

  on(
    DevicesDashboardActions.loadDeviceHealthThresholdsV2Success,
    (
      state: DevicesDashboardState,
      { data }: ReturnType<typeof DevicesDashboardActions.loadDeviceHealthThresholdsV2Success>,
    ): DevicesDashboardState => ({
      ...state,
      thresholdsV2: data,
    }),
  ),
);

/**
 * devicesDashboardState
 * @param {DevicesDashboardState} state
 * @param {Action} action
 * @returns {DevicesDashboardState}
 */
export function devicesDashboardState(state: DevicesDashboardState, action: Action): DevicesDashboardState {
  return _reducer(state, action);
}
