/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { RuleGroup, SuggestionSearch, TrendDateRange } from '@ws1c/intelligence-models';

/**
 * OsUpdatesDashboardState
 * @export
 * @interface OsUpdatesDashboardState
 */
export interface OsUpdatesDashboardState {
  platform: string;
  suggestionSearch: SuggestionSearch;
  versions: {
    filterRuleGroup: RuleGroup;
    trendDateRange: TrendDateRange;
  };
  patches: {
    filterRuleGroup: RuleGroup;
    trendDateRange: TrendDateRange;
  };
}

export const initialOsUpdatesDashboardState: OsUpdatesDashboardState = {
  platform: undefined,
  suggestionSearch: undefined,
  versions: {
    filterRuleGroup: undefined,
    trendDateRange: undefined,
  },
  patches: {
    filterRuleGroup: undefined,
    trendDateRange: undefined,
  },
};
