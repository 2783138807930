/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { GenericObject, HostContainerDirective } from '@dpa/ui-common';

import { MetaFormUtils } from '@ws1c/intelligence-core/components/dynamic-form/metaform.utils';
import { JsonSchemaMetadata, LabelValue, LookupVariable, MetaFormFieldPresentationType } from '@ws1c/intelligence-models';
import {
  CheckboxFormFieldComponent,
  ColumnTextareaFormFieldComponent,
  DefaultFormFieldComponent,
  EmailMultiFormFieldComponent,
  ListFormFieldComponent,
  LookupFormFieldComponent,
  PasswordFormFieldComponent,
  RadioFormFieldComponent,
  ReadOnlyFormFieldComponent,
  RichTextFormFieldComponent,
  TextareaFormFieldComponent,
  UrlFormFieldComponent,
} from './components';

/**
 * FormFieldComponent
 * @export
 * @class FormFieldComponent
 * @implements {OnChanges}
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-form-field',
  templateUrl: 'form-field.component.html',
  styleUrls: ['form-field.component.scss'],
})
export class FormFieldComponent implements OnChanges, OnInit {
  @Input() public control: AbstractControl;
  @Input() public name: string;
  @Input() public metadata: JsonSchemaMetadata;
  @Input() public defaultValue?: string;
  @Input() public required?: boolean;
  @Input() public enumList?: LabelValue[];
  @Input() public selectedEnum?: LabelValue;
  @Input() public columnLookupVariables?: LookupVariable[];
  @Input() public readonly?: boolean = false;
  @Input() public value?: any;
  @Input() public formNodeId?: string;
  @Input() public searchableActionsEnabled?: boolean = true;
  @ViewChild(HostContainerDirective, { static: true }) public dpaHostContainer: HostContainerDirective;

  public readonly PRESENTATION_TYPE_TO_FIELD_MAP = {
    [MetaFormFieldPresentationType.RADIO]: RadioFormFieldComponent,
    [MetaFormFieldPresentationType.TEXTAREA]: TextareaFormFieldComponent,
    [MetaFormFieldPresentationType.RICH_TEXT_EDITOR]: RichTextFormFieldComponent,
    [MetaFormFieldPresentationType.CHECKBOX]: CheckboxFormFieldComponent,
    [MetaFormFieldPresentationType.PASSWORD]: PasswordFormFieldComponent,
    [MetaFormFieldPresentationType.LIST]: ListFormFieldComponent,
    [MetaFormFieldPresentationType.EMAIL_MULTISELECT]: EmailMultiFormFieldComponent,
    [MetaFormFieldPresentationType.TEXT_AREA_WITH_COLUMN_VARIABLES]: ColumnTextareaFormFieldComponent,
    [MetaFormFieldPresentationType.URL]: UrlFormFieldComponent,
    [MetaFormFieldPresentationType.LOOKUP]: LookupFormFieldComponent,
  };

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof FormFieldComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.value?.currentValue) {
      this.control.patchValue(changes.value.currentValue);
    }
  }

  /**
   * ngOnInit
   * @memberof FormFieldComponent
   */
  public ngOnInit() {
    this.loadComponent();
  }

  /**
   * loadComponent
   * @memberof FormFieldComponent
   */
  public loadComponent() {
    const presentationType = this.getPresentationType();
    if (MetaFormFieldPresentationType.HIDDEN === presentationType) {
      return;
    }
    const component = this.readonly
      ? ReadOnlyFormFieldComponent
      : this.PRESENTATION_TYPE_TO_FIELD_MAP[presentationType] ?? DefaultFormFieldComponent;

    if (this.readonly && this.enumList?.length) {
      this.control.patchValue(this.enumList.find((lv: LabelValue) => lv.value === this.control.value).label);
    }

    const viewContainerRef: ViewContainerRef = this.dpaHostContainer?.viewContainerRef;
    if (!viewContainerRef) {
      return;
    }
    viewContainerRef.clear();
    const componentRef: GenericObject = viewContainerRef.createComponent(component);
    const instance = componentRef.instance;
    instance.metadata = this.metadata;
    instance.name = this.name;
    instance.lookupVariables = this.columnLookupVariables;
    instance.selectedEnum = this.selectedEnum;
    instance.enumList = this.enumList;
    instance.defaultValue = this.defaultValue;
    instance.required = this.required;
    instance.formControl = this.control;
    instance.id = this.formNodeId;
    instance.searchableActionsEnabled = this.searchableActionsEnabled;
  }

  /**
   * getPresentationType
   *
   * @returns {MetaFormFieldPresentationType}
   * @memberof FormFieldComponent
   */
  public getPresentationType(): MetaFormFieldPresentationType {
    if (this.metadata.lookupConfig) {
      return MetaFormFieldPresentationType.LOOKUP;
    }
    return MetaFormUtils.getPresentationTypeDisplay(this.metadata.templateAllowed, this.metadata.presentationType);
  }
}
