/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector, Selector } from '@ngrx/store';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { getDefaultedTrendDate } from '@ws1c/dashboard-common/utils';
import { RuleGroup, StandardDashboardRequest, StandardDashboardType, TrendDateRange } from '@ws1c/intelligence-models';
import { UagTunnelDashboardState } from './uag-tunnel-dashboard.state';

/**
 * UagTunnelDashboardSelectors
 * @export
 * @class UagTunnelDashboardSelectors
 */
export class UagTunnelDashboardSelectors {
  /**
   * selectUagTunnelState
   * @static
   * @param {MerlotState} state
   * @type {Selector<MerlotState, UagTunnelDashboardState>}
   * @memberof UagTunnelDashboardSelectors
   */
  public static selectUagTunnelState: Selector<MerlotState, UagTunnelDashboardState> = (state: MerlotState) =>
    state.standardDashboardState.uagTunnelDashboardState;

  /**
   * getTrendDateRange
   * @static
   * @type {MemoizedSelector<MerlotState, TrendDateRange>}
   * @memberof UagTunnelDashboardSelectors
   */
  public static getTrendDateRange: MemoizedSelector<MerlotState, TrendDateRange> = createSelector(
    UagTunnelDashboardSelectors.selectUagTunnelState,
    (state: UagTunnelDashboardState) => {
      return getDefaultedTrendDate(state.trendDateRange, StandardDashboardType.UAG_TUNNEL);
    },
  );

  /**
   * getDashboardRequest
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardRequest>}
   * @memberof UagTunnelDashboardSelectors
   */
  public static getDashboardRequest: MemoizedSelector<MerlotState, StandardDashboardRequest> = createSelector(
    UagTunnelDashboardSelectors.getTrendDateRange,
    (trendDateRange: TrendDateRange) => {
      return new StandardDashboardRequest(StandardDashboardType.UAG_TUNNEL, new RuleGroup(), trendDateRange);
    },
  );
}
