/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { GenericObject, WebError } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import { Automation, CustomReport, DashboardView, Device, DeviceUser, StandardDashboardRequest } from '@ws1c/intelligence-models';

/**
 * GlobalSearchActions
 * @export
 * @class GlobalSearchActions
 */
@Injectable()
export class GlobalSearchActions {
  public static readonly CATEGORY = '[GLOBAL_SEARCH]';

  public static getOsMetadata = createAction(
    `${GlobalSearchActions.CATEGORY} GET_OS_METADATA`,
    props<{ request: StandardDashboardRequest }>(),
  );
  public static getOsMetadataSuccess = createAction(
    `${GlobalSearchActions.CATEGORY} GET_OS_METADATA_SUCCESS`,
    props<{ osMetaData: GenericObject }>(),
  );
  public static getOsMetadataFailure = createAction(
    `${GlobalSearchActions.CATEGORY} GET_OS_METADATA_FAILURE`,
    props<{ error: WebError }>(),
  );
  public static searchOsUpdate = createAction(`${GlobalSearchActions.CATEGORY} SEARCH_OS_UPDATE`, props<{ query: GenericObject }>());
  public static searchOsUpdateSuccess = createAction(
    `${GlobalSearchActions.CATEGORY} SEARCH_OS_UPDATE_SUCCESS`,
    props<{ results: GenericObject }>(),
  );
  public static searchOsUpdateFailure = createAction(
    `${GlobalSearchActions.CATEGORY} SEARCH_OS_UPDATE_FAILURE`,
    props<{ error: WebError }>(),
  );
  public static searchWidgets = createAction(`${GlobalSearchActions.CATEGORY} SEARCH_WIDGETS`, props<{ query: string }>());
  public static searchWidgetsSuccess = createAction(
    `${GlobalSearchActions.CATEGORY} SEARCH_WIDGETS_SUCCESS`,
    props<{ dashboards: DashboardView[] }>(),
  );
  public static searchWidgetsFailure = createAction(`${GlobalSearchActions.CATEGORY} SEARCH_WIDGETS_FAILURE`, props<{ error: WebError }>());
  public static searchDevices = createAction(`${GlobalSearchActions.CATEGORY} SEARCH_DEVICES`, props<{ query: string }>());
  public static searchDevicesSuccess = createAction(
    `${GlobalSearchActions.CATEGORY} SEARCH_DEVICES_SUCCESS`,
    props<{ devices: Device[] }>(),
  );
  public static searchDevicesFailure = createAction(`${GlobalSearchActions.CATEGORY} SEARCH_DEVICES_FAILURE`, props<{ error: WebError }>());
  public static searchReports = createAction(`${GlobalSearchActions.CATEGORY} SEARCH_REPORTS`, props<{ query: string }>());
  public static searchReportsSuccess = createAction(
    `${GlobalSearchActions.CATEGORY} SEARCH_REPORTS_SUCCESS`,
    props<{ reports: CustomReport[] }>(),
  );
  public static searchReportsFailure = createAction(`${GlobalSearchActions.CATEGORY} SEARCH_REPORTS_FAILURE`, props<{ error: WebError }>());
  public static searchAutomations = createAction(`${GlobalSearchActions.CATEGORY} SEARCH_AUTOMATIONS`, props<{ query: string }>());
  public static searchAutomationsSuccess = createAction(
    `${GlobalSearchActions.CATEGORY} SEARCH_AUTOMATIONS_SUCCESS`,
    props<{ automations: Automation[] }>(),
  );
  public static searchAutomationsFailure = createAction(
    `${GlobalSearchActions.CATEGORY} SEARCH_AUTOMATIONS_FAILURE`,
    props<{ error: WebError }>(),
  );
  public static goToOsPage = createAction(`${GlobalSearchActions.CATEGORY} GO_TO_OS_PAGE`, props<{ name: string }>());
  public static searchUsers = createAction(`${GlobalSearchActions.CATEGORY} SEARCH_USERS`, props<{ query: string }>());
  public static searchUsersSuccess = createAction(`${GlobalSearchActions.CATEGORY} SEARCH_USERS_SUCCESS`, props<{ users: DeviceUser[] }>());
  public static searchUsersFailure = createAction(`${GlobalSearchActions.CATEGORY} SEARCH_USERS_FAILURE`, props<{ error: WebError }>());
}
