/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GenericObject } from '@dpa/ui-common';
import { some } from 'lodash-es';

import {
  AutomationActionFieldForLookup,
  AutomationActionFieldLookupRequestPayload,
  helpers,
  isFieldDisplayed,
  MetaForm,
  MetaFormField,
  MetaFormType,
  NameValue,
  SectionsMetaForm,
} from '@ws1c/intelligence-models';

/**
 * Create an html form using metaData
 *
 * @export
 * @class MetaFormComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-meta-form',
  templateUrl: 'meta-form.component.html',
  styleUrls: ['meta-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaFormComponent implements OnInit {
  @Input() public columnLookupVariables?: any[] = [];
  @Input() public isEditMode?: boolean = false;
  @Input() public metaForm: MetaForm;
  @Input() public settings: object | undefined;
  @Input() public settingsBySection?: object | undefined;
  @Input() public searchableActionsEnabled?: boolean = false;
  @Input() public lookupMap: Record<string, NameValue[]> = {};
  @Input() public lookupMapLoading?: boolean = false;
  @Input() public nestedLookups?: GenericObject = {};
  @Output() public formGroupChange: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
  @Output() public lookupChoiceChange = new EventEmitter<AutomationActionFieldForLookup>();
  @Output() public onRequestLookup = new EventEmitter<AutomationActionFieldLookupRequestPayload>();
  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    [MetaFormType.FIELDS]: new UntypedFormGroup({}),
  });
  public FORM_TYPE = MetaFormType;

  /**
   * isLegacy
   * @readonly
   * @type {boolean}
   * @memberof MetaFormComponent
   */
  public get isLegacy(): boolean {
    return helpers.hasFields(this.metaForm);
  }

  /**
   * isSectioned
   * @readonly
   * @type {boolean}
   * @memberof MetaFormComponent
   */
  public get isSectioned(): boolean {
    return helpers.hasSections(this.metaForm);
  }

  /**
   * sectionMetaForm
   * @readonly
   * @type {SectionsMetaForm}
   * @memberof MetaFormComponent
   */
  public get sectionMetaForm(): SectionsMetaForm {
    return this.metaForm as SectionsMetaForm;
  }

  /**
   * ngOnInit
   * @memberof MetaFormComponent
   */
  public ngOnInit() {
    if (this.isSectioned) {
      this.formGroup.addControl(MetaFormType.SECTIONS, new UntypedFormGroup({}));
    }
  }

  /**
   * getFormGroup
   * @param {string} formType
   * @returns {UntypedFormGroup}
   * @memberof MetaFormComponent
   */
  public getFormGroup(formType: string): UntypedFormGroup {
    return this.formGroup.get(formType) as UntypedFormGroup;
  }

  /**
   * Re-emit changes from the underlying form component
   * @param {string} formType
   * @param {UntypedFormGroup} formGroup
   * @memberof MetaFormComponent
   */
  public onFormGroupChange(formType: string, formGroup: UntypedFormGroup) {
    this.formGroup.setControl(formType, formGroup);
    this.formGroupChange.emit(this.formGroup);
  }

  /**
   * onLookupChoiceChange - re-emit lookup choice change
   * @param {AutomationActionFieldForLookup} fieldForLookup
   * @memberof MetaFormComponent
   */
  public onLookupChoiceChange(fieldForLookup: AutomationActionFieldForLookup) {
    this.lookupChoiceChange.emit(fieldForLookup);
  }

  /**
   * onRequestFieldLookup - re-emit lookup request
   * @param {AutomationActionFieldLookupRequestPayload} fieldRequest
   * @memberof MetaFormComponent
   */
  public onRequestFieldLookup(fieldRequest: AutomationActionFieldLookupRequestPayload) {
    this.onRequestLookup.emit(fieldRequest);
  }

  /**
   * showPayloadBodyHeader
   * @returns {boolean}
   * @memberof MetaFormComponent
   */
  public showPayloadBodyHeader(): boolean {
    const fieldsFormGroup: UntypedFormGroup = this.formGroup.get(MetaFormType.FIELDS) as UntypedFormGroup;
    return this.isLegacy && some(this.metaForm?.fields, (field: MetaFormField) => isFieldDisplayed(fieldsFormGroup, this.metaForm, field));
  }
}
