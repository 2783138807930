/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { LoginRiskDashboardActions } from './login-risk-dashboard.actions';
import { initialLoginRiskDashboardState, LoginRiskDashboardState } from './login-risk-dashboard.state';

const _reducer: ActionReducer<LoginRiskDashboardState> = createReducer(
  initialLoginRiskDashboardState,

  on(
    LoginRiskDashboardActions.setDashboardFilters,
    (state: LoginRiskDashboardState, props: ReturnType<typeof LoginRiskDashboardActions.setDashboardFilters>): LoginRiskDashboardState => ({
      ...state,
      ruleGroup: props.ruleGroup,
      trendDateRange: props.trendDateRange,
    }),
  ),

  on(
    LoginRiskDashboardActions.hideBanner,
    (state: LoginRiskDashboardState): LoginRiskDashboardState => ({
      ...state,
      hideBanner: true,
    }),
  ),
);

/**
 * loginRiskDashboardState
 * @export
 * @param {LoginRiskDashboardState} state
 * @param {Action} action
 * @returns {LoginRiskDashboardState}
 */
export function loginRiskDashboardState(state: LoginRiskDashboardState, action: Action): LoginRiskDashboardState {
  return _reducer(state, action);
}
