/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { BreadCrumb, PagedRequest, SortOn, WebError } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import {
  AllHostTab,
  AppCrashGroup,
  AppCrashGroupResponse,
  AppErrorCrashUploadsTableResponse,
  AppHealthStatus,
  CustomReportPreviewSearchResponse,
  DateRangeFilters,
  DeemAppDetailsPage,
  LocalDataGridSettings,
  NetworkInsightsSearchResponse,
  NetworkInsightsUrlResponse,
  RuleGroup,
  TrendDateRange,
  UserDetailPageType,
  UserFlow,
} from '@ws1c/intelligence-models';

/**
 * AppsDashboardActions
 * @export
 * @class AppsDashboardActions
 */
export class AppsDashboardActions {
  public static readonly CATEGORY = '[DASHBOARD][APPS]';

  public static selectNetworkInsightsHost = createAction(
    `${AppsDashboardActions.CATEGORY} SELECT_NETWORK_INSIGHTS_HOST`,
    props<{ host: string }>(),
  );

  public static openNetworkInsightsHostUrlsModal = createAction(`${AppsDashboardActions.CATEGORY} OPEN_NETWORK_INSIGHTS_HOST_URLS_MODAL`);

  public static fetchAppHealthStatus = createAction(
    `${AppsDashboardActions.CATEGORY} FETCH_APP_HEALTH_STATUS`,
    props<{ appHealthStatus: AppHealthStatus }>(),
  );

  public static fetchAppHealthStatusSuccess = createAction(
    `${AppsDashboardActions.CATEGORY} FETCH_APP_HEALTH_STATUS_SUCCESS`,
    props<{ status: string }>(),
  );

  public static fetchAppHealthStatusFailure = createAction(
    `${AppsDashboardActions.CATEGORY} FETCH_APP_HEALTH_STATUS_FAILURE`,
    props<{ status: string }>(),
  );

  public static setUserFlowsCardsSortOn = createAction(
    `${AppsDashboardActions.CATEGORY} SET_USER_FLOWS_CARDS_SORT_ON`,
    props<{ sortOn: SortOn }>(),
  );

  public static goToUserFlowsDetailFromCard = createAction(
    `${AppsDashboardActions.CATEGORY} GO_TO_USER_FLOWS_DETAIL_FROM_CARD`,
    props<{
      userFlow: UserFlow;
      breadCrumbs: BreadCrumb[];
    }>(),
  );

  public static setNetworkInsightsAllHostTab = createAction(
    `${AppsDashboardActions.CATEGORY} SET_NETWORK_INSIGHTS_ALL_HOST_TAB`,
    props<{ allHostTab: AllHostTab }>(),
  );

  public static setNetworkInsightsGroupBy = createAction(
    `${AppsDashboardActions.CATEGORY} SET_NETWORK_INSIGHTS_GROUP_BY`,
    props<{ groupBy: string }>(),
  );

  public static setNetworkInsightsDetailsGroupBy = createAction(
    `${AppsDashboardActions.CATEGORY} SET_NETWORK_INSIGHTS_DETAILS_GROUP_BY`,
    props<{ groupBy: string }>(),
  );

  public static setNetworkInsightsErrorTab = createAction(
    `${AppsDashboardActions.CATEGORY} SET_NETWORK_INSIGHTS_ERROR_TAB`,
    props<{ tab: string }>(),
  );

  public static loadAppErrorCrashTable = createAction(
    `${AppsDashboardActions.CATEGORY} LOAD_APP_ERROR_CRASH_TABLE`,
    props<{ query: string }>(),
  );

  public static loadAppErrorCrashTableSuccess = createAction(
    `${AppsDashboardActions.CATEGORY} LOAD_APP_ERROR_CRASH_TABLE_SUCCESS`,
    props<{ response: AppCrashGroupResponse }>(),
  );

  public static loadAppErrorCrashTableFailure = createAction(
    `${AppsDashboardActions.CATEGORY} LOAD_APP_ERROR_CRASH_TABLE_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static patchAppErrorCrashTableSettings = createAction(
    `${AppsDashboardActions.CATEGORY} PATCH_APP_ERROR_CRASH_TABLE_SETTINGS`,
    props<{ settings: Partial<LocalDataGridSettings> }>(),
  );

  public static setAppErrorCrashTableSettings = createAction(
    `${AppsDashboardActions.CATEGORY} SET_APP_ERROR_CRASH_TABLE_SETTINGS`,
    props<{ settings: LocalDataGridSettings }>(),
  );

  public static loadAppErrorDashboard = createAction(`${AppsDashboardActions.CATEGORY} LOAD_APP_ERROR_DASHBOARD`);

  public static loadAppErrorStacktraceThreads = createAction(`${AppsDashboardActions.CATEGORY} LOAD_APP_ERROR_STACKTRACE_THREADS`);

  public static loadAppErrorStacktraceThreadsSuccess = createAction(
    `${AppsDashboardActions.CATEGORY} LOAD_APP_ERROR_STACKTRACE_THREADS_SUCCESS`,
    props<{ response: CustomReportPreviewSearchResponse }>(),
  );

  public static loadAppErrorStacktraceThreadsFailure = createAction(
    `${AppsDashboardActions.CATEGORY} LOAD_APP_ERROR_STACKTRACE_THREADS_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static setAppErrorBreadcrumbsVisible = createAction(
    `${AppsDashboardActions.CATEGORY} SET_APP_ERROR_BREADCRUMBS_VISIBLE`,
    props<{ isVisible: boolean }>(),
  );

  public static setAppErrorBreadcrumbsPagedRequest = createAction(
    `${AppsDashboardActions.CATEGORY} SET_APP_ERROR_BREADCRUMBS_PAGED_REQUEST`,
    props<{ pagedRequest: PagedRequest }>(),
  );

  public static setAppErrorBreadcrumbsSortOns = createAction(
    `${AppsDashboardActions.CATEGORY} SET_APP_ERROR_BREADCRUMBS_SORT_ONS`,
    props<{ sortOns: SortOn[] }>(),
  );

  public static loadAppErrorBreadcrumbs = createAction(`${AppsDashboardActions.CATEGORY} LOAD_APP_ERROR_BREADCRUMBS`);

  public static loadAppErrorBreadcrumbsSuccess = createAction(
    `${AppsDashboardActions.CATEGORY} LOAD_APP_ERROR_BREADCRUMBS_SUCCESS`,
    props<{ response: CustomReportPreviewSearchResponse }>(),
  );

  public static loadAppErrorBreadcrumbsFailure = createAction(
    `${AppsDashboardActions.CATEGORY} LOAD_APP_ERROR_BREADCRUMBS_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static patchAppErrorHeTableSettings = createAction(
    `${AppsDashboardActions.CATEGORY} PATCH_APP_ERROR_HE_TABLE_SETTINGS`,
    props<{ settings: Partial<LocalDataGridSettings> }>(),
  );

  public static setAppErrorHeTableSettings = createAction(
    `${AppsDashboardActions.CATEGORY} SET_APP_ERROR_HE_TABLE_SETTINGS`,
    props<{ settings: LocalDataGridSettings }>(),
  );

  public static setNetworkInsightsSubFilterRuleGroup = createAction(
    `${AppsDashboardActions.CATEGORY} SET_NETWORK_INSIGHTS_SUB_FILTER_RULE_GROUP`,
    props<{ ruleGroup: RuleGroup }>(),
  );

  public static loadAppIdmDashboard = createAction(`${AppsDashboardActions.CATEGORY} LOAD_APP_IDM_DASHBOARD`);

  public static updateIdmAppDashboardDateRange = createAction(
    `${AppsDashboardActions.CATEGORY} UPDATE_IDM_APP_DASHBOARD_DATE_RANGE`,
    props<{ trendDateRange?: TrendDateRange }>(),
  );

  public static setIdmAppDashboardDateRange = createAction(
    `${AppsDashboardActions.CATEGORY} SET_IDM_APP_DASHBOARD_DATE_RANGE`,
    props<{ trendDateRange: TrendDateRange }>(),
  );

  public static setNetworkInsightsViewingHistorical = createAction(
    `${AppsDashboardActions.CATEGORY} SET_NETWORK_INSIGHTS_VIEWING_HISTORICAL`,
    props<{ isView: boolean }>(),
  );

  public static setNetworkInsightsSortOns = createAction(
    `${AppsDashboardActions.CATEGORY} SET_NETWORK_INSIGHTS_SORT_ONS`,
    props<{ sortOns: SortOn[] }>(),
  );

  public static setNetworkInsightsTablePagedRequest = createAction(
    `${AppsDashboardActions.CATEGORY} SET_NETWORK_INSIGHTS_TABLE_PAGED_REQUEST`,
    props<{ pagedRequest: PagedRequest }>(),
  );

  public static setNetworkInsightsFilter = createAction(
    `${AppsDashboardActions.CATEGORY} SET_NETWORK_INSIGHTS_FILTER`,
    props<{ filter: string }>(),
  );

  public static loadNetworkInsightsTable = createAction(`${AppsDashboardActions.CATEGORY} LOAD_NETWORK_INSIGHTS_TABLE`);

  public static loadNetworkInsightsTableSuccess = createAction(
    `${AppsDashboardActions.CATEGORY} LOAD_NETWORK_INSIGHTS_TABLE_SUCCESS`,
    props<{ response: NetworkInsightsSearchResponse }>(),
  );

  public static loadNetworkInsightsTableFailure = createAction(
    `${AppsDashboardActions.CATEGORY} LOAD_NETWORK_INSIGHTS_TABLE_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static setNetworkInsightsUrlsModalHostUrl = createAction(
    `${AppsDashboardActions.CATEGORY} SET_NETWORK_INSIGHTS_URLS_MODAL_HOST_URL`,
    props<{ url: string }>(),
  );

  public static loadNetworkInsightsUrls = createAction(`${AppsDashboardActions.CATEGORY} LOAD_NETWORK_INSIGHTS_URLS`);

  public static loadNetworkInsightsUrlsSuccess = createAction(
    `${AppsDashboardActions.CATEGORY} LOAD_NETWORK_INSIGHTS_URLS_SUCCESS`,
    props<{ response: NetworkInsightsUrlResponse }>(),
  );

  public static loadNetworkInsightsUrlsFailure = createAction(
    `${AppsDashboardActions.CATEGORY} LOAD_NETWORK_INSIGHTS_URLS_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static setNetworkInsightsLogModalIsOpen = createAction(
    `${AppsDashboardActions.CATEGORY} SET_NETWORK_INSIGHTS_LOG_MODAL_IS_OPEN`,
    props<{ isOpen: boolean }>(),
  );

  public static setNetworkInsightsLogSelectedTabName = createAction(
    `${AppsDashboardActions.CATEGORY} SET_NETWORK_INSIGHTS_LOG_SELECTED_TAB_NAME`,
    props<{ tab: string }>(),
  );

  public static setNetworkInsightsLogSelectedSortOns = createAction(
    `${AppsDashboardActions.CATEGORY} SET_NETWORK_INSIGHTS_LOG_SELECTED_SORT_ONS`,
    props<{ sortOns: SortOn[] }>(),
  );

  public static setNetworkLogsPagedRequest = createAction(
    `${AppsDashboardActions.CATEGORY} SET_NETWORK_LOGS_PAGED_REQUEST`,
    props<{ pagedRequest: PagedRequest }>(),
  );

  public static setNetworkInsightsLogSearchQuery = createAction(
    `${AppsDashboardActions.CATEGORY} SET_NETWORK_INSIGHTS_LOG_SEARCH_QUERY`,
    props<{ query: string }>(),
  );

  public static loadNetworkInsightsLog = createAction(`${AppsDashboardActions.CATEGORY} LOAD_NETWORK_INSIGHTS_LOG`);

  public static loadNetworkInsightsLogSuccess = createAction(
    `${AppsDashboardActions.CATEGORY} LOAD_NETWORK_INSIGHTS_LOG_SUCCESS`,
    props<{ response: CustomReportPreviewSearchResponse }>(),
  );

  public static loadNetworkInsightsLogFailure = createAction(
    `${AppsDashboardActions.CATEGORY} LOAD_NETWORK_INSIGHTS_LOG_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static downloadAppErrorStacktraceThreads = createAction(
    `${AppsDashboardActions.CATEGORY} DOWNLOAD_APP_ERROR_STACKTRACE_THREADS`,
    props<{ appCrashGroup: AppCrashGroup }>(),
  );

  public static downloadAppErrorStacktraceThreadsFromSearchResponse = createAction(
    `${AppsDashboardActions.CATEGORY} DOWNLOAD_APP_ERROR_STACKTRACE_THREADS_FROM_SEARCH_RESPONSE`,
  );

  public static loadAppErrorHeTable = createAction(`${AppsDashboardActions.CATEGORY} LOAD_APP_ERROR_HE_TABLE`);

  public static loadAppErrorHeTableSuccess = createAction(
    `${AppsDashboardActions.CATEGORY} LOAD_APP_ERROR_HE_TABLE_SUCCESS`,
    props<{ response: AppCrashGroupResponse }>(),
  );

  public static loadAppErrorHeTableFailure = createAction(
    `${AppsDashboardActions.CATEGORY} LOAD_APP_ERROR_HE_TABLE_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static downloadAppErrorCrashUpload = createAction(
    `${AppsDashboardActions.CATEGORY} DOWNLOAD_APP_ERROR_CRASH_UPLOAD`,
    props<{ id: string }>(),
  );

  public static downloadAppErrorCrashUploadSuccess = createAction(
    `${AppsDashboardActions.CATEGORY} DOWNLOAD_APP_ERROR_CRASH_UPLOAD_SUCCESS`,
  );

  public static downloadAppErrorCrashUploadFailure = createAction(
    `${AppsDashboardActions.CATEGORY} DOWNLOAD_APP_ERROR_CRASH_UPLOAD_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static deleteAppErrorCrashUpload = createAction(
    `${AppsDashboardActions.CATEGORY} DELETE_APP_ERROR_CRASH_UPLOAD`,
    props<{ id: string }>(),
  );

  public static deleteAppErrorCrashUploadSuccess = createAction(`${AppsDashboardActions.CATEGORY} DELETE_APP_ERROR_CRASH_UPLOAD_SUCCESS`);

  public static deleteAppErrorCrashUploadFailure = createAction(
    `${AppsDashboardActions.CATEGORY} DELETE_APP_ERROR_CRASH_UPLOAD_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static loadAppErrorCrashUploadsTable = createAction(`${AppsDashboardActions.CATEGORY} LOAD_APP_ERROR_CRASH_UPLOADS_TABLE`);

  public static loadAppErrorCrashUploadsTableSuccess = createAction(
    `${AppsDashboardActions.CATEGORY} LOAD_APP_ERROR_CRASH_UPLOADS_TABLE_SUCCESS`,
    props<{ response: AppErrorCrashUploadsTableResponse }>(),
  );

  public static loadAppErrorCrashUploadsTableFailure = createAction(
    `${AppsDashboardActions.CATEGORY} LOAD_APP_ERROR_CRASH_UPLOADS_TABLE_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static setAppErrorCrashUploadsTableFilterString = createAction(
    `${AppsDashboardActions.CATEGORY} SET_APP_ERROR_CRASH_UPLOADS_TABLE_FILTER_STRING`,
    props<{ query: string }>(),
  );

  public static setAppErrorCrashUploadsTablePagedRequest = createAction(
    `${AppsDashboardActions.CATEGORY} SET_APP_ERROR_CRASH_UPLOADS_TABLE_PAGED_REQUEST`,
    props<{ request: PagedRequest }>(),
  );

  public static setAppErrorCrashUploadsTableSortOns = createAction(
    `${AppsDashboardActions.CATEGORY} SET_APP_ERROR_CRASH_UPLOADS_TABLE_SORT_ONS`,
    props<{ sortOns: SortOn[] }>(),
  );

  public static goToDeemAppDetailsPage = createAction(
    `${AppsDashboardActions.CATEGORY} GO_TO_DEEM_APP_DETAILS_PAGE`,
    props<{
      deemAppDetailsPage: Partial<DeemAppDetailsPage>;
      userDetailPageType?: UserDetailPageType;
      subDashboardId?: string;
      platform?: string;
    }>(),
  );

  public static uploadCrashMapping = createAction(`${AppsDashboardActions.CATEGORY} UPLOAD_CRASH_MAPPING`, props<{ files: File[] }>());

  public static uploadCrashMappingSuccess = createAction(`${AppsDashboardActions.CATEGORY} UPLOAD_CRASH_MAPPING_SUCCESS`);

  public static uploadCrashMappingFailure = createAction(
    `${AppsDashboardActions.CATEGORY} UPLOAD_CRASH_MAPPING_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static setMappingUploadAppVersion = createAction(
    `${AppsDashboardActions.CATEGORY} SET_MAPPING_UPLOAD_APP_VERSION`,
    props<{ appVersion: string }>(),
  );

  public static setApteligentDashboardFilters = createAction(
    `${AppsDashboardActions.CATEGORY} SET_APTELIGENT_DASHBOARD_FILTERS`,
    props<DateRangeFilters>(),
  );

  public static updateAppDashboardFilters = createAction(
    `${AppsDashboardActions.CATEGORY} UPDATE_APP_DASHBOARD_FILTERS`,
    props<DateRangeFilters>(),
  );

  public static setAppsDashboardFilters = createAction(
    `${AppsDashboardActions.CATEGORY} SET_APPS_DASHBOARD_FILTERS`,
    props<DateRangeFilters>(),
  );

  public static loadAppNetworkInsightsDashboard = createAction(`${AppsDashboardActions.CATEGORY} LOAD_APP_NETWORK_INSIGHTS_DASHBOARD`);
}
