/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { RuleGroupOperator } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { cloneDeep, get, sortBy, values } from 'lodash-es';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { FilterGroupDefaultViewComponent } from '@ws1c/intelligence-core/components/query-builder/filter-group-default-view/filter-group-default-view.component';
import { CoreAppState, IntegrationMetaSelectors } from '@ws1c/intelligence-core/store';
import { Category, Column, ColumnIndex, FilterRule, QueryBuilder, RuleGroup, SuggestionFilterBy } from '@ws1c/intelligence-models';

/**
 * FilterGroupComponent
 * @export
 * @class FilterGroupComponent
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-filter-group',
  templateUrl: 'filter-group.component.html',
  styleUrls: ['filter-group.component.scss'],
  animations: [
    // prettier-ignore
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(250, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate(250, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class FilterGroupComponent implements OnChanges, OnDestroy {
  @Input() public readOnly?: boolean = false;
  @Input() public group: RuleGroup;
  @Input() public groupTextView: TemplateRef<any>;
  @Input() public groupView: TemplateRef<any>;
  @Input() public columnsByName: ColumnIndex;
  @Input() public showLess: boolean;
  @Input() public isCrossCategory?: boolean = false;
  @Input() public applyCardStyling?: boolean = true;
  @Input() public isCollapsed?: boolean = false;
  @Input() public suggestionCategory?: Category;
  @Input() public suggestionFilterBys?: SuggestionFilterBy[];
  @Input() public showThreeColumnFilter?: boolean = true;
  @Input() public alwaysShowKeySelector?: boolean = true;
  @Input() public showIncludesAllText?: boolean = false;
  @Input() public hideEmptyRules?: boolean = false;
  @Input() public showFilterTextInline?: boolean = false;
  @Input() public editable?: boolean = true;
  @Input() public delimiterSupported?: boolean = false;
  @Input() public showColumnsFromInput?: boolean = false;

  @Output() public rulesChanged: EventEmitter<RuleGroup> = new EventEmitter<RuleGroup>();
  @Output() public filterClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() public isFilterGroupValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public isCollapsedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('filterGroupDefaultView') public filterGroupRootComponent: FilterGroupDefaultViewComponent;

  public visibleColumnsSortedByName$: Observable<Column[]>;
  public group$: Subject<RuleGroup>;
  public validGroup$: Observable<RuleGroup>;
  public isValidGroup$: Observable<boolean>;
  public DEBOUNCE_TIME_MS: number = 200;
  public subscription: Subscription = new Subscription();

  public columnsSortedByName: Column[];
  public allColumnsByName: ColumnIndex = {};

  public focusFilterGroupRuleBound: any;
  public onFilterClickBound: any;

  public readonly RuleGroupOperator = RuleGroupOperator;

  /**
   * Creates an instance of FilterGroupComponent.
   * @param {Store<CoreAppState>} store
   * @memberof FilterGroupComponent
   */
  constructor(private store: Store<CoreAppState>) {
    this.visibleColumnsSortedByName$ = this.store.select(IntegrationMetaSelectors.getVisibleColumnsSortedByName);
    this.group$ = new Subject<RuleGroup>();

    this.init();

    this.subscription.add(
      this.store.select(IntegrationMetaSelectors.getColumnsByName).subscribe((allColumnsByName: ColumnIndex) => {
        this.allColumnsByName = allColumnsByName;
      }),
    );

    // functions bound for template use
    this.focusFilterGroupRuleBound = this.focusFilterGroupRule.bind(this);
    this.onFilterClickBound = this.onFilterClick.bind(this);
  }

  /**
   * init
   * @memberof FilterGroupComponent
   */
  public init() {
    this.validGroup$ = this.group$.pipe(
      filter(Boolean),
      map((group: RuleGroup) => {
        // clone the group to clone its rules, so we don't compare the same references in `distinctUntilChanged`
        const rules = group.rules.map(cloneDeep);
        return new RuleGroup(rules as Array<FilterRule | RuleGroup>, group.operator);
      }),
      filter((group: RuleGroup) => {
        // filter if the group has invalid rules, allow empty rules
        return !group.hasInvalidAndNotEmptyRules(this.allColumnsByName);
      }),
      distinctUntilChanged((prev: RuleGroup, next: RuleGroup) => {
        // we can't just use `isEqual(prev, next)` because we need to remove the isDraft property first
        // to avoid sending an unnecessary preview request after a value has been selected
        return QueryBuilder.areSameQueries(prev, next, this.columnsByName);
      }),
      debounceTime(this.DEBOUNCE_TIME_MS),
    );

    this.isValidGroup$ = this.group$.pipe(
      filter(Boolean),
      map((group: RuleGroup) => {
        return !group.hasInvalidAndNotEmptyRules(this.allColumnsByName);
      }),
      debounceTime(this.DEBOUNCE_TIME_MS),
    );

    this.subscription.add(
      this.validGroup$.subscribe(() => {
        this.rulesChanged.emit(this.group);
      }),
    );
    this.subscription.add(
      this.isValidGroup$.subscribe((isValid: boolean) => {
        this.isFilterGroupValid.emit(isValid);
      }),
    );
  }

  /**
   * ngOnChanges
   * The new array is needed for now to trigger onPush change detectors
   * @param {SimpleChanges} changes
   * @memberof FilterGroupComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    const newGroup = changes.group && changes.group.currentValue;
    if (newGroup && !newGroup.rules.length) {
      newGroup.rules = [this.getNewFilterRule()];
    }

    if (changes.columnsByName) {
      if (this.columnsByName) {
        this.allColumnsByName = this.columnsByName;
      }
      this.columnsSortedByName = sortBy(values(this.columnsByName), (column: Column) => (column.label || column.name).toLowerCase());
    }

    if (changes?.group?.currentValue || changes?.columnsByName?.currentValue) {
      if (this.group && this.allColumnsByName) {
        this.group$.next(this.group);
      }
    }
  }

  /**
   * @memberof FilterGroupComponent
   */
  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * focusFilterGroupRule
   * @param {FilterRule} rule
   * @memberof FilterGroupComponent
   */
  public focusFilterGroupRule(rule: FilterRule) {
    this.filterGroupRootComponent?.focusSelectedRule(rule);
  }

  /**
   * onRulesChange
   * Reassigning the copied rules Array is needed for now to trigger onPush change detectors
   * @param {RuleGroup} $event
   * @memberof FilterGroupComponent
   */
  public onRulesChange($event: RuleGroup) {
    this.group = $event;
    this.group$.next($event);
  }

  /**
   * onFilterClick
   * @memberof FilterGroupComponent
   */
  public onFilterClick() {
    this.filterClick.emit();
  }

  /**
   * getNewFilterRule
   * Needed for specs so we can spy on it
   * @returns {FilterRule}
   * @memberof FilterGroupComponent
   */
  public getNewFilterRule(): FilterRule {
    return new FilterRule();
  }

  /**
   * hasFilterGroup
   * @returns {boolean}
   * @memberof FilterGroupComponent
   */
  public hasFilterGroup(): boolean {
    if (get(this.group, ['rules', '0', 'attribute'])) {
      return true;
    }
    return false;
  }

  /**
   * isRuleGroup
   * @param {RuleGroup | FilterRule} rule
   * @returns {boolean}
   * @memberof FilterGroupComponent
   */
  public isRuleGroup(rule: RuleGroup | FilterRule): rule is RuleGroup {
    return RuleGroup.isRuleGroup(rule);
  }
  /**
   * toggleCollapse
   * @memberof FilterGroupComponent
   */
  public toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.isCollapsedChange.emit(this.isCollapsed);
  }
}
