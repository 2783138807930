/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GenericObject } from '@dpa/ui-common';
import { isEqual, keyBy } from 'lodash-es';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { JsonSchemaMetadata, JsonSchemaNode, LookupVariable } from '@ws1c/intelligence-models';

/**
 * DynamicFormComponent
 * @export
 * @class DynamicFormComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-dynamic-form',
  templateUrl: 'dynamic-form.component.html',
  styleUrls: ['dynamic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormComponent implements OnInit, OnDestroy {
  @Input() public jsonSchema: JsonSchemaNode;
  @Input() public metadata: JsonSchemaMetadata[];
  @Input() public columnLookupVariables?: LookupVariable[];
  @Input() public formValues?: GenericObject = {};
  @Input() public readonly?: boolean = false;
  @Input() public searchableActionsEnabled?: boolean = true;
  @Output() public formGroupChange: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();

  public metadataByAnchor: Record<string, JsonSchemaMetadata> = {};
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public sub: Subscription = new Subscription();

  /**
   * ngOnInit
   * @memberof DynamicFormComponent
   */
  public ngOnInit() {
    this.metadataByAnchor = keyBy(this.metadata, 'scope');
    this.sub.add(
      // Note: the 100ms debounce was added because this is fired off for every single form field resulting in excessive
      // change events (even on `input` focus). Internally, `valueChanges` patches the form values, which means that
      // `distinctUntilChanged` doesn't effectively reduce the number of times this is fired for all forms
      this.form.valueChanges.pipe(debounceTime(100), distinctUntilChanged(isEqual)).subscribe(() => {
        this.formGroupChange.emit(this.form);
      }),
    );
  }

  /**
   * ngOnDestroy
   * @memberof DynamicFormComponent
   */
  public ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
