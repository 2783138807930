/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { ActionReducerMap, combineReducers } from '@ngrx/store';

import { appsDashboardState } from './apps/apps-dashboard.reducer';
import { AppsDashboardState } from './apps/apps-dashboard.state';
import { carbonBlackDashboardState } from './carbon-black/carbon-black-dashboard.reducer';
import { CarbonBlackDashboardState } from './carbon-black/carbon-black-dashboard.state';
import { devicesDashboardState } from './devices/devices-dashboard.reducer';
import { DevicesDashboardState } from './devices/devices-dashboard.state';
import { horizonState } from './horizon/horizon.reducer';
import { HorizonState } from './horizon/horizon.state';
import { loginRiskDashboardState } from './login-risk/login-risk-dashboard.reducer';
import { LoginRiskDashboardState } from './login-risk/login-risk-dashboard.state';
import { osUpdatesDashboardState } from './os-updates/os-updates-dashboard.reducer';
import { OsUpdatesDashboardState } from './os-updates/os-updates-dashboard.state';
import { securityDashboardState } from './security/security-dashboard.reducer';
import { SecurityDashboardState } from './security/security-dashboard.state';
import { uagTunnelDashboardState } from './uag-tunnel/uag-tunnel-dashboard.reducer';
import { UagTunnelDashboardState } from './uag-tunnel/uag-tunnel-dashboard.state';
import { userProfileState } from './user-profile/user-profile.reducer';
import { UserProfileState } from './user-profile/user-profile.state';
import { userRiskDashboardState } from './user-risk/user-risk-dashboard.reducer';
import { UserRiskDashboardState } from './user-risk/user-risk-dashboard.state';
import { workspaceOneMtdDashboardState } from './workspace-one-mtd/workspace-one-mtd-dashboard.reducer';
import { WorkspaceOneMtdDashboardState } from './workspace-one-mtd/workspace-one-mtd-dashboard.state';

/**
 * StandardDashboardState
 * @export
 * @interface StandardDashboardState
 */
export interface StandardDashboardState {
  appsDashboardState: AppsDashboardState;
  carbonBlackDashboardState: CarbonBlackDashboardState;
  devicesDashboardState: DevicesDashboardState;
  horizonState: HorizonState;
  loginRiskDashboardState: LoginRiskDashboardState;
  osUpdatesDashboardState: OsUpdatesDashboardState;
  securityDashboardState: SecurityDashboardState;
  uagTunnelDashboardState: UagTunnelDashboardState;
  userProfileState: UserProfileState;
  userRiskDashboardState: UserRiskDashboardState;
  workspaceOneMtdDashboardState: WorkspaceOneMtdDashboardState;
}

/**
 * standardDashboardReducer
 */
export const standardDashboardReducer: ActionReducerMap<StandardDashboardState> = {
  appsDashboardState,
  carbonBlackDashboardState,
  devicesDashboardState,
  horizonState,
  loginRiskDashboardState,
  osUpdatesDashboardState,
  securityDashboardState,
  uagTunnelDashboardState,
  userRiskDashboardState,
  userProfileState,
  workspaceOneMtdDashboardState,
};

/**
 * standardDashboardReducer
 */
export const standardDashboardReducerMap: ActionReducerMap<StandardDashboardState> = {
  appsDashboardState,
  carbonBlackDashboardState,
  devicesDashboardState,
  horizonState,
  loginRiskDashboardState,
  osUpdatesDashboardState,
  securityDashboardState,
  uagTunnelDashboardState,
  userRiskDashboardState,
  userProfileState,
  workspaceOneMtdDashboardState,
};

export const standardDashboardReducers = combineReducers(standardDashboardReducerMap);
