/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { DynamicFormNodeComponent } from './components/dynamic-form-node/dynamic-form-node.component';
import { FORM_FIELD_COMPONENTS } from './components/form-field';

export const DYNAMIC_FORM_COMPONENTS: any[] = [DynamicFormComponent, DynamicFormNodeComponent, ...FORM_FIELD_COMPONENTS];

export * from './components/dynamic-form/dynamic-form.component';
export * from './components/form-field/form-field.component';
export * from './metaform.utils';
