/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { createAction, props } from '@ngrx/store';

import { TrendDateRange } from '@ws1c/intelligence-models';

/**
 * CarbonBlackDashboardActions
 * @export
 * @class CarbonBlackDashboardActions
 */
export class CarbonBlackDashboardActions {
  public static readonly CATEGORY = '[DASHBOARD][CARBONBLACK]';

  public static setTrendDateRange = createAction(
    `${CarbonBlackDashboardActions.CATEGORY} SET_CARBONBLACK_TREND_DATE_RANGE`,
    props<{ trendDateRange: TrendDateRange }>(),
  );

  public static loadDashboard = createAction(`${CarbonBlackDashboardActions.CATEGORY} LOAD_CARBONBLACK_DASHBOARD`);
}
