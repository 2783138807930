/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { PagedRequest, SortOn, WebError } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import { type } from '@ws1c/intelligence-common/utils/type';
import {
  AccountSearchResponse,
  DataAccessPolicy,
  DataAccessPolicyAssignPreview,
  DataAccessPolicyBulkAssignRequest,
  DataAccessPolicySearchResponse,
  GenericSearchRequest,
} from '@ws1c/intelligence-models';

/**
 * DataAccessPolicyActions
 * @export
 * @class DataAccessPolicyActions
 */
@Injectable()
export class DataAccessPolicyActions {
  public static readonly CATEGORY = '[DATA_ACCESS_POLICY]';

  public static readonly GET_DATA_ACCESS_POLICY = type(`${DataAccessPolicyActions.CATEGORY} GET_DATA_ACCESS_POLICY`);
  public static readonly GET_DATA_ACCESS_POLICY_SUCCESS = type(`${DataAccessPolicyActions.CATEGORY} GET_DATA_ACCESS_POLICY_SUCCESS`);
  public static readonly GET_DATA_ACCESS_POLICY_FAILURE = type(`${DataAccessPolicyActions.CATEGORY} GET_DATA_ACCESS_POLICY_FAILURE`);

  public static readonly GET_DATA_ACCESS_POLICY_BY_ID = type(`${DataAccessPolicyActions.CATEGORY} GET_DATA_ACCESS_POLICY_BY_ID`);
  public static readonly GET_DATA_ACCESS_POLICY_BY_ID_SUCCESS = type(
    `${DataAccessPolicyActions.CATEGORY} GET_DATA_ACCESS_POLICY_BY_ID_SUCCESS`,
  );
  public static readonly GET_DATA_ACCESS_POLICY_BY_ID_FAILURE = type(
    `${DataAccessPolicyActions.CATEGORY} GET_DATA_ACCESS_POLICY_BY_ID_FAILURE`,
  );

  public static readonly FILTER_DATA_ACCESS_POLICY = type(`${DataAccessPolicyActions.CATEGORY} FILTER_DATA_ACCESS_POLICY`);
  public static readonly PAGE_DATA_ACCESS_POLICY = type(`${DataAccessPolicyActions.CATEGORY} PAGE_DATA_ACCESS_POLICY`);
  public static readonly REFRESH_DATA_ACCESS_POLICY = type(`${DataAccessPolicyActions.CATEGORY} REFRESH_DATA_ACCESS_POLICY`);
  public static readonly SORT_DATA_ACCESS_POLICY = type(`${DataAccessPolicyActions.CATEGORY} SORT_DATA_ACCESS_POLICY`);

  public static readonly GO_TO_ADD_DATA_ACCESS_POLICY = type(`${DataAccessPolicyActions.CATEGORY} GO_TO_ADD_DATA_ACCESS_POLICY`);
  public static readonly GO_TO_EDIT_DATA_ACCESS_POLICY = type(`${DataAccessPolicyActions.CATEGORY} GO_TO_EDIT_DATA_ACCESS_POLICY`);

  public static readonly GO_TO_DATA_ACCESS_POLICY_DETAIL = type(`${DataAccessPolicyActions.CATEGORY} GO_TO_DATA_ACCESS_POLICY_DETAIL`);
  public static readonly GO_TO_LIST_VIEW = type(`${DataAccessPolicyActions.CATEGORY} GO_TO_LIST_VIEW`);

  public static readonly PREVIEW_ADD_DATA_ACCESS_POLICY = type(`${DataAccessPolicyActions.CATEGORY} PREVIEW_ADD_DATA_ACCESS_POLICY`);
  public static readonly ADD_DATA_ACCESS_POLICY = type(`${DataAccessPolicyActions.CATEGORY} ADD_DATA_ACCESS_POLICY`);
  public static readonly EDIT_DATA_ACCESS_POLICY = type(`${DataAccessPolicyActions.CATEGORY} EDIT_DATA_ACCESS_POLICY`);

  public static readonly ASSIGN_USERS = type(`${DataAccessPolicyActions.CATEGORY} ASSIGN_USERS`);
  public static readonly ASSIGN_USERS_SUCCESS = type(`${DataAccessPolicyActions.CATEGORY} ASSIGN_USERS_SUCCESS`);
  public static readonly ASSIGN_USERS_FAILURE = type(`${DataAccessPolicyActions.CATEGORY} ASSIGN_USERS_FAILURE`);

  public static readonly PREVIEW_EDIT_DATA_ACCESS_POLICY = type(`${DataAccessPolicyActions.CATEGORY} PREVIEW_EDIT_DATA_ACCESS_POLICY`);
  public static readonly GET_ASSIGN_USERS_PREVIEW = type(`${DataAccessPolicyActions.CATEGORY} GET_ASSIGN_USERS_PREVIEW`);
  public static readonly SET_ASSIGN_USERS_PREVIEW = type(`${DataAccessPolicyActions.CATEGORY} SET_ASSIGN_USERS_PREVIEW`);

  public static readonly UNASSIGN_USERS = type(`${DataAccessPolicyActions.CATEGORY} UNASSIGN_USERS`);

  public static readonly GET_ASSIGNED_USERS = type(`${DataAccessPolicyActions.CATEGORY} GET_ASSIGNED_USERS`);
  public static readonly GET_ASSIGNED_USERS_SUCCESS = type(`${DataAccessPolicyActions.CATEGORY} GET_ASSIGNED_USERS_SUCCESS`);

  public static readonly SHOW_DELETE_CONFIRM_MODAL = type(`${DataAccessPolicyActions.CATEGORY} SHOW_DELETE_CONFIRM_MODAL`);
  public static readonly DELETE_DATA_ACCESS_POLICY = type(`${DataAccessPolicyActions.CATEGORY} DELETE_DATA_ACCESS_POLICY`);
  public static readonly DELETE_DATA_ACCESS_POLICY_SUCCESS = type(`${DataAccessPolicyActions.CATEGORY} DELETE_DATA_ACCESS_POLICY_SUCCESS`);
  public static readonly DELETE_DATA_ACCESS_POLICY_FAILURE = type(`${DataAccessPolicyActions.CATEGORY} DELETE_DATA_ACCESS_POLICY_FAILURE`);

  public static readonly SELECT_DATA_ACCESS_POLICY = type(`${DataAccessPolicyActions.CATEGORY} SELECT_DATA_ACCESS_POLICY`);
  public static readonly SELECTED_DATA_ACCESS_POLICY = type(`${DataAccessPolicyActions.CATEGORY} SELECTED_DATA_ACCESS_POLICY`);
  public static readonly SET_SELECTED_DATA_ACCESS_POLICY = type(`${DataAccessPolicyActions.CATEGORY} SET_SELECTED_DATA_ACCESS_POLICY`);

  public static readonly TOGGLE_DATA_ACCESS_POLICY = type(`${DataAccessPolicyActions.CATEGORY} TOGGLE_DATA_ACCESS_POLICY`);

  public static getDataAccessPolicy = createAction(
    DataAccessPolicyActions.GET_DATA_ACCESS_POLICY,
    props<{ genericSearchRequest: GenericSearchRequest }>(),
  );
  public static getDataAccessPolicySuccess = createAction(
    DataAccessPolicyActions.GET_DATA_ACCESS_POLICY_SUCCESS,
    props<{ dataAccessPolicySearchResponse: DataAccessPolicySearchResponse }>(),
  );
  public static getDataAccessPolicyFailure = createAction(
    DataAccessPolicyActions.GET_DATA_ACCESS_POLICY_FAILURE,
    props<{ webError: WebError }>(),
  );

  public static getDataAccessPolicyById = createAction(DataAccessPolicyActions.GET_DATA_ACCESS_POLICY_BY_ID, props<{ policyId: string }>());
  public static getDataAccessPolicyByIdSuccess = createAction(
    DataAccessPolicyActions.GET_DATA_ACCESS_POLICY_BY_ID_SUCCESS,
    props<{ dataAccessPolicy: DataAccessPolicy }>(),
  );
  public static getDataAccessPolicyByIdFailure = createAction(
    DataAccessPolicyActions.GET_DATA_ACCESS_POLICY_BY_ID_FAILURE,
    props<{ webError: WebError }>(),
  );

  public static goToListView = createAction(DataAccessPolicyActions.GO_TO_LIST_VIEW);

  public static goToAddDataAccessPolicy = createAction(DataAccessPolicyActions.GO_TO_ADD_DATA_ACCESS_POLICY);

  public static goToEditDataAccessPolicy = createAction(
    DataAccessPolicyActions.GO_TO_EDIT_DATA_ACCESS_POLICY,
    props<{ dataAccessPolicy: DataAccessPolicy }>(),
  );

  public static previewAddDataAccessPolicy = createAction(
    DataAccessPolicyActions.PREVIEW_ADD_DATA_ACCESS_POLICY,
    props<{
      dataAccessPolicy: DataAccessPolicy;
      preview: DataAccessPolicyAssignPreview;
    }>(),
  );

  public static addDataAccessPolicy = createAction(DataAccessPolicyActions.ADD_DATA_ACCESS_POLICY);

  public static editDataAccessPolicy = createAction(DataAccessPolicyActions.EDIT_DATA_ACCESS_POLICY);

  public static assignUsers = createAction(
    DataAccessPolicyActions.ASSIGN_USERS,
    props<{
      bulkAssignRequest: DataAccessPolicyBulkAssignRequest;
    }>(),
  );

  public static previewEditDataAccessPolicy = createAction(
    DataAccessPolicyActions.PREVIEW_EDIT_DATA_ACCESS_POLICY,
    props<{
      dataAccessPolicy: DataAccessPolicy;
    }>(),
  );

  public static setAssignUsersPreview = createAction(
    DataAccessPolicyActions.SET_ASSIGN_USERS_PREVIEW,
    props<{
      preview: DataAccessPolicyAssignPreview;
    }>(),
  );

  public static assignUsersSuccess = createAction(DataAccessPolicyActions.ASSIGN_USERS_SUCCESS);

  public static unAssignUsers = createAction(
    DataAccessPolicyActions.UNASSIGN_USERS,
    props<{
      bulkAssignRequest: DataAccessPolicyBulkAssignRequest;
    }>(),
  );

  public static getAssignedUsers = createAction(DataAccessPolicyActions.GET_ASSIGNED_USERS, props<{ id: string }>());

  public static getAssignedUsersSuccess = createAction(
    DataAccessPolicyActions.GET_ASSIGNED_USERS_SUCCESS,
    props<{ assignedAccountsSearchResponse: AccountSearchResponse }>(),
  );

  public static filterDataAccessPolicy = createAction(DataAccessPolicyActions.FILTER_DATA_ACCESS_POLICY, props<{ query: string }>());
  public static pageDataAccessPolicy = createAction(
    DataAccessPolicyActions.PAGE_DATA_ACCESS_POLICY,
    props<{ pagedRequest: PagedRequest }>(),
  );
  public static refreshDataAccessPolicy = createAction(DataAccessPolicyActions.REFRESH_DATA_ACCESS_POLICY);
  public static sortDataAccessPolicy = createAction(DataAccessPolicyActions.SORT_DATA_ACCESS_POLICY, props<{ sortOns: SortOn[] }>());

  public static showDeleteConfirmModal = createAction(
    DataAccessPolicyActions.SHOW_DELETE_CONFIRM_MODAL,
    props<{
      showModal: boolean;
      dataAccessPolicy: DataAccessPolicy;
    }>(),
  );

  public static deleteDataAccessPolicy = createAction(DataAccessPolicyActions.DELETE_DATA_ACCESS_POLICY);

  public static deleteDataAccessPolicySuccess = createAction(DataAccessPolicyActions.DELETE_DATA_ACCESS_POLICY_SUCCESS);

  public static deleteDataAccessPolicyFailure = createAction(
    DataAccessPolicyActions.DELETE_DATA_ACCESS_POLICY_FAILURE,
    props<{ webError: WebError }>(),
  );

  public static selectDataAccessPolicy = createAction(
    DataAccessPolicyActions.SELECT_DATA_ACCESS_POLICY,
    props<{ dataAccessPolicies: DataAccessPolicy[] }>(),
  );
  public static setSelectedDataAccessPolicy = createAction(
    DataAccessPolicyActions.SET_SELECTED_DATA_ACCESS_POLICY,
    props<{ dataAccessPolicy: DataAccessPolicy }>(),
  );

  public static toggleDataAccessPolicy = createAction(
    DataAccessPolicyActions.TOGGLE_DATA_ACCESS_POLICY,
    props<{ dataAccessPolicy: DataAccessPolicy }>(),
  );
}
