/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AlertBannerTarget, Connector, ConnectorUpdateParams } from '@ws1c/intelligence-models';

/**
 * ConnectorRenameModalComponent
 *
 * migrated from CustomConnectionModalsComponent
 * @export
 * @class ConnectorRenameModalComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-connector-rename-modal',
  templateUrl: 'connector-rename-modal.component.html',
  styleUrls: ['connector-rename-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectorRenameModalComponent implements OnChanges {
  @Input() public isOpen?: boolean = true;
  @Input() public isLoading: boolean = false;
  @Input() public connector: Connector;
  @Output() public modalClose: EventEmitter<void> = new EventEmitter();
  @Output() public rename: EventEmitter<ConnectorUpdateParams> = new EventEmitter();

  public ALERT_BANNER_TARGET = AlertBannerTarget;
  public connectorForm: UntypedFormGroup;

  /**
   * Creates an instance of ConnectorRenameModalComponent
   * @param {UntypedFormBuilder} fb
   * @memberof ConnectorRenameModalComponent
   */
  constructor(private fb: UntypedFormBuilder) {
    this.connectorForm = this.fb.group({
      name: ['', [Validators.required]],
      description: [''],
    });
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof ConnectorRenameModalComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.connector?.currentValue) {
      const connector = changes.connector?.currentValue;
      this.connectorForm.patchValue({
        name: connector.name,
        description: connector.description,
      });
    }
  }

  /**
   * confirmRename
   * @memberof ConnectorRenameModalComponent
   */
  public confirmRename() {
    const params = new ConnectorUpdateParams({
      ...this.connectorForm.value,
    });
    this.rename.emit(params);
  }
}
