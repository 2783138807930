<dpa-modal
  *ngIf="isModalOpen"
  [modalOpen]="isModalOpen"
  (onModalClose)="closeModal()"
>
  <dpa-modal-title>
    {{ 'WIDGET_CUSTOMIZE.EDIT_DATA_RANGES' | translate }}
  </dpa-modal-title>
  <div class="modal-body">
    <form
      clrForm
      [formGroup]="editRangeFormGroup"
      class="clr-form"
    >
      <span class="clr-required-mark">{{ 'COMMON_MESSAGES.REQUIRED' | translate }}</span>
      <div class="clr-form-control clr-row slider-control">
        <label
          class="clr-control-label clr-col-sm-3 mt-4 clr-required-mark"
          for="trialFirstName"
        >
          <span>{{ 'WIDGET_CUSTOMIZE.GROUP_BY_COLON' | translate }}</span>
          <span class="ml-1">
            {{ groupByLabel$ | async }}
          </span>
          <span *ngIf="editRangeFormGroup.get('rangeType')?.value === RANGE_TYPE.PERCENTAGE">(%)</span>
        </label>
        <div class="clr-control-container clr-col-sm-9">
          <dpa-range-slider
            *ngIf="max"
            #rangeSlider
            [min]="min"
            [max]="max"
            [step]="step"
            [lowBoundary]="boundaries.low"
            [highBoundary]="boundaries.high"
            [colorConfig]="SLIDER_COLOR_CONFIG"
            (onChange)="setSelectedRange($event)"
          ></dpa-range-slider>
        </div>
      </div>
      <clr-radio-container>
        <clr-radio-wrapper>
          <input
            clrRadio
            [value]="RANGE_TYPE.COUNT"
            formControlName="rangeType"
            type="radio"
          />
          <label class="pl-5">
            {{ 'WIDGET_CUSTOMIZE.ZOOM_IN_ON_A_SUBSET_OF_THE_Y_AXIS' | translate }}
          </label>
        </clr-radio-wrapper>
        <clr-radio-wrapper>
          <input
            clrRadio
            [value]="RANGE_TYPE.PERCENTAGE"
            formControlName="rangeType"
            type="radio"
          />
          <label class="pl-5 mt-2">
            {{ 'WIDGET_CUSTOMIZE.FILTER_GROUPS_BY_PERCENTAGE_OF_THE_WHOLE_DATASET' | translate }}
            <dpa-tooltip [tooltipTemplate]="tooltipTemplate">
              <cds-icon
                shape="info-circle"
                size="24"
              ></cds-icon>
              <ng-template #tooltipTemplate>
                <div class="tooltip-container">
                  <div>
                    {{ 'WIDGET_CUSTOMIZE.FILTER_GROUPS_BY_PERCENTAGE_DESCRIPTION' | translate }}
                  </div>
                  <div class="mt-x2">
                    {{ 'WIDGET_CUSTOMIZE.FILTER_GROUPS_BY_PERCENTAGE_FORMULA' | translate }}
                  </div>
                </div>
              </ng-template>
            </dpa-tooltip>
          </label>
        </clr-radio-wrapper>
      </clr-radio-container>
    </form>
  </div>
  <div class="modal-footer">
    <button
      (click)="closeModal()"
      class="btn btn-outline"
    >
      {{ 'COMMON_ACTIONS.CANCEL' | translate }}
    </button>
    <button
      [disabled]="isSaveButtonDisabled()"
      (click)="saveRange()"
      class="btn btn-primary"
    >
      {{ 'COMMON_ACTIONS.SAVE' | translate }}
    </button>
  </div>
</dpa-modal>
