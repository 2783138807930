/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { PagedRequest, WebError } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import {
  DATA_SOURCES_CARD_TYPE,
  GenericSearchRequest,
  IntegrationActionsSearchResponse,
  IntegrationDetails,
  IntegrationDetailView,
  IntegrationHealthReport,
  IntegrationObjectsCounts,
  IntegrationObjectsSearchResponse,
  IntegrationObjectsType,
  IntegrationReprovisionStatus,
  IntegrationResultCode,
} from '@ws1c/intelligence-models';

/**
 * IntegrationActions
 * @export
 * @class IntegrationActions
 */
export class IntegrationActions {
  public static readonly CATEGORY = '[INTEGRATION]';

  public static getStarted = createAction(`${IntegrationActions.CATEGORY} GET_STARTED`);

  public static setCurrentIntegration = createAction(
    `${IntegrationActions.CATEGORY} SET_CURRENT_INTEGRATION`,
    props<{ integration: string }>(),
  );

  public static handleRedirectfromSetupFlow = createAction(`${IntegrationActions.CATEGORY} HANDLE_REDIRECT_FROM_SETUP_FLOW`);

  public static deAuthorize = createAction(`${IntegrationActions.CATEGORY} DEAUTHORIZE`);

  public static deAuthorizeSuccess = createAction(`${IntegrationActions.CATEGORY} DEAUTHORIZE_SUCCESS`);

  public static deAuthorizeFailure = createAction(`${IntegrationActions.CATEGORY} DEAUTHORIZE_FAILURE`, props<{ error: WebError }>());

  public static showDeauthConfirmModal = createAction(
    `${IntegrationActions.CATEGORY} SHOW_DEAUTHORIZE_CONFIRM_MODAL`,
    props<{ showDeauthConfirmModal: boolean }>(),
  );

  public static updateAuthorizedState = createAction(
    `${IntegrationActions.CATEGORY} UPDATE_AUTHORIZED_STATE`,
    props<{
      integration: string;
      state: boolean;
      integrationResultCode?: IntegrationResultCode;
      errorMessage?: string;
    }>(),
  );

  public static authorizeSuccess = createAction(`${IntegrationActions.CATEGORY} AUTHORIZE_SUCCESS`);

  public static authorizeFailure = createAction(
    `${IntegrationActions.CATEGORY} AUTHORIZE_FAILURE`,
    props<{
      error?: WebError;
      integrationResultCode?: IntegrationResultCode;
      errorMessage?: string;
    }>(),
  );

  public static showSetupModal = createAction(`${IntegrationActions.CATEGORY} SHOW_SETUP_MODAL`, props<{ showSetupModal: boolean }>());

  public static showAuthConfirmModal = createAction(
    `${IntegrationActions.CATEGORY} SHOW_AUTHORIZE_CONFIRM_MODAL`,
    props<{ showAuthConfirmModal: boolean }>(),
  );

  public static getItsmStarted = createAction(`${IntegrationActions.CATEGORY} GET_ITSM_STARTED`);

  public static showItsmSetupModal = createAction(
    `${IntegrationActions.CATEGORY} SHOW_ITSM_SETUP_MODAL`,
    props<{ showItsmSetupModal: boolean }>(),
  );

  public static showItsmAuthConfirmModal = createAction(
    `${IntegrationActions.CATEGORY} SHOW_ITSM_AUTHORIZE_CONFIRM_MODAL`,
    props<{ showItsmAuthConfirmModal: boolean }>(),
  );

  public static showItsmDeauthConfirmModal = createAction(
    `${IntegrationActions.CATEGORY} SHOW_ITSM_DEAUTHORIZE_CONFIRM_MODAL`,
    props<{ showItsmDeauthConfirmModal: boolean }>(),
  );

  public static getUagStarted = createAction(`${IntegrationActions.CATEGORY} GET_UAG_STARTED`);

  public static showUagSetupModal = createAction(
    `${IntegrationActions.CATEGORY} SHOW_UAG_SETUP_MODAL`,
    props<{ showUagSetupModal: boolean }>(),
  );

  public static showUagAuthConfirmModal = createAction(
    `${IntegrationActions.CATEGORY} SHOW_UAG_AUTHORIZE_CONFIRM_MODAL`,
    props<{ showUagAuthConfirmModal: boolean }>(),
  );

  public static showUagDeauthConfirmModal = createAction(
    `${IntegrationActions.CATEGORY} SHOW_UAG_DEAUTHORIZE_CONFIRM_MODAL`,
    props<{ showUagDeauthConfirmModal: boolean }>(),
  );

  public static finishAuthConfirmModalSuccess = createAction(
    `${IntegrationActions.CATEGORY} FINISH_AUTH_CONFIRM_MODAL_SUCCESS`,
    props<{ finishAuthConfirmModalSuccess: boolean }>(),
  );

  public static finishAuthConfirmModalFailure = createAction(
    `${IntegrationActions.CATEGORY} FINISH_AUTH_CONFIRM_MODAL_FAILURE`,
    props<{ finishAuthConfirmModalFailure: boolean }>(),
  );

  public static downloadCredential = createAction(`${IntegrationActions.CATEGORY} DOWNLOAD_CREDENTIAL`, props<{ integration: string }>());

  public static deactivateCredential = createAction(
    `${IntegrationActions.CATEGORY} DEACTIVATE_CREDENTIAL`,
    props<{
      integration: string;
    }>(),
  );

  public static deauthorizeFromIntegrationViewSuccess = createAction(
    `${IntegrationActions.CATEGORY} DEAUTHORIZE_FROM_INTEGRATION_VIEW_SUCCESS`,
  );

  public static reactivateCredential = createAction(
    `${IntegrationActions.CATEGORY} REACTIVATE_CREDENTIAL`,
    props<{
      integration: string;
      sourceSystemId: string;
    }>(),
  );

  public static showAzureSetupModal = createAction(
    `${IntegrationActions.CATEGORY} SHOW_AZURE_SETUP_MODAL`,
    props<{ showAzureSetupModal: boolean }>(),
  );

  public static showDeleteAzureConnectionModal = createAction(
    `${IntegrationActions.CATEGORY} SHOW_DELETE_AZURE_CONNECTION_MODAL`,
    props<{ showDeleteAzureConnectionModal: boolean }>(),
  );

  public static showReauthorizeAzureConnectionModal = createAction(
    `${IntegrationActions.CATEGORY} SHOW_REAUTHORIZE_AZURE_CONNECTION_MODAL`,
    props<{ showReauthorizeAzureConnectionModal: boolean }>(),
  );

  public static initializeIntegrationView = createAction(
    `${IntegrationActions.CATEGORY} INITIALIZE_INTEGRATION_VIEW`,
    props<{ integrationDetailView: IntegrationDetailView }>(),
  );

  public static optIntoIntegration = createAction(
    `${IntegrationActions.CATEGORY} OPT_INTO_INTEGRATION`,
    props<{ integrationDetails: IntegrationDetails }>(),
  );

  public static optIntoIntegrationSuccess = createAction(
    `${IntegrationActions.CATEGORY} OPT_INTO_INTEGRATION_SUCCESS`,
    props<{ id: string }>(),
  );

  public static optIntoIntegrationFailure = createAction(
    `${IntegrationActions.CATEGORY} OPT_INTO_INTEGRATION_FAILURE`,
    props<{ id: string }>(),
  );

  public static optIntoIntegrationComplete = createAction(`${IntegrationActions.CATEGORY} OPT_INTO_INTEGRATION_COMPLETE`);

  public static optIntoIntegrations = createAction(
    `${IntegrationActions.CATEGORY} OPT_INTO_INTEGRATIONS`,
    props<{ integrationDetailsList: IntegrationDetails[] }>(),
  );

  public static optIntoIntegrationsComplete = createAction(`${IntegrationActions.CATEGORY} OPT_INTO_INTEGRATIONS_COMPLETE`);

  public static optOutOfIntegration = createAction(
    `${IntegrationActions.CATEGORY} OPT_OUT_OF_INTEGRATION`,
    props<{ integrationDetails: IntegrationDetails }>(),
  );

  public static getIntegrationReprovisioningStatus = createAction(`${IntegrationActions.CATEGORY} GET_INTEGRATION_REPROVISIONING_STATUS`);

  public static updateIntegrationReprovisioningStatus = createAction(
    `${IntegrationActions.CATEGORY} UPDATE_INTEGRATION_REPROVISIONING_STATUS`,
    props<{ integrationReprovisionStatus: IntegrationReprovisionStatus }>(),
  );

  public static setIntegrationReprovisioningStatus = createAction(
    `${IntegrationActions.CATEGORY} SET_INTEGRATION_REPROVISIONING_STATUS`,
    props<{ accessIntegrationReprovisioningStatusList: IntegrationReprovisionStatus[] }>(),
  );

  public static setActiveIntegrationDetails = createAction(
    `${IntegrationActions.CATEGORY} SET_ACTIVE_INTEGRATION_DETAILS`,
    props<{ integrationDetails: IntegrationDetails }>(),
  );

  public static setPreviewIntegrationSlideOverOpen = createAction(
    `${IntegrationActions.CATEGORY} SET_PREVIEW_INTEGRATION_SLIDE_OVER_OPEN`,
    props<{ isOpen: boolean }>(),
  );

  public static viewIntegrationDetails = createAction(
    `${IntegrationActions.CATEGORY} VIEW_INTEGRATION_DETAILS`,
    props<{
      integrationDetailView: IntegrationDetailView;
      dataSourceCardType?: DATA_SOURCES_CARD_TYPE;
    }>(),
  );

  public static loadIntegrationHealthStatus = createAction(`${IntegrationActions.CATEGORY} LOAD_INTEGRATION_HEALTH_STATUS`);

  public static loadIntegrationHealthStatusSuccess = createAction(
    `${IntegrationActions.CATEGORY} LOAD_INTEGRATION_HEALTH_STATUS_SUCCESS`,
    props<{
      integration: string;
      integrationHealthReport: IntegrationHealthReport;
    }>(),
  );

  public static loadIntegrationHealthStatusFailure = createAction(`${IntegrationActions.CATEGORY} LOAD_INTEGRATION_HEALTH_STATUS_FAILURE`);

  public static copyIntegrationHealthStatusSuccess = createAction(`${IntegrationActions.CATEGORY} COPY_INTEGRATION_HEALTH_STATUS_SUCCESS`);

  public static copyIntegrationHealthStatusFailure = createAction(`${IntegrationActions.CATEGORY} COPY_INTEGRATION_HEALTH_STATUS_FAILURE`);

  public static updateIntegrationTenantAuthorization = createAction(
    `${IntegrationActions.CATEGORY} UPDATE_INTEGRATION_TENANT_AUTHORIZATION`,
    props<{
      isAuthorizingIntegrationTenant: boolean;
      integrationDetails: IntegrationDetails;
    }>(),
  );

  public static loadIntegrationObjectsCounts = createAction(`${IntegrationActions.CATEGORY} LOAD_INTEGRATION_OBJECTS_COUNTS`);

  public static loadIntegrationObjectsCountsSuccess = createAction(
    `${IntegrationActions.CATEGORY} LOAD_INTEGRATION_OBJECTS_COUNTS_SUCCESS`,
    props<{ objectsCounts: IntegrationObjectsCounts }>(),
  );

  public static loadIntegrationObjectsCountsFailure = createAction(
    `${IntegrationActions.CATEGORY} LOAD_INTEGRATION_OBJECTS_COUNTS_FAILURE`,
  );

  public static loadIntegrationObjects = createAction(
    `${IntegrationActions.CATEGORY} LOAD_INTEGRATION_OBJECTS`,
    props<{
      selectedObjectType: IntegrationObjectsType;
      objectsSearchRequest: GenericSearchRequest;
    }>(),
  );

  public static loadIntegrationObjectsSuccess = createAction(
    `${IntegrationActions.CATEGORY} LOAD_INTEGRATION_OBJECTS_SUCCESS`,
    props<{ objectsSearchResponse: IntegrationObjectsSearchResponse }>(),
  );

  public static loadIntegrationObjectsFailure = createAction(`${IntegrationActions.CATEGORY} LOAD_INTEGRATION_OBJECTS_FAILURE`);

  public static changeIntegrationObjectsPagination = createAction(
    `${IntegrationActions.CATEGORY} CHANGE_INTEGRATION_OBJECTS_PAGINATION`,
    props<{ pagedRequest: PagedRequest }>(),
  );

  public static refreshIntegrationObjects = createAction(`${IntegrationActions.CATEGORY} REFRESH_INTEGRATION_OBJECTS`);

  public static loadIntegrationActions = createAction(
    `${IntegrationActions.CATEGORY} LOAD_INTEGRATION_ACTIONS`,
    props<{ searchRequest: GenericSearchRequest }>(),
  );

  public static loadIntegrationActionsSuccess = createAction(
    `${IntegrationActions.CATEGORY} LOAD_INTEGRATION_ACTIONS_SUCCESS`,
    props<{ searchResponse: IntegrationActionsSearchResponse }>(),
  );

  public static loadIntegrationActionsFailure = createAction(`${IntegrationActions.CATEGORY} LOAD_INTEGRATION_ACTIONS_FAILURE`);

  public static changeIntegrationActionsPagination = createAction(
    `${IntegrationActions.CATEGORY} CHANGE_INTEGRATION_ACTIONS_PAGINATION`,
    props<{ pagedRequest: PagedRequest }>(),
  );

  public static refreshIntegrationActions = createAction(`${IntegrationActions.CATEGORY} REFRESH_INTEGRATION_ACTIONS`);
}
