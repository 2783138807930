<ng-container
  *ngIf="node"
  [ngSwitch]="node.type"
>
  <!-- NODE TYPE OBJECT -->
  <ng-container *ngSwitchCase="NODE_TYPE.OBJECT">
    <h6 *ngIf="node.title">
      {{ node.title }}
    </h6>
    <ng-container *ngFor="let item of node.properties | keyvalue: originalOrder">
      <dpa-dynamic-form-node
        [node]="item.value"
        [metadataByAnchor]="metadataByAnchor"
        [dependentNodes]="node.dependencies?.[item.key]?.oneOf"
        [nodeKey]="item.key"
        [required]="node.required?.includes(item.key)"
        [parentForm]="nodeControl ?? parentForm"
        [formValues]="nodeControl && formValues?.[nodeKey] ? formValues?.[nodeKey] : formValues"
        [columnLookupVariables]="columnLookupVariables"
        [readonly]="readonly"
        [searchableActionsEnabled]="searchableActionsEnabled"
      ></dpa-dynamic-form-node>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="NODE_TYPE.ARRAY">
    <ng-container *ngIf="iterateArray; else formField">
      <h6 *ngIf="node.title">
        {{ node.title }}
      </h6>
      <ng-container *ngFor="let item of itemsArray; let i = index; trackBy: trackByForArray">
        <dpa-dynamic-form-node
          [node]="item"
          [metadataByAnchor]="metadataByAnchor"
          [nodeKey]="item.$anchor"
          [required]="node.required?.includes(item.$anchor)"
          [parentFormArray]="nodeControl"
          [formValues]="formValues[nodeKey] ?? formValues"
          [value]="formValues?.[nodeKey]?.[i]"
          [columnLookupVariables]="columnLookupVariables"
          [readonly]="readonly"
          [searchableActionsEnabled]="searchableActionsEnabled"
        ></dpa-dynamic-form-node>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- NODE TYPE DEFAULT -->
  <ng-container *ngSwitchDefault>
    <ng-template [ngTemplateOutlet]="formField"></ng-template>
  </ng-container>
</ng-container>
<ng-template #formField>
  <dpa-form-field
    *ngIf="!node.enum || node.enum.length > 1"
    [name]="nodeKey"
    [control]="nodeControl"
    [metadata]="nodeMetadata"
    [required]="required"
    [enumList]="nodeEnumList"
    [defaultValue]="node.default"
    [columnLookupVariables]="columnLookupVariables"
    [searchableActionsEnabled]="searchableActionsEnabled"
    [value]="value"
    [readonly]="readonly"
    [formNodeId]="id"
  ></dpa-form-field>
</ng-template>

<!-- NODE DEPENDENCIES -->
<dpa-dynamic-form-node
  *ngIf="dependentNode"
  [node]="dependentNode"
  [metadataByAnchor]="metadataByAnchor"
  [parentForm]="parentForm"
  [formValues]="formValues"
  [columnLookupVariables]="columnLookupVariables"
  [readonly]="readonly"
  [searchableActionsEnabled]="searchableActionsEnabled"
></dpa-dynamic-form-node>
