/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { GenericObject, unsubscribe } from '@dpa/ui-common';
import { each, filter, find, get, isEmpty, isEqual, uniqueId } from 'lodash-es';
import Quill from 'quill';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { I18NService } from '@ws1c/intelligence-common/services/i18n.service';
import { getColumnKey } from '@ws1c/intelligence-common/utils/type-ahead-helper';
import {
  AutomationActionFieldForLookup,
  AutomationActionFieldLookupRequestPayload,
  ChoiceValue,
  DataType,
  FieldsMetaForm,
  isFieldDisplayed,
  LookupVariable,
  MetaFormConfig,
  MetaFormField,
  MetaFormFieldPresentationType,
  MetaFormFieldValidators,
  NameValue,
  UserAdminAccount,
} from '@ws1c/intelligence-models';

/**
 * Create a html form using metaData
 *
 * @export
 * @class FieldsMetaFormComponent
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-fields-meta-form',
  templateUrl: 'fields-meta-form.component.html',
  styleUrls: ['fields-meta-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldsMetaFormComponent implements OnChanges, OnDestroy, OnInit {
  @Input() public columnLookupVariables?: any[] = [];
  /**
   * accepting initial values through a formGroup input doesn't work
   * formGroup can not have any formGroupControls outside this component
   * this is because a change in metaForm triggers resetting the formGroupControls
   * formGroups without formGroupControls can not have their values set
   */
  @Input() public formGroup: UntypedFormGroup = new UntypedFormGroup({});
  @Input() public isEditMode?: boolean = false;
  @Input() public metaForm: FieldsMetaForm;
  @Input() public settings: any;
  @Input() public searchableActionsEnabled?: boolean = false;
  @Input() public lookupMap: Record<string, NameValue[]> = {};
  @Input() public lookupMapLoading?: boolean = false;
  @Input() public nestedLookups?: GenericObject = {};
  @Output() public formGroupChange: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
  @Output() public lookupChoiceChange = new EventEmitter<AutomationActionFieldForLookup>();
  @Output() public onRequestLookup = new EventEmitter<AutomationActionFieldLookupRequestPayload>();

  public isLookupSelectedForField: Record<string, boolean> = {};
  public filteredFormFields: MetaFormField[] = [];
  public selectedUsersByFieldName: Record<string, UserAdminAccount[]> = {};
  public sub: Subscription;
  public unsubscribe: (subs: Subscription | Subscription[]) => void = unsubscribe;
  public debounceTime: number = 200;
  public editorInstance: Quill | undefined;
  public readonly MetaFormFieldPresentationType = MetaFormFieldPresentationType;

  // Clarity require each label + input pair to have a unique id.
  public id: string = uniqueId('metaform-component');
  public getColumnKey: (column: any) => string = getColumnKey;

  /**
   * constructor
   * @param {I18NService} i18nService
   */
  constructor(private i18nService: I18NService) {
    this.subscribeToFormGroup(this.formGroup);
  }

  /**
   * ngOnInit
   * @memberof FieldsMetaFormComponent
   */
  public ngOnInit() {
    // Workaround for meta-form using both fields and sections
    this.formGroupChange.emit(this.formGroup);
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} change
   * @memberof FieldsMetaFormComponent
   */
  public ngOnChanges(change: SimpleChanges) {
    // Changes should not trigger additional events
    // Setting a formGroup's controls and patching its values emits valueChange events
    this.unsubscribe(this.sub);
    if (change.metaForm && change.metaForm.currentValue) {
      this.setFormGroupControls(change.metaForm.currentValue);
      // setFormGroupControls clears form data, this repopulates if there were settings
      this.updateFormValues(this.settings);
    }
    if (change.settings && change.settings.currentValue) {
      this.updateFormValues(change.settings.currentValue);
      if (!change.settings.previousValue) {
        this.formGroupChange.emit(this.formGroup);
      }
    }
    this.subscribeToFormGroup(this.formGroup);
  }

  /**
   * ngOnDestroy
   * @memberof FieldsMetaFormComponent
   */
  public ngOnDestroy() {
    this.unsubscribe(this.sub);
  }

  /**
   * subscribeToFormGroup
   * @param {UntypedFormGroup} formGroup
   * @memberof FieldsMetaFormComponent
   */
  public subscribeToFormGroup(formGroup: UntypedFormGroup) {
    if (formGroup) {
      this.sub = formGroup.valueChanges.pipe(debounceTime(this.debounceTime), distinctUntilChanged(isEqual)).subscribe(() => {
        this.formGroupChange.emit(formGroup);
      });
    }
  }

  /**
   * setFormGroupControls
   * @param {FieldsMetaForm} metaForm
   * @memberof FieldsMetaFormComponent
   */
  public setFormGroupControls(metaForm: FieldsMetaForm) {
    this.setFilterFormFields(metaForm.fields);
    this.resetFormGroupControls(this.formGroup);
    this.filteredFormFields.forEach((field: MetaFormField) => this.setFormControl(field));
  }

  /**
   * resetFormGroupControls
   * @param {UntypedFormGroup} formGroup
   * @memberof FieldsMetaFormComponent
   */
  public resetFormGroupControls(formGroup: UntypedFormGroup) {
    each(formGroup.controls, (formControl: UntypedFormControl, formControlName: string) => {
      formGroup.removeControl(formControlName);
    });
  }

  /**
   * getValidator
   * @param {MetaFormField} metaFormField
   * @returns {ValidatorFn}
   * @memberof FieldsMetaFormComponent
   */
  public getValidator(metaFormField: MetaFormField): ValidatorFn {
    const validators = [];
    if (metaFormField.required) {
      validators.push(Validators.required);
    }
    switch (metaFormField.presentationType) {
      case MetaFormFieldPresentationType.PHONE:
        validators.push(Validators.pattern(MetaFormFieldValidators.PHONE_NUMBER_REGEX));
        break;
      case MetaFormFieldPresentationType.EMAIL:
        validators.push(Validators.email);
        break;
      default:
        break;
    }
    return Validators.compose(validators);
  }

  /**
   * getFormControl
   * @param {string} fieldName
   * @returns {FormControl}
   * @memberof FieldsMetaFormComponent
   */
  public getFormControl(fieldName: string): FormControl {
    return this.formGroup.get([fieldName]) as FormControl;
  }

  /**
   * getWarningVisible
   * @param {MetaFormField} field
   * @returns {boolean}
   * @memberof FieldsMetaFormComponent
   */
  public getWarningVisible(field: MetaFormField): boolean {
    const formControl = this.getFormControl(field.name);
    if (!formControl) {
      return false;
    }
    return formControl.invalid && (formControl.dirty || formControl.touched);
  }

  /**
   * getWarningMessage
   * @param {MetaFormField} field
   * @returns {string}
   * @memberof FieldsMetaFormComponent
   */
  public getWarningMessage(field: MetaFormField): string {
    const formControl = this.getFormControl(field.name);
    if (!formControl || !formControl.errors) {
      return '';
    }
    const { required, email, pattern } = formControl.errors;
    if (required) {
      return this.i18nService.translate('FORM_VALIDATION.REQUIRED_FIELD');
    }
    if (email) {
      return this.i18nService.translate('FORM_VALIDATION.INVALID_EMAIL');
    }
    if (pattern) {
      switch (pattern.requiredPattern) {
        case MetaFormFieldValidators.PHONE_NUMBER_REGEX.toString():
          return this.i18nService.translate('AUTOMATION_ACTIONS.INVALID_PHONE_NUMBER');
        default:
          return this.i18nService.translate('AUTOMATION_ACTIONS.INVALID_VALUE');
      }
    }
  }

  /**
   * getPresentationTypeDisplay
   * @param {MetaFormField} field
   * @returns {MetaFormFieldPresentationType}
   * @memberof FieldsMetaFormComponent
   */
  public getPresentationTypeDisplay(field: MetaFormField): MetaFormFieldPresentationType {
    if (!field.useTemplate) {
      return field.presentationType;
    }
    const noTemplateTypes = [
      MetaFormFieldPresentationType.LIST,
      MetaFormFieldPresentationType.CHECKBOX,
      MetaFormFieldPresentationType.RADIO,
      MetaFormFieldPresentationType.RICH_TEXT_EDITOR,
      MetaFormFieldPresentationType.EMAIL_MULTISELECT,
    ];
    return noTemplateTypes.includes(field.presentationType)
      ? field.presentationType
      : MetaFormFieldPresentationType.TEXT_AREA_WITH_COLUMN_VARIABLES;
  }

  /**
   * getAssociatedChoiceValue
   * @param {MetaFormField} field
   * @returns {ChoiceValue}
   * @memberof FieldsMetaFormComponent
   */
  public getAssociatedChoiceValue(field: MetaFormField): ChoiceValue {
    const associatedField = this.getMetaFormField(field.name, 'associatedField');
    if (!associatedField) {
      return;
    }
    const control: AbstractControl = this.getFormControl(associatedField.name);
    return associatedField.choiceValues.find((choice: ChoiceValue) => choice.value === control.value);
  }

  /**
   * getFieldInputType
   * @param {MetaFormField} field
   * @returns {string}
   * @memberof FieldsMetaFormComponent
   */
  public getFieldInputType(field: MetaFormField): string {
    const selectedChoice: ChoiceValue = this.getAssociatedChoiceValue(field);
    const presentationType = (selectedChoice && selectedChoice.associatedPresentationType) || field.presentationType;
    return MetaFormConfig.inputTypeByPresentationType[presentationType];
  }

  /**
   * getFieldId
   * @param {string} fieldName
   * @param {number} index
   * @returns {string}
   * @memberof FieldsMetaFormComponent
   */
  public getFieldId(fieldName: string, index: number = 0): string {
    return `${this.id}-${fieldName}${index}`;
  }

  /**
   * isEditPasswordField
   * @param {MetaFormField} field
   * @returns {boolean}
   * @memberof FieldsMetaFormComponent
   */
  public isEditPasswordField(field: MetaFormField): boolean {
    return this.isEditMode && this.getPresentationTypeDisplay(field) === MetaFormFieldPresentationType.PASSWORD;
  }

  /**
   * getFieldPlaceholder
   * @param {MetaFormField} field
   * @returns {string}
   * @memberof FieldsMetaFormComponent
   */
  public getFieldPlaceholder(field: MetaFormField): string {
    const selectedChoice: ChoiceValue = this.getAssociatedChoiceValue(field);
    return (selectedChoice && selectedChoice.associatedPlaceholderValue) || field.placeholderValue || '';
  }

  /**
   * isDisabledField
   * @param {MetaFormField} field
   * @returns {boolean}
   * @memberof FieldsMetaFormComponent
   */
  public isDisabledField(field: MetaFormField): boolean {
    return field.disabled || (this.isEditMode && field.disabledInEditMode);
  }

  /**
   * onChoiceChange
   * @param {MetaFormField} field
   * @param {string} selectedValue
   * @memberof FieldsMetaFormComponent
   */
  public onChoiceChange(field: MetaFormField, selectedValue: string) {
    const hasAssociatedDisplayFields = field.choiceValues.some((choice: ChoiceValue) => {
      return !isEmpty(choice.associatedDisplayFields);
    });
    if (!hasAssociatedDisplayFields) {
      return;
    }
    const fieldsToRemove: Set<string> = new Set();
    const fieldsToAdd: MetaFormField[] = [];
    field.choiceValues.forEach((choice: ChoiceValue) => {
      if (isEmpty(choice.associatedDisplayFields)) {
        return;
      }
      const isMatchingValue = choice.value === selectedValue;
      choice.associatedDisplayFields.forEach((associatedFieldName: string) => {
        if (isMatchingValue) {
          const associatedField: MetaFormField = this.getMetaFormField(associatedFieldName);
          fieldsToAdd.push(associatedField);
        } else {
          fieldsToRemove.add(associatedFieldName);
        }
      });
    });
    // ESC-23349 START: avoid removing+adding a field a single choiceChange
    fieldsToRemove.forEach((fieldName: string) => {
      if (fieldsToAdd.some((associatedField) => associatedField.name === fieldName)) {
        return;
      }
      this.removeFormControl(fieldName);
    });
    fieldsToAdd.forEach((associatedField: MetaFormField) => {
      if (fieldsToRemove.has(associatedField.name)) {
        return;
      }
      this.setFormControl(associatedField);
    });
    // ESC-23349 END: avoid removing+adding a field in a single choiceChange
    this.setFilterFormFields(this.metaForm.fields);
    // Add new FormControls for nested fields if available
    this.filteredFormFields
      .filter((item: MetaFormField) => !this.getFormControl(item.name))
      .forEach((item: MetaFormField) => this.setFormControl(item));
  }

  /**
   * onLookupChoiceClick - emits lookup choice changes
   *
   * @param {MetaFormField} field
   * @memberof FieldsMetaFormComponent
   */
  public onLookupChoiceClick(field: MetaFormField) {
    this.formGroup.get(field.name).reset();
    this.isLookupSelectedForField[field.name] = true;
    this.lookupChoiceChange.emit(new AutomationActionFieldForLookup({ field }));
  }

  /**
   * onCustomValueChoiceClick - sets lookup selection for field to false
   *
   * @param {MetaFormField} field
   * @memberof FieldsMetaFormComponent
   */
  public onCustomValueChoiceClick(field: MetaFormField) {
    this.isLookupSelectedForField[field.name] = false;
  }

  /**
   *  onRequestFieldLookup - emits field lookup request payload
   *
   * @param {AutomationActionFieldLookupRequestPayload} fieldRequest
   * @memberof FieldsMetaFormComponent
   */
  public onRequestFieldLookup(fieldRequest: AutomationActionFieldLookupRequestPayload) {
    this.onRequestLookup.emit(fieldRequest);
  }

  /**
   * setFieldValueFromLookup
   *
   * @param {string} value
   * @param {string} fieldName
   * @memberof FieldsMetaFormComponent
   */
  public setFieldValueFromLookup(value: string, fieldName: string) {
    this.formGroup.get(fieldName).setValue(value);
  }

  /**
   * isCustomValueInputShown
   *
   * @param {MetaFormField} field
   * @returns {boolean}
   * @memberof FieldsMetaFormComponent
   */
  public isCustomValueInputShown(field: MetaFormField): boolean {
    return (
      field.presentationType !== MetaFormFieldPresentationType.HIDDEN && (!field.lookupConfig || !this.isLookupSelectedForField[field.name])
    );
  }

  /**
   * removeFormControl - remove a form control and all associated fields
   *
   * @param {string} fieldName
   * @memberof FieldsMetaFormComponent
   */
  public removeFormControl = (fieldName: string): void => {
    const field: MetaFormField | undefined = find(this.metaForm.fields, (metaFormField: MetaFormField) => metaFormField.name === fieldName);
    if (!this.formGroup.contains(fieldName) || !field) {
      return;
    }
    this.formGroup.removeControl(fieldName);
    if (!field.choiceValues) {
      return;
    }
    const allAssociatedFields = new Set(
      field.choiceValues.reduce((accum, choiceValue) => {
        if (choiceValue.associatedDisplayFields) {
          accum.push(...choiceValue.associatedDisplayFields);
        }
        return accum;
      }, []),
    );
    allAssociatedFields.forEach(this.removeFormControl);
  };

  /**
   * sets the form field's values when the user selects a value from the typeahead
   * @param {MetaFormField} field
   * @param {ChoiceValue|undefined} event
   * @memberof FieldsMetaFormComponent
   */
  public setTypeaheadControlValue(field: MetaFormField, event: ChoiceValue | undefined) {
    const value: string | undefined = get(event, 'value');
    if (!value) {
      return;
    }
    const control: AbstractControl = this.getFormControl(field.name);
    const _field: MetaFormField = this.getMetaFormField(field.name);
    if (!_field || !_field.choiceValues || !_field.choiceValues.length || control.value === value) {
      return;
    }

    control.patchValue(value, {
      onlySelf: true,
      emitEvent: false,
    });

    this.onChoiceChange(_field, value);

    this.formGroup.patchValue(Object.assign({}, this.settings, { [_field.name]: value }));
  }

  /**
   * formatter for type ahead
   * @param {*} result
   * @returns {string}
   * @memberof FieldsMetaFormComponent
   */
  public formatter(result: any): string {
    return result.label || '';
  }

  /**
   * Iterates over field.choiceValues and returns an object matching the defaultValue.
   * Used in typeahead to select the default choice from the list
   * @param {MetaFormField} field
   * @returns {object}
   * @memberof FieldsMetaFormComponent
   */
  public getSelectedChoice(field: MetaFormField): object {
    // see if there's a value stored in this.settings (edit mode), if not fetch it from default value
    return this.settings && this.settings[field.name]
      ? field.choiceValues.find((choiceValue) => choiceValue.value === this.settings[field.name])
      : field.choiceValues.find((choiceValue) => choiceValue.value === field.defaultValue) || field.choiceValues[0];
  }

  /**
   * showOptionalLabel
   * @param {MetaFormFieldPresentationType} fieldType
   * @returns {boolean}
   * @memberof FieldsMetaFormComponent
   */
  public showOptionalLabel(fieldType: MetaFormFieldPresentationType): boolean {
    return ![
      MetaFormFieldPresentationType.LIST,
      MetaFormFieldPresentationType.CHECKBOX,
      MetaFormFieldPresentationType.RADIO,
      MetaFormFieldPresentationType.RICH_TEXT_EDITOR,
    ].includes(fieldType);
  }

  /**
   * onEditorCreation
   * @param {Quill} event
   * @memberof FieldsMetaFormComponent
   */
  public onEditorCreation(event: Quill) {
    this.editorInstance = event;
  }

  /**
   * onLookupVariableSelect
   * @param {LookupVariable} lookupVariable
   * @memberof FieldsMetaFormComponent
   */
  public onLookupVariableSelect(lookupVariable: LookupVariable) {
    if (!this.editorInstance) {
      return;
    }
    const range = this.editorInstance.getSelection(true);
    this.editorInstance.insertText(range.index, lookupVariable.name, Quill.sources.USER);
    this.editorInstance.setSelection(range.index + lookupVariable.name.length, Quill.sources.SILENT);
  }

  /**
   * onSelectedUsersChange
   * @param {UserAdminAccount[]} users
   * @param {string} fieldName
   * @memberof FieldsMetaFormComponent
   */
  public onSelectedUsersChange(users: UserAdminAccount[], fieldName: string) {
    this.formGroup.patchValue({
      [fieldName]: users.map((user: UserAdminAccount) => user.email),
    });
  }

  /**
   * getSelectedUsers
   * @param {string} fieldName
   * @returns {UserAdminAccount[]}
   * @memberOf FieldsMetaFormComponent
   */
  public getSelectedUsers(fieldName: string) {
    if (!this.selectedUsersByFieldName[fieldName]) {
      this.selectedUsersByFieldName[fieldName] = [];
    }
    each(this.formGroup.controls[fieldName].value, (email: string) => {
      if (!this.selectedUsersByFieldName[fieldName].find((user: UserAdminAccount) => user.email === email)) {
        this.selectedUsersByFieldName[fieldName].push(
          new UserAdminAccount({
            email,
          }),
        );
      }
    });
    return this.selectedUsersByFieldName[fieldName];
  }

  /**
   * setFormControl
   * @param {MetaFormField} metaFormField
   * @memberof FieldsMetaFormComponent
   */
  public setFormControl(metaFormField: MetaFormField) {
    let value = metaFormField.defaultValue || '';
    if (metaFormField.dataType === DataType[DataType.BOOLEAN]) {
      value = metaFormField.defaultValue || false;
    }
    const formControl = new UntypedFormControl(
      {
        value,
        disabled: this.isDisabledField(metaFormField),
      },
      this.getValidator(metaFormField),
    );
    this.formGroup.setControl(metaFormField.name, formControl);
  }

  /**
   * trackByFn
   * @param {number} index
   * @param {MetaFormField} item
   * @returns {string | number}
   * @memberof FieldsMetaFormComponent
   */
  public trackByFn(index: number, item: MetaFormField): string | number {
    return item.name ?? index;
  }

  /**
   * updateFormValues
   * @param {any} settings
   * @memberof FieldsMetaFormComponent
   */
  private updateFormValues(settings: any) {
    if (!settings) {
      return;
    }
    const currentData = this.formGroup.value;
    const settingsCopy = { ...settings };
    Object.keys(settings).forEach((key: string) => {
      if (settings[key] === currentData[key]) {
        delete settingsCopy[key]; // eslint-disable-line @typescript-eslint/no-dynamic-delete
      } else {
        const control: AbstractControl = this.getFormControl(key);
        if (!control) {
          return;
        }
        const field: MetaFormField = this.getMetaFormField(key);
        if (!field || !field.choiceValues || !field.choiceValues.length) {
          return;
        }
        const value = settings[key];
        control.patchValue(value, {
          onlySelf: true,
          emitEvent: false,
        });

        this.onChoiceChange(field, value);
      }
    });
    // patching only different values, due to Safari bug with cursor jumping
    this.formGroup.patchValue(settingsCopy || {});
  }

  /**
   * getMetaFormField
   * @param {string} fieldName
   * @param {string} propName
   * @returns {MetaFormField}
   * @memberof FieldsMetaFormComponent
   */
  private getMetaFormField(fieldName: string, propName: string = 'name'): MetaFormField {
    return find(this.metaForm.fields, (metaField: MetaFormField) => metaField[propName] === fieldName);
  }

  /**
   * setFilterFormFields
   * @param {MetaFormField[]} fields
   * @memberof FieldsMetaFormComponent
   */
  private setFilterFormFields(fields: MetaFormField[]) {
    this.filteredFormFields = filter(fields, (field: MetaFormField) => {
      return isFieldDisplayed(this.formGroup, this.metaForm, field, true);
    });
  }
}
