/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { cloneDeep } from 'lodash-es';

import { HorizonDashboardConfig } from '@dpa-shared/merlot/model/dashboard/horizon-dashboard.config';
import { filterEmptyRules } from '@ws1c/intelligence-common';
import { getComparisonQueryResponse, getLastCount } from '@ws1c/intelligence-core/store/dashboard/dashboard-selector-helpers';
import {
  COLUMN_NAMES,
  ComparisonQueryResponse,
  FilterRule,
  RuleGroup,
  StandardDashboardRequest,
  StandardDashboardType,
  StandardWidgetSubtype,
  Trend,
  TrendDateRange,
} from '@ws1c/intelligence-models';

/**
 * convertToPodIdFilterRule
 * @export
 * @param {RuleGroup} filterRuleGroup
 * @param {string} attribute
 * @param {string[]} podIds
 * @returns {RuleGroup}
 */
export function convertToPodIdFilterRule(filterRuleGroup: RuleGroup, attribute: string, podIds: string[]): RuleGroup {
  const convertedfilterRuleGroup: RuleGroup = cloneDeep(filterRuleGroup);
  const filterRule = convertedfilterRuleGroup.rules.find((rule: FilterRule) => {
    return rule.attribute === COLUMN_NAMES.byFullyQualifiedName.horizon_pod_pod_id;
  }) as FilterRule;
  if (filterRule) {
    filterRule.attribute = attribute;
    if (podIds?.length) {
      filterRule.condition = FilterRule.FILTER_CONDITION.includes;
      filterRule.data = podIds;
    }
  }
  if (!podIds || !podIds.length) {
    filterEmptyRules(convertedfilterRuleGroup);
  }
  return convertedfilterRuleGroup;
}

/**
 * convertToPoolIdFilterRule
 * @export
 * @param {RuleGroup} filterRuleGroup
 * @param {string} attribute
 * @returns {RuleGroup}
 */
export function convertToPoolIdFilterRule(filterRuleGroup: RuleGroup, attribute: string): RuleGroup {
  const convertedfilterRuleGroup: RuleGroup = cloneDeep(filterRuleGroup);
  if (attribute === COLUMN_NAMES.byFullyQualifiedName.horizon_session_historical_pod_id) {
    convertedfilterRuleGroup.rules.forEach((rule: FilterRule) => {
      if (rule.attribute === COLUMN_NAMES.byFullyQualifiedName.horizon_pool_pool_id) {
        rule.attribute = COLUMN_NAMES.byFullyQualifiedName.horizon_session_historical_pool_id;
      }
    });
  }
  return convertedfilterRuleGroup;
}

/**
 * convertHorizonTitanFilterRule
 * @export
 * @param {RuleGroup} filterRuleGroup
 * @returns {RuleGroup}
 */
export function convertHorizonTitanFilterRule(filterRuleGroup: RuleGroup): RuleGroup {
  const convertedfilterRuleGroup: RuleGroup = cloneDeep(filterRuleGroup);
  convertedfilterRuleGroup.rules.forEach((rule: FilterRule) => {
    if (
      rule.attribute === COLUMN_NAMES.byFullyQualifiedName.horizon_edge_titan_edge_id ||
      rule.attribute === COLUMN_NAMES.byFullyQualifiedName.horizon_template_titan_edge_id
    ) {
      rule.attribute = COLUMN_NAMES.byFullyQualifiedName.horizon_session_titan_edge_id;
    }
    if (rule.attribute === COLUMN_NAMES.byFullyQualifiedName.horizon_template_titan_template_id) {
      rule.attribute = COLUMN_NAMES.byFullyQualifiedName.horizon_session_titan_template_id;
    }
  });
  return convertedfilterRuleGroup;
}

/**
 * convertHorizonFilterRule
 * @export
 * @param {RuleGroup} filterRuleGroup
 * @param {string} attribute
 * @param {string[]} podIds
 * @returns {RuleGroup}
 */
export function convertHorizonFilterRule(filterRuleGroup: RuleGroup, attribute: string, podIds: string[]): RuleGroup {
  if (attribute === COLUMN_NAMES.byFullyQualifiedName.horizon_edge_titan_edge_id) {
    return convertHorizonTitanFilterRule(filterRuleGroup);
  } else {
    const podEventFilterRuleGroup = convertToPodIdFilterRule(filterRuleGroup, attribute, podIds);
    return convertToPoolIdFilterRule(podEventFilterRuleGroup, attribute);
  }
}

/**
 * buildHorizonDashboardRequest
 * @export
 * @param {StandardDashboardType} dashboardType
 * @param {RuleGroup} filterRuleGroup
 * @param {TrendDateRange} trendDateRange
 * @param {string[]} podIds
 * @returns {StandardDashboardRequest}
 */
export function buildHorizonDashboardRequest(
  dashboardType: StandardDashboardType,
  filterRuleGroup: RuleGroup,
  trendDateRange: TrendDateRange,
  podIds: string[],
): StandardDashboardRequest {
  filterEmptyRules(filterRuleGroup);
  switch (dashboardType) {
    case StandardDashboardType.HORIZON_TREE_MAP:
    case StandardDashboardType.HORIZON_TITAN_TREE_MAP:
      return new StandardDashboardRequest(dashboardType, new RuleGroup());
    case StandardDashboardType.HORIZON_TITAN_USERS:
    case StandardDashboardType.HORIZON_TITAN_SUMMARY:
      return new StandardDashboardRequest(dashboardType, filterRuleGroup);
    case StandardDashboardType.HORIZON_TITAN_UTILIZATION:
      return new StandardDashboardRequest(dashboardType, new RuleGroup(), undefined, {
        edge_ids: `'${(podIds || []).join("','")}'`,
      });
    case StandardDashboardType.HORIZON_UTILIZATION_AZURE_HISTORICAL:
    case StandardDashboardType.HORIZON_UTILIZATION_VMWARE_SDDC_HISTORICAL:
      return new StandardDashboardRequest(dashboardType, new RuleGroup(), trendDateRange, {
        pod_ids: `'${(podIds || []).join("','")}'`,
      });
    default:
      const attribute =
        HorizonDashboardConfig.horizonDashboardTypeToPodIdColumn[dashboardType] || COLUMN_NAMES.byFullyQualifiedName.horizon_pod_pod_id;
      const horizonFilterRuleGroup = convertHorizonFilterRule(filterRuleGroup, attribute, podIds);
      return new StandardDashboardRequest(dashboardType, horizonFilterRuleGroup, trendDateRange);
  }
}

/**
 * getCounterDataFromSessions
 * @export
 * @param {Map<string, Trend>} standardDashboardData
 * @param {StandardWidgetSubtype} subTypeForCounter
 * @param {StandardWidgetSubtype} subTypeForDetail
 * @returns {ComparisonQueryResponse}
 */
export function getCounterDataFromSessions(
  standardDashboardData: Map<string, Trend>,
  subTypeForCounter: StandardWidgetSubtype,
  subTypeForDetail: StandardWidgetSubtype,
): ComparisonQueryResponse {
  const trendForCounter: Trend = standardDashboardData.get(StandardWidgetSubtype[subTypeForCounter]);
  const lastCount = getLastCount(trendForCounter);
  const trendForDetail: Trend = standardDashboardData.get(StandardWidgetSubtype[subTypeForDetail]);
  return Object.assign(getComparisonQueryResponse(0, lastCount), {
    trend: trendForDetail,
    subType: subTypeForDetail,
  });
}
