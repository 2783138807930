/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { createAction, props } from '@ngrx/store';

import { QueryBuilder, ReportTemplate } from '@ws1c/intelligence-models';

/**
 * DataExplorerActions
 * @export
 * @class DataExplorerActions
 */
@Injectable()
export class DataExplorerActions {
  public static readonly CATEGORY = '[DATA_EXPLORER]';
  private static type = (actionName: string): string => `${DataExplorerActions.CATEGORY} ${actionName}`;

  public static setPreviewColumns = createAction(DataExplorerActions.type('SET_PREVIEW_COLUMNS'), props<{ columns: string[] }>());
  public static setCustomQuery = createAction(DataExplorerActions.type('SET_CUSTOM_QUERY'), props<{ query: QueryBuilder }>());
  public static setPreviewQuery = createAction(DataExplorerActions.type('SET_PREVIEW_QUERY'), props<{ query: QueryBuilder }>());
  public static setCategory = createAction(
    DataExplorerActions.type('SET_CATEGORY'),
    props<{ categoryId: string; template?: ReportTemplate }>(),
  );
  public static toggleAddReportModal = createAction(DataExplorerActions.type('TOGGLE_ADD_REPORT_MODAL'));
  public static setSelectDataSourceVisible = createAction(
    DataExplorerActions.type('SET_SELECT_DATA_SOURCE_VISIBLE'),
    props<{ isVisible: boolean }>(),
  );
}
