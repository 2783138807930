/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

/**
 * Edit locked credentials via pencil and undo icons
 * @export
 * @class CredentialInputComponent
 */
@Component({
  selector: 'dpa-credential-input',
  templateUrl: 'credential-input.component.html',
  styleUrls: ['credential-input.component.scss'],
})
export class CredentialInputComponent implements OnInit {
  @Input() public control: FormControl;
  @Input() public editable?: boolean = true;
  @ViewChild('passwordField', { static: true }) public passwordField: ElementRef;
  public isDisabled: boolean = false;
  private isUndoClicked: boolean = false;
  private lastLockedPassword: string = '';

  /**
   * ngOnInit
   * @memberof CredentialInputComponent
   */
  public ngOnInit() {
    this.togglePasswordField(true);
  }

  /**
   * editLockedPassword
   * @param {any} $event
   * @memberof CredentialInputComponent
   */
  public editLockedPassword($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    // Save the last-entered password so it can be restored if needed
    this.lastLockedPassword = this.control.value;
    this.control.patchValue('');
    this.togglePasswordField(false);
    this.passwordField?.nativeElement.focus();
  }

  /**
   * onUndoMouseDown
   * @memberof CredentialInputComponent
   */
  public onUndoMouseDown() {
    // Must use the mousedown event to flag this before the blur event occurs
    this.isUndoClicked = true;
  }

  /**
   * passwordLockAfterBlur
   * @memberof CredentialInputComponent
   */
  public passwordLockAfterBlur() {
    if (this.isUndoClicked || !this.control.value) {
      // Reset the password if the undo icon was clicked or if the field was left blank
      this.control.patchValue(this.lastLockedPassword);
    }
    this.togglePasswordField(true);
    this.isUndoClicked = false;
  }

  /**
   * togglePasswordField
   * @param {boolean} isDisabled
   * @memberof CredentialInputComponent
   */
  private togglePasswordField(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
