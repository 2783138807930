<clr-alert
  *ngIf="(irrecoverableActions$ | async)?.length"
  [clrAlertType]="'warning'"
  [clrAlertClosable]="false"
>
  <clr-alert-item class="alert-item">
    <span class="alert-text">
      {{ 'WORKFLOWS.RECOVERABLE_MESSAGE' | translate: { count: (irrecoverableActions$ | async)?.length } }}
    </span>
  </clr-alert-item>
</clr-alert>

<div class="actions-list">
  <div *ngFor="let action of irrecoverableActions$ | async; let actionIndex = index">
    <div
      (click)="toggleDesc(actionIndex)"
      (keyup.enter)="toggleDesc(actionIndex)"
      class="action-label"
    >
      <cds-icon
        [attr.direction]="toggleDescStatus[actionIndex] ? 'down' : 'right'"
        shape="angle"
      ></cds-icon>
      {{ action.name }}
    </div>
    <div
      *ngIf="toggleDescStatus[actionIndex]"
      class="action-unrecoverable-message"
    >
      {{ action.metadata.unrecoverableMessage }}
    </div>
  </div>
</div>
