/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { createAction, props } from '@ngrx/store';

import { TrendDateRange } from '@ws1c/intelligence-models';

/**
 * WorkspaceOneMtdDashboardActions
 * @export
 * @class WorkspaceOneMtdDashboardActions
 */
export class WorkspaceOneMtdDashboardActions {
  public static readonly CATEGORY = '[DASHBOARD][WORKSPACE_ONE_MTD]';

  private static type = (actionName: string): string => `${WorkspaceOneMtdDashboardActions.CATEGORY} ${actionName}`;

  public static loadDashboard = createAction(WorkspaceOneMtdDashboardActions.type('LOAD_WORKSPACE_ONE_MTD_DASHBOARD'));

  public static setTrendDateRange = createAction(
    WorkspaceOneMtdDashboardActions.type('SET_WORKSPACE_ONE_MTD_TREND_DATE_RANGE'),
    props<{ trendDateRange: TrendDateRange }>(),
  );
}
