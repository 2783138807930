/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { QueryBuilder, ReportTemplate } from '@ws1c/intelligence-models';

/**
 * Defines UI State for Data Explorer
 *
 * @export
 * @interface DataExplorerState
 */
export interface DataExplorerState {
  customQuery: QueryBuilder;
  previewQuery: QueryBuilder;
  previewColumns: string[];
  showAddReportModal: boolean;
  isSelectDataSourceVisible: boolean;
  selectedTemplate: ReportTemplate;
}

export const initialDataExplorerState: DataExplorerState = {
  customQuery: undefined,
  previewQuery: undefined,
  previewColumns: [],
  showAddReportModal: false,
  isSelectDataSourceVisible: false,
  selectedTemplate: undefined,
};
