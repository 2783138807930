/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { createAction, props } from '@ngrx/store';

import { CustomReport, ReportTrackingSearchResponse, ShareData } from '@ws1c/intelligence-models';

/**
 * NonAdminActions
 * @export
 * @class NonAdminActions
 */
export class NonAdminActions {
  public static readonly CATEGORY = '[NON_ADMIN]';

  public static getReportShareList = createAction(`${NonAdminActions.CATEGORY} GET_REPORT_SHARE_LIST`, props<{ shareData: ShareData }>());

  public static getReportShareListSuccess = createAction(
    `${NonAdminActions.CATEGORY} GET_REPORT_SHARE_LIST_SUCCESS`,
    props<{ reportShareList: ReportTrackingSearchResponse }>(),
  );

  public static getReportShareListFailure = createAction(`${NonAdminActions.CATEGORY} GET_REPORT_SHARE_LIST_FAILURE`);

  public static getReportShareDetails = createAction(
    `${NonAdminActions.CATEGORY} GET_REPORT_SHARE_DETAILS`,
    props<{ shareData: ShareData }>(),
  );

  public static getReportShareDetailsSuccess = createAction(
    `${NonAdminActions.CATEGORY} GET_REPORT_SHARE_DETAILS_SUCCESS`,
    props<{ reportDetails: CustomReport }>(),
  );

  public static getReportShareDetailsFailure = createAction(`${NonAdminActions.CATEGORY} GET_REPORT_DETAILS_FAILURE`);
}
