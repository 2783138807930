<div class="card tree-map-card">
  <div
    [ngClass]="{ 'tree-map-with-list': showSplitListView }"
    class="tree-map-view"
  >
    <div class="ph-x5 pt-2">
      <dpa-flexible-drilldown-navigator
        [drilldownList]="drilldownList$ | async"
        (onNavigateTo)="navigateTo($event)"
      ></dpa-flexible-drilldown-navigator>
    </div>
    <div class="tree-map-container">
      <dpa-standard-chart
        [skipSort]="skipSort"
        [showDetailsLink]="true"
        [chartData]="treeMapData$ | async"
        [loading]="isLoading$ | async"
        [activeChartType]="CHART_TYPE.TREE_MAP"
        [labelFormatting]="labelFormatting"
        [valueFormatting]="valueFormatting"
        [showSameSizeCell]="showSameSizeCell"
        (drilldown)="pushDrilldownEvent($event)"
      ></dpa-standard-chart>
    </div>
  </div>
  <div
    *ngIf="showSplitListView"
    class="tree-map-list-view"
  >
    <h4 *ngIf="listTitle">
      {{ listTitle | translate }}
    </h4>
    <dpa-split-view-list
      *ngIf="listData$ | async as listData"
      [data]="listData?.trendResults"
      [total]="listData?.trendResults?.length"
      [itemSize]="listItemSize"
      [dataItemTemplate]="dataItemTemplate"
      [noItemsToShowTemplate]="noItemsToShowTemplate"
      [searchIconClass]="'filter'"
      [searchPlaceholderText]="searchPlaceholderText | translate"
      [endOfListMessage]="'COMMON_MESSAGES.SPLIT_VIEW_END_OF_LIST_MESSAGE' | translate"
      (searchItem)="searchItem($event)"
    ></dpa-split-view-list>

    <ng-template
      #dataItemTemplate
      let-item="item"
    >
      <ng-template
        *ngIf="customListItemTemplte; defaultDataItemTemplate"
        [ngTemplateOutlet]="customListItemTemplte"
        [ngTemplateOutletContext]="{ item: item }"
      ></ng-template>
    </ng-template>
    <ng-template
      #defaultDataItemTemplate
      let-item="item"
    >
      <dpa-ellipsis-with-tooltip class="title">
        <a
          (click)="clickListItem(item)"
          (keyup.enter)="clickListItem(item)"
          class="clicklable link-normal"
        >
          {{ item.bucketingAttributes[0].value }}
        </a>
      </dpa-ellipsis-with-tooltip>
    </ng-template>

    <ng-template #noItemsToShowTemplate>
      <dpa-no-search-results
        [message]="'COMMON_MESSAGES.NO_ITEMS_FOUND' | translate"
        class="mt-x6"
      ></dpa-no-search-results>
    </ng-template>
  </div>
</div>
