/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { EventForwarderTemplate } from '@dpa-shared-merlot/model';
import { FeatureControl } from '@ws1c/intelligence-common';
import {
  AvailableEventForwarder,
  AvailablePartner,
  AvailableService,
  ConnectionModalMode,
  EventForwarderType,
  FeatureControls,
  OAuthPartnerType,
  ObjectById,
  TrustNetworkPartnerType,
} from '@ws1c/intelligence-models';

/**
 * isModalMode
 * Returns a function that will return true if the supplied modal mode matches
 * @param {ConnectionModalMode[]} expectedModes
 * @export
 * @returns {(modalMode: ConnectionModalMode) => boolean}
 */
export function isModalMode(...expectedModes: ConnectionModalMode[]): (modalMode: ConnectionModalMode) => boolean {
  return (modalMode: ConnectionModalMode) => expectedModes.includes(modalMode);
}

/**
 * getDefaultAvailableTrustNetworkPartners
 * Takes in list of available trust network partners and feature flags
 * and concat all the default partners if they are not present in the list,
 * while filtering out with feature flags.
 * Note: for trust network partners `name` is the unique id.
 *
 * @export
 * @param {AvailablePartner[]} availablePartners
 * @param {FeatureControls} featureControls
 * @returns {AvailablePartner[]}
 */
export function getDefaultAvailableTrustNetworkPartners(
  availablePartners: AvailablePartner[],
  featureControls: FeatureControls,
): AvailablePartner[] {
  return Object.values(TrustNetworkPartnerType)
    .filter((defaultPartner: TrustNetworkPartnerType) => {
      // Trust Network Partners feature flags are: WANDERA_ENABLED, ZIMPERIUM_ENABLED, CHECKPOINT_ENABLED
      const featureFlag: string = FeatureControl[`${defaultPartner}_ENABLED`];
      return featureControls[featureFlag];
    })
    .map((defaultPartner: TrustNetworkPartnerType) => {
      return (
        availablePartners.find((availablePartner: AvailablePartner) => availablePartner.name === defaultPartner) ||
        new AvailablePartner({
          name: defaultPartner,
        })
      );
    });
}

/**
 * getDefaultAvailableOAuthPartners
 * @export
 * @param {AvailablePartner[]} availablePartners
 * @param {FeatureControls} featureControls
 * @returns {AvailablePartner[]}
 */
export function getDefaultAvailableOAuthPartners(
  availablePartners: AvailablePartner[],
  featureControls: FeatureControls,
): AvailablePartner[] {
  return Object.values(OAuthPartnerType)
    .filter((defaultPartner: OAuthPartnerType) => {
      // OAuth Partners feature flags are: ZOOM_ENABLED
      const featureFlag: string = FeatureControl[`${defaultPartner}_ENABLED`];
      return featureControls[featureFlag];
    })
    .map((defaultPartner: OAuthPartnerType) => {
      return (
        availablePartners.find((availablePartner: AvailablePartner) => availablePartner.name === defaultPartner) ||
        new AvailablePartner({
          name: defaultPartner,
        })
      );
    });
}

/**
 * filterTrustNetworkServices
 * Takes in list of existing trust network services and filters them based on new
 * Event Forwarder services based on feature flags
 *
 * @export
 * @param {AvailableService[]} trustNetworkServices
 * @param {FeatureControls} featureControls
 * @param {ObjectById<EventForwarderTemplate>} eventForwardersTemplatesByName
 * @returns {AvailableService[]}
 */
export function filterTrustNetworkServices(
  trustNetworkServices: AvailableService[],
  featureControls: FeatureControls,
  eventForwardersTemplatesByName: ObjectById<EventForwarderTemplate>,
): AvailableService[] {
  if (!eventForwardersTemplatesByName) {
    return trustNetworkServices;
  }
  Object.values(EventForwarderType)
    .filter((defaultEventForwarder: EventForwarderType) => {
      // Trust Network Partners Event Forwarders feature flags are: CARBONBLACK_ENABLED
      const featureFlag: string = FeatureControl[`${defaultEventForwarder}_ENABLED`];
      const featureEnabled: boolean = featureControls[featureFlag];

      // Special check (made generic) for upgrade flow for carbon black integration as in this case
      // even if event.forwarder.enabled is true, we need to show old card with upgrade option
      // Setting upgrade flag of model as true also here
      const upgradeFlag: string = FeatureControl[`${defaultEventForwarder}_UPGRADE_REQUIRED`];
      const upgradeRequired: boolean = upgradeFlag ? featureControls[upgradeFlag] : false;
      if (upgradeRequired) {
        trustNetworkServices.forEach((service: AvailableService) => {
          if (service.key === defaultEventForwarder) {
            service.upgradeRequired = true;
          }
        });
      }

      // Filter based on feature and upgrade
      return featureEnabled && !upgradeRequired;
    })
    .forEach((defaultEventForwarder: EventForwarderType) => {
      // Remove from the existing service if present
      trustNetworkServices = trustNetworkServices.filter((service: AvailableService) => service.key !== defaultEventForwarder);
    });
  return trustNetworkServices;
}

/**
 * getDefaultAvailableEventForwarders
 * Takes in list of available event forwarders and feature flags and
 * concat all the default event forwarders if they are not present in
 * the list, while filtering out with feature flags.
 * Note: for event forwarders `name` is the unique id.
 *
 * @export
 * @param {AvailableEventForwarder[]} eventForwarders
 * @param {FeatureControls} featureControls
 * @returns {AvailableEventForwarder[]}
 */
export function getDefaultAvailableEventForwarders(
  eventForwarders: AvailableEventForwarder[],
  featureControls: FeatureControls,
): AvailableEventForwarder[] {
  return Object.values(EventForwarderType)
    .filter((defaultEventForwarder: EventForwarderType) => {
      // Trust Network Partners Event Forwarders feature flags are: CARBONBLACK_ENABLED
      const featureFlag: string = FeatureControl[`${defaultEventForwarder}_ENABLED`];
      const featureEnabled: boolean = featureControls[featureFlag];

      // Special check (made generic) for upgrade flow for carbon black integration as in this case
      // even if event.forwarder.enabled is true, we need to show old card for upgrade option
      const upgradeFlag: string = FeatureControl[`${defaultEventForwarder}_UPGRADE_REQUIRED`];
      const upgradeRequired: boolean = upgradeFlag ? featureControls[upgradeFlag] : false;

      // Filter based on feature and upgrade
      return featureEnabled && !upgradeRequired;
    })
    .map((defaultEventForwarder: EventForwarderType) => {
      return (
        eventForwarders.find((eventForwarder: AvailableEventForwarder) => eventForwarder.name === defaultEventForwarder) ||
        new AvailableEventForwarder({
          name: defaultEventForwarder,
        })
      );
    });
}
