/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { AppConfig } from '@ws1c/intelligence-common';
import { MarketplaceResource, MarketplaceResourceLabelType } from '@ws1c/intelligence-models';

/**
 * MarketplaceResourcePreviewHeaderComponent
 * @export
 * @class MarketplaceResourcePreviewHeaderComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-marketplace-resource-preview-header',
  templateUrl: 'marketplace-resource-preview-header.component.html',
  styleUrls: ['marketplace-resource-preview-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketplaceResourcePreviewHeaderComponent implements OnChanges {
  @Input() public buttonDisabledTooltipText: string;
  @Input() public buttonText: string;
  @Input() public isButtonDisabled: boolean;
  @Input() public resource: MarketplaceResource;
  @Output() public selectResource: EventEmitter<MarketplaceResource> = new EventEmitter<MarketplaceResource>();

  public modifiedAtLabel: string;

  public readonly resourceTagBgColorClass: string = AppConfig.MARKETPLACE_RESOURCE_TAG_BG_COLOR_CLASS;
  public readonly MarketplaceResourceLabelType = MarketplaceResourceLabelType;

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes - Holds map of changed input property to value
   * @memberof MarketplaceResourcePreviewHeaderComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.resource) {
      this.modifiedAtLabel =
        this.resource?.customTagType === MarketplaceResourceLabelType.NEW ? 'MARKETPLACE.ADDED_WHEN' : 'MARKETPLACE.UPDATED_WHEN';
    }
  }
}
