<div class="card">
  <div class="card-block">
    <dpa-standard-chart
      [customColors]="customColors"
      [ngClass]="size"
      [loading]="!trend?.trendDefinition"
      [chartData]="trend"
      [activeChartType]="type"
      [showSeriesNames]="showSeriesNames"
      [showLegend]="false"
      [showXAxisLabel]="showXAxisLabel"
      [showAllAvailableLabels]="true"
      [xAxisLabelOverride]="xAxisLabelOverride"
      [yAxisLabelOverride]="yAxisLabelOverride"
      [yScaleMax]="yScaleMax"
      [isPercentageOverride]="isPercentageOverride"
      class="standard-chart"
    ></dpa-standard-chart>
    <dpa-tooltip
      *ngIf="isUserBookmarksEnabled"
      [tooltipText]="(hasBookmark ? 'BOOKMARKS.REMOVE_FROM_BOOKMARKS' : 'BOOKMARKS.ADD_TO_BOOKMARKS') | translate"
      [attr.aria-label]="(hasBookmark ? 'BOOKMARKS.REMOVE_FROM_BOOKMARKS' : 'BOOKMARKS.ADD_TO_BOOKMARKS') | translate"
      [focusEnabled]="true"
      (click)="onBookmarkClick()"
      (keyup.enter)="onBookmarkClick()"
      class="bookmark-wrapper"
      role="tooltip"
    >
      <cds-icon
        [attr.solid]="hasBookmark"
        shape="bookmark"
        size="20"
      ></cds-icon>
    </dpa-tooltip>
  </div>
  <div class="card-block card-title mb-0">
    {{ title }}
  </div>
  <div class="card-footer">
    <button
      [attr.aria-label]="'DASHBOARD_ACTIONS.VIEW_DASHBOARD_WIDGET_ARIA_LABEL' | translate: { name: title }"
      [routerLink]="[link]"
      [queryParams]="queryParams"
      class="btn btn-sm btn-link"
    >
      {{ 'COMMON_ACTIONS.VIEW_DASHBOARD' | translate }}
    </button>
  </div>
</div>
