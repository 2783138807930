/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ValidatorFn, Validators } from '@angular/forms';

import { MetaFormFieldPresentationType, MetaFormFieldValidators } from '@ws1c/intelligence-models';

/**
 * MetaFormUtils
 * @export
 */
export class MetaFormUtils {
  /**
   * getPresentationTypeDisplay
   *
   * @param {boolean} templateAllowed
   * @param {MetaFormFieldPresentationType} presentationType
   * @returns {MetaFormFieldPresentationType}
   * @memberof MetaFormUtils
   */
  public static getPresentationTypeDisplay(
    templateAllowed: boolean,
    presentationType: MetaFormFieldPresentationType,
  ): MetaFormFieldPresentationType {
    if (!templateAllowed) {
      return presentationType;
    }
    const noTemplateTypes = [
      MetaFormFieldPresentationType.LIST,
      MetaFormFieldPresentationType.CHECKBOX,
      MetaFormFieldPresentationType.RADIO,
      MetaFormFieldPresentationType.RICH_TEXT_EDITOR,
      MetaFormFieldPresentationType.EMAIL_MULTISELECT,
      MetaFormFieldPresentationType.HIDDEN,
    ];
    return noTemplateTypes.includes(presentationType) ? presentationType : MetaFormFieldPresentationType.TEXT_AREA_WITH_COLUMN_VARIABLES;
  }

  /**
   * getValidator
   *
   * @param {MetaFormFieldPresentationType} presentationType
   * @returns {ValidatorFn}
   * @memberof MetaFormUtils
   */
  public static getValidator(presentationType: MetaFormFieldPresentationType): ValidatorFn {
    const validators = [];
    switch (presentationType) {
      case MetaFormFieldPresentationType.PHONE:
        validators.push(Validators.pattern(MetaFormFieldValidators.PHONE_NUMBER_REGEX));
        break;
      case MetaFormFieldPresentationType.EMAIL:
        validators.push(Validators.email);
        break;
      default:
        break;
    }
    return Validators.compose(validators);
  }
}
