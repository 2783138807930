/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { RiskyUserDeviceSummaryLocator } from '@ws1c/intelligence-models/dashboard';
import { UserRiskDashboardActions } from './user-risk-dashboard.actions';
import { initialUserRiskDashboardState, UserRiskDashboardState } from './user-risk-dashboard.state';

const _reducer: ActionReducer<UserRiskDashboardState> = createReducer(
  initialUserRiskDashboardState,

  on(
    UserRiskDashboardActions.setDashboardFilters,
    (state: UserRiskDashboardState, props: ReturnType<typeof UserRiskDashboardActions.setDashboardFilters>): UserRiskDashboardState => ({
      ...state,
      ruleGroup: props.ruleGroup,
      trendDateRange: props.trendDateRange,
    }),
  ),

  on(
    UserRiskDashboardActions.hideBanner,
    (state: UserRiskDashboardState): UserRiskDashboardState => ({
      ...state,
      hideBanner: true,
    }),
  ),

  on(
    UserRiskDashboardActions.loadRiskyUserDeviceSummarySuccess,
    UserRiskDashboardActions.loadRiskyUserDeviceSummaryFailure,
    (state: UserRiskDashboardState, props: RiskyUserDeviceSummaryLocator): UserRiskDashboardState => ({
      ...state,
      riskyUserByTimestampByEmail: {
        ...state.riskyUserByTimestampByEmail,
        [props.email]: {
          ...state.riskyUserByTimestampByEmail[props.email],
          [props.timestamp]: props.results,
        },
      },
    }),
  ),
);

/**
 * userRiskDashboardState
 * @param {UserRiskDashboardState} state
 * @param {Action} action
 * @returns {UserRiskDashboardState}
 */
export function userRiskDashboardState(state: UserRiskDashboardState, action: Action): UserRiskDashboardState {
  return _reducer(state, action);
}
