/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ConnectorCardComponent } from './connector-card/connector-card.component';
import { ConnectorGridComponent } from './connector-grid/connector-grid.component';
import { ConnectorAddModalComponent } from './connector-modals/connector-add-modal/connector-add-modal.component';
import { ConnectorAuthorizeModalComponent } from './connector-modals/connector-authorize-modal/connector-authorize-modal.component';
import { ConnectorDeleteModalComponent } from './connector-modals/connector-delete-modal/connector-delete-modal.component';
import { ConnectorEditModalComponent } from './connector-modals/connector-edit-modal/connector-edit-modal.component';
import { ConnectorModalsComponent } from './connector-modals/connector-modals.component';
import { ConnectorRenameModalComponent } from './connector-modals/connector-rename-modal/connector-rename-modal.component';
import { ConnectorSetupCustomConnectorComponent } from './connector-modals/connector-setup-custom-connector/connector-setup-custom-connector.component';

export const CONNECTOR_COMPONENTS: any[] = [
  ConnectorAddModalComponent,
  ConnectorAuthorizeModalComponent,
  ConnectorCardComponent,
  ConnectorSetupCustomConnectorComponent,
  ConnectorDeleteModalComponent,
  ConnectorEditModalComponent,
  ConnectorGridComponent,
  ConnectorModalsComponent,
  ConnectorRenameModalComponent,
];
