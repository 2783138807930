/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { createAction, props } from '@ngrx/store';

import { DateRangeFilters } from '@ws1c/intelligence-models';

/**
 * LoginRiskDashboardActions
 * @export
 * @class LoginRiskDashboardActions
 */
export class LoginRiskDashboardActions {
  public static readonly CATEGORY = '[DASHBOARD][LOGIN_RISK]';

  public static loadDashboard = createAction(`${LoginRiskDashboardActions.CATEGORY} LOAD_DASHBOARD`);

  public static hideBanner = createAction(`${LoginRiskDashboardActions.CATEGORY} HIDE_DASHBOARD_BANNER`);

  public static goToIDMSetup = createAction(`${LoginRiskDashboardActions.CATEGORY} GO_TO_IDM_SETUP`);

  public static setDashboardFilters = createAction(`${LoginRiskDashboardActions.CATEGORY} SET_FILTERS`, props<DateRangeFilters>());
}
