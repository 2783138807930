<form [formGroup]="formGroup">
  <div
    *ngIf="!listSelection; else listSelectionTmpl"
    [class.input-container]="rangeSelection"
  >
    <div [class.clr-error]="formGroup.get('ipStartValue')?.dirty && formGroup.get('ipStartValue')?.invalid">
      <input
        [placeholder]="'COMMON_ACTIONS.ENTER_VALUE' | translate"
        formControlName="ipStartValue"
        type="text"
        class="clr-input standard-input"
        maxlength="15"
      />
      <span
        *ngIf="formGroup.dirty && formGroup.get('ipStartValue')?.hasError('pattern')"
        class="clr-subtext"
      >
        {{ 'ERROR_MESSAGES.INVALID_IP_ADDRESS' | translate }}
      </span>
    </div>
    <ng-container *ngIf="rangeSelection">
      <span class="p2">&amp;</span>
      <div [class.clr-error]="formGroup.get('ipEndValue')?.dirty && formGroup.get('ipEndValue')?.invalid">
        <input
          [placeholder]="'COMMON_ACTIONS.ENTER_VALUE' | translate"
          formControlName="ipEndValue"
          type="text"
          class="clr-input standard-input"
          maxlength="15"
        />
        <span
          *ngIf="formGroup.dirty && formGroup.get('ipEndValue')?.hasError('pattern')"
          class="clr-subtext"
        >
          {{ 'ERROR_MESSAGES.INVALID_IP_ADDRESS' | translate }}
        </span>
      </div>
    </ng-container>
  </div>
  <ng-template #listSelectionTmpl>
    <dpa-type-ahead-multi
      [placeholderText]="'COMMON_ACTIONS.ENTER_VALUE' | translate"
      [selectedItems]="selectedItems"
      [keyBy]="unwrapValue"
      [searchFormatter]="unwrapValue"
      [labelFormatter]="unwrapValue"
      [createCustomItem]="wrapValue"
      [delimiterSupported]="true"
      [hideArrow]="true"
      [hideActionButtons]="true"
      [noValueFoundText]="'COMMON_MESSAGES.IP_ADDRESS_ADD_DESCRITION'"
      [customHelpText]="'COMMON_MESSAGES.PRESS_ENTER_TO_ADD_VALUE'"
      (selectedItemsChange)="onSelectedItemsChange($event)"
    ></dpa-type-ahead-multi>
  </ng-template>
</form>
