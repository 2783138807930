/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { RuleGroup } from '@ws1c/intelligence-models';
import { HorizonActions } from './horizon.actions';
import { HorizonState, initialHorizonState } from './horizon.state';

const _reducer: ActionReducer<HorizonState> = createReducer(
  initialHorizonState,
  on(HorizonActions.initHorizonDashboard, (state: HorizonState) => ({
    ...state,
    horizonFilterRuleGroup: new RuleGroup([]),
    selectedPoolName: undefined,
    selectedDeploymentTypes: undefined,
    podIdsForActiveDeploymentTab: undefined,
    selectedPoolTemplateName: undefined,
  })),
  on(
    HorizonActions.setHorizonFilterRuleGroup,
    (state: HorizonState, { filterRules }: ReturnType<typeof HorizonActions.setHorizonFilterRuleGroup>): HorizonState => ({
      ...state,
      horizonFilterRuleGroup: new RuleGroup(filterRules),
    }),
  ),
  on(
    HorizonActions.setHorizonTrendDateRange,
    (state: HorizonState, { dateRange }: ReturnType<typeof HorizonActions.setHorizonTrendDateRange>): HorizonState => ({
      ...state,
      horizonDashboardTrendDateRange: dateRange,
    }),
  ),
  on(
    HorizonActions.setHorizonTitlePrefix,
    (state: HorizonState, { titlePrefix }: ReturnType<typeof HorizonActions.setHorizonTitlePrefix>): HorizonState => ({
      ...state,
      titlePrefix,
    }),
  ),
  on(
    HorizonActions.setHorizonSelectedPoolName,
    (state: HorizonState, { selectedPoolName }: ReturnType<typeof HorizonActions.setHorizonSelectedPoolName>): HorizonState => ({
      ...state,
      selectedPoolName,
    }),
  ),
  on(
    HorizonActions.setSelectedDeploymentTypes,
    (state: HorizonState, { selectedDeploymentTypes }: ReturnType<typeof HorizonActions.setSelectedDeploymentTypes>): HorizonState => ({
      ...state,
      selectedDeploymentTypes,
    }),
  ),
  on(
    HorizonActions.setPodIdsForActiveDeploymentTab,
    (
      state: HorizonState,
      { podIdsForActiveDeploymentTab }: ReturnType<typeof HorizonActions.setPodIdsForActiveDeploymentTab>,
    ): HorizonState => ({
      ...state,
      podIdsForActiveDeploymentTab,
    }),
  ),
  on(
    HorizonActions.loadHorizonDashboardWithMergedRequests,
    (
      state: HorizonState,
      { dashboardTypeList }: ReturnType<typeof HorizonActions.loadHorizonDashboardWithMergedRequests>,
    ): HorizonState => ({
      ...state,
      dashboardTypeList,
    }),
  ),
  on(
    HorizonActions.setHorizonTitanSelectedPoolTemplateName,
    (
      state: HorizonState,
      { selectedPoolTemplateName }: ReturnType<typeof HorizonActions.setHorizonTitanSelectedPoolTemplateName>,
    ): HorizonState => ({
      ...state,
      selectedPoolTemplateName,
    }),
  ),
);

/**
 * horizonState
 * @export
 * @param {HorizonState} state
 * @param {Action} action
 * @returns {HorizonState}
 */
export function horizonState(state: HorizonState, action: Action): HorizonState {
  return _reducer(state, action);
}
