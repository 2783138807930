/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';

import { RuleGroup, TrendDateRange } from '@ws1c/intelligence-models';

export interface UserRiskDashboardState {
  ruleGroup: RuleGroup;
  trendDateRange: TrendDateRange;
  hideBanner: boolean;
  riskyUserByTimestampByEmail: GenericObject;
}

export const initialUserRiskDashboardState: UserRiskDashboardState = {
  hideBanner: false,
  ruleGroup: undefined,
  trendDateRange: undefined,
  riskyUserByTimestampByEmail: {},
};
