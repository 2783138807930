/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { GenericObject } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { I18NService } from '@ws1c/intelligence-common';
import { ActiveWidgetDialogMode, CoreAppState, DashboardActions, DashboardSelectors } from '@ws1c/intelligence-core/store';
import { AggregationWidget, AlertBannerTarget, DashboardConfig, WidgetColorSchema } from '@ws1c/intelligence-models';

/**
 * Edit Theme Modal
 * @export
 * @class EditThemeModalComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-edit-theme-modal',
  templateUrl: 'edit-theme-modal.component.html',
  styleUrls: ['edit-theme-modal.component.scss'],
})
export class EditThemeModalComponent implements OnInit, OnDestroy {
  public activeWidget$: Observable<AggregationWidget>;
  public activeWidgetTheme$: Observable<GenericObject>;

  public colorGroups: Array<{ label: string; colors: string[]; expanded?: boolean }> = [];
  public colorSchemas: WidgetColorSchema[];
  public subscription: Subscription = new Subscription();

  public readonly ColorSchemaColors = DashboardConfig.COLOR_SCHEMA_COLORS;
  public readonly AlertBannerTarget = AlertBannerTarget;

  /**
   * Creates an instance of EditThemeModalComponent.
   * @param {Store<CoreAppState>} store
   * @param {I18NService} i18nService
   * @memberof EditThemeModalComponent
   */
  constructor(
    private store: Store<CoreAppState>,
    private i18nService: I18NService,
  ) {
    this.activeWidget$ = this.store.select(DashboardSelectors.getActiveWidgetState);
    this.activeWidgetTheme$ = this.store.select(DashboardSelectors.getActiveWidgetTheme);
    this.colorGroups = [
      {
        label: this.i18nService.translate('WIDGET_THEME.STATUS_COLORS'),
        colors: DashboardConfig.STATUS_COLORS,
        expanded: true,
      },
      {
        label: this.i18nService.translate('WIDGET_THEME.DEFAULT_COLORS'),
        colors: DashboardConfig.COLOR_SCHEMA_COLORS,
        expanded: false,
      },
    ];
  }

  /**
   * ngOnInit
   * @memberof EditThemeModalComponent
   */
  public ngOnInit() {
    this.subscription.add(
      this.activeWidgetTheme$.subscribe((activeWidgetTheme: GenericObject) => {
        if (!activeWidgetTheme) {
          return;
        }
        this.colorSchemas = Object.keys(activeWidgetTheme).map((name: string) => {
          return new WidgetColorSchema({
            name,
            value: activeWidgetTheme[name],
          });
        });
      }),
    );
  }

  /**
   * ngOnDestroy
   * @memberof EditThemeModalComponent
   */
  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * closeModal
   * @memberof EditThemeModalComponent
   */
  public closeModal() {
    this.store.dispatch(DashboardActions.setActiveWidgetDialogMode({ mode: ActiveWidgetDialogMode.CLOSE }));
  }

  /**
   * selectColor
   * @param {string} color
   * @param {number} index
   * @memberof EditThemeModalComponent
   */
  public selectColor(color: string, index: number) {
    this.colorSchemas[index].value = color;
  }

  /**
   * saveWidgetTheme
   * @memberof EditThemeModalComponent
   */
  public saveWidgetTheme() {
    this.store.dispatch(DashboardActions.saveEditThemeWidget({ colorSchemas: this.colorSchemas }));
  }

  /**
   * trackByName
   * @param {number} _index
   * @param {WidgetColorSchema} schema
   * @returns {string}
   * @memberof EditThemeModalComponent
   */
  public trackByName(_index: number, schema: WidgetColorSchema): string {
    return schema.name;
  }
}
