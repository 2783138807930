/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { RuleGroup, StandardDashboardType, TrendDateRange } from '@ws1c/intelligence-models';

/**
 * Defines UI State for Horizon Feature
 *
 * @export
 * @interface HorizonState
 */
export interface HorizonState {
  horizonFilterRuleGroup: RuleGroup;
  horizonDashboardTrendDateRange: TrendDateRange;
  horizonDashboardType: StandardDashboardType;
  selectedPoolName: string;
  selectedPoolTemplateName: string;
  titlePrefix: string;
  selectedDeploymentTypes: string[];
  podIdsForActiveDeploymentTab: string[];
  dashboardTypeList: StandardDashboardType[];
}

export const initialHorizonState: HorizonState = {
  horizonFilterRuleGroup: new RuleGroup(),
  horizonDashboardTrendDateRange: undefined,
  horizonDashboardType: undefined,
  selectedPoolName: undefined,
  selectedPoolTemplateName: undefined,
  titlePrefix: undefined,
  selectedDeploymentTypes: undefined,
  podIdsForActiveDeploymentTab: undefined,
  dashboardTypeList: [],
};
