/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector, Selector } from '@ngrx/store';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { getDefaultedTrendDate } from '@ws1c/dashboard-common/utils';
import { FeatureSelectors } from '@ws1c/intelligence-core/store/rbac';
import { UserPreferenceFeatureControlsSelectors, UserPreferenceSelectors } from '@ws1c/intelligence-core/store/user-preference';
import { RuleGroup, StandardDashboardRequest, StandardDashboardType, TrendDateRange } from '@ws1c/intelligence-models';
import { LoginRiskDashboardState } from './login-risk-dashboard.state';

/**
 * LoginRiskDashboardSelectors
 * @export
 * @class LoginRiskDashboardSelectors
 */
export class LoginRiskDashboardSelectors {
  /**
   * selectLoginRiskDashboardState
   * @static
   * @param {MerlotState} state
   * @type {Selector<MerlotState, LoginRiskDashboardState>}
   * @memberof LoginRiskDashboardSelectors
   */
  public static selectLoginRiskDashboardState: Selector<MerlotState, LoginRiskDashboardState> = (state: MerlotState) =>
    state.standardDashboardState.loginRiskDashboardState;

  /**
   * isLoginRiskBannerHidden
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof LoginRiskDashboardSelectors
   */
  public static isLoginRiskBannerHidden: MemoizedSelector<MerlotState, boolean> = createSelector(
    LoginRiskDashboardSelectors.selectLoginRiskDashboardState,
    (state: LoginRiskDashboardState) => state.hideBanner,
  );

  /**
   * isIDMReadyToBeSetup
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof LoginRiskDashboardSelectors
   */
  public static isIDMReadyToBeSetup: MemoizedSelector<MerlotState, boolean> = createSelector(
    FeatureSelectors.hasIntegrationReadOnlyPerm,
    UserPreferenceFeatureControlsSelectors.isIDMEnabled,
    UserPreferenceSelectors.isIDMAuthorized,
    LoginRiskDashboardSelectors.isLoginRiskBannerHidden,
    (isIntegrationAllowed: boolean, isIDMEnabled: boolean, isIDMAuthorized: boolean, isBannerHidden: boolean) =>
      isIntegrationAllowed && isIDMEnabled && !isIDMAuthorized && !isBannerHidden,
  );

  /**
   * getDashboardTrendDateRange
   * @static
   * @type {MemoizedSelector<MerlotState, TrendDateRange>}
   * @memberof LoginRiskDashboardSelectors
   */
  public static getDashboardTrendDateRange: MemoizedSelector<MerlotState, TrendDateRange> = createSelector(
    LoginRiskDashboardSelectors.selectLoginRiskDashboardState,
    (state: LoginRiskDashboardState) => getDefaultedTrendDate(state.trendDateRange, StandardDashboardType.LOGIN_RISK),
  );

  /**
   * getRuleGroup
   * @static
   * @type {MemoizedSelector<MerlotState, RuleGroup>}
   * @memberof LoginRiskDashboardSelectors
   */
  public static getRuleGroup: MemoizedSelector<MerlotState, RuleGroup> = createSelector(
    LoginRiskDashboardSelectors.selectLoginRiskDashboardState,
    (state: LoginRiskDashboardState) => state.ruleGroup ?? new RuleGroup(),
  );

  /**
   * getStandardDashboardRequest
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardRequest>}
   * @memberof LoginRiskDashboardSelectors
   */
  public static getStandardDashboardRequest: MemoizedSelector<MerlotState, StandardDashboardRequest> = createSelector(
    LoginRiskDashboardSelectors.getRuleGroup,
    LoginRiskDashboardSelectors.getDashboardTrendDateRange,
    (ruleGroup: RuleGroup, trendDateRange: TrendDateRange) => {
      return new StandardDashboardRequest(StandardDashboardType.LOGIN_RISK, ruleGroup, trendDateRange);
    },
  );
}
