/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Ws1BrokerAllDirectories } from '@ws1c/intelligence-models';

/**
 * Defines UI State for WS1 Broker
 *
 * @export
 * @interface WS1BrokerState
 */
export interface WS1BrokerState {
  ws1BrokerDirectories: Ws1BrokerAllDirectories;
}

/**
 * initialBrokerState
 */
export const initialWS1BrokerState: WS1BrokerState = {
  ws1BrokerDirectories: null,
};
