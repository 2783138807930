/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { ServiceAccountsActions } from './service-accounts.actions';
import { initialServiceAccountsState, ServiceAccountsState } from './service-accounts.state';

const _reducer: ActionReducer<ServiceAccountsState> = createReducer(
  initialServiceAccountsState,

  on(
    ServiceAccountsActions.getServiceAccountsSuccess,
    (state: ServiceAccountsState, props: ReturnType<typeof ServiceAccountsActions.getServiceAccountsSuccess>): ServiceAccountsState => {
      return {
        ...state,
        serviceAccounts: props.serviceAccounts,
      };
    },
  ),

  on(
    ServiceAccountsActions.goToServiceAccountDetail,
    ServiceAccountsActions.selectServiceAccount,
    (
      state: ServiceAccountsState,
      props: ReturnType<typeof ServiceAccountsActions.goToServiceAccountDetail | typeof ServiceAccountsActions.selectServiceAccount>,
    ): ServiceAccountsState => {
      return {
        ...state,
        selectedServiceAccount: props.serviceAccount,
      };
    },
  ),

  on(
    ServiceAccountsActions.regenerateClientSecretSuccess,
    (state: ServiceAccountsState, props: ReturnType<typeof ServiceAccountsActions.regenerateClientSecretSuccess>): ServiceAccountsState => {
      return {
        ...state,
        selectedServiceAccount: {
          ...state.selectedServiceAccount,
          clientSecret: props.credentials,
        },
      };
    },
  ),

  on(
    ServiceAccountsActions.showRegenerateConfirmModal,
    (state: ServiceAccountsState, props: ReturnType<typeof ServiceAccountsActions.showRegenerateConfirmModal>): ServiceAccountsState => {
      return {
        ...state,
        showRegenerateConfirmModal: props.isOpen,
      };
    },
  ),

  on(
    ServiceAccountsActions.showDeleteConfirmModal,
    (state: ServiceAccountsState, props: ReturnType<typeof ServiceAccountsActions.showDeleteConfirmModal>): ServiceAccountsState => {
      return {
        ...state,
        showDeleteConfirmModal: props.isOpen,
      };
    },
  ),
);

/**
 * serviceAccountsReducer
 * @param {ServiceAccountsState} state
 * @param {Action} action
 * @returns {ServiceAccountsState}
 */
export function serviceAccountsState(state: ServiceAccountsState, action: Action): ServiceAccountsState {
  return _reducer(state, action);
}
