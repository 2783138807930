/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ModalSize } from '@dpa/ui-common/model';
import { Store } from '@ngrx/store';

import { ConnectorCommonActions } from '@ws1c/intelligence-core/store';
import { AlertBannerTarget, Connector, ConnectorConfigData, ConnectorSchema } from '@ws1c/intelligence-models';

/**
 * ConnectorAuthorizeModalComponent
 *
 * migrated from EditConnectionModalsComponent
 * @export
 * @class ConnectorAuthorizeModalComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-connector-authorize-modal',
  templateUrl: 'connector-authorize-modal.component.html',
  styleUrls: ['connector-authorize-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectorAuthorizeModalComponent implements OnChanges {
  @Input() public isOpen?: boolean = true;
  @Input() public isLoading: boolean = false;
  @Input() public connector: Connector;
  @Input() public connectorConfigSchema: ConnectorSchema;
  @Output() public modalClose: EventEmitter<void> = new EventEmitter();
  @Output() public authorize: EventEmitter<ConnectorConfigData> = new EventEmitter();

  public configForm: UntypedFormGroup;

  public readonly AlertBannerTarget = AlertBannerTarget;
  public readonly ModalSize = ModalSize;

  /**
   * Creates an instance of ConnectorAuthorizeModalComponent
   * @param {Store} store
   * @memberof ConnectorAuthorizeModalComponent
   */
  constructor(private store: Store) {}

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof ConnectorAuthorizeModalComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    const connector = changes.connector?.currentValue;
    if (connector) {
      this.store.dispatch(ConnectorCommonActions.getConnectorConfigSchema({ configSchemaId: connector.configSchemaId }));
    }
  }

  /**
   * confirmAuthorize
   * @memberof ConnectorAuthorizeModalComponent
   */
  public confirmAuthorize() {
    this.authorize.emit(ConnectorConfigData.getConnectorConfigDataFromConfigForm(this.configForm.getRawValue()));
  }
}
