/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { DataExplorerActions } from './data-explorer.actions';
import { DataExplorerState, initialDataExplorerState } from './data-explorer.state';

/**
 * _reducer
 * @type {ActionReducer<DataExplorerState, Action>}
 */
const _reducer: ActionReducer<DataExplorerState, Action> = createReducer(
  initialDataExplorerState,

  on(
    DataExplorerActions.setCategory,
    (state: DataExplorerState, { template }: ReturnType<typeof DataExplorerActions.setCategory>): DataExplorerState => {
      return {
        ...state,
        selectedTemplate: template,
      };
    },
  ),

  on(
    DataExplorerActions.setPreviewColumns,
    (state: DataExplorerState, { columns }: ReturnType<typeof DataExplorerActions.setPreviewColumns>): DataExplorerState => {
      return {
        ...state,
        previewColumns: columns,
      };
    },
  ),

  on(
    DataExplorerActions.setCustomQuery,
    (state: DataExplorerState, { query }: ReturnType<typeof DataExplorerActions.setCustomQuery>): DataExplorerState => {
      return {
        ...state,
        customQuery: query,
      };
    },
  ),

  on(
    DataExplorerActions.setPreviewQuery,
    (state: DataExplorerState, { query }: ReturnType<typeof DataExplorerActions.setPreviewQuery>): DataExplorerState => {
      return {
        ...state,
        previewQuery: query,
      };
    },
  ),

  on(DataExplorerActions.toggleAddReportModal, (state: DataExplorerState): DataExplorerState => {
    return {
      ...state,
      showAddReportModal: !state.showAddReportModal,
    };
  }),

  on(
    DataExplorerActions.setSelectDataSourceVisible,
    (state: DataExplorerState, { isVisible }: ReturnType<typeof DataExplorerActions.setSelectDataSourceVisible>): DataExplorerState => {
      return {
        ...state,
        isSelectDataSourceVisible: isVisible,
      };
    },
  ),
);

/**
 * Creates and returns DataExplorerState
 * @export
 * @param {DataExplorerState} state - Holds state object of type DataExplorerState
 * @param {Action} action - Holds the action which needs to be invoked in reducer
 * @returns {DataExplorerState}
 */
export function dataExplorerState(state: DataExplorerState, action: Action): DataExplorerState {
  return _reducer(state, action);
}
