/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CoreAppState } from '@ws1c/intelligence-core/store';
import { AutomationCommonActions, AutomationCommonSelectors } from '@ws1c/intelligence-core/store/automation-common';
import { ConnectorAction } from '@ws1c/intelligence-models';

/**
 * Automation Irrecoverable Action Component View
 * Displays the list of irrecoverable actions in an automation.
 * @export
 * @class AutomationIrrecoverableActionsComponent
 */
@Component({
  selector: 'dpa-automation-irrecoverable-actions',
  templateUrl: 'automation-irrecoverable-actions.component.html',
  styleUrls: ['automation-irrecoverable-actions.component.scss'],
})
export class AutomationIrrecoverableActionsComponent implements OnInit {
  public irrecoverableActions$: Observable<ConnectorAction[]>;
  public toggleDescStatus: any = {};

  /**
   * @param {Store<CoreAppState>} store
   * @memberof AutomationIrrecoverableActionsComponent
   */
  constructor(private store: Store<CoreAppState>) {
    this.irrecoverableActions$ = this.store.select(AutomationCommonSelectors.getAutomationIrrecoverableConnectorActions);
  }

  /**
   * @memberof AutomationIrrecoverableActionsComponent
   */
  public ngOnInit() {
    this.store.dispatch(AutomationCommonActions.getIrrecoverableActions());
  }

  /**
   * @param {number} actionId
   * @memberof AutomationIrrecoverableActionsComponent
   */
  public toggleDesc(actionId: number) {
    this.toggleDescStatus[actionId] = !this.toggleDescStatus[actionId];
  }
}
