/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { DestroyRef, inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { DashboardActions } from '@ws1c/intelligence-core/store';
import { StandardWidgetSubtype } from '@ws1c/intelligence-models';

/**
 * `removeWidgetSubtypesOnDestroy` can be used to remove widget subtypes on destroy.
 * If you call this function in `constructor`, `widgetSubtypes` is the only parameter you need to pass.
 * If you call this function in `ngOnInit`, you need to pass `store` and `destroyRef` as well.
 *
 * @export
 * @param {StandardWidgetSubtype[]} widgetSubtypes
 * @param {Store} [store=inject(Store)]
 * @param {DestroyRef} [destroyRef=inject(DestroyRef)]
 */
export function removeWidgetSubtypesOnDestroy(
  widgetSubtypes: StandardWidgetSubtype[],
  store = inject(Store),
  destroyRef = inject(DestroyRef),
) {
  destroyRef.onDestroy(() => {
    store.dispatch(DashboardActions.removeVisibleWidgetSubtypes({ widgetSubtypes }));
  });
}

/**
 * `setupWidgetSubtypes` can be used to add widget subtypes on init and remove them on destroy.
 * @export
 * @param {StandardWidgetSubtype[]} widgetSubtypes
 * @param {Store} store
 * @param {DestroyRef} destroyRef
 */
export function setupWidgetSubtypes(widgetSubtypes: StandardWidgetSubtype[], store: Store, destroyRef: DestroyRef) {
  store.dispatch(DashboardActions.addVisibleWidgetSubtypes({ widgetSubtypes }));

  removeWidgetSubtypesOnDestroy(widgetSubtypes, store, destroyRef);
}
