/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { AdminUserSelectorComponent } from './admin-user-selector/admin-user-selector.component';
import { AlertBannerComponent } from './alert-banner/alert-banner.component';
import {
  AutomationGridListComponent,
  AutomationIrrecoverableActionsComponent,
  AutomationModalsComponent,
  AutomationRatioIndicatorComponent,
  AutomationStatusBadgeComponent,
  AutomationTestActionComponent,
  AutomationTriggerActionComponent,
} from './automation';
import { BannerComponent } from './banner/banner.component';
import { ConfirmFeatureOptinOptoutComponent } from './confirm-feature-optin-optout/confirm-feature-optin-optout.component';
import { CONNECTOR_COMPONENTS } from './connector';
import { DASHBOARD_COMPONENTS } from './dashboard';
import { DYNAMIC_FORM_COMPONENTS } from './dynamic-form';
import { IntroFeatureAlertComponent } from './intro-feature-alert/intro-feature-alert.component';
import { KeySelectorComponent } from './key-selector/key-selector.component';
import { LOOKUP_VARIABLE_INPUT_COMPONENTS } from './lookup-variable-input';
import {
  MarketplaceResourceMetadataComponent,
  MarketplaceResourcePreviewComponent,
  MarketplaceResourcePreviewHeaderComponent,
  MarketplaceResourceSearchComponent,
} from './marketplace-resource-search';
import { META_FORM_COMPONENTS } from './meta-form';
import { PreviewTagComponent } from './preview-tag/preview-tag.component';
import { QUERY_BUILDER_COMPONENTS } from './query-builder';
import { SIDE_PANEL_COMPONENTS } from './side-panel';
import { TemplateCategorySelectorComponent } from './template-category-selector/template-category-selector.component';
import { TEMPLATE_SEARCH_COMPONENTS } from './template-search';
import { TrialBannerComponent } from './trial-banner/trial-banner.component';

export const INTELLIGENCE_CORE_COMPONENTS = [
  AdminUserSelectorComponent,
  AlertBannerComponent,
  AutomationGridListComponent,
  AutomationIrrecoverableActionsComponent,
  AutomationModalsComponent,
  AutomationRatioIndicatorComponent,
  AutomationStatusBadgeComponent,
  AutomationTestActionComponent,
  AutomationTriggerActionComponent,
  BannerComponent,
  ConfirmFeatureOptinOptoutComponent,
  IntroFeatureAlertComponent,
  KeySelectorComponent,
  MarketplaceResourceSearchComponent,
  MarketplaceResourceMetadataComponent,
  MarketplaceResourcePreviewComponent,
  MarketplaceResourcePreviewHeaderComponent,
  PreviewTagComponent,
  TrialBannerComponent,
  TemplateCategorySelectorComponent,
  ...LOOKUP_VARIABLE_INPUT_COMPONENTS,
  ...DYNAMIC_FORM_COMPONENTS,
  ...META_FORM_COMPONENTS,
  ...SIDE_PANEL_COMPONENTS,
  ...QUERY_BUILDER_COMPONENTS,
  ...TEMPLATE_SEARCH_COMPONENTS,
  ...CONNECTOR_COMPONENTS,
  ...DASHBOARD_COMPONENTS,
];

export * from './alert-banner/alert-banner.component';
export * from './admin-user-selector/admin-user-selector.component';
export * from './automation';
export * from './banner/banner.component';
export * from './connector';
export * from './confirm-feature-optin-optout/confirm-feature-optin-optout.component';
export * from './dashboard';
export * from './dynamic-form';
export * from './intro-feature-alert/intro-feature-alert.component';
export * from './key-selector/key-selector.component';
export * from './meta-form';
export * from './marketplace-resource-search';
export * from './lookup-variable-input';
export * from './preview-tag/preview-tag.component';
export * from './query-builder';
export * from './side-panel';
export * from './template-search';
export * from './template-category-selector/template-category-selector.component';
export * from './trial-banner/trial-banner.component';
