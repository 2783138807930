/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { WebError } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import { ServiceAccount } from '@ws1c/intelligence-models';

/**
 * ServiceAccountsActions
 * @export
 * @class ServiceAccountsActions
 */
export class ServiceAccountsActions {
  public static readonly CATEGORY = '[SERVICE_ACCOUNTS]';

  public static getServiceAccounts = createAction(`${ServiceAccountsActions.CATEGORY} GET_SERVICE_ACCOUNTS`);

  public static getServiceAccountsSuccess = createAction(
    `${ServiceAccountsActions.CATEGORY} GET_SERVICE_ACCOUNTS_SUCCESS`,
    props<{ serviceAccounts: ServiceAccount[] }>(),
  );

  public static getServiceAccountsFailure = createAction(
    `${ServiceAccountsActions.CATEGORY} GET_SERVICE_ACCOUNTS_FAILURE`,
    props<{ webError: WebError }>(),
  );

  public static goToAddServiceAccount = createAction(`${ServiceAccountsActions.CATEGORY} GO_TO_ADD_SERVICE_ACCOUNT`);

  public static goToServiceAccountDetail = createAction(
    `${ServiceAccountsActions.CATEGORY} GO_TO_SERVICE_ACCOUNT_DETAIL`,
    props<{ serviceAccount: ServiceAccount }>(),
  );

  public static goToListView = createAction(`${ServiceAccountsActions.CATEGORY} GO_TO_LIST_VIEW`);

  public static generateClientSecret = createAction(
    `${ServiceAccountsActions.CATEGORY} GENERATE_CLIENT_SECRET`,
    props<{ serviceAccount: ServiceAccount }>(),
  );

  public static generateClientSecretSuccess = createAction(
    `${ServiceAccountsActions.CATEGORY} GENERATE_CLIENT_SECRET_SUCCESS`,
    props<{ serviceAccount: ServiceAccount }>(),
  );

  public static generateClientSecretFailure = createAction(
    `${ServiceAccountsActions.CATEGORY} GENERATE_CLIENT_SECRET_FAILURE`,
    props<{ webError: WebError }>(),
  );

  public static selectServiceAccount = createAction(
    `${ServiceAccountsActions.CATEGORY} SELECT_SERVICE_ACCOUNT`,
    props<{ serviceAccount: ServiceAccount }>(),
  );

  public static showRegenerateConfirmModal = createAction(
    `${ServiceAccountsActions.CATEGORY} SHOW_REGENERATE_CONFIRM_MODAL`,
    props<{ isOpen: boolean }>(),
  );

  public static regenerateClientSecret = createAction(`${ServiceAccountsActions.CATEGORY} REGENERATE_CLIENT_SECRET`);

  public static regenerateClientSecretSuccess = createAction(
    `${ServiceAccountsActions.CATEGORY} REGENERATE_CLIENT_SECRET_SUCCESS`,
    props<{ credentials: string }>(),
  );

  public static regenerateClientSecretFailure = createAction(
    `${ServiceAccountsActions.CATEGORY} REGENERATE_CLIENT_SECRET_FAILURE`,
    props<{ webError: WebError }>(),
  );

  public static showDeleteConfirmModal = createAction(
    `${ServiceAccountsActions.CATEGORY} SHOW_DELETE_CONFIRM_MODAL`,
    props<{ isOpen: boolean }>(),
  );

  public static deleteServiceAccount = createAction(`${ServiceAccountsActions.CATEGORY} DELETE_SERVICE_ACCOUNT`);

  public static deleteClientSecretFailure = createAction(
    `${ServiceAccountsActions.CATEGORY} DELETE_SERVICE_ACCOUNT_FAILURE`,
    props<{ webError: WebError }>(),
  );

  public static saveClientSecretToFile = createAction(`${ServiceAccountsActions.CATEGORY} SAVE_CLIENT_SECRET_TO_FILE`);
}
