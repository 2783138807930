/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { isEqual, unionWith } from 'lodash-es';

import { CveSearchRequest, CveSearchResponse, SearchTerm } from '@ws1c/intelligence-models';
import { SecurityDashboardActions } from './security-dashboard.actions';
import { initialSecurityDashboardState, SecurityDashboardState } from './security-dashboard.state';

const _reducer: ActionReducer<SecurityDashboardState> = createReducer(
  initialSecurityDashboardState,

  on(
    SecurityDashboardActions.setDashboardFilters,
    (
      state: SecurityDashboardState,
      { ruleGroup: filterRuleGroup, trendDateRange }: ReturnType<typeof SecurityDashboardActions.setDashboardFilters>,
    ): SecurityDashboardState => ({
      ...state,
      filterRuleGroup,
      trendDateRange,
    }),
  ),
  on(
    SecurityDashboardActions.setVulnerabilitiesDashboardFilters,
    (
      state: SecurityDashboardState,
      { ruleGroup: filterRuleGroup, trendDateRange }: ReturnType<typeof SecurityDashboardActions.setVulnerabilitiesDashboardFilters>,
    ): SecurityDashboardState => ({
      ...state,
      vulnerabilities: {
        ...state.vulnerabilities,
        filterRuleGroup,
        trendDateRange,
      },
    }),
  ),
  on(
    SecurityDashboardActions.goToCveDetailPage,
    (
      state: SecurityDashboardState,
      { detailPage }: ReturnType<typeof SecurityDashboardActions.goToCveDetailPage>,
    ): SecurityDashboardState => ({
      ...state,
      vulnerabilities: {
        ...state.vulnerabilities,
        cveDetailPage: detailPage,
      },
    }),
  ),
  on(
    SecurityDashboardActions.clearCveDetailPage,
    (state: SecurityDashboardState): SecurityDashboardState => ({
      ...state,
      vulnerabilities: {
        ...state.vulnerabilities,
        cveDetailPage: undefined,
      },
    }),
  ),
  on(
    SecurityDashboardActions.setCveInsightCardVisible,
    (
      state: SecurityDashboardState,
      { isVisible }: ReturnType<typeof SecurityDashboardActions.setCveInsightCardVisible>,
    ): SecurityDashboardState => ({
      ...state,
      vulnerabilities: {
        ...state.vulnerabilities,
        cveInsightCardVisible: isVisible,
      },
    }),
  ),
  on(
    SecurityDashboardActions.setMacOsCveInsightCardVisible,
    (
      state: SecurityDashboardState,
      { isVisible }: ReturnType<typeof SecurityDashboardActions.setMacOsCveInsightCardVisible>,
    ): SecurityDashboardState => ({
      ...state,
      vulnerabilities: {
        ...state.vulnerabilities,
        macOSCveInsightCardVisible: isVisible,
      },
    }),
  ),
  on(
    SecurityDashboardActions.loadCveTable,
    (state: SecurityDashboardState, { request }: ReturnType<typeof SecurityDashboardActions.loadCveTable>): SecurityDashboardState => ({
      ...state,
      vulnerabilities: {
        ...state.vulnerabilities,
        cveTableSearchRequest: request,
        cveSearchError: undefined,
      },
    }),
  ),
  on(
    SecurityDashboardActions.loadCveTableSuccess,
    (
      state: SecurityDashboardState,
      { response }: ReturnType<typeof SecurityDashboardActions.loadCveTableSuccess>,
    ): SecurityDashboardState => ({
      ...state,
      vulnerabilities: {
        ...state.vulnerabilities,
        cveTableSearchResponse: response,
      },
    }),
  ),
  on(
    SecurityDashboardActions.loadCveTableFailure,
    (
      state: SecurityDashboardState,
      { error }: ReturnType<typeof SecurityDashboardActions.loadCveTableFailure>,
    ): SecurityDashboardState => ({
      ...state,
      vulnerabilities: {
        ...state.vulnerabilities,
        cveTableSearchResponse: undefined,
        cveSearchError: error,
      },
    }),
  ),
  on(
    SecurityDashboardActions.sortCveTable,
    (state: SecurityDashboardState, { sortOns }: ReturnType<typeof SecurityDashboardActions.sortCveTable>): SecurityDashboardState => ({
      ...state,
      vulnerabilities: {
        ...state.vulnerabilities,
        cveTableSearchRequest: new CveSearchRequest(state.vulnerabilities.cveTableSearchRequest, {
          sortOns,
          from: 0,
        } as CveSearchRequest),
        cveSearchError: undefined,
      },
    }),
  ),
  on(
    SecurityDashboardActions.searchCveTable,
    (state: SecurityDashboardState, { query }: ReturnType<typeof SecurityDashboardActions.searchCveTable>): SecurityDashboardState => ({
      ...state,
      vulnerabilities: {
        ...state.vulnerabilities,
        cveTableSearchRequest: new CveSearchRequest(state.vulnerabilities.cveTableSearchRequest, {
          searchTerm: new SearchTerm({
            value: query,
          } as SearchTerm),
        }),
        cveSearchError: undefined,
      },
    }),
  ),
  on(
    SecurityDashboardActions.loadCveSearchSuccess,
    (
      state: SecurityDashboardState,
      { response }: ReturnType<typeof SecurityDashboardActions.loadCveSearchSuccess>,
    ): SecurityDashboardState => ({
      ...state,
      vulnerabilities: {
        ...state.vulnerabilities,
        cveSearchResponse: new CveSearchResponse({
          data: unionWith(state.vulnerabilities.cveSearchResponse?.data ?? [], response.data, isEqual),
        }),
      },
    }),
  ),
);

/**
 * securityDashboardState
 * @param {SecurityDashboardState} state
 * @param {Action} action
 * @returns {SecurityDashboardState}
 */
export function securityDashboardState(state: SecurityDashboardState, action: Action): SecurityDashboardState {
  return _reducer(state, action);
}
