/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { SidePanelExpandedBodyComponent } from './side-panel-expanded-body/side-panel-expanded-body.component';
import { SidePanelExpandedHeaderComponent } from './side-panel-expanded-header/side-panel-expanded-header.component';
import {
  SidePanelMainBodyCardBlockComponent,
  SidePanelMainBodyCardComponent,
  SidePanelMainBodyCardFooterComponent,
  SidePanelMainBodyComponent,
} from './side-panel-main-body';
import { SidePanelMainFooterComponent } from './side-panel-main-footer/side-panel-main-footer.component';
import { SidePanelComponent } from './side-panel.component';

export const SIDE_PANEL_COMPONENTS: any[] = [
  SidePanelComponent,
  SidePanelExpandedBodyComponent,
  SidePanelExpandedHeaderComponent,
  SidePanelMainBodyCardComponent,
  SidePanelMainBodyCardBlockComponent,
  SidePanelMainBodyComponent,
  SidePanelMainBodyCardFooterComponent,
  SidePanelMainFooterComponent,
];

export * from './side-panel-expanded-body/side-panel-expanded-body.component';
export * from './side-panel-expanded-header/side-panel-expanded-header.component';
export * from './side-panel-main-body';
export * from './side-panel-main-footer/side-panel-main-footer.component';
export * from './side-panel.component';
