/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { FilterEditorComponent } from './filter-editor/filter-editor.component';
import { FilterGroupComponent } from './filter-group/filter-group.component';
import { FilterGroupDateRangeComponent } from './filter-group-date-range/filter-group-date-range.component';
import { FilterGroupDefaultViewComponent } from './filter-group-default-view/filter-group-default-view.component';
import { FilterGroupRuleComponent } from './filter-group-rule/filter-group-rule.component';
import { FilterGroupSlideOverComponent } from './filter-group-slide-over/filter-group-slide-over.component';
import { FilterGroupTextViewComponent } from './filter-group-text-view/filter-group-text-view.component';
import { FilterResultsComponent } from './filter-results/filter-results.component';
import { FilterValueComponent } from './filter-value/filter-value.component';
import { KeySelectorSearchComponent } from './key-selector-search/key-selector-search.component';
import { RuleTextComponent } from './rule-text/rule-text.component';
import { CommonValueSelectorComponent } from './shared/common-value-selector/common-value-selector.component';
import { IPValueSelectorComponent } from './shared/ip-value-selector/ip-value-selector.component';
import { MultiNumberSelectorComponent } from './shared/multi-number-selector/multi-number-selector.component';
import { OrganizationGroupSelectorComponent } from './shared/organization-group-selector/organization-group-selector.component';

export const QUERY_BUILDER_COMPONENTS: any[] = [
  FilterEditorComponent,
  FilterGroupComponent,
  FilterGroupDefaultViewComponent,
  FilterGroupDateRangeComponent,
  FilterGroupRuleComponent,
  FilterGroupSlideOverComponent,
  FilterGroupTextViewComponent,
  FilterResultsComponent,
  FilterValueComponent,
  KeySelectorSearchComponent,
  CommonValueSelectorComponent,
  IPValueSelectorComponent,
  MultiNumberSelectorComponent,
  OrganizationGroupSelectorComponent,
  KeySelectorSearchComponent,
  RuleTextComponent,
];

export * from './filter-editor/filter-editor.component';
export * from './filter-group-date-range/filter-group-date-range.component';
export * from './filter-group-default-view/filter-group-default-view.component';
export * from './filter-group-rule/filter-group-rule.component';
export * from './filter-group-slide-over/filter-group-slide-over.component';
export * from './filter-group-text-view/filter-group-text-view.component';
export * from './filter-group/filter-group.component';
export * from './filter-results/filter-results.component';
export * from './filter-value/filter-value.component';
export * from './key-selector-search/key-selector-search.component';
export * from './rule-text/rule-text.component';
export * from './shared/common-value-selector/common-value-selector.component';
export * from './shared/ip-value-selector/ip-value-selector.component';
export * from './shared/multi-number-selector/multi-number-selector.component';
export * from './shared/organization-group-selector/organization-group-selector.component';
