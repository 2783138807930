/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { I18NService } from '@ws1c/intelligence-common';

import { BaseTemplateSearchComponent } from '@ws1c/intelligence-core/components/template-search/base-template-search.component';
import { UserPreferenceSelectors } from '@ws1c/intelligence-core/store';
import { Column, COLUMN_NAMES, DataType, Template, TEMPLATE_TYPE } from '@ws1c/intelligence-models';

/**
 * SensorTemplateSearchComponent
 * @export
 * @class SensorTemplateSearchComponent
 * @extends {BaseTemplateSearchComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-sensor-template-search',
  templateUrl: 'sensor-template-search.component.html',
  styleUrls: ['sensor-template-search.component.scss'],
})
export class SensorTemplateSearchComponent extends BaseTemplateSearchComponent implements OnInit {
  public selectedTag: any;
  public templatesList: Template[] = [];
  @Output() public deployTemplate: EventEmitter<Template> = new EventEmitter<Template>();

  /**
   * Creates an instance of SensorTemplateSearchComponent.
   * @param {Store} store
   * @param {I18NService} i18nService
   * @memberof SensorTemplateSearchComponent
   */
  constructor(
    public store: Store,
    public i18nService: I18NService,
  ) {
    super(store, i18nService, TEMPLATE_TYPE.SENSORS);
    this.tableColumns = [
      new Column({
        name: COLUMN_NAMES.byName.name,
        label: this.i18nService.translate('LIST_VIEW.COLUMNS.TEMPLATE_NAME'),
        dataType: DataType[DataType.STRING],
        className: 'dpa-dg-col-lg',
      }),
      new Column({
        name: COLUMN_NAMES.byName.description,
        label: this.i18nService.translate('LIST_VIEW.COLUMNS.DESCRIPTION'),
        dataType: DataType[DataType.STRING],
        className: '',
      }),
      new Column({
        name: COLUMN_NAMES.byName.language,
        label: this.i18nService.translate('LIST_VIEW.COLUMNS.LANGUAGE'),
        className: '',
      }),
      new Column({
        name: COLUMN_NAMES.byName.platform,
        label: this.i18nService.translate('LIST_VIEW.COLUMNS.PLATFORM'),
        className: '',
      }),
      new Column({
        name: COLUMN_NAMES.byName.actions,
        label: this.i18nService.translate('COMMON_MESSAGES.TAGS'),
        className: '',
      }),
    ];
  }

  /**
   * ngOnInit
   * @memberof ScriptTemplateSearchComponent
   */
  public ngOnInit() {
    super.ngOnInit();
    this.sub.add(
      this.store
        .select(UserPreferenceSelectors.isGemProvisionedAirwatchIntegrationPresent)
        .subscribe((isGemProvisionedAirwatchIntegrationPresent: boolean) => {
          this.actions = [
            {
              label: this.i18nService.translate('COMMON_ACTIONS.PREVIEW'),
              onClick: this.onPreviewTemplate.bind(this),
            },
            {
              label: this.i18nService.translate('COMMON_ACTIONS.DEPLOY'),
              onClick: this.onDeployTemplate.bind(this),
              isDisabled: (_template: Template) => !isGemProvisionedAirwatchIntegrationPresent,
            },
          ];
        }),
    );
  }

  /**
   * onDeployTemplate
   * @param {Template} template
   * @memberof SensorTemplateSearchComponent
   */
  public onDeployTemplate(template: Template) {
    this.deployTemplate.emit(template);
  }
}
