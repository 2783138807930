/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { GenericObject } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { UserPreferenceActions, UserPreferenceUIPreferenceSelectors } from '@ws1c/intelligence-core/store/user-preference';

/**
 * BannerComponent
 *
 * @export
 * @class BannerComponent
 */
@Component({
  selector: 'dpa-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit, OnDestroy {
  @Input() public header: string;
  @Input() public body: string;
  @Input() public linkText: string;
  @Input() public linkRoute: string | any[];
  @Input() public linkRouteParams?: Params | null;
  @Input() public dismissParam: string;

  public isBannerHidden: boolean;
  public subscription: Subscription = new Subscription();

  private recentUISettingsUpdated: boolean = false;

  /**
   * Creates an instance of BannerComponent
   *
   * @param {Store<CoreAppState>} store
   * @memberof BannerComponent
   */
  constructor(private store: Store<CoreAppState>) {}

  /**
   * ngOnInit
   *
   * @memberof BannerComponent
   */
  public ngOnInit() {
    this.subscription.add(
      this.store.select(UserPreferenceUIPreferenceSelectors.uiSettingsPreferences).subscribe((preferences: GenericObject) => {
        // Hide the banner only when value is false in the initial load
        // Do not hide banner when user clicks on the "Don't show again checkbox" until he click on Close button
        if (this.recentUISettingsUpdated) {
          return;
        }
        this.isBannerHidden = !!preferences?.[this.dismissParam];
      }),
    );
  }

  /**
   * ngOnDestroy
   *
   * @memberof BannerComponent
   */
  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * hideBanner : Only hide banner in UI, do not update settings
   *
   * @memberof BannerComponent
   */
  public hideBanner() {
    this.isBannerHidden = true;
  }

  /**
   * updateBannerUISettings : Only update the hidden flag to
   * false in UI settings, do not hide banner untill unless close icon is clicked
   *
   * @memberof BannerComponent
   */
  public updateBannerUISettings() {
    this.recentUISettingsUpdated = true;
    this.store.dispatch(
      UserPreferenceActions.updateUISettings({
        uiSettings: {
          [this.dismissParam]: true,
        },
      }),
    );
  }
}
