/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { getFailureReason, WebError, WindowService } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { AppConfig, I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import { ERROR_I18N_KEY_BY_ERROR_CODE, URL_LINK_BY_ERROR_CODE } from '@ws1c/intelligence-core/const/script-sensors-error-codes';
import { AutomationService, TemplateService } from '@ws1c/intelligence-core/services';
import {
  AlertBannerActions,
  CoreAppState,
  ReportCommonSelectors,
  TemplateCommonActions,
  TemplateCommonSelectors,
  UserPreferenceAssetsSelectors,
  UserPreferenceSelectors,
} from '@ws1c/intelligence-core/store';
import {
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  AutomationTemplate,
  DashboardTemplate,
  GenericSearchRequest,
  getUniqueId,
  IntegrationDetails,
  ReportTemplate,
  ResourceDeployResponse,
  ResourceDeployValidationResponse,
  ROUTE_NAMES,
  ScriptTemplateSearchResponse,
  SensorTemplateSearchResponse,
  Tag,
  Template,
  TEMPLATE_TYPE,
  TemplateBookmark,
  WidgetTemplate,
} from '@ws1c/intelligence-models';

/**
 * TemplateCommonEffects
 *
 * @export
 * @class TemplateCommonEffects
 */
@Injectable()
export class TemplateCommonEffects {
  /**
   * Get template tags
   * @type {Observable<Action>}
   * @memberof TemplateCommonEffects
   */
  public getTags$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplateCommonActions.getTags),
      switchMap(() =>
        this.templateService.getTags().pipe(
          map((tags: Tag[]) => TemplateCommonActions.setTags({ tags })),
          catchError(() => of(TemplateCommonActions.setTags({ tags: [] }))),
        ),
      ),
    ),
  );

  /**
   * loadTemplates$
   * @type {Observable<Action>}
   * @memberof TemplateCommonEffects
   */
  public loadTemplates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplateCommonActions.loadTemplates),
      mergeMap(({ templateType }: ReturnType<typeof TemplateCommonActions.loadTemplates>) => {
        const scriptOrSensorSearchRequest = new GenericSearchRequest({ size: 500 });
        switch (templateType) {
          case TEMPLATE_TYPE.AUTOMATIONS: {
            return this.automationService.getAutomationTemplatesV3().pipe(
              map((templates: AutomationTemplate[]) => TemplateCommonActions.loadTemplatesSuccess({ templateType, templates })),
              catchError(() => of(TemplateCommonActions.loadTemplatesFailure({ templateType }))),
            );
          }
          case TEMPLATE_TYPE.REPORTS: {
            return this.templateService.getReportTemplates().pipe(
              map((templates: ReportTemplate[]) => TemplateCommonActions.loadTemplatesSuccess({ templateType, templates })),
              catchError(() => of(TemplateCommonActions.loadTemplatesFailure({ templateType }))),
            );
          }
          case TEMPLATE_TYPE.WIDGETS: {
            return this.templateService.getWidgetTemplates().pipe(
              map((templates: WidgetTemplate[]) => TemplateCommonActions.loadTemplatesSuccess({ templateType, templates })),
              catchError(() => of(TemplateCommonActions.loadTemplatesFailure({ templateType }))),
            );
          }
          case TEMPLATE_TYPE.SCRIPTS: {
            return this.templateService.getScriptTemplates(scriptOrSensorSearchRequest).pipe(
              map((response: ScriptTemplateSearchResponse) =>
                TemplateCommonActions.loadTemplatesSuccess({ templateType, templates: response.results }),
              ),
              catchError(() => of(TemplateCommonActions.loadTemplatesFailure({ templateType }))),
            );
          }
          case TEMPLATE_TYPE.DASHBOARDS: {
            return this.templateService.getDashboardTemplates().pipe(
              map((templates: DashboardTemplate[]) => TemplateCommonActions.loadTemplatesSuccess({ templateType, templates })),
              catchError(() => of(TemplateCommonActions.loadTemplatesFailure({ templateType }))),
            );
          }
          case TEMPLATE_TYPE.SENSORS: {
            return this.templateService.getSensorTemplates(scriptOrSensorSearchRequest).pipe(
              map((response: SensorTemplateSearchResponse) =>
                TemplateCommonActions.loadTemplatesSuccess({ templateType, templates: response.results }),
              ),
              catchError(() => of(TemplateCommonActions.loadTemplatesFailure({ templateType }))),
            );
          }
        }
      }),
    ),
  );

  /**
   * loadTemplateByName$
   * @type {Observable<Action>}
   * @memberof TemplateCommonEffects
   */
  public loadTemplateByName$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplateCommonActions.loadTemplateByName),
      withLatestFrom(this.store.select(TemplateCommonSelectors.getTemplateByMarketplaceResourceId)),
      mergeMap(
        ([{ templateType, integration, entity, templateName }, templateByMarketplaceResourceId]: [
          ReturnType<typeof TemplateCommonActions.loadTemplateByName>,
          Record<string, Template>,
        ]) => {
          const resourceId =
            templateType === TEMPLATE_TYPE.DASHBOARDS ? getUniqueId(templateName) : getUniqueId(integration, entity, templateName);
          const cachedTemplate = templateByMarketplaceResourceId[resourceId];
          if (cachedTemplate) {
            return [TemplateCommonActions.loadTemplateByNameSuccess({ template: cachedTemplate })];
          }
          switch (templateType) {
            case TEMPLATE_TYPE.AUTOMATIONS: {
              return this.templateService.getAutomationTemplateByNameV3(integration, entity, templateName).pipe(
                map((template: AutomationTemplate) => TemplateCommonActions.loadTemplateByNameSuccess({ template })),
                catchError(() => of(TemplateCommonActions.loadTemplateByNameFailure({ templateType }))),
              );
            }
            case TEMPLATE_TYPE.REPORTS: {
              return this.templateService.getReportTemplateByName(integration, entity, templateName).pipe(
                map((template: ReportTemplate) => TemplateCommonActions.loadTemplateByNameSuccess({ template })),
                catchError(() => of(TemplateCommonActions.loadTemplateByNameFailure({ templateType }))),
              );
            }
            case TEMPLATE_TYPE.WIDGETS: {
              return this.templateService.getWidgetTemplateByName(integration, entity, templateName).pipe(
                map((template: WidgetTemplate) => TemplateCommonActions.loadTemplateByNameSuccess({ template })),
                catchError(() => of(TemplateCommonActions.loadTemplateByNameFailure({ templateType }))),
              );
            }
            case TEMPLATE_TYPE.DASHBOARDS: {
              return this.templateService.getDashboardTemplateByName(templateName).pipe(
                map((template: DashboardTemplate) => TemplateCommonActions.loadTemplateByNameSuccess({ template })),
                catchError(() => of(TemplateCommonActions.loadTemplateByNameFailure({ templateType }))),
              );
            }
          }
        },
      ),
    ),
  );

  /**
   * toggleTemplateBookmark$
   * @type {Observable<Action>}
   * @memberof TemplateCommonEffects
   */
  public toggleTemplateBookmark$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplateCommonActions.toggleTemplateBookmark),
      switchMap(({ templateType, templateBookmark }: ReturnType<typeof TemplateCommonActions.toggleTemplateBookmark>) => {
        const { templateId, bookmarked }: TemplateBookmark = templateBookmark;
        const serviceCall = bookmarked
          ? this.templateService.addWidgetTemplateBookmark(templateId, templateType)
          : this.templateService.deleteWidgetTemplateBookmark(templateId, templateType);
        return serviceCall.pipe(
          switchMap((template: Template) => [
            TemplateCommonActions.toggleTemplateBookmarkSuccess({ templateType, template }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              target: AlertBannerTarget.MODAL,
              message: this.i18nService.translate('DASHBOARD_ACTIONS.SET_DASHBOARD_BOOKMARKS_SUCCESS_MSG', { name: template.label }),
            }),
          ]),
          catchError((error: WebError) => [
            TemplateCommonActions.toggleTemplateBookmarkFailure({ templateType }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.MODAL,
              message: this.i18nService.translate('DASHBOARD_ACTIONS.SET_DASHBOARD_BOOKMARKS_FAILURE_MSG', {
                reason: getFailureReason(error),
              }),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * deployTemplate$
   * @type {Observable<Action>}
   * @memberof TemplateCommonEffects
   */
  public deployTemplate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplateCommonActions.deployTemplate),
      withLatestFrom(
        this.store.select(UserPreferenceSelectors.getGemProvisionedAirwatchIntegrationDetails),
        this.store.select(UserPreferenceAssetsSelectors.customerConnectUrl),
      ),
      switchMap(
        ([{ templateType, templateId, deployRequest }, details, customerConnectUrl]: [
          ReturnType<typeof TemplateCommonActions.deployTemplate>,
          IntegrationDetails,
          string,
        ]) => {
          return this.templateService.deployTemplate(templateType, templateId, details.id, deployRequest).pipe(
            mergeMap((deployResponse: ResourceDeployResponse) => [
              TemplateCommonActions.openResourceDeploymentUrl({ resourceUrl: deployResponse.resourceEditUrl }),
              TemplateCommonActions.deployTemplateSuccess(),
            ]),
            catchError((error: WebError) => [
              TemplateCommonActions.deployTemplateFailure(),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate(this.getDeploymentFailureMessageKey(error?.errors[0]?.code, templateType), {
                  name: deployRequest.templateName,
                  link: customerConnectUrl,
                }),
              }),
            ]),
          );
        },
      ),
    ),
  );

  /**
   * goToCreateWidgetFromTemplate$
   * @memberof TemplateCommonEffects
   */
  public goToCreateWidgetFromTemplate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TemplateCommonActions.goToCreateWidgetFromTemplate),
        tap(({ templateId, dashboardId, sourceTemplateId }: ReturnType<typeof TemplateCommonActions.goToCreateWidgetFromTemplate>) => {
          this.routerExt.navigate([ROUTE_NAMES.DASHBOARD.ADD_WIDGET(ROUTE_NAMES.WORKSPACE.HOME, dashboardId)], {
            queryParams: {
              [AppConfig.QUERY_PARAM_TEMPLATE_ID]: templateId,
              [AppConfig.QUERY_PARAM_SOURCE_TEMPLATE_ID]: sourceTemplateId,
            },
          });
        }),
      ),
    { dispatch: false },
  );

  /**
   * goToCreateReportFromTemplate$
   * @memberof TemplateCommonEffects
   */
  public goToCreateReportFromTemplate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TemplateCommonActions.goToCreateReportFromTemplate),
        withLatestFrom(this.store.select(ReportCommonSelectors.getAddRoute)),
        tap(
          ([{ templateId, sourceTemplateId }, addRoute]: [ReturnType<typeof TemplateCommonActions.goToCreateReportFromTemplate>, string]) =>
            this.routerExt.navigate([addRoute], {
              queryParams: {
                [AppConfig.QUERY_PARAM_TEMPLATE_ID]: templateId,
                [AppConfig.QUERY_PARAM_SOURCE_TEMPLATE_ID]: sourceTemplateId,
              },
            }),
        ),
      ),
    { dispatch: false },
  );

  /**
   * goToCreateAutomationFromTemplate$
   * @memberof TemplateCommonEffects
   */
  public goToCreateAutomationFromTemplate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TemplateCommonActions.goToCreateAutomationFromTemplate),
        tap(({ templateId, sourceTemplateId }: ReturnType<typeof TemplateCommonActions.goToCreateAutomationFromTemplate>) =>
          this.routerExt.navigate([`/${ROUTE_NAMES.GLOBAL_ORCHESTRATOR.ADD_WORKFLOW}`], {
            queryParams: { [AppConfig.QUERY_PARAM_TEMPLATE_ID]: templateId, [AppConfig.QUERY_PARAM_SOURCE_TEMPLATE_ID]: sourceTemplateId },
          }),
        ),
      ),
    { dispatch: false },
  );

  /**
   * openResourceDeploymentUrl$
   * @type {Observable<Action>}
   * @memberof TemplateCommonEffects
   */
  public openResourceDeploymentUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TemplateCommonActions.openResourceDeploymentUrl),
        filter(({ resourceUrl }: ReturnType<typeof TemplateCommonActions.openResourceDeploymentUrl>) => !!resourceUrl),
        tap(({ resourceUrl }: ReturnType<typeof TemplateCommonActions.openResourceDeploymentUrl>) => {
          this.windowService.open(resourceUrl, '_blank');
        }),
      ),
    { dispatch: false },
  );

  /**
   * loadTemplateDeployValidations$
   * @type {Observable<Action>}
   * @memberof TemplateCommonEffects
   */
  public loadTemplateDeployValidations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplateCommonActions.loadTemplateDeployValidations),
      withLatestFrom(
        this.store.select(UserPreferenceSelectors.getGemProvisionedAirwatchIntegrationDetails),
        this.store.select(UserPreferenceAssetsSelectors.customerConnectUrl),
      ),
      switchMap(
        ([{ templateType, templateId, templateName }, details, customerConnectUrl]: [
          ReturnType<typeof TemplateCommonActions.loadTemplateDeployValidations>,
          IntegrationDetails,
          string,
        ]) => {
          return this.templateService.loadTemplateDeployValidations(templateType, templateId, details?.id).pipe(
            map((response: ResourceDeployValidationResponse) => {
              // convert error codes to error messages
              response.validationMessages = response.validationMessages.map((errorCode: string) =>
                this.i18nService.translate(ERROR_I18N_KEY_BY_ERROR_CODE[errorCode], {
                  name: templateName,
                  link: URL_LINK_BY_ERROR_CODE[errorCode] ?? customerConnectUrl,
                }),
              );
              return TemplateCommonActions.loadTemplateDeployValidationsSuccess({ validationMessages: response.validationMessages });
            }),
            catchError(() => [TemplateCommonActions.loadTemplateDeployValidationsFailure()]),
          );
        },
      ),
    ),
  );

  /**
   * Creates an instance of TemplateCommonEffects.
   * @param {Actions} actions$
   * @param {AutomationService} automationService
   * @param {TemplateService} templateService
   * @param {I18NService} i18nService
   * @param {RouterExtensions} routerExt
   * @param {Store<CoreAppState>} store
   * @param {WindowService} windowService
   * @memberof TemplateCommonEffects
   */
  constructor(
    private actions$: Actions,
    private automationService: AutomationService,
    private templateService: TemplateService,
    private i18nService: I18NService,
    private routerExt: RouterExtensions,
    private store: Store<CoreAppState>,
    private windowService: WindowService,
  ) {}

  /**
   * Get deployment failure message key based on errorcode and template type.
   * @param {string} code
   * @param {TEMPLATE_TYPE} templateType
   * @returns {string} Message key
   * @memberof TemplateCommonEffects
   */
  private getDeploymentFailureMessageKey(code: string, templateType: TEMPLATE_TYPE): string {
    if (code && ERROR_I18N_KEY_BY_ERROR_CODE[code]) {
      return ERROR_I18N_KEY_BY_ERROR_CODE[code];
    }
    return templateType === TEMPLATE_TYPE.SCRIPTS
      ? 'MARKETPLACE.SCRIPTS_AND_SENSORS.SCRIPT_DEPLOY_ERROR'
      : 'MARKETPLACE.SCRIPTS_AND_SENSORS.SENSOR_DEPLOY_ERROR';
  }
}
