/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import {
  Account,
  AccountGroup,
  AccountGroupSearchResponse,
  AccountRole,
  AccountSearchResponse,
  DataAccessPolicy,
  GenericSearchRequest,
  IntegrationGroup,
  LOAD_STATE,
  RolesSearchResponse,
  UserAdminAccount,
} from '@ws1c/intelligence-models';

/**
 * Defines UI State for Accounts
 *
 * @export
 * @interface AccountState
 */
export interface AccountState {
  isLoading: boolean;
  searchRequest: GenericSearchRequest;
  searchResponse: AccountSearchResponse;
  selectedAccounts: Account[];
  roles: AccountRole[];
  usersForAddAccount: UserAdminAccount[];
  isLoadingUsers: boolean;
  accountByAzureId: Account;
  accountGroupSearchRequest: GenericSearchRequest;
  accountGroupSearchResponse: AccountGroupSearchResponse;
  isLoadingAccountGroups: boolean;
  isLoadingIntegrationGroups: boolean;
  selectedGroups: AccountGroup[];
  integrationGroups: IntegrationGroup[];
  groupByAzureId: AccountGroup;
  showChooseDataAccessPolicyModal: boolean;
  selectedDataAccessPolicy: DataAccessPolicy;
  intelligenceRolesRequest: GenericSearchRequest;
  intelligenceRolesLoadingStatus: LOAD_STATE;
  intelligenceRolesResponse: RolesSearchResponse;
  selectedIntelligenceRoles: AccountRole[];
  showSaveWarning: boolean;
  showPolicyWarning: boolean;
  showReplaceConfirm: boolean;
  showRemoveConfirm: boolean;
}

export const initialAccountState: AccountState = {
  isLoading: false,
  searchRequest: undefined,
  searchResponse: undefined,
  selectedAccounts: [],
  roles: undefined,
  usersForAddAccount: [],
  isLoadingUsers: false,
  accountByAzureId: undefined,
  accountGroupSearchRequest: undefined,
  accountGroupSearchResponse: undefined,
  isLoadingAccountGroups: false,
  isLoadingIntegrationGroups: false,
  selectedGroups: [],
  integrationGroups: [],
  groupByAzureId: undefined,
  showChooseDataAccessPolicyModal: false,
  selectedDataAccessPolicy: undefined,
  intelligenceRolesRequest: new GenericSearchRequest(),
  intelligenceRolesLoadingStatus: LOAD_STATE.NONE,
  intelligenceRolesResponse: new RolesSearchResponse(),
  selectedIntelligenceRoles: [],
  showSaveWarning: false,
  showPolicyWarning: false,
  showReplaceConfirm: false,
  showRemoveConfirm: false,
};
