/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector, Selector } from '@ngrx/store';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { getDefaultedTrendDate } from '@ws1c/dashboard-common/utils';
import { RuleGroup, StandardDashboardRequest, StandardDashboardType, TrendDateRange } from '@ws1c/intelligence-models';
import { WorkspaceOneMtdDashboardState } from './workspace-one-mtd-dashboard.state';

/**
 * WorkspaceOneMtdDashboardSelectors
 * @export
 * @class WorkspaceOneMtdDashboardSelectors
 */
export class WorkspaceOneMtdDashboardSelectors {
  /**
   * selectWorkspaceOneMtdDashboardState
   * @static
   * @param {MerlotState} state
   * @type {Selector<MerlotState, WorkspaceOneMtdDashboardState>}
   * @memberof WorkspaceOneMtdDashboardSelectors
   */
  public static selectWorkspaceOneMtdDashboardState: Selector<MerlotState, WorkspaceOneMtdDashboardState> = (state: MerlotState) => {
    return state.standardDashboardState.workspaceOneMtdDashboardState;
  };

  /**
   * getTrendDateRange
   * @static
   * @type {MemoizedSelector<MerlotState, TrendDateRange>}
   * @memberof WorkspaceOneMtdDashboardSelectors
   */
  public static getTrendDateRange: MemoizedSelector<MerlotState, TrendDateRange> = createSelector(
    WorkspaceOneMtdDashboardSelectors.selectWorkspaceOneMtdDashboardState,
    (state: WorkspaceOneMtdDashboardState) => {
      return getDefaultedTrendDate(state.trendDateRange, StandardDashboardType.WORKSPACE_ONE_MTD);
    },
  );

  /**
   * getDashboardRequest
   * @static
   * @type {MemoizedSelector<MerlotState, StandardDashboardRequest>}
   * @memberof WorkspaceOneMtdDashboardSelectors
   */
  public static getDashboardRequest: MemoizedSelector<MerlotState, StandardDashboardRequest> = createSelector(
    WorkspaceOneMtdDashboardSelectors.getTrendDateRange,
    (trendDateRange: TrendDateRange) => {
      return new StandardDashboardRequest(StandardDashboardType.WORKSPACE_ONE_MTD, new RuleGroup(), trendDateRange);
    },
  );
}
