/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { WebError } from '@dpa/ui-common';

import { CveDetailPage, CveSearchRequest, CveSearchResponse, RuleGroup, TrendDateRange } from '@ws1c/intelligence-models';

/**
 * SecurityDashboardState
 * @export
 * @interface SecurityDashboardState
 */
export interface SecurityDashboardState {
  filterRuleGroup: RuleGroup;
  trendDateRange: TrendDateRange;
  vulnerabilities: {
    filterRuleGroup: RuleGroup;
    trendDateRange: TrendDateRange;
    cveInsightCardVisible: boolean;
    cveTableSearchRequest: CveSearchRequest;
    cveTableSearchResponse: CveSearchResponse;
    cveSearchResponse: CveSearchResponse;
    macOSCveInsightCardVisible: boolean;
    cveDetailPage: CveDetailPage;
    cveSearchError: WebError;
  };
}

export const initialSecurityDashboardState: SecurityDashboardState = {
  filterRuleGroup: undefined,
  trendDateRange: undefined,
  vulnerabilities: {
    filterRuleGroup: undefined,
    trendDateRange: undefined,
    cveInsightCardVisible: undefined,
    cveTableSearchRequest: undefined,
    cveTableSearchResponse: undefined,
    cveSearchResponse: undefined,
    macOSCveInsightCardVisible: undefined,
    cveDetailPage: undefined,
    cveSearchError: undefined,
  },
};
