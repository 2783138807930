<ng-container *ngIf="alertBanner$ | async as alertBanner">
  <clr-alert
    *ngIf="alertBanner.visible"
    dpaAutoFocus
    [clrAlertClosed]="!alertBanner.visible"
    [clrAlertAppLevel]="appLevel"
    [clrAlertType]="alertBanner.alertType"
    [clrAlertClosable]="!alertBanner.autoDismiss"
    [class.page-level]="pageLevel"
    (clrAlertClosedChange)="onClose()"
    role="alert"
    tabindex="0"
  >
    <clr-alert-item *ngFor="let alertItem of alertItems; trackBy: alertItemTrackBy">
      <span
        [innerHTML]="alertItem.message"
        class="alert-text"
      ></span>
      <div
        *ngIf="alertItem.actionText"
        class="alert-actions"
      >
        <a
          *ngIf="isExternalLink(alertItem.actionLink); else internalLink"
          [href]="alertItem.actionLink"
          (click)="onActionClick($event, alertItem.actionLink)"
          class="alert-action"
          target="_blank"
          rel="noopener"
        >
          {{ alertItem.actionText }}
        </a>
        <ng-template #internalLink>
          <button
            [routerLink]="alertItem.actionLink"
            (click)="onActionClick($event, alertItem.actionLink)"
            class="btn alert-action"
          >
            {{ alertItem.actionText }}
          </button>
        </ng-template>
      </div>
    </clr-alert-item>
  </clr-alert>
</ng-container>
