/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalSize } from '@dpa/ui-common/model';

import { AlertBannerTarget, Connector } from '@ws1c/intelligence-models';

/**
 * ConnectorDeleteModalComponent
 *
 * migrated from CustomConnectionModalsComponent
 * @export
 * @class ConnectorDeleteModalComponent
 */
@Component({
  selector: 'dpa-connector-delete-modal',
  templateUrl: 'connector-delete-modal.component.html',
  styleUrls: ['connector-delete-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectorDeleteModalComponent {
  @Input() public isOpen?: boolean = true;
  @Input() public isLoading: boolean = false;
  @Input() public connector: Connector;
  @Input() public connectorUsageCount: number;
  @Output() public modalClose: EventEmitter<void> = new EventEmitter();
  @Output() public delete: EventEmitter<void> = new EventEmitter();
  @Output() public viewWorkflows: EventEmitter<void> = new EventEmitter();

  public readonly AlertBannerTarget = AlertBannerTarget;
  public readonly ModalSize = ModalSize;
}
