/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GenericObject, SkeletonClassName } from '@dpa/ui-common';

import { I18NService } from '@ws1c/intelligence-common';
import {
  AggregationFunction,
  AggregationWidgetChartType,
  BucketingAttribute,
  COLUMN_NAMES,
  Counter,
  CounterDefinition,
  CounterResult,
  MetaFormField,
  Trend,
  TrendDefinition,
  TrendResult,
} from '@ws1c/intelligence-models';

/**
 * UsageCardComponent
 *
 * @export
 * @class UsageCardComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-usage-card',
  templateUrl: 'usage-card.component.html',
  styleUrls: ['usage-card.component.scss'],
})
export class UsageCardComponent implements OnChanges {
  @Input() public heading: string;
  @Input() public used: number;
  @Input() public total: number;
  @Input() public isLoading?: boolean = false;
  @Input() public overUsageText?: string = '';
  @Input() public showFooter?: boolean = false;
  @Input() public footerText?: string;
  @Input() public footerTooltipText?: string;

  public trend: Trend = undefined;
  public usageColors: GenericObject[] = undefined;

  public readonly SkeletonClassName = SkeletonClassName;
  public readonly CHART_TYPE = AggregationWidgetChartType;
  public readonly usageColorsBelowEightyPercent: GenericObject[];
  public readonly usageColorsAboveEightyPercent: GenericObject[];
  public readonly usageColorsWhenFull: GenericObject[];

  /**
   * Initializes instance of UsageCardComponent
   * @param {I18NService} i18nService - I18n Service instance
   * @memberof UsageCardComponent
   */
  constructor(private i18nService: I18NService) {
    // Set usage colors default values
    const used: string = this.i18nService.translate('COMMON_MESSAGES.USED');
    const remaining: string = this.i18nService.translate('COMMON_MESSAGES.REMAINING');
    this.usageColorsBelowEightyPercent = [
      {
        name: used,
        value: '#004D8A',
      },
      {
        name: remaining,
        value: '#DBE7F0',
      },
    ];
    this.usageColorsAboveEightyPercent = [
      {
        name: used,
        value: '#FAC400',
      },
      {
        name: remaining,
        value: '#F7EFD4',
      },
    ];
    this.usageColorsWhenFull = [
      {
        name: used,
        value: '#F54F47',
      },
    ];
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof UsageCardComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.used === undefined && changes.total === undefined) {
      return;
    }
    this.updateTrend();
    this.updateUsageColors();
  }

  /**
   * updateTrend
   * @memberof UsageCardComponent
   */
  private updateTrend() {
    this.trend = new Trend({
      trendDefinition: new TrendDefinition({
        bucketingAttributes: [COLUMN_NAMES.byName.item],
        counterDefinitions: [new CounterDefinition()],
      }),
      trendResults: [
        new TrendResult({
          bucketingAttributes: [
            new BucketingAttribute({
              value: this.i18nService.translate('COMMON_MESSAGES.REMAINING'),
              reportColumnView: new MetaFormField(),
            }),
          ],
          counters: [
            new Counter({
              definition: new CounterDefinition({
                aggregationFunction: AggregationFunction.COUNT_DISTINCT,
              }),
              result: new CounterResult({
                value: this.total - this.used,
                reportColumnView: new MetaFormField(),
              }),
            }),
          ],
        }),
        new TrendResult({
          bucketingAttributes: [
            new BucketingAttribute({
              value: this.i18nService.translate('COMMON_MESSAGES.USED'),
              reportColumnView: new MetaFormField(),
            }),
          ],
          counters: [
            new Counter({
              definition: new CounterDefinition({
                aggregationFunction: AggregationFunction.COUNT_DISTINCT,
              }),
              result: new CounterResult({
                value: this.used,
                reportColumnView: new MetaFormField(),
              }),
            }),
          ],
        }),
      ],
    });
  }

  /**
   * updateUsageColors
   * @memberof UsageCardComponent
   */
  private updateUsageColors() {
    if (this.total - this.used <= 0) {
      this.usageColors = this.usageColorsWhenFull;
    } else if (this.used >= this.total * 0.8) {
      this.usageColors = this.usageColorsAboveEightyPercent;
    } else {
      this.usageColors = this.usageColorsBelowEightyPercent;
    }
  }
}
