/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { DEEM_ROUTE_NAMES } from '@ws1c/deem-solution/const/deem-route-names';
import { I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import { SolutionSettingMetricService } from '@ws1c/intelligence-core/services/solution-setting-metric.service';
import { AlertBannerActions, CoreAppState, DeemCommonActions, DeemCommonSelectors } from '@ws1c/intelligence-core/store';
import {
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  getThresholdsByMetricByEntity,
  SolutionSettingMetricDetails,
} from '@ws1c/intelligence-models';

/**
 * DeemCommonEffects
 *
 * @export
 * @class DeemCommonEffects
 */
@Injectable()
export class DeemCommonEffects {
  /**
   * navigateToSurveyCreate$
   * @type {Observable<Action>}
   * @memberof DeemSurveysEffects
   */
  public navigateToSurveyCreate$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeemCommonActions.navigateToSurveyCreate),
        tap(() => {
          this.routerExtensions.navigate([DEEM_ROUTE_NAMES.CREATE_SURVEY_FQ, DEEM_ROUTE_NAMES.OVERVIEW]);
        }),
      ),
    { dispatch: false },
  );

  /**
   * returnToUrl$
   * @type {Observable<Action>}
   * @memberof DeemSurveysEffects
   */
  public returnToUrl$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeemCommonActions.returnToUrl),
        tap(({ url }: ReturnType<typeof DeemCommonActions.returnToUrl>) => {
          this.routerExtensions.navigateByUrl(url);
        }),
      ),
    { dispatch: false },
  );

  /**
   * setThresholdV2$
   * @type {Observable<Action>}
   * @memberof DeemSetupEffects
   */
  public setThresholdV2$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeemCommonActions.setThresholdV2),
      switchMap(({ metrics }: ReturnType<typeof DeemCommonActions.setThresholdV2>) => {
        return this.solutionSettingMetricService.setSolutionSettingMetrics(metrics).pipe(
          mergeMap((response: SolutionSettingMetricDetails[]) => {
            const thresholdsV2Data = getThresholdsByMetricByEntity(response);
            return [
              DeemCommonActions.setThresholdV2Success({
                thresholdsByEntity: thresholdsV2Data,
                entity: Object.keys(thresholdsV2Data)[0],
                metric: metrics[0].metricKey.metricName,
              }),
              DeemCommonActions.closeDeemThresholdEditModal(),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.WARNING,
                target: AlertBannerTarget.PAGE,
                message: this.i18nService.translate('COMMON_MESSAGES.UPDATE_IN_N_HOURS_PROGRESS', { count: 4 }),
              }),
            ];
          }),
          catchError(() => [
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate('COMMON_MESSAGES.DEFINITION_SAVE_FAILED'),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * getDefaultThresholdV2$
   * @type {Observable<Action>}
   * @memberof DeemCommonEffects
   */
  public getDefaultThresholdV2$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeemCommonActions.getDefaultThresholdV2),
      switchMap(({ settingMetricKey }: ReturnType<typeof DeemCommonActions.getDefaultThresholdV2>) => {
        return this.solutionSettingMetricService.getDefaultSolutionSettingMetrics(settingMetricKey).pipe(
          map((updatedThresholds: SolutionSettingMetricDetails[]) => {
            const metric: string = settingMetricKey.metricName;
            const solutionSetting: string = settingMetricKey.solutionSetting;
            const thresholdV2Data: Record<string, Record<string, SolutionSettingMetricDetails[]>> = getThresholdsByMetricByEntity(
              updatedThresholds,
            );
            const updatingSettingMetrics: SolutionSettingMetricDetails[] = thresholdV2Data[solutionSetting][metric];

            return DeemCommonActions.setUpdatingActiveSettingMetrics({ updatingSettingMetrics });
          }),
          catchError(() => [
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate('COMMON_MESSAGES.DEFINITION_RESET_FAILED'),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * resetThresholdV2$
   * @type {Observable<Action>}
   * @memberof DeemCommonEffects
   */
  public resetThresholdV2$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeemCommonActions.resetThresholdV2),
      mergeMap(({ settingMetricKey }: ReturnType<typeof DeemCommonActions.resetThresholdV2>) => {
        return this.solutionSettingMetricService.resetSolutionSettingMetrics(settingMetricKey).pipe(
          mergeMap((metricDetails: SolutionSettingMetricDetails[]) => {
            const updatedThresholds = getThresholdsByMetricByEntity(metricDetails);
            const platform: string = settingMetricKey.platform;
            const metric: string = settingMetricKey.metricName;
            const solutionSetting: string = settingMetricKey.solutionSetting;
            const activeSettingMetrics: SolutionSettingMetricDetails[] = updatedThresholds[solutionSetting][metric];

            return [
              DeemCommonActions.setThresholdV2Success({
                thresholdsByEntity: updatedThresholds,
                entity: Object.keys(updatedThresholds)[0],
                metric,
                platform,
              }),
              DeemCommonActions.setActiveSettingMetrics({ activeSettingMetrics }),
            ];
          }),
          catchError(() => [
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate('COMMON_MESSAGES.DEFINITION_RESET_FAILED'),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * getActiveSettingMetrics$
   * @type {Observable<Action>}
   * @memberof DeemCommonEffects
   */
  public getActiveSettingMetrics$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeemCommonActions.getActiveSettingMetrics),
      mergeMap(({ settingMetricKey }: ReturnType<typeof DeemCommonActions.getActiveSettingMetrics>) => {
        return this.solutionSettingMetricService.getSolutionSettingMetrics(settingMetricKey).pipe(
          map((response: SolutionSettingMetricDetails[]) => {
            const data = getThresholdsByMetricByEntity(response);
            const metricName = settingMetricKey.metricName;
            const solutionSetting = settingMetricKey.solutionSetting;
            const activeSettingMetrics = data[solutionSetting][metricName];
            return DeemCommonActions.setActiveSettingMetrics({ activeSettingMetrics });
          }),
        );
      }),
    ),
  );

  /**
   * setActiveSettingMetrics$
   * @type {Observable<Action>}
   * @memberof DeemCommonEffects
   */
  public setActiveSettingMetrics$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeemCommonActions.setActiveSettingMetrics),
      withLatestFrom(this.store.select(DeemCommonSelectors.isDeemThresholdEditModalOpen)),
      mergeMap(([{ activeSettingMetrics }, isOpen]: [ReturnType<typeof DeemCommonActions.setActiveSettingMetrics>, boolean]) => {
        const actions: Action[] = [
          DeemCommonActions.setUpdatingActiveSettingMetrics({ updatingSettingMetrics: cloneDeep(activeSettingMetrics) }),
        ];
        if (!isOpen) {
          actions.push(DeemCommonActions.showDeemThresholdEditModal());
        }
        return actions;
      }),
    ),
  );

  /**
   * Creates an instance of DeemCommonEffects.
   * @param {SolutionSettingMetricService} solutionSettingMetricService
   * @param {Store<CoreAppState>} store
   * @param {Actions} actions$
   * @param {RouterExtensions} routerExtensions
   * @param {I18NService} i18nService
   * @memberof DeemCommonEffects
   */
  constructor(
    private solutionSettingMetricService: SolutionSettingMetricService,
    private store: Store<CoreAppState>,
    private actions$: Actions,
    private routerExtensions: RouterExtensions,
    public i18nService: I18NService,
  ) {}
}
