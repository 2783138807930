/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { TrendDateRange } from '@ws1c/intelligence-models';

/**
 * Defines UI State for CarbonBlack Dashboard Feature
 *
 * @export
 * @interface CarbonBlackDashboardState
 */
export interface CarbonBlackDashboardState {
  trendDateRange: TrendDateRange;
}

export const initialCarbonBlackDashboardState: CarbonBlackDashboardState = {
  trendDateRange: undefined,
};
