/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { WebError } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import {
  Account,
  AccountGroup,
  AccountGroupRolesRequest,
  AccountGroupSearchResponse,
  AccountRole,
  AccountSearchResponse,
  DataAccessPolicy,
  GenericSearchRequest,
  IntegrationGroup,
  IntegrationGroupSearchRequest,
  RolesSearchResponse,
  UserAdminAccount,
  UserAdminAccountRoles,
} from '@ws1c/intelligence-models';

/**
 * AccountActions
 * @export
 * @class AccountActions
 */
export class AccountActions {
  public static readonly CATEGORY = '[ACCOUNT]';

  public static addGroup = createAction(
    `${AccountActions.CATEGORY} ADD_GROUP`,
    props<{ accountGroupRolesRequest: AccountGroupRolesRequest }>(),
  );
  public static addGroupSuccess = createAction(`${AccountActions.CATEGORY} ADD_GROUP_SUCCESS`);
  public static addGroupFailure = createAction(`${AccountActions.CATEGORY} ADD_GROUP_FAILURE`);

  public static updateGroup = createAction(
    `${AccountActions.CATEGORY} UPDATE_GROUP`,
    props<{
      accountGroup: AccountGroup;
      isEditMode?: boolean;
    }>(),
  );
  public static updateGroupSuccess = createAction(`${AccountActions.CATEGORY} UPDATE_GROUP_SUCCESS`);
  public static updateGroupFailure = createAction(`${AccountActions.CATEGORY} UPDATE_GROUP_FAILURE`);

  public static deleteGroup = createAction(`${AccountActions.CATEGORY} DELETE_GROUP`, props<{ id: string }>());
  public static deleteGroupSuccess = createAction(`${AccountActions.CATEGORY} DELETE_GROUP_SUCCESS`);
  public static deleteGroupFailure = createAction(`${AccountActions.CATEGORY} DELETE_GROUP_FAILURE`);

  public static deleteGroups = createAction(`${AccountActions.CATEGORY} DELETE_GROUPS`);
  public static deleteGroupsSuccess = createAction(`${AccountActions.CATEGORY} DELETE_GROUPS_SUCCESS`);
  public static deleteGroupsFailure = createAction(`${AccountActions.CATEGORY} DELETE_GROUPS_FAILURE`);

  public static getAccountGroupByDirectoryGroupId = createAction(
    `${AccountActions.CATEGORY} GET_ACCOUNT_GROUP_BY_DIRECTORY_GROUP_ID`,
    props<{
      directoryGroupId: string;
      directoryType: string;
    }>(),
  );
  public static getAccountGroupByDirectoryGroupIdSuccess = createAction(
    `${AccountActions.CATEGORY} GET_ACCOUNT_GROUP_BY_DIRECTORY_GROUP_ID_SUCCESS`,
    props<{ accountGroup: AccountGroup }>(),
  );
  public static getAccountGroupByDirectoryGroupIdFailure = createAction(
    `${AccountActions.CATEGORY} GET_ACCOUNT_GROUP_BY_DIRECTORY_GROUP_ID_FAILURE`,
  );

  public static getUsersForAddAccount = createAction(
    `${AccountActions.CATEGORY} GET_USERS_FOR_ADD_ACCOUNT`,
    props<{ searchTerm: string }>(),
  );

  public static getUsersForAddAccountSuccess = createAction(
    `${AccountActions.CATEGORY} GET_USERS_FOR_ADD_ACCOUNT_SUCCESS`,
    props<{ userAdminAccounts: UserAdminAccount[] }>(),
  );

  public static getUsersForAddAccountFailure = createAction(`${AccountActions.CATEGORY} GET_USERS_FOR_ADD_ACCOUNT_FAILURE`);

  public static getAccounts = createAction(`${AccountActions.CATEGORY} GET_ACCOUNTS`, props<{ searchRequest: GenericSearchRequest }>());

  public static getAccountsSuccess = createAction(
    `${AccountActions.CATEGORY} GET_ACCOUNTS_SUCCESS`,
    props<{ accountSearchResponse: AccountSearchResponse }>(),
  );

  public static getAccountsFailure = createAction(`${AccountActions.CATEGORY} GET_ACCOUNTS_FAILURE`, props<{ error: WebError }>());

  public static refreshAccounts = createAction(`${AccountActions.CATEGORY} REFRESH_ACCOUNTS`);

  public static selectAccounts = createAction(`${AccountActions.CATEGORY} SELECT_ACCOUNTS`, props<{ selectedAccounts: Account[] }>());

  public static goToAssignAccountRoles = createAction(`${AccountActions.CATEGORY} GO_TO_ASSIGN_ACCOUNT_ROLES`);

  public static goToAssignGroupRoles = createAction(`${AccountActions.CATEGORY} GO_TO_ASSIGN_GROUP_ROLES`);

  public static goToAdministratorHome = createAction(`${AccountActions.CATEGORY} GO_TO_ADMINISTRATOR_HOME`);

  public static goToAdministratorGroupHome = createAction(`${AccountActions.CATEGORY} GO_TO_ADMINISTRATOR_GROUP_HOME`);

  public static goToAdministratorInviteHome = createAction(
    `${AccountActions.CATEGORY} GO_TO_ADMINISTRATOR_INVITE_HOME`,
    props<{ selectedAccountId: string; selectedAccountRoles: AccountRole[]; selectedEmail: string }>(),
  );

  public static deleteAccount = createAction(`${AccountActions.CATEGORY} DELETE_ACCOUNT`, props<{ id: string }>());

  public static deleteAccountSuccess = createAction(`${AccountActions.CATEGORY} DELETE_ACCOUNT_SUCCESS`);

  public static deleteAccountFailure = createAction(`${AccountActions.CATEGORY} DELETE_ACCOUNT_FAILURE`, props<{ error: WebError }>());

  public static deleteAccounts = createAction(`${AccountActions.CATEGORY} DELETE_ACCOUNTS`);

  public static deleteAccountsSuccess = createAction(`${AccountActions.CATEGORY} DELETE_ACCOUNTS_SUCCESS`);

  public static deleteAccountsFailure = createAction(`${AccountActions.CATEGORY} DELETE_ACCOUNTS_FAILURE`, props<{ error: WebError }>());

  public static getRoles = createAction(`${AccountActions.CATEGORY} GET_ROLES`);

  public static getRolesSuccess = createAction(`${AccountActions.CATEGORY} GET_ROLES_SUCCESS`, props<{ roles: AccountRole[] }>());

  public static getRolesFailure = createAction(`${AccountActions.CATEGORY} GET_ROLES_FAILURE`, props<{ error: WebError }>());

  public static addAccount = createAction(
    `${AccountActions.CATEGORY} ADD_ACCOUNT`,
    props<{ userRoleAssignmentRequest: UserAdminAccountRoles }>(),
  );

  public static addAccountSuccess = createAction(`${AccountActions.CATEGORY} ADD_ACCOUNT_SUCCESS`);

  public static addAccountFailure = createAction(`${AccountActions.CATEGORY} ADD_ACCOUNT_FAILURE`, props<{ error: WebError }>());

  public static updateAccount = createAction(`${AccountActions.CATEGORY} UPDATE_ACCOUNT`, props<{ account: Account }>());

  public static updateAccountSuccess = createAction(`${AccountActions.CATEGORY} UPDATE_ACCOUNT_SUCCESS`);

  public static updateAccountFailure = createAction(`${AccountActions.CATEGORY} UPDATE_ACCOUNT_FAILURE`, props<{ error: WebError }>());

  public static updateAccounts = createAction(`${AccountActions.CATEGORY} UPDATE_ACCOUNTS`);

  public static updateAccountsSuccess = createAction(`${AccountActions.CATEGORY} UPDATE_ACCOUNTS_SUCCESS`);

  public static updateAccountsFailure = createAction(`${AccountActions.CATEGORY} UPDATE_ACCOUNTS_FAILURE`, props<{ error: WebError }>());

  public static getAccountGroups = createAction(
    `${AccountActions.CATEGORY} GET_ACCOUNT_GROUPS`,
    props<{ searchRequest: GenericSearchRequest }>(),
  );

  public static getAccountGroupsSuccess = createAction(
    `${AccountActions.CATEGORY} GET_ACCOUNT_GROUPS_SUCCESS`,
    props<{ accountGroupSearchResponse: AccountGroupSearchResponse }>(),
  );

  public static getAccountGroupsFailure = createAction(
    `${AccountActions.CATEGORY} GET_ACCOUNT_GROUPS_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static getIntegrationGroups = createAction(
    `${AccountActions.CATEGORY} GET_INTEGRATION_GROUPS`,
    props<{ searchRequest: IntegrationGroupSearchRequest }>(),
  );

  public static getIntegrationGroupsSuccess = createAction(
    `${AccountActions.CATEGORY} GET_INTEGRATION_GROUPS_SUCCESS`,
    props<{ integrationGroups: IntegrationGroup[] }>(),
  );

  public static getIntegrationGroupsFailure = createAction(
    `${AccountActions.CATEGORY} GET_INTEGRATION_GROUPS_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static selectGroups = createAction(`${AccountActions.CATEGORY} SELECT_GROUPS`, props<{ selectedGroups: AccountGroup[] }>());

  public static refreshGroups = createAction(`${AccountActions.CATEGORY} REFRESH_GROUPS`);

  public static handleAadCommunicationError = createAction(`${AccountActions.CATEGORY} HANDLE_AAD_COMMUNICATION_ERROR`);

  public static showChooseDataAccessPolicyModal = createAction(
    `${AccountActions.CATEGORY} SHOW_CHOOSE_DATA_ACCESS_POLICY_MODAL`,
    props<{ showChooseDataAccessPolicyModal: boolean }>(),
  );

  public static selectDataAccessPolicy = createAction(
    `${AccountActions.CATEGORY} SELECT_DATA_ACCESS_POLICY`,
    props<{ selectedDataAccessPolicy: DataAccessPolicy }>(),
  );

  public static getAssignPolicyPreviewAndSave = createAction(
    `${AccountActions.CATEGORY} GET_ASSIGN_POLICY_PREVIEW_AND_SAVE`,
    props<{ selectedAccounts: Account[] }>(),
  );

  public static readonly searchIntelligenceRoles = createAction(`${AccountActions.CATEGORY} SEARCH_INTELLIGENCE_ROLES`);
  public static readonly setIntelligenceRolesSearchTerm = createAction(
    `${AccountActions.CATEGORY} SET_INTELLIGENCE_ROLES_SEARCH_TERM`,
    props<{ query: string }>(),
  );

  public static readonly getIntelligenceRolesSuccess = createAction(
    `${AccountActions.CATEGORY} GET_INTELLIGENCE_ROLES_SUCCESS`,
    props<{ intelligenceRolesResponse: RolesSearchResponse }>(),
  );

  public static readonly getIntelligenceRolesFailure = createAction(
    `${AccountActions.CATEGORY} GET_INTELLIGENCE_ROLES_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static readonly setSelectedIntelligenceRoles = createAction(
    `${AccountActions.CATEGORY} SET_SELECTED_INTELLIGENCE_ROLES`,
    props<{ selectedIntelligenceRoles: AccountRole[] }>(),
  );

  public static toggleRemoveConfirmModal = createAction(
    `${AccountActions.CATEGORY} TOGGLE_REMOVE_CONFIRM_MODAL`,
    props<{ show: boolean }>(),
  );

  public static toggleReplaceConfirmModal = createAction(
    `${AccountActions.CATEGORY} TOGGLE_REPLACE_CONFIRM_MODAL`,
    props<{ show: boolean }>(),
  );

  public static togglePolicyWarning = createAction(`${AccountActions.CATEGORY} TOGGLE_POLICY_WARNING_MODAL`, props<{ show: boolean }>());

  public static toggleSaveWarning = createAction(`${AccountActions.CATEGORY} TOGGLE_SAVE_WARNING`, props<{ show: boolean }>());
}
