/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';
import { GenericObject } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  AlertBannerActions,
  ConnectorCommonActions,
  ConnectorCommonSelectors,
  UserPreferenceAssetsSelectors,
} from '@ws1c/intelligence-core/store';
import {
  AlertBannerTarget,
  Connector,
  ConnectorConfigData,
  ConnectorConfigUpdateParams,
  ConnectorCreateParams,
  ConnectorModalType,
  ConnectorUpdateParams,
  WorkflowConnectorsTab,
} from '@ws1c/intelligence-models';

/**
 * ConnectorModalsComponent
 * @export
 * @class ConnectorModalsComponent
 */
@Component({
  selector: 'dpa-connector-modals',
  templateUrl: 'connector-modals.component.html',
  styleUrls: ['connector-modals.component.scss'],
})
export class ConnectorModalsComponent {
  public connectorModalState$: Observable<GenericObject>;
  public userGuideUrl$: Observable<string>;
  public samplesUrl$: Observable<string>;
  public readonly ConnectorModalType = ConnectorModalType;
  public readonly ALERT_BANNER_TARGET = AlertBannerTarget;

  /**
   * Creates an instance of ConnectorModalsComponent
   * @param {Store} store
   * @memberof ConnectorModalsComponent
   */
  constructor(private store: Store) {
    this.connectorModalState$ = this.store.select(ConnectorCommonSelectors.getConnectorModalState);
    this.userGuideUrl$ = this.store.select(UserPreferenceAssetsSelectors.getCustomConnectorsUserGuideUrl);
    this.samplesUrl$ = this.store.select(UserPreferenceAssetsSelectors.getCustomConnectorsSamplesUrl);
  }

  /**
   * closeModal
   * @memberof ConnectorModalsComponent
   */
  public closeModal() {
    this.store.dispatch(ConnectorCommonActions.closeModal());
    this.store.dispatch(
      AlertBannerActions.dismissAlertBanner({
        target: AlertBannerTarget.MODAL,
      }),
    );
  }

  /**
   * onCreate
   * @param {ConnectorCreateParams} params
   * @memberof ConnectorModalsComponent
   */
  public onCreate(params: ConnectorCreateParams) {
    this.store.dispatch(ConnectorCommonActions.createCustomConnector({ params }));
  }

  /**
   * onAuthorize
   * @param {string} id
   * @param {ConnectorConfigData} configData
   * @memberof ConnectorModalsComponent
   */
  public onAuthorize(id: string, configData: ConnectorConfigData) {
    this.store.dispatch(ConnectorCommonActions.createConnectorConfig({ id, configData }));
  }

  /**
   * onRename
   * @param {string} id
   * @param {ConnectorUpdateParams} params
   * @memberof ConnectorModalsComponent
   */
  public onRename(id: string, params: ConnectorUpdateParams) {
    this.store.dispatch(ConnectorCommonActions.updateConnector({ id, params }));
  }

  /**
   * onEdit
   * @param {string} id
   * @param {ConnectorConfigUpdateParams} params
   * @memberof ConnectorModalsComponent
   */
  public onEdit(id: string, params: ConnectorConfigUpdateParams) {
    this.store.dispatch(ConnectorCommonActions.updateConnectorConfig({ id, params }));
  }

  /**
   * onDelete
   * @param {Connector} connector
   * @memberof ConnectorModalsComponent
   */
  public onDelete(connector: Connector) {
    this.store.dispatch(ConnectorCommonActions.deleteConnectorConfig({ connector }));
  }

  /**
   * viewWorkflows
   * @param {Connector} connector
   * @memberof ConnectorModalsComponent
   */
  public onViewWorkflows(connector: Connector) {
    this.closeModal();
    this.store.dispatch(
      ConnectorCommonActions.navigateToConnectorDetails({
        id: connector.id,
        tab: WorkflowConnectorsTab.USAGE,
      }),
    );
  }
}
