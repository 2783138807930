/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { merge, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { I18NService } from '@ws1c/intelligence-common';
import { TrialService } from '@ws1c/intelligence-core/services/trial.service';
import {
  AlertBannerActions,
  CoreAppState,
  IntegratedServicesActions,
  UserPreferenceActions,
  UserPreferenceFeatureControlsSelectors,
  UserPreferenceSelectors,
} from '@ws1c/intelligence-core/store';
import { TrialServicesActions, TrialServicesSelectors } from '@ws1c/intelligence-core/store/trial-services';
import {
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  TrialActivationDetail,
  TrialDetail,
  TrialStatus,
  TrialUserContactDetails,
  UserAccount,
} from '@ws1c/intelligence-models';

/**
 * TrialServicesEffects
 *
 * @export
 * @class TrialServicesEffects
 */
@Injectable()
export class TrialServicesEffects {
  /**
   * getTrialDetails$
   * @type {Observable<Action>}
   * @memberof TrialServicesEffects
   */
  public getTrialDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TrialServicesActions.getTrialDetails),
      withLatestFrom(this.store.select(UserPreferenceFeatureControlsSelectors.isInProductTrialEnabled)),
      filter(([_action, isInProductTrialEnabled]: [Action, boolean]) => isInProductTrialEnabled),
      switchMap(() =>
        this.trialService.getTrialDetails().pipe(
          map((trialDetails: TrialDetail[]) => TrialServicesActions.getTrialDetailsSuccess({ trialDetails })),
          catchError((error: WebError) => {
            return merge(
              of(TrialServicesActions.getTrialDetailsFailure()),
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  message: this.i18nService.getLocalizedError('TRIAL_BANNER.GET_TRIAL_DETAILS_FAILURE_MSG', error),
                  target: AlertBannerTarget.PAGE,
                }),
              ),
            );
          }),
        ),
      ),
    ),
  );

  /**
   * refreshTrialStatus$
   * @type {Observable<Action>}
   * @memberof TrialServicesEffects
   */
  public refreshTrialStatus$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TrialServicesActions.refreshTrialStatus),
      withLatestFrom(this.store.select(TrialServicesSelectors.getTrialStatusByServiceTypeMap)),
      switchMap(
        ([_, trialStatusByServiceTypeMap]: [ReturnType<typeof TrialServicesActions.refreshTrialStatus>, Map<string, TrialStatus>]) =>
          this.trialService.getTrialDetails().pipe(
            mergeMap((trialDetails: TrialDetail[]) => {
              const actions: Action[] = [TrialServicesActions.getTrialDetailsSuccess({ trialDetails })];
              const isSomeTrialServiceStatusUpdated: boolean = trialDetails?.some(
                (trialDetail: TrialDetail) =>
                  trialDetail.status === TrialStatus.ACTIVE &&
                  trialStatusByServiceTypeMap.get(trialDetail.integratedServiceType) === TrialStatus.REQUESTED,
              );
              if (isSomeTrialServiceStatusUpdated) {
                actions.push(UserPreferenceActions.loadUserPreference());
                actions.push(IntegratedServicesActions.getIntegratedServices());
              }
              return actions;
            }),
            catchError((error: WebError) => [
              TrialServicesActions.getTrialDetailsFailure(),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                message: this.i18nService.getLocalizedError('TRIAL_BANNER.GET_TRIAL_DETAILS_FAILURE_MSG', error),
                target: AlertBannerTarget.PAGE,
              }),
            ]),
          ),
      ),
    ),
  );

  /**
   * getTrialDetailsForServiceType$
   * @type {Observable<Action>}
   * @memberof TrialServicesEffects
   */
  public getTrialDetailsForServiceType$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TrialServicesActions.getTrialDetailsForServiceType),
      withLatestFrom(this.store.select(UserPreferenceFeatureControlsSelectors.isInProductTrialEnabled)),
      filter(([_action, isInProductTrialEnabled]: [Action, boolean]) => isInProductTrialEnabled),
      switchMap(([{ serviceType }]: [ReturnType<typeof TrialServicesActions.getTrialDetailsForServiceType>, boolean]) =>
        this.trialService.getTrialDetails().pipe(
          map((trialDetails: TrialDetail[]) => TrialServicesActions.getTrialDetailsForServiceTypeSuccess({ trialDetails, serviceType })),
          catchError((error: WebError) => {
            return merge(
              of(TrialServicesActions.getTrialDetailsForServiceTypeFailure({ serviceType })),
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  message: this.i18nService.getLocalizedError('TRIAL_BANNER.GET_TRIAL_DETAILS_FAILURE_MSG', error),
                  target: AlertBannerTarget.PAGE,
                }),
              ),
            );
          }),
        ),
      ),
    ),
  );

  /**
   * startTrialV2$
   * @type {Observable<Action>}
   * @memberof TrialServicesEffects
   */
  public startTrialV2$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TrialServicesActions.startTrialV2),
      withLatestFrom(this.store.select(TrialServicesSelectors.getTrialUserContactDetails)),
      switchMap(([{ serviceType }, userContactDetails]: [ReturnType<typeof TrialServicesActions.startTrialV2>, TrialUserContactDetails]) =>
        this.trialService.startTrialV2(serviceType, userContactDetails).pipe(
          mergeMap((trialActivationDetail: TrialActivationDetail) => {
            const actions: Action[] = [
              TrialServicesActions.startTrialV2Success({ trialActivationDetail }),
              TrialServicesActions.getTrialDetailsForServiceType({ serviceType }),
            ];
            if (trialActivationDetail?.requestStatus === TrialStatus.FAILED) {
              actions.push(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  message: this.i18nService.translate('TRIAL_BANNER.START_TRIAL_FAILED_ERROR_MSG'),
                  target: AlertBannerTarget.PAGE,
                }),
              );
            }
            return actions;
          }),
          catchError((error: WebError) => {
            return merge(
              of(TrialServicesActions.startTrialV2Failure({ serviceType })),
              of(
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  message: this.i18nService.getLocalizedError('TRIAL_BANNER.START_TRIAL_FAILURE_MSG', error),
                  target: AlertBannerTarget.PAGE,
                }),
              ),
            );
          }),
        ),
      ),
    ),
  );

  /**
   * getTrialUserContactDetailsV2$
   * @type {Observable<Action>}
   * @memberof TrialServicesEffects
   */
  public getTrialUserContactDetailsV2$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TrialServicesActions.getTrialUserContactDetailsV2),
      switchMap(() =>
        this.trialService.getTrialUserContactDetailsV2().pipe(
          switchMap((trialUserContactDetails: TrialUserContactDetails) => {
            return [
              TrialServicesActions.getTrialUserContactDetailsV2Success({ trialUserContactDetails }),
              TrialServicesActions.setTrialServicesModalOpenState({ isOpen: true }),
              TrialServicesActions.showTrialUserContactDetailsForm({ showTrialUserContactDetailsForm: true }),
            ];
          }),
          catchError((error: WebError) => {
            return [
              TrialServicesActions.getTrialUserContactDetailsV2Failure(),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                message: this.i18nService.getLocalizedError('TRIAL_BANNER.GET_TRIAL_USER_CONTACT_DETAILS_FAILURE_MSG', error),
                target: AlertBannerTarget.PAGE,
              }),
            ];
          }),
        ),
      ),
    ),
  );

  /**
   * collectTrialUserDetailsOrStartTrialFromWorkspace$
   * start trial if user contact details are present else get contact details to prepopulate form
   * @type {Observable<Action>}
   * @memberof TrialServicesEffects
   */
  public collectTrialUserDetailsOrStartTrialFromWorkspace$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TrialServicesActions.collectTrialUserDetailsOrStartTrialFromWorkspace),
      withLatestFrom(
        this.store.select(UserPreferenceSelectors.getUserAccountInfo),
        this.store.select(TrialServicesSelectors.getTrialUserContactDetails),
        this.store.select(TrialServicesSelectors.isTrialUserContactDetailsAccepted),
      ),
      switchMap(
        ([{ serviceType }, userAccount, trialUserContactDetails, isTrialUserContactDetailsAccepted]: [
          ReturnType<typeof TrialServicesActions.collectTrialUserDetailsOrStartTrialFromWorkspace>,
          UserAccount,
          TrialUserContactDetails,
          boolean,
        ]) => {
          const actions: Action[] = [
            TrialServicesActions.setTrialServicesModalOpenState({ isOpen: true }),
            TrialServicesActions.showTrialUserContactDetailsForm({ showTrialUserContactDetailsForm: true }),
          ];
          if (userAccount.isValid) {
            return [
              TrialServicesActions.getTrialUserContactDetailsV2Success({
                trialUserContactDetails: new TrialUserContactDetails({
                  email: userAccount.email,
                  firstName: userAccount.firstName,
                  lastName: userAccount.lastName,
                }),
              }),
              TrialServicesActions.startTrialV2({ serviceType }),
            ];
          }
          if (trialUserContactDetails) {
            if (!isTrialUserContactDetailsAccepted) {
              return actions;
            }
            return of(TrialServicesActions.startTrialV2({ serviceType }));
          }
          return of(TrialServicesActions.getTrialUserContactDetailsV2());
        },
      ),
    ),
  );

  /**
   * openTrialServicesModalAndCollectUserContactDetails$
   * open trial services modal without form if contact details are present else get contact details to prepopulate form
   * @type {Observable<Action>}
   * @memberof TrialServicesEffects
   */
  public openTrialServicesModalAndCollectUserContactDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TrialServicesActions.openTrialServicesModalAndCollectUserContactDetails),
      withLatestFrom(
        this.store.select(UserPreferenceSelectors.getUserAccountInfo),
        this.store.select(TrialServicesSelectors.getTrialUserContactDetails),
        this.store.select(TrialServicesSelectors.isTrialUserContactDetailsAccepted),
      ),
      switchMap(
        ([, userAccount, trialUserContactDetails, isTrialUserContactDetailsAccepted]: [
          ReturnType<typeof TrialServicesActions.openTrialServicesModalAndCollectUserContactDetails>,
          UserAccount,
          TrialUserContactDetails,
          boolean,
        ]) => {
          const actions: Action[] = [
            TrialServicesActions.setTrialServicesModalOpenState({ isOpen: true }),
            TrialServicesActions.showTrialUserContactDetailsForm({ showTrialUserContactDetailsForm: false }),
          ];
          if (userAccount.isValid) {
            actions.push(
              TrialServicesActions.getTrialUserContactDetailsV2Success({
                trialUserContactDetails: new TrialUserContactDetails({
                  email: userAccount.email,
                  firstName: userAccount.firstName,
                  lastName: userAccount.lastName,
                }),
              }),
            );
            return actions;
          }
          if (trialUserContactDetails) {
            if (isTrialUserContactDetailsAccepted) {
              return actions;
            }
            return [
              TrialServicesActions.setTrialServicesModalOpenState({ isOpen: true }),
              TrialServicesActions.showTrialUserContactDetailsForm({ showTrialUserContactDetailsForm: true }),
            ];
          }
          return of(TrialServicesActions.getTrialUserContactDetailsV2());
        },
      ),
    ),
  );

  /**
   * Creates an instance of TrialServicesEffects
   * @param {Actions} actions$ - Actions observable instance
   * @param {TrialService} trialService - Instance of TrialService
   * @param {Store<CoreAppState>} store - Merlot store observable instance
   * @param {I18NService} i18nService
   * @memberof TrialServicesEffects
   */
  constructor(
    private actions$: Actions,
    private trialService: TrialService,
    private store: Store<CoreAppState>,
    private i18nService: I18NService,
  ) {}
}
