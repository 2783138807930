/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { FullPageElementService, GenericObject, WebError, WindowService } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { iif, merge, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { AppConfig, CookiesService, I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import { AccountService, UserPreferenceService } from '@ws1c/intelligence-core/services';
import {
  AlertBannerActions,
  CoreAppState,
  IntegratedServicesActions,
  NavigationMenuActions,
  OrgActions,
  OrgSelectors,
  UserPreferenceActions,
  UserPreferenceCommonSelectors,
  UserPreferenceSelectors,
  UserPreferenceTrialInfoSelectors,
  UserPreferenceUIPreferenceSelectors,
} from '@ws1c/intelligence-core/store';
import {
  AcceptTrial,
  Account,
  ALERT_BANNER_TYPE,
  Directory,
  IntelOrg,
  IntroModalMode,
  LOAD_STATE,
  PendoMetadata,
  PendoUpdateResponse,
  PendoVisitorMetadata,
  ROUTE_NAMES,
  TrialBanner,
  TrialBannerState,
  UIPreference,
  UISettings,
  User,
  UserAccount,
  UserPreference,
} from '@ws1c/intelligence-models';

/**
 * UserPreferenceEffects
 * @export
 * @class UserPreferenceEffects
 */
@Injectable()
export class UserPreferenceEffects {
  public static readonly ALMOST_EXPIRED_DAYS_THRESHOLD = 5;
  public static readonly NOT_FOUND_STATUS_CODE: number = 404;

  /**
   * acknowledgeIntroPageVisitPreference$
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public acknowledgeIntroPageVisitPreference$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.acknowledgeIntroPageVisitPreference),
      withLatestFrom(this.store.select(UserPreferenceSelectors.getCurrentIntroPageUIPreference)),
      map(([_actions, currentIntroPagePreference]: [Action, UIPreference]) =>
        UserPreferenceActions.updateUISettings({
          uiSettings: { [currentIntroPagePreference]: true },
        }),
      ),
    ),
  );

  /**
   * updateUISettings$
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public updateUISettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.updateUISettings),
      switchMap(({ uiSettings: preferencesToBeUpdated }: ReturnType<typeof UserPreferenceActions.updateUISettings>) => {
        const uiSettingsToBeUpdated: UISettings = new UISettings({
          preferences: {
            ...preferencesToBeUpdated,
          },
        });
        const preferenceKeys = Object.keys(preferencesToBeUpdated);
        return this.userPreferenceService.updateUISettings(uiSettingsToBeUpdated).pipe(
          mergeMap(() => [
            UserPreferenceActions.updateUISettingsSuccess({ uiSettings: uiSettingsToBeUpdated }),
            UserPreferenceActions.uiPreferencesChangeSuccess({
              preferenceKeys,
            }),
          ]),
          catchError((errorDetails: WebError) =>
            of(
              UserPreferenceActions.uiPreferencesChangeFailure({
                preferenceKeys,
                error: errorDetails,
              }),
            ),
          ),
        );
      }),
    ),
  );

  /**
   * navigateToHomePage$
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public navigateToHomePage$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserPreferenceActions.navigateToHomePage),
        tap(() => {
          this.routerExtensions.navigate([`/${ROUTE_NAMES.WORKSPACE.HOME}`]);
        }),
      ),
    { dispatch: false },
  );

  /**
   * loadUserPreference$
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public loadUserPreference$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.loadUserPreference, UserPreferenceActions.refreshUserPreference),
      startWith(UserPreferenceActions.loadUserPreference()),
      switchMap((action: ReturnType<typeof UserPreferenceActions.refreshUserPreference>) => {
        return merge(
          of(UserPreferenceActions.setUserPreferenceLoadState({ loadState: LOAD_STATE.IN_FLIGHT })),
          this.userPreferenceService.getUserPreference().pipe(
            mergeMap((userPreference: UserPreference) => {
              if (action.redirectRoute) {
                this.routerExtensions.navigate([action.redirectRoute]);
              }
              return [
                UserPreferenceActions.loadUserPreferenceSuccess({ userPreference }),
                UserPreferenceActions.getUISettingsSuccess({ uiSettings: userPreference.uiSettings }),
              ];
            }),
            catchError(() => of(UserPreferenceActions.setUserPreferenceLoadState({ loadState: LOAD_STATE.FAILURE }))),
          ),
        );
      }),
    ),
  );

  /**
   * loadUserPreferenceSuccess$
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public loadUserPreferenceSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.loadUserPreferenceSuccess),
      withLatestFrom(this.store.select(UserPreferenceSelectors.isRefreshingUserPreference)),
      filter(
        ([_action, isRefreshingUserPreference]: [ReturnType<typeof UserPreferenceActions.loadUserPreferenceSuccess>, boolean]) =>
          !isRefreshingUserPreference,
      ),
      map(() => {
        return UserPreferenceActions.getPendoMetadata();
      }),
    ),
  );

  /**
   * Close the intro modal
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public closeIntroModal$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.closeIntroModal),
      map(() => UserPreferenceActions.setIntroModalMode({ introModalMode: IntroModalMode.CLOSE })),
    ),
  );

  /**
   * Resize full-page container when the trial banner is displayed or closed
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public setTrialBannerState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserPreferenceActions.setTrialBannerState),
        tap(({ trialBannerState }: ReturnType<typeof UserPreferenceActions.setTrialBannerState>) => {
          if (trialBannerState !== TrialBannerState.HIDDEN) {
            this.fullPageElementService.resizeContainer();
          }
        }),
      ),
    { dispatch: false },
  );

  /**
   * Get trial banner state after the user preferences have been loaded
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public getTrialBannerState$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.loadUserPreferenceSuccess),
      withLatestFrom(
        this.store.select(UserPreferenceTrialInfoSelectors.isTrialBannerStarted),
        this.store.select(UserPreferenceSelectors.isRefreshingUserPreference),
      ),
      filter(([_action, isTrialBannerStarted, isRefreshingUserPreference]: [Action, boolean, boolean]) => {
        return !(isTrialBannerStarted || isRefreshingUserPreference);
      }),
      map(([{ userPreference }]: [ReturnType<typeof UserPreferenceActions.loadUserPreferenceSuccess>, boolean, boolean]) => {
        const trialInfo = userPreference.org.trialBanner as TrialBanner;
        let bannerState: TrialBannerState = TrialBannerState.HIDDEN;
        if (trialInfo.expired) {
          bannerState = TrialBannerState.EXPIRED;
        } else if (trialInfo.active) {
          bannerState = TrialBannerState.COUNTDOWN;
        }
        return UserPreferenceActions.setTrialBannerState({ trialBannerState: bannerState });
      }),
    ),
  );

  /**
   * Get the HTML EULA content
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public getEula$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.getEula),
      switchMap(() => {
        return this.userPreferenceService.getEula().pipe(
          map((eulaHtml: string) => UserPreferenceActions.getEulaSuccess({ eulaHtml })),
          catchError((error: WebError) => of(UserPreferenceActions.getEulaFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * Show the landing page after the EULA has been accepted
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public acceptEulaSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.acceptEulaSuccess),
      map(() => {
        this.routerExtensions.navigate(['/']);
        return UserPreferenceActions.acceptEulaRedirect();
      }),
    ),
  );

  /**
   * Cancel the EULA and show access-denied page
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public cancelEula$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.cancelEula, UserPreferenceActions.acceptEulaFailure),
      map(() => {
        this.routerExtensions.navigate(['/access-denied']);
        return UserPreferenceActions.cancelEulaSuccess();
      }),
    ),
  );

  /**
   * Accept the EULA
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public acceptEula$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.acceptEula),
      switchMap((action: ReturnType<typeof UserPreferenceActions.acceptEula>) => {
        return this.userPreferenceService.acceptEula(action.formData).pipe(
          map(() => UserPreferenceActions.acceptEulaSuccess()),
          catchError((error: WebError) => of(UserPreferenceActions.acceptEulaFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * Invokes service method to fetch trial user contact details
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public getTrialUserContactDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.getTrialUserContactDetails),
      withLatestFrom(
        this.store.select(UserPreferenceSelectors.isCspLoggedInUser),
        this.store.select(UserPreferenceSelectors.getUserAccountInfo),
      ),
      switchMap(([_action, isCspLoggedInUser, userAccount]: [Action, boolean, UserAccount]) =>
        iif(
          () => isCspLoggedInUser,
          this.userPreferenceService.getTrialUserContactDetails().pipe(
            map((userContactDetails: AcceptTrial) => UserPreferenceActions.getTrialUserContactDetailsSuccess({ userContactDetails })),
            catchError((error: WebError) => of(UserPreferenceActions.getTrialUserContactDetailsFailure({ error }))),
          ),
          of(
            UserPreferenceActions.getTrialUserContactDetailsSuccess({
              userContactDetails: new AcceptTrial({
                emailAddress: userAccount.email,
                firstName: userAccount.firstName,
                lastName: userAccount.lastName,
              }),
            }),
          ),
        ),
      ),
    ),
  );

  /**
   * Accept the trial
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public acceptTrial$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.acceptTrial),
      switchMap(({ formData }: ReturnType<typeof UserPreferenceActions.acceptTrial>) => {
        return this.userPreferenceService.startTrial(formData).pipe(
          switchMap(() =>
            merge(
              of(UserPreferenceActions.acceptTrialSuccess()),
              of(UserPreferenceActions.setTrialBannerState({ trialBannerState: TrialBannerState.STARTED })),
              // Reload User Preferences to update feature controls after accepted trial.
              of(UserPreferenceActions.loadUserPreference()),
              of(IntegratedServicesActions.getIntegratedServices()),
            ),
          ),
          catchError((error: WebError) => of(UserPreferenceActions.acceptTrialFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * Fetches trial user contact details if intro modal mode is ACTIVATE_TRIAL
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public setIntroModalMode$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.setIntroModalMode),
      filter(
        ({ introModalMode }: ReturnType<typeof UserPreferenceActions.setIntroModalMode>) =>
          introModalMode === IntroModalMode.ACTIVATE_TRIAL,
      ),
      map(() => UserPreferenceActions.getTrialUserContactDetails()),
    ),
  );

  /**
   * startFeature$
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public startFeature$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.startFeature),
      withLatestFrom(this.store.select(OrgSelectors.showServicesOptInModal)),
      switchMap(([_action, showServicesOptInModal]: [Action, boolean]) =>
        iif(
          () => showServicesOptInModal,
          of(OrgActions.toggleServiceOptInModal({ isOpen: true })),
          of(UserPreferenceActions.acknowledgeIntroPageVisitPreference()),
        ),
      ),
    ),
  );

  /**
   * uiPreferencesChangeSuccess$
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public uiPreferencesChangeSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.uiPreferencesChangeSuccess),
      withLatestFrom(this.store.select(UserPreferenceUIPreferenceSelectors.uiSettingsPreferences)),
      mergeMap(
        ([{ preferenceKeys }, preferences]: [ReturnType<typeof UserPreferenceActions.uiPreferencesChangeSuccess>, GenericObject]) => {
          // Find changed intro page UI preference and navigate to default feature menu item
          const actions: Action[] = [];
          const introPageVisitedPreference: string = preferenceKeys.find((uiPreferenceKey: UIPreference) =>
            [
              UIPreference.IS_AUTOMATION_INTRO_PAGE_VISITED,
              UIPreference.IS_DASHBOARD_INTRO_PAGE_VISITED,
              UIPreference.IS_REPORT_INTRO_PAGE_VISITED,
            ].includes(uiPreferenceKey),
          );
          const homePageSectionOrderPreference: string = preferenceKeys.find((uiPreferenceKey: UIPreference) =>
            [UIPreference.HOME_PAGE_SECTION_ORDER].includes(uiPreferenceKey),
          );

          if (introPageVisitedPreference && preferences[introPageVisitedPreference]) {
            actions.push(NavigationMenuActions.navigateOnIntroAcknowledgement({ preference: introPageVisitedPreference as UIPreference }));
          }

          if (homePageSectionOrderPreference && preferences[homePageSectionOrderPreference]) {
            actions.push(UserPreferenceActions.navigateToHomePage());
          }

          return actions;
        },
      ),
    ),
  );

  /**
   * uiPreferencesChangeFailure$
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public uiPreferencesChangeFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.uiPreferencesChangeFailure),
      mergeMap((action: ReturnType<typeof UserPreferenceActions.uiPreferencesChangeFailure>) => {
        const { preferenceKeys, error } = action;
        // Find changed intro page UI preference and show relevant error message
        const INTRO_PAGE_PREFERENCE_TO_FEATURE_LABEL: Record<string, string> = {
          [UIPreference.IS_AUTOMATION_INTRO_PAGE_VISITED]: 'SETTINGS.WORKFLOW',
          [UIPreference.IS_DASHBOARD_INTRO_PAGE_VISITED]: 'COMMON_MESSAGES.DASHBOARD',
          [UIPreference.IS_REPORT_INTRO_PAGE_VISITED]: 'EVENTS_TYPES.REPORT',
        };
        const introPageVisitedPreference: string = preferenceKeys.find((preferenceKey: UIPreference) =>
          [
            UIPreference.IS_AUTOMATION_INTRO_PAGE_VISITED,
            UIPreference.IS_DASHBOARD_INTRO_PAGE_VISITED,
            UIPreference.IS_REPORT_INTRO_PAGE_VISITED,
          ].includes(preferenceKey),
        );
        const actions: Action[] = [];
        if (introPageVisitedPreference) {
          actions.push(
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              message: this.i18nService.translate('INTRO_PAGE.INTRO_PAGE_START_FAILURE', {
                feature: this.i18nService.translate(INTRO_PAGE_PREFERENCE_TO_FEATURE_LABEL[introPageVisitedPreference]),
                reason: error && error.getFullReason(),
              }),
            }),
          );
        }
        return actions;
      }),
    ),
  );

  /**
   * navigateToCookiePolicy$
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public navigateToCookiePolicy$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserPreferenceActions.navigateToCookiePolicy),
        tap(() => {
          this.routerExtensions.navigateByUrl(`/${ROUTE_NAMES.COOKIE_POLICY}`);
        }),
      ),
    { dispatch: false },
  );

  /**
   * refreshMyAccountInfo$
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public refreshMyAccountInfo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.refreshMyAccountInfo),
      switchMap(() =>
        this.userPreferenceService.getUserInfo().pipe(
          map((user: User) => UserPreferenceActions.refreshMyAccountInfoSuccess({ user })),
          catchError(() => [
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              message: this.i18nService.translate('USER_PREFERENCES.FAILED_TO_UPDATE'),
            }),
          ]),
        ),
      ),
    ),
  );

  /**
   * refreshUserAccount$
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public refreshUserAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.refreshUserAccount),
      switchMap(() =>
        this.userPreferenceService
          .getUserAccount()
          .pipe(map((userAccount: UserAccount) => UserPreferenceActions.addBasicInfoToProfile({ userAccount }))),
      ),
    ),
  );

  /**
   * addBasicInfoToProfile$
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public addBasicInfoToProfile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.addBasicInfoToProfile),
      withLatestFrom(this.store.select(UserPreferenceSelectors.isUemIdentity)),
      switchMap(([{ userAccount }, isUemIdentity]: [ReturnType<typeof UserPreferenceActions.addBasicInfoToProfile>, boolean]) => {
        return this.accountService.getAccountById(userAccount.id).pipe(
          map((account: Account) =>
            UserPreferenceActions.refreshUserAccountSuccess({
              userAccount: new UserAccount({
                ...userAccount,
                firstName: account.firstName,
                lastName: account.lastName,
                email: account.email,
                userName: account.userName,
                isUemIdentity,
                directoryUser: new Directory(account.directory),
              }),
            }),
          ),
        );
      }),
    ),
  );

  /**
   * getPendoMetadata$
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public getPendoMetadata$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.getPendoMetadata),
      withLatestFrom(this.store.select(UserPreferenceSelectors.pendoVisitorId)),
      filter(() => !!AppConfig.PENDO),
      switchMap(([_action, pendoVisitorId]: [Action, string]) => {
        return this.userPreferenceService.getPendoMetadata(pendoVisitorId).pipe(
          map((metadata: PendoMetadata) => UserPreferenceActions.setPendoMetadata({ metadata })),
          catchError(({ status }: { status: number }) => {
            const actions: Action[] = [UserPreferenceActions.setPendoMetadata({ metadata: new PendoMetadata() })];
            if (status === UserPreferenceEffects.NOT_FOUND_STATUS_CODE) {
              return actions;
            }
            return [
              ...actions,
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                message: this.i18nService.translate('UI_TELEMETRY.GET_PENDO_METADATA_FAILURE_MSG'),
              }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * setPendoMetadata$
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public setPendoMetadata$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserPreferenceActions.setPendoMetadata),
        withLatestFrom(
          this.store.select(UserPreferenceCommonSelectors.getUserPreference),
          this.store.select(UserPreferenceSelectors.pendoAccountId),
          this.store.select(UserPreferenceSelectors.pendoVisitorId),
        ),
        tap(
          ([action, userPreference, pendoAccountId, pendoVisitorId]: [
            ReturnType<typeof UserPreferenceActions.setPendoMetadata>,
            UserPreference,
            string,
            string,
          ]) => {
            const pendo: typeof AppConfig.PENDO = AppConfig.PENDO;
            const isPendoReady: boolean = typeof pendo.isReady === 'function' ? pendo.isReady() : false;
            if (!isPendoReady && action.metadata.donotprocess) {
              return;
            }
            // Initialize pendo if pendo is not ready
            if (!isPendoReady) {
              this.userPreferenceService.initializePendo(userPreference, pendoAccountId, pendoVisitorId, action.metadata.blacklistguides);
              return;
            }
            // Stop event tracking if opted out
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            action.metadata.donotprocess ? pendo.stopSendingEvents() : pendo.startSendingEvents();
            // Disable guides if opted out
            pendo.setGuidesDisabled(action.metadata.blacklistguides);
          },
        ),
      ),
    { dispatch: false },
  );

  /**
   * updatePendoMetadata$
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public updatePendoMetadata$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.updatePendoMetadata),
      withLatestFrom(this.store.select(UserPreferenceSelectors.pendoVisitorId)),
      mergeMap(([{ metadata }, pendoVisitorId]: [ReturnType<typeof UserPreferenceActions.updatePendoMetadata>, string]) => {
        const pendoVisitorMetadata: PendoVisitorMetadata = new PendoVisitorMetadata({
          visitorId: pendoVisitorId,
          values: metadata,
        });
        return this.userPreferenceService.updatePendoMetadata([pendoVisitorMetadata]).pipe(
          mergeMap((pendoUpdateResponse: PendoUpdateResponse) => {
            const actions: Action[] = [UserPreferenceActions.getPendoMetadata()];
            if (!pendoUpdateResponse?.failed) {
              return actions;
            }
            return [
              ...actions,
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                message: this.i18nService.translate('UI_TELEMETRY.UPDATE_PENDO_METADATA_FAILURE_MSG'),
              }),
            ];
          }),
          catchError(() => [
            UserPreferenceActions.getPendoMetadata(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              message: this.i18nService.translate('UI_TELEMETRY.UPDATE_PENDO_METADATA_FAILURE_MSG'),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * setBrownfieldNotificationModalOpenState$
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public setBrownfieldNotificationModalOpenState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserPreferenceActions.setBrownfieldNotificationModalOpenState),
        tap(({ isOpen }: ReturnType<typeof UserPreferenceActions.setBrownfieldNotificationModalOpenState>) => {
          if (!isOpen) {
            const sessionIdentifier: string = this.cookieService.getCookie(AppConfig.SESSION_IDENTIFIER) || '';
            if (sessionIdentifier) {
              this.windowService.sessionStorage.setItem(
                `${sessionIdentifier}_${AppConfig.IS_BROWNFIELD_NOTIFICATION_MODAL_DISPLAYED}`,
                'true',
              );
            }
          }
        }),
      ),
    { dispatch: false },
  );

  /**
   * updateBrownfieldNotificationModalOpenStateBasedOnSessionStorage$
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public updateBrownfieldNotificationModalOpenStateBasedOnSessionStorage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.updateBrownfieldNotificationModalOpenStateBasedOnSessionStorage),
      startWith(UserPreferenceActions.updateBrownfieldNotificationModalOpenStateBasedOnSessionStorage),
      map(() => {
        const sessionIdentifier: string = this.cookieService.getCookie(AppConfig.SESSION_IDENTIFIER) || '';
        const isDisplayed = JSON.parse(
          this.windowService.sessionStorage.getItem(`${sessionIdentifier}_${AppConfig.IS_BROWNFIELD_NOTIFICATION_MODAL_DISPLAYED}`),
        );
        return UserPreferenceActions.setBrownfieldNotificationModalOpenState({ isOpen: !isDisplayed });
      }),
    ),
  );

  /**
   * getUserOrgs$
   * @type {Observable<Action>}
   * @memberof UserPreferenceEffects
   */
  public getUserOrgs$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.getUserOrgs),
      withLatestFrom(this.store.select(UserPreferenceSelectors.getUserAccountInfo)),
      switchMap(([_action, user]: [ReturnType<typeof UserPreferenceActions.getUserOrgs>, UserAccount]) => {
        return this.userPreferenceService.getUserOrgs(user.email).pipe(
          map((userOrgs: IntelOrg[]) => UserPreferenceActions.getUserOrgsSuccess({ userOrgs })),
          catchError(() => of(UserPreferenceActions.getUserOrgsFailure())),
        );
      }),
    ),
  );

  /**
   * Creates an instance of UserPreferenceEffects
   * @param {Store<CoreAppState>} store - Merlot store observable instance
   * @param {Actions} actions$ - Actions observable instance
   * @param {CookiesService} cookieService
   * @param {FullPageElementService} fullPageElementService - Full page element service instance
   * @param {UserPreferenceService} userPreferenceService - User preference service instance
   * @param {AccountService} accountService - Account service instance
   * @param {I18NService} i18nService - I18n service instance
   * @param {RouterExtensions} routerExtensions - Router extensions instance
   * @param {WindowService} windowService
   * @memberof UserPreferenceEffects
   */
  constructor(
    private store: Store<CoreAppState>,
    private actions$: Actions,
    private cookieService: CookiesService,
    private fullPageElementService: FullPageElementService,
    private userPreferenceService: UserPreferenceService,
    private accountService: AccountService,
    private i18nService: I18NService,
    private routerExtensions: RouterExtensions,
    private windowService: WindowService,
  ) {}
}
