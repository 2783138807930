<form
  clrForm
  [formGroup]="form"
>
  <dpa-dynamic-form-node
    [node]="jsonSchema"
    [metadataByAnchor]="metadataByAnchor"
    [parentForm]="form"
    [formValues]="formValues"
    [columnLookupVariables]="columnLookupVariables"
    [readonly]="readonly"
    [searchableActionsEnabled]="searchableActionsEnabled"
  ></dpa-dynamic-form-node>
</form>
