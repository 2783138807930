/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { UagTunnelDashboardActions } from './uag-tunnel-dashboard.actions';
import { initialUagTunnelDashboardState, UagTunnelDashboardState } from './uag-tunnel-dashboard.state';

const _reducer: ActionReducer<UagTunnelDashboardState> = createReducer(
  initialUagTunnelDashboardState,
  on(
    UagTunnelDashboardActions.setTrendDateRange,
    (
      state: UagTunnelDashboardState,
      { trendDateRange }: ReturnType<typeof UagTunnelDashboardActions.setTrendDateRange>,
    ): UagTunnelDashboardState => ({
      ...state,
      trendDateRange,
    }),
  ),
);

/**
 * uagTunnelDashboardState
 * @export
 * @param {UagTunnelDashboardState} state
 * @param {Action} action
 * @returns {UagTunnelDashboardState}
 */
export function uagTunnelDashboardState(state: UagTunnelDashboardState, action: Action): UagTunnelDashboardState {
  return _reducer(state, action);
}
