/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input } from '@angular/core';

/**
 * SidePanelExpandedHeaderComponent
 * @export
 * @class SidePanelExpandedHeaderComponent
 */
@Component({
  selector: 'dpa-side-panel-expanded-header',
  templateUrl: 'side-panel-expanded-header.component.html',
  styleUrls: ['side-panel-expanded-header.component.scss'],
})
export class SidePanelExpandedHeaderComponent {
  @Input() public title: string;
}
